import {WorksheetItem} from "../../_model/WorksheetItem";
import {WorksheetItemUpdate} from "./WorksheetItemUpdate";

export class WorksheetItemAction {
    updates: WorksheetItemUpdate[] = []
    newItems: WorksheetItem[] = []

    constructor(updates: WorksheetItemUpdate[], newItems: WorksheetItem[]) {
        this.updates = updates
        this.newItems = newItems
    }
}
