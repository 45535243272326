import React from "react";

interface IProps {
    id: string
    resize: () => void
    error: boolean
}
interface IState {

}

export class WDTextboxResizeHandler extends React.Component<IProps, IState> {

    render() {

        let className = "ws-designer-textbox-resize-handler";

        if(this.props.error) {
            className += "-error";
        }

        return <div className={className}
                    onDoubleClick={this.props.resize}>

                    <div className="ws-designer-textbox-resize-handler-ball"/>
                    <div className="ws-designer-textbox-resize-handler-line"/>
        </div>
    }
}
