import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";

/**
 * Checkbox (Yes/No) in Toolbar
 * Effects selected element(s)
 * Only possible if exercise is generated by the logic of the element
 * Yes: if activated (Yes) 0 can be within the calculation
 * No:  only values without 0 are calculated
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    onActionButton: (action: WDToolbarAction, data: any) => void
}

export class WDToolbarButtonZeroCalculation extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    changeZeroCalculation = () => {
        let valuePressed: any = {"zeroCalculation": !this.props.pressed}
        this.props.onActionButton(WDToolbarAction.CHANGE_ZERO_CALCULATION, valuePressed)
    }

    render() {
        return <div className={"checkbox-group"} style={{width: "239px"}}>
            <div >{this.context.translate(translations.toolbar.zero_calculation)}</div>
            <input type={"checkbox"}
                   className={"switch"}
                   id={"chkZero" + this.props.id}
                   value={"chkZero" + this.props.id}
                   disabled={!this.props.clickable}
                   checked={this.props.pressed}
                   onChange={this.changeZeroCalculation}
            />
        </div>
    }
}
