import Entity from "../../_model/Entity";

export default class ImageListItem extends Entity {
    colorProperties: string
    searchTerms: string
    family: string
    collection: string
    url?: string
    owner: string
    createdBy: string
    modifiedOn: Date
    status: string
    rating?: number

    constructor(id: number, name: string, status: string, owner: string, modifiedOn: Date, colorProperties: string,
                searchTerms: string, family: string, collection: string, createdBy: string, url?: string, rating?: number) {
        super(name, id);

        this.url = url
        this.status = status
        this.colorProperties = colorProperties
        this.searchTerms = searchTerms
        this.family = family
        this.collection = collection

        this.rating = rating
        this.owner = owner
        this.modifiedOn = modifiedOn
        this.createdBy = createdBy
    }
}
