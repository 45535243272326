import React from 'react';
import {RibbonButton} from "../Ribbon/RibbonButton";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import {Ribbon} from "../Ribbon/Ribbon";
import {MainContext} from "../../_base/MainContext";
import {AdminObjectType, Status} from "../../Framework/Enums";
import {EntityData} from "../../_model/Entity";
import {AdminStatusConfirmationModal} from "../../Admin/AdminStatusConfirmationModal";
import User from "../../_model/User";
import Auth from "../../Framework/Auth";
import {RibbonButtonData} from "../Ribbon/RibbonButtonData";

interface IProps {
    item: EntityData
    itemType: AdminObjectType

    onSave?: (close: boolean, item?: EntityData) => void
    onCopy?: () => void
    onCancel: () => void
    allowStatusChangeToUserItself: boolean
    customRibbonButtons?: RibbonButtonData[]

    gotoRecord: (index: number) => void
    setStatus?: (status: Status, user?: User, title?: string, comment?: string) => void
}

interface IState {
    changeStatus?: Status
}

export default class FormRibbon extends React.Component<IProps, IState> {

    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {}
    }

    evaluateRibbonButtons = () => {
        let ribbonButtons: RibbonButtonData[] = []

        if (this.props.onSave && (this.isDraft() || this.isPublished() || (this.isSubmittedForApproval() && this.isCurrentUserOwner()))) {
            ribbonButtons.push(new RibbonButtonData(
                "save",
                process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "save.svg",
                10,
                true,
                this.context.translate(translations.command.save),
                undefined,
                () => this.props.onSave!(false)
            ))
            ribbonButtons.push(new RibbonButtonData(
                "save_and_close",
                process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "save_and_close.svg",
                20,
                true,
                this.context.translate(translations.command.save_and_close),
                undefined,
                () => this.props.onSave!(true)
            ))
        }
        if (this.props.onCopy && this.isExisting() && !this.isDeactivated()) {
            ribbonButtons.push(new RibbonButtonData(
                "saveAsCopy",
                process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "save_as_copy.svg",
                30,
                true,
                this.context.translate(translations.command.save_as_copy),
                undefined,
                this.props.onCopy
            ))
        }

        ribbonButtons.push(new RibbonButtonData(
            "cancel",
            process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "terminate.svg",
            40,
            true,
            this.context.translate(translations.command.close),
            undefined,
            this.props.onCancel
        ))

        if (this.props.setStatus) {
            if (this.showDeactivate()) {
                ribbonButtons.push(new RibbonButtonData(
                    "deactivate",
                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "delete.svg",
                    50,
                    true,
                    this.context.translate(translations.command.deactivate),
                    undefined,
                    this.onDeactivate
                ))
            }

            if (this.showActivate()) {
                ribbonButtons.push(new RibbonButtonData(
                    "activate",
                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "checkmark.svg",
                    60,
                    true,
                    this.context.translate(translations.command.activate),
                    undefined,
                    this.onDraft
                ))
            }

            if (this.showPublish()) {
                ribbonButtons.push(new RibbonButtonData(
                    "publish",
                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "checkmark.svg",
                    70,
                    true,
                    this.context.translate(this.isSearchResult() ? translations.command.close : translations.command.publish),
                    undefined,
                    this.onPublish
                ))
            }

            if (this.showApproval()) {
                ribbonButtons.push(new RibbonButtonData(
                    "approval",
                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "checkmark.svg",
                    80,
                    true,
                    this.context.translate(this.isSearchResult() ? translations.command.to_verify : translations.command.approval),
                    undefined,
                    this.onSubmitForApproval
                ))
            }
        }

        ribbonButtons = ribbonButtons.concat(this.props.customRibbonButtons ?? [])

        return ribbonButtons.sort((a, b) => a.sort - b.sort)
    }

    isExisting = () => {
        return (this.props.item.id !== undefined)
    }
    isDraft = () => {
        return (this.props.item.status === Status.draft)
    }
    isPublished = () => {
        return (this.props.item.status === Status.published)
    }
    isSubmittedForApproval = () => {
        return (this.props.item.status === Status.approval)
    }
    isDeactivated = () => {
        return (this.props.item.status === Status.deactivated)
    }

    isCurrentUserCreator = () => {
        return (this.props.item.createdBy?.id === Auth.getUserId())
    }
    isCurrentUserOwner = () => {
        return (this.props.item.ownerId?.id === Auth.getUserId())
    }

    getListIndexCurrent = () => {
        let items = this.context.getListRecords()
        if (items) {
            return items.findIndex(i => i.id === this.props.item.id && i.objectType === this.props.itemType)
        } else {
            return 0
        }
    }
    getListRecordCount = () => {
        const items = this.context.getListRecords()
        return items ? items.length : 1
    }

    onDraft = () => {
        if (this.props.setStatus === undefined) {
            return
        }

        // If it is currently published get comment from user
        if (this.props.item.status === Status.published || this.props.item.status === Status.approval) {
            this.setState({changeStatus: Status.draft})
            return
        }

        // if it is currently deactivated, set status immediately
        if (this.props.item.status === Status.deactivated) {
            this.props.setStatus(Status.draft)
        }
    }
    onDeactivate = () => {
        if (this.props.setStatus === undefined) {
            return
        }

        this.setState({changeStatus: Status.deactivated})
    }
    onSubmitForApproval = () => {
        if (this.props.setStatus === undefined) {
            return
        }

        this.setState({changeStatus: Status.approval})
    }
    onPublish = () => {
        if (this.props.setStatus === undefined) {
            return
        }

        if (this.props.item && this.props.item.status === Status.approval) {
            this.props.setStatus(Status.published, undefined, this.context.translate(translations.text_fragment.status_publish))
        }
    }

    onSubmitStatus = (user?: User, comment?: string) => {
        if (this.props.setStatus === undefined) {
            return
        }

        if (this.props.item) {

            // New state: submit for approval
            if (this.state.changeStatus === Status.approval && this.props.item.status === Status.draft) {

                if (user) {
                    let title = this.context.translate(translations.text_fragment.status_approval_to) + user.firstname + " " + user.lastname

                    this.props.setStatus(Status.approval, user, title, comment)
                    this.setState({changeStatus: undefined})
                }

            }

            // New state: deactivated
            if (this.state.changeStatus === Status.deactivated && this.props.item.status !== Status.deactivated) {
                this.props.setStatus(Status.deactivated, undefined, this.context.translate(translations.text_fragment.status_deactivate), comment)
                this.setState({changeStatus: undefined})
            }

            // New state: draft
            if (this.state.changeStatus === Status.draft) {
                this.props.setStatus(Status.draft, this.props.item.createdBy, this.context.translate(translations.text_fragment.status_draft), comment)
                this.setState({changeStatus: undefined})
            }
        }
    }
    onCancelStatus = () => {
        this.setState({changeStatus: undefined})
    }

    isSearchResult = () => {
        return this.props.itemType === AdminObjectType.search_result
    }

    showActivate = () => {
        return this.isExisting() && !this.isSearchResult() && (this.isDeactivated() || (this.isSubmittedForApproval() && (this.isCurrentUserCreator() || this.isCurrentUserOwner())) || this.isPublished())
    }
    showDeactivate = () => {
        return this.isExisting() && (this.isDraft() || this.isPublished())
    }
    showApproval = () => {
        return this.isExisting() && this.isDraft()
    }
    showPublish = () => {
        return this.isExisting() && (this.isSubmittedForApproval() && this.isCurrentUserOwner())
    }

    onGotoFirst = () => {
        this.props.gotoRecord(0)
    }
    onGotoPrevious = () => {
        const index = this.getListIndexCurrent()
        this.props.gotoRecord(index > 0 ? index - 1 : 0)
    }
    onGotoNext = () => {
        const items = this.context.getListRecords()
        const index = this.getListIndexCurrent()

        const maxIndex = items ? items.length - 1 : 0

        this.props.gotoRecord(index < maxIndex ? index + 1 : maxIndex)
    }
    onGotoLast = () => {
        let items = this.context.getListRecords()
        this.props.gotoRecord(items ? items.length - 1 : 0)
    }

    render() {
        let ribbonButtons: RibbonButtonData[] = this.evaluateRibbonButtons()

        return <>
            <Ribbon userRibbon={false}>
                <div className={"ribbon-buttons"}>
                    {ribbonButtons.map(b => {
                        if (b.shownOnCondition === undefined || b.shownOnCondition()) {
                            return <RibbonButton data={b} key={"ribbon-button" + b.id} colorIconPrimary={true}/>
                        }
                        return undefined
                    })}
                </div>

                {this.context.getListRecords() && this.isExisting() &&
                    <div className={"ribbon-buttons"}>
                        <div className={"ribbon-record-pagination"}>
                            <input type="image"
                                   src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_first.svg"}
                                   onClick={this.onGotoFirst}
                                   alt={this.context.translate(translations.command.first)}/>
                            <input type="image"
                                   src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_previous.svg"}
                                   onClick={this.onGotoPrevious}
                                   alt={this.context.translate(translations.command.prev)}/>

                            <span
                                style={{marginRight: "10px"}}>{this.getListIndexCurrent() + 1} / {this.getListRecordCount()}</span>

                            <input type="image"
                                   src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_next.svg"}
                                   onClick={this.onGotoNext}
                                   alt={this.context.translate(translations.command.next)}/>
                            <input type="image"
                                   src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_last.svg"}
                                   onClick={this.onGotoLast}
                                   alt={this.context.translate(translations.command.last)}/>
                        </div>
                    </div>
                }
            </Ribbon>

            {this.state.changeStatus &&
                <AdminStatusConfirmationModal
                    status={this.state.changeStatus}
                    allowStatusChangeToUserItself={this.props.allowStatusChangeToUserItself}
                    onSave={this.onSubmitStatus}
                    onCancel={this.onCancelStatus}/>
            }
        </>
    }
}
