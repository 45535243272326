import React, {CSSProperties} from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";
import {FloatingHint} from "../Notification/FloatingHint";
import {NotificationStatus} from "../../Framework/Enums";
import {NotificationData} from "../Notification/Hint";

interface IProps {
    id?: string
    value?: number
    clickable: boolean
    required: boolean
    placeholder?: string
    style?: CSSProperties

    width: number
    maxlength: number
    minValue?: number
    maxValue?: number
    stepSize?: number

    unit?: string
    unitWidth?: number
    unitPaddingRight?: number

    onChangeValue: (value: number) => void
}
interface IState {
    value: string
    showList: boolean
    error: string | undefined
}

export class NumberInput extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    propagateChange: boolean = false

    constructor(props: IProps) {
        super(props)

        this.state = {
            value: this.props.value !== undefined ? this.props.value.toString() : "",
            showList: false,
            error: undefined
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.value === undefined && this.state.value !== "") {
            this.setState({value: ""})
        }
        else if (this.props.value !== undefined && !isNaN(this.props.value) && this.props.value !== prevProps.value) {
            this.setState({value: this.props.value!.toString()})
        }
    }

    checkValue = (value: number) => {
        const minValue = (this.props.minValue !== undefined ? this.props.minValue : Number.MIN_VALUE)
        const maxValue = (this.props.maxValue !== undefined ? this.props.maxValue : Number.MAX_VALUE)

        if (value < minValue) {
            this.setState({
                value: minValue.toString(),
                error: this.context.translate(translations.error.number_min_value).replace("%1%", minValue.toString())
            })
            return false
        }

        if (value > maxValue) {
            this.setState({
                value: maxValue.toString(),
                error: this.context.translate(translations.error.number_max_value).replace("%1%", maxValue.toString())
            })

            return false
        }

        return true
    }
    propagateValue = (value: number) => {
        if (this.checkValue(value)) {
            this.setState({
                value: value.toString(),
                error: undefined
            })

            this.props.onChangeValue(value)
        }
    }

    onBlurValue = () => {
        let value = +this.state.value

        if (this.props.value !== value) {
            this.propagateValue(value)
        }
    }
    onChangeValue = (value: string) => {
        const val = +value

        if (!isNaN(val)) {
            if (!this.propagateChange || this.checkValue(val)) {

                this.setState({value: value}, () => {
                    if (this.propagateChange) {
                        this.propagateValue(+this.state.value)
                        this.propagateChange = false
                    }
                })
            }
        }
    }
    onIncreaseValue = () => {
        this.props.clickable && this.propagateValue(+this.state.value + (this.props.stepSize || 1))
    }
    onDecreaseValue = () => {
        this.props.clickable && this.propagateValue(+this.state.value - (this.props.stepSize || 1))
    }
    onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'ArrowUp') {
            event.preventDefault()
            event.stopPropagation()

            this.onIncreaseValue()
        }
        else if (event.key === 'ArrowDown') {
            event.preventDefault()
            event.stopPropagation()

            this.onDecreaseValue()
        }
        else if ((event.key === 'ArrowLeft') || (event.key === 'ArrowRight')) {
            event.stopPropagation()
        }
        else if (event.key === 'Enter') {
            event.stopPropagation()
            event.preventDefault()
            this.onBlurValue()
        }
        else if (event.key === 'Tab') {
            this.onBlurValue()
        }
        else if (event.key === 'Delete' || event.key === 'Backspace') {
            event.stopPropagation()
        }
        else if (event.key.length === 1 && event.key.match("^[0-9]")) {
            event.stopPropagation()
        }
    }
    onWheel = (event: React.WheelEvent) => {
        event.preventDefault()
        event.stopPropagation()

        // If the input field has the focus propagate value change in onChange (this.propagateChange)
        let target = (event.target as HTMLInputElement)
        if (target === document.activeElement) {
            this.propagateChange = true
        }
    }
    onHideError = () => {
        this.setState({error: undefined})
    }

    render () {
        let className = "number-input"
        let classNameImg = "number-input-img"

        className += this.props.clickable ? "" : " ws-designer-toolbar-button-disabled"
        classNameImg += this.props.clickable ? "" : " ws-designer-toolbar-button-disabled"

        let cssProps: CSSProperties = { ...this.props.style} || {}
        cssProps.width = this.props.width

        return <div id={this.props.id} className={className} style={cssProps}>
            <img
                id={"number-input-increase"}
                src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "dropdown_arrow_up.svg"}
                className={classNameImg + " number-input-increase"}
                alt={this.context.translate(translations.command.increase)}
                draggable={"false"}
                onClick={this.props.clickable ? this.onIncreaseValue : () => {}}
                onContextMenu={(e) => e.preventDefault() }
            />
            <img
                id={"number-input-decrease"}
                src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                className={classNameImg + " number-input-decrease"}
                alt={this.context.translate(translations.command.decrease)}
                draggable={"false"}
                onClick={this.props.clickable ? this.onDecreaseValue : () => {}}
                onContextMenu={(e) => e.preventDefault() }
            />

            <input name="value" id="value" type="number" maxLength={this.props.maxlength || 3}
                   style={{
                       width: (this.props.width - (20 + (this.props.unitWidth || 0))),
                       paddingRight: 16 + (this.props.unitWidth || 0) + (this.props.unitPaddingRight && this.props.unitPaddingRight > 0 ? this.props.unitPaddingRight : 0),
                       marginRight: 5
                   }}
                   onChange={this.props.clickable ? (e) => this.onChangeValue(e.target.value) : () => {}}
                   onBlur={this.onBlurValue}
                   onKeyDown={this.onKeyDown}
                   onWheel={this.onWheel}
                   placeholder={this.props.placeholder}
                   required={this.props.required}
                   disabled={!this.props.clickable}
                   value={isNaN(+this.state.value) || this.state.value === "" ? "" : +this.state.value}
            />

            <div className={"number-input-unit"} style={{right: 12 + (this.props.unitPaddingRight || 0)}}>{this.props.unit}</div>

            {this.state.error &&
            <FloatingHint id={"error-number-input"}
                          notificationData={new NotificationData(NotificationStatus.info, this.state.error)}
                          style={{width: "186px", left: "-73%", top: "35px"}}
                          onHide={this.onHideError} />
            }
        </div>
    }
}

