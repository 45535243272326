import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButton} from "../WDToolbarButton";
import {ImagePath} from "../../../../Framework/CategoryImage";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";
import {GlyphData} from "../../../../Framework/GlyphData";

/**
 * Button in Toolbar with submenu to insert a glyph
 */

interface IProps {
    id: number
    width?: number
    height?: number
    open: boolean
    clickable: boolean

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
}

export enum GlyphType {
    math = "math",
    fractions = "fractions",
    letters = "abc",
    numbers = "123",
    smileys = "smile"
}

export class WDToolbarButtonInsertGlyph extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = { }
    }

    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    onClickBookmark = (type: string) => {
        let typeList = document.getElementById("ws-designer-glyph-list")
        if (typeList) {
            let element = document.getElementById("ws-designer-glyph-list-row-" + type)
            let rect = element?.getBoundingClientRect()

            if (rect) {
                typeList.scrollTo({
                    top: rect.top - typeList.getBoundingClientRect().top + typeList.scrollTop,
                    behavior: 'smooth'
                })
            }
        }
    }

    onSelectGlyph = (code: string) => {
        let valuePressed: any = {code: code}
        this.props.onActionButton(WDToolbarAction.INSERT_GLYPH, valuePressed)
    }

    renderGlyphs() {
        let group: JSX.Element[] = []
        let glyphs: JSX.Element[] = []

        let oldType: GlyphType | undefined = undefined
        GlyphData.glyphData.forEach((g, i, arr) => {

            if (g.type !== oldType) {
                if (oldType !== undefined) {
                    group.push(<div id={"ws-designer-glyph-list-row-" + oldType}
                                    key={"ws-designer-glyph-list-row-" + oldType}
                                    className={"ws-designer-glyph-list-row"}>
                        {glyphs.map(c => c)}
                    </div>)
                    glyphs = []
                }

                oldType = g.type
            }

            glyphs.push(<div id={"item-" + oldType + "-" + i}
                             key={"item-" + oldType + "-" + i}
                             className={"ws-designer-glyph-list-item"}
                             onClick={() => this.onSelectGlyph(g.code)}>{g.code}</div>)
        })

        group.push(<div id={"ws-designer-glyph-list-row-" + oldType}
                        key={"ws-designer-glyph-list-row-" + oldType}
                        className={"ws-designer-glyph-list-row"}>{glyphs.map(c => c)}</div>)

        return <div id={"ws-designer-glyph-list"} className={"ws-designer-glyph-list"}>
            {group.map(c => c)}
        </div>
    }
    render() {
        return <li
            key={"ws-designer-toolbar-button"}
            className={"ws-designer-toolbar-item"}
            id={"insertIcon"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + "insert_glyphs.svg"}
                             iconClassSelected={"ws-designer-toolbar-button-img"}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.insert_glyph), this.context.translate(translations.tooltip.insert_glyph))}
                             onToggleButton={this.props.onToggleButton}
                             buttonWidth={28}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.open}
            />

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.insert_glyph), this.context.translate(translations.tooltip.insert_glyph))}

                             iconClassSelected={"ws-designer-toolbar-button-options"}
                             iconClassUnselected={"ws-designer-toolbar-button-options"}

                             arrow={false}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             buttonHeight={32}
                             buttonWidth={15}
                             imageHeight={5}
                             imageWidth={5}

                             onToggleButton={this.props.onToggleButton}
                             pressed={this.props.open}
            />

            {this.props.open &&
                <div className={"ws-designer-toolbar-options"}
                     style={{
                         width: this.props.width || 220,
                         height: this.props.height || 200,
                         paddingTop: 10,
                         overflowY: "hidden"
                     }}>

                    <div className={"ws-designer-options-section-row"}
                         style={{
                             width: this.props.width || 220,
                             height: 30,
                             paddingTop: 10,
                             justifyContent: "space-evenly",
                             marginLeft: -2,
                             marginRight: 10
                         }}>

                        {Object.values(GlyphType).map(type => {
                            return <WDToolbarButton id={this.props.id}
                                                    key={this.props.id + "-" + type}
                                                    icon={ImagePath.getButtonUrl() + type + ".svg"}
                                                    iconClassSelected={"ws-designer-toolbar-button-img"}
                                                    cssClass={"ws-designer-toolbar-button"}
                                                    onToggleButton={() => this.onClickBookmark(type)}
                                                    arrow={false}
                                                    enabled={this.props.clickable}
                                                    pressed={false} />
                        })
                        }
                    </div>

                    {this.renderGlyphs()}
                </div>
            }
        </li>
    }
}
