import React, {RefObject} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Const from "../../Framework/Const";
import translations from "../../Framework/translations.json";
import SelectBox from "../../Components/Controls/SelectBox";
import TextBox from "../../Components/Controls/TextBox";
import {
    CreateWordTopic,
    GetAllActiveWordLookups,
    GetWord,
    GetWordHighlights,
    GetWordTopics
} from "../../_endpoint/WordEndpoint";
import {
    AdminObjectType,
    Difficulty,
    EntityArrayToEnumValues,
    EnumToEntityArray,
    EnumValuesToEntityArray,
    EnumValueToValueNumber,
    Status
} from "../../Framework/Enums";
import CheckBoxSwitch from "../../Components/Controls/CheckBoxSwitch";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {MainContext} from "../../_base/MainContext";
import AdminDataForm from "../Maintenance/AdminDataForm";
import Word, {SearchPosition, SyllableMethod, WordDefinition} from "../../_model/Dictionary/Word";
import CheckBoxList from "../../Components/Controls/CheckBoxList";
import Entity from "../../_model/Entity";
import NounFormPart from "./Noun/NounFormPart";
import {WordType} from "./WordType";
import User from "../../_model/User";
import FormHeader from "../../Components/Form/FormHeader";
import Auth from "../../Framework/Auth";
import WordLookup from "../../_model/Dictionary/WordLookup";
import Tag from "../../_model/Tag";
import AdjectiveFormPart from "./Adjective/AdjectiveFormPart";
import WordHighlight from "../../_model/Dictionary/WordHighlight";
import DualList from "../../Components/Controls/DualList";
import VerbFormPart from "./Verb/VerbFormPart";
import ArticleFormPart from "./Article/ArticleFormPart";
import {WordDuplicate} from "./WordDuplicate";
import PronounFormPart from "./Pronoun/PronounFormPart";
import NumeralFormPart from "./Numeral/NumeralFormPart";
import AdverbFormPart from "./Adverb/AdverbFormPart";
import PrepositionFormPart from "./Preposition/PrepositionFormPart";
import ConjunctionFormPart from "./Conjunction/ConjunctionFormPart";
import InterjectionFormPart from "./Interjection/InterjectionFormPart";
import FusionFormPart from "./Fusion/FusionFormPart";
import WordTopic from "../../_model/Dictionary/WordTopic";
import ProperNameFormPart from "./ProperName/ProperNameFormPart";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> { }

interface IState {
    item: Word
    syllables: string[]
    unsavedChanges: boolean
    newWord: boolean
    wordTypeRef?: RefObject<WordType>

    showSimilarWords: boolean

    wordLookups: WordLookup[]
    syllableFilteredWords: WordLookup[]
    definitionSyllableFilteredWords: WordLookup[]
    chosenSynonymTags: Tag[]
    chosenWordLinkTags: Tag[]
    wordHighlights: any[]
    chosenWordHighlights: any[]

    wordTopics?: WordTopic[]
}

export default class WordForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    wordHighlights: WordHighlight[] = []

    constructor(props: MatchProps, state: IState) {
        super(props, state)

        this.state = {
            item: new Word("", Status.draft),
            syllables: [],
            unsavedChanges: false,
            showSimilarWords: true,
            newWord: true,
            wordTypeRef: React.createRef<WordType>(),
            wordLookups: [],
            definitionSyllableFilteredWords: [],
            syllableFilteredWords: [],
            chosenSynonymTags: [],
            chosenWordLinkTags: [],
            wordHighlights: [],
            chosenWordHighlights: []
        }
    }

    componentDidMount() {
        if(this.props.match.params.id !== Const.CreateNewDataUrl) {
            this.fetchData()
        }
        this.fetchWordHighlights()
        this.fetchWordTopics()
    }
    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchData()
        }
    }

    fetchData = () => {
        GetWord(+this.props.match.params.id).then(
            (itemData) => {
                if(itemData.status === Status.published) {
                    this.context.setNotification(Notification.handleInfo(this.context.translate(translations.text.data_published_info)))
                }

                let syllablesSaved = this.fetchSyllables(itemData)
                this.fetchWordLookups(itemData)

                this.setState({
                    item: itemData,
                    syllables: syllablesSaved,
                    showSimilarWords: false,
                    newWord: false,
                    chosenWordHighlights: itemData.wordHighlights ? itemData.wordHighlights.map(entry => {return entry.id}) : []},
                    () =>
                        // load specific information for word (noun, verb, ...)
                        this.state.wordTypeRef?.current?.fetchData()
                )
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchSyllables = (itemData: Word) : string[] => {
        let values: string[] = []
        itemData.syllableSpeak && values.push(SyllableMethod.syllableSpeak)
        itemData.syllableDivide && values.push(SyllableMethod.syllableDivide)
        return values
    }
    fetchWordLookups = (word: Word) => {
        GetAllActiveWordLookups().then(
            (data) => {
                // get all words with the correct syllable methods
                let wordsFetched = data.filter(value => value.id !== word.id)
                let syllableFilteredWords = this.filterWordLookupsBySyllable(wordsFetched, word)
                let chosenWordLinks = WordForm.mapLookupToTag(word.wordLinks, syllableFilteredWords)

                if(word.wordDefinition) {
                    let definitionAndSyllableFilteredWords = this.getWordDefinitionWords(word.wordDefinition!, syllableFilteredWords)
                    let chosenSynonyms = WordForm.mapLookupToTag(word.synonymLinks, definitionAndSyllableFilteredWords)

                    this.setState({
                        wordLookups: wordsFetched,
                        definitionSyllableFilteredWords: definitionAndSyllableFilteredWords,
                        syllableFilteredWords: syllableFilteredWords,
                        chosenSynonymTags: chosenSynonyms,
                        chosenWordLinkTags: chosenWordLinks})
                } else {
                    this.setState({
                        wordLookups: wordsFetched,
                        syllableFilteredWords: syllableFilteredWords,
                        chosenWordLinkTags: chosenWordLinks
                    })
                }
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchWordHighlights = () => {
        GetWordHighlights().then(
            (data) => {
                this.wordHighlights = data
                this.setState({wordHighlights: data.map((entry) => {
                            return {value: entry.id, label: entry.name}})})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchWordTopics = () => {
        GetWordTopics().then(
            (wordTopics) => {
                this.setState({ wordTopics: wordTopics })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    filterWordLookupsBySyllable = (wordLookups: WordLookup[], word: Word) : WordLookup[] => {
        return wordLookups.filter(value =>
            (value.syllableSpeak === true && word.syllableSpeak === true)
            || (value.syllableDivide && word.syllableDivide))
    }

    isFormValid = () => {
        const form1 = document.getElementById("formData1")  as HTMLFormElement;
        const form2 = document.getElementById("formData2")  as HTMLFormElement;
        return form1 && form1.reportValidity() && form2 && form2.reportValidity()
    }

    onSave = async (close: boolean) => {

        if (this.isFormValid()) {
            try {
                let itemToSave = this.state.item

                //Set selection as wordHighlight for item
                if(this.state.chosenWordHighlights) {
                    itemToSave.wordHighlights = this.wordHighlights.filter (
                        item => this.state.chosenWordHighlights.find(entry => entry === item.id) !== undefined
                    )
                }

                // Added new topic, needs to be created
                let wordTopics: WordTopic[] = []
                if (itemToSave.wordTopics) {
                    wordTopics = itemToSave.wordTopics.filter(i => i.id !== undefined)
                    for (const i1 of itemToSave.wordTopics.filter(i => i.id === undefined)) {
                        wordTopics.push(await CreateWordTopic(i1));
                    }
                }
                itemToSave.wordTopics = wordTopics

                let entityData = await this.state.wordTypeRef?.current?.onSave(itemToSave) // save specific word information (noun, verb, ...)

                if(entityData) {
                    let savedWord = this.state.item
                    savedWord.id = entityData.id
                    savedWord.status = entityData.status
                    savedWord.modifiedOn = entityData.modifiedOn
                    savedWord.modifiedBy = entityData.modifiedBy
                    savedWord.createdOn = entityData.createdOn
                    savedWord.createdBy = entityData.createdBy
                    savedWord.ownerId = entityData.ownerId

                    this.setState({item: savedWord, unsavedChanges: false}, () => {
                        if (close) { this.onCancel() }
                    })
                }

                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(path || "/maintenance/dictionary")
        )
    }
    onSetState = (status: Status, user?: User) => {
        if(this.state.item) {
            const item = this.state.item

            Word.setStatus(item, status, user)
            this.setState({item: item}, () => this.onSave(false))
        }
    }

    onChangeString = (value: string, field: string) => {
        let currentItem = this.state.item
        currentItem[field] = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeWordDefinition = (value: number) => {
        // Get enum value by index (value)
        let enumValue = Object.values(WordDefinition).find((e, i) => i === value)
        if (enumValue) {
            let currentItem = this.state.item
            currentItem.wordDefinition = enumValue

            this.fetchWordLookups(currentItem)

            let newSynonymWords = this.getWordDefinitionWords(enumValue, this.state.wordLookups)

            this.setState({item: currentItem, unsavedChanges: true, definitionSyllableFilteredWords: newSynonymWords})
        }
    }
    onChangeEnum = (value: number, enumType: any, itemProperty: string) => {
        // Get enum value by index (value)
        let enumValue = Object.values(enumType).find((e, i) => i === value)
        if (enumValue) {
            let currentItem = this.state.item
            currentItem[itemProperty] = enumValue
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeCheckbox = (fieldName: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = !currentItem[fieldName]
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeSyllable = (values: Entity[]) => {
        let item = this.state.item
        item.syllableDivide = !!values.find(syllable => syllable.id === 0)
        item.syllableSpeak = !!values.find(syllable => syllable.id === 1)

        // delete all synonyms and word links
        item.synonymLinks = []
        item.wordLinks = []

        let syllableFilteredWordLookups = this.filterWordLookupsBySyllable(this.state.wordLookups, item)
        let definitionSyllableFilteredWordLookups = item.wordDefinition
            ? this.getWordDefinitionWords(item.wordDefinition, syllableFilteredWordLookups)
            : this.state.definitionSyllableFilteredWords

        this.setState({
            item: item,
            syllableFilteredWords: syllableFilteredWordLookups,
            chosenSynonymTags: [],
            chosenWordLinkTags: [],
            definitionSyllableFilteredWords: definitionSyllableFilteredWordLookups,
            syllables: EntityArrayToEnumValues(values, SyllableMethod)})
    }
    onChangeTags = (tags: Tag[], synonym: boolean) => {
        let currentItem = this.state.item

        if(synonym) {
            currentItem.synonymLinks = tags.map(i => new WordLookup(i.name, i.id))
            this.setState({item: currentItem, chosenSynonymTags: tags, unsavedChanges: true})
        } else {
            currentItem.wordLinks = tags.map(i => new WordLookup(i.name, i.id))
            this.setState({item: currentItem, chosenWordLinkTags: tags, unsavedChanges: true})
        }
    }
    onChangeHighlights = (selected) => {
        this.setState({ chosenWordHighlights: selected , unsavedChanges: true});
    }
    onChangeWordTopic = (wordTopics: WordTopic[]) => {
        let currentItem = this.state.item
        currentItem.wordTopics = wordTopics
        this.setState({item: currentItem, unsavedChanges: true})
    }

    autoSelectHighlights = () => {
        if(this.state.item.name && this.wordHighlights) {
            let foundHighlights: WordHighlight[] = []

            this.wordHighlights.forEach(highlight => {
                if(highlight.value) {
                    switch (highlight.searchPosition) {

                        case SearchPosition.start:
                            if(this.state.item.name.startsWith(highlight.value)) {

                                if(this.isSpecificHighlightConditionReached(
                                    this.state.item.name,
                                    highlight.value,
                                    this.state.item.name.indexOf(highlight.value))) {

                                    foundHighlights.push(highlight)
                                }
                            }
                            break

                        case SearchPosition.end:
                            if(this.state.item.name.endsWith(highlight.value)) {

                                if(this.isSpecificHighlightConditionReached(
                                    this.state.item.name,
                                    highlight.value,
                                    this.state.item.name.indexOf(highlight.value))) {

                                    foundHighlights.push(highlight)
                                }
                            }
                            break

                        case SearchPosition.everywhere:
                            if(highlight.id === 101) {
                                // Dreifachkonsonant
                                if(this.state.item.name.match(/^.*(.)\1\1/)) {
                                    foundHighlights.push(highlight)
                                }
                            }
                            else if (this.state.item.name.includes(highlight.value)) {

                                if(this.isSpecificHighlightConditionReached(
                                    this.state.item.name,
                                    highlight.value,
                                    this.state.item.name.indexOf(highlight.value))) {

                                    foundHighlights.push(highlight)
                                }
                            }
                            break

                        case SearchPosition.except_start:
                            if(this.state.item.name.includes(highlight.value, 1)) {

                                if(this.isSpecificHighlightConditionReached(
                                    this.state.item.name,
                                    highlight.value,
                                    this.state.item.name.indexOf(highlight.value))) {

                                    foundHighlights.push(highlight)
                                }
                            }
                            break

                        case SearchPosition.middle:
                            let nameMiddle = this.state.item.name.substring(1, this.state.item.name.length -1)
                            if(nameMiddle.includes(highlight.value)) {

                                if(this.isSpecificHighlightConditionReached(
                                    this.state.item.name,
                                    highlight.value,
                                    this.state.item.name.indexOf(highlight.value))) {

                                    foundHighlights.push(highlight)
                                }
                            }
                            break
                    }
                }
            })
            let mappedHighlights = foundHighlights.map((entry) => {return entry.id})
            this.setState({chosenWordHighlights: mappedHighlights, unsavedChanges: true})

        } else {
            this.context.setNotification(Notification.handleInfo(this.context.translate(translations.notification.fill_in_word)))
        }
    }
    isSpecificHighlightConditionReached = (word: string, highlightValue: string, positionOfHighlight: number) : boolean => {
        switch (highlightValue) {
            case "ch":
                if(positionOfHighlight - 1 >= 0) {
                    let charBeforeHighlight = word.charAt(positionOfHighlight-1)
                    if(charBeforeHighlight === "s") {
                        return false
                    }
                }
                break

            case "chs":
                if(positionOfHighlight - 1 >= 0) {
                    let charBeforeHighlight = word.charAt(positionOfHighlight-1)
                    if(charBeforeHighlight === "s") {
                        return false
                    }
                }
                break

            case "h":
                if(positionOfHighlight - 1 >= 0) {
                    let charBeforeHighlight = word.charAt(positionOfHighlight-1)
                    if(charBeforeHighlight === "c") {
                        return false
                    }
                }
                break
        }

        return true
    }

    static mapLookupToTag = (wordLookups?: WordLookup[], searchInValues?: WordLookup[]) : Tag[] => {
        let chosenTags: Tag[] = []

        if (wordLookups && searchInValues) {
            wordLookups.forEach(value => {
                let foundValue = searchInValues.find(searchInValue => searchInValue.id === value.id)
                if (foundValue) {
                    chosenTags.push(foundValue)
                }
            })
        }

        return chosenTags
    }
    getWordDefinitionWords = (wordDefinition: WordDefinition, wordLookups: WordLookup[]) : WordLookup[] => {
        let wordDefinitionWords: WordLookup[] = []

        wordLookups.forEach(word => {
            if(word.wordDefinition === wordDefinition) {
                wordDefinitionWords.push(word)
            }
        })

        return wordDefinitionWords
    }

    getAuthor = () => {
        if (this.state.item.createdBy) {
            return this.state.item.createdBy.firstname + " " + this.state.item.createdBy.lastname
        }
        return ""
    }

    isReadonly = () => {
        return this.state.item.status === Status.deactivated || (this.state.item.status === Status.approval && this.state.item.ownerId?.id !== Auth.getUserId())
    }

    render() {
        let wordDuplicates = this.state.wordLookups
            .filter(w => w.name === this.state.item.name && w.id !== this.state.item.id)

        let reducedSyllableOptions = EnumToEntityArray(SyllableMethod, translations.enum.syllable, this.context.translate).filter(value => {
            return value.name !== this.context.translate(translations.enum.syllable.noSyllable)
        })

        return <AdminDataForm
            onSave={this.onSave}
            onCancel={this.onCancel}
            onSetState={this.onSetState}
            allowStatusChangeToUserItself={false}
            item={this.state.item}
            itemType={AdminObjectType.word}
            hasUnsavedChanges={this.state.unsavedChanges}

            history={this.props.history}
            location={this.props.location}
            match={this.props.match}>

                <div className={"admin-form-content"}>

                    <form id={"formData1"} className="admin-form-input" onSubmit={() => {
                        return false
                    }}>

                        <FormHeader
                            number={this.state.item.id}
                            status={this.state.item.status}
                            statusTranslationPath={translations.enum.status}
                            createdBy={this.state.item.createdBy}
                            ownerId={this.state.item.ownerId}
                        />

                        <div className="form-row">
                            <TextBox id={"txtWord"}
                                     width={400}
                                     label={this.context.translate(translations.fields.word.word)}
                                     required={true}
                                     readonly={this.isReadonly()}
                                     onChange={(value) => this.onChangeString(value, "name")}
                                     value={this.state.item.name}
                            />

                            <SelectBox id={"selWordDefinition"}
                                       width={400}
                                       label={this.context.translate(translations.fields.word.wordDefinition)}
                                       data={EnumToEntityArray(WordDefinition, translations.enum.wordDefinition, this.context.translate)}
                                       required={true}
                                       value={EnumValueToValueNumber(WordDefinition, this.state.item.wordDefinition)}
                                       readonly={this.isReadonly() || !this.state.newWord}
                                       onChange={(value) => this.onChangeWordDefinition(value)}
                                       positioningRow={false}
                            />
                        </div>

                        {this.state.showSimilarWords && wordDuplicates.length > 0 &&
                            <div className="form-row">
                                <div className="form-group" style={{width: "800px"}}>
                                    <div className={"word-duplicate-container"}>
                                        {wordDuplicates.map(w => <WordDuplicate key={w.id} word={w}/>)}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={"form-row"}>
                            <div className={"form-column"}>
                                <CheckBoxList
                                    id={"ckbSyllable"}
                                    width={264}
                                    label={this.context.translate(translations.fields.word.syllable)}
                                    data={reducedSyllableOptions}
                                    selectedData={EnumValuesToEntityArray(SyllableMethod, this.state.syllables || [], translations.enum.syllable, this.context.translate)}
                                    onChange={this.onChangeSyllable}
                                    required={true}
                                    readonly={this.isReadonly()}/>
                            </div>

                            <div className={"form-column"}>
                                <div className={"form-group"}>
                                    <div
                                        className={"bold-label"}>{this.context.translate(translations.fields.word.additional_infos)}</div>
                                    <CheckBoxSwitch id={"ckbForeign"}
                                                    width={264}
                                                    style={{paddingRight: "20px"}}
                                                    label={this.context.translate(translations.fields.word.foreign)}
                                                    checked={this.state.item.foreignWord}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeCheckbox("foreignWord")}
                                    />

                                    <CheckBoxSwitch id={"ckbException"}
                                                    width={264}
                                                    style={{paddingRight: "20px"}}
                                                    label={this.context.translate(translations.fields.word.exception)}
                                                    checked={this.state.item.exceptionWord}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeCheckbox("exceptionWord")}
                                    />

                                    <CheckBoxSwitch id={"ckbBasicVocabulary"}
                                                    width={264}
                                                    style={{paddingRight: "20px"}}
                                                    label={this.context.translate(translations.fields.word.basic_vocabulary)}
                                                    checked={this.state.item.basicVocabulary}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeCheckbox("basicVocabulary")}
                                    />

                                    <CheckBoxSwitch id={"ckbPhonemic"}
                                                    width={264}
                                                    style={{paddingRight: "20px"}}
                                                    label={this.context.translate(translations.fields.word.phonemic)}
                                                    checked={this.state.item.phonemic}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeCheckbox("phonemic")}
                                    />

                                    <CheckBoxSwitch id={"ckbShowInDictionary"}
                                                    width={264}
                                                    style={{paddingRight: "20px"}}
                                                    label={this.context.translate(translations.fields.word.show_in_dictionary)}
                                                    checked={this.state.item.showInDictionary}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeCheckbox("showInDictionary")}
                                    />
                                </div>
                            </div>

                            <div className={"form-column"}>
                                <SelectBox id={"selDifficulty"}
                                           width={264}
                                           label={this.context.translate(translations.fields.word.difficulty)}
                                           data={EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate)}
                                           required={true}
                                           value={EnumValueToValueNumber(Difficulty, this.state.item.difficulty)}
                                           readonly={this.isReadonly()}
                                           onChange={(value) => this.onChangeEnum(value, Difficulty, "difficulty")}
                                           positioningRow={false}
                                />
                            </div>
                        </div>

                        <div className="form-row" style={{marginBottom: "40px"}}>
                            <TextBox id={"txtDescriptionSimple"}
                                     width={800}
                                     multiline={true}
                                     label={this.context.translate(translations.fields.word.description_simple)}
                                     required={false}
                                     readonly={this.isReadonly()}
                                     onChange={(value) => this.onChangeString(value, "descriptionSimple")}
                                     value={this.state.item.descriptionSimple || ""}
                            />
                        </div>
                    </form>

                    <div className={"form-row"}>
                        <DualList
                            options={this.state.wordHighlights}
                            selected={this.state.chosenWordHighlights}
                            width={700}
                            marginTop={0}
                            marginLeft={0}
                            disabled={this.isReadonly()}
                            label={this.context.translate(translations.fields.word.word_highlights)}
                            onChange={this.onChangeHighlights}
                        />

                        <input id="btnHighlightsRefresh"
                               className={"form-button"}
                               type={"button"}
                               style={{
                                   alignSelf: "flex-start",
                                   width: "100px",
                                   textAlign: "center"
                               }}
                               value={this.context.translate(translations.text_fragment.prefill)}
                               disabled={this.isReadonly()}
                               onClick={this.autoSelectHighlights}
                        />
                    </div>

                    <div className={"form-row"}>
                        <TextBox id={"txtWordTopics"}
                                 width={800}
                                 label={this.context.translate(translations.fields.word.word_topics)}
                                 required={false}
                                 readonly={this.isReadonly()}
                                 data={this.state.wordTopics}
                                 tags={this.state.item.wordTopics || []}
                                 onChangeTags={this.onChangeWordTopic}
                                 mode={{autoComplete: true, tags: true}}
                                 value={""}
                        />
                    </div>

                    <form id={"formData2"} className="admin-form-input" onSubmit={() => { return false} }>

                        {this.state.item.wordDefinition !== WordDefinition.article &&
                            this.state.item.wordDefinition !== WordDefinition.fusions &&
                            <div className={"form-row"}>
                                <TextBox id={"txtSynonyms"}
                                         width={800}
                                         label={this.context.translate(translations.fields.word.synonyms)}
                                         required={false}
                                         readonly={this.isReadonly()}
                                         data={this.state.definitionSyllableFilteredWords}
                                         tags={this.state.chosenSynonymTags}
                                         onChangeTags={(tags) => this.onChangeTags(tags, true)}
                                         mode={{autoComplete: true, tags: true, restrictCreation: true}}
                                         value={""}
                                />
                            </div>
                        }
                        {this.state.item.wordDefinition !== WordDefinition.article &&
                            this.state.item.wordDefinition !== WordDefinition.fusions &&
                            <div className={"form-row"}>
                                <TextBox id={"txtLinks"}
                                         width={800}
                                         label={this.context.translate(translations.fields.word.word_links)}
                                         required={false}
                                         readonly={this.isReadonly()}
                                         data={this.state.syllableFilteredWords}
                                         tags={this.state.chosenWordLinkTags}
                                         onChangeTags={(tags) => this.onChangeTags(tags, false)}
                                         mode={{autoComplete: true, tags: true, restrictCreation: true}}
                                         value={""}
                                />
                            </div>
                        }

                        {this.state.item.wordDefinition === WordDefinition.noun &&
                        <NounFormPart wordId={this.state.item.id}
                                      readonly={this.isReadonly()} ref={this.state.wordTypeRef as RefObject<NounFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.adjective && this.state.definitionSyllableFilteredWords.length > 0 &&
                        <AdjectiveFormPart wordId={this.state.item.id}
                                           wordLookups={this.state.definitionSyllableFilteredWords}
                                           readonly={this.isReadonly()}
                                           ref={this.state.wordTypeRef as RefObject<AdjectiveFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.verb &&
                        <VerbFormPart wordId={this.state.item.id}
                                       readonly={this.isReadonly()}
                                       ref={this.state.wordTypeRef as RefObject<VerbFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.article &&
                        <ArticleFormPart wordId={this.state.item.id}
                                      readonly={this.isReadonly()}
                                      ref={this.state.wordTypeRef as RefObject<ArticleFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.pronoun &&
                        <PronounFormPart wordId={this.state.item.id}
                                         readonly={this.isReadonly()}
                                         ref={this.state.wordTypeRef as RefObject<PronounFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.numeral &&
                        <NumeralFormPart wordId={this.state.item.id}
                                         readonly={this.isReadonly()}
                                         ref={this.state.wordTypeRef as RefObject<NumeralFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.adverb &&
                        <AdverbFormPart wordId={this.state.item.id}
                                         readonly={this.isReadonly()}
                                         ref={this.state.wordTypeRef as RefObject<AdverbFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.preposition &&
                        <PrepositionFormPart wordId={this.state.item.id}
                                        readonly={this.isReadonly()}
                                        ref={this.state.wordTypeRef as RefObject<PrepositionFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.conjunction &&
                        <ConjunctionFormPart wordId={this.state.item.id}
                                             readonly={this.isReadonly()}
                                             ref={this.state.wordTypeRef as RefObject<ConjunctionFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.interjection &&
                        <InterjectionFormPart wordId={this.state.item.id}
                                             readonly={this.isReadonly()}
                                             ref={this.state.wordTypeRef as RefObject<InterjectionFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.fusions &&
                        <FusionFormPart wordId={this.state.item.id}
                                             readonly={this.isReadonly()}
                                             ref={this.state.wordTypeRef as RefObject<FusionFormPart>}/>
                        }
                        {this.state.item.wordDefinition === WordDefinition.properName &&
                        <ProperNameFormPart wordId={this.state.item.id}
                                        readonly={this.isReadonly()}
                                        ref={this.state.wordTypeRef as RefObject<ProperNameFormPart>}/>
                        }

                    </form>

                </div>
        </AdminDataForm>
    }
}

