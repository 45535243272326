import React from "react";
import {WorksheetPage} from "../../../../_model/WorksheetPage";
import {WDToolbarButton} from "../WDToolbarButton";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {PageBorderPosition, WDPageBorder} from "../../../../Components/PageBorder/WDPageBorder";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button (with submenu) in Toolbar
 * Effects page border
 * Includes the distance to the page end in each direction (top, right, left, bottom)
 * Change Input:   The distance changes (for each or separately depending on toggle button)
 * Toggle Button:  Open - distances can be changes separately, Closed - all distances are effected by the change
 */

export enum PageBorderDirection {
    left,
    top,
    right,
    bottom
}

interface IProps {
    id: number
    showDialog: boolean
    clickable: boolean
    worksheetPage: WorksheetPage

    onToggleButton: (id: number) => void
    onChangePageSettings: (id: number, sheet: WorksheetPage) => void
}

export class WDToolbarButtonPageBorder extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onChangeBorder = (border: PageBorderPosition) => {
        let b: WorksheetPage = this.props.worksheetPage;

        b.borderLeft = border.left
        b.borderRight = border.right
        b.borderTop = border.top
        b.borderBottom = border.bottom
        b.linkBorders = border.link

        this.props.onChangePageSettings(this.props.worksheetPage.id!, b)
    }

    render() {
        return <div>
            <li className={"ws-designer-toolbar-item"}>
                <WDToolbarButton id={this.props.id}
                                         icon={ImagePath.getPageUrl() + "page_border.svg"}
                                         tooltip={new TooltipText(this.context.translate(translations.text.worksheet_settings.page_border))}
                                         onToggleButton={this.props.onToggleButton}
                                         arrow={true}
                                         enabled={this.props.clickable}
                                         pressed={this.props.showDialog} />

            {this.props.showDialog &&
            <div className={"ws-designer-toolbar-options"} style={{padding: "10px"}}>

                <WDPageBorder border={{
                    left: this.props.worksheetPage.borderLeft,
                    top: this.props.worksheetPage.borderTop,
                    right: this.props.worksheetPage.borderRight,
                    bottom: this.props.worksheetPage.borderBottom,
                    link: this.props.worksheetPage.linkBorders
                }} linkReadOnly={!this.props.clickable} smallRendering={true} onChangeBorder={this.onChangeBorder} />

            </div>
            }
            </li>

        </div>
    }
}

