import React, {PropsWithChildren, RefObject} from "react";
import AppHeader from "../../_base/AppHeader";
import {Menu, MenuContext, MenuType} from "../../Components/Menu/Menu";
import FormRibbon from "../../Components/Form/FormRibbon";
import {Prompt, RouteComponentProps} from "react-router-dom";
import {UnsavedChangesModal} from "../../Components/Notification/UnsavedChangesModal";
import {AdminObjectType, Status} from "../../Framework/Enums";
import {EntityData} from "../../_model/Entity";
import {NoteList} from "../Notes/NoteList";
import Note from "../../_model/Note";
import User from "../../_model/User";
import translations from "../../Framework/translations.json";
import {CreateNote} from "../../_endpoint/NoteEndpoint";
import {MainContext} from "../../_base/MainContext";
import {AdminUtils} from "./AdminUtils";
import {RibbonButtonData} from "../../Components/Ribbon/RibbonButtonData";
import {Modal} from "../../Components/Modal";
import {ButtonInfo} from "../../Components/Controls/ButtonList";

interface IProps extends RouteComponentProps, PropsWithChildren {
    id?: string
    item: EntityData
    itemType: AdminObjectType

    onSave?: (close: boolean) => any
    onCopy?: () => void
    onCancel: (path?: string) => any
    onSetState?: (status: Status, user?: User) => boolean | void
    allowStatusChangeToUserItself: boolean
    customRibbonButtons?: RibbonButtonData[]

    hasUnsavedChanges: boolean
}

interface IState {
    showCloseDialog: boolean
    showStateDialog: boolean
    location?: string
    notesRef: RefObject<NoteList>
}

export default class AdminDataForm extends React.Component<IProps, IState> {

    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            showCloseDialog: false,
            showStateDialog: false,
            notesRef: React.createRef<NoteList>()
        }
    }

    blockNavigation = (l) => {
        this.setState({showCloseDialog: true, location: l.pathname});
        return false
    }

    getNotesObjectType = () => {
        return this.props.itemType === AdminObjectType.marketplace_worksheet_admin ? AdminObjectType.marketplace_worksheet : this.props.itemType
    }

    onSetState = (status: Status, user?: User, title?: string, comment?: string) => {
        if (this.props.onSetState === undefined) {
            return
        }

        if (this.props.hasUnsavedChanges) {
            this.setState({showStateDialog: true})
            return
        }

        try {
            // Set state on record (populate
            let valid = this.props.onSetState(status, user)
            if (!valid) {
                return
            }

            // Create note if user and comment are provided
            if (title) {
                let note = new Note(title, comment || "", this.props.item.id!, this.props.itemType, true)
                CreateNote(note).then(
                    () => {
                        this.state.notesRef.current?.refresh()
                    },
                    (error) => {
                        this.context.handleError(error, this.context.translate(translations.notification.saving_error))
                    })
            }
        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.invalid_status_change_error))
        }
    }

    closeDialogInteraction = async (save: boolean, saveDecision: boolean) => {
        if(save) {
            this.props.onSave?.(true)
        }
        else {
            this.props.onCancel(this.state.location)
        }
    }
    cancelSaveDialog = () => {
        this.setState({showCloseDialog: false})
    }
    cancelStateDialog = () => {
        this.setState({showStateDialog: false})
    }

    gotoRecord = (index: number) => {
        this.context.log.info("Go to record Index = " + index)
        const records = this.context.getListRecords()
        if (records && records.length > index) {
            const url = AdminUtils.getObjectTypeUrl(records[index].objectType)

            this.context.log.info("Go to record Url = " + url + records[index].id)
            this.context.log.flush()

            this.props.history.push(url + records[index].id)
        }
    }

    render() {
        return <div className="admin-form" id={this.props.id}>
            <AppHeader isAdminArea={true}/>

            <div className="menu-container">
                <Menu menuType={MenuType.app} menuContext={MenuContext.admin}/>
            </div>

            <div className="admin-form-main">
                <FormRibbon
                    onSave={this.props.onSave}
                    onCopy={this.props.onCopy}
                    onCancel={this.props.onCancel}
                    allowStatusChangeToUserItself={this.props.allowStatusChangeToUserItself}
                    setStatus={this.props.onSetState ? this.onSetState : undefined}
                    gotoRecord={this.gotoRecord}
                    item={this.props.item}
                    itemType={this.props.itemType}
                    customRibbonButtons={this.props.customRibbonButtons}
                />

                {/* Show unsaved changes dialog if there are unsaved changes in children components */}
                <Prompt when={this.props.hasUnsavedChanges} message={(l, a) => this.blockNavigation(l)}/>
                {this.state.showCloseDialog &&
                <UnsavedChangesModal checkSaveDecision={this.closeDialogInteraction} showSaveAlwaysCheckbox={false} cancel={this.cancelSaveDialog}/>
                }

                <div className={"admin-form-container"}>
                    <div className={"admin-form-data"}>
                        {/* Use the top code as template for the children below */}
                        {this.props.children}
                    </div>

                    {/* List of notes */}
                    {this.props.item.id &&
                    <NoteList objectId={this.props.item.id} objectType={this.getNotesObjectType()} ref={this.state.notesRef} />
                    }
                </div>

                {/* Show unsaved changes dialog if state change should be done before saving*/}
                {this.state.showStateDialog &&
                    <Modal id={"stateDialog"}
                           onFormSubmit={this.cancelStateDialog}
                           title={this.context.translate(translations.text.unsaved_changes)}
                           buttons={[
                               new ButtonInfo("btnSave", "button button-save", "submit", true, false, this.context.translate(translations.command.ok), undefined, {marginLeft: "10px"})
                           ]}
                           dialogStyle={{width: "15%", height: "200px", minWidth: "400px"}}
                           contentAlignment={"flex-start"}
                    >
                        {this.context.translate(translations.text.save_unsaved_changes)}
                    </Modal>
                }

            </div>
        </div>
    }
}
