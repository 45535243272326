import API from "../Framework/API";
import Word, {SyllableMethod, WordDefinition} from "../_model/Dictionary/Word";
import Noun, {GenderEnum} from "../_model/Dictionary/Noun";
import PossessivePronouns from "../_model/Dictionary/PossessivePronouns";
import WordLookup from "../_model/Dictionary/WordLookup";
import Adjective from "../_model/Dictionary/Adjective";
import AdjectiveUseCases from "../_model/Dictionary/AdjectiveUseCases";
import WordHighlight from "../_model/Dictionary/WordHighlight";
import {EntityData} from "../_model/Entity";
import Verb from "../_model/Dictionary/Verb";
import Article from "../_model/Dictionary/Article";
import Pronoun from "../_model/Dictionary/Pronoun";
import Numeral from "../_model/Dictionary/Numeral";
import Adverb from "../_model/Dictionary/Adverb";
import Preposition from "../_model/Dictionary/Preposition";
import Conjunction from "../_model/Dictionary/Conjunction";
import Interjection from "../_model/Dictionary/Interjection";
import Dictionary from "../_model/Dictionary/Dictionary";
import Fusion from "../_model/Dictionary/Fusion";
import {WordFilter} from "../Designer/Sidebar/SidebarDictionary/SidebarDictionary";
import WordTopic from "../_model/Dictionary/WordTopic";
import WordKit from "../_model/Dictionary/WordKit";
import Syllable from "../_model/Dictionary/Syllable";
import ProperName from "../_model/Dictionary/ProperName";

const URL = "words"

export function GetAllWordsAdmin() {
    let url = URL + "/"
    return API.Get<Word[]>(url)
}
export function GetWord(wordId: number) {
    let url = URL + "/" + wordId
    return API.Get<Word>(url)
}
export async function UpdateWord(word: Word) {
    return API.Put<object, Word>(URL + "/" + word.id, word)
}
export function GetAllActiveWordLookups(definition?: WordDefinition) {
    let url = URL

    if(definition) {
        url += "/lookup/?definition=" + definition
    } else {
        url += "/lookup/all/"
    }

    return API.Get<WordLookup[]>(url)
}
export function GetWordTopics() {
    return API.Get<WordTopic[]>(URL + "/word-topics")
}
export function CreateWordTopic(wordTopic: WordTopic) {
    return API.Post<WordTopic, WordTopic>(URL + "/word-topics", wordTopic)
}
export function GetSyllabification(words: Syllable[], syllableMethod: SyllableMethod) {
    let url = URL + "/syllabification/?syllableMethod=" + syllableMethod
    return API.Post<Syllable[], Syllable[]>(url, words)
}

export function GetNoun(wordId: number) {
    let url = URL + "/" + wordId + "/noun"
    return API.Get<Noun>(url)
}
export async function CreateNoun(noun: Noun, word: Word) {
    let url = URL + "/noun"

    const itemData = {
        syllabificationSingular: noun.syllabificationSingular,
        syllabificationPlural: noun.syllabificationPlural,
        articleDefinite: noun.articleDefinite,
        articleIndefinite: noun.articleIndefinite,
        characteristic: noun.characteristic,
        gender: noun.gender,
        caseFirst: noun.caseFirst,
        caseFirstIndefinite: noun.caseFirstIndefinite,
        caseFirstValue: noun.caseFirstValue,
        caseSecond: noun.caseSecond,
        caseSecondIndefinite: noun.caseSecondIndefinite,
        caseSecondValue: noun.caseSecondValue,
        caseThird: noun.caseThird,
        caseThirdIndefinite: noun.caseThirdIndefinite,
        caseThirdValue: noun.caseThirdValue,
        caseFourth: noun.caseFourth,
        caseFourthIndefinite: noun.caseFourthIndefinite,
        caseFourthValue: noun.caseFourthValue,
        caseFirstValuePL: noun.caseFirstValuePL,
        caseSecondValuePL: noun.caseSecondValuePL,
        caseThirdValuePL: noun.caseThirdValuePL,
        caseFourthValuePL: noun.caseFourthValuePL,
        caseFirstPossessiveValue: noun.caseFirstPossessiveValue,
        caseFirstPossessiveValuePL: noun.caseFirstPossessiveValuePL,
        caseSecondPossessiveValue: noun.caseSecondPossessiveValue,
        caseSecondPossessiveValuePL: noun.caseSecondPossessiveValuePL,
        caseThirdPossessiveValue: noun.caseThirdPossessiveValue,
        caseThirdPossessiveValuePL: noun.caseThirdPossessiveValuePL,
        caseFourthPossessiveValue: noun.caseFourthPossessiveValue,
        caseFourthPossessiveValuePL: noun.caseFourthPossessiveValuePL,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, Noun>(url, itemData)
}
export async function UpdateNoun(noun: Noun, word: Word) {
    let url = URL + "/noun"

    const itemData = {
        id: word.id,
        syllabificationSingular: noun.syllabificationSingular,
        syllabificationPlural: noun.syllabificationPlural,
        articleDefinite: noun.articleDefinite,
        articleIndefinite: noun.articleIndefinite,
        characteristic: noun.characteristic,
        gender: noun.gender,
        caseFirst: noun.caseFirst,
        caseFirstIndefinite: noun.caseFirstIndefinite,
        caseFirstValue: noun.caseFirstValue,
        caseSecond: noun.caseSecond,
        caseSecondIndefinite: noun.caseSecondIndefinite,
        caseSecondValue: noun.caseSecondValue,
        caseThird: noun.caseThird,
        caseThirdIndefinite: noun.caseThirdIndefinite,
        caseThirdValue: noun.caseThirdValue,
        caseFourth: noun.caseFourth,
        caseFourthIndefinite: noun.caseFourthIndefinite,
        caseFourthValue: noun.caseFourthValue,
        caseFirstValuePL: noun.caseFirstValuePL,
        caseSecondValuePL: noun.caseSecondValuePL,
        caseThirdValuePL: noun.caseThirdValuePL,
        caseFourthValuePL: noun.caseFourthValuePL,
        caseFirstPossessiveValue: noun.caseFirstPossessiveValue,
        caseFirstPossessiveValuePL: noun.caseFirstPossessiveValuePL,
        caseSecondPossessiveValue: noun.caseSecondPossessiveValue,
        caseSecondPossessiveValuePL: noun.caseSecondPossessiveValuePL,
        caseThirdPossessiveValue: noun.caseThirdPossessiveValue,
        caseThirdPossessiveValuePL: noun.caseThirdPossessiveValuePL,
        caseFourthPossessiveValue: noun.caseFourthPossessiveValue,
        caseFourthPossessiveValuePL: noun.caseFourthPossessiveValuePL,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn,
    }

    return API.Put<object, EntityData>(url, itemData)
}
export async function GetPossessivePronouns(gender: GenderEnum) {
    let url = URL + "/noun/possessive/?gender=" + gender
    return API.Get<PossessivePronouns[]>(url)
}

export function GetAdjective(wordId: number) {
    let url = URL + "/" + wordId + "/adjective"
    return API.Get<Adjective>(url)
}
export async function CreateAdjective(adjective: Adjective, word: Word) {
    let url = URL + "/adjective"

    const itemData = {
        syllabificationSingular: adjective.syllabificationSingular,
        irregular: adjective.irregular,
        basicForm: adjective.basicForm,
        comparative: adjective.comparative,
        superlative: adjective.superlative,
        opposites: adjective.opposites,
        adjectiveUseCases: adjective.adjectiveUseCases,

        declensionStrongMasculineFirst: adjective.declensionStrongMasculineFirst,
        declensionStrongMasculineSecond: adjective.declensionStrongMasculineSecond,
        declensionStrongMasculineThird: adjective.declensionStrongMasculineThird,
        declensionStrongMasculineFourth: adjective.declensionStrongMasculineFourth,

        declensionStrongFeminineFirst: adjective.declensionStrongFeminineFirst,
        declensionStrongFeminineSecond: adjective.declensionStrongFeminineSecond,
        declensionStrongFeminineThird: adjective.declensionStrongFeminineThird,
        declensionStrongFeminineFourth: adjective.declensionStrongFeminineFourth,

        declensionStrongNeutralFirst: adjective.declensionStrongNeutralFirst,
        declensionStrongNeutralSecond: adjective.declensionStrongNeutralSecond,
        declensionStrongNeutralThird: adjective.declensionStrongNeutralThird,
        declensionStrongNeutralFourth: adjective.declensionStrongNeutralFourth,

        declensionStrongPluralFirst: adjective.declensionStrongPluralFirst,
        declensionStrongPluralSecond: adjective.declensionStrongPluralSecond,
        declensionStrongPluralThird: adjective.declensionStrongPluralThird,
        declensionStrongPluralFourth: adjective.declensionStrongPluralFourth,

        declensionWeakMasculineFirst: adjective.declensionWeakMasculineFirst,
        declensionWeakMasculineSecond: adjective.declensionWeakMasculineSecond,
        declensionWeakMasculineThird: adjective.declensionWeakMasculineThird,
        declensionWeakMasculineFourth: adjective.declensionWeakMasculineFourth,

        declensionWeakFeminineFirst: adjective.declensionWeakFeminineFirst,
        declensionWeakFeminineSecond: adjective.declensionWeakFeminineSecond,
        declensionWeakFeminineThird: adjective.declensionWeakFeminineThird,
        declensionWeakFeminineFourth: adjective.declensionWeakFeminineFourth,

        declensionWeakNeutralFirst: adjective.declensionWeakNeutralFirst,
        declensionWeakNeutralSecond: adjective.declensionWeakNeutralSecond,
        declensionWeakNeutralThird: adjective.declensionWeakNeutralThird,
        declensionWeakNeutralFourth: adjective.declensionWeakNeutralFourth,

        declensionWeakPluralFirst: adjective.declensionWeakPluralFirst,
        declensionWeakPluralSecond: adjective.declensionWeakPluralSecond,
        declensionWeakPluralThird: adjective.declensionWeakPluralThird,
        declensionWeakPluralFourth: adjective.declensionWeakPluralFourth,

        declensionMixedMasculineFirst: adjective.declensionMixedMasculineFirst,
        declensionMixedMasculineSecond: adjective.declensionMixedMasculineSecond,
        declensionMixedMasculineThird: adjective.declensionMixedMasculineThird,
        declensionMixedMasculineFourth: adjective.declensionMixedMasculineFourth,

        declensionMixedFeminineFirst: adjective.declensionMixedFeminineFirst,
        declensionMixedFeminineSecond: adjective.declensionMixedFeminineSecond,
        declensionMixedFeminineThird: adjective.declensionMixedFeminineThird,
        declensionMixedFeminineFourth: adjective.declensionMixedFeminineFourth,

        declensionMixedNeutralFirst: adjective.declensionMixedNeutralFirst,
        declensionMixedNeutralSecond: adjective.declensionMixedNeutralSecond,
        declensionMixedNeutralThird: adjective.declensionMixedNeutralThird,
        declensionMixedNeutralFourth: adjective.declensionMixedNeutralFourth,

        declensionMixedPluralFirst: adjective.declensionMixedPluralFirst,
        declensionMixedPluralSecond: adjective.declensionMixedPluralSecond,
        declensionMixedPluralThird: adjective.declensionMixedPluralThird,
        declensionMixedPluralFourth: adjective.declensionMixedPluralFourth,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }
    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdateAdjective(adjective: Adjective, word: Word) {
    let url = URL + "/adjective"

    const itemData = {
        id: word.id,
        syllabificationSingular: adjective.syllabificationSingular,
        irregular: adjective.irregular,
        basicForm: adjective.basicForm,
        comparative: adjective.comparative,
        superlative: adjective.superlative,
        opposites: adjective.opposites,
        adjectiveUseCases: adjective.adjectiveUseCases,

        declensionStrongMasculineFirst: adjective.declensionStrongMasculineFirst,
        declensionStrongMasculineSecond: adjective.declensionStrongMasculineSecond,
        declensionStrongMasculineThird: adjective.declensionStrongMasculineThird,
        declensionStrongMasculineFourth: adjective.declensionStrongMasculineFourth,

        declensionStrongFeminineFirst: adjective.declensionStrongFeminineFirst,
        declensionStrongFeminineSecond: adjective.declensionStrongFeminineSecond,
        declensionStrongFeminineThird: adjective.declensionStrongFeminineThird,
        declensionStrongFeminineFourth: adjective.declensionStrongFeminineFourth,

        declensionStrongNeutralFirst: adjective.declensionStrongNeutralFirst,
        declensionStrongNeutralSecond: adjective.declensionStrongNeutralSecond,
        declensionStrongNeutralThird: adjective.declensionStrongNeutralThird,
        declensionStrongNeutralFourth: adjective.declensionStrongNeutralFourth,

        declensionStrongPluralFirst: adjective.declensionStrongPluralFirst,
        declensionStrongPluralSecond: adjective.declensionStrongPluralSecond,
        declensionStrongPluralThird: adjective.declensionStrongPluralThird,
        declensionStrongPluralFourth: adjective.declensionStrongPluralFourth,

        declensionWeakMasculineFirst: adjective.declensionWeakMasculineFirst,
        declensionWeakMasculineSecond: adjective.declensionWeakMasculineSecond,
        declensionWeakMasculineThird: adjective.declensionWeakMasculineThird,
        declensionWeakMasculineFourth: adjective.declensionWeakMasculineFourth,

        declensionWeakFeminineFirst: adjective.declensionWeakFeminineFirst,
        declensionWeakFeminineSecond: adjective.declensionWeakFeminineSecond,
        declensionWeakFeminineThird: adjective.declensionWeakFeminineThird,
        declensionWeakFeminineFourth: adjective.declensionWeakFeminineFourth,

        declensionWeakNeutralFirst: adjective.declensionWeakNeutralFirst,
        declensionWeakNeutralSecond: adjective.declensionWeakNeutralSecond,
        declensionWeakNeutralThird: adjective.declensionWeakNeutralThird,
        declensionWeakNeutralFourth: adjective.declensionWeakNeutralFourth,

        declensionWeakPluralFirst: adjective.declensionWeakPluralFirst,
        declensionWeakPluralSecond: adjective.declensionWeakPluralSecond,
        declensionWeakPluralThird: adjective.declensionWeakPluralThird,
        declensionWeakPluralFourth: adjective.declensionWeakPluralFourth,

        declensionMixedMasculineFirst: adjective.declensionMixedMasculineFirst,
        declensionMixedMasculineSecond: adjective.declensionMixedMasculineSecond,
        declensionMixedMasculineThird: adjective.declensionMixedMasculineThird,
        declensionMixedMasculineFourth: adjective.declensionMixedMasculineFourth,

        declensionMixedFeminineFirst: adjective.declensionMixedFeminineFirst,
        declensionMixedFeminineSecond: adjective.declensionMixedFeminineSecond,
        declensionMixedFeminineThird: adjective.declensionMixedFeminineThird,
        declensionMixedFeminineFourth: adjective.declensionMixedFeminineFourth,

        declensionMixedNeutralFirst: adjective.declensionMixedNeutralFirst,
        declensionMixedNeutralSecond: adjective.declensionMixedNeutralSecond,
        declensionMixedNeutralThird: adjective.declensionMixedNeutralThird,
        declensionMixedNeutralFourth: adjective.declensionMixedNeutralFourth,

        declensionMixedPluralFirst: adjective.declensionMixedPluralFirst,
        declensionMixedPluralSecond: adjective.declensionMixedPluralSecond,
        declensionMixedPluralThird: adjective.declensionMixedPluralThird,
        declensionMixedPluralFourth: adjective.declensionMixedPluralFourth,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}
export async function GetAllAdjectiveUseCases() {
    let url = URL + "/adjective/usecases"
    return API.Get<AdjectiveUseCases[]>(url)
}

export function GetVerb(wordId: number) {
    let url = URL + "/" + wordId + "/verb"
    return API.Get<Verb>(url)
}
export async function CreateVerb(verb: Verb, word: Word) {
    let url = URL + "/verb"

    const itemData = {
        syllabificationSingular: verb.syllabificationSingular,
        separationByIssue: verb.separationByIssue,
        separationByIndependence: verb.separationByIndependence,
        separationByPerson: verb.separationByPerson,
        separationByStrength: verb.separationByStrength,
        baseForm1: verb.baseForm1,
        baseForm2: verb.baseForm2,
        baseForm3: verb.baseForm3,
        baseForm3Help: verb.baseForm3Help,
        presentInfinitive: verb.presentInfinitive,
        presentParticiple: verb.presentParticiple,
        presentIndicativeI: verb.presentIndicativeI,
        presentIndicativeYou: verb.presentIndicativeYou,
        presentIndicativeHe: verb.presentIndicativeHe,
        presentIndicativeWe: verb.presentIndicativeWe,
        presentIndicativeYouPL: verb.presentIndicativeYouPL,
        presentIndicativeThey: verb.presentIndicativeThey,
        presentConjunctiveI: verb.presentConjunctiveI,
        presentConjunctiveYou: verb.presentConjunctiveYou,
        presentConjunctiveHe: verb.presentConjunctiveHe,
        presentConjunctiveWe: verb.presentConjunctiveWe,
        presentConjunctiveYouPL: verb.presentConjunctiveYouPL,
        presentConjunctiveThey: verb.presentConjunctiveThey,
        presentImperativeYou: verb.presentImperativeYou,
        presentImperativeYou2: verb.presentImperativeYou2,
        presentImperativeWe: verb.presentImperativeWe,
        presentImperativeWe2: verb.presentImperativeWe2,
        presentImperativeYouPL: verb.presentImperativeYouPL,
        presentImperativeYouPL2: verb.presentImperativeYouPL2,
        presentImperativeThey: verb.presentImperativeThey,
        presentImperativeThey2: verb.presentImperativeThey2,
        pastTenseIndicativeI: verb.pastTenseIndicativeI,
        pastTenseIndicativeYou: verb.pastTenseIndicativeYou,
        pastTenseIndicativeHe: verb.pastTenseIndicativeHe,
        pastTenseIndicativeWe: verb.pastTenseIndicativeWe,
        pastTenseIndicativeYouPL: verb.pastTenseIndicativeYouPL,
        pastTenseIndicativeThey: verb.pastTenseIndicativeThey,
        pastTenseConjunctive2I: verb.pastTenseConjunctive2I,
        pastTenseConjunctive2You: verb.pastTenseConjunctive2You,
        pastTenseConjunctive2He: verb.pastTenseConjunctive2He,
        pastTenseConjunctive2We: verb.pastTenseConjunctive2We,
        pastTenseConjunctive2YouPL: verb.pastTenseConjunctive2YouPL,
        pastTenseConjunctive2They: verb.pastTenseConjunctive2They,
        perfectParticiple: verb.perfectParticiple,
        perfectParticipleHelp: verb.perfectParticipleHelp,
        future1: verb.future1,
        future2: verb.future2,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdateVerb(verb: Verb, word: Word) {
    let url = URL + "/verb"

    const itemData = {
        id: word.id,
        syllabificationSingular: verb.syllabificationSingular,
        separationByIssue: verb.separationByIssue,
        separationByIndependence: verb.separationByIndependence,
        separationByPerson: verb.separationByPerson,
        separationByStrength: verb.separationByStrength,
        baseForm1: verb.baseForm1,
        baseForm2: verb.baseForm2,
        baseForm3: verb.baseForm3,
        baseForm3Help: verb.baseForm3Help,
        presentInfinitive: verb.presentInfinitive,
        presentParticiple: verb.presentParticiple,
        presentIndicativeI: verb.presentIndicativeI,
        presentIndicativeYou: verb.presentIndicativeYou,
        presentIndicativeHe: verb.presentIndicativeHe,
        presentIndicativeWe: verb.presentIndicativeWe,
        presentIndicativeYouPL: verb.presentIndicativeYouPL,
        presentIndicativeThey: verb.presentIndicativeThey,
        presentConjunctiveI: verb.presentConjunctiveI,
        presentConjunctiveYou: verb.presentConjunctiveYou,
        presentConjunctiveHe: verb.presentConjunctiveHe,
        presentConjunctiveWe: verb.presentConjunctiveWe,
        presentConjunctiveYouPL: verb.presentConjunctiveYouPL,
        presentConjunctiveThey: verb.presentConjunctiveThey,
        presentImperativeYou: verb.presentImperativeYou,
        presentImperativeYou2: verb.presentImperativeYou2,
        presentImperativeWe: verb.presentImperativeWe,
        presentImperativeWe2: verb.presentImperativeWe2,
        presentImperativeYouPL: verb.presentImperativeYouPL,
        presentImperativeYouPL2: verb.presentImperativeYouPL2,
        presentImperativeThey: verb.presentImperativeThey,
        presentImperativeThey2: verb.presentImperativeThey2,
        pastTenseIndicativeI: verb.pastTenseIndicativeI,
        pastTenseIndicativeYou: verb.pastTenseIndicativeYou,
        pastTenseIndicativeHe: verb.pastTenseIndicativeHe,
        pastTenseIndicativeWe: verb.pastTenseIndicativeWe,
        pastTenseIndicativeYouPL: verb.pastTenseIndicativeYouPL,
        pastTenseIndicativeThey: verb.pastTenseIndicativeThey,
        pastTenseConjunctive2I: verb.pastTenseConjunctive2I,
        pastTenseConjunctive2You: verb.pastTenseConjunctive2You,
        pastTenseConjunctive2He: verb.pastTenseConjunctive2He,
        pastTenseConjunctive2We: verb.pastTenseConjunctive2We,
        pastTenseConjunctive2YouPL: verb.pastTenseConjunctive2YouPL,
        pastTenseConjunctive2They: verb.pastTenseConjunctive2They,
        perfectParticiple: verb.perfectParticiple,
        perfectParticipleHelp: verb.perfectParticipleHelp,
        future1: verb.future1,
        future2: verb.future2,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

export function GetArticle(wordId: number) {
    let url = URL + "/" + wordId + "/article"
    return API.Get<Article>(url)
}
export async function CreateArticle(article: Article, word: Word) {
    let url = URL + "/article"

    const itemData = {
        syllabification: article.syllabification,
        gender: article.gender,
        type: article.type,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdateArticle(article: Article, word: Word) {
    let url = URL + "/article"

    const itemData = {
        id: word.id,
        syllabification: article.syllabification,
        gender: article.gender,
        type: article.type,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

export function GetPronoun(wordId: number) {
    let url = URL + "/" + wordId + "/pronoun"
    return API.Get<Pronoun>(url)
}
export async function CreatePronoun(pronoun: Pronoun, word: Word) {
    let url = URL + "/pronoun"

    const itemData = {
        syllabification: pronoun.syllabification,
        pronounType: pronoun.pronounType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdatePronoun(pronoun: Pronoun, word: Word) {
    let url = URL + "/pronoun"

    const itemData = {
        id: word.id,
        syllabification: pronoun.syllabification,
        pronounType: pronoun.pronounType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

export function GetNumeral(wordId: number) {
    let url = URL + "/" + wordId + "/numeral"
    return API.Get<Numeral>(url)
}
export async function CreateNumeral(numeral: Numeral, word: Word) {
    let url = URL + "/numeral"

    const itemData = {
        syllabification: numeral.syllabification,
        numeralType: numeral.numeralType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdateNumeral(numeral: Numeral, word: Word) {
    let url = URL + "/numeral"

    const itemData = {
        id: word.id,
        syllabification: numeral.syllabification,
        numeralType: numeral.numeralType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

export function GetAdverb(wordId: number) {
    let url = URL + "/" + wordId + "/adverb"
    return API.Get<Adverb>(url)
}
export async function CreateAdverb(adverb: Adverb, word: Word) {
    let url = URL + "/adverb"

    const itemData = {
        syllabification: adverb.syllabification,
        adverbType: adverb.adverbType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdateAdverb(adverb: Adverb, word: Word) {
    let url = URL + "/adverb"

    const itemData = {
        id: word.id,
        syllabification: adverb.syllabification,
        adverbType: adverb.adverbType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

export function GetPreposition(wordId: number) {
    let url = URL + "/" + wordId + "/preposition"
    return API.Get<Preposition>(url)
}
export async function CreatePreposition(preposition: Preposition, word: Word) {
    let url = URL + "/preposition"

    const itemData = {
        syllabification: preposition.syllabification,
        prepositionType: preposition.prepositionType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdatePreposition(preposition: Preposition, word: Word) {
    let url = URL + "/preposition"

    const itemData = {
        id: word.id,
        syllabification: preposition.syllabification,
        prepositionType: preposition.prepositionType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

export function GetConjunction(wordId: number) {
    let url = URL + "/" + wordId + "/conjunction"
    return API.Get<Conjunction>(url)
}
export async function CreateConjunction(conjunction: Conjunction, word: Word) {
    let url = URL + "/conjunction"

    const itemData = {
        syllabification: conjunction.syllabification,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdateConjunction(conjunction: Conjunction, word: Word) {
    let url = URL + "/conjunction"

    const itemData = {
        id: word.id,
        syllabification: conjunction.syllabification,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

export function GetInterjection(wordId: number) {
    let url = URL + "/" + wordId + "/interjection"
    return API.Get<Interjection>(url)
}
export async function CreateInterjection(conjunction: Interjection, word: Word) {
    let url = URL + "/interjection"

    const itemData = {
        syllabification: conjunction.syllabification,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdateInterjection(conjunction: Interjection, word: Word) {
    let url = URL + "/interjection"

    const itemData = {
        id: word.id,
        syllabification: conjunction.syllabification,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

export function GetFusion(wordId: number) {
    let url = URL + "/" + wordId + "/fusion"
    return API.Get<Fusion>(url)
}
export async function CreateFusion(fusion: Fusion, word: Word) {
    let url = URL + "/fusion"

    const itemData = {
        syllabification: fusion.syllabification,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdateFusion(fusion: Fusion, word: Word) {
    let url = URL + "/fusion"

    const itemData = {
        id: word.id,
        syllabification: fusion.syllabification,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

export function GetProperName(wordId: number) {
    let url = URL + "/" + wordId + "/properName"
    return API.Get<ProperName>(url)
}
export async function CreateProperName(properName: ProperName, word: Word) {
    let url = URL + "/properName"

    const itemData = {
        syllabification: properName.syllabification,
        properNameType: properName.properNameType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary
    }

    return API.Post<object, EntityData>(url, itemData)
}
export async function UpdateProperName(properName: ProperName, word: Word) {
    let url = URL + "/properName"

    const itemData = {
        id: word.id,
        syllabification: properName.syllabification,
        properNameType: properName.properNameType,

        name: word.name,
        descriptionSimple: word.descriptionSimple,
        basicVocabulary: word.basicVocabulary,
        phonemic: word.phonemic,
        foreignWord: word.foreignWord,
        exceptionWord: word.exceptionWord,
        syllableDivide: word.syllableDivide,
        syllableSpeak: word.syllableSpeak,
        difficulty: word.difficulty,
        wordDefinition: word.wordDefinition,
        synonymLinks: word.synonymLinks,
        wordLinks: word.wordLinks,
        wordHighlights: word.wordHighlights,
        wordTopics: word.wordTopics,
        showInDictionary: word.showInDictionary,

        status: word.status,
        ownerId: word.ownerId,
        createdBy: word.createdBy,
        createdOn: word.createdOn,
        modifiedBy: word.modifiedBy,
        modifiedOn: word.modifiedOn
    }

    return API.Put<object, EntityData>(url, itemData)
}

// Dictionary
export function GetDictionaryFiltered(filter: WordFilter, page?: number, size?: number) {
    let url = URL + "/dictionary/filter"

    let params = ""
    if (page !== null) {
        params = "?page=" + page
    }
    if (size) {
        params += ((params === "") ? "?" : "&") + "size=" + size
    }

    return API.Post<WordFilter, Dictionary[]>(url + params, filter)
}
export function GetWordKitFiltered(filter: WordFilter, size: number) {
    let url = URL + "/dictionary/word-kit"
    let params = "?size=" + size

    return API.Post<WordFilter, WordKit[]>(url + params, filter)
}

// Additionals
export async function GetWordHighlights() {
    let url = URL + "/highlight/"
    return API.Get<WordHighlight[]>(url)
}
