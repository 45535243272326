import React from "react";
import Entity from "../../_model/Entity";

interface IProps {
    id: string,
    width?: number,
    label?: string,
    data: Entity[],
    value?: number,
    border?: boolean,

    required: boolean,
    readonly: boolean,

    onChange?: (value: Entity) => void
}

interface IState { }

export default class RadioButtonList extends React.Component<IProps, IState> {

    onChange = (value: Entity) => {
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }

    render() {
        let classNameGroup = "form-group"

        if(this.props.border) {
            classNameGroup += " form-border"
        }

        return <div className={classNameGroup} style={{width: this.props.width}}>
            {this.props.label !== undefined &&
            <label className="bold-label" htmlFor={this.props.id}>{this.props.label}</label>
            }
            <div id={this.props.id} className={"form-group-radiobutton-list"}>
                {this.props.data.map(value => {
                    return <div key={this.props.id + value.id} className={"form-group-radiobutton-list-item"}>
                        <label className={"label"} htmlFor={this.props.id + value.id}>{value.name}</label>
                        <input type={"radio"}
                               id={this.props.id + value.id}
                               value={value.id}
                               disabled={this.props.readonly}
                               required={this.props.required}
                               checked={this.props.value === value.id}
                               onChange={() => this.onChange(value)} />
                    </div>
                })}
            </div>
        </div>
    }
}
