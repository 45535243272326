import {Difficulty, ExerciseCalculationType, ExerciseType} from "../Framework/Enums";
import {MenuItem} from "./MenuItem";
import Entity from "./Entity";

export class ExerciseFilter {
    search?: string
    difficulty?: Difficulty
    calculationType?: ExerciseCalculationType
    exerciseType?: ExerciseType
    menuEntry?: MenuItem
    topicMenuEntry?: MenuItem
    parentExercise?: Entity

    constructor(exerciseType: ExerciseType) {
        this.exerciseType = exerciseType
    }
}
