export enum PrepositionType {
    lokal = "lokal",
    temporal = "temporal",
    modal = "modal",
    kausal = "kausal"
}

export class Preposition {
    wordId?: number
    syllabification?: string
    prepositionType?: PrepositionType

    toString = () : string => {
        return "- Preposition"
    }
}

export default Preposition
