import React from "react";
import {MainContext} from "../../../../_base/MainContext";
import translations from "../../../../Framework/translations.json";
import {GetVerb} from "../../../../_endpoint/WordEndpoint";
import {SyllableDefinition, WDSyllableWord} from "../../../Elements/Textbox/WDSyllableWord";
import Verb, {
    VerbHelpFutureConjunctive2Sein,
    VerbHelpFutureConjunctiveSein,
    VerbHelpFutureIndicativeSein, VerbHelpPastConjunctive2Haben,
    VerbHelpPastConjunctive2Sein,
    VerbHelpPastIndicativeHaben,
    VerbHelpPastIndicativeSein,
    VerbPerfectParticipleHelp
} from "../../../../_model/Dictionary/Verb";
import {SidebarContentDragElementType} from "../../SidebarContentBase";
import {ImagePath} from "../../../../Framework/CategoryImage";
import Image from "../../../../_model/Image";

interface IProps {
    wordId?: number
    syllableDefinition: SyllableDefinition
    onDragStart: (event: React.DragEvent, dragElementType: SidebarContentDragElementType, elementId: string, image?: Image, spellCheck?: boolean) => void
}
interface IState {
    verb?: Verb
}

export class SidebarDictionaryWordFormVerb extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {}
    }

    componentDidMount() {
        this.fetchVerb()
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(prevProps.wordId !== this.props.wordId) {
            this.fetchVerb()
        }
    }

    fetchVerb = () => {
        if(this.props.wordId) {
            GetVerb(this.props.wordId).then(
                (itemData) => {
                    this.setState({verb: itemData})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }

    render() {
        return this.state.verb !== undefined && this.state.verb !== null

            ?
            <>
                <div className={"ws-designer-sidebar-row ws-designer-dictionary-form-entry-row-column"}>
                    <div className={"ws-designer-dictionary-form-entry-row-label"} style={{marginRight: "5px"}}>
                        {this.context.translate(translations.fields.word.verb.separation) + ":"}
                    </div>
                    <div className={"ws-designer-dictionary-form-entry-row-text"}>
                        {this.state.verb.separationByStrength + "; " + this.state.verb.separationByPerson + "; "
                            + this.state.verb.separationByIndependence + "; " + this.state.verb.separationByIssue}
                    </div>
                </div>

                <div className={"ws-designer-sidebar-row"}>
                    <div className={"ws-designer-sidebar-column ws-designer-dictionary-form-entry-row-column"}>
                        <div className={"ws-designer-dictionary-form-entry-row-label"} style={{marginRight: "5px"}}>
                            {this.context.translate(translations.fields.word.verb.present_infinitive) + ":"}
                        </div>
                        {this.state.verb.presentInfinitive &&
                            <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                            syllableWord={this.state.verb.presentInfinitive}/>
                        }
                    </div>

                    <div className={"ws-designer-sidebar-column ws-designer-dictionary-form-entry-row-column"}>
                        <div className={"ws-designer-dictionary-form-entry-row-label"} style={{marginRight: "5px"}}>
                            {this.context.translate(translations.fields.word.verb.present_participle) + ":"}
                        </div>
                        {this.state.verb.presentParticiple &&
                            <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                            syllableWord={this.state.verb.presentParticiple}/>
                        }
                    </div>
                </div>

                <div className={"ws-designer-sidebar-row ws-designer-dictionary-form-entry-row-column"}>
                    <div className={"ws-designer-dictionary-form-entry-row-label"}>
                        {this.context.translate(translations.fields.word.verb.basic_forms) + ":"}
                    </div>
                </div>

                <div className={"ws-designer-dictionary-table-container"}
                     draggable={true}
                     onDragStart={(event) =>
                         this.props.onDragStart(
                             event,
                             SidebarContentDragElementType.TABLE,
                             "ws-designer-dictionary-table-baseform",
                             undefined,
                             !this.props.syllableDefinition)}>
                    <div className={"table-grabber"}>
                        <img
                            draggable={"false"}
                            alt={this.context.translate(translations.command.move)}
                            src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                    </div>
                    <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-baseform"}>
                        <tbody>
                        <tr>
                            <td>{this.state.verb.baseForm1 &&
                                <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                syllableWord={this.state.verb.baseForm1}/>}</td>
                            <td>{this.state.verb.baseForm2 &&
                                <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                syllableWord={this.state.verb.baseForm2}/>}</td>
                            <td>{this.state.verb.baseForm3 &&
                                <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                syllableWord={this.state.verb.baseForm3}/>}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                {/* Table present */}
                <div className={"ws-designer-dictionary-table-label"}>
                    {this.context.translate(translations.fields.word.verb.present) + ":"}

                </div>
                <div className={"ws-designer-dictionary-table-container"}
                     draggable={true}
                     onDragStart={(event) =>
                         this.props.onDragStart(
                             event,
                             SidebarContentDragElementType.TABLE,
                             "ws-designer-dictionary-table-present",
                             undefined,
                             !this.props.syllableDefinition)}>
                    <div className={"table-grabber"}>
                        <img
                            draggable={"false"}
                            alt={this.context.translate(translations.command.move)}
                            src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                    </div>
                    <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-present"}>
                        <thead>
                        <tr>
                            <th>{this.context.translate(translations.fields.word.person.person)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.indicative)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.conjunctive_1)}</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.context.translate(translations.fields.word.person.i)}</td>
                                <td>
                                    {this.state.verb.presentIndicativeI &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentIndicativeI}/>}
                                </td>
                                <td>
                                    {this.state.verb.presentConjunctiveI &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentConjunctiveI}/>}
                                </td>
                            </tr>
                            <tr>
                                <td>{this.context.translate(translations.fields.word.person.you)}</td>
                                <td>
                                    {this.state.verb.presentIndicativeYou &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentIndicativeYou}/>}
                                </td>
                                <td>
                                    {this.state.verb.presentConjunctiveYou &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentConjunctiveYou}/>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={"ws-designer-dictionary-table-cell-row"}>
                                        {this.context.translate(translations.fields.word.person.he)}
                                        , &#8203;
                                        {this.context.translate(translations.fields.word.person.she)}
                                        , &#8203;
                                        {this.context.translate(translations.fields.word.person.it)}
                                    </div>
                                </td>
                                <td>
                                    {this.state.verb.presentIndicativeHe &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentIndicativeHe}/>}
                                </td>
                                <td>
                                    {this.state.verb.presentConjunctiveHe &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentConjunctiveHe}/>}
                                </td>
                            </tr>
                            <tr>
                                <td>{this.context.translate(translations.fields.word.person.we)}</td>
                                <td>
                                    {this.state.verb.presentIndicativeWe &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentIndicativeWe}/>}
                                </td>
                                <td>
                                    {this.state.verb.presentConjunctiveWe &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentConjunctiveWe}/>}
                                </td>
                            </tr>
                            <tr>
                                <td>{this.context.translate(translations.fields.word.person.you_pl)}</td>
                                <td>
                                    {this.state.verb.presentIndicativeYouPL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentIndicativeYouPL}/>}
                                </td>
                                <td>
                                    {this.state.verb.presentConjunctiveYouPL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentConjunctiveYouPL}/>}
                                </td>
                            </tr>
                            <tr>
                                <td>{this.context.translate(translations.fields.word.person.they)}</td>
                                <td>
                                    {this.state.verb.presentIndicativeThey &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentIndicativeThey}/>}
                                </td>
                                <td>
                                    {this.state.verb.presentConjunctiveThey &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentConjunctiveThey}/>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Table present imperative */}
                <div className={"ws-designer-dictionary-table-container"}
                     style={{marginTop: "10px"}}
                     draggable={true}
                     onDragStart={(event) =>
                         this.props.onDragStart(
                             event,
                             SidebarContentDragElementType.TABLE,
                             "ws-designer-dictionary-table-present-imperative",
                             undefined,
                             !this.props.syllableDefinition)}>
                    <div className={"table-grabber"}>
                        <img
                            draggable={"false"}
                            alt={this.context.translate(translations.command.move)}
                            src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                    </div>
                    <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-present-imperative"}>
                        <thead>
                        <tr>
                            <th>{this.context.translate(translations.fields.word.person.person)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.imperative)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.i)}</td>
                            <td>
                                -
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb.presentImperativeYou &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentImperativeYou}/>}
                                    &#160;
                                    {this.state.verb.presentImperativeYou2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentImperativeYou2}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.context.translate(translations.fields.word.person.he)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.she)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.it)}
                                </div>
                            </td>
                            <td>
                                -
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.we)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb.presentImperativeWe &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentImperativeWe}/>}
                                    &#160;
                                    {this.state.verb.presentImperativeWe2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentImperativeWe2}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you_pl)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb.presentImperativeYouPL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentImperativeYouPL}/>}
                                    &#160;
                                    {this.state.verb.presentImperativeYouPL2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentImperativeYouPL2}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.they)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb.presentImperativeThey &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentImperativeThey}/>}
                                    &#160;
                                    {this.state.verb.presentImperativeThey2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.presentImperativeThey2}/>}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                {/* Table past tense */}
                <div className={"ws-designer-dictionary-table-label"}>
                    {this.context.translate(translations.fields.word.verb.past_tense) + ":"}
                </div>
                <div className={"ws-designer-dictionary-table-container"}
                     draggable={true}
                     onDragStart={(event) =>
                         this.props.onDragStart(
                             event,
                             SidebarContentDragElementType.TABLE,
                             "ws-designer-dictionary-table-past-tense",
                             undefined,
                             !this.props.syllableDefinition)}>
                    <div className={"table-grabber"}>
                        <img
                            draggable={"false"}
                            alt={this.context.translate(translations.command.move)}
                            src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                    </div>
                    <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-past-tense"}>
                        <thead>
                        <tr>
                            <th>{this.context.translate(translations.fields.word.person.person)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.indicative)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.conjunctive_2)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.i)}</td>
                            <td>
                                {this.state.verb.pastTenseIndicativeI &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseIndicativeI}/>}
                            </td>
                            <td>
                                {this.state.verb.pastTenseConjunctive2I &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseConjunctive2I}/>}
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you)}</td>
                            <td>
                                {this.state.verb.pastTenseIndicativeYou &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseIndicativeYou}/>}
                            </td>
                            <td>
                                {this.state.verb.pastTenseConjunctive2You &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseConjunctive2You}/>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.context.translate(translations.fields.word.person.he)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.she)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.it)}
                                </div>
                            </td>
                            <td>
                                {this.state.verb.pastTenseIndicativeHe &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseIndicativeHe}/>}
                            </td>
                            <td>
                                {this.state.verb.pastTenseConjunctive2He &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseConjunctive2He}/>}
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.we)}</td>
                            <td>
                                {this.state.verb.pastTenseIndicativeWe &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseIndicativeWe}/>}
                            </td>
                            <td>
                                {this.state.verb.pastTenseConjunctive2We &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseConjunctive2We}/>}
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you_pl)}</td>
                            <td>
                                {this.state.verb.pastTenseIndicativeYouPL &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseIndicativeYouPL}/>}
                            </td>
                            <td>
                                {this.state.verb.pastTenseConjunctive2YouPL &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseConjunctive2YouPL}/>}
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.they)}</td>
                            <td>
                                {this.state.verb.pastTenseIndicativeThey &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseIndicativeThey}/>}
                            </td>
                            <td>
                                {this.state.verb.pastTenseConjunctive2They &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.verb.pastTenseConjunctive2They}/>}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                {/* Table past perfect */}
                <div className={"ws-designer-dictionary-table-label"}>
                    {this.context.translate(translations.fields.word.verb.past_perfect) + ":"}
                </div>
                <div className={"ws-designer-dictionary-table-container"}
                     draggable={true}
                     onDragStart={(event) =>
                         this.props.onDragStart(
                             event,
                             SidebarContentDragElementType.TABLE,
                             "ws-designer-dictionary-table-past-perfect",
                             undefined,
                             !this.props.syllableDefinition)}>
                    <div className={"table-grabber"}>
                        <img
                            draggable={"false"}
                            alt={this.context.translate(translations.command.move)}
                            src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                    </div>
                    <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-past-perfect"}>
                        <thead>
                        <tr>
                            <th>{this.context.translate(translations.fields.word.person.person)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.indicative)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.conjunctive_1)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.i)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                            ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition} syllableWord={VerbHelpPastIndicativeSein.i}/>
                                            : <WDSyllableWord syllableDefinition={this.props.syllableDefinition} syllableWord={VerbHelpPastIndicativeHaben.i}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition} syllableWord={VerbHelpPastIndicativeSein.i}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition} syllableWord={VerbHelpPastIndicativeHaben.i}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.you}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.you}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.you}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.you}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.context.translate(translations.fields.word.person.he)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.she)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.it)}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.he}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.he}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.he}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.he}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.we)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.we}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.we}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.we}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.we}/>
                                        )
                                    }
                                    <div style={{marginLeft: "3px"}}/>
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you_pl)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.you_pl}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.you_pl}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.you_pl}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.you_pl}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.they)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.they}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.they}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeSein.they}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastIndicativeHaben.they}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                {/* Table past perfect progressive */}
                <div className={"ws-designer-dictionary-table-label"}>
                    {this.context.translate(translations.fields.word.verb.past_perfect_progressive) + ":"}
                </div>
                <div className={"ws-designer-dictionary-table-container"}
                     draggable={true}
                     onDragStart={(event) =>
                         this.props.onDragStart(
                             event,
                             SidebarContentDragElementType.TABLE,
                             "ws-designer-dictionary-table-past-perfect-progressive",
                             undefined,
                             !this.props.syllableDefinition)}>
                    <div className={"table-grabber"}>
                        <img
                            draggable={"false"}
                            alt={this.context.translate(translations.command.move)}
                            src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                    </div>
                    <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-past-perfect-progressive"}>
                        <thead>
                        <tr>
                            <th>{this.context.translate(translations.fields.word.person.person)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.conjunctive_2)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.i)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Sein.i}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Haben.i}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Sein.you}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Haben.you}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.context.translate(translations.fields.word.person.he)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.she)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.it)}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Sein.he}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Haben.he}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.we)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Sein.we}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Haben.we}/>
                                        )
                                    }
                                    <div style={{marginLeft: "3px"}}/>
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you_pl)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Sein.you_pl}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Haben.you_pl}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.they)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.verb?.perfectParticipleHelp
                                        && (this.state.verb.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                                ? <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Sein.they}/>
                                                : <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                                  syllableWord={VerbHelpPastConjunctive2Haben.they}/>
                                        )
                                    }
                                    &#160;
                                    {this.state.verb?.perfectParticiple &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.perfectParticiple}/>}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                {/* Table future 1 */}
                <div className={"ws-designer-dictionary-table-label"}>
                    {this.context.translate(translations.fields.word.verb.future) + ":"}
                </div>
                <div className={"ws-designer-dictionary-table-container"}
                     draggable={true}
                     onDragStart={(event) =>
                         this.props.onDragStart(
                             event,
                             SidebarContentDragElementType.TABLE,
                             "ws-designer-dictionary-table-future-1",
                             undefined,
                             !this.props.syllableDefinition)}>
                    <div className={"table-grabber"}>
                        <img
                            draggable={"false"}
                            alt={this.context.translate(translations.command.move)}
                            src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                    </div>
                    <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-future-1"}>
                        <thead>
                        <tr>
                            <th>{this.context.translate(translations.fields.word.person.person)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.indicative)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.conjunctive_1)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.i)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.i}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctiveSein.i}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.you}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctiveSein.you}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.context.translate(translations.fields.word.person.he)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.she)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.it)}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.he}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctiveSein.he}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.we)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.we}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctiveSein.we}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you_pl)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.you_pl}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctiveSein.you_pl}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.they)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.they}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctiveSein.they}/>
                                    &#160;
                                    {this.state.verb?.future1 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future1}/>}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                {/* Table future 2 */}
                <div className={"ws-designer-dictionary-table-label"}>
                    {this.context.translate(translations.fields.word.verb.future2) + ":"}
                </div>
                <div className={"ws-designer-dictionary-table-container"}
                     draggable={true}
                     onDragStart={(event) =>
                         this.props.onDragStart(
                             event,
                             SidebarContentDragElementType.TABLE,
                             "ws-designer-dictionary-table-future-2",
                             undefined,
                             !this.props.syllableDefinition)}>
                    <div className={"table-grabber"}>
                        <img
                            draggable={"false"}
                            alt={this.context.translate(translations.command.move)}
                            src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                    </div>
                    <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-future-2"}>
                        <thead>
                        <tr>
                            <th>{this.context.translate(translations.fields.word.person.person)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.indicative)}</th>
                            <th>{this.context.translate(translations.fields.word.verb.conjunctive_2)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.i)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.i}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctive2Sein.i}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.you}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctive2Sein.you}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.context.translate(translations.fields.word.person.he)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.she)}
                                    , &#8203;
                                    {this.context.translate(translations.fields.word.person.it)}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.he}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctive2Sein.he}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.we)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.we}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctive2Sein.we}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.you_pl)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.you_pl}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctive2Sein.you_pl}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.person.they)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureIndicativeSein.they}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={VerbHelpFutureConjunctive2Sein.they}/>
                                    &#160;
                                    {this.state.verb?.future2 &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.verb.future2}/>}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </>

            :
            <></>

    }
}
