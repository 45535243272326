import React from "react";
import {MainContext} from "../../../_base/MainContext";
import {SidebarContentDragElementType} from "../../Sidebar/SidebarContentBase";

export class SyllableDefinition {
    showSyllable: boolean
    syllableColor1: string
    syllableColor2: string

    constructor(showSyllable: boolean, syllableColor1: string, syllableColor2: string) {
        this.showSyllable = showSyllable
        this.syllableColor1 = syllableColor1
        this.syllableColor2 = syllableColor2
    }
}

interface IProps {
    syllableWord: string
    syllableDefinition: SyllableDefinition
    onDragStart?:(event: React.DragEvent, dragElementType: SidebarContentDragElementType, imageElementId: string) => void
}
interface IState {
}

export class WDSyllableWord extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        let color1 = this.props.syllableDefinition.syllableColor1
        let color2 = this.props.syllableDefinition.syllableColor2
        let colorToUse = color2

        let wordWithSyllableSplit = this.props.syllableDefinition.showSyllable ? this.props.syllableWord.split("/") : []
        let wordWithoutSyllables = !this.props.syllableDefinition.showSyllable ? this.props.syllableWord.replaceAll("/","") : ""

        return <>
            {this.props.syllableDefinition.showSyllable
            ?
            <span className={"ws-designer-word"}
                  id={"syllable-word-" + this.props.syllableWord}
                  draggable={this.props.onDragStart !== undefined}
                  onDragStart={(event) =>
                      this.props.onDragStart?.(event, SidebarContentDragElementType.TEXT, "syllable-word-" + this.props.syllableWord)}>
                {wordWithSyllableSplit.map((syllable, i) => {
                    colorToUse = (colorToUse === color1 ? color2 : color1)

                    return <span className="ws-designer-word-syllabification"
                                 key={"syllable-" + syllable + "-" + i}
                                 style={{color: colorToUse}}>
                        {syllable}
                    </span>
                })}
            </span>
            :
            <div className={"ws-designer-word"}
                 id={"syllable-word-" + wordWithoutSyllables}
                 draggable={this.props.onDragStart !== undefined}
                 onDragStart={(event) =>
                     this.props.onDragStart?.(event, SidebarContentDragElementType.TEXT, "syllable-word-" + wordWithoutSyllables)}>
                {wordWithoutSyllables}
            </div>
        }
        </>
    }
}
