import React from "react";
import {MainContext} from "../../../_base/MainContext";
import _ from "lodash";


interface IProps {
    id: string
    solutionPosition: number
    value: string
    fontSize: number
    borderColor: string
    solutionShow: boolean
}

interface IState {
    strikeThroughLineLength?: number
}

export class WDShapeCraftPatternSolutionBarValue extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }
    componentDidMount() {
        this.recalculateLineLength()
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        this.recalculateLineLength()
    }

    recalculateLineLength = () => {
        let solutionBar = document.getElementById(this.props.id + "-solution-bar")
        let solutionBarValue = document.getElementById(this.props.id + "-" + this.props.solutionPosition + "-solution-bar-value")
        let solutionBarStrikeThrough = document.getElementById(this.props.id + "-" + this.props.solutionPosition + "-solution-bar-value-strike-through")

        if(solutionBar && solutionBarValue && solutionBarStrikeThrough) {
            let zoom = this.context.getZoom()
            let rectSolutionBar = solutionBar.getBoundingClientRect()
            let rect = solutionBarValue.getBoundingClientRect()

            let length = Math.sqrt(Math.pow(rect.width / zoom, 2) + Math.pow(rect.height / zoom, 2))
            let degree = -Math.atan2((rect.height / zoom), (rect.width / zoom)) * (180 / Math.PI);
            let position = rect.left / zoom - rectSolutionBar.left / zoom - ((length - rect.width / zoom) / 2)

            solutionBarStrikeThrough.style.setProperty("--strikeThroughLength", length + "px")
            solutionBarStrikeThrough.style.setProperty("--strikeThroughAngle", degree + "deg")
            solutionBarStrikeThrough.style.setProperty("--strikeThroughPositionLeft", position + "px")
        }
    }

    render() {
        return <div className={"ws-designer-shape-solution-bar-value"}
                    id={this.props.id + "-" + this.props.solutionPosition + "-solution-bar-value" }
                    key={this.props.id + "-" + this.props.solutionPosition + "-solution-bar-value"}
                    style={{borderColor: '#' + this.props.borderColor, fontSize: this.props.fontSize}}>

            {(this.props.solutionShow) &&
                <div className={"ws-designer-shape-solution-bar-value-checked"}
                     id={this.props.id + "-" + this.props.solutionPosition + "-solution-bar-value-strike-through"}/>
            }
            <div> {this.props.value} </div>
        </div>
    }
}
