import React, {ChangeEvent} from "react";
import {MainContext} from "../../_base/MainContext";

interface IProps {
    value: number

    minValue?: number
    maxValue?: number
    unit?: string

    width?: number

    onChangeValue: (value: number) => void
}
interface IState {
    value: number
}

export class Slider extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            value: this.props.value,
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.value && this.props.value !== prevProps.value) {
            this.setState({value: this.props.value})
        }
    }

    onChangeValue = (value: number) => {
        this.setState({value: value})
        this.props.onChangeValue(value)
    }

    render () {
        return <div className={"slider"} style={{width: this.props.width}}>

            <input type="range"
                   style={{width: "90%"}}
                   min={this.props.minValue}
                   max={this.props.maxValue}
                   value={this.state.value}
                   onChange={(e: ChangeEvent) => this.onChangeValue(+(e.target as HTMLInputElement).value)} />
            <span style={{paddingLeft: "5px"}}>{this.state.value}{this.props.unit}</span>

        </div>
    }
}

