import React from "react";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {ResizeInfo, WDElementContainer} from "../WDElementContainer";
import Const from "../../../Framework/Const";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../Framework/CategoryImage";
import {Util} from "../../../Framework/Util";
import {WDWritingLineature} from "../Lineature/WritingLineature/WDWritingLineature";
import {WDShapeCraftPatternSolutionBarValue} from "./WDShapeCraftPatternSolutionBarValue";
import {WDPresentationAction} from "../../Presentation/WDPresentationAction";
import {SolutionForceMode} from "../../../Framework/Enums";

export class WDShapeCraftPatternData extends WDElementBaseData {
    imageKey: string
    solutionShow: boolean
    showSolutionBar: boolean
    solutionBarValues: string[]
    fontSize: number

    constructor(imageKey: string, solutionShow: boolean, showSolutionBar: boolean) {
        super()
        this.imageKey = imageKey
        this.solutionShow = solutionShow
        this.showSolutionBar = showSolutionBar
        this.solutionBarValues = []
        this.fontSize = 24
    }

    static serialize = (data: WDShapeCraftPatternData): string => {
        return JSON.stringify(data, (key, value) => {
            if (key === "url" || key === "width" || key === "height") return undefined;
            else return value;
        })
    }
}
export class WDShapeCraftPatternSolutionBarData {
    imageKey: string
    solutionBarValues: string[]

    constructor(imageKey: string, solutionBarValues: string[]) {
        this.imageKey = imageKey
        this.solutionBarValues = solutionBarValues
    }
}


interface IProps extends WDElementBaseProps {
    data: WDShapeCraftPatternData
    isReadOnly: boolean
}

interface IState extends WDElementBaseState {
}

export class WDShapeCraftPattern extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    solutionBarValues = [
        new WDShapeCraftPatternSolutionBarData("FORM_CRAFT_B_DE_CUBE", ["6", "12", "Würfel", "8", "a · a · a", "6a²"]),
        new WDShapeCraftPatternSolutionBarData("FORM_CRAFT_B_DE_CUBOID", ["6", "12", "Quader", "8", "2ab + 2ac + 2bc", "a · b · c"]),
        new WDShapeCraftPatternSolutionBarData("FORM_CRAFT_B_DE_CONE", ["2", "1", "Kegel", "1 : 3 · π r² h", "π · r2 + π r s", "1", "0"]),
        new WDShapeCraftPatternSolutionBarData("FORM_CRAFT_B_DE_PYRAMID", ["5", "8", "Pyramide", "5", "a² · h : 3", "a · a + 2 · a · h"]),
        new WDShapeCraftPatternSolutionBarData("FORM_CRAFT_B_DE_CYLINDER", ["3", "2", "Zylinder", "0", "π · r2 · h", "2 · G + M"]),
    ]
    initialFontSize: number = 24

    constructor(props: IProps) {
        super(props);

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef()
        }
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getMinWidth = () => {
        return 50
    }
    getMinHeight = () => {
        return 50
    }

    static getDefaultWidth = () => {
        return 500
    }
    static getDefaultHeight = () => {
        return 500
    }
    getDefaultImageSrc = () => {
        let image = CategoryImageValue.getImageByKey([ImageCategory.FORMS_CRAFT_PATTERN], this.props.data.imageKey)
        return image.replace("-thumb.svg", "")
    }

    doPresentationAction = (action: WDPresentationAction, data?: any): WorksheetItemUpdate => {
        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDPresentationAction.CHANGE_SOLUTION_SHOW:
                update.value.renderSolutionInPresentationMode = !this.props.element.renderSolutionInPresentationMode
                break
        }

        return update
    }
    doAction = (action: WDToolbarAction, data?: string) => {
        let newData = {...this.props.data}

        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.CHANGE_GRAPHIC:
                if(data && data["image"]) {
                    newData.imageKey = data["image"]

                    // always set solution to false if image is changed from exercise to normal shape
                    if(this.props.data.imageKey.startsWith("FORM_CRAFT_B_") && newData.imageKey.startsWith("FORM_CRAFT_F_")) {
                        newData.showSolutionBar = false
                        newData.solutionShow = false
                    }

                    if(newData.showSolutionBar) {
                        newData.solutionBarValues = this.getSolutionBarValues(newData.imageKey)
                    }
                }
                break

            case WDToolbarAction.CHANGE_SOLUTION_SHOW:
                if(data) {
                    newData.solutionShow = data["showSolution"]
                }
                break

            case WDToolbarAction.SOLUTION_BAR:
                if(data) {
                    newData.showSolutionBar = data["showSolutionBar"]

                    if(data["showSolutionBar"]) {
                        newData.solutionBarValues = this.getSolutionBarValues()
                    }
                }
                break

            default:
                break
        }

        update.value.content = this.serializeElementData(newData)
        return update
    }

    getSolutionBarValues = (imageKey?: string) => {
        let solutionBarValueEntities = this.solutionBarValues.filter(v => v.imageKey === (imageKey || this.props.data.imageKey))
        return (solutionBarValueEntities.length > 0 ? Util.shuffleArray(solutionBarValueEntities[0].solutionBarValues) : [])
    }
    calculateFontSize = (width?: number) => {
        // Always calculate fontsize - so it is right when dragging from menu!!
        let fontSize = this.initialFontSize / WDWritingLineature.getDefaultWidth()
        return fontSize * (width || this.props.element.width)
    }

    onResizeElement = (proportional: boolean, x: number, y: number) => {
        this.props.onElementResize?.(true, x, y)
    }

    /**
     * Overridden methods
     */
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDShapeCraftPatternData.serialize(data as WDShapeCraftPatternData)
    }
    recalculateSize = (width: number, height: number): WorksheetItemUpdate => {
        let newData = {...this.props.data}
        newData.fontSize = this.calculateFontSize(width)

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(newData),
            width: width,
            height: height
        })
    }

    renderSolution = () : boolean => {
        if (this.props.inPresentationMode) {
            return this.props.element.renderSolutionInPresentationMode
        }
        else {
            return (this.props.solutionForceMode === SolutionForceMode.ForceShow ||
                (this.props.solutionForceMode === SolutionForceMode.Off && this.props.data.solutionShow))
        }
    }
    render() {
        let resizeInfo: ResizeInfo =
            new ResizeInfo(!this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly,
                false, false, false, false,
                this.getMinWidth(), Const.MaxElementSize, this.getMinHeight(), Const.MaxElementSize)

        // if element is marked as deleted, do not render
        if(this.props.element.deleted) {
            return <></>
        }

        let defaultSrc = this.getDefaultImageSrc()
        let shapeBorder = defaultSrc.substring(0, defaultSrc.length - 2) + "-r.svg"
        let shapeColor = defaultSrc + ".svg"
        let solution = defaultSrc + "-loesung.svg"
        let solutionCounter = -1

        let borderColorClass = " svg-color-" + this.props.element.borderColor.replace("#", "")
        let fillColorClass = " svg-color-" + this.props.element.fillColor.replace("#", "")

        let renderSolution = this.renderSolution()

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            hasResizeOnCreate={false}
            resizeInfo={resizeInfo}
            renderWrapper={true}
            renderBackgroundColor={false}
            renderBorder={false}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.onResizeElement}
            isEditModeAllowed={() => false}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            ref={this.state.elementRef}
        >

            <div className={"ws-designer-shape print"}>
                {this.props.element.fillColor !== 'transparent' &&
                <img className={"ws-designer-shape-image" + fillColorClass} alt={""}
                     src={process.env.PUBLIC_URL + ImagePath.getFormsUrl() + shapeColor}/>
                }
                <img className={"ws-designer-shape-image" + borderColorClass} alt={""}
                     src={process.env.PUBLIC_URL + ImagePath.getFormsUrl() + shapeBorder}/>

                {renderSolution &&
                    <img className={"ws-designer-shape-image solution" + fillColorClass} alt={""}
                         src={process.env.PUBLIC_URL + ImagePath.getFormsUrl() + solution}/>
                }

                {this.props.data.showSolutionBar &&
                    <div className={"ws-designer-shape-solution-bar"} id={this.props.id + "-solution-bar"}>
                        {this.props.data.solutionBarValues.map(v => {
                            solutionCounter++
                            return <WDShapeCraftPatternSolutionBarValue
                                id={this.props.id}
                                key={this.props.id + "-" + solutionCounter + "-solution-bar-value"}
                                solutionPosition={solutionCounter}
                                value={v}
                                fontSize={this.props.data.fontSize}
                                borderColor={this.props.element.borderColor}
                                solutionShow={renderSolution}/>
                            }
                        )}
                    </div>
                }
            </div>

        </WDElementContainer>
    }
}
