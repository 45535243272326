import React from "react";

interface IProps extends React.PropsWithChildren {
    userRibbon: boolean
}

export class Ribbon extends React.Component<IProps> {

    render () {
        let cssClass = "ribbon" + (this.props.userRibbon ? " ribbon-user" : "")

        return <div id="ribbon" className={cssClass}>
            {this.props.children}
        </div>
    }
}
