import React from "react";
import Entity from "../../_model/Entity";
import {DropDown, DropDownItem, DropDownType} from "./DropDown";

export enum LabelPosition {
    top,
    left
}

interface IProps {
    id: string,
    width: number,

    label?: string,
    labelClass?: string,
    labelWidth?: number,
    style?: React.CSSProperties,

    data: Entity[],
    value?: number,

    required: boolean,
    readonly: boolean,
    positioningRow: boolean

    onChange?: (value: number) => void
}

interface IState {

}

export default class SelectBox extends React.Component<IProps, IState> {

    onChange = (value: string) => {
        this.props.onChange?.(+value)
    }

    render() {
        const items = this.props.data.map(item => new DropDownItem(item.id !== undefined ? item.id.toString() : "", item.name))

        return <div className={this.props.positioningRow ? "form-row" : "form-group"} style={this.props.style}>
            <label className={this.props.labelClass || "bold-label"} htmlFor={this.props.id}>{this.props.label}</label>

            <DropDown id={this.props.id}
                      type={DropDownType.TEXT}
                      value={this.props.value !== undefined ? this.props.value.toString() : ""}
                      required={this.props.required}
                      width={this.props.width}
                      items={items}
                      onChangeValue={this.onChange}
                      readonly={this.props.readonly}
                      autocomplete={true}
            />
        </div>
    }
}
