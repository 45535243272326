import React from "react";
import {MainContext} from "../../../../_base/MainContext";
import translations from "../../../../Framework/translations.json";
import {GetAdjective} from "../../../../_endpoint/WordEndpoint";
import {Status} from "../../../../Framework/Enums";
import {SyllableDefinition, WDSyllableWord} from "../../../Elements/Textbox/WDSyllableWord";
import {SidebarContentDragElementType} from "../../SidebarContentBase";
import {ImagePath} from "../../../../Framework/CategoryImage";
import Adjective from "../../../../_model/Dictionary/Adjective";
import Image from "../../../../_model/Image";

interface IProps {
    wordId?: number
    syllableDefinition: SyllableDefinition
    onDragStart: (event: React.DragEvent, dragElementType: SidebarContentDragElementType, elementId: string, image?: Image, spellCheck?: boolean) => void
    onClickNewWord: (wordId: number) => void
}
interface IState {
    adjective?: Adjective
    irregularText: string
}

export class SidebarDictionaryWordFormAdjective extends React.Component<IProps, IState>{
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            irregularText: ""
        }
    }

    componentDidMount() {
        this.fetchAdjective()
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(this.props.wordId !== prevProps.wordId) {
            this.fetchAdjective()
        }
    }

    fetchAdjective = () => {
        if(this.props.wordId) {
            GetAdjective(this.props.wordId).then(
                (itemData) => {
                    this.setState({adjective: itemData}, () => {
                        this.getIrregularText()
                    })
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }
    getIrregularText = () => {
        let text = ""
        if(this.state.adjective?.irregular === true) {
            text = this.context.translate(translations.text.yes)
        } else if(this.state.adjective?.irregular === false) {
            text = this.context.translate(translations.text.no)
        }

        this.setState({irregularText: text})
    }

    render() {
        if (this.state.adjective === null || this.state.adjective === undefined) {
            return <></>
        }

        return <>
            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.adjective.irregular) + ":"}
                </div>
                <div className={"ws-designer-dictionary-form-entry-row-text"}>
                    {this.state.irregularText}
                </div>
            </div>

            <div className={"ws-designer-dictionary-table-label"}>
                {this.context.translate(translations.fields.word.adjective.forms) + ":"}
            </div>
            <div className={"ws-designer-dictionary-table-container"}
                 draggable={true}
                 onDragStart={(event) =>
                    this.props.onDragStart(
                    event,
                    SidebarContentDragElementType.TABLE,
                    "ws-designer-dictionary-table-adjective-forms",
                        undefined,
                        !this.props.syllableDefinition)}>
                <div className={"table-grabber"}>
                    <img
                        draggable={"false"}
                        alt={this.context.translate(translations.command.move)}
                        src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                </div>
                <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-adjective-forms"}>
                    <tbody>
                    <tr>
                        <td>{this.context.translate(translations.fields.word.adjective.basic_form)}</td>
                        <td>{this.state.adjective.basicForm &&
                                <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                syllableWord={this.state.adjective.basicForm}/>}
                        </td>
                    </tr>
                    <tr>
                        <td>{this.context.translate(translations.fields.word.adjective.comparative)}</td>
                        <td>{this.state.adjective.comparative &&
                                <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                syllableWord={this.state.adjective.comparative}/>}
                        </td>
                    </tr>
                    <tr>
                        <td>{this.context.translate(translations.fields.word.adjective.superlative)}</td>
                        <td>
                            <div className={"ws-designer-dictionary-table-cell-row"}>
                                {this.state.adjective.superlative &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={"am"}/>}

                                &nbsp;

                                {this.state.adjective.superlative &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.adjective.superlative}/>}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.adjective.use_cases) + ":"}
                </div>
            </div>
            <div className={"ws-designer-dictionary-form-entry-row-text-new-line"}>
                {this.state.adjective?.adjectiveUseCases
                    ?.map((useCase, i, arr) => {
                        return <div className={" ws-designer-dictionary-form-list-entry"} key={"use-case-" + i}>
                            {useCase.name}
                            {(i < arr.length - 1) &&
                                <div style={{marginRight: "5px"}}>,</div>
                            }
                        </div>
                    })
                }
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.adjective.opposite) + ":"}
                </div>
            </div>
            <div className={"ws-designer-dictionary-form-entry-row-text-new-line"}>
                {this.state.adjective.opposites
                    ?.filter(opp => opp.status === Status.published)
                    .map((opp, i, arr) => {
                        if(opp.syllabification !== undefined) {
                            return <div className={" ws-designer-dictionary-click"}
                                        key={"adjective-opposite-" + opp.id}
                                        onClick={() => opp.id && this.props.onClickNewWord(opp.id)}>
                                <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                syllableWord={opp.syllabification}/>

                                {(i < arr.length - 1) &&
                                    <div style={{marginRight: "5px"}}>,</div>
                                }
                            </div>
                        }
                        return ""
                    })
                }
            </div>
        </>

    }
}
