import Entity from "../../../_model/Entity";

export class SalesMaterialListItem extends Entity {
    status: string
    modifiedOn: Date
    owner: string
    createdBy: string

    constructor(id: number, name: string, status: string, owner: string, modifiedOn: Date, createdBy: string) {
        super(name, id);

        this.owner = owner
        this.modifiedOn = modifiedOn
        this.createdBy = createdBy
        this.status = status
    }
}

export default SalesMaterialListItem
