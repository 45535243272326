import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "./WDToolbarElement";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbar, WDToolbarOrientation} from "./WDToolbar";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "./WDToolbarTabs";
import {WDToolbarSectionTransformation} from "../Section/WDToolbarSectionTransformation";
import Converter from "../../../Framework/Converter";
import Const from "../../../Framework/Const";
import {ElementLayout} from "../../Elements/WDElementContainer";
import {WSContextType} from "../../Elements/WSContext";

export interface WDToolbarMixedProps extends WDToolbarElementProps { }
export interface WDToolbarMixedState extends WDToolbarElementState { }

export class WDToolbarMixed extends WDToolbarElement<WDToolbarMixedProps, WDToolbarMixedState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: WDToolbarMixedProps, state: WDToolbarMixedState) {
        super(props, state);

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: this.mapEditModeToToolbarType(),
            refToolbar: React.createRef()
        }
    }

    componentDidUpdate(prevProps: WDToolbarMixedProps) {

        // Check if new selected element is different from previous selected element by data
        if (this.props.editMode === prevProps.editMode &&
            this.props.worksheetItemData.length === prevProps.worksheetItemData.length &&
            this.props.worksheetItemData.every((value, index) => { return value === prevProps.worksheetItemData[index]})) {

            return
        }

        this.updateToolbarValues()
    }
    updateToolbarValues = () => {
        this.setState({ activeId: -1, activeToolbarType: this.mapEditModeToToolbarType() })
    }

    componentDidMount() {
        this.setState({activeId: -1})
    }
    mapEditModeToToolbarType = () => {
        return WDToolbarTypeEnum.TRANSFORMATION
    }

    render () {

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(false, true, false, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minWidth: Math.round(Converter.pxToMm(Const.MinElementSize)),
                        minHeight: Math.round(Converter.pxToMm(Const.MinElementSize)),
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={this.props.context === WSContextType.text_exercise_child}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={true}
                    canChangeLinkState={true}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onChangeGroupingStatus={this.props.onChangeGroupingStatus}
                    onDuplicate={this.props.onDuplicate}
                    onCut={this.props.onCut}
                    onCopy={this.props.onCopy}
                    onPaste={this.props.onPaste}
                    onElementDeleted={this.props.onElementDeleted}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                    onChangeLockingStatus={this.props.onChangeLockingStatus}
                />
            </ul>
            }
        </WDToolbar>
    }
}
