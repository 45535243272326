import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import {TooltipText} from "../../../../Components/Tooltips";
import {ImagePath} from "../../../../Framework/CategoryImage";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";

/**
 * Action Button in Text Toolbar
 * Effects selected text of an element
 * Marked text should be marked as variable text in exercises
 */


interface IProps {
    id: number
    clickable: boolean
    action: WDToolbarAction

    onActionButton: (action: WDToolbarAction) => void
}

interface IState {}

export class WDToolbarButtonVariableText extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onToggle = () => {
        this.props.onActionButton(this.props.action)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-solution"}
            className={"ws-designer-toolbar-item"}
            id={"toolbar-button-solution"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + "variable_text.svg"}
                             iconClassSelected={"ws-designer-toolbar-button-img svg-color-primary"}
                             iconClassUnselected={"ws-designer-toolbar-button-img"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.variable_text), this.context.translate(translations.tooltip.variable_text))}
                             onToggleButton={this.onToggle}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={false} />

        </li>
    }
}
