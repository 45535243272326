import {EntityData} from "./Entity";
import {Worksheet} from "./Worksheet";
import LessonSubject from "./LessonSubject";
import SchoolLevel from "./SchoolLevel";
import Tag from "./Tag";
import User from "./User";
import {Difficulty, ExerciseCalculationType, ExerciseEpicType, ExerciseTextType, Status} from "../Framework/Enums";
import {MenuItem} from "./MenuItem";
import ExerciseCategory from "./ExerciseCategory";
import WordHighlight from "./Dictionary/WordHighlight";

export class Exercise extends EntityData {
    difficulty?: Difficulty
    description?: string
    calculationType?: ExerciseCalculationType
    parentExercise?: Exercise
    flagComma: boolean
    textType?: ExerciseTextType
    epicType?: ExerciseEpicType

    worksheet?: Worksheet
    lessonSubject?: LessonSubject
    exerciseCategory?: ExerciseCategory
    schoolLevels?: SchoolLevel[]
    menuEntries?: MenuItem[]
    tags?: Tag[]
    wordHighlights?: WordHighlight[]
    exercises?: Exercise[]

    constructor(name: string, status: Status, createdOn?: Date, createdBy?: User, modifiedOn?: Date, modifiedBy?: User,
                ownerId?: User, id?: number) {

        super(name, status, createdOn, createdBy, modifiedOn, modifiedBy, ownerId, id);

        this.flagComma = false
        this.schoolLevels = []
        // this.exercises = []
        this.wordHighlights = []
    }
}

export default Exercise
