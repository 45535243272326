import React, {FormEvent} from "react";
import '../assets/css/layout.min.css';
import {MainContext} from "../_base/MainContext";
import {Modal} from "../Components/Modal";
import translations from "../Framework/translations.json";
import SelectBox from "../Components/Controls/SelectBox";
import {GetAllAdmins} from "../_endpoint/UserEndpoint";
import Auth from "../Framework/Auth";
import User from "../_model/User";
import Entity from "../_model/Entity";
import TextBox from "../Components/Controls/TextBox";
import {Status} from "../Framework/Enums";
import {ButtonInfo} from "../Components/Controls/ButtonList";

interface IProps {
    status: Status
    allowStatusChangeToUserItself: boolean

    onSave: (user?: User, comment?: string) => void
    onCancel: () => void
}

interface IState {
    user: User[]
    selectedUser?: User
    comment: string
}

export class AdminStatusConfirmationModal extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = ({
            user: [],
            comment: ""
        })
    }

    componentDidMount() {
        GetAllAdmins().then((result) => {
            if(this.props.allowStatusChangeToUserItself) {
                this.setState({user: result})
            } else {
                this.setState({user: result.filter(i => i.id !== Auth.getUserId())})
            }
        }, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })
    }

    onChangeUser = (id: number) => {
        this.setState({selectedUser: this.state.user.find(i => i.id === id)})
    }
    onChangeComment = (value: string) => {
        this.setState({comment: value})
    }

    onFormSubmit = (event: FormEvent) => {
        event.preventDefault();

        this.props.onSave(this.state.selectedUser, this.state.comment)
    }
    onFormCancel = () => {
        this.props.onCancel()
    }

    isApproval = () => {
        return this.props.status === Status.approval
    }

    render () {
        let users = this.state.user.map(i => { return new Entity(i.firstname + " " + i.lastname, i.id) })

        let height = this.isApproval() ? "325px" : "250px"
        let commentRequired = !this.isApproval()

        return <Modal  id={"statusConfirmation"}
                       onFormSubmit={this.onFormSubmit}
                       onFormCancel={this.onFormCancel}
                       title={this.context.translate(translations.command.status_change) + ": " + this.context.translate(translations.enum.status[this.props.status])}
                       buttons={[
                           new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onFormCancel, {}),
                           new ButtonInfo("btnSave", "button button-save", "submit", true, false, this.context.translate(translations.command.save), undefined, {marginLeft: "10px"})
                       ]}
                       dialogStyle={{width: "500px", height: height}}
                       contentAlignment={"flex-start"}>

            {this.isApproval() &&
            <SelectBox id={"selUser"}
                       width={300}
                       required={true}
                       readonly={false}
                       positioningRow={false}
                       label={this.context.translate(translations.text.select_user_to_approve)}
                       onChange={this.onChangeUser}
                       data={users}
                       value={this.state.selectedUser?.id}
                       style={{paddingTop: "30px"}}
            />
            }

            <div style={{paddingTop: 20, paddingBottom: 20}}>
                <label className={"bold-label"} htmlFor={"txtComment"}>{this.context.translate(translations.text.comment)}</label>
                <TextBox id={"txtComment"}
                         width={300}
                         value={this.state.comment}
                         placeholder={this.context.translate(translations.text.comment)}
                         required={commentRequired}
                         readonly={false}
                         multiline={true}
                         onChange={this.onChangeComment}
                />
            </div>
        </Modal>
    }
}
