import {EntityData} from "./Entity";
import {Worksheet} from "./Worksheet";
import LessonSubject from "./LessonSubject";
import User from "./User";
import {Color, Difficulty, Status} from "../Framework/Enums";
import Tag from "./Tag";
import {WDWritingLineatureSize} from "../Designer/Elements/Lineature/WritingLineature/WDWritingLineature";
import {WDMathLineatureSize} from "../Designer/Elements/Lineature/MathLineature/WDMathLineatureData";

export enum WritingCourseCharacterTypeEnum {
    digit = "digit",
    block_letter = "block_letter",
    script = "script",
    swing_exercise = "swing_exercise",
    calligraphy = "calligraphy"
}

export class WritingCourse extends EntityData {
    description?: string
    difficulty?: Difficulty
    parentWritingCourse?: WritingCourse
    startNumber?: boolean
    sequenceNumber?: boolean
    traceArrow?: boolean
    syllableMethod?: boolean
    previewSymbol?: boolean
    previewSymbolTrace?: boolean
    previewSymbolImage?: boolean
    upperLowerLetterMixed?: boolean
    mixedLineature?: boolean
    usedInMenu?: boolean
    writingLineatureSize?: WDWritingLineatureSize
    mathLineatureSize?: WDMathLineatureSize
    color?: Color

    worksheet?: Worksheet
    lessonSubject?: LessonSubject
    characterType?: WritingCourseCharacterTypeEnum
    writingCourses?: WritingCourse[]
    tags?: Tag[]

    constructor(name: string, status: Status, createdOn?: Date, createdBy?: User, modifiedOn?: Date, modifiedBy?: User,
                ownerId?: User, id?: number) {

        super(name, status, createdOn, createdBy, modifiedOn, modifiedBy, ownerId, id);

        this.writingCourses = []
    }
}

export default WritingCourse
