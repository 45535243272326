import {WDElementBase, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {WorksheetItem} from "../../../_model/WorksheetItem";
import {MainContext} from "../../../_base/MainContext";
import React from "react";
import translations from "../../../Framework/translations.json";
import {ElementLayout, ResizeInfo, WDElementContainer} from "../WDElementContainer";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import Const from "../../../Framework/Const";
import {
    GetAlternativeWritingCourse,
    GetChildByParentAndName,
    GetMenuChildWritingCourse,
    GetRandomWritingCourse,
    GetWritingCourseWorksheetItems
} from "../../../_endpoint/WritingCourseEndpoint";
import {WritingCourseFilter} from "../../../_model/WritingCourseFilter";
import WDWritingCourseData, {WritingCourseCharacterTypeEnum} from "../../../_model/WritingCourse";
import {FloatingHint} from "../../../Components/Notification/FloatingHint";
import {NotificationStatus} from "../../../Framework/Enums";
import {NotificationData} from "../../../Components/Notification/Hint";
import {WDUtils} from "../../Utils/WDUtils";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {WDActionLogCategory} from "../../ActionLog/WDActionLogEntry";

interface IProps extends WDElementBaseProps {
    data: WDWritingCourseData
    elements: WorksheetItem[]

    isReadOnly: boolean

    renderElement: (element: WorksheetItem) => JSX.Element
    addElementToGroup: (groupKey: string, element: WorksheetItem) => void
    deleteChildren: (itemKey: string) => void
}

interface IState extends WDElementBaseState {
    isDragging: boolean
    error?: string
}

export class WDWritingCourse extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    loading: boolean = false
    resizeInfo: ResizeInfo =
        new ResizeInfo(false, false, false, false,
            false, false, false, false,
            300, Const.MaxElementSize, 300, Const.MaxElementSize)

    constructor(props: IProps) {
        super(props);

        this.state = {
            isEdited: false,
            isDragging: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,

            elementRef: React.createRef(),
        }
    }

    componentDidMount() {
        // New instance of writing course created by user (menu level 4)
        if (this.props.data.id === undefined && this.props.data.parentWritingCourse) {
            this.loadFirstChildCourse(this.props.data.parentWritingCourse)
        }
        // New instance of writing course created by user (menu level 3)
        else if (this.props.data.id === undefined && this.props.data.parentWritingCourse === undefined) {
            this.loadRandomCourse(this.props.data.parentWritingCourse, this.props.data.characterType,
                this.props.data.syllableMethod, this.props.data.previewSymbol)
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    loadFirstChildCourse = (parent: WDWritingCourseData) => {
        if (parent && parent.id) {
            GetMenuChildWritingCourse(parent.id!).then((course) => {
                    this.context.log.info("Child writing course id = " + course.id!)
                    this.context.log.flush()

                    let filter = new WritingCourseFilter()
                    filter.lessonSubject = parent.lessonSubject
                    filter.previewSymbol = parent.previewSymbol
                    filter.syllableMethod = parent.syllableMethod
                    filter.characterType = parent.characterType

                    this.instantiateWritingCourse(course, filter)
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.error.no_writing_course_found))
                })
        }
    }
    loadRandomCourse = (parent?: WDWritingCourseData, characterType?: WritingCourseCharacterTypeEnum, syllableMethod?: boolean, previewSymbol?: boolean, search?: string) => {
        let filter = new WritingCourseFilter()

        filter.parentWritingCourse = parent
        filter.characterType = characterType
        filter.syllableMethod = syllableMethod
        filter.previewSymbol = previewSymbol
        filter.search = search

        GetRandomWritingCourse(filter).then((course) => {
                this.context.log.info("Random writing course id = " + course.id!)
                this.context.log.flush()
                this.instantiateWritingCourse(course, filter)

            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.error.no_writing_course_found))
            })
    }
    loadChildByParentWritingCourse = (parent: WDWritingCourseData, childName: string) => {
        if (parent.id) {
            GetChildByParentAndName(parent.id, childName).then((course) => {
                    this.context.log.info("Child writing course id = " + course.id!)
                    this.context.log.flush()

                    let filter = new WritingCourseFilter()
                    filter.lessonSubject = parent.lessonSubject
                    filter.previewSymbol = parent.previewSymbol
                    filter.characterType = parent.characterType
                    filter.syllableMethod = parent.syllableMethod

                    this.instantiateWritingCourse(course, filter)
                },
                (error) => {
                    this.setState({error: this.context.translate(translations.error.no_writing_course_designs_found)})
                    this.context.handleError(error, this.context.translate(translations.error.no_writing_course_designs_found))
                })
        }
    }
    loadAlternativeWritingCourse = (search: string, filter: WritingCourseFilter) => {
        filter.search = search

        if (this.props.data.id) {
            GetAlternativeWritingCourse(this.props.data.id, filter).then((course) => {
                    this.context.log.info("Alternative writing course id = " + course.id!)
                    this.context.log.flush()

                    this.instantiateWritingCourse(course, filter)
                },
                (error) => {
                    this.setState({error: this.context.translate(translations.error.no_writing_course_designs_found)})
                    this.context.handleError(error, this.context.translate(translations.error.no_writing_course_designs_found))
                })
        }
    }
    instantiateWritingCourse = (course: WDWritingCourseData, filter?: WritingCourseFilter) => {
        GetWritingCourseWorksheetItems(course.id!).then(async (worksheetItems) => {
                this.context.log.info("Worksheet item found!")
                this.context.log.flush()

                this.loading = true

                let newData = {...this.props.data}
                newData.id = course.id
                newData.parentWritingCourse = course.parentWritingCourse
                newData.name = course.name
                newData.previewSymbol = filter ? filter.previewSymbol : course.parentWritingCourse?.previewSymbol
                newData.lessonSubject = filter ? filter.lessonSubject : course.parentWritingCourse?.lessonSubject
                newData.syllableMethod = filter ? filter.syllableMethod : course.parentWritingCourse?.syllableMethod
                newData.characterType = filter ? filter.characterType : course.parentWritingCourse?.characterType

                if (worksheetItems) {
                    // Delete existing child elements (images)
                    this.props.deleteChildren(this.props.id)

                    let writingCourseHTML = document.getElementById("writing-course-" + this.props.id)

                    if (writingCourseHTML !== null) {
                        let layout = ElementLayout.createFromWorksheetItem(this.props.element)
                        layout = WDUtils.convertRectToAnchorObjectCoordinates(this.props.id, layout)
                        let left, right, top, bottom

                        // remove childElements from worksheetItems -> instead add as children
                        worksheetItems = WDUtils.loadGroupStructureToHierarchy(worksheetItems)

                        // find values to calculate width and height
                        worksheetItems
                            .forEach(i => {
                                if (left === undefined || i.posX < left) {
                                    left = i.posX
                                }
                                if (right === undefined || (i.posX + i.width) > right) {
                                    right = i.posX + i.width
                                }
                                if (top === undefined || i.posY < top) {
                                    top = i.posY
                                }
                                if (bottom === undefined || (i.posY + i.height) > bottom) {
                                    bottom = i.posY + i.height
                                }
                            })

                        // change positioning of elements and add to designer
                        worksheetItems
                            .forEach(i => {
                                i = WorksheetItem.duplicate(i)

                                i.posX -= left
                                i.posY -= top

                                this.props.addElementToGroup(this.props.id, i)
                            })

                        // Resize container to fit the retrieved worksheet item
                        let container: ElementLayout = new ElementLayout(
                            layout.left,
                            layout.top,
                            right - left,
                            bottom - top)

                        let update = new WorksheetItemUpdate(this.props.id, {
                            content: this.serializeElementData(newData),
                            width: container.width,
                            height: container.height
                        })

                        this.props.onUpdateElement(update, {
                            applyToChildren: false,
                            actionCategory: WDActionLogCategory.create
                        })

                        this.loading = false
                    }
                }
            },
            (error) => {
                this.context.log.error(error.message)
                this.context.log.flush()

                this.setState({error: error.message})
            })
    }

    static getDefaultWidth = () => {
        return Const.WorksheetDefaultPageWidth;
    }
    static getDefaultHeight = () => {
        return Const.WorksheetDefaultPageHeight;
    }
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }

    doAction = (action: WDToolbarAction, data?: any) => {
        switch (action) {
            case WDToolbarAction.REGENERATE:
                let filter = new WritingCourseFilter()
                filter.search = this.props.data.name
                filter.characterType = this.props.data.characterType
                filter.syllableMethod = this.props.data.syllableMethod
                filter.previewSymbol = this.props.data.previewSymbol

                this.loadAlternativeWritingCourse(this.props.data.name, filter)
                break

            case WDToolbarAction.CHANGE_TASK:
                if (data && data["writingCourse"]) {
                    if (data["isParent"] === undefined || data["isParent"] === false) {
                        this.instantiateWritingCourse(data["writingCourse"])
                    } else {
                        this.loadChildByParentWritingCourse(data["writingCourse"], this.props.data.name)
                    }
                }
                break
        }

        return new WorksheetItemUpdate(this.props.id, {})
    }

    /**
     * Overridden methods
     */
    isEditModeAllowed = () => {
        return false
    }

    getElementExerciseWorksheet = () => {
        return this.props.data.parentWritingCourse?.worksheet
    }
    getSourceRecordId = () => {
        return this.props.data.parentWritingCourse?.id
    }

    onResizeElement = (proportional: boolean, x: number, y: number) => {
        const layout = this.state.elementRef.current?.resizeElement(false, x, y)
        if (layout) {
            this.props.onElementResize?.(false, x, y)
        }
    }

    onHideError = () => {
        this.setState({error: undefined})
    }

    render() {
        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            hasResizeOnCreate={false}
            renderWrapper={true}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.props.onElementResize}
            isEditModeAllowed={() => false}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            ref={this.state.elementRef}
            resizeInfo={this.resizeInfo}
        >

            {this.state.error &&
                <FloatingHint id={"error-text-exercise"}
                              notificationData={new NotificationData(NotificationStatus.info, this.state.error)}
                              style={{width: "500px", left: (this.props.element.width / 2) - 250, top: "15px"}}
                              animationTimeout={4000}
                              onHide={this.onHideError}/>
            }

            <div id={"writing-course-" + this.props.id} className={"ws-designer-writing-course print"}>
                {this.props.elements.map((element, index) => {
                    return <div id={element.itemKey + "-container"}
                                className={"ws-designer-element-container"}
                                key={index}
                                style={{
                                    left: element.posX,
                                    top: element.posY,
                                    width: element.width,
                                    height: element.height,
                                    zIndex: (element.posZ === undefined ? WDUtils.getMaxPosZOfElements(this.props.elements) : element.posZ)
                                }}
                                onDragOver={e => e.preventDefault()}
                                onDragEnter={e => e.preventDefault()}
                                onDragLeave={e => e.preventDefault()}
                    >
                        {this.props.renderElement(element)}
                    </div>
                })}
            </div>
        </WDElementContainer>
    }
}
