import {EntityData} from "./Entity";
import User from "./User";
import {Status} from "../Framework/Enums";
import Language from "./Language";

export default class Country extends EntityData {
    isoCode?: string
    phoneCode?: string
    languages?: Language[]

    constructor(name: string, status: Status, createdOn?: Date, createdBy?: User, modifiedOn?: Date, modifiedBy?: User,
                ownerId?: User, id?: number) {

        super(name, status, createdOn, createdBy, modifiedOn, modifiedBy, ownerId, id);

        this.status = status
    }
}
