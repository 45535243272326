import React from "react";
import {MainContext} from "../_base/MainContext";

interface IProps {
    title: string
    style: DashboardListStyle
    items: any[]
    renderData: (item: any, index: number) => JSX.Element

    width: number
    height: number
}

interface IState {
}

export enum DashboardListStyle {
    normal = "normal",
    primary = "primary",
}

export class DashboardList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        return <div className={"dashboard-list dashboard-list-" + this.props.style}
                    style={{width: this.props.width, height: this.props.height}}
                    key={"dashboard-list-" + this.props.title}>

                <div className={"dashboard-list-title"}>
                    {this.props.title}
                </div>
                <div className={"dashboard-list-items"}>
                    {this.props.items.map((item, index) => this.props.renderData(item, index))}
                </div>
        </div>
    }
}
