import React from "react";
import {Menu, MenuContext, MenuType} from "../Components/Menu/Menu";
import AppHeader from "../_base/AppHeader";
import {MainContext} from "../_base/MainContext";
import {DashboardList, DashboardListStyle} from "./DashboardList";
import translations from "../Framework/translations.json";
import DashboardKPI from "./DashboardKPI";
import {ImagePath} from "../Framework/CategoryImage";
import {GetMPWorksheetList, GetWorksheetListDashboard, GetWorksheetThumbnail} from "../_endpoint/WorksheetEndpoint";
import {Worksheet, WorksheetMarketplaceFilter} from "../_model/Worksheet";
import {Util} from "../Framework/Util";
import {Link, RouteComponentProps} from "react-router-dom";
import {GetSchoolLevel} from "../_endpoint/MetadataEndpoint";
import SchoolLevel from "../_model/SchoolLevel";
import {GetNumberOfMarketplaceWorksheets, GetNumberOfWorksheets} from "../_endpoint/KPIEndpoint";
import {MPStatusIcon} from "../Marketplace/MPStatusIcon";
import {TooltipPosition} from "../Components/Tooltips";

interface IProps extends RouteComponentProps {
}

interface IState {
    marketplaceItems: Worksheet[]
    marketplaceItemsIndex: number
    worksheetItems: Worksheet[]
    schoolLevels: SchoolLevel[]

    kpiWorksheetCount?: number
    kpiMarketplaceWorksheetCount?: number
}

export class Dashboard extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            marketplaceItems: [],
            marketplaceItemsIndex: 0,
            worksheetItems: [],
            schoolLevels: []
        }
    }

    componentDidMount() {
        this.getWorksheets()
        this.getSchoolLevel()

        this.getKPIs()
    }

    getSchoolLevel = () => {
        GetSchoolLevel().then(
            (result) => {
                this.setState({schoolLevels: result}, () => {
                    this.getMarketplaceItems()
                })
            },
            (error) => {
                this.context.handleError(error)
            })
    }
    getMarketplaceItems = () => {
        let filter = new WorksheetMarketplaceFilter()
        filter.schoolLevels = this.state.schoolLevels
        filter.format = undefined

        GetMPWorksheetList(filter, 0, 5).then(
            (itemData) => {
                this.setState({marketplaceItems: itemData}, () => {
                    itemData.forEach((item) => {
                        GetWorksheetThumbnail(item.id).then((itemThumb) => {
                                let items = this.state.marketplaceItems
                                let w = items.find(i => i.id === item.id)
                                w && (w.thumbnail = itemThumb.thumbnail)

                                this.setState({marketplaceItems: items})
                            },
                            (_) => {
                                // Ignore error (no thumbnail), default thumbnail is shown
                            }
                        )
                    })
                })
            }
        )
    }
    getWorksheets = () => {
        GetWorksheetListDashboard().then(
            (itemData) => {
                this.setState({worksheetItems: itemData}, () => {

                    itemData.forEach((item) => {
                        GetWorksheetThumbnail(item.id).then((itemThumb) => {
                                let items = this.state.worksheetItems
                                let w = items.find(i => i.id === item.id)
                                w && (w.thumbnail = itemThumb.thumbnail)

                                this.setState({worksheetItems: items})
                            },
                            (_) => {
                                // Ignore error (no thumbnail), default thumbnail is shown
                            }
                        )
                    })
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    getKPIs = () => {
        GetNumberOfWorksheets().then
        (kpi => this.setState({kpiWorksheetCount: kpi.value}),
            (error) => {
                this.context.handleError(error)
            })
        GetNumberOfMarketplaceWorksheets().then
        (kpi => this.setState({kpiMarketplaceWorksheetCount: kpi.value}),
            (error) => {
                this.context.handleError(error)
            })
    }

    changeMarketplaceIndex = (offset: number) => {
        if (this.state.marketplaceItemsIndex + offset < 0) {
            return
        }
        if (this.state.marketplaceItemsIndex + offset > this.state.marketplaceItems.length - 1) {
            return
        }

        this.setState({marketplaceItemsIndex: this.state.marketplaceItemsIndex + offset})
    }
    showWorksheetOnMarketplace = (id: number) => {

    }
    onOpenMyMarketplaceWorksheets = () => {
        let filter = WorksheetMarketplaceFilter.initFilter()
        filter.schoolLevels = this.state.schoolLevels
        filter.onlyMyWorksheets = true
        this.context.setMarketplaceFilter(filter, () => {
            this.props.history.push("/marketplace")
        })
    }

    renderMarketplaceItem = (item: any) => {
        // Causing warning: Each child in a list should have a unique "key" prop.
        return <>

        </>
    }
    renderNewOnMarketplaceItem = (item: Worksheet, index: number) => {
        if (index !== this.state.marketplaceItemsIndex) {
            return <></>
        }

        return <div className={"dashboard-marketplace-item"} key={"dashboard-marketplace-" + item.id!}>
            <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_previous.svg"}
                 className={"dashboard-marketplace-item-navigation " + (index > 0 ? "svg-color-primary" : "svg-color-D8D8D8")}
                 style={{left: 0}}
                 width={24}
                 draggable={"false"}
                 onClick={() => this.changeMarketplaceIndex(-1)}
                 onContextMenu={(e) => e.preventDefault()}
                 alt={this.context.translate(translations.command.prev)}
            />

            <Link to={"/marketplace/" + item.id!.toString()}>
                <img src={item.thumbnail
                    ? ("data:image/jpeg;base64," + item.thumbnail)
                    : process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                     alt={"thumbnail"}
                     draggable={"false"}
                     onContextMenu={(e) => e.preventDefault()}
                     className={"dashboard-marketplace-item-thumbnail " + (item.orientation ? "dashboard-marketplace-item-thumbnail-portrait" : "dashboard-marketplace-item-thumbnail-landscape")}
                />
            </Link>

            <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_next.svg"}
                 className={"dashboard-marketplace-item-navigation " + ((index < this.state.marketplaceItems.length - 1) ? "svg-color-primary" : "svg-color-D8D8D8")}
                 style={{right: 0}}
                 width={24}
                 draggable={"false"}
                 onClick={() => this.changeMarketplaceIndex(1)}
                 onContextMenu={(e) => e.preventDefault()}
                 alt={this.context.translate(translations.command.next)}
            />
        </div>
    }
    renderWorksheetItem = (item: Worksheet) => {
        let schoolLevel = item.schoolLevels?.sort((a, b) => a.sort - b.sort)
            .map(r => r.name).join(", ")

        return <Link to={"/designer/" + item.id!.toString()} draggable="false" style={{textDecoration: "none"}}
                     key={"dashboard-" + item.id!.toString()}>
            <div className={"dashboard-worksheet-item"}>
                <div>
                    <img src={item.thumbnail
                        ? ("data:image/jpeg;base64," + item.thumbnail)
                        : process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                         alt={"thumbnail"}
                         draggable={"false"}
                         onContextMenu={(e) => e.preventDefault()}
                         className={"dashboard-worksheet-item-thumbnail " + (item.orientation ? "dashboard-worksheet-item-thumbnail-portrait" : "dashboard-worksheet-item-thumbnail-landscape")}/>
                </div>
                <div className={"dashboard-worksheet-item-data"}>
                    <span style={{fontWeight: "bold"}}>{item.name}</span><br/>
                    {Util.formatDate(item.modifiedOn!)}<br/>
                    {item.subject && <>{item.subject.name}<br/></>}
                    {schoolLevel && <>{schoolLevel}<br/></>}
                    {item.marketplaceStatus &&
                        <div className={"dashboard-worksheet-item-data-mp-status"}>
                            <MPStatusIcon translateX={-80} translateY={-20} tooltipPosition={TooltipPosition.above}
                                          mpStatus={item.marketplaceStatus}/>
                            <span>{this.context.translate(translations.enum.marketplace_ws_status[item.marketplaceStatus])}</span>
                        </div>
                    }
                </div>
            </div>
        </Link>
    }
    renderInterestingItem = (item: any) => {
        // TODO: Render news from website

        return <></>
    }
    renderApps = (item: any) => {

        return <></>
    }

    render() {
        if (this.state === null) {
            return <></>
        }

        let publishedOnMarketplace = (this.context.translate(translations.dashboard.published_on_mp) as string).replace("%1%",
            this.state.kpiMarketplaceWorksheetCount === undefined ? "-" : this.state.kpiMarketplaceWorksheetCount.toString())
        // let yearsVicido = (this.context.translate(translations.dashboard.years_vicido) as string).replace("%1%", "2")
        let worksheets = (this.context.translate(translations.dashboard.worksheets) as string).replace("%1%",
            this.state.kpiWorksheetCount === undefined ? "-" : this.state.kpiWorksheetCount.toString())

        let worksheetDashboard: JSX.Element = <></>

        if (this.state.kpiWorksheetCount === undefined || this.state.kpiWorksheetCount === 0) {
            worksheetDashboard = <DashboardKPI loading={this.state.kpiWorksheetCount === undefined}
                                               alternative={false}
                                               style={{
                                                   justifyContent: "flex-start",
                                                   position: "relative",
                                                   margin: "0 15px 30px 15px"
                                               }}
                                               width={400} height={400}
            >
                {this.state.kpiWorksheetCount === 0 &&
                    <Link to={"/designer/new"} draggable="false" style={{textDecoration: "none"}}>
                        <div
                            style={{textAlign: "left"}}
                        >
                            <div className={"dashboard-list-title"}>{this.context.translate(translations.dashboard.welcome)}</div>
                            <p>
                                {this.context.translate(translations.dashboard.create_first_worksheet)}
                            </p>
                        </div>

                        <img alt={this.context.translate(translations.command.add)} className="plus-button-main"
                             src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "plus.svg"}
                             style={{width: "70px", height: "70px", paddingTop: 60}}
                             draggable={"false"}
                             onContextMenu={(e) => e.preventDefault()}
                        />

                        <img alt={this.context.translate(translations.command.add)}
                             src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "a-at-motivation-vici-hello.svg"}
                             style={{position: "absolute", right: -30, width: 200, height: 200, bottom: -15}}
                             draggable={"false"}
                             onContextMenu={(e) => e.preventDefault()}
                        />
                    </Link>
                }
            </DashboardKPI>
        } else if (this.state.kpiWorksheetCount > 0) {
            worksheetDashboard = <>
                <DashboardList title={this.context.translate(translations.dashboard.your_work)}
                               style={DashboardListStyle.normal}
                               items={this.state.worksheetItems}
                               renderData={this.renderWorksheetItem}
                               height={400}
                               width={400}
                />
            </>
        }

        return <div className="app-main-content">
            <AppHeader isAdminArea={false} app={this.context.translate(translations.app.dashboard)}/>
            <div className="dashboard-main">
                <div className="menu-container">
                    <Menu menuType={MenuType.app} menuContext={MenuContext.app}/>
                </div>
                <div className="dashboard-content">
                    <div className="dashboard-content-lists">
                        {/*<DashboardList title={this.context.translate(translations.dashboard.marketplace)}*/}
                        {/*               style={DashboardListStyle.primary}*/}
                        {/*               items={this.state.marketplaceItems}*/}
                        {/*               renderData={this.renderMarketplaceItem}*/}
                        {/*               height={400}*/}
                        {/*               width={400}*/}
                        {/*/>*/}

                        <DashboardList title={this.context.translate(translations.dashboard.new_on_marketplace)}
                                       style={DashboardListStyle.normal}
                                       items={this.state.marketplaceItems}
                                       renderData={this.renderNewOnMarketplaceItem}
                                       height={400}
                                       width={400}
                        />

                        {worksheetDashboard}

                        <div className={"dashboard-kpi-list"}>
                            <DashboardKPI loading={this.state.kpiMarketplaceWorksheetCount === undefined}
                                          alternative={true}
                                          onClick={this.onOpenMyMarketplaceWorksheets}>
                                <img src={process.env.PUBLIC_URL + ImagePath.getDashboardUrl() + "fireworks01.svg"}
                                     alt={publishedOnMarketplace}
                                     className={"svg-color-white"}
                                     draggable={"false"}
                                     onContextMenu={(e) => e.preventDefault()}
                                />
                                <div>{publishedOnMarketplace}</div>
                            </DashboardKPI>

                            {/*<DashboardKPI loading={false}>*/}
                            {/*    <img src={process.env.PUBLIC_URL + ImagePath.getDashboardUrl() + "anniversary03.svg"}*/}
                            {/*         alt={yearsVicido}*/}
                            {/*         className={"svg-color-white"}*/}
                            {/*         draggable={"false"}*/}
                            {/*         onContextMenu={(e) => e.preventDefault()}*/}
                            {/*    />*/}
                            {/*    <div>{yearsVicido}</div>*/}
                            {/*</DashboardKPI>*/}

                            {/*<DashboardKPI loading={false}>*/}
                            {/*    <div style={{fontSize: "40px"}}>{"103"}</div>*/}
                            {/*    <Rating rating={5}/>*/}
                            {/*    <div>{this.context.translate(translations.dashboard.stars_rating)}</div>*/}
                            {/*</DashboardKPI>*/}

                            <DashboardKPI loading={this.state.kpiWorksheetCount === undefined}
                                          alternative={true}
                            >
                                <Link to={"/designer/"} draggable="false" style={{textDecoration: "none"}}>
                                    <img
                                        src={process.env.PUBLIC_URL + ImagePath.getDashboardUrl() + "anniversary01.svg"}
                                        alt={worksheets}
                                        className={"svg-color-white"}
                                        draggable={"false"}
                                        onContextMenu={(e) => e.preventDefault()}
                                    />
                                    <div>{worksheets}</div>
                                </Link>
                            </DashboardKPI>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}
