export class WDPrintOptions {
    printWorksheet: boolean
    printSolutionSheets: boolean
    printAutomaticSolutions: boolean

    constructor(printWorksheet: boolean, printSolutionSheets: boolean, printSolutions: boolean) {
        this.printWorksheet = printWorksheet
        this.printSolutionSheets = printSolutionSheets
        this.printAutomaticSolutions = printSolutions
    }

    isValueSet() {
        return this.printWorksheet || this.printSolutionSheets || this.printAutomaticSolutions
    }
}
