import WDContextMenuGroup from "./WDContextMenuGroup";

export default class WDContextMenuItem {
    id: number
    name: string
    action?: () => void
    icon?: string
    shortcut?: string
    children?: WDContextMenuGroup
    disabled: boolean

    constructor(id: number, name: string, action?: () => void, icon?: string, shortcut?: string, children?: WDContextMenuGroup, disabled?: boolean) {
        this.id = id
        this.name = name
        this.action = action
        this.icon = icon
        this.shortcut = shortcut
        this.children = children
        this.disabled = disabled === undefined ? false : disabled
    }
}
