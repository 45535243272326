export enum VerbSeparationByIssue {
    Tätigkeit = "Tätigkeit",
    Vorgang = "Vorgang",
    Zustand = "Zustand"
}
export enum VerbSeparationByIndependence {
    Vollverb = "Vollverb",
    Hilfsverb = "Hilfsverb",
    Modalverb = "Modalverb"
}
export enum VerbSeparationByPerson {
    persönlich = "persönlich",
    unpersönlich = "unpersönlich"
}
export enum VerbSeparationByStrength {
    stark = "stark",
    schwach = "schwach"
}
export enum VerbBase3Help {
    ist = "ist",
    hat = "hat"
}
export enum VerbPerfectParticipleHelp {
    sein = "sein",
    haben = "haben"
}
export enum VerbHelpPastIndicativeSein {
    i = "bin",
    you = "bist",
    he = "ist",
    she = "ist",
    it = "ist",
    we = "sind",
    you_pl = "seid",
    they = "sind",
}
export enum VerbHelpPastIndicativeHaben {
    i = "habe",
    you = "hast",
    he = "hat",
    she = "hat",
    it = "hat",
    we = "haben",
    you_pl = "habt",
    they = "haben",
}
export enum VerbHelpPastConjunctiveSein {
    i = "sei",
    you = "seist",
    he = "sei",
    she = "sei",
    it = "sei",
    we = "seien",
    you_pl = "seiet",
    they = "seien",
}
export enum VerbHelpPastConjunctiveHaben {
    i = "habe",
    you = "habest",
    he = "habe",
    she = "habe",
    it = "habe",
    we = "haben",
    you_pl = "habet",
    they = "haben",
}
export enum VerbHelpPastConjunctive2Sein {
    i = "wäre",
    you = "wärst",
    he = "wäre",
    she = "wäre",
    it = "wäre",
    we = "wären",
    you_pl = "wärt",
    they = "wären",
}
export enum VerbHelpPastConjunctive2Haben {
    i = "hätte",
    you = "hättest",
    he = "hätte",
    she = "hätte",
    it = "hätte",
    we = "hätten",
    you_pl = "hättet",
    they = "hätten",
}
export enum VerbHelpFutureIndicativeSein {
    i = "werde",
    you = "wirst",
    he = "wird",
    she = "wird",
    it = "wird",
    we = "werden",
    you_pl = "werdet",
    they = "werden",
}
export enum VerbHelpFutureConjunctiveSein {
    i = "werde",
    you = "werdest",
    he = "werde",
    she = "werde",
    it = "werde",
    we = "werden",
    you_pl = "werdet",
    they = "werden",
}
export enum VerbHelpFutureConjunctive2Sein {
    i = "würde",
    you = "würdest",
    he = "würde",
    she = "würde",
    it = "würde",
    we = "würden",
    you_pl = "würdet",
    they = "würden",
}

export class Verb {
    wordId?: number
    syllabificationSingular?: string
    separationByIssue?: VerbSeparationByIssue
    separationByIndependence?: VerbSeparationByIndependence
    separationByPerson?: VerbSeparationByPerson
    separationByStrength?: VerbSeparationByStrength
    baseForm1?: string
    baseForm2?: string
    baseForm3?: string
    baseForm3Help?: VerbBase3Help

    presentInfinitive?: string
    presentParticiple?: string

    presentIndicativeI?: string
    presentIndicativeYou?: string
    presentIndicativeHe?: string
    presentIndicativeWe?: string
    presentIndicativeYouPL?: string
    presentIndicativeThey?: string

    presentConjunctiveI?: string
    presentConjunctiveYou?: string
    presentConjunctiveHe?: string
    presentConjunctiveWe?: string
    presentConjunctiveYouPL?: string
    presentConjunctiveThey?: string

    presentImperativeYou?: string
    presentImperativeYou2?: string
    presentImperativeWe?: string
    presentImperativeWe2?: string
    presentImperativeYouPL?: string
    presentImperativeYouPL2?: string
    presentImperativeThey?: string
    presentImperativeThey2?: string

    pastTenseIndicativeI?: string
    pastTenseIndicativeYou?: string
    pastTenseIndicativeHe?: string
    pastTenseIndicativeWe?: string
    pastTenseIndicativeYouPL?: string
    pastTenseIndicativeThey?: string

    pastTenseConjunctive2I?: string
    pastTenseConjunctive2You?: string
    pastTenseConjunctive2He?: string
    pastTenseConjunctive2We?: string
    pastTenseConjunctive2YouPL?: string
    pastTenseConjunctive2They?: string

    perfectParticiple?: string
    perfectParticipleHelp?: VerbPerfectParticipleHelp
    future1?: string
    future2?: string
}

export default Verb
