export enum AdverbType {
    Pronominaladverb = 'Pronominaladverb',
    Modaladverb = 'Modaladverb',
    Lokaladverb = 'Lokaladverb',
    Temporaladverb = 'Temporaladverb',
    Kausaladverb = 'Kausaladverb',
    Frage_und_Relativadverb = 'Frage_und_Relativadverb',
}

export class Adverb {
    wordId?: number
    syllabification?: string
    adverbType?: AdverbType

    toString = () : string => {
        return "- Adverb"
    }
}

export default Adverb
