import Converter from "../../../../Framework/Converter";
import {WDWritingLineatureSize} from "./WDWritingLineature";

const { detect } = require('detect-browser');
const browser = detect();

/**
 * Defines the line height and marginTop used in lineature textbox depending on the used additionalRowSpacing
 * @param additionalRowSpacing - the additional row spacing set by user
 * @param lineHeight - defines the lineHeight used for the text lines in textbox
 * @param marginTop - is the margin top of textbox
 */
export class WSDesignerWritingLineatureLineHeight {
    additionalRowSpacing: number
    lineHeight: number
    marginTop: number

    constructor(additionalRowSpacing: number, lineHeight: number, marginTop: number) {
        this.additionalRowSpacing = additionalRowSpacing
        this.lineHeight = lineHeight
        this.marginTop = marginTop
    }
}

/**
 * Defines all necessary values defined in lineature to show text with selected font correct
 * @param font - is the name of the selected font
 * @param fontSize - is the size for the selected font; it should not be resized as it may not fit lineatures after
 * @param rowSpacing - defines the margin of each row needed to write with this font within the rows
 * @param rowHeight - defines the row height needed to fit with the font size
 */
export class WDWritingLineatureFontValues {
    font: string
    fontSize: string
    rowSpacing: number
    textSpacing?: number
    rowHeight: number
    lineatureSize: string
    lineHeight: WSDesignerWritingLineatureLineHeight[]

    constructor(font: string, lineatureSize: string, fontSize: string, rowSpacing: number, rowHeight: number,
                lineHeight: WSDesignerWritingLineatureLineHeight[], textSpacing?: number) {

        this.font = font
        this.fontSize = fontSize
        this.rowSpacing = rowSpacing
        this.textSpacing = textSpacing
        this.rowHeight = rowHeight
        this.lineatureSize = lineatureSize
        this.lineHeight = lineHeight
    }
}

const fontValue = [
    // 10th lineature has negative rowspacing so lines are not rendered twice which would make it thicker
    new WDWritingLineatureFontValues("VicidoSans", "10", "40pt", -1, Math.round(Converter.mmToPx(10)),
        getLineHeight("VicidoSans", "10"), 1.2),
    new WDWritingLineatureFontValues("VicidoSans", "4-4-4", "32pt", 8, Math.round(Converter.mmToPx(12)),
        getLineHeight("VicidoSans", "4-4-4")),
    new WDWritingLineatureFontValues("VicidoSans", "6-6-6", "48pt", 13, Math.round(Converter.mmToPx(18)),
        getLineHeight("VicidoSans", "6-6-6")),
    new WDWritingLineatureFontValues("VicidoSans", "8-4", "32pt", 8, Math.round(Converter.mmToPx(12)),
        getLineHeight("VicidoSans", "8-4")),
    new WDWritingLineatureFontValues("VicidoSans", "8-8-8", "64pt", 19, Math.round(Converter.mmToPx(24)),
        getLineHeight("VicidoSans", "8-8-8")),
    new WDWritingLineatureFontValues("VicidoSans", "12-6", "48pt", 12, Math.round(Converter.mmToPx(18)),
        getLineHeight("VicidoSans", "12-6")),
    new WDWritingLineatureFontValues("VicidoSans", "16-8", "64pt", 17, Math.round(Converter.mmToPx(24)),
        getLineHeight("VicidoSans", "16-8")),

    // vicido Leseschrift
    new WDWritingLineatureFontValues("VicidoLS", "10", "40pt", -1, Math.round(Converter.mmToPx(10)),
        getLineHeight("VicidoLS", "10"), 1.2),
    new WDWritingLineatureFontValues("VicidoLS", "4-4-4", "32pt", 8, Math.round(Converter.mmToPx(12)),
        getLineHeight("VicidoLS", "4-4-4")),
    new WDWritingLineatureFontValues("VicidoLS", "6-6-6", "48pt", 13, Math.round(Converter.mmToPx(18)),
        getLineHeight("VicidoLS", "6-6-6")),
    new WDWritingLineatureFontValues("VicidoLS", "8-4", "32pt", 8, Math.round(Converter.mmToPx(12)),
        getLineHeight("VicidoLS", "8-4")),
    new WDWritingLineatureFontValues("VicidoLS", "8-8-8", "64pt", 19, Math.round(Converter.mmToPx(24)),
        getLineHeight("VicidoLS", "8-8-8")),
    new WDWritingLineatureFontValues("VicidoLS", "12-6", "48pt", 12, Math.round(Converter.mmToPx(18)),
        getLineHeight("VicidoLS", "12-6")),
    new WDWritingLineatureFontValues("VicidoLS", "16-8", "64pt", 17, Math.round(Converter.mmToPx(24)),
        getLineHeight("VicidoLS", "16-8")),

    // vicido Konturenschrift
    new WDWritingLineatureFontValues("VicidoKT", "10", "40pt", -1, Math.round(Converter.mmToPx(10)),
        getLineHeight("VicidoKT", "10"), 1.2),
    new WDWritingLineatureFontValues("VicidoKT", "4-4-4", "32pt", 8, Math.round(Converter.mmToPx(12)),
        getLineHeight("VicidoKT", "4-4-4")),
    new WDWritingLineatureFontValues("VicidoKT", "6-6-6", "48pt", 13, Math.round(Converter.mmToPx(18)),
        getLineHeight("VicidoKT", "6-6-6")),
    new WDWritingLineatureFontValues("VicidoKT", "8-4", "32pt", 8, Math.round(Converter.mmToPx(12)),
        getLineHeight("VicidoKT", "8-4")),
    new WDWritingLineatureFontValues("VicidoKT", "8-8-8", "64pt", 19, Math.round(Converter.mmToPx(24)),
        getLineHeight("VicidoKT", "8-8-8")),
    new WDWritingLineatureFontValues("VicidoKT", "12-6", "48pt", 12, Math.round(Converter.mmToPx(18)),
        getLineHeight("VicidoKT", "12-6")),
    new WDWritingLineatureFontValues("VicidoKT", "16-8", "64pt", 17, Math.round(Converter.mmToPx(24)),
        getLineHeight("VicidoKT", "16-8"))
]

function getLineHeight(fontName: string, lineatureSize: string): WSDesignerWritingLineatureLineHeight[] {
    let sizeData: WSDesignerWritingLineatureLineHeight[] = []

    // Edge and Chrome don't support decimal values, Firefox does
    if(fontName === "VicidoSans" || fontName === "VicidoKT") {
        switch (lineatureSize) {
            case "10":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 37, marginTop: 19.5}
                    ]
                } else { // OK
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 37, marginTop: 18}
                    ]
                }
                break;
            case "4-4-4":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 53, marginTop: 15.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 57, marginTop: 13.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 61, marginTop: 11.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 64, marginTop: 9.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 68, marginTop: 8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 72, marginTop: 5.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 76, marginTop: 4},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 79, marginTop: 2.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 83, marginTop: 0.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 87, marginTop: -1.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 91, marginTop: -3.5},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 53, marginTop: 15},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 57, marginTop: 13},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 61, marginTop: 11},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 64, marginTop: 9.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 68, marginTop: 7.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 72, marginTop: 5.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 76, marginTop: 3.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 79, marginTop: 2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 83, marginTop: 0},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 87, marginTop: -2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 91, marginTop: -4},
                    ]
                }
                break;
            case "6-6-6":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 81, marginTop: 12},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 85, marginTop: 10.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 89, marginTop: 8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 92, marginTop: 6.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 96, marginTop: 4.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 100, marginTop: 2.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 104, marginTop: 0.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 107, marginTop: -1.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 111, marginTop: -2.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 115, marginTop: -5.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 119, marginTop: -6.5},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 81, marginTop: 12},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 85, marginTop: 10},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 89, marginTop: 8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 92, marginTop: 6.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 96, marginTop: 4.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 100, marginTop: 2.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 104, marginTop: 0.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 107, marginTop: -1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 111, marginTop: -3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 115, marginTop: -5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 119, marginTop: -7},
                    ]
                }
                break;
            case "8-4":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 53, marginTop: 15},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 57, marginTop: 13},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 61, marginTop: 11},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 64, marginTop: 10},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 68, marginTop: 8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 72, marginTop: 6},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 76, marginTop: 4},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 79, marginTop: 2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 83, marginTop: 0},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 87, marginTop: -2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 91, marginTop: -4},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 53, marginTop: 15},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 57, marginTop: 13},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 61, marginTop: 11},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 64, marginTop: 9.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 68, marginTop: 7.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 72, marginTop: 5.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 76, marginTop: 3.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 79, marginTop: 2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 83, marginTop: 0},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 87, marginTop: -2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 91, marginTop: -4},
                    ]
                }
                break;
            case "8-8-8":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 110, marginTop: 8.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 114, marginTop: 6.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 118, marginTop: 4.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 121, marginTop: 4},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 125, marginTop: 2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 129, marginTop: 0},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 133, marginTop: -2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 136, marginTop: -4},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 140, marginTop: -6},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 144, marginTop: -8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 148, marginTop: -10},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 110, marginTop: 8.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 114, marginTop: 6.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 118, marginTop: 4.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 121, marginTop: 3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 125, marginTop: 1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 129, marginTop: -1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 133, marginTop: -3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 136, marginTop: -4.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 140, marginTop: -6.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 144, marginTop: -8.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 148, marginTop: -10.5},
                    ]
                }
                break;
            case "12-6":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 80, marginTop: 13},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 84, marginTop: 10.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 88, marginTop: 9},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 91, marginTop: 8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 95, marginTop: 6},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 99, marginTop: 4},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 103, marginTop: 2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 106, marginTop: 0},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 110, marginTop: -1.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 114, marginTop: -4},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 118, marginTop: -5.5},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 80, marginTop: 12.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 84, marginTop: 10.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 88, marginTop: 8.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 91, marginTop: 7},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 95, marginTop: 5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 99, marginTop: 3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 103, marginTop: 1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 106, marginTop: -0.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 110, marginTop: -2.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 114, marginTop: -4.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 118, marginTop: -6.5},
                    ]
                }
                break;
            case "16-8":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 108, marginTop: 10},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 112, marginTop: 7.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 116, marginTop: 5.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 119, marginTop: 4.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 123, marginTop: 2.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 127, marginTop: 0.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 131, marginTop: -1.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 134, marginTop: -3.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 138, marginTop: -5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 142, marginTop: -7},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 146, marginTop: -9},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 108, marginTop: 9.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 112, marginTop: 7.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 116, marginTop: 5.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 119, marginTop: 4},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 123, marginTop: 2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 127, marginTop: 0},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 131, marginTop: -2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 134, marginTop: -3.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 138, marginTop: -5.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 142, marginTop: -7.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 146, marginTop: -9.5},
                    ]
                }
                break;
        }
    }
    else if (fontName === "VicidoLS") {
        switch (lineatureSize) {
            case "10":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 37, marginTop: 15}
                    ]
                } else { // OK
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 37, marginTop: 14.2}
                    ]
                }
                break;
            case "4-4-4":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 53, marginTop: 12},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 57, marginTop: 10},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 61, marginTop: 8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 64, marginTop: 7},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 68, marginTop: 5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 72, marginTop: 3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 76, marginTop: 1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 79, marginTop: -1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 83, marginTop: -3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 87, marginTop: -5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 91, marginTop: -7},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 53, marginTop: 12},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 57, marginTop: 10},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 61, marginTop: 8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 64, marginTop: 6.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 68, marginTop: 4.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 72, marginTop: 2.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 76, marginTop: 0.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 79, marginTop: -1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 83, marginTop: -3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 87, marginTop: -5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 91, marginTop: -7},
                    ]
                }
                break;
            case "6-6-6":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 81, marginTop: 7.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 85, marginTop: 5.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 89, marginTop: 3.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 92, marginTop: 1.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 96, marginTop: -0.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 100, marginTop: -2.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 104, marginTop: -4.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 107, marginTop: -5.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 111, marginTop: -7.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 115, marginTop: -9.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 119, marginTop: -11.5},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 81, marginTop: 7.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 85, marginTop: 5.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 89, marginTop: 3.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 92, marginTop: 1.6},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 96, marginTop: -0.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 100, marginTop: -2.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 104, marginTop: -4.4},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 107, marginTop: -5.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 111, marginTop: -7.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 115, marginTop: -9.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 119, marginTop: -11.8},
                    ]
                }
                break;
            case "8-4":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 53, marginTop: 12},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 57, marginTop: 10},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 61, marginTop: 8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 64, marginTop: 7},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 68, marginTop: 5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 72, marginTop: 3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 76, marginTop: 1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 79, marginTop: -1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 83, marginTop: -3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 87, marginTop: -5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 91, marginTop: -7},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 53, marginTop: 12},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 57, marginTop: 10},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 61, marginTop: 8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 64, marginTop: 6.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 68, marginTop: 4.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 72, marginTop: 2.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 76, marginTop: 0.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 79, marginTop: -1},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 83, marginTop: -3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 87, marginTop: -5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 91, marginTop: -7},
                    ]
                }
                break;
            case "8-8-8":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 110, marginTop: 2.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 114, marginTop: 0},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 118, marginTop: -2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 121, marginTop: -3.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 125, marginTop: -5.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 129, marginTop: -7.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 133, marginTop: -9.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 136, marginTop: -10.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 140, marginTop: -12.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 144, marginTop: -14.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 148, marginTop: -16.5},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 110, marginTop: 2.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 114, marginTop: 0},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 118, marginTop: -2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 121, marginTop: -3.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 125, marginTop: -5.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 129, marginTop: -7.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 133, marginTop: -9.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 136, marginTop: -11},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 140, marginTop: -12.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 144, marginTop: -14.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 148, marginTop: -16.8},
                    ]
                }
                break;
            case "12-6":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 80, marginTop: 7.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 84, marginTop: 5.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 88, marginTop: 3.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 91, marginTop: 2.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 95, marginTop: 0.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 99, marginTop: -1.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 103, marginTop: -3.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 106, marginTop: -5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 110, marginTop: -7},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 114, marginTop: -9},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 118, marginTop: -11},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 80, marginTop: 7.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 84, marginTop: 5.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 88, marginTop: 3.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 91, marginTop: 2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 95, marginTop: 0.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 99, marginTop: -1.7},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 103, marginTop: -3.7},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 106, marginTop: -5.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 110, marginTop: -7.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 114, marginTop: -9.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 118, marginTop: -11.2},
                    ]
                }
                break;
            case "16-8":
                if (browser?.name === 'edge-chromium' || browser?.name === 'chrome') {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 108, marginTop: 3.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 112, marginTop: 1.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 116, marginTop: -0.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 119, marginTop: -2.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 123, marginTop: -4.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 127, marginTop: -6.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 131, marginTop: -8.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 134, marginTop: -9.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 138, marginTop: -11.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 142, marginTop: -13.5},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 146, marginTop: -15.5},
                    ]
                } else {
                    sizeData = [
                        {additionalRowSpacing: 0, lineHeight: 108, marginTop: 3.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(1)), lineHeight: 112, marginTop: 1.2},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(2)), lineHeight: 116, marginTop: -0.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(3)), lineHeight: 119, marginTop: -2.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(4)), lineHeight: 123, marginTop: -4.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(5)), lineHeight: 127, marginTop: -6.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(6)), lineHeight: 131, marginTop: -8.3},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(7)), lineHeight: 134, marginTop: -9.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(8)), lineHeight: 138, marginTop: -11.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(9)), lineHeight: 142, marginTop: -13.8},
                        {additionalRowSpacing: Math.round(Converter.mmToPx(10)), lineHeight: 146, marginTop: -15.8},
                    ]
                }
                break;
        }
    }
    return sizeData
}

export function getAllFontValues() {
    return fontValue
}

export function getDefaultFontValue(): WDWritingLineatureFontValues {
    return fontValue[0]
}
export function getDefaultFontName(): string {
    return "VicidoSans"
}

export function getFontValue(fontName: string, lineatureSize: WDWritingLineatureSize): WDWritingLineatureFontValues | undefined {
    if(lineatureSize === undefined) { lineatureSize = WDWritingLineatureSize.size_10 }

    return fontValue.find(font => {
        return font.font === fontName && font.lineatureSize === lineatureSize.valueOf()
    })
}
