import React from "react";

export enum TooltipPosition {
    above = "above",
    below = "below",
    belowRight = "below-right",
    belowLeft = "below-left"
}
export class TooltipText {
    text: string
    subtext?: string

    constructor(text: string, subtext?: string) {
        this.text = text
        this.subtext = subtext
    }
}

interface IProps {
    text: TooltipText
    translateX?: number
    translateY?: number
}

interface IState {
}

export class Tooltips extends React.Component<IProps, IState> {
    render() {
        return <div className="tooltip-text"
                    style={{transform: "translate("+(this.props.translateX || 0) + "px, " + (this.props.translateY || 0) + "px)"}}
                    >
            <span>{this.props.text.text}</span>

            {this.props.text.subtext &&
            <span className={"tooltip-subtext"}>
                {this.props.text.subtext}
            </span>
            }
        </div>
    }
}
