import {Status} from "../Framework/Enums";
import User from "./User";
import Auth from "../Framework/Auth";
import {ValidationError} from "../Framework/Error/ValidationError";

export default class Entity {
    id?: number
    name: string
    thumbnail?: string

    constructor(name: string, id?: number, image?: string) {
        this.id = id
        this.name = name
        this.thumbnail = image
    }
}

export class EntityData extends Entity {
    status: Status
    ownerId?: User
    createdOn?: Date
    createdBy?: User
    modifiedOn?: Date
    modifiedBy?: User

    constructor(name: string, status: Status, createdOn?: Date, createdBy?: User, modifiedOn?: Date, modifiedBy?: User,
                ownerId?: User, id?: number, thumbnail?: string) {

        super(name, id, thumbnail);

        this.status = status
        this.ownerId = ownerId
        this.createdOn = createdOn
        this.createdBy = createdBy
        this.modifiedOn = modifiedOn
        this.modifiedBy = modifiedBy
    }

    static setStatus(entity: EntityData, newStatus: Status, user?: User) {
        const isCreator = entity.createdBy?.id === Auth.getUserId()
        const isOwner = entity.ownerId?.id === Auth.getUserId()

        if (newStatus === Status.draft && (entity.status === Status.published || (entity.status === Status.approval && !isCreator && !isOwner))) {
            throw new ValidationError("Invalid status change to " + Status.draft)
        }
        else if (newStatus === Status.approval && !(entity.status === Status.draft)) {
            throw new ValidationError("Invalid status change to " + Status.approval)
        }
        else if (newStatus === Status.published && (entity.status !== Status.approval || !isOwner)) {
            throw new ValidationError("Invalid status change to " + Status.published)
        }
        else if (newStatus === Status.deactivated && (entity.status !== Status.draft && entity.status !== Status.published)) {
            throw new ValidationError("Invalid status change to " + Status.deactivated)
        }

        if (newStatus === Status.draft && user === undefined) {
            user = entity.createdBy
        }

        entity.status = newStatus
        if (user) {
            entity.ownerId = user
        }
    }
}
