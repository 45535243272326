import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {WDToolbarButtonBorderOptions} from "./WDToolbarButtonBorderOptions";
import {ElementBorder} from "../../../Elements/WDElementContainer";
import {TooltipText} from "../../../../Components/Tooltips";

interface IProps {
    id: number

    clickable: boolean
    open: boolean
    borderOptions: ElementBorder
    showBorderPadding: boolean
    iconClass?: string

    onToggleButton: (id: number) => void
    onChangeBorder: (border: ElementBorder) => void
}

interface IState {
}

/**
 * Button (Yes/No) in all Toolbars with submenu for settings
 * Effects selected element
 * Yes: selected element will get a border
 *      settings in submenu will take effect
 * No:  selected element will lose border
 */

export class WDToolbarButtonBorder extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = { }
    }

    onToggle = () => {
        let borderOptions = this.props.borderOptions
        borderOptions = borderOptions.onToggleSetBorderOptions()
        this.props.onChangeBorder(borderOptions)
    }
    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-border"}
            className={"ws-designer-toolbar-item"}
            id={"ws-designer-toolbar-button-border"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + "frame.svg"}
                             iconClassSelected={this.props.iconClass}
                             iconClassUnselected={this.props.iconClass}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.border.border_options),
                                 this.context.translate(translations.tooltip.border)
                             )}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             buttonWidth={28}
                             onToggleButton={this.onToggle}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.borderOptions.visible}
            />

            <WDToolbarButtonBorderOptions id={this.props.id}
                                          clickable={this.props.clickable}
                                          open={this.props.open}
                                          showBorderPadding={this.props.showBorderPadding}
                                          borderOptions={this.props.borderOptions}
                                          onToggleButton={this.props.onToggleButton}
                                          onChangeBorder={this.props.onChangeBorder}
            />

        </li>
    }
}
