import {AdminObjectType} from "../../Framework/Enums";

export class AdminDataListSortSettings {
    objectType: AdminObjectType
    sortString: string
    descending: boolean

    constructor(objectType: AdminObjectType, sortString: string, descending: boolean) {
        this.objectType = objectType
        this.sortString = sortString
        this.descending = descending
    }
}
