import React from "react";

interface IProps {
    labelText: string
    htmlFor: string,
    className?: string,
    requiredField: boolean
}

export default class Label extends React.Component<IProps> {
    render() {
        let className = this.props.className || "bold-label"

        return <div className="row">
            <label className={className} htmlFor={this.props.htmlFor}>{this.props.labelText}</label>
            {this.props.requiredField &&
                <span className={className + " required-symbol"}>*</span>
            }
        </div>
    }
}
