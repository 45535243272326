import Entity from "../../_model/Entity";

export default class WSDesignerListItem extends Entity {
    id: number
    name: string
    status: string
    marketplaceStatus?: string
    modifiedOn: Date
    orientation: boolean

    constructor(id: number, name: string, status: string, modifiedOn: Date, orientation: boolean, marketplaceStatus?: string, thumbnail?: string) {
        super(name, id, thumbnail);

        this.id = id
        this.name = name
        this.modifiedOn = modifiedOn
        this.status = status
        this.marketplaceStatus = marketplaceStatus
        this.orientation = orientation
    }
}
