import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {Difficulty, EnumToEntityArray, EnumValueToEntity} from "../../../../Framework/Enums";
import {DropDown, DropDownItem, DropDownType} from "../../../../Components/Controls/DropDown";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button (with submenu) in Toolbar
 * Effects selected element(s)
 * Shows difficulty level as dropdown (easy, medium, heavy)
 * Dropdown:   Recalculation with different difficulty level logic should be done
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltipDisabled?: TooltipText
    difficulty?: Difficulty
    showEmptyValue: boolean
    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

export class WDToolbarButtonDifficultyLevel extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onChangeDifficultyLevel = (difficulty: string) => {
        let enumValue = Object.values(Difficulty).find((e, i) => i === +difficulty)
        let valueRange: any = {"difficultyLevel": enumValue}
        this.props.onActionButton(WDToolbarAction.CHANGE_DIFFICULTY_LEVEL, valueRange)
    }

    render() {
        let items: DropDownItem[] = []
        if (this.props.showEmptyValue) {
            items.push(new DropDownItem("-1", ""))
        }
        items.push(...EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate).map(i => new DropDownItem(i.id!.toString(), i.name)))

        let value = ""
        if (this.props.difficulty) {
            value = EnumValueToEntity(Difficulty, this.props.difficulty, translations.enum.difficulty, this.context.translate).id!.toString()
        }

        return <li
            key={"ws-designer-toolbar-button-difficulty-level"}
            id={"changeDiffLevel"}>

            <WDToolbarButton key={"btnChangeDiffLevel"}
                             id={this.props.id}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.difficulty_level),
                                 this.context.translate(translations.tooltip.difficulty_level))}
                             tooltipDisabled={this.props.tooltipDisabled}
                             icon={ImagePath.getMathUrl() + "difficulty.svg"}
                             onToggleButton={this.props.onToggleButton}
                             arrow={true}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed}>
            </WDToolbarButton>

            {this.props.pressed &&
                <div className="ws-designer-toolbar-submenu">
                    <label className={"ws-designer-options-section-label-normal"}>
                        {this.context.translate(translations.toolbar.difficulty_level) + '\n'}
                    </label>
                    <DropDown
                        id={"difficulty-level"}
                        type={DropDownType.TEXT}
                        readonly={!this.props.clickable}
                        required={false}
                        value={value}
                        width={135}
                        autocomplete={false}
                        items={items}
                        onChangeValue={this.onChangeDifficultyLevel} />
                </div>
            }
        </li>
    }
}
