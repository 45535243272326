import translations from "../../../Framework/translations.json";
import {ExerciseCalculationType} from "../../../Framework/Enums";


export default class WDTextExerciseDropDownValues {
    static getNumberRange() {
        return [
            {key: -1, value: ""},
            {key: 10, value: "10"},
            {key: 20, value: "20"},
            {key: 30, value: "30"},
            {key: 100, value: "100"},
            {key: 1000, value: "1000"},
            {key: 10000, value: "10000"},
            {key: 100000, value: "100000"},
            {key: 1000000, value: "1000000"},
        ];
    }
    static mapNumberRange(numberRange: number) {
        switch (numberRange) {
            case 10: return 5
            case 20: return 6
            case 30: return 7
            case 100: return 8
            case 1000: return 9
            case 10000: return 10
            case 100000: return 11
            case 1000000: return 12
        }

        return -1
    }

    static getTopics() {
        return [
            {key: -1, value: ""},
            {key: 13, value: "Zeit"},
            {key: 14, value: "Längen"},
            {key: 15, value: "Gewichte"},
            {key: 16, value: "Umfang"},
            {key: 17, value: "Flächen"},
            {key: 59, value: "Geld"}
        ];
    }

    static getCalculationType() {
        return [
            {key: ExerciseCalculationType.none, value: translations.enum.exercise_calculation_type.none},
            {key: ExerciseCalculationType.add, value: translations.enum.exercise_calculation_type.add},
            {key: ExerciseCalculationType.subtract, value: translations.enum.exercise_calculation_type.subtract},
            {key: ExerciseCalculationType.add_subtract, value: translations.enum.exercise_calculation_type.add_subtract},
            {key: ExerciseCalculationType.multiply, value: translations.enum.exercise_calculation_type.multiply},
            {key: ExerciseCalculationType.divide, value: translations.enum.exercise_calculation_type.divide},
            {key: ExerciseCalculationType.multiply_divide, value: translations.enum.exercise_calculation_type.multiply_divide},
            {key: ExerciseCalculationType.mixed, value: translations.enum.exercise_calculation_type.mixed}
        ]
    }
}
