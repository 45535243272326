import Entity from "../../_model/Entity";
import {AdminObjectType, ListStyle} from "../../Framework/Enums";
import {SearchFields} from "../Controls/SearchInput";

export class ListFilter {
    objectType: AdminObjectType
    listStyle: ListStyle
    searchString: string
    searchOption: SearchFields
    filterValues: ListFilterValue[]

    constructor(objectType: AdminObjectType, listStyle: ListStyle, searchString: string, searchOptions: SearchFields, filter: ListFilterValue[]) {
        this.objectType = objectType
        this.listStyle = listStyle
        this.searchString = searchString
        this.searchOption = searchOptions
        this.filterValues = filter
    }
}

export class ListFilterDefinitionItem {
    id: number
    name: string
    value?: number

    constructor(id: number, name: string, value?: number) {
        this.id = id
        this.name = name
        this.value = value
    }

}
export class ListFilterDefinition {
    name: string
    data: ListFilterDefinitionItem[]
    onFilter: (items: Entity[], value: number | undefined) => Entity[]
    defaultValue: number

    constructor(name: string, filterData: ListFilterDefinitionItem[], onFilter: (items: Entity[], value: any) => Entity[], defaultValue: number) {
        this.name = name
        this.data = filterData
        this.defaultValue = defaultValue
        this.onFilter = onFilter
    }
}

export class ListFilterValue {
    filter: ListFilterDefinition
    value?: number

    constructor(filter: ListFilterDefinition) {
        this.filter = filter
        this.value = filter.defaultValue
    }
}
