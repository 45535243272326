import React from "react";
import {WDElementBase, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {ResizeInfo, WDElementContainer} from "../WDElementContainer";
import Const from "../../../Framework/Const";
import {MainContext} from "../../../_base/MainContext";
import _ from "lodash";
import {WorksheetItem} from "../../../_model/WorksheetItem";

interface IProps extends WDElementBaseProps {
    elements: WorksheetItem[]
    renderElement: (element: WorksheetItem) => JSX.Element
}

interface IState extends WDElementBaseState {
}

export class WDGroup extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef(),
        }
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getMinWidth = () => {
        return Const.MinElementSize
    }
    getMinHeight = () => {
        return Const.MinElementSize
    }

    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }

    /**
     * Overridden methods
     */
    onEditElement = async(editMode: boolean) => {
        if (editMode !== this.state.isEdited) {

            this.setState({isEdited: editMode}, () => {
                // Tell designer this is in edit mode
                // Cancels all event handlers to avoid element being dragged while in edit mode
                this.props.onElementEdit?.(this.props.id, false)
            })
        }
    }

    render() {
        let resizeInfo: ResizeInfo =
            new ResizeInfo(true, true, true, true, false, false, false, false,
                this.getMinWidth(), Const.WorksheetDefaultPageHeight, this.getMinHeight(), Const.WorksheetDefaultPageHeight)

        let className = "ws-designer-element-group"
        if (this.state.isEdited) {
            className += " ws-designer-element-group-edit"
        }

        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }

        return <WDElementContainer id={this.props.id}
                                   element={this.props.element}
                                   hasResizeOnCreate={false}
                                   renderWrapper={true}
                                   onUnlockElement={this.unlockElement}
                                   onResizeStateChanged={this.props.onResizeStateChanged}
                                   onResizeElement={this.props.onElementResize}
                                   onEdit={this.onEditElement}
                                   isEditModeAllowed={() => true}
                                   isReadOnly={this.props.isReadOnly}
                                   onContextMenu={this.props.onContextMenu}
                                   ref={this.state.elementRef}
                                   resizeInfo={resizeInfo}
        >

            <div id={this.props.id + "-group"} className={className}>
                {this.props.elements.map((element, index) => this.props.renderElement(element))}
            </div>
        </WDElementContainer>
    }
}
