import React from 'react';
import {ColumnDefinition} from "../../Components/List/List";
import translations from "../../Framework/translations.json";
import {AdminObjectType, ColumnRenderOption, Status} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import User from "../../_model/User";
import WritingCourseListItem from "./WritingCourseListItem";
import Const from "../../Framework/Const";
import {WSContextType} from "../../Designer/Elements/WSContext";
import WritingCourse from "../../_model/WritingCourse";
import {
    CloneWritingCourse,
    GetWritingCourse,
    GetWritingCourseList,
    UpdateWritingCourse
} from "../../_endpoint/WritingCourseEndpoint";
import {ChildStatus} from "../Exercise/TextExerciseListItem";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: WritingCourse[]
    listItems?: WritingCourseListItem[]
}

export default class WritingCourseList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.title), "name", 27, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.exercise_count), "exerciseCount", 8, false, true, ColumnRenderOption.Status),
        new ColumnDefinition(this.context.translate(translations.fields.writing_course.character_type), "characterType", 15, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 12, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 12, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 12, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 14, false, true, ColumnRenderOption.DateTime)
    ]
    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ items: [] })

        GetWritingCourseList().then(
            (itemData) => {

                const listItems = itemData.map(item => {
                    // Status-Text (translated)
                    let status = translations.enum.status[item.status]
                    let charType = item.characterType ? translations.enum.character_type[item.characterType] : undefined

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    let createdBy = ""
                    if (item.createdBy) {
                        createdBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    let childStatus = new ChildStatus(0, 0, 0)
                    if (item.writingCourses) {
                        childStatus = new ChildStatus(
                            item.writingCourses.filter(c => c.status === Status.published).length,
                            item.writingCourses.filter(c => c.status === Status.approval).length,
                            item.writingCourses.filter(c => c.status === Status.draft).length
                        )
                    }

                    return new WritingCourseListItem(
                        item.id!,
                        item.name,
                        this.context.translate(status),
                        childStatus,
                        charType ? this.context.translate(charType) : "",
                        owner,
                        item.modifiedOn || item.createdOn!,
                        createdBy
                    )
                })

                this.setState({ items: itemData, listItems: listItems })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    onCopyItem = async(items: number[]) => {
        try {
            for (const id of items) {
                const course = await GetWritingCourse(id)
                if (course && course.status !== Status.deactivated) {

                    let clone: WritingCourse = { ...course }
                    clone.id = undefined
                    clone.status = Status.draft

                    await CloneWritingCourse(clone, clone.name + " - " + this.context.translate(translations.text_fragment.copy), WSContextType.writing_course_main)
                }
            }

            this.fetchData()
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))
        }
        catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
        }
    }

    onSetState = async(item: number, status: Status, user?: User) => {
        try {
            const course = this.state.items.find(d => d.id === item)
            if (course) {
                WritingCourse.setStatus(course, status, user)
                await UpdateWritingCourse(course)

                return true
            }
        } catch (e) { }

        return false
    }
    onRefresh = () => {
        this.fetchData()
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              newItemUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.writing_course_main) + Const.CreateNewDataUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.writing_course_main) + "{0}/list"}
                              objectType={AdminObjectType.writing_course_main}
                              onCopyItem={this.onCopyItem}
                              onSetStatus={this.onSetState}
                              onRefresh={this.onRefresh}
                              paginationOptions={{ showPagination: true }}
                              searchOptions={{ showSearch: true, typeAheadSearch: true, showSearchOptions: false }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "name",
                                  sortDescending: false
                              }}
                              filterOptions={{
                                  externalFilter: false,
                                  filterDefinitions: [],
                                  removeDefaultFilter: false
                              }}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              showActivate={true}
                              showDeactivate={true}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}

