import {MainContext} from "../../_base/MainContext";
import React from "react";
import {WorksheetRating} from "../../_model/WorksheetRating";
import {Util} from "../../Framework/Util";
import Rating from "./Rating";
import translations from "../../Framework/translations.json";

interface IProps {
    ratings: WorksheetRating[]
}
interface IState {
}

export default class RatingList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
        }
    }

    render() {
        return <div className={"rating-list"}>
            {this.props.ratings
                .filter(r => r.createdOn)
                .sort((a, b) => new Date(b.createdOn!).getTime() - new Date(a.createdOn!).getTime())
                .map((r, idx) => {
                    let legend = r.author
                    if (r.createdOn) {
                        legend += ", " + Util.formatDate(r.createdOn, true)
                    }
                    if (r.oldVersion) {
                        legend += " (" + this.context.translate(translations.text.marketplace.rating_old_version) + ")"
                    }

                    return <div className={"rating-list-item"} key={"rating-" + idx}>
                        <div className={"form-separator"} style={{marginTop: 10, width: "100%"}}/>
                        <Rating rating={r.rating}
                                legend={legend}
                                fontSize={13}
                                description={r.description}
                        />
                    </div>
                })}
        </div>
    }
}

