import Entity from "../../_model/Entity";

export class PatternListItem extends Entity {
    status: string
    lessonSubject: string
    schoolLevel: string
    exerciseCategory: string
    modifiedOn: Date
    owner: string
    createdBy: string

    constructor(id: number, name: string, status: string, owner: string, modifiedOn: Date, lessonSubject: string,
                schoolLevel: string, exerciseCategory: string, createdBy: string) {
        super(name, id);

        this.lessonSubject = lessonSubject
        this.schoolLevel = schoolLevel
        this.exerciseCategory = exerciseCategory

        this.owner = owner
        this.createdBy = createdBy
        this.modifiedOn = modifiedOn
        this.status = status
    }
}

export default PatternListItem
