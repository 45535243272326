import {WDHistory} from "./WDHistory";
import {WDElementHistoryItem} from "./WDElementHistoryItem";
import {WDHistoryDirection} from "./Enum/WDHistoryDirection";

export class WDElementHistory implements WDHistory {
    oldValue: WDElementHistoryItem
    newValue?: WDElementHistoryItem

    restoreCallback: (history: WDElementHistory, direction: WDHistoryDirection) => Promise<void>

    constructor(restoreCallback: (history: WDElementHistory, direction: WDHistoryDirection) => Promise<void>,
                oldValue: WDElementHistoryItem, newValue?: WDElementHistoryItem) {

        this.oldValue = oldValue
        this.newValue = newValue
        this.restoreCallback = restoreCallback
    }

    restoreElements = async(direction: WDHistoryDirection) => {
        await this.restoreCallback(this, direction)
    }

    update = (newValue: WDElementHistoryItem) => {
        if (this.newValue === undefined) {
            this.newValue = new WDElementHistoryItem([])
        }

        newValue.updates.forEach(update => {
            let existing = this.newValue!.updates.find(u => u.itemKey === update.itemKey)
            if (existing) {
                Object.keys(update.value).forEach(key => {
                    existing!.value[key] = update.value[key]
                })
            }
            else {
                this.newValue!.updates.push(update)
            }
        })
    }
}



