import API from "../Framework/API";
import LessonSubject from "../_model/LessonSubject";
import SchoolLevel from "../_model/SchoolLevel";
import Language from "../_model/Language";
import Originator from "../_model/Originator";
import Tag from "../_model/Tag";
import Announcement from "../_model/Announcement";
import {Version} from "../_model/Version";

const URL = "metadata"

export function GetBackendVersion() {
    return API.Get<Version>(URL + "/version")
}
export function GetLessonSubject() {
    return API.Get<LessonSubject[]>(URL + "/lesson_subjects")
}

export function GetSchoolLevel(parentId?: number) {
    let url = URL + "/school_levels"
    url += parentId ? ("?parentId=" + parentId) : ("")
    return API.Get<SchoolLevel[]>(url)
}

export function GetAllLanguages() {
    return API.Get<Language[]>(URL + "/languages")
}

export function GetOriginator() {
    return API.Get<Originator[]>(URL + "/originators")
}
export function CreateOriginator(originator: Originator) {
    return API.Post<Originator, Originator>(URL + "/originators", originator)
}

export function GetTags() {
    return API.Get<Tag[]>(URL + "/tags")
}
export function CreateTag(tag: Tag) {
    return API.Post<Tag, Tag>(URL + "/tags", tag)
}

export function GetAnnouncements() {
    return API.Get<Announcement[]>(URL + "/announcements")
}
