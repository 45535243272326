import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {LineWidth} from "./WDToolbarButtonColorPicker";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {WDToolbarAction} from "../../WDToolbarAction";
import {TooltipPosition, TooltipText} from "../../../../Components/Tooltips";
import {Slider} from "../../../../Components/Controls/Slider";

interface IProps {
    id: number
    clickable: boolean
    pressed: boolean
    open: boolean

    lineWidth: LineWidth

    icon?: string
    iconClass?: string
    tooltip?: TooltipText
    tooltipPosition?: TooltipPosition

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data?: any) => void
}

interface IState {
    lineWidth
}

/**
 * Button Submenu for setting eraser and width of it
 */
export class WDToolbarButtonEraser extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onChangeWidth = (width: number) => {
        this.props.lineWidth && this.props.lineWidth.onChangeWidth(width)
    }

    onToggle = () => {
        this.props.onActionButton(WDToolbarAction.ERASER)
    }
    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    render() {
        let tooltip = this.props.tooltip

        if(!tooltip) {
            tooltip = new TooltipText(
                this.context.translate(translations.toolbar.lineColor),
                this.context.translate(translations.tooltip.line_color)
            )
        }

        let icon = this.props.icon || ImagePath.getButtonUrl() + "edit.svg"

        return <li
            key={"ws-designer-toolbar-button-line-color"}
            className={"ws-designer-toolbar-item"}
            id={"ws-designer-toolbar-button-line-color"}>

            <WDToolbarButton id={this.props.id}
                             icon={icon}
                             buttonWidth={32}
                             buttonHeight={32}
                             tooltip={tooltip}
                             tooltipPosition={this.props.tooltipPosition}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             onToggleButton={this.onToggle}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed} />

            <WDToolbarButton id={this.props.id}
                             tooltip={this.props.tooltip}
                             tooltipPosition={this.props.tooltipPosition}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             buttonHeight={32}
                             buttonWidth={15}
                             imageHeight={5}
                             imageWidth={5}
                             icon={ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                             iconClassSelected={"ws-designer-toolbar-button-options"}
                             iconClassUnselected={"ws-designer-toolbar-button-options"}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.open}
                             onToggleButton={this.onToggleList}
            />

            { /* Eraser width dialog */}
            {this.props.open &&
                <div className={"ws-designer-toolbar-options ws-designer-toolbar-arrow-options"}
                     style={{ width: "250px", height: "100px" }}>

                        <div className={"ws-designer-options-section"}>
                            <div className={"ws-designer-options-section-label"}>
                                {this.context.translate(translations.toolbar.color_picker.line_width)}
                            </div>
                            <Slider value={this.props.lineWidth.lineWidth}
                                    unit={"px"}
                                    minValue={this.props.lineWidth.minWidth || 1}
                                    maxValue={this.props.lineWidth.maxWidth}
                                    onChangeValue={this.onChangeWidth}
                            />
                        </div>
                </div>
            }

        </li>
    }
}
