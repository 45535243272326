import React from "react";
import {EntityData} from "../../_model/Entity";
import Word from "../../_model/Dictionary/Word";

/**
 * Base class for all word types - specific words extend from base
 * includes default implementation for save method
 * interface between Word and specific words
 */

export interface WordTypeProps {
}
export interface WordTypeState {
}

export class WordType<T extends WordTypeProps = WordTypeProps,
    U extends WordTypeState = WordTypeState> extends React.Component<T, U> {

    onSave = async (word: Word) : Promise<EntityData | undefined> => {
        return undefined
    }

    fetchData = () => {}
}
