import Entity from "./Entity";
import {ImageAlignment} from "./Image";
import {Dimensions} from "../Framework/Dimensions";

export enum MenuContentType {
    icon = "icon",
    thumbnail = "thumbnail",
    image = "image",
    text = "text"
}
export class MenuItem extends Entity{
    id: number
    name: string
    icon?: string
    description?: string
    menuContentType?: MenuContentType
    draggable?: boolean
    adminArea?: boolean
    linkTo?: string
    parentId?: MenuItem
    worksheetItemTypeId?: number
    configData?: string
    alignment?: ImageAlignment

    constructor(draggable: boolean, id: number, name: string, adminArea?: boolean, linkTo?: string, icon?: string, description?: string,
                menuContentType?: MenuContentType, parentId?: MenuItem, worksheetItemTypeId?: number, configData?: string, alignment?: ImageAlignment) {

        super(name, id)
        this.id = id
        this.name = name
        this.draggable = draggable
        this.adminArea = adminArea
        this.linkTo = linkTo
        this.icon = icon
        this.description = description
        this.menuContentType = menuContentType
        this.parentId = parentId;
        this.worksheetItemTypeId = worksheetItemTypeId
        this.configData = configData
        this.alignment = alignment
    }

    public static getMenuImageSizeByAlignment(alignment: ImageAlignment): Dimensions {
        switch (alignment) {
            case ImageAlignment.quadratic:
                return new Dimensions(130, 130)

            case ImageAlignment.landscape:
                return new Dimensions(287, 150)

            case ImageAlignment.portrait:
                return new Dimensions(138, 244)

            case ImageAlignment.panorama:
                return new Dimensions(287, 50)
        }
    }
}
export class MenuItemText {
    worksheetId: number
    name: string
    description: string

    constructor(worksheetId: number, name: string, description: string) {
        this.worksheetId = worksheetId
        this.name = name
        this.description = description
    }
}
