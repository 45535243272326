import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbar, WDToolbarOrientation} from "../../Toolbar/Toolbar/WDToolbar";
import {WDToolbarSectionTransformation} from "../../Toolbar/Section/WDToolbarSectionTransformation";
import Converter from "../../../Framework/Converter";
import {MainContext} from "../../../_base/MainContext";
import {ElementLayout} from "../WDElementContainer";
import {WDToolbarSectionObjectGeneral} from "../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../Toolbar/Toolbar/WDToolbarTabs";
import {WorksheetItemType, WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import _ from "lodash";
import {WDToolbarButtonGraphics} from "../../Toolbar/Button/General/WDToolbarButtonGraphics";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../Framework/CategoryImage";
import {TooltipPosition, TooltipText} from "../../../Components/Tooltips";
import translations from "../../../Framework/translations.json";
import {WDToolbarButtonSeparator} from "../../Toolbar/Button/General/WDToolbarButtonSeparator";
import {WDToolbarButtonColor} from "../../Toolbar/Button/General/WDToolbarButtonColor";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {WDToolbarButtonLineColor} from "../../Toolbar/Button/General/WDToolbarButtonLineColor";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {WDHistoryAction} from "../../History/Enum/WDHistoryAction";
import {WDActionLogCategory} from "../../ActionLog/WDActionLogEntry";

export interface WDToolbarShapeProps extends WDToolbarElementProps {
}

export interface WDToolbarShapeState extends WDToolbarElementState {
}

export class WDShapeToolbar3D extends WDToolbarElement<WDToolbarShapeProps, WDToolbarShapeState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: WDToolbarShapeProps, state: WDToolbarShapeState) {
        super(props, state);

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef()
        }
    }

    shouldComponentUpdate(nextProps: Readonly<WDToolbarShapeProps>, nextState: Readonly<WDToolbarShapeState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getElementGraphic = (obj: any) => {
        // #4266 Graphic of changeGraphic button should change matching to the element
        let iconString = ImagePath.getFormsUrl() + "m-at-3d-form-quader-thumb.svg"
        if(obj && obj.newValue) {
            let key = "FORM_" + obj.newValue.toString().toUpperCase()
            iconString = ImagePath.getFormsUrl() + CategoryImageValue.getImageByKey([ImageCategory.FORMS_3D], key).toString()
        }

        return iconString
    }

    onChangeFillColor = (color: string) => {
        this.props.onUpdateSelectedElements(new WorksheetItemUpdate("", {
            fillColor: color
        }), {
            historyAction: WDHistoryAction.FILL_COLOR,
            actionCategory: WDActionLogCategory.background,
            applyRecursive: false,
            applyToChildren: true
        })
        this.setState({activeId: -1})
    }
    onChangeTransparency = (transparency: number) => {
        this.props.onUpdateSelectedElements(new WorksheetItemUpdate("", {
            transparency: transparency
        }), {
            historyAction: WDHistoryAction.FILL_COLOR,
            actionCategory: WDActionLogCategory.background,
            applyRecursive: false,
            applyToChildren: true
        })
    }

    onToolbarAction = (action: WDToolbarAction, data?: any) => {
        if(action === WDToolbarAction.LINE_COLOR) {
            this.setState({ activeId: -1 }, () => {
                let border = this.props.elementProps.border
                border.color = data["lineColor"]
                this.props.onChangeBorder(border)
            })
        } else {
            this.setState({ activeId: -1 }, () => {
                this.props.onToolbarAction(action, data)
            })
        }
    }

    render () {
        let graphics = this.multiselectSearchForValue("type")

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, false, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&

            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minLeft: this.props.elementProps.minLeft,
                        minTop: this.props.elementProps.minTop,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                        maxLeft: this.props.elementProps.maxLeft !== undefined ? Math.round(Converter.pxToMm(this.props.elementProps.maxLeft)) : undefined,
                        maxTop: this.props.elementProps.maxTop !== undefined ? Math.round(Converter.pxToMm(this.props.elementProps.maxTop)) : undefined,
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={this.isUsedInTextExerciseChild()}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={true}
                    canChangeLinkState={false}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&

            <ul className={"ws-designer-toolbar-buttons"}>

                <div className={"ws-designer-toolbar-section"}>
                    <WDToolbarButtonGraphics
                        id={2}
                        icon={this.getElementGraphic(graphics)}
                        pressed={this.state.activeId === 2}
                        clickable={this.state.isLicenseValidForElement}
                        imageCategory={[ImageCategory.FORMS_3D]}
                        imagePath={ImagePath.getFormsUrl()}
                        onToggleButton={this.onToggleButton}
                        onActionButton={this.onToolbarAction}
                        imageWidth={100}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic),
                            this.context.translate(translations.tooltip.change_graphic)
                        )}
                        tooltipDisabled={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic_disabled),
                            this.context.translate(translations.tooltip.no_license))
                        }
                        text={this.context.translate(translations.toolbar.graphic)}
                        iconImageWidth={80}
                        iconImageHeight={80}
                        iconButtonWidth={100}
                        iconButtonHeight={69}
                    />
                </div>

                <WDToolbarButtonSeparator/>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonLineColor
                            id={10}
                            open={this.state.activeId === 10}
                            clickable={true}
                            pressed={this.props.elementProps.border.color !== undefined}
                            lineColor={this.props.elementProps.border.color || "6D80BF"}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonColor
                            id={11}
                            clickable={true}
                            command={"foreColor"}
                            options={{
                                action: WDToolbarAction.FILL_COLOR,
                                open: this.state.activeId === 11,
                                width: 70,
                                height: 150
                            }}
                            tooltipPosition={TooltipPosition.above}
                            showColor={true}
                            transparency={true}
                            pressed={false}
                            transparencyAmount={this.props.elementProps.color.transparency}
                            onChangeTransparency={this.onChangeTransparency}
                            initialColor={this.props.elementProps.color.fillColor}
                            action={WDToolbarAction.FILL_COLOR}
                            icon={ImagePath.getButtonUrl() + "fill_color.svg"}
                            tooltip={new TooltipText(
                                this.context.translate(translations.toolbar.fillColor),
                                this.context.translate(translations.tooltip.fill_color))
                            }
                            onActionButton={(action, data) => this.onChangeFillColor(data.color)}
                            onToggleButton={this.onToggleButton}
                        />
                    </div>
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={false}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    worksheetItemType={new WorksheetItemType("", WorksheetItemTypeEnum.IMAGE)}
                    elementProps={this.props.elementProps}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onChangeBorder={this.props.onChangeBorder}
                    onElementDeleted={this.props.onElementDeleted}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onAddRule={this.props.onAddRule}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                />
            </ul>
            }
        </WDToolbar>
    }
}
