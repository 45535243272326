import React from 'react';
import translations from "../../Framework/translations.json";
import TextBox from "../Controls/TextBox";
import {EnumValueToEntity, Status,} from "../../Framework/Enums";
import {MainContext} from "../../_base/MainContext";
import User from "../../_model/User";

interface IProps {
    number?: number
    status: Status
    statusTranslationPath: {}
    createdBy?: User
    ownerId?: User
}

interface IState {

}

export default class FormHeader extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
        }
    }

    render() {
        let status = EnumValueToEntity(Status, this.props.status, this.props.statusTranslationPath, this.context.translate)

        let color = "#dfe8ff"
        switch (this.props.status) {
            case Status.approval:
                color = "#f3eeb4"
                break
            case Status.published:
                color = "#d4ead0"
                break
            case Status.deactivated:
                color = "#d7d7d7"
                break
        }

        return <div className="form-row form-header" style={{backgroundColor: color}}>

            <TextBox id={"txtNumber"}
                     width={100}
                     label={this.context.translate(translations.fields.number)}
                     required={false}
                     readonly={true}
                     value={this.props.number?.toString() || ""}
                     style={{marginRight: "20px"}}
            />

            <TextBox id={"txtStatus"}
                     width={200}
                     label={this.context.translate(translations.fields.status)}
                     required={false}
                     readonly={true}
                     value={status.name}
                     style={{marginRight: "20px"}}
            />

            <TextBox id={"txtAuthor"}
                     width={250}
                     label={this.context.translate(translations.fields.creator)}
                     required={false}
                     readonly={true}
                     value={User.getFullName(this.props.createdBy)}
                     style={{marginRight: "20px"}}
            />

            <TextBox id={"txtOwner"}
                     width={250}
                     label={this.context.translate(translations.fields.owner)}
                     required={false}
                     readonly={true}
                     value={User.getFullName(this.props.ownerId)}
            />

        </div>
    }
}

