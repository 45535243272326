import React from "react";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {ElementLayout, ResizeInfo, WDElementContainer} from "../WDElementContainer";
import Const from "../../../Framework/Const";
import {MainContext} from "../../../_base/MainContext";
import {Draw, DrawBorder, DrawBorderStyle, DrawLineStyle} from "../../../Framework/Draw";
import {Coords} from "../../../Framework/Coords";
import {WDUtils} from "../../Utils/WDUtils";
import Converter from "../../../Framework/Converter";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {NameValueToEnumKey} from "../../../Framework/Enums";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {WDActionLogCategory, WDActionLogEntryDetails, WDActionLogType} from "../../ActionLog/WDActionLogEntry";
import {WDElementHistoryItem} from "../../History/WDElementHistoryItem";

export class WDLineData extends WDElementBaseData {
    pos1: Coords
    pos2: Coords
    type: WDLineType

    constructor(pos1: Coords, pos2: Coords, type: WDLineType) {
        super();

        this.pos1 = pos1
        this.pos2 = pos2
        this.type = type
    }

    static serialize = (data: WDLineData): string => {
        return JSON.stringify(data, (key, value) => {
            if (key === "url" || key === "width" || key === "height") return undefined;
            else return value;
        })
    }
}

interface IProps extends WDElementBaseProps {
    data: WDLineData
    isReadOnly: boolean

    propagateEvent: (event: React.UIEvent, itemKey: string) => void
}

interface IState extends WDElementBaseState {
    path: Path2D | undefined
    currentPoint?: string
    renderWrapper: boolean
}

export enum WDLineType {
    Solid1 = "solid_1",
    Dotted1 = "dotted_1",
    Dotted2 = "dotted_2",
    Dashed1 = "dashed_1",
    Dashed2 = "dashed_2",
    Dashed3 = "dashed_3",
}

export class WDLine extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    resizeInfo: ResizeInfo =
        new ResizeInfo(false, false, false, false,
            false, false, false, false,
            this.getMinWidth(), Const.MaxElementSize, this.getMinHeight(), Const.MaxElementSize)

    moveControlPointHistory: boolean = false
    SELECTION_RADIUS = 4
    renderedPath: Path2D = new Path2D()

    constructor(props: IProps) {
        super(props)

        this.state = {
            isEdited: false,
            path: undefined,
            renderWrapper: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef()
        }

        //this.resizeInfo.displaySelectionBorder = false
    }

    async componentDidMount() {
        // resize container to correct size after create of line
        let update = this.getResizeContainerUpdate(this.props.data)
        if (update) {
            await this.props.onUpdateElement(update)
        }

        this.renderLine()
    }
    componentDidUpdate() {
        this.renderLine()
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getDefaultWidth = () => {
        return 200;
    }
    static getDefaultHeight = () => {
        return 100;
    }
    static getDefaultContentData = (): WDLineData => {
        return new WDLineData(
            new Coords(Const.ELEMENT_PADDING, Const.ELEMENT_PADDING),
            new Coords(200 - Const.ELEMENT_PADDING, Const.ELEMENT_PADDING),
            WDLineType.Solid1
        )
    }
    static getDefaultContent = (): string => {
        return JSON.stringify(WDLine.getDefaultContentData())
    }
    static mapImageKeyToLineType = (imageKey: string): WDLineType => {
        let type = imageKey.replace("AT_ALG_LINE_", "").toLowerCase()
        return WDLineType[NameValueToEnumKey(type, WDLineType)]
    }

    /**
     * Overridden methods
     */
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDLineData.serialize(data as WDLineData)
    }

    getCanvasId = () => {
        return this.props.id + "-canvas-container"
    }

    getMouseCoords = (pos: Coords) => {
        pos = WDUtils.convertCoordsToAnchorObjectCoordinates(this.props.id, pos)

        return new Coords(
            Converter.toMmGrid((pos.x / this.context.getZoom()) - this.props.element.posX),
            Converter.toMmGrid((pos.y / this.context.getZoom()) - this.props.element.posY)
        )
    }
    checkLineGrabberClicked = (pos: Coords): string => {
        if (this.props.data.pos1 &&
            pos.x >= this.props.data.pos1.x - this.SELECTION_RADIUS && pos.x <= this.props.data.pos1.x + this.SELECTION_RADIUS &&
            pos.y >= this.props.data.pos1.y - this.SELECTION_RADIUS && pos.y <= this.props.data.pos1.y + this.SELECTION_RADIUS) {

            return "pos1"
        } else if (this.props.data.pos2 &&
            pos.x >= this.props.data.pos2.x - this.SELECTION_RADIUS && pos.x <= this.props.data.pos2.x + this.SELECTION_RADIUS &&
            pos.y >= this.props.data.pos2.y - this.SELECTION_RADIUS && pos.y <= this.props.data.pos2.y + this.SELECTION_RADIUS) {

            return "pos2"
        }

        return ""
    }

    getResizeContainerUpdate = (data: WDLineData) => {
        if (data.pos1 === undefined || data.pos2 === undefined) {
            return
        }

        let newLayout = ElementLayout.createFromWorksheetItem(this.props.element)
        let minX = Const.ELEMENT_PADDING - Math.min(data.pos1.x, data.pos2.x),
            minY = Const.ELEMENT_PADDING - Math.min(data.pos1.y, data.pos2.y)

        if (minX !== 0) {
            let right = newLayout.left + newLayout.width
            newLayout.left -= minX
            newLayout.width = right - newLayout.left
        } else {
            let maxX = Math.round((newLayout.width - Const.ELEMENT_PADDING) - Math.max(data.pos1.x, data.pos2.x))
            newLayout.width -= maxX
        }

        if (minY !== 0) {
            let bottom = newLayout.top + newLayout.height
            newLayout.top -= minY
            newLayout.height = bottom - newLayout.top
        } else {
            let maxY = Math.round((newLayout.height - Const.ELEMENT_PADDING) - Math.max(data.pos1.y, data.pos2.y))
            newLayout.height -= maxY
        }

        if (data.pos2.x < data.pos1.x) {
            data.pos1.x = newLayout.width - Const.ELEMENT_PADDING
            data.pos2.x = Const.ELEMENT_PADDING
        } else {
            data.pos2.x = newLayout.width - Const.ELEMENT_PADDING
            data.pos1.x = Const.ELEMENT_PADDING
        }

        if (data.pos2.y < data.pos1.y) {
            data.pos1.y = newLayout.height - Const.ELEMENT_PADDING
            data.pos2.y = Const.ELEMENT_PADDING
        } else {
            data.pos2.y = newLayout.height - Const.ELEMENT_PADDING
            data.pos1.y = Const.ELEMENT_PADDING
        }

        // Resize container if the sizes changes due to point movement
        if (newLayout.top !== this.props.element.posY || newLayout.left !== this.props.element.posX ||
            newLayout.width !== this.props.element.width || newLayout.height !== this.props.element.height) {

            return new WorksheetItemUpdate(this.props.id, {
                content: this.serializeElementData(data),
                posX: newLayout.left,
                posY: newLayout.top,
                width: newLayout.width,
                height: newLayout.height
            })
        }
    }

    onMouseDown = (e: React.MouseEvent, bubbleToSibling?: boolean) => {
        if (this.props.data.pos1 === undefined || this.props.data.pos2 === undefined) {
            return
        }

        let pos = this.getMouseCoords(new Coords(e.clientX, e.clientY))

        let processed = false
        if (this.props.element.selected && !this.props.element.locked) {
            let grabber = this.checkLineGrabberClicked(pos)
            if (grabber !== "") {
                e.preventDefault()
                e.stopPropagation()

                this.moveControlPointHistory = false

                document.addEventListener("mousemove", this.onMouseMoveControlPoint)
                document.addEventListener("mouseup", this.onMouseUp)

                processed = true
                this.setState({currentPoint: grabber})
            }
        } else {
            let canvas = document.getElementById(this.getCanvasId()) as HTMLCanvasElement
            let draw = new Draw(canvas)

            if (this.state.path) {
                let pointInPath = draw.IsPointInStroke(this.state.path, pos)
                if (pointInPath) {
                    e.preventDefault()
                    e.stopPropagation()

                    processed = true
                    this.props.onElementSelect?.(this.props.id, true, !e.ctrlKey)
                }
            }
        }

        if (!processed && bubbleToSibling !== false) {
            e.stopPropagation()

            document.elementsFromPoint(e.clientX, e.clientY)
                .filter((element) => element.className.includes("ws-designer-element-container") && element.id !== this.props.id + "-container")
                .forEach((element) => {
                    this.props.propagateEvent(e, element.id.replace("-container", ""))
                })
        }
    }
    onMouseMove = (e: React.MouseEvent) => {
        let newPos = this.getMouseCoords(new Coords(e.clientX, e.clientY))

        // When moving the mouse on a selected line render a wrapper at the mouse position
        if (this.props.element.selected && !this.props.element.locked) {
            let canvas = document.getElementById(this.getCanvasId()) as HTMLCanvasElement
            let draw = new Draw(canvas)

            if (this.state.path) {
                let pointInPath = draw.IsPointInStroke(this.state.path, newPos)
                if (pointInPath) {
                    let grabber = this.checkLineGrabberClicked(newPos)
                    pointInPath = (grabber === "")
                }

                if (this.state.renderWrapper !== pointInPath) {
                    this.setState({renderWrapper: pointInPath})
                }
            }
        }
    }
    onMouseMoveControlPoint = async(e: MouseEvent) => {
        let newPos = this.getMouseCoords(new Coords(e.clientX, e.clientY))

        if (this.state.currentPoint !== undefined) {
            let newData = {...this.props.data}
            let other = this.state.currentPoint === "pos1" ? newData["pos2"] : newData["pos1"]

            // Snap logic for horizontal and vertical lines
            newData[this.state.currentPoint] = newPos
            if (other && other.x >= newPos.x - 5 && other.x <= newPos.x + 5) {
                newData[this.state.currentPoint].x = other.x
            }
            if (other && other.y >= newPos.y - 5 && other.y <= newPos.y + 5) {
                newData[this.state.currentPoint].y = other.y
            }

            let update = this.getResizeContainerUpdate(newData)
            if (update) {
                // Create or update history entry
                if (!this.moveControlPointHistory) {
                    this.props.pushHistory(
                        [new WorksheetItemUpdate(this.props.id, {
                            content: this.serializeElementData(this.props.data),
                            posX: this.props.element.posX,
                            posY: this.props.element.posY,
                            width: this.props.element.width,
                            height: this.props.element.height
                        })],
                        [update]
                    )
                    this.moveControlPointHistory = true
                }
                else {
                    this.props.updateHistory(new WDElementHistoryItem([update]))
                }

                await this.props.onUpdateElement(update)
            }
        }
    }
    onMouseUp = async (e: MouseEvent) => {
        document.removeEventListener("mousemove", this.onMouseMoveControlPoint)
        document.removeEventListener("mouseup", this.onMouseUp)

        let newData = {...this.props.data}
        let update = this.getResizeContainerUpdate(newData)
        if (update) {
            if (this.moveControlPointHistory) {
                this.props.updateHistory(new WDElementHistoryItem([update]))
                this.moveControlPointHistory = false
            }

            await this.props.onUpdateElement(update)
        }

        this.context.addWDAction(WDActionLogType.Info, WDActionLogCategory.content,
            [new WDActionLogEntryDetails(this.props.element.itemKey, this.props.element.worksheetItemTypeId, undefined,
                {pos1: this.props.data.pos1, pos2: this.props.data.pos2})])

        this.setState({
            currentPoint: undefined,
            path: this.renderedPath
        })
    }

    doAction = (action: WDToolbarAction, data?: string) => {
        let newData = {...this.props.data}

        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.CHANGE_GRAPHIC:
                if (data && data["image"]) {
                    let typeString = WDLine.mapImageKeyToLineType(data["image"])
                    newData.type = WDLineType[NameValueToEnumKey(typeString, WDLineType)]
                }
                break

            default:
                break
        }

        update.value.content = this.serializeElementData(newData)
        return update
    }

    onPropagateEvent = (e: React.UIEvent) => {
        if (e.type === "mousedown") {
            this.onMouseDown(e as React.MouseEvent, false)
        }
    }

    renderLine = () => {
        let canvas = document.getElementById(this.getCanvasId()) as HTMLCanvasElement
        if (canvas === null || canvas === undefined) {
            return
        }

        if (this.props.data.pos1 && this.props.data.pos2) {
            let color = this.props.element.borderColor !== "transparent" ? this.props.element.borderColor : Const.COLOR_PRIMARY
            let lineWidth = this.props.element.borderWeight > 0 ? this.props.element.borderWeight : 3

            let draw = new Draw(canvas)

            draw.Clear()
            draw.setBorder(new DrawBorder(color, DrawBorderStyle.miter, 0))
            draw.setFillColor(color)
            draw.setLineWidth(lineWidth)

            switch (this.props.data.type) {
                case WDLineType.Solid1:
                    draw.setLineStyle(DrawLineStyle.solid)
                    draw.Line(this.props.data.pos1, this.props.data.pos2)
                    break

                case WDLineType.Dashed1:
                    draw.setLineStyle(DrawLineStyle.dashed_wide)
                    draw.Line(this.props.data.pos1, this.props.data.pos2)
                    break

                case WDLineType.Dashed2:
                    draw.setLineStyle(DrawLineStyle.dashed)
                    draw.Line(this.props.data.pos1, this.props.data.pos2)
                    break

                case WDLineType.Dashed3:
                    draw.setLineStyle(DrawLineStyle.dashed_medium)
                    draw.Line(this.props.data.pos1, this.props.data.pos2)
                    break

                case WDLineType.Dotted1:
                    draw.setLineStyle(DrawLineStyle.solid)
                    draw.LineDotted(this.props.data.pos1, this.props.data.pos2, lineWidth / 2, lineWidth)
                    break

                case WDLineType.Dotted2:
                    draw.setLineStyle(DrawLineStyle.solid)
                    draw.LineDotted(this.props.data.pos1, this.props.data.pos2, lineWidth / 2, lineWidth * 2)
                    break
            }

            // Click area (transparent, 6px width, must be solid!)
            draw.setLineStyle(DrawLineStyle.solid)
            draw.setBorder(new DrawBorder("transparent", DrawBorderStyle.miter, 15))
            this.renderedPath = draw.Line(this.props.data.pos1, this.props.data.pos2)

            if (this.props.element.selected && !this.props.element.locked) {
                draw.setFillColor(Const.COLOR_RED)
                draw.Circle(this.props.data.pos1, this.SELECTION_RADIUS)
                draw.Circle(this.props.data.pos2, this.SELECTION_RADIUS)
            }

            // Save path initially
            if (this.state.path === undefined) {
                this.setState({path: this.renderedPath})
            }
        }
    }

    render() {
        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            hasResizeOnCreate={false}
            resizeInfo={this.resizeInfo}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.props.onElementResize}
            renderWrapper={this.state.renderWrapper}
            isEditModeAllowed={() => false}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            onMouseMove={this.onMouseMove}
            ref={this.state.elementRef}
        >

            <div className={"ws-designer-line print"}>
                <canvas id={this.getCanvasId()}
                        width={this.props.element.width}
                        height={this.props.element.height}
                        onMouseDown={this.onMouseDown}
                        onMouseMove={this.onMouseMove}>
                </canvas>
            </div>

        </WDElementContainer>
    }
}
