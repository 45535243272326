import React, {FormEvent} from "react";
import '../assets/css/layout.min.css';
import {MainContext} from "../_base/MainContext";
import {Modal} from "../Components/Modal";
import translations from "../Framework/translations.json";
import {Worksheet} from "../_model/Worksheet";
import {ButtonInfo} from "../Components/Controls/ButtonList";
import Rating from "../Components/Controls/Rating";
import TextBox from "../Components/Controls/TextBox";
import CheckBoxSwitch from "../Components/Controls/CheckBoxSwitch";

interface IProps {
    worksheet?: Worksheet

    onClose: () => void
    onSave: (rating: number, showName: boolean, description?: string) => void
}

interface IState {
    tabHeight: number

    rating?: number
    description?: string
    showName: boolean
}

export class RateWorksheetModal extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = ({
            showName: true,
            tabHeight: this.getTabHeight()
        })
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResizeWindow)
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.onResizeWindow)
    }

    getTabHeight = () => {
        return window.innerHeight * 0.7 - 100
    }

    onFormSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (this.state.rating === undefined) {
            return
        }

        this.props.onSave(this.state.rating, this.state.showName, this.state.description)
    }
    onFormCancel = () => {
        this.props.onClose()
    }

    onResizeWindow = () => {
        this.setState({tabHeight: this.getTabHeight()})
    }
    onRatingChange = (rating: number) => {
        this.setState({rating: rating})
    }

    render() {
        let tabHeight = this.getTabHeight()

        let placeholder = this.context.translate(translations.text.max_length)
        placeholder = placeholder.replace("%1%", "200")

        return <Modal id={"settings"}
                      onFormSubmit={this.onFormSubmit}
                      onFormCancel={this.onFormCancel}
                      title={this.context.translate(translations.text.marketplace.rate_marketplace_worksheet_title)}
                      buttons={[new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.no_thanks), this.onFormCancel),
                          new ButtonInfo("btnSubmit", "button button-save", "submit", true, (this.state.rating === undefined), this.context.translate(translations.command.send), undefined, {marginLeft: "10px"})]}
                      dialogStyle={{width: "40%", height: "40%", minHeight: 380}}
                      contentStyle={{paddingTop: 0, paddingLeft: 0, paddingRight: 0, overflowY: "hidden"}}
                      contentAlignment={"flex-start"}
        >

            <div className={"modal-publish-worksheet"} style={{height: tabHeight}}>
                <div className={"form-row"} style={{marginBottom: "20px", width: "90%"}}>
                    <div className={"form-group"}>
                        <div style={{marginBottom: 20}}>
                            {this.context.translate(translations.text.marketplace.rate_marketplace_worksheet_description)}
                        </div>

                        <div className={"horizontal-center"} style={{marginTop: 25}}>
                            <Rating rating={this.state.rating || 0}
                                    iconSize={32}
                                    onChange={this.onRatingChange}
                            />
                        </div>

                        <TextBox id={"description"}
                                 label={this.context.translate(translations.text.marketplace.rating_description)}
                                 style={{marginTop: 20}}
                                 labelClass={"label-bold"}
                                 placeholder={placeholder}
                                 value={this.state.description || ""}
                                 required={(this.state.rating || 0) > 0 && (this.state.rating || 0) < 3}
                                 readonly={false}
                                 multiline={true}
                                 maxLength={200}
                                 onChange={(value) => this.setState({description: value})}
                        />

                        <CheckBoxSwitch id={"showName"}
                                        label={this.context.translate(translations.text.marketplace.show_name)}
                                        checked={this.state.showName}
                                        readonly={false}
                                        width={250}
                                        style={{marginTop: 20}}
                                        onChange={() => this.setState({showName: !this.state.showName})}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    }
}
