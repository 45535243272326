import React from 'react';
import translations from "../../../Framework/translations.json";
import TextBox from "../../../Components/Controls/TextBox";
import {MainContext} from "../../../_base/MainContext";
import {WordType, WordTypeProps, WordTypeState} from "../WordType";
import {
    CreateInterjection,
    GetInterjection,
    UpdateInterjection
} from "../../../_endpoint/WordEndpoint";
import Word from "../../../_model/Dictionary/Word";
import {EntityData} from "../../../_model/Entity";
import Interjection from "../../../_model/Dictionary/Interjection";

export interface IProps extends WordTypeProps {
    wordId?: number
    readonly: boolean
}

interface IState extends WordTypeState {
    item: Interjection
    unsavedChanges: boolean
}

export default class InterjectionFormPart extends WordType<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            item: new Interjection(),
            unsavedChanges: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        if (this.props.wordId) {
            GetInterjection(this.props.wordId).then(
                (itemData) => {
                    this.setState({item: itemData})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }

    // close and success messages are done in word
    onSave = async (word: Word) : Promise<EntityData | undefined> => {
        let result

        if (word.id) {
            result = await UpdateInterjection(this.state.item, word)
        } else {
            result = await CreateInterjection(this.state.item, word)
        }

        this.setState({unsavedChanges: false})
        return result
    }

    onChangeText = (fieldName: string, value: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = value
        this.setState({item: currentItem, unsavedChanges: true})
    }

    render() {
        return <>
            <div className={"form-row"}>
                <TextBox id={"txtSyllabification"}
                         label={this.context.translate(translations.fields.word.conjunction.syllabification)}
                         width={400}
                         required={true}
                         readonly={this.props.readonly}
                         onChange={(text: string) => this.onChangeText("syllabification", text)}
                         value={this.state.item.syllabification || ""}
                />
            </div>
        </>
    }
}

