import React from "react";
import {MainContext} from "../../../_base/MainContext";
import translations from "../../../Framework/translations.json";
import {WritingCourseFilter} from "../../../_model/WritingCourseFilter";
import SelectBox from "../../../Components/Controls/SelectBox";
import {
    Color,
    Difficulty,
    EnumToEntityArray,
    EnumToEntityArrayWithoutTranslate,
    EnumValueToValueNumber
} from "../../../Framework/Enums";
import {SidebarAdvancedFilterArrow} from "../../Sidebar/SidebarAdvancedFilterArrow";
import LessonSubject from "../../../_model/LessonSubject";
import {GetLessonSubject} from "../../../_endpoint/MetadataEndpoint";
import {WritingCourseCharacterTypeEnum} from "../../../_model/WritingCourse";
import {WDWritingLineatureSize} from "../Lineature/WritingLineature/WDWritingLineature";
import {WDMathLineatureSize} from "../Lineature/MathLineature/WDMathLineatureData";
import CheckBoxSwitch from "../../../Components/Controls/CheckBoxSwitch";
import Entity from "../../../_model/Entity";

export interface IProps {
    filter: WritingCourseFilter
    onChangeFilter: (filter: WritingCourseFilter) => void
}
export interface IState {
    filterVisible: boolean
    filter: WritingCourseFilter

    lessonSubjects: LessonSubject[]
    characterTypeEnumValues: Entity[]
    difficultyEnumValues: Entity[]
    writingLineatureEnumValues: Entity[]
    mathLineatureEnumValues: Entity[]
    colorEnumValues: Entity[]
}

export class WDWritingCourseToolbarFilter extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            filterVisible: false,
            filter: this.props.filter,
            lessonSubjects: [],

            difficultyEnumValues: EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate),
            writingLineatureEnumValues: this.initEnums(WDWritingLineatureSize),
            mathLineatureEnumValues: this.initEnums(WDMathLineatureSize),
            characterTypeEnumValues: this.initEnums(WritingCourseCharacterTypeEnum, translations.enum.character_type),
            colorEnumValues: this.initEnums(Color, translations.enum.color)
        }
    }

    componentDidMount() {
        this.fetchLists()
    }
    initEnums = (enumTyp: { [s: string]: string }, translationPath?: any) : Entity[] => {
        let enumEntityArray = translationPath ? EnumToEntityArray(enumTyp, translationPath, this.context.translate) : EnumToEntityArrayWithoutTranslate(enumTyp)
        enumEntityArray.splice(0, 0, new Entity(this.context.translate(translations.enum.difficulty.none), -1))
        return enumEntityArray
    }
    fetchLists = () => {
        GetLessonSubject().then((result) => {
            result = result.filter(l => l.code === "MA" || l.code === "D")
            result.splice(0, 0, new LessonSubject(this.context.translate(translations.enum.difficulty.none), "", -1))
            this.setState({lessonSubjects: result})
        })
    }

    onChangeFilterSubject = (value: number) => {
        let currentFilter = this.state.filter
        currentFilter.lessonSubject = this.state.lessonSubjects?.find(item => item.id === value && item.id > 0)

        // Set filter back undefined to get all elements
        if(value === -1) {
            currentFilter.lessonSubject = undefined
        }

        this.props.onChangeFilter(currentFilter)
        this.setState({filter: currentFilter})
    }
    onChangeFilterDropdownValue = (value: number, fieldName: string, enumType: any,  enumArray: Entity[]) => {
        let currentFilter = this.state.filter

        // Set filter back undefined to get all elements
        if(value === enumArray[0].id) {
            currentFilter[fieldName] = undefined
        }
        // Set enum value
        else {
            let enumValue = Object.values(enumType).find((e, i) => i === value)
            if (enumValue) {
                currentFilter[fieldName] = enumValue
            }
        }

        this.props.onChangeFilter(currentFilter)
        this.setState({filter: currentFilter})
    }
    /**
     * Changes the value of the bool field.
     * Can only set to undefined or true.
     * Undefined: irrelevant if elements have fieldname === true or false
     * True: only elements matching the fieldname value are included
     * @param fieldName name of the bool field
     */
    onChangeFilterCheckboxValue = (fieldName: string) => {
        let currentFilter = this.state.filter

        if(currentFilter[fieldName] === true) {
            currentFilter[fieldName] = undefined
        } else {
            currentFilter[fieldName] = true
        }

        this.props.onChangeFilter(currentFilter)
        this.setState({filter: currentFilter})
    }

    onToggleSettings = () => {
        this.setState({filterVisible: !this.state.filterVisible})
    }
    render() {
        return <div className={"ws-designer-toolbar-button-filter"}>
            {this.state.filterVisible !== undefined &&
                <div className={this.state.filterVisible
                        ? "ws-designer-toolbar-button-filter-advanced-show ws-designer-toolbar-button-filter-writing-course-animation-in"
                        : "ws-designer-toolbar-button-filter-advanced-hide ws-designer-toolbar-button-filter-writing-course-animation-out"}
                >
                    <div className={"ws-designer-toolbar-button-filter-column"}>
                        <SelectBox id={"selSubject"}
                                   width={180}
                                   label={this.context.translate(translations.fields.lesson_subject)}
                                   labelClass={"label"}
                                   data={this.state.lessonSubjects || []}
                                   value={this.state.filter.lessonSubject?.id || -1}
                                   style={{paddingTop: "10px", justifyContent: "space-between", marginBottom: 0}}
                                   required={false}
                                   readonly={false}
                                   positioningRow={true}
                                   onChange={(value) => this.onChangeFilterSubject(value)}
                        />
                        <SelectBox id={"selCharacterType"}
                                   width={180}
                                   style={{paddingTop: "10px", justifyContent: "space-between", marginBottom: 0}}
                                   label={this.context.translate(translations.fields.writing_course.character_type)}
                                   labelClass={"label"}
                                   value={EnumValueToValueNumber(WritingCourseCharacterTypeEnum, this.state.filter.characterType)}
                                   data={this.state.characterTypeEnumValues}
                                   required={false} readonly={false}
                                   onChange={(value) => this.onChangeFilterDropdownValue(
                                       value,
                                       "characterType",
                                       WritingCourseCharacterTypeEnum,
                                       this.state.characterTypeEnumValues)}
                                   positioningRow={true}
                        />
                        <SelectBox id={"selDifficulty"}
                                   width={180}
                                   style={{paddingTop: "10px", justifyContent: "space-between", marginBottom: 0}}
                                   label={this.context.translate(translations.fields.difficulty)}
                                   labelClass={"label"}
                                   value={EnumValueToValueNumber(Difficulty, this.state.filter.difficulty)}
                                   data={this.state.difficultyEnumValues}
                                   required={false} readonly={false}
                                   onChange={(value) => this.onChangeFilterDropdownValue(
                                       value,
                                       "difficulty",
                                       Difficulty,
                                       this.state.difficultyEnumValues)}
                                   positioningRow={true}
                        />
                        <SelectBox id={"selColor"}
                                   width={180}
                                   style={{paddingTop: "10px", justifyContent: "space-between", marginBottom: 0}}
                                   label={this.context.translate(translations.fields.color)}
                                   labelClass={"label"}
                                   value={EnumValueToValueNumber(Color, this.state.filter.color)}
                                   data={this.state.colorEnumValues}
                                   required={false} readonly={false}
                                   onChange={(value) => this.onChangeFilterDropdownValue(
                                       value,
                                       "color",
                                       Color,
                                       this.state.colorEnumValues)}
                                   positioningRow={true}
                        />
                        <SelectBox id={"selWritingLineature"}
                                   width={180}
                                   style={{paddingTop: "10px", justifyContent: "space-between", marginBottom: 0}}
                                   label={this.context.translate(translations.fields.writing_course.lineature_type_german)}
                                   labelClass={"label"}
                                   value={EnumValueToValueNumber(WDWritingLineatureSize, this.state.filter.writingLineatureSize)}
                                   data={this.state.writingLineatureEnumValues}
                                   required={false} readonly={false}
                                   onChange={(value) => this.onChangeFilterDropdownValue(
                                       value,
                                       "writingLineatureSize",
                                       WDWritingLineatureSize,
                                       this.state.writingLineatureEnumValues)}
                                   positioningRow={true}
                        />
                        <SelectBox id={"selMathLineature"}
                                   width={180}
                                   style={{paddingTop: "10px", justifyContent: "space-between", marginBottom: 0}}
                                   label={this.context.translate(translations.fields.writing_course.lineature_type_math)}
                                   labelClass={"label"}
                                   value={EnumValueToValueNumber(WDMathLineatureSize, this.state.filter.mathLineatureSize)}
                                   data={this.state.mathLineatureEnumValues}
                                   required={false} readonly={false}
                                   onChange={(value) => this.onChangeFilterDropdownValue(
                                       value,
                                       "mathLineatureSize",
                                       WDMathLineatureSize,
                                       this.state.mathLineatureEnumValues)}
                                   positioningRow={true}
                        />

                        <br/>

                        <CheckBoxSwitch id={"chkSyllableMethod"}
                                  label={this.context.translate(translations.fields.writing_course.syllable_method)}
                                  onChange={() => this.onChangeFilterCheckboxValue("syllableMethod")}
                                  checked={this.state.filter.syllableMethod || false}
                                  style={{paddingTop: "10px", justifyContent: "space-between", padding: 0}}
                                  readonly={false}
                        />
                        <CheckBoxSwitch id={"chkPreviewSymbol"}
                                        label={this.context.translate(translations.fields.writing_course.preview_symbol)}
                                        onChange={() => this.onChangeFilterCheckboxValue("previewSymbol")}
                                        checked={this.state.filter.previewSymbol || false}
                                        style={{justifyContent: "space-between", padding: 0}}
                                        readonly={false}
                        />
                        <CheckBoxSwitch id={"chkPreviewSymbolImage"}
                                        label={this.context.translate(translations.fields.writing_course.preview_symbol_image)}
                                        onChange={() => this.onChangeFilterCheckboxValue("previewSymbolImage")}
                                        checked={this.state.filter.previewSymbolImage || false}
                                        style={{justifyContent: "space-between", padding: 0}}
                                        readonly={false}
                        />
                        <CheckBoxSwitch id={"chkPreviewSymbolTrace"}
                                        label={this.context.translate(translations.fields.writing_course.preview_symbol_trace)}
                                        onChange={() => this.onChangeFilterCheckboxValue("previewSymbolTrace")}
                                        checked={this.state.filter.previewSymbolTrace || false}
                                        style={{justifyContent: "space-between", padding: 0}}
                                        readonly={false}
                        />
                        <CheckBoxSwitch id={"chkStartNumber"}
                                        label={this.context.translate(translations.fields.writing_course.start_number)}
                                        onChange={() => this.onChangeFilterCheckboxValue("startNumber")}
                                        checked={this.state.filter.startNumber || false}
                                        style={{justifyContent: "space-between", padding: 0}}
                                        readonly={false}
                        />
                        <CheckBoxSwitch id={"chkSequenceNumber"}
                                        label={this.context.translate(translations.fields.writing_course.sequence_number)}
                                        onChange={() => this.onChangeFilterCheckboxValue("sequenceNumber")}
                                        checked={this.state.filter.sequenceNumber || false}
                                        style={{justifyContent: "space-between", padding: 0}}
                                        readonly={false}
                        />
                        <CheckBoxSwitch id={"chkUpperLowerLetterMixed"}
                                        label={this.context.translate(translations.fields.writing_course.mixed_upper_lower_letter)}
                                        onChange={() => this.onChangeFilterCheckboxValue("upperLowerLetterMixed")}
                                        checked={this.state.filter.upperLowerLetterMixed || false}
                                        style={{justifyContent: "space-between", padding: 0}}
                                        readonly={false}
                        />
                    </div>
                </div>

            }

            <SidebarAdvancedFilterArrow
                advancedSettingsVisible={this.state.filterVisible!}
                textMore={this.context.translate(translations.text.more_settings)}
                textLess={this.context.translate(translations.text.less_settings)}
                onToggle={this.onToggleSettings}
                className={"ws-designer-toolbar-button-filter-more-item-right"}
            />
        </div>
    }
}
