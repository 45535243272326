import React, {RefObject} from "react";
import {CollapseState, MenuLevel} from "./MenuLevel";
import {MenuItem} from "../../_model/MenuItem";

export enum MenuType {
    app = "app",
    toolbox = "toolbox"
}
export enum MenuContext {
    toolbox = "toolbox",
    app = "app",
    admin = "admin"
}
export enum MenuExerciseType {
    automatic = "automatic",
    exercise = "exercise",
    pattern = "pattern",
    image = "image"
}

interface IProps {
    menuType: MenuType
    menuContext: MenuContext
    isEditingAllowed?: boolean

    onMenuItemClicked?: (item?: MenuItem) => void
    onMenuItemBack?: () => void
}
interface IState {
    ref: RefObject<MenuLevel>
}

export class Menu extends React.Component<IProps, IState> {

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            ref: React.createRef<MenuLevel>()
        }
    }

    closeMenu = () => {
        if (this.state.ref.current) {
            this.state.ref.current.onCloseMenu()
        }
    }

    render() {
        return  <div className="menu">
            <MenuLevel
                menuType={this.props.menuType}
                menuContext={this.props.menuContext}
                level={0}
                lastLevel={false}
                className="menu-level"
                callstack={[]}
                isCollapsable={true}
                isEditingAllowed={this.props.isEditingAllowed}
                collapseState={CollapseState.manualCollapsed}
                onItemClicked={this.props.onMenuItemClicked}
                onBack={this.props.onMenuItemBack}
                ref={this.state.ref}
            />
        </div>
    }
}

export function getMenuItemCSS(menuContext: MenuContext, isSelected: boolean, hasHoverEffect: boolean) {
    let menuClass = "menu-item"

    if(isSelected && hasHoverEffect) {
        menuClass += " menu-item-selected menu-item-hover"
    } else if(hasHoverEffect) {
        menuClass += " menu-item-hover"
    }

    menuClass += " tooltip"

    return menuClass
}
