import {EntityData} from "./Entity";
import User from "./User";
import {Status} from "../Framework/Enums";
import {Worksheet} from "./Worksheet";
import Tag from "./Tag";

export default class SalesMaterial extends EntityData {
    publishOnMP: boolean
    description?: string
    worksheet?: Worksheet
    tags?: Tag[]

    constructor(name: string, status: Status, createdOn?: Date, createdBy?: User, modifiedOn?: Date, modifiedBy?: User,
                ownerId?: User, id?: number) {

        super(name, status, createdOn, createdBy, modifiedOn, modifiedBy, ownerId, id);

        this.status = status
        this.publishOnMP = false
    }
}
