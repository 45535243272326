import {WorksheetPage} from "../../../_model/WorksheetPage";
import React from "react";
import {MainContext} from "../../../_base/MainContext";
import translations from "../../../Framework/translations.json";
import Const from "../../../Framework/Const";
import {ImagePath} from "../../../Framework/CategoryImage";
import {WDUtils} from "../../Utils/WDUtils";
import domToImage from 'dom-to-image';
import {LogLevel} from "../../../Framework/Log";

interface IProps {
    page: WorksheetPage
    pageIndex: number
    className: string

    onMouseDown: (e: React.MouseEvent, key: string) => void
    onDblClick: (e: React.MouseEvent, key: string) => void
}

interface IState {
    thumbnail?: string
}

export class PagePreview extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {}
    }

    componentDidMount() {
        // When duplicating a page the page is not yet rendered
        setTimeout(this.generateImage, 1000)
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.page.orientation !== prevProps.page.orientation) {
            this.setState({thumbnail: undefined}, this.generateImage)
        }
    }

    generateImage = async ()=> {
        // console.log("Generating preview for " + WorksheetPage.getUniqueElementIdentifier(this.props.page))

        let pageElement = document.getElementById(WorksheetPage.getUniqueElementIdentifier(this.props.page))
        if (pageElement === null || pageElement === undefined) {
            return
        }

        let clone = await WDUtils.generateThumbnailElement(pageElement!.parentElement!)
        if (clone === null) {
            return
        }

        domToImage.toPng(clone).then(
            (imageUrl) => {
                this.context.log.debug("Generated preview for " + pageElement!.id)
                this.context.log.flush(LogLevel.INFO)

                clone?.remove()

                this.setState({thumbnail: imageUrl})
            },
            (error) => {
                console.error(error)
            }
        )
    }

    render() {
        const pageTitle = this.context.translate(translations.text.page) + " " + (this.props.pageIndex + 1)
        const key = WorksheetPage.getUniqueElementIdentifier(this.props.page)

        return <div
            id={"page-preview-" + key}
            className={this.props.className}
            onMouseDown={(e) => this.props.onMouseDown(e, key)}
            onDoubleClick={(e) => this.props.onDblClick(e, key)}
            key={key}
        >
            <div
                className={"ws-designer-sidebar-page-preview " + (this.props.page.orientation ? "ws-designer-sidebar-page-preview-portrait" : "ws-designer-sidebar-page-preview-landscape")}>

                {this.state.thumbnail ?
                    <img id={"img-" + key}
                         src={this.state.thumbnail}
                         className={"ws-designer-sidebar-page-preview-thumbnail"}
                         alt={pageTitle}
                    />
                    :
                    <>
                        <img id={"img-" + key}
                             src={Const.EMPTY_IMAGE}
                             className={"ws-designer-sidebar-page-preview-thumbnail"}
                             alt={pageTitle}
                        />
                        <img src={ImagePath.getNotificationUrl() + "loading.gif"}
                             className={"ws-designer-sidebar-page-item-loading"}
                             alt={"Loading..."}
                        />
                    </>
                }
                {this.props.page.solution &&
                    // <div className={"ws-designer-sidebar-page-preview-overlay"}>Lösung</div>
                    <img id={"overlay-" + key}
                         src={ImagePath.getButtonUrl() + "page_solution.svg"}
                         className={"ws-designer-sidebar-page-preview-overlay"}
                         alt={""}
                    />}
            </div>
            {pageTitle}
        </div>
    }
}