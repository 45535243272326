import User from "../_model/User";
import API from "../Framework/API";
import {UserSettings} from "../_model/UserSettings";
import {VicidoProduct} from "../_model/VicidoProduct";

const URL = "users"

export function GetAllUser() {
    return API.Get<User[]>(URL)
}
export function GetAllAdmins() {
    return API.Get<User[]>(URL + "/admins")
}
export function GetUserProductLicenses() {
    let url = URL + "/subscription-product-licenses"
    return API.Get<VicidoProduct[]>(url)
}
export function GetUserSettings() {
    let url = URL + "/settings"
    return API.Get<UserSettings>(url)
}
export function UpdateUserSettings(settings: UserSettings) {
    let url = URL + "/settings"
    let newSettings = new UserSettings(
        settings.id,
        settings.profilePicture,
        settings.languageId,
        settings.autoSave,
        settings.autoSaveOnClose,
        settings.listPageSize,
        settings.syllable,
        settings.syllableColor1,
        settings.syllableColor2)

    return API.Put<object, UserSettings>(url, newSettings)
}
