export class Fusion {
    wordId?: number
    syllabification?: string

    toString = () : string => {
        return "- Fusion"
    }
}

export default Fusion
