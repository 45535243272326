import {EntityData} from "./Entity";
import {AdminContentType, Status} from "../Framework/Enums";
import User from "./User";

export default class SearchResult extends EntityData {
    type: AdminContentType
    details: string
    resultAmount: number

    constructor(name: string, type: AdminContentType, details: string, resultAmount: number,
                status: Status, createdOn?: Date, createdBy?: User, modifiedOn?: Date, modifiedBy?: User,
                ownerId?: User, id?: number) {
        super(name, status, createdOn, createdBy, modifiedOn, modifiedBy, ownerId, id);

        this.type = type
        this.details = details
        this.resultAmount = resultAmount
    }
}
