import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButton} from "../WDToolbarButton";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {TooltipText} from "../../../../Components/Tooltips";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";
import {DropDown, DropDownType} from "../../../../Components/Controls/DropDown";

/**
 * Number input in Lineature Toolbar
 * Effects rows of lineature
 * If a value is entered the space between the rows will be changed - min value is the default row spacing.
 * This button should only additionally add row spacing.
 */

interface IProps {
    id: number
    clickable: boolean
    rowSpacing?: number
    onActionButton: (action: WDToolbarAction, data: any) => void
}

export class WDToolbarButtonRowSpacing extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onChangeRowSpacing = (value: string) => {
        this.props.onActionButton(WDToolbarAction.ADD_ROW_SPACING, {additionalRowSpacing: +value})
    }

    render() {
        return <li
            className={"ws-designer-toolbar-item"}
            key={"ws-designer-toolbar-button-row-spacing"}
            style={{marginRight: "5px"}}
            id={"row-spacing"}>

            <WDToolbarButton id={12}
                             icon={ImagePath.getButtonUrl() + "line_spacing.svg"}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.line_spacing),
                                 this.context.translate(translations.tooltip.line_spacing))}
                             tooltipDisabled={new TooltipText(
                                 this.context.translate(translations.toolbar.line_spacing_disabled),
                                 this.context.translate(translations.tooltip.line_spacing_disabled)
                             )}
                             cssClass={"ws-designer-toolbar-button-ml"}
                             buttonClass={"ws-designer-toolbar-button-image-no-hover"}
                             iconClassSelected={"svg-color-1E1F28"}
                             iconClassUnselected={"svg-color-1E1F28"}
                             onToggleButton={() => {}}
                             imageWidth={13}
                             buttonWidth={25}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={false} />

            <DropDown id={"row-spacing-input"}
                      width={90}
                      type={DropDownType.NUMBER}
                      required={false}
                      readonly={!this.props.clickable}
                      autocomplete={false}
                      maxlength={4}
                      unit={"mm"} unitWidth={25} unitPaddingRight={4}
                      minValue={0} maxValue={10}
                      value={this.props.rowSpacing?.toString() || "0"}
                      items={[
                          { value: "0", name: "0 mm" },
                          { value: "1", name: "1 mm" },
                          { value: "2", name: "2 mm" },
                          { value: "3", name: "3 mm" },
                          { value: "4", name: "4 mm" },
                          { value: "5", name: "5 mm" },
                          { value: "6", name: "6 mm" },
                          { value: "7", name: "7 mm" },
                          { value: "8", name: "8 mm" },
                          { value: "9", name: "9 mm" },
                          { value: "10", name: "10 mm" }
                      ]}
                      onChangeValue={this.onChangeRowSpacing} />
        </li>
    }
}
