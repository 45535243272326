export enum PronounType {
    Personalpronomen = 'Personalpronomen',
    Demonstrativpronomen = 'Demonstrativpronomen',
    Possessivpronomen = 'Possessivpronomen',
    Relativpronomen = 'Relativpronomen',
    Reflexivpronomen = 'Reflexivpronomen',
    Interrogativpronomen = 'Interrogativpronomen',
    Indefinitpronomen = 'Indefinitpronomen'
}

export class Pronoun {
    wordId?: number
    syllabification?: string
    pronounType?: PronounType

    toString = () : string => {
        return "- Pronomen"
    }
}

export default Pronoun
