import React from "react";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";

/**
 * Rendering of one image in graphics button
 * use this class to render images in graphics button (submenu)
 *
 * @param onClickItem: method which will be called when item in is selected
 *
 */

interface IProps {
    id: number
    keyValue: string
    image: string

    imagePath?: string
    imageWidth: number
    imageHeight?: number
    imageClass?: string

    onClickItem: (id: string) => void
}

interface IState {
    hoverDraggable: boolean
}

export class WDToolbarButtonGraphicsImage extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            hoverDraggable: false
        }
    }

    onMouseOver = (event: React.MouseEvent) => {
        this.setState({hoverDraggable: true})
    }
    onMouseLeave = (event: React.MouseEvent) => {
        this.setState({hoverDraggable: false})
    }

    render() {
        let image = this.props.imagePath
            ? process.env.PUBLIC_URL + this.props.imagePath + this.props.image
            : ImagePath.getThumbnailUrl() + this.props.image

        return <img key={this.props.id + this.props.keyValue}
                    className={this.props.imageClass + " " + (this.state.hoverDraggable ? "graphic-hover" : undefined)}
                    onMouseOver={this.onMouseOver}
                    onMouseLeave={this.onMouseLeave}
                    width={this.props.imageWidth}
                    height={this.props.imageHeight}
                    draggable={"false"}
                    style={{width: this.props.imageWidth, height: this.props.imageHeight}}
                    onClick={() => this.props.onClickItem && this.props.onClickItem(this.props.keyValue)}
                    src={image}
                    alt={this.context.translate(translations.toolbar.graphic)}/>
    }
}

