import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button (Yes/No) in Toolbar
 * Effects selected element(s)
 * Only possible if exercise is generated by the logic of the element
 * Yes: solutions numbers of exercises should be shown within a bar below the element
 * No:  no bar is shown (default)
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltipDisabled?: TooltipText
    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

export class WDToolbarButtonSolutionBar extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    changeShowSolutionBar = () => {
        let valuePressed: any = {"showSolutionBar": !this.props.pressed}
        this.props.onActionButton(WDToolbarAction.SOLUTION_BAR, valuePressed)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-solution-bar"}
            id={"solutionBar"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getMathUrl() + "solution_bar.svg"}
                             iconClassSelected={"svg-color-default"}
                             iconClassUnselected={"svg-color-default"}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.solution_bar),
                                 this.context.translate(translations.tooltip.solution_bar))}
                             tooltipDisabled={this.props.tooltipDisabled}
                             onToggleButton={this.changeShowSolutionBar}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed} />
        </li>
    }
}
