import {MainContext} from "../../_base/MainContext";
import React from "react";
import {Util} from "../../Framework/Util";

export class PopupMenuItem {
    title: string
    action: (event: React.MouseEvent) => void

    constructor(title: string, action: (event: React.MouseEvent) => void) {
        this.title = title
        this.action = action
    }
}

interface IProps {
    id: string
    width?: number

    items?: PopupMenuItem[]
}

export default class PopoutMenu extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    getId = () => {
        return "popout-menu-list-" + this.props.id
    }

    openProfileMenu = (e: MouseEvent) => {
        let profileMenu = document.getElementById(this.getId());
        if (profileMenu) {
            e.stopPropagation()
            e.preventDefault()

            if (profileMenu.style.visibility === "hidden") {
                this.closeProfileMenu(e)

                profileMenu!.style.visibility = "visible";
                profileMenu!.style.animation = "fade-in 0.3s";
                document.addEventListener('click', this.closeProfileMenu, false);
            } else {
                this.closeProfileMenu(e)
            }
        }
    }
    closeProfileMenu = (e: MouseEvent) => {
        if (Util.isChildOfId(e.target as HTMLElement, this.getId())) {
            return
        }

        this.closeProfileMenuInternal(e)
    }
    closeProfileMenuInternal = (e: MouseEvent) => {
        let profileMenus = document.getElementsByClassName("popout-menu-list")
        if (profileMenus.length > 0) {
            e.stopPropagation()
            e.preventDefault()
        }

        Array.from(profileMenus).forEach((menu) => {
            let element = (menu as HTMLElement)
            element.style.visibility = "hidden"
            element.style.animation = ""
            document.removeEventListener('click', this.closeProfileMenu, false)
        })
    }

    onClickItem = (event: React.MouseEvent, action: (event: React.MouseEvent) => void) => {
        this.closeProfileMenuInternal(event.nativeEvent)
        action(event)
    }

    render() {
        return <ul className="popout-menu-list" id={this.getId()} style={{visibility: 'hidden', width: this.props.width || 235}}>
                {/* Popout Menu items*/}
                {this.props.items?.map(item => {
                    return <li key={item.title} onClick={event => this.onClickItem(event, item.action)}>
                        {item.title}
                    </li>
                })}
            </ul>
    }
}

