import {WDElementBaseData} from "../../Elements/WDElementBase";
import {ElementBorder} from "../../Elements/WDElementContainer";

export class WDFrameData extends WDElementBaseData {
    imageId?: number
    borderOptions?: ElementBorder

    constructor(imageId?: number, borderOptions?: ElementBorder) {
        super()
        this.imageId = imageId
        this.borderOptions = borderOptions
    }
}
