import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";
import {DropDown, DropDownItem, DropDownType} from "../../../../Components/Controls/DropDown";
import {Difficulty, EnumToEntityArray, EnumValueToEntity} from "../../../../Framework/Enums";

export enum NumberPlacement {
    none = "none",
    all_inner = "all_inner",
    two_inner_one_outer = "two_inner_one_outer",
    one_inner_two_outer = "one_inner_two_outer",
    all_outer = "all_outer",
    puzzle = "puzzle"
}

/**
 * Button (with submenu) in Toolbar
 * Effects selected element(s)
 * Shows number placement as dropdown
 * Shows difficulty as dropdown
 * Recalculation with different placement logic should be done
 * Number placement and difficulty are connected to each other
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltipDisabled?: TooltipText
    numberPlacementValue?: NumberPlacement
    difficultyValue?: Difficulty
    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

export class WDToolbarButtonNumberPlacementDifficulty extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    numberPlacementItems: DropDownItem[] = []
    difficultyItems: DropDownItem[] = []

    componentDidMount() {
        this.numberPlacementItems.push(...EnumToEntityArray(NumberPlacement, translations.enum.number_placement, this.context.translate).map(
            i => new DropDownItem(i.id!.toString(), i.name)
        ))

        this.difficultyItems.push(...EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate).map(
            i => new DropDownItem(i.id!.toString(), i.name)
        ))
    }

    onChangeNumberPlacement = (placement: string) => {
        let enumValue = Object.values(NumberPlacement).find((e, i) => i === +placement)
        let data: any = {"numberPlacement": enumValue}
        this.props.onActionButton(WDToolbarAction.CHANGE_NUMBER_PLACEMENT, data)
    }
    onChangeDifficultyLevel = (difficulty: string) => {
        let enumValue = Object.values(Difficulty).find((e, i) => i === +difficulty)
        let valueRange: any = {"difficultyLevel": enumValue}
        this.props.onActionButton(WDToolbarAction.CHANGE_DIFFICULTY_LEVEL, valueRange)
    }

    render() {
        let numberPlacementValue = ""
        if (this.props.numberPlacementValue) {
            numberPlacementValue = EnumValueToEntity(NumberPlacement, this.props.numberPlacementValue, translations.enum.number_placement, this.context.translate).id!.toString()
        }

        let difficultyValue = ""
        if (this.props.difficultyValue) {
            difficultyValue = EnumValueToEntity(Difficulty, this.props.difficultyValue, translations.enum.difficulty, this.context.translate).id!.toString()
        }

        return <li
            key={"ws-designer-toolbar-button-number-placement"}
            id={"number-placement-difficulty"}>

            <WDToolbarButton key={"numberPlacementDifficulty"}
                             id={this.props.id}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.difficulty_level),
                                 this.context.translate(translations.tooltip.difficulty_level))}
                             tooltipDisabled={this.props.tooltipDisabled}
                             icon={ImagePath.getMathUrl() + "difficulty.svg"}
                             onToggleButton={this.props.onToggleButton}
                             arrow={true}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed}>
            </WDToolbarButton>

            {this.props.pressed &&
                <div className="ws-designer-toolbar-submenu">
                    <label className={"ws-designer-options-section-label-normal"}>
                        {this.context.translate(translations.toolbar.difficulty_level) + '\n'}
                    </label>
                    <DropDown
                        id={"difficulty-level"}
                        type={DropDownType.TEXT}
                        readonly={!this.props.clickable}
                        required={false}
                        value={difficultyValue}
                        width={225}
                        autocomplete={false}
                        items={this.difficultyItems}
                        onChangeValue={this.onChangeDifficultyLevel} />

                    <label className={"ws-designer-options-section-label-normal"}>
                        {this.context.translate(translations.toolbar.number_placement) + '\n'}
                    </label>
                    <DropDown
                        id={"number-placement"}
                        type={DropDownType.TEXT}
                        readonly={!this.props.clickable}
                        required={false}
                        value={numberPlacementValue}
                        width={225}
                        autocomplete={false}
                        items={this.numberPlacementItems}
                        onChangeValue={this.onChangeNumberPlacement} />
                </div>
            }
        </li>
    }
}
