import React from 'react';
import translations from "../../../Framework/translations.json";
import TextBox from "../../../Components/Controls/TextBox";
import {EnumToEntityArrayWithoutTranslate, EnumValueToValueNumber} from "../../../Framework/Enums";
import {MainContext} from "../../../_base/MainContext";
import {WordType, WordTypeProps, WordTypeState} from "../WordType";
import {CreateVerb, GetVerb, UpdateVerb} from "../../../_endpoint/WordEndpoint";
import Word from "../../../_model/Dictionary/Word";
import {EntityData} from "../../../_model/Entity";
import Verb, {
    VerbBase3Help,
    VerbHelpFutureConjunctive2Sein,
    VerbHelpFutureConjunctiveSein,
    VerbHelpFutureIndicativeSein,
    VerbHelpPastConjunctive2Haben,
    VerbHelpPastConjunctive2Sein,
    VerbHelpPastConjunctiveHaben,
    VerbHelpPastConjunctiveSein,
    VerbHelpPastIndicativeHaben,
    VerbHelpPastIndicativeSein,
    VerbPerfectParticipleHelp,
    VerbSeparationByIndependence,
    VerbSeparationByIssue,
    VerbSeparationByPerson,
    VerbSeparationByStrength
} from "../../../_model/Dictionary/Verb";
import SelectBox from "../../../Components/Controls/SelectBox";

export interface IProps extends WordTypeProps {
    wordId?: number
    readonly: boolean
}

interface IState extends WordTypeState {
    item: Verb
    unsavedChanges: boolean
}

export default class VerbFormPart extends WordType<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        let item = new Verb()
        item.perfectParticipleHelp = VerbPerfectParticipleHelp.sein

        this.state = {
            item: item,
            unsavedChanges: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        if(this.props.wordId) {
            GetVerb(this.props.wordId).then(
                (itemData) => {
                    this.setState({item: itemData})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }

    // close and success messages are done in word
    onSave = async (word: Word) : Promise<EntityData | undefined> => {
        let result

        if (word.id) {
            result = await UpdateVerb(this.state.item, word)
        } else {
            result = await CreateVerb(this.state.item, word)
        }

        this.setState({unsavedChanges: false})
        return result
    }

    onChangeText = (fieldName: string, value: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeEnum = (value: number, enumType: any, itemProperty: string) => {
        // Get enum value by index (value)
        let enumValue = Object.values(enumType).find((e, i) => i === value)
        if (enumValue) {
            let currentItem = this.state.item
            currentItem[itemProperty] = enumValue
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }

    render() {
        return <>
            <div className={"form-row"}>
                <TextBox id={"txtSyllabificationSingular"}
                         label={this.context.translate(translations.fields.word.verb.syllabificationSingular)}
                         width={400}
                         required={true}
                         readonly={this.props.readonly}
                         onChange={(text: string) => this.onChangeText("syllabificationSingular", text)}
                         value={this.state.item.syllabificationSingular || ""}
                />
            </div>

            <div className="form-row" style={{marginBottom: "0px"}}>
                <div className={"form-group"}>
                    <div className={"bold-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.separation)}</div>
                </div>
            </div>
            <div className={"form-row"}>
                <div className={"form-group"}>
                    <div className="form-row" style={{marginBottom: "5px"}}>
                        <div className={"sub-label"} style={{paddingTop: "5px"}}>{this.context.translate(translations.fields.word.verb.separationByIssue)}</div>
                    </div>
                    <SelectBox id={"selSeparationByIssue"}
                               width={380}
                               data={EnumToEntityArrayWithoutTranslate(VerbSeparationByIssue)}
                               required={true}
                               value={EnumValueToValueNumber(VerbSeparationByIssue, this.state.item.separationByIssue)}
                               readonly={this.props.readonly}
                               onChange={(value) => this.onChangeEnum(value, VerbSeparationByIssue, "separationByIssue")}
                               positioningRow={false}
                    />
                </div>

                <div className={"form-group"}>
                    <div className="form-row" style={{marginBottom: "5px"}}>
                        <div className={"sub-label"} style={{paddingTop: "5px"}}>{this.context.translate(translations.fields.word.verb.separationByIndependence)}</div>
                    </div>
                    <SelectBox id={"selSeparationByIndependence"}
                               width={380}
                               data={EnumToEntityArrayWithoutTranslate(VerbSeparationByIndependence)}
                               required={true}
                               value={EnumValueToValueNumber(VerbSeparationByIndependence, this.state.item.separationByIndependence)}
                               readonly={this.props.readonly}
                               onChange={(value) => this.onChangeEnum(value, VerbSeparationByIndependence, "separationByIndependence")}
                               positioningRow={false}
                    />
                </div>
            </div>
            <div className={"form-row"}>
                <div className={"form-group"}>
                    <div className="form-row" style={{marginBottom: "5px"}}>
                        <div className={"sub-label"} style={{paddingTop: "5px"}}>{this.context.translate(translations.fields.word.verb.separationByPerson)}</div>
                    </div>
                    <SelectBox id={"selSeparationByPerson"}
                               width={380}
                               data={EnumToEntityArrayWithoutTranslate(VerbSeparationByPerson)}
                               required={true}
                               value={EnumValueToValueNumber(VerbSeparationByPerson, this.state.item.separationByPerson)}
                               readonly={this.props.readonly}
                               onChange={(value) => this.onChangeEnum(value, VerbSeparationByPerson, "separationByPerson")}
                               positioningRow={false}
                    />
                </div>

                <div className={"form-group"}>
                    <div className="form-row" style={{marginBottom: "5px"}}>
                        <div className={"sub-label"} style={{paddingTop: "5px"}}>{this.context.translate(translations.fields.word.verb.separationByStrength)}</div>
                    </div>
                    <SelectBox id={"selSeparationByStrength"}
                               width={380}
                               data={EnumToEntityArrayWithoutTranslate(VerbSeparationByStrength)}
                               required={true}
                               value={EnumValueToValueNumber(VerbSeparationByStrength, this.state.item.separationByStrength)}
                               readonly={this.props.readonly}
                               onChange={(value) => this.onChangeEnum(value, VerbSeparationByStrength, "separationByStrength")}
                               positioningRow={false}
                    />
                </div>
            </div>

            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"bold-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.basic_forms)}</div>
            </div>

            <div className={"form-row"}>
                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.verb.basic_form1)}</div>
                    <TextBox id={"txtBasicForm1"}
                             width={267}
                             required={true}
                             style={{paddingTop: "8px"}}
                             value={this.state.item.baseForm1 || ""}
                             readonly={this.props.readonly}
                             onChange={(value) => this.onChangeText("baseForm1", value)}
                    />
                </div>
                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.verb.basic_form2)}</div>
                    <TextBox id={"txtBasicForm2"}
                             width={267}
                             required={true}
                             style={{paddingTop: "8px"}}
                             value={this.state.item.baseForm2 || ""}
                             readonly={this.props.readonly}
                             onChange={(value) => this.onChangeText("baseForm2", value)}
                    />
                </div>
                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.verb.basic_form3)}</div>

                    <div className={"form-row"}>
                        <SelectBox id={"selBasicForm3Help"}
                                   width={50}
                                   data={EnumToEntityArrayWithoutTranslate(VerbBase3Help)}
                                   required={true}
                                   value={EnumValueToValueNumber(VerbBase3Help, this.state.item.baseForm3Help)}
                                   readonly={this.props.readonly}
                                   onChange={(value) => this.onChangeEnum(value, VerbBase3Help, "baseForm3Help")}
                                   positioningRow={false}
                        />
                        <TextBox id={"txtBasicForm3"}
                                 width={217}
                                 required={true}
                                 style={{paddingTop: "8px"}}
                                 value={this.state.item.baseForm3 || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("baseForm3", value)}
                        />
                    </div>
                </div>
            </div>

            {/* Tense */}
            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"bold-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.tenses)}</div>
            </div>

            {/* Present */}
            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"sub-label"} >{this.context.translate(translations.fields.word.verb.present)}</div>
            </div>
            <div className={"form-row"}>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "5px"}}>{this.context.translate(translations.fields.word.verb.present_infinitive)}</div>
                    <TextBox id={"txtPresentInfinitive"}
                             width={250}
                             required={true}
                             value={this.state.item.presentInfinitive || ""}
                             readonly={this.props.readonly}
                             onChange={(value) => this.onChangeText("presentInfinitive", value)}
                    />
                </div>

                <div className={"form-column"}>
                    <div className={"sub-label"} style={{paddingTop: "5px"}}>{this.context.translate(translations.fields.word.verb.present_participle)}</div>
                    <TextBox id={"txtPresentParticiple"}
                             width={250}
                             required={true}
                             value={this.state.item.presentParticiple || ""}
                             readonly={this.props.readonly}
                             onChange={(value) => this.onChangeText("presentParticiple", value)}
                    />
                </div>
            </div>
            <div className={"form-row"}>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.present_indicative)}</div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <TextBox id={"txtPresentIndicativeI"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentIndicativeI || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentIndicativeI", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <TextBox id={"txtPresentIndicativeYou"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentIndicativeYou || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentIndicativeYou", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <TextBox id={"txtPresentIndicativeHe"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentIndicativeHe || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentIndicativeHe", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <TextBox id={"txtPresentIndicativeShe"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentIndicativeHe || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <TextBox id={"txtPresentIndicativeIt"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentIndicativeHe || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <TextBox id={"txtPresentIndicativeWe"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentIndicativeWe || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentIndicativeWe", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <TextBox id={"txtPresentIndicativeYouPL"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentIndicativeYouPL || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentIndicativeYouPL", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <TextBox id={"txtPresentIndicativeThey"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentIndicativeThey || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentIndicativeThey", value)}
                        />
                    </div>
                </div>

                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.present_conjunctive)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <TextBox id={"txtPresentConjunctiveI"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentConjunctiveI || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentConjunctiveI", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <TextBox id={"txtPresentConjunctiveYou"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentConjunctiveYou || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentConjunctiveYou", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <TextBox id={"txtPresentConjunctiveHe"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentConjunctiveHe || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentConjunctiveHe", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <TextBox id={"txtPresentConjunctiveShe"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentConjunctiveHe || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <TextBox id={"txtPresentConjunctiveIt"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentConjunctiveHe || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <TextBox id={"txtPresentConjunctiveWe"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentConjunctiveWe || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentConjunctiveWe", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <TextBox id={"txtPresentConjunctiveYouPL"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentConjunctiveYouPL || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentConjunctiveYouPL", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <TextBox id={"txtPresentConjunctiveThey"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.presentConjunctiveThey || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentConjunctiveThey", value)}
                        />
                    </div>
                </div>

                <div className={"form-column"}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.present_imperative)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <TextBox id={"txtPresentImperativeI"}
                                 width={100}
                                 required={true}
                                 value={"-"}
                                 readonly={true}
                        />
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <TextBox id={"txtPresentImperativeYou"}
                                 width={100}
                                 required={true}
                                 value={this.state.item.presentImperativeYou || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentImperativeYou", value)}
                        />
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <TextBox id={"txtPresentImperativeYou2"}
                                 width={100}
                                 required={false}
                                 value={this.state.item.presentImperativeYou2 || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentImperativeYou2", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <TextBox id={"txtPresentImperativeHe"}
                                 width={100}
                                 required={true}
                                 value={"-"}
                                 readonly={true}
                        />
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <TextBox id={"txtPresentImperativeShe"}
                                 width={100}
                                 required={true}
                                 value={"-"}
                                 readonly={true}
                        />
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <TextBox id={"txtPresentImperativeIt"}
                                 width={100}
                                 required={true}
                                 value={"-"}
                                 readonly={true}
                        />
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <TextBox id={"txtPresentImperativeWe"}
                                 width={100}
                                 required={true}
                                 value={this.state.item.presentImperativeWe || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentImperativeWe", value)}
                        />
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <TextBox id={"txtPresentImperativeWe2"}
                                 width={100}
                                 required={false}
                                 value={this.state.item.presentImperativeWe2 || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentImperativeWe2", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <TextBox id={"txtPresentImperativeYouPL"}
                                 width={100}
                                 required={true}
                                 value={this.state.item.presentImperativeYouPL || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentImperativeYouPL", value)}
                        />
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <TextBox id={"txtPresentImperativeYouPL2"}
                                 width={100}
                                 required={false}
                                 value={this.state.item.presentImperativeYouPL2 || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentImperativeYouPL2", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <TextBox id={"txtPresentImperativeThey"}
                                 width={100}
                                 required={true}
                                 value={this.state.item.presentImperativeThey || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentImperativeThey", value)}
                        />
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <TextBox id={"txtPresentImperativeThey2"}
                                 width={100}
                                 required={false}
                                 value={this.state.item.presentImperativeThey2 || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("presentImperativeThey2", value)}
                        />
                    </div>
                </div>
            </div>

            {/* Past tense */}
            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"sub-label"} style={{marginTop: "30px"}}>{this.context.translate(translations.fields.word.verb.past_tense)}</div>
            </div>
            <div className={"form-row"}>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.past_tense_indicative)}</div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <TextBox id={"txtPastTenseIndicativeI"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseIndicativeI || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseIndicativeI", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <TextBox id={"txtPastTenseIndicativeYou"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseIndicativeYou || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseIndicativeYou", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <TextBox id={"txtPastTenseIndicativeHe"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseIndicativeHe || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseIndicativeHe", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <TextBox id={"txtPastTenseIndicativeShe"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseIndicativeHe || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <TextBox id={"txtPastTenseIndicativeIt"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseIndicativeHe || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <TextBox id={"txtPastTenseIndicativeWe"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseIndicativeWe || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseIndicativeWe", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <TextBox id={"txtPastTenseIndicativeYouPL"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseIndicativeYouPL || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseIndicativeYouPL", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <TextBox id={"txtPastTenseIndicativeThey"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseIndicativeThey || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseIndicativeThey", value)}
                        />
                    </div>
                </div>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.past_tense_conjunctive2)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <TextBox id={"txtPastTenseConjunctive2I"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseConjunctive2I || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseConjunctive2I", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <TextBox id={"txtPastTenseConjunctive2You"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseConjunctive2You || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseConjunctive2You", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <TextBox id={"txtPastTenseConjunctive2He"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseConjunctive2He || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseConjunctive2He", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <TextBox id={"txtPastTenseConjunctive2She"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseConjunctive2He || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <TextBox id={"txtPastTenseConjunctive2It"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseConjunctive2He || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <TextBox id={"txtPastTenseConjunctive2We"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseConjunctive2We || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseConjunctive2We", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <TextBox id={"txtPastTense2ConjunctiveYouPL"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseConjunctive2YouPL || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseConjunctive2YouPL", value)}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <TextBox id={"txtPastTenseConjunctive2They"}
                                 width={210}
                                 required={true}
                                 value={this.state.item.pastTenseConjunctive2They || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("pastTenseConjunctive2They", value)}
                        />
                    </div>
                </div>
            </div>

            {/* Past perfect */}
            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"sub-label"} style={{marginTop: "30px"}}>{this.context.translate(translations.fields.word.verb.past_perfect)}</div>
                <div className={"sub-label"} style={{marginTop: "30px"}}>{this.context.translate(translations.fields.word.verb.past_perfect_progressive)}</div>
            </div>
            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.verb.past_perfect_participle)}</div>
                    <TextBox id={"txtPastPerfectParticiple"}
                             width={210}
                             required={true}
                             value={this.state.item.perfectParticiple || ""}
                             readonly={this.props.readonly}
                             onChange={(value) => this.onChangeText("perfectParticiple", value)}
                    />
                </div>
                <div className={"form-column"}>
                    <SelectBox id={"selPastPerfectParticipleHelp"}
                               style={{marginTop: "13px"}}
                               width={210}
                               data={EnumToEntityArrayWithoutTranslate(VerbPerfectParticipleHelp)}
                               required={true}
                               value={EnumValueToValueNumber(VerbPerfectParticipleHelp, this.state.item.perfectParticipleHelp)}
                               readonly={this.props.readonly}
                               onChange={(value) => this.onChangeEnum(value, VerbPerfectParticipleHelp, "perfectParticipleHelp")}
                               positioningRow={false}
                    />
                </div>
            </div>
            <div className={"form-row"}>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.past_perfect_indicative)}</div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastIndicativeSein.i
                                : VerbHelpPastIndicativeHaben.i
                            }
                        </div>
                        <TextBox id={"txtPastPerfectIndicativeI"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastIndicativeSein.you
                                : VerbHelpPastIndicativeHaben.you
                            }
                        </div>
                        <TextBox id={"txtPastPerfectIndicativeYou"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastIndicativeSein.he
                                : VerbHelpPastIndicativeHaben.he
                            }
                        </div>
                        <TextBox id={"txtPastPerfectIndicativeHe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastIndicativeSein.she
                                : VerbHelpPastIndicativeHaben.she
                            }
                        </div>
                        <TextBox id={"txtPastPerfectIndicativeShe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastIndicativeSein.it
                                : VerbHelpPastIndicativeHaben.it
                            }
                        </div>
                        <TextBox id={"txtPastPerfectIndicativeIt"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastIndicativeSein.we
                                : VerbHelpPastIndicativeHaben.we
                            }
                        </div>
                        <TextBox id={"txtPastPerfectIndicativeWe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastIndicativeSein.you_pl
                                : VerbHelpPastIndicativeHaben.you_pl
                            }
                        </div>
                        <TextBox id={"txtPastPerfectIndicativeYouPL"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastIndicativeSein.they
                                : VerbHelpPastIndicativeHaben.they
                            }
                        </div>
                        <TextBox id={"txtPastPerfectIndicativeThey"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>
                </div>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.past_perfect_conjunctive)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctiveSein.i
                                : VerbHelpPastConjunctiveHaben.i
                            }
                        </div>
                        <TextBox id={"txtPastPerfectConjunctiveI"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctiveSein.you
                                : VerbHelpPastConjunctiveHaben.you
                            }
                        </div>
                        <TextBox id={"txtPastPerfectConjunctiveYou"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctiveSein.he
                                : VerbHelpPastConjunctiveHaben.he
                            }
                        </div>
                        <TextBox id={"txtPastPerfectConjunctiveHe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctiveSein.she
                                : VerbHelpPastConjunctiveHaben.she
                            }
                        </div>
                        <TextBox id={"txtPastPerfectConjunctiveShe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctiveSein.it
                                : VerbHelpPastConjunctiveHaben.it
                            }
                        </div>
                        <TextBox id={"txtPastPerfectConjunctiveIt"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctiveSein.we
                                : VerbHelpPastConjunctiveHaben.we
                            }
                        </div>
                        <TextBox id={"txtPastPerfectConjunctiveWe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctiveSein.you_pl
                                : VerbHelpPastConjunctiveHaben.you_pl
                            }
                        </div>
                        <TextBox id={"txtPastPerfectConjunctiveYouPL"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctiveSein.they
                                : VerbHelpPastConjunctiveHaben.they
                            }
                        </div>
                        <TextBox id={"txtPastPerfectConjunctiveThey"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>
                </div>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.past_perfect_progressive_conjunctive)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctive2Sein.i
                                : VerbHelpPastConjunctive2Haben.i
                            }
                        </div>
                        <TextBox id={"txtPastPerfectProgressiveConjunctive2I"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctive2Sein.you
                                : VerbHelpPastConjunctive2Haben.you
                            }
                        </div>
                        <TextBox id={"txtPastPerfectProgressiveConjunctive2You"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctive2Sein.he
                                : VerbHelpPastConjunctive2Haben.he
                            }
                        </div>
                        <TextBox id={"txtPastPerfectProgressiveConjunctive2He"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctive2Sein.she
                                : VerbHelpPastConjunctive2Haben.she
                            }
                        </div>
                        <TextBox id={"txtPastPerfectProgressiveConjunctive2She"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctive2Sein.it
                                : VerbHelpPastConjunctive2Haben.it
                            }
                        </div>
                        <TextBox id={"txtPastPerfectProgressiveConjunctive2It"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctive2Sein.we
                                : VerbHelpPastConjunctive2Haben.we
                            }
                        </div>
                        <TextBox id={"txtPastPerfectProgressiveConjunctive2We"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctive2Sein.you_pl
                                : VerbHelpPastConjunctive2Haben.you_pl
                            }
                        </div>
                        <TextBox id={"txtPastPerfectProgressiveConjunctive2YouPL"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-column">
                            {this.state.item.perfectParticipleHelp === VerbPerfectParticipleHelp.sein
                                ? VerbHelpPastConjunctive2Sein.they
                                : VerbHelpPastConjunctive2Haben.they
                            }
                        </div>
                        <TextBox id={"txtPastPerfectProgressiveConjunctive2They"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.perfectParticiple || ""}
                                 readonly={true}
                        />
                    </div>
                </div>
            </div>

            {/* Future */}
            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"form-column"}>
                    <div className={"sub-label"} style={{marginTop: "30px"}}>{this.context.translate(translations.fields.word.verb.future)}</div>
                    <TextBox id={"txtFuture"}
                             width={210}
                             required={true}
                             value={this.state.item.future1 || ""}
                             readonly={this.props.readonly}
                             onChange={(value) => this.onChangeText("future1", value)}
                    />
                </div>
            </div>
            <div className={"form-row"}>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.future_indicative)}</div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.i}
                        </div>
                        <TextBox id={"txtFutureIndicativeI"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.you}
                        </div>
                        <TextBox id={"txtFutureIndicativeYou"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.he}
                        </div>
                        <TextBox id={"txtFutureIndicativeHe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.she}
                        </div>
                        <TextBox id={"txtFutureIndicativeShe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.it}
                        </div>
                        <TextBox id={"txtFutureIndicativeIt"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.we}
                        </div>
                        <TextBox id={"txtFutureIndicativeWe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.you_pl}
                        </div>
                        <TextBox id={"txtFutureIndicativeYouPL"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.they}
                        </div>
                        <TextBox id={"txtFutureIndicativeThey"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>
                </div>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.future_conjunctive)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.i}
                        </div>
                        <TextBox id={"txtFutureConjunctiveI"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.you}
                        </div>
                        <TextBox id={"txtFutureConjunctiveYou"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.he}
                        </div>
                        <TextBox id={"txtFutureConjunctiveHe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.she}
                        </div>
                        <TextBox id={"txtFutureConjunctiveShe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.it}
                        </div>
                        <TextBox id={"txtFutureConjunctiveIt"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.we}
                        </div>
                        <TextBox id={"txtFutureConjunctiveWe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.you_pl}
                        </div>
                        <TextBox id={"txtFutureConjunctiveYouPL"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.they}
                        </div>
                        <TextBox id={"txtFutureConjunctiveThey"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>
                </div>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.future_conjunctive2)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.i}
                        </div>
                        <TextBox id={"txtFutureConjunctive2I"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.you}
                        </div>
                        <TextBox id={"txtFutureConjunctive2You"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.he}
                        </div>
                        <TextBox id={"txtFutureConjunctive2He"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.she}
                        </div>
                        <TextBox id={"txtFutureConjunctive2She"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.it}
                        </div>
                        <TextBox id={"txtFutureConjunctive2It"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.we}
                        </div>
                        <TextBox id={"txtFutureConjunctive2We"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.you_pl}
                        </div>
                        <TextBox id={"txtFutureConjunctive2YouPL"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.they}
                        </div>
                        <TextBox id={"txtFutureConjunctive2They"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future1 || ""}
                                 readonly={true}
                        />
                    </div>
                </div>
            </div>

            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"form-column"}>
                    <div className={"sub-label"} style={{marginTop: "30px"}}>{this.context.translate(translations.fields.word.verb.future2)}</div>
                    <TextBox id={"txtFuture2"}
                             width={210}
                             required={true}
                             value={this.state.item.future2 || ""}
                             readonly={this.props.readonly}
                             onChange={(value) => this.onChangeText("future2", value)}
                    />
                </div>
            </div>
            <div className={"form-row"}>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.future2_indicative)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.i}
                        </div>
                        <TextBox id={"txtFuture2IndicativeI"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.you}
                        </div>
                        <TextBox id={"txtFuture2IndicativeYou"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.he}
                        </div>
                        <TextBox id={"txtFuture2IndicativeHe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.she}
                        </div>
                        <TextBox id={"txtFuture2IndicativeShe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.it}
                        </div>
                        <TextBox id={"txtFuture2IndicativeIt"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.we}
                        </div>
                        <TextBox id={"txtFuture2IndicativeWe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.you_pl}
                        </div>
                        <TextBox id={"txtFuture2IndicativeYouPL"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureIndicativeSein.they}
                        </div>
                        <TextBox id={"txtFuture2IndicativeThey"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>
                </div>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.future2_conjunctive)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.i}
                        </div>
                        <TextBox id={"txtFuture2ConjunctiveI"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.you}
                        </div>
                        <TextBox id={"txtFuture2ConjunctiveYou"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.he}
                        </div>
                        <TextBox id={"txtFuture2ConjunctiveHe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.she}
                        </div>
                        <TextBox id={"txtFuture2ConjunctiveShe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.it}
                        </div>
                        <TextBox id={"txtFuture2ConjunctiveIt"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.we}
                        </div>
                        <TextBox id={"txtFuture2ConjunctiveWe"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.you_pl}
                        </div>
                        <TextBox id={"txtFuture2ConjunctiveYouPL"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctiveSein.they}
                        </div>
                        <TextBox id={"txtFuture2ConjunctiveThey"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>
                </div>
                <div className={"form-column"} style={{marginRight: "20px"}}>
                    <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.verb.future2_conjunctive2)}</div>
                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.i)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.i}
                        </div>
                        <TextBox id={"txtFuture2Conjunctive2I"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.you}
                        </div>
                        <TextBox id={"txtFuture2Conjunctive2You"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.he)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.he}
                        </div>
                        <TextBox id={"txtFuture2Conjunctive2He"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.she)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.she}
                        </div>
                        <TextBox id={"txtFuture2Conjunctive2She"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.it)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.it}
                        </div>
                        <TextBox id={"txtFuture2Conjunctive2It"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.we)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.we}
                        </div>
                        <TextBox id={"txtFuture2Conjunctive2We"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.you_pl)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.you_pl}
                        </div>
                        <TextBox id={"txtFuture2Conjunctive2YouPL"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>

                    <div className={"form-row"} style={{margin: "0", alignItems: "flex-end"}}>
                        <div className="admin-form-text admin-form-text-x-small-column">
                            {this.context.translate(translations.fields.word.person.they)}
                        </div>
                        <div className="admin-form-text admin-form-text-middle-big-column">
                            {VerbHelpFutureConjunctive2Sein.they}
                        </div>
                        <TextBox id={"txtFuture2Conjunctive2They"}
                                 width={160}
                                 required={true}
                                 value={this.state.item.future2 || ""}
                                 readonly={true}
                        />
                    </div>
                </div>
            </div>
        </>
    }
}

