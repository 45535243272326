import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {DropDown, DropDownType} from "../../../../Components/Controls/DropDown";
import {Util} from "../../../../Framework/Util";

/**
 * Number input and drop down in Text Toolbar
 * Effects selected text of an element
 * If a value is entered or selected from the list the font size is changed
 */

interface IProps {
    id: number
    action: WDToolbarAction
    readonly: boolean

    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    value: string
}

export class WDToolbarLineHeight extends React.Component<IProps, IState> {

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            value: ""
        }
    }

    componentDidMount() {
        document.addEventListener('selectionchange', this.onSelectionChange, false);
    }
    componentWillUnmount() {
        document.removeEventListener('selectionchange', this.onSelectionChange, false);
    }

    onSelectionChange = () => {

        const selection = window.getSelection()
        if (selection && selection.focusNode) {
            const selectedElement = selection.focusNode as HTMLElement

            // Get the textbox element and check if selected item is in text
            const parent = Util.getParentByClass(selectedElement, "ws-designer-textbox")
            if (parent) {

                // Default font size
                let lineHeight = "1.5"

                // Get the font tag "above" the current node, stop at textbox which is the container
                const line = Util.getParentByStyle(selectedElement, "line-height", "", parent.id, [])

                // If found, get the font size and set it as current value
                if (line) {
                    lineHeight = line.style.lineHeight
                }

                this.setState({value: lineHeight})
            }
        }
    }

    onChangeValue = (value: string) => {
        this.setState({value: value})

        this.props.onActionButton(this.props.action, {lineHeight: value})
    }

    render() {
        return <li
            key={"ws-designer-toolbar-line-height"}
            id={"line-height"}>

            <DropDown id="line-height"
                      width={70}
                      readonly={this.props.readonly}
                      required={false}
                      autocomplete={false}
                      type={DropDownType.NUMBER}
                      value={this.state.value}
                      minValue={1}
                      maxValue={2}
                      stepSize={0.25}
                      items={[
                          { value: "1", name: "1" },
                          { value: "1.25", name: "1,25" },
                          { value: "1.5", name: "1,5" },
                          { value: "1.75", name: "1,75" },
                          { value: "2", name: "2" }
                      ]}
                      onChangeValue={this.onChangeValue} />

        </li>
    }
}
