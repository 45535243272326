import LessonSubject from "./LessonSubject";
import RuleCategory from "./RuleCategory";
import SchoolLevel from "./SchoolLevel";
import RuleType from "./RuleType";
import RulePillar from "./RulePillar";
import {WorksheetItemType} from "./WorksheetItemType";

export class RuleFilter {
    search?: string
    lessonSubject?: LessonSubject
    ruleCategory?: RuleCategory
    schoolLevel?: SchoolLevel
    ruleType?: RuleType
    rulePillar?: RulePillar
    worksheetItemType?: WorksheetItemType
}
