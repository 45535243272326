export enum WDElementMode {
    NONE,
    MOVE,
    RESIZE,

    EDIT,

    ADD_TEXTBOX,
    ADD_BALLOON,
    ADD_TABLE
}
