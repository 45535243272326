import React from "react";
import {MainContext} from "./MainContext";
import {ImagePath} from "../Framework/CategoryImage";
import Const from "../Framework/Const";
import translations from "../Framework/translations.json";

interface IProps {
    adminArea: boolean
    app?: string
}

export class AppLogo extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        return <div className="app-header-group-left">
            {!this.props.adminArea &&
                <img className="app-header-logo"
                     src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "00_Logo_4c.svg"}
                     alt={Const.ApplicationName}
                     draggable={"false"}
                     onContextMenu={(e) => e.preventDefault()}
                />
            }
            {this.props.adminArea &&
                <img className="app-header-logo"
                     src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "00_Logo_sw.svg"}
                     alt={Const.ApplicationName}
                     draggable={"false"}
                     onContextMenu={(e) => e.preventDefault()}
                />
            }
            {this.props.app &&
                <div className="app-header-group-left">
                    <div className={"app-header-title"}>{this.props.app}</div>
                </div>
            }
            {this.props.adminArea &&
                <div className="app-header-admin-text">{this.context.translate(translations.text.maintenance)}</div>
            }
        </div>;
    }
}
