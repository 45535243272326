import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButton} from "../WDToolbarButton";
import {ImagePath} from "../../../../Framework/CategoryImage";
import translations from "../../../../Framework/translations.json";
import {
    CorrectionMarginPosition,
    WDWritingLineatureCorrectionMargin
} from "../../../Elements/Lineature/WritingLineature/WDWritingLineatureCorrectionMargin";
import {WDToolbarButtonGraphics} from "../General/WDToolbarButtonGraphics";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";
import {GraphicOptionSettings} from "../../General/GraphicOptionSettings";

/**
 * Button (Yes/No) in Toolbar with submenu
 * Effects selected element(s)
 * Yes: correcting margin should be shown at specific position
 * No:  correcting margin should be hidden
 */

interface IProps {
    id: number
    correctionMarginData: WDWritingLineatureCorrectionMargin
    options: GraphicOptionSettings
    clickable: boolean
    onToggleButton: (id: number, showCorrectionMargin?: boolean) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    buttonIcon: string
}

export class WDToolbarButtonCorrectionMargin extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            buttonIcon: this.changeButtonIcon(this.props.correctionMarginData.position)
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(this.props.correctionMarginData !== prevProps.correctionMarginData) {
            this.setState({
                buttonIcon: this.changeButtonIcon(this.props.correctionMarginData.position)})
        }
    }

    changeButtonIcon = (position: CorrectionMarginPosition) => {
        switch (position) {
            case CorrectionMarginPosition.left:
                return  "insert_margin_l.svg"

            default:
                return "insert_margin_r.svg"
        }
    }

    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    onChangePosition = (position: string) => {
        let newData: WDWritingLineatureCorrectionMargin = this.props.correctionMarginData

        if (position.includes("LEFT")) {
            newData = new WDWritingLineatureCorrectionMargin(
                true,
                CorrectionMarginPosition.left,
                "91px")
        }
        else if (position.includes("RIGHT")) {
            newData = new WDWritingLineatureCorrectionMargin(
                true,
                CorrectionMarginPosition.right,
                "91px")
        }

        let valuePressed: any = {"correctionMargin": newData}
        this.setState({buttonIcon: this.changeButtonIcon(newData.position)})
        this.props.onActionButton(WDToolbarAction.CORRECTION_MARGIN, valuePressed)

        // close submenu
        this.props.onToggleButton(-1)
    }

    onToggleInsertMargin = () => {
        let marginData = this.props.correctionMarginData
        marginData.showCorrectionMargin = !this.props.correctionMarginData.showCorrectionMargin
        let valuePressed: any = {"correctionMargin": marginData}

        this.props.onActionButton(WDToolbarAction.CORRECTION_MARGIN, valuePressed)
    }

    render() {
        return <div
            key={"ws-designer-toolbar-button-correction-margin"}
            className={"ws-designer-toolbar-item"}
            id={"correctionMargin"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + this.state.buttonIcon}
                             iconClassSelected={"ws-designer-toolbar-button-img"}
                             iconClassUnselected={"ws-designer-toolbar-button-img"}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.insert_margin),
                                 this.context.translate(translations.tooltip.insert_margin))}
                             onToggleButton={this.onToggleInsertMargin}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.correctionMarginData.showCorrectionMargin} />

            <WDToolbarButtonGraphics
                id={this.props.id}
                pressed={this.props.options.open}
                clickable={this.props.clickable}
                tooltip={new TooltipText(
                    this.context.translate(translations.toolbar.insert_margin),
                    this.context.translate(translations.tooltip.insert_margin))}
                imageCategory={[this.props.options.imageCategory]}
                imagePath={this.props.options.imagePath}
                imageWidth={this.props.options.itemWidth}
                submenuWidth={this.props.options.width}
                submenuHeight={this.props.options.height}
                iconClassSelected={"ws-designer-toolbar-button-options"}
                iconClassUnselected={"ws-designer-toolbar-button-options"}
                onClickItem={this.onChangePosition}
                onToggleButton={this.onToggleList}/>
        </div>
    }
}
