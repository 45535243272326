import Entity from "./Entity";

export enum AnnouncementType {
    maintenance = "maintenance",
    problem = "problem",
    info = "info"
}

export default class Announcement extends Entity {
    active: boolean
    type: AnnouncementType
    from?: Date
    to?: Date
    description: string

    constructor(id: number, name: string, active: boolean, type: AnnouncementType, description: string, from?: Date, to?: Date) {
        super(name, id);

        this.active = active
        this.type = type
        this.from = from
        this.to = to
        this.description = description
    }
}
