import {ICacheObject} from "./ICacheObject";
import {WorksheetItemTypeEnum} from "../../_model/WorksheetItemType";

export class ImageCacheObject implements ICacheObject {
    id: number
    objectType: WorksheetItemTypeEnum
    data: string

    constructor(id: number, data: string) {
        this.id = id
        this.objectType = WorksheetItemTypeEnum.IMAGE
        this.data = data
    }
}