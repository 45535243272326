import React, {CSSProperties} from "react";
import {ImagePath} from "../../Framework/CategoryImage";

export class ButtonInfo {
    id: string
    className: string
    type: string
    showLoading: boolean
    readonly: boolean
    value: string
    onClick?: () => void
    style?: {}

    constructor(
        id: string,
        className: string,
        type: string,
        showLoading: boolean,
        readonly: boolean,
        value: string,
        onClick?: () => void,
        style?: {}
    ) {
        this.id = id
        this.className = className
        this.type = type
        this.showLoading = showLoading
        this.readonly = readonly
        this.value = value
        this.onClick = onClick
        this.style = style
    }
}

interface IProps {
    buttons: ButtonInfo[]
    buttonGroupStyle?: CSSProperties
    containerStyle?: CSSProperties
    loading: boolean
}

interface IState {
}

export class ButtonList extends React.Component<IProps, IState> {

    render() {
        return <div className={"modal-dialog-button-container"} style={this.props.containerStyle}>
                <div className="modal-dialog-buttons" style={this.props.buttonGroupStyle}>
                    {this.props.buttons && this.props.buttons.map(button => {
                        if (button.type === "submit") {
                            if (this.props.loading && button.showLoading) {
                                return <div key={button.id} className={button.className} style={button.style}>
                                    <img src={process.env.PUBLIC_URL + ImagePath.getNotificationUrl() + "loading.gif"}
                                         alt="loading"
                                         draggable={"false"}
                                         onContextMenu={(e) => e.preventDefault() }
                                    />
                                </div>
                            }
                            else {
                                return <button id={button.id} key={button.id} disabled={button.readonly} className={button.className} type={"submit"}
                                               style={button.style} onClick={button.readonly ? () => {} : (e) => e.stopPropagation()}>
                                    {button.value}
                                </button>
                            }
                        } else if(button.type === "button") {
                            if (this.props.loading && button.showLoading) {
                                return <div key={button.id} className={button.className} style={button.style}>
                                    <img src={process.env.PUBLIC_URL + ImagePath.getNotificationUrl() + "loading.gif"}
                                         alt="loading"
                                         draggable={"false"}
                                         onContextMenu={(e) => e.preventDefault() }
                                    />
                                </div>
                            }
                            else {
                                return <button id={button.id} key={button.id} disabled={button.readonly}
                                               className={button.className} type={"button"}
                                               onClick={button.readonly ? () => {
                                               } : button.onClick} style={button.style}>
                                    {button.value}
                                </button>
                            }
                        } else {
                            return <button id={button.id} key={button.id} disabled={button.readonly} className={button.className} type={"reset"}
                                           onClick={button.readonly ? () => {} : button.onClick} style={button.style}>
                                {button.value}
                            </button>
                        }
                    })}
                </div>
            </div>
    }
}
