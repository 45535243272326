import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Clickable without pressed version
 * After click - row should be inserted
 */

export enum WDColumnPosition {
    NONE,
    LEFT,
    RIGHT
}

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    position: WDColumnPosition
    onActionButton: (action: WDToolbarAction) => void
    onToggleButton: (id: number) => void
}

export class WDToolbarButtonInsertColumn extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    insert = () => {
        switch (this.props.position) {
            case WDColumnPosition.NONE:
                this.props.onActionButton(WDToolbarAction.ADD_COLUMN)
                break
            case WDColumnPosition.LEFT:
                this.props.onActionButton(WDToolbarAction.ADD_COLUMN_LEFT)
                break
            case WDColumnPosition.RIGHT:
                this.props.onActionButton(WDToolbarAction.ADD_COLUMN_RIGHT)
                break
        }
        this.props.onToggleButton(this.props.id)
    }

    getImageUrl = () => {
        switch (this.props.position) {
            case WDColumnPosition.NONE:
                return "insert_column.svg"
            case WDColumnPosition.LEFT:
                return "insert_column_left.svg"
            case WDColumnPosition.RIGHT:
                return "insert_column_right.svg"
        }
    }
    getTooltip = () => {
        switch (this.props.position) {
            case WDColumnPosition.NONE:
                return new TooltipText(
                    this.context.translate(translations.toolbar.insert_col),
                    this.context.translate(translations.tooltip.insert_col)
                )
            case WDColumnPosition.LEFT:
                return new TooltipText(
                    this.context.translate(translations.toolbar.insert_col_left),
                    this.context.translate(translations.tooltip.insert_col_left)
                )
            case WDColumnPosition.RIGHT:
                return new TooltipText(
                    this.context.translate(translations.toolbar.insert_col_right),
                    this.context.translate(translations.tooltip.insert_col_right)
                )
        }
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-insert-column"}
            id={"insertColumn"}>

            <WDToolbarButton id={this.props.id}
                                     enabled={this.props.clickable}
                                     icon={ImagePath.getButtonUrl() + this.getImageUrl()}
                                     tooltip={this.getTooltip()}
                                     arrow={false}
                                     onToggleButton={this.insert}
                                     pressed={this.props.pressed} />
        </li>
    }
}
