import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "../../Toolbar/Toolbar/WDToolbarElement";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbar, WDToolbarOrientation} from "../../Toolbar/Toolbar/WDToolbar";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarSectionTransformation} from "../../Toolbar/Section/WDToolbarSectionTransformation";
import {ElementLayout} from "../WDElementContainer";
import Converter from "../../../Framework/Converter";
import _ from "lodash";

export interface WDGroupToolbarProps extends WDToolbarElementProps { }
export interface WDGroupToolbarState extends WDToolbarElementState { }

export class WDGroupToolbar extends WDToolbarElement<WDGroupToolbarProps, WDGroupToolbarState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: WDGroupToolbarProps, state: WDGroupToolbarState) {
        super(props, state);

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: this.mapEditModeToToolbarType(),
            refToolbar: React.createRef()
        }
    }

    componentDidUpdate(prevProps: WDGroupToolbarProps) {
        this.updateToolbarValues()
    }
    updateToolbarValues = () => {
        this.setState({ activeToolbarType: this.mapEditModeToToolbarType() })
    }

    mapEditModeToToolbarType = () => {
        return WDToolbarTypeEnum.TRANSFORMATION
    }
    shouldComponentUpdate(nextProps: Readonly<WDGroupToolbarProps>, nextState: Readonly<WDGroupToolbarState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    render () {

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(false, true, false, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={false}
                    isEdited={this.props.editMode}
                    showColorOptions={true}
                    initialLinkState={true}
                    canChangeLinkState={true}
                    onChangeActive={() => this.setState({activeId: -1})}
                    onChangeBorder={this.props.onChangeBorder}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onChangeGroupingStatus={this.props.onChangeGroupingStatus}
                    onChangeLockingStatus={this.props.onChangeLockingStatus}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onElementDeleted={this.props.onElementDeleted}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                    grouped={true}
                />
            </ul>
            }
        </WDToolbar>
    }
}
