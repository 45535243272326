import React from "react";
import translations from "../../../Framework/translations.json";
import {MainContext} from "../../../_base/MainContext";
import {
    GetImageCollections,
    GetImageDetails,
    GetImageGalleryFiltered,
    GetImagesByFamily
} from "../../../_endpoint/ImageEndpoint";
import Image, {
    ImageAI,
    ImageAlignment,
    ImageBackground,
    ImageColor,
    ImageFilter, ImageFilterAdmin,
    ImageLicense,
    ImageProperty
} from "../../../_model/Image";
import Conf from "../../../Framework/Conf";
import SelectBox from "../../../Components/Controls/SelectBox";
import {GetOriginator} from "../../../_endpoint/MetadataEndpoint";
import Originator from "../../../_model/Originator";
import ImageCollection from "../../../_model/ImageCollection";
import {
    EntityArrayToEnumValues,
    EnumToEntityArray,
    EnumValuesToEntityArray,
    EnumValueToEntity
} from "../../../Framework/Enums";
import Entity from "../../../_model/Entity";
import CheckBoxList from "../../../Components/Controls/CheckBoxList";
import {ImagePath} from "../../../Framework/CategoryImage";
import {ImageGroupListItem} from "../../../Admin/Images/ImageGroupListItem";
import {SidebarFilterBase} from "../SidebarFilterBase";
import {
    SidebarContentBase,
    SidebarContentBaseProps,
    SidebarContentBaseState,
    SidebarContentDragElementType
} from "../SidebarContentBase";
import {SidebarAdvancedFilterArrow} from "../SidebarAdvancedFilterArrow";
import {WSContextType} from "../../Elements/WSContext";
import {ImageListFilter} from "../../../Admin/Images/ImageListFilter";
import {SidebarFilterButtonData} from "../SidebarFilterButtonData";
import {ImageGallery} from "../../../Components/Images/ImageGallery";
import {WDUtils} from "../../Utils/WDUtils";
import ListSortSetting from "../../../Components/List/ListSortSetting";
import {TooltipPosition} from "../../../Components/Tooltips";

interface IProps extends SidebarContentBaseProps {
    worksheetType: WSContextType
}
interface IState extends SidebarContentBaseState {
    filter: ImageListFilter
    filterHistory: ImageListFilter[]

    items?: Image[]
    originator?: Originator[]
    collection?: ImageCollection[]
}

export class SidebarImages extends SidebarContentBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            filter: (this.props.worksheetType === WSContextType.writing_course_child || this.props.worksheetType === WSContextType.writing_course_main)
                    ? ImageListFilter.initWritingCourseImageFilter()
                    : ImageListFilter.initFilter(),
            filterHistory: [],
            filterHistoryIndex: 0,
            page: 0,
            lastItem: false
        }
    }

    componentDidMount() {
        this.fetchImages(true)
        this.fetchCollections()
        this.fetchOriginator()
    }
    applyFilterCollection = () => {
        if (this.state.filter.activeElement && (this.state.filter.activeElement as Image).collection) {
            let filter = ImageListFilter.initFilter()
            filter.activeElement = undefined
            filter.collectionId = (this.state.filter.activeElement as Image).collection!.id!
            this.setState({filter: filter, lastItem: false}, () => this.fetchData(true))
        }
    }
    applyFilterOriginator = () => {
        if (this.state.filter.activeElement && (this.state.filter.activeElement as Image).originator) {
            let filter = ImageListFilter.initFilter()
            filter.activeElement = undefined
            filter.originatorId = (this.state.filter.activeElement as Image).originator!.id!
            this.setState({filter: filter, lastItem: false}, () => this.fetchData(true))
        }
    }
    deleteFilter = () => {
        this.setState({filter: ImageListFilter.initFilter()}, () => {
            this.fetchData(true)
        })
    }

    onFetchNextPage = (callback?: (page: number, lastPage: boolean) => void) => {
        this.fetchImages(false, true, callback)
    }

    fetchData = (addHistory: boolean, append: boolean = false, callback?: (page: number, lastPage: boolean) => void) => {
        if(!append && this.state.filter.search !== "") {
            this.setState({items: undefined}, () => {
                this.fetchImages(addHistory, append, callback)
            })
        }
        else {
            this.fetchImages(addHistory, append, callback)
        }
    }
    fetchImages = (addHistory: boolean, append: boolean = false, callback?: (page: number, lastPage: boolean) => void) => {
        const filter = new ImageFilter()

        if (this.state.filter.search.length > 0) {
            filter.search = this.state.filter.search
        }
        if (this.state.filter.alignments !== undefined) {
            filter.alignment = this.state.filter.alignments
        }
        if (this.state.filter.originatorId > 0) {
            filter.originatorId = this.state.filter.originatorId
        }
        if (this.state.filter.collectionId > 0) {
            filter.collectionId = this.state.filter.collectionId
        }
        if (this.state.filter.properties !== undefined) {
            filter.property = this.state.filter.properties
        }
        if (this.state.filter.colors !== undefined) {
            filter.color = this.state.filter.colors
        }
        if (this.state.filter.backgrounds !== undefined && this.state.filter.backgrounds.length === 1) {
            filter.background = (this.state.filter.backgrounds[0] === ImageBackground.withBackground)
        }
        if (this.state.filter.licenses !== undefined && this.state.filter.licenses.length === 1) {
            filter.licenseRequired = (this.state.filter.licenses[0] === ImageLicense.license)
        }
        if (this.state.filter.aiGenerated !== undefined && this.state.filter.aiGenerated.length === 1) {
            filter.aiGenerated = (this.state.filter.aiGenerated[0] === ImageAI.aiGenerated)
        }
        filter.sort = new ListSortSetting("rating", true)
        filter.adminFilter = new ImageFilterAdmin()

        // console.log(filter)

        let page = append ? this.state.page + 1 : 0
        GetImageGalleryFiltered(filter, this.props.worksheetType, page, this.PAGE_SIZE).then(
            (itemData) => {
                if (addHistory) {
                    this.pushHistory(this.state.filter)
                }

                let items = (itemData.content as Image[]).filter(item => item.url !== null)
                if (append && this.state.items) {
                    // Page number would be increased by 1 but if no items are retrieved keep page number
                    if (items.length === 0) {
                        page --
                    }
                    items = this.state.items?.concat(items)
                }
                let lastItemReached = WDUtils.lastItemResultReached(itemData.content.length, this.PAGE_SIZE)

                this.setState({items: items, page: page, lastItem: lastItemReached},
                    () => callback?.(page, lastItemReached))
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }
    fetchImage = (imageId: number) => {
        GetImageDetails(imageId).then(
            (itemData) => {
                let filter = this.state.filter
                filter.activeElement = itemData

                this.pushHistory(filter)

                this.setState({ filter: filter })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }
    fetchOriginator = () => {
        GetOriginator().then(
            (originator) => {
                this.setState({ originator: [new Originator("- " + this.context.translate(translations.text.all) + " -", -1), ...originator] })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }
    fetchCollections = () => {
        GetImageCollections().then(
            (collection) => {
                this.setState({ collection: [new ImageCollection("- " + this.context.translate(translations.text.all) + " -", -1), ...collection] })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    getImageDetailProperties = (): string => {
        let properties: string[] = []

        if (this.state.filter.activeElement) {

            if ((this.state.filter.activeElement as Image).property) {
                let entity = EnumValueToEntity(ImageProperty, (this.state.filter.activeElement as Image).property!, translations.enum.image_property, this.context.translate)
                properties.push(entity.name)
            }

            if ((this.state.filter.activeElement as Image).color) {
                let entity = EnumValueToEntity(ImageColor, (this.state.filter.activeElement as Image).color!, translations.enum.image_color, this.context.translate)
                properties.push(entity.name)
            }

            if ((this.state.filter.activeElement as Image).alignment) {
                let entity = EnumValueToEntity(ImageAlignment, (this.state.filter.activeElement as Image).alignment!, translations.enum.image_alignment, this.context.translate)
                properties.push(entity.name)
            }

            properties.push(this.context.translate(
                (this.state.filter.activeElement as Image).background ? translations.enum.image_background.with_background : translations.enum.image_background.without_background
            ))

            properties.push(this.context.translate(
                (this.state.filter.activeElement as Image).licenseRequired ? translations.enum.image_license.license : translations.enum.image_license.free
            ))

            if((this.state.filter.activeElement as Image).aiGenerated) {
                properties.push(this.context.translate(translations.fields.image.ai_generated))
            }
        }
        return properties.join(", ")
    }

    onChangeOriginator = (value: number) => {
        let filter = this.state.filter
        filter.filterChanged = true
        filter.originatorId = value
        this.setState({ filter: filter, lastItem: false }, () => this.fetchData(true))
    }
    onChangeCollection = (value: number) => {
        let filter = this.state.filter
        filter.filterChanged = true
        filter.collectionId = value
        this.setState({ filter: filter, lastItem: false }, () => this.fetchData(true))
    }
    onChangeProperty = (values: Entity[]) => {
        let filter = this.state.filter
        filter.filterChanged = true
        filter.properties = EntityArrayToEnumValues(values, ImageProperty)
        this.setState({ filter: filter, lastItem: false },() => this.fetchData(true))
    }
    onChangeColor = (values: Entity[]) => {
        let filter = this.state.filter
        filter.filterChanged = true
        filter.colors = EntityArrayToEnumValues(values, ImageColor)
        this.setState({ filter: filter, lastItem: false }, () => this.fetchData(true))
    }
    onChangeBackground = (values: Entity[]) => {
        let filter = this.state.filter
        filter.filterChanged = true
        filter.backgrounds = EntityArrayToEnumValues(values, ImageBackground)
        this.setState({ filter: filter, lastItem: false },() => this.fetchData(true))
    }
    onChangeLicense = (values: Entity[]) => {
        let filter = this.state.filter
        filter.filterChanged = true
        filter.licenses = EntityArrayToEnumValues(values, ImageLicense)
        this.setState({ filter: filter, lastItem: false },() => this.fetchData(true))
    }
    onChangeAIGenerated = (values: Entity[]) => {
        let filter = this.state.filter
        filter.filterChanged = true
        filter.aiGenerated = EntityArrayToEnumValues(values, ImageAI)
        this.setState({ filter: filter, lastItem: false },() => this.fetchData(true))
    }

    onDragImage = (event: React.DragEvent, image: Image) => {
        this.onDragStart(event, SidebarContentDragElementType.IMAGE, "sidebar-image-" + image.id!.toString(), image)
    }
    onClickImage = (image: Image) => {
        this.fetchImage(image.id!)
    }
    onClickAlignment = (alignment: ImageAlignment) => {

        if (this.state.filter.alignments) {
            let elements = [...this.state.filter.alignments]
            const index = this.state.filter.alignments.indexOf(alignment, 0);

            if (index !== undefined && index > -1) {
                elements.splice(index, 1);
            }
            else {
                elements = [alignment, ...elements]
            }

            let filter = this.state.filter
            filter.alignments = elements
            this.setState({filter: filter}, () => this.fetchData(true))
        }
    }

    render() {
        return <div className={"ws-designer-sidebar-container"}>
            {/* Search and navigation */}
            <SidebarFilterBase
                onSearch={this.onSearch}
                searchValue={this.state.filter.search}
                buttons={[
                    new SidebarFilterButtonData(1,
                        this.context.translate(translations.command.backward),
                        this.context.translate(translations.tooltip.backward),
                        "navigation_backward.svg",
                        this.state.filterHistoryIndex === 0,
                        this.onClickBackwards,
                        TooltipPosition.belowRight, -138, -5),
                    new SidebarFilterButtonData(2,
                        this.context.translate(translations.command.forward),
                        this.context.translate(translations.tooltip.forward),
                        "navigation_forward.svg",
                        this.state.filterHistoryIndex === this.state.filterHistory.length -1,
                        this.onClickForwards,
                        TooltipPosition.belowRight, -138, -5),
                    new SidebarFilterButtonData(3,
                        this.context.translate(translations.command.delete_filter),
                        this.context.translate(translations.tooltip.delete_filter),
                        "delete_filters.svg",
                        !this.state.filter.filterChanged,
                        this.deleteFilter,
                        TooltipPosition.belowRight, -138, -5),
                    new SidebarFilterButtonData(4,
                        this.context.translate(translations.command.report_image),
                        this.context.translate(translations.tooltip.report_image),
                        "mail.svg",
                        this.state.filter.activeElement === undefined,
                        () => this.onClickReport(this.state.filter.activeElement!.id!, this.context.translate(translations.text.image)),
                        TooltipPosition.belowRight, -138, -5)

                ]}
            />

            <div className={this.state.filter.activeElement === undefined ? "ws-designer-sidebar-filter-show" : "ws-designer-sidebar-filter-hide"}>

                {/* Advanced filter settings */}
                {this.state.advancedSettingsVisible !== undefined &&
                <div className={this.state.advancedSettingsVisible ?
                    "ws-designer-sidebar-filter-advanced-show ws-designer-sidebar-image-filter-animation-in" :
                    "ws-designer-sidebar-filter-advanced-hide ws-designer-sidebar-image-filter-animation-out"}>

                    <div className={"ws-designer-sidebar-column"}>
                        <div className={"ws-designer-sidebar-row"}>
                            {/* Filter options */}
                            <div className={"ws-designer-sidebar-column"}>
                                <SelectBox id={"collections"} width={190} label={this.context.translate(translations.fields.image.collection)}
                                           value={this.state.filter.collectionId} data={this.state.collection || []}
                                           required={false} readonly={false} onChange={this.onChangeCollection} positioningRow={false}/>
                            </div>
                            <div className={"ws-designer-sidebar-column"}>
                                <SelectBox id={"originator"} width={190} label={this.context.translate(translations.fields.image.originator)}
                                           value={this.state.filter.originatorId} data={this.state.originator || []}
                                           required={false} readonly={false} onChange={this.onChangeOriginator} positioningRow={false}/>
                            </div>
                        </div>
                        <div className={"ws-designer-sidebar-row"}>

                            <div className={"ws-designer-sidebar-column"}>
                                <CheckBoxList id={"property"}
                                              label={this.context.translate(translations.fields.image.property)}
                                              data={EnumToEntityArray(ImageProperty, translations.enum.image_property, this.context.translate)}
                                              selectedData={EnumValuesToEntityArray(ImageProperty, this.state.filter.properties || [], translations.enum.image_property, this.context.translate)}
                                              onChange={this.onChangeProperty}
                                              required={false} readonly={false} />

                                <CheckBoxList id={"color"}
                                              data={EnumToEntityArray(ImageColor, translations.enum.image_color, this.context.translate)}
                                              selectedData={EnumValuesToEntityArray(ImageColor, this.state.filter.colors || [], translations.enum.image_color, this.context.translate)}
                                              onChange={this.onChangeColor}
                                              required={false} readonly={false} />

                                <CheckBoxList id={"background"}
                                              data={EnumToEntityArray(ImageBackground, translations.enum.image_background, this.context.translate)}
                                              selectedData={EnumValuesToEntityArray(ImageBackground, this.state.filter.backgrounds || [], translations.enum.image_background, this.context.translate)}
                                              onChange={this.onChangeBackground}
                                              required={false} readonly={false} />
                            </div>
                            <div className={"ws-designer-sidebar-column"}>

                                <div className={"ws-designer-sidebar-images-filter-alignment form-group"} style={{marginBottom: "14px"}}>
                                    <label className={"bold-label"}>{this.context.translate(translations.fields.image.alignment)}</label>
                                    <div className={"ws-designer-sidebar-images-filter-alignment-frame"}>
                                        <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "image_alignment_landscape.svg"}
                                             className={this.state.filter.alignments?.includes(ImageAlignment.landscape) ? "ws-designer-sidebar-images-filter-alignment-active" : ""}
                                             draggable={"false"}
                                             onClick={() => this.onClickAlignment(ImageAlignment.landscape)}
                                             onContextMenu={(e) => e.preventDefault() }
                                             alt={this.context.translate(translations.enum.image_alignment.landscape)} />
                                    </div>
                                    <div className={"ws-designer-sidebar-images-filter-alignment-frame"}>
                                        <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "image_alignment_portrait.svg"}
                                             className={this.state.filter.alignments?.includes(ImageAlignment.portrait) ? "ws-designer-sidebar-images-filter-alignment-active" : ""}
                                             draggable={"false"}
                                             onClick={() => this.onClickAlignment(ImageAlignment.portrait)}
                                             onContextMenu={(e) => e.preventDefault() }
                                             alt={this.context.translate(translations.enum.image_alignment.portrait)} />
                                    </div>
                                    <div className={"ws-designer-sidebar-images-filter-alignment-frame"}>
                                        <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "image_alignment_quadratic.svg"}
                                             className={this.state.filter.alignments?.includes(ImageAlignment.quadratic) ? "ws-designer-sidebar-images-filter-alignment-active" : ""}
                                             draggable={"false"}
                                             onClick={() => this.onClickAlignment(ImageAlignment.quadratic)}
                                             onContextMenu={(e) => e.preventDefault() }
                                             alt={this.context.translate(translations.enum.image_alignment.quadratic)} />
                                    </div>
                                    <div className={"ws-designer-sidebar-images-filter-alignment-frame"}>
                                        <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "image_alignment_panorama.svg"}
                                             className={this.state.filter.alignments?.includes(ImageAlignment.panorama) ? "ws-designer-sidebar-images-filter-alignment-active" : ""}
                                             draggable={"false"}
                                             onClick={() => this.onClickAlignment(ImageAlignment.panorama)}
                                             onContextMenu={(e) => e.preventDefault() }
                                             style={{height: 45}}
                                             alt={this.context.translate(translations.enum.image_alignment.panorama)} />
                                    </div>
                                </div>

                                <CheckBoxList id={"license"}
                                              label={this.context.translate(translations.fields.image.license)}
                                              data={EnumToEntityArray(ImageLicense, translations.enum.image_license, this.context.translate)}
                                              selectedData={EnumValuesToEntityArray(ImageLicense, this.state.filter.licenses || [], translations.enum.image_license, this.context.translate)}
                                              onChange={this.onChangeLicense}
                                              required={false} readonly={false} />

                                <CheckBoxList id={"chkAIGenerated"}
                                              data={EnumToEntityArray(ImageAI, translations.enum.image_ai, this.context.translate)}
                                              selectedData={EnumValuesToEntityArray(ImageAI, this.state.filter.aiGenerated || [], translations.enum.image_ai, this.context.translate)}
                                              required={false} readonly={false}
                                              onChange={this.onChangeAIGenerated}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                }

                <div className={"ws-designer-sidebar-filter-more"}>
                    <SidebarAdvancedFilterArrow
                        advancedSettingsVisible={this.state.advancedSettingsVisible!}
                        textMore={this.context.translate(translations.text.more_settings)}
                        textLess={this.context.translate(translations.text.less_settings)}
                        onToggle={this.onToggleSettings}
                        className={"ws-designer-sidebar-filter-more-item-right"}
                    />
                </div>
            </div>

            {/* Image details / Image list */}
            <div className={"ws-designer-sidebar-content"}>

                {this.state.filter.activeElement !== undefined ?
                    <div className={"ws-designer-sidebar-images-details"}>

                        <div className={"ws-designer-sidebar-images-details-image"}>
                            <img id={"sidebar-image-details-" + this.state.filter.activeElement.id!.toString()} src={Conf.IMAGE_URL() + (this.state.filter.activeElement as Image).url}
                                 onDragStart={event => this.onDragStart(event,
                                     SidebarContentDragElementType.IMAGE,
                                     "sidebar-image-details-" + this.state.filter.activeElement!.id!.toString(),
                                     (this.state.filter.activeElement as Image)!)}
                                 onContextMenu={(e) => e.preventDefault() }
                                 alt={this.state.filter.activeElement.name}/>
                        </div>

                        <div className={"ws-designer-sidebar-column"} style={{marginLeft: "10px"}}>
                            <label className={"bold-label"}>{this.context.translate(translations.fields.image.image)}</label>
                            <div>
                                <span>{this.state.filter.activeElement.name}</span>
                            </div>
                        </div>

                        <div className={"ws-designer-sidebar-column"} style={{marginLeft: "10px"}}>
                            <label className={"bold-label"}>{this.context.translate(translations.fields.image.collection)}</label>
                            <div>
                                <span className={"render-link"} onClick={() => this.applyFilterCollection()}>
                                    {(this.state.filter.activeElement as Image).collection !== undefined ? (this.state.filter.activeElement as Image).collection?.name : "-"}
                                </span>
                            </div>
                        </div>

                        <div className={"ws-designer-sidebar-column"} style={{marginLeft: "10px", marginTop: "10px"}}>
                            <label className={"bold-label"}>{this.context.translate(translations.fields.image.originator)}</label>
                            <div>
                                <span className={"render-link"} onClick={() => this.applyFilterOriginator()}>
                                    {(this.state.filter.activeElement as Image).originator !== undefined ? (this.state.filter.activeElement as Image).originator?.name : "-"}
                                </span>
                            </div>
                        </div>

                        <div className={"ws-designer-sidebar-column"} style={{marginLeft: "10px", marginTop: "10px", width: "100%"}}>
                            <label className={"bold-label"}>{this.context.translate(translations.fields.image.property)}</label>
                            <div>{this.getImageDetailProperties()}</div>
                        </div>

                        <div className={"ws-designer-sidebar-column"} style={{marginLeft: "10px", marginTop: "10px", width: "100%"}}>
                            <label className={"bold-label"}>{this.context.translate(translations.fields.image.family) + " \"" + (this.state.filter.activeElement as Image).family!.name + "\""}</label>
                            {(this.state.filter.activeElement as Image).family &&
                                <ImageGroupListItem id={(this.state.filter.activeElement as Image).family!.id!}
                                                    name={(this.state.filter.activeElement as Image).family!.name}
                                                    currentImageId={this.state.filter.activeElement.id!}
                                                    showTitle={false}
                                                    showHover={false}
                                                    showImages={true}
                                                    retrieveImages={GetImagesByFamily}
                                                    onClickItem={(image) => this.onClickImage(image)}
                                                    onDragStart={this.onDragStart}
                                />
                            }
                        </div>

                    </div>

                    :

                    <ImageGallery
                        items={this.state.items}
                        itemHeight={75}
                        onFetchNextPage={this.onFetchNextPage}
                        onClickImage={this.onClickImage}
                        onDragImage={this.onDragImage}
                    />
                }
            </div>
        </div>
    }
}

