import React from "react";
import {ImageToggleButton} from "../../../Components/Controls/ImageToggleButton";
import {TooltipPosition, TooltipText} from "../../../Components/Tooltips";

/**
 * @param text - if button is represented by text add string
 * @param arrow - defines if button has an arrow rendered within the button
 * @param tooltip - shown tooltip text when hovering over the button
 * @param tooltipDisabled - shown tooltip text when hovering over the button and the button is currently disabled (explanation for disabled)
 * @param tooltipPosition - adds possibility to change position of the tooltip (default = above)
 * @param cssClass - styling of the button
 * @param color - color in hex if button contains line under image which should be colored (for example text color)
 * @param icon - if button is represented by image add icon path (thumbnail or image is mandatory!)
 * @param iconClassSelected - specific css classes for image if it is selected
 * @param iconClassSelected - specific css classes for image if it is unselected
 * @param thumbnail - if button should show thumbnail add the whole image (thumbnail or image is mandatory!)
 * @param buttonClass - specific css classes for button
 * @param buttonWidth - width of button if it is not default one
 * @param buttonHeight - height of button if it is not default one
 * @param imageWidth - width of image if it is not default one
 * @param imageHeight - height of image if it is not default one
 * @param pressed - if button is activated/pressed currently
 * @param enabled - is button can be clicked
 */
interface IProps extends React.PropsWithChildren {
    id: number
    text?: string
    arrow: boolean
    tooltip?: TooltipText
    tooltipDisabled?: TooltipText
    tooltipPosition?: TooltipPosition
    cssClass?: string
    color?: string

    icon?: string
    iconClassSelected?: string
    iconClassUnselected?: string
    iconClassDisabled?: string
    thumbnail?: string

    buttonClass?: string
    buttonWidth?: number
    buttonHeight?: number
    imageWidth?: number
    imageHeight?: number

    pressed: boolean
    enabled: boolean

    onToggleButton: (id: number) => void
}

export class WDToolbarButton extends React.Component<IProps> {
    onToggle = (status: boolean) => {
        if(this.props.enabled) {
            this.props.onToggleButton(this.props.pressed ? -1 : this.props.id)
        }
    }

    render() {
        let toggleButtonClass = this.props.cssClass || "ws-designer-toolbar-button"
        let imageClassSelected = this.props.iconClassSelected //|| "svg-color-primary"
        let imageClassUnselected = this.props.iconClassUnselected // || "svg-color-primary"

        // check for disabled
        if(!this.props.enabled) {
            toggleButtonClass += " "
            toggleButtonClass += this.props.iconClassDisabled ? this.props.iconClassDisabled : "ws-designer-toolbar-button-disabled"
        }

        // check if selected
        if(this.props.pressed) {
            toggleButtonClass += " ws-designer-toolbar-button-selected"
        }

        // check tooltip position for rendering
        let tooltipPosition = " tooltip-above"
        if(this.props.tooltipPosition !== undefined) {
            tooltipPosition = " tooltip-" + this.props.tooltipPosition
        }

        return <div className={toggleButtonClass + tooltipPosition} >

            <ImageToggleButton
                id={"toolbar-button-" + this.props.id}
                defaultStatus={this.props.pressed}
                buttonClass={"tooltip-control " + (this.props.buttonClass || (this.props.arrow ? "ws-designer-toolbar-button-image-arrow" : "ws-designer-toolbar-button-image"))}
                imageUrlSelected={this.props.icon}
                imageClassSelected={imageClassSelected}
                imageUrlUnselected={this.props.icon}
                imageClassUnselected={imageClassUnselected}
                thumbnail={this.props.thumbnail}
                buttonWidth={this.props.buttonWidth}
                buttonHeight={this.props.buttonHeight}
                imageWidth={this.props.imageWidth}
                imageHeight={this.props.imageHeight}
                text={this.props.text}
                color={this.props.color}
                arrow={this.props.arrow}
                tooltip={this.props.enabled ? this.props.tooltip : this.props.tooltipDisabled}
                tooltipPosition={this.props.tooltipPosition}
                altText={this.props.tooltip?.text || ""}
                toggleHandler={this.onToggle}
            />

            {this.props.pressed && this.props.children}
        </div>
    }
}

