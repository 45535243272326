import Entity from "../../_model/Entity";

export default class NameListItem extends Entity {
    notes?: string
    languages: string
    gender: string
    difficulty: string
    status: string
    modifiedOn: Date
    owner: string
    createdBy: string

    constructor(id: number, name: string, notes: string, owner: string, modifiedOn: Date, languages: string,
                gender: string, difficulty: string, status: string, createdBy: string) {

        super(name, id);

        this.notes = notes
        this.languages = languages
        this.gender = gender
        this.difficulty = difficulty

        this.owner = owner
        this.modifiedOn = modifiedOn
        this.createdBy = createdBy
        this.status = status
    }
}
