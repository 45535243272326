import {Coords} from "./Coords";
// import Image, {ImageAlignment} from "../_model/Image";
import _ from "lodash";
import {Image, ImageAlignment} from "../_model/Image";
import React from "react";

export interface Resource {
    de: string
    en: string
}

export class Selection {
    start: number
    end: number

    constructor(start: number, end: number) {
        this.start = start
        this.end = end
    }
}

export class Util {

    static getObjectDifference(obj1: any, obj2: any) {
        return Object.keys(obj1).reduce((result, key) => {
            if (!obj2.hasOwnProperty(key)) {
                result.push(key);
            } else if (_.isEqual(obj1[key], obj2[key])) {
                const resultKeyIndex = result.indexOf(key);
                result.splice(resultKeyIndex, 1);
            }
            return result;
        }, Object.keys(obj2));
    }

    /**
     * DOM manipulation
     * */
    static getRequiredElementById(id: string): HTMLElement {
        const element = document.getElementById(id)
        if (element === null || element === undefined) {
            throw new Error("Required element " + id + " not found")
        }

        return element
    }

    static getParentByClass(element: HTMLElement, className: string) {
        let el: HTMLElement | null = element.parentElement

        while (el) {
            if (el.className) {
                let classes = el.className.split(" ")
                if (classes.find(i => i === className)) {
                    return el;
                }
            }

            el = el.parentElement
        }

        return el
    }

    static getParentById(element: HTMLElement, id: string) {

        let el: HTMLElement | null = element.parentElement
        while (el && el.id !== id) {
            el = el.parentElement
        }

        return el
    }

    static getParentByTag(element: HTMLElement, tagNames: string, containerId?: string) {
        let el: HTMLElement | null = element.parentElement
        while (el) {

            // Element with tag found
            if (el.tagName && tagNames.toUpperCase().indexOf(el.tagName.toUpperCase()) >= 0) {
                return el
            }

            // Container reached and tag not found -> return
            if (containerId && el.id === containerId) {
                return
            }

            el = el.parentElement
        }

        return
    }

    static getParentByStyle(element: HTMLElement, styleName: string, styleValue: string, containerId: string, excludeList: string[]) {
        let el: HTMLElement | null = element.parentElement
        while (el) {

            // Element with style found
            if (el.style && el.style[styleName]) {
                let style = el.style[styleName].toLowerCase()
                style = style.replaceAll(" ", "")

                // Check exclude list (style values that are ignored)
                if (excludeList.includes(style)) {
                    return
                }

                if (styleValue === "" || style === styleValue.toLowerCase()) {
                    return el
                }

                return
            }

            // Container reached and tag not found -> return
            if (el.id === containerId) {
                return
            }

            el = el.parentElement
        }

        return
    }

    static isChildOfId(element: HTMLElement, id: string) {
        return (Util.getParentById(element, id) !== null)
    }

    static isChildOfClass(element: HTMLElement, className: string) {
        return (Util.getParentByClass(element, className) !== null)
    }

    static setStyleOnElement(element: HTMLElement, styleName: string, styleValue: string) {
        element.style[styleName] = styleValue
    }

    static removeStyleFromElement(element: HTMLElement, styleName: string, excludeList: string[]) {

        // Check exclude list (style values that are ignored)
        if (excludeList.find(item => element.style[styleName].indexOf(item.toLowerCase()) >= 0)) {
            return
        }

        element.style.removeProperty(styleName)

        // If there are no style attributes left, unwrap the node (remove and keep children)
        this.removeEmptyStyle(element)
    }

    static removeStyleFromElementsRecursive(element: HTMLElement, styleName: string, removeFromCurrent: boolean, excludeList: string[]) {
        for (let i = 0; i < element.childNodes.length; i++) {
            if (element.childNodes[i].nodeType !== Node.TEXT_NODE) {
                const childElement = element.childNodes[i] as HTMLElement

                // Remove style from element and all child elements
                this.removeStyleFromElementsRecursive(childElement, styleName, true, excludeList)
            }
        }

        if (removeFromCurrent) {
            Util.removeStyleFromElement(element, styleName, excludeList)
        }
    }

    static removeEmptyStyle(element: HTMLElement) {
        // Don't unwrap specific tags as this would change the html structure!
        if (element.style.length === 0) {
            if ("li|ul|ol|br|p|div|input".indexOf(element.tagName.toLowerCase()) < 0) {
                Util.unwrapNode(element)
            } else {
                element.removeAttribute("style")
            }
        }
    }

    /**
     * Remove the given HTML element and keep all children. The children will be attached
     * to the parent of the given element. If there is no parent the method will do nothing.
     * @param element HTML element that will be removed from the DOM
     */
    static unwrapNode(element: HTMLElement) {
        const parent = element.parentNode;
        if (parent) {
            while (element.firstChild) {
                parent.insertBefore(element.firstChild, element);
            }
            parent.removeChild(element);
        }
    }

    static replaceNode(oldElement: HTMLElement, newElement: HTMLElement) {
        if (oldElement.parentNode) {
            newElement.innerHTML = oldElement.innerHTML;
            oldElement.parentNode.replaceChild(newElement, oldElement);
        }
    }

    /**
     * Removes all HTML-Tags but leaves line breaks
     */
    static removeHtmlFormatting(element: HTMLElement) {
        element.removeAttribute("style")
        element.removeAttribute("class")

        for (let i = 0; i < element.childNodes.length; i++) {
            if (element.childNodes[i].nodeType !== Node.TEXT_NODE) {
                const childElement = element.childNodes[i] as HTMLElement

                this.removeHtmlFormatting(childElement)

                if ((element.tagName.toUpperCase() !== "DIV" && element.tagName.toUpperCase() !== "BR" && element.tagName.toUpperCase() !== "P")
                    || element.className === "ws-designer-word") {
                    this.unwrapNode(element)
                }
            }
        }

        if ((element.tagName.toUpperCase() !== "DIV" && element.tagName.toUpperCase() !== "BR" && element.tagName.toUpperCase() !== "P")
            || element.className === "ws-designer-word") {
            this.unwrapNode(element)
        }
    }

    /**
     * Removes specific HTML-Tags
     * @param element the element where the tags should be removed
     * @param tagName name of tag without < and >
     */
    static removeHtmlTag(element: HTMLElement, tagName: string) {
        let tagsToRemove = element.getElementsByTagName(tagName)

        // remove all <tagName> elements
        while (tagsToRemove !== null && tagsToRemove[0]) {
            tagsToRemove[0].remove()
        }
    }

    /**
     * Clears text element which was pasted from word
     * @param element the element which should be cleared
     */
    static clearWordHtml(element: HTMLElement) {
        Util.removeHtmlTag(element, "o:p")
        Util.removeHtmlTag(element, "img")
    }

    /**
     * Merge style of sibling nodes to the parent node of the given element
     * if the value is the same (= simplify HTML). Styles are not propagated bottom-up
     * for some tags (like ul)
     * @param element HTML element which sibling element styles will be merged to parent
     * @param styleName Name of the style attribute
     */
    static mergeStyle(element: HTMLElement, styleName: string) {
        const parent = element.parentElement
        if (parent && "ul|ol".indexOf(parent.tagName.toLowerCase()) < 0) {

            let styleValue = this.getChildStyle(parent, styleName)
            if (styleValue !== null) {

                // Merge style attribute to node: parent
                Util.setStyleOnElement(parent, styleName, styleValue)

                // Remove style from direct children
                const children = Array.from(parent.childNodes)
                for (let i = 0; i < children.length; i++) {
                    const childElement = children[i] as HTMLElement
                    if (children[i].nodeType !== Node.TEXT_NODE) {
                        Util.removeStyleFromElement(childElement, styleName, [])
                    }
                }
            }
        }
    }

    /**
     * Reformat the HTML code by merging styles of siblings and removing empty tags without styling
     * afterwards
     * @param element HTML element starting point of DOM (= container)
     * @param styleName Name of style attribute to be merged
     */
    static reformatHtml(element: HTMLElement, styleName: string) {
        Util.reformatHtmlChild(element, styleName, false)
    }

    private static reformatHtmlChild(element: HTMLElement, styleName: string, merge: boolean) {

        for (let i = 0; i < element.childNodes.length; i++) {
            if (element.childNodes[i].nodeType !== Node.TEXT_NODE) {
                const childElement = element.childNodes[i] as HTMLElement

                this.reformatHtmlChild(childElement, styleName, true)

                if (merge) {
                    this.mergeStyle(childElement, styleName)
                }

                // If a whole "li" has a font-color set the same color on the bullet SVG icon by setting the
                // CSS class corresponding to the font color
                if ("li".indexOf(childElement.tagName.toLowerCase()) >= 0) {

                    // Color set so colorize bullet
                    const color = childElement.style["color"]
                    if (color) {
                        Util.colorizeListItem(childElement, color)
                    }
                    // Color not set, remove css class
                    else {
                        if (childElement.hasAttribute("class")) {
                            childElement.removeAttribute("class")
                        }
                    }
                }
            }
        }
    }

    /**
     * Get style attribute value of all children if it is identical. If at least one child node
     * has a different style value OR there is a text node (which has no style attributes at all)
     * the function returns NULL
     * @param element HTML element which child nodes are inspected
     * @param styleAttribute Name of the style attribute (e.g. fontSize)
     * @return
     */
    static getChildStyle(element: HTMLElement, styleAttribute: string): string | null {

        if (element && element.firstChild) {

            // Get style attribute of first child
            let styleValue: string | null = null

            // check value of all children
            for (let i = 0; i < element.childNodes.length; i++) {
                if (element.childNodes[i].nodeType === Node.TEXT_NODE) {
                    return null
                }

                let c = (element.childNodes[i] as HTMLElement)
                if (c && c.style && c.style[styleAttribute]) {
                    if (styleValue === null) {
                        styleValue = c.style[styleAttribute]
                    } else if (c.style[styleAttribute] !== styleValue) {
                        return null
                    }
                }
            }

            if (styleValue === undefined || styleValue === "") {
                return null
            }

            return styleValue
        }

        return null
    }

    /** Color functions */
    static colorizeListItem(element: HTMLElement, color: string) {
        if (!this.isHexColor(color)) {
            color = this.convertRgbToHex(color)
        }
        let hexColor = color.replaceAll(" ", "").replaceAll("#", "")
        element.className = "svg-color-" + hexColor.toUpperCase() + "-before"
    }

    static isHexColor(color: string) {
        let Reg_Exp = /^#[0-9A-F]{6}$/i;
        return Reg_Exp.test(color)
    }

    static convertRgbToHex(colorRgb: string) {
        colorRgb = colorRgb.replace("rgb(", "").replace(")", "")
        let colorArray = colorRgb.split(",")

        let colorHex = "#"
        colorArray.forEach(c => {
            let hex = (+c).toString(16)
            colorHex = colorHex.concat(hex.length === 1 ? "0" + hex : hex)
        })

        return colorHex
    }

    static shadeColor(color: string, percent: number) {
        let R = parseInt(color.substring(1, 3), 16)
        let G = parseInt(color.substring(3, 5), 16)
        let B = parseInt(color.substring(5, 7), 16)

        R = R * (100 + percent) / 100
        G = G * (100 + percent) / 100
        B = B * (100 + percent) / 100

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        let RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
        let GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
        let BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    /**
     * HTML Element transformation
     * */
    static getRotationOfStyle(element: HTMLElement) {
        let st = window.getComputedStyle(element, null);
        let tr = st.getPropertyValue("-webkit-transform") ||
            st.getPropertyValue("-moz-transform") ||
            st.getPropertyValue("-ms-transform") ||
            st.getPropertyValue("-o-transform") ||
            st.getPropertyValue("transform") ||
            "FAIL";

        const values = tr.split('(')[1].split(')')[0].split(',');
        const a: number = +values[0];
        const b: number = +values[1];

        return Math.round(Math.atan2(b, a) * (180 / Math.PI))
    }

    static getScaleRatioOfRotatedObject(width: number, height: number, angle: number) {
        let alpha = Math.atan(height / width)
        let diagLength = Math.sqrt(Math.pow(height, 2) + Math.pow(width, 2))
        let theta = Util.degToRad(angle)

        theta = Util.radTrunc(theta)
        theta = (theta > Math.PI / 2 && theta < Math.PI) || (theta > 3 * Math.PI / 2 && theta < 2 * Math.PI) ? Math.PI - theta : theta

        let beta = (Math.PI / 2) - (alpha + theta)
        let nonScaledHeight = Math.cos(beta) * diagLength
        return Math.abs(height / nonScaledHeight)
    }

    static degToRad(angle: number) {
        return angle * Math.PI / 180;
    }

    static radTrunc(angle: number) {
        while (angle > (Math.PI * 2)) {
            angle -= (Math.PI * 2);
        }
        while (angle < 0) {
            angle += (Math.PI * 2);
        }
        return angle;
    }

    /** Image functions */
    static compressImage(imageUrl: string, newWidth: number): Promise<string> {
        return new Promise((resolve, reject) => {
            if (imageUrl.endsWith("svg")) {
                resolve(imageUrl)
            }

            // Provide default values
            let imageType = "image/jpeg"
            if (imageUrl.endsWith("png")) {
                imageType = "image/png"
            } else if (imageUrl.endsWith("gif")) {
                imageType = "image/gif"
            }
            imageType = imageType || "image/jpeg"

            // Create a temporary image so that we can compute the height of the downscaled image.
            let image = document.createElement("img")
            image.setAttribute("crossorigin", "anonymous");
            image.src = imageUrl

            // Quality and size settings
            let imageQuality = 1    // Full quality (adjustment done by size)
            newWidth = newWidth * 2          // Double the size of the image element for better quality

            image.onload = function () {
                // console.log("W / H: " + image.width + " / " + image.height + " -> " + newWidth)
                let newHeight = Math.floor(image.height / image.width * newWidth)

                // Create a temporary canvas to draw the downscaled image on.
                let canvas = document.createElement("canvas")
                canvas.width = newWidth
                canvas.height = newHeight

                // Draw the downscaled image on the canvas and return the new data URL.
                let ctx = canvas.getContext("2d")
                if (ctx === null) {
                    resolve(imageUrl)
                }

                ctx?.drawImage(image, 0, 0, newWidth, newHeight)
                let dataUrl = canvas.toDataURL(imageType, imageQuality)
                resolve(dataUrl)
            }
        })
    }

    /**
     * Date functions
     * */
    static formatDate(value: Date, time?: boolean): string {
        const date = new Date(value)
        let d = date.getDate()
        let m = date.getMonth() + 1

        let day = d < 10 ? "0" + d : d
        let month = m < 10 ? "0" + m : m
        let year = date.getFullYear()

        let result = day + "." + month + "." + year
        if (time === true) {
            result += " " + date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
        }

        return result;
    }

    static formatTime(value: Date): string {
        const time = new Date(value)

        return time.toLocaleTimeString() + `.${time.getMilliseconds()}`;
    }

    /**
     * Cursor Selection
     * */
    static getSelection(element: HTMLElement) {
        let selection = getSelection()
        if (selection) {

            // Retrieve selection when the selected element is a child of the element provided as parameter
            if (selection.focusNode && Util.isChildOfId(selection.focusNode as HTMLElement, element.id)) {

                let focusNode = selection.focusNode as HTMLElement
                if (focusNode.tagName && focusNode.tagName.toUpperCase() === "INPUT") {
                    return
                }

                let range = selection.getRangeAt(0)
                if (range.startContainer instanceof Node) {
                    let preSelectionRange = range.cloneRange()
                    preSelectionRange.selectNodeContents(element)
                    preSelectionRange.setEnd(range.startContainer, range.startOffset)

                    return new Selection(
                        preSelectionRange.toString().length,
                        preSelectionRange.toString().length + range.toString().length
                    )
                }
            }
        }
    }

    static setSelection(element: HTMLElement, selection: Selection, selectedNodeId: string | undefined) {

        let charIndex = 0, range = document.createRange();
        let nodeStack: Node[] = [element as Node], node: Node | undefined, foundStart = false, stop = false;

        // Nothing selected, position caret to element with id provided
        if (selection.start === selection.end && selectedNodeId) {
            const selectedNode = document.getElementById(selectedNodeId)
            if (selectedNode) {
                selectedNode.removeAttribute("id")

                range.selectNodeContents(selectedNode);
                range.collapse(false);
            }
        }

        // Re-select original selection
        else {
            range.setStart(element, 0);
            range.collapse(false);

            while (!stop && (node = nodeStack.pop())) {

                // Text node
                if (node.nodeType === Node.TEXT_NODE) {
                    let nextCharIndex = charIndex + node.nodeValue!.length;

                    if (!foundStart && selection.start >= charIndex && selection.start <= nextCharIndex) {
                        range.setStart(node, selection.start - charIndex);
                        foundStart = true;
                    }
                    if (foundStart && selection.end >= charIndex && selection.end <= nextCharIndex) {
                        range.setEnd(node, selection.end - charIndex);
                        stop = true;
                    }
                    charIndex = nextCharIndex;
                } else {
                    let i = node.childNodes.length;
                    while (i--) {
                        nodeStack.push(node.childNodes[i]);
                    }
                }
            }
        }

        // Focus on element, remove all selection ranges and create new one
        let sel = window.getSelection();
        if (sel) {
            sel.removeAllRanges();
            sel.addRange(range);
        }
        element.focus()
    }

    static selectAllText(element: HTMLElement) {
        let range = document.createRange();
        range.selectNodeContents(element);
        let selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
        }
        element.focus()
    }

    static getSelectedText() {
        let sel = window.getSelection()
        if (sel) {
            return sel.toString()
        }

        return "";
    }

    static clearSelection() {
        if (window.getSelection) {
            window.getSelection()?.removeAllRanges()
        }
    }

    static addElementAtSelection(element: HTMLElement | Node, select: boolean = true) {
        let range: Range, lastNode: HTMLElement | undefined;
        let sel = element.ownerDocument?.defaultView?.getSelection()

        if (sel && sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0)

            // Check if the selected element is the textbox itself, then go to end of content
            if (range.startContainer instanceof HTMLElement && range.endContainer instanceof HTMLElement) {
                let startElement = range.startContainer as HTMLElement
                let endElement = range.endContainer as HTMLElement

                if (startElement.className.indexOf("ws-designer-textbox") >= 0 &&
                    endElement.className.indexOf("ws-designer-textbox") >= 0) {

                    range.deleteContents()

                    range.selectNodeContents(startElement)
                    range.collapse(false)
                    sel.removeAllRanges()
                    sel.addRange(range)
                }
            }

            range.deleteContents()

            if (element instanceof HTMLElement) {
                let frag = document.createDocumentFragment()
                lastNode = frag.appendChild(element)
                lastNode.id = "node-" + Date.now() + Math.round(Math.random() * 1000)
                range.insertNode(frag)

                // Select the inserted element
                if (select) {
                    range = range.cloneRange()
                    range.setStartAfter(lastNode)
                    range.collapse(true)
                    sel.removeAllRanges()
                    sel.addRange(range)
                }
            }
            else if (element instanceof Node) {
                range.insertNode(element)

                if (select) {
                    range = range.cloneRange()
                    range.setStartAfter(element)
                    range.collapse(true)
                    sel.removeAllRanges()
                    sel.addRange(range)
                }
            }
        }

        return lastNode?.id
    }

    static getCaretPositionInElement(element: HTMLElement) {
        let sel = element.ownerDocument.defaultView?.getSelection()
        if (!sel || sel.rangeCount === 0) return 0

        let range = element.ownerDocument.defaultView?.getSelection()?.getRangeAt(0)
        if (!range) return 0

        return range.startOffset
    }

    static getCharacterPrecedingCaret(element: HTMLElement) {
        let precedingChar = ""
        if (window.getSelection) {
            let sel = window.getSelection()
            if (sel && sel.rangeCount > 0) {
                let range = sel.getRangeAt(0).cloneRange()
                range.collapse(true)
                range.setStart(element, 0)
                precedingChar = range.toString().slice(-1)
            }
        }
        return precedingChar
    }

    /**
     * Positioning
     * **/
    static horizontalCenter(element: HTMLElement) {
        let browserWidth = window.outerWidth //.innerWidth
        let elementWidth = element.getBoundingClientRect().width

        element.style.left = (browserWidth / 2 - elementWidth / 2) + "px"
    }

    /**
     * Array
     * */
    static cloneArray<T>(array: T[]) {
        // return array.map<T>(item => Array.isArray(item) ? this.cloneArray(item) : item)

        const cloned: T[] = [];
        array.forEach(val => cloned.push({...val}))
        return cloned
    }

    static shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array
    }

    /**
     * Timing
     * */
    static async sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Numbers & Formatting
     * **/
    static formatNumber(number: number, format: string): string {
        return new Intl.NumberFormat(format).format(number)
    }

    static roundTo(n: number, digits: number): number {
        let negative = false;

        if (digits === undefined) {
            digits = 0;
        }
        if (n < 0) {
            negative = true;
            n = n * -1;
        }

        let multiplicator = Math.pow(10, digits)
        n = parseFloat((n * multiplicator).toFixed(11))
        n = +(Math.round(n) / multiplicator).toFixed(digits)

        if (negative) {
            n = +(n * -1).toFixed(digits)
        }
        return n
    }

    /**
     * Calculations
     * **/
    static calculateRenderSize(imageSize: Coords, alignment: ImageAlignment): Coords {
        let renderCoords = new Coords(210, 210)

        switch (alignment) {
            case ImageAlignment.quadratic:
                renderCoords.x = 210
                renderCoords.y = 210
                break

            case ImageAlignment.portrait:
                renderCoords.x = Math.round((297 / imageSize.y) * imageSize.x)
                renderCoords.y = 297
                break

            case ImageAlignment.landscape:
                renderCoords.x = 297
                renderCoords.y = Math.round((297 / imageSize.x) * imageSize.y)
                break

            case ImageAlignment.panorama:
                renderCoords.x = 420
                renderCoords.y = Math.round((420 / imageSize.x) * imageSize.y)
                break
        }

        return renderCoords
    }

    static calculateProportionalImageThumbWidth = (image: Image, targetHeight: number) => {
        const alignment = image.thumbAlignment || image.alignment || ImageAlignment.quadratic
        let renderCoords = Image.getSizeByAlignment(alignment)

        if (image.thumbWidth && image.thumbHeight) {
            renderCoords = Util.calculateRenderSize(new Coords(image.thumbWidth, image.thumbHeight), alignment)
        }
        else if (image.width && image.height) {
            renderCoords = Util.calculateRenderSize(new Coords(image.width, image.height), alignment)
        }

        return renderCoords.x * (targetHeight / renderCoords.y)
    }

    static getZoomStyle = (zoom: number) => {
        let p = ["Webkit", "Moz", "Ms", "O"]
        let s = "scale(" + zoom + ")"
        let origin = "top center";
        let style: React.CSSProperties = {}

        for (let i = 0; i < p.length; i++) {
            style[p[i] + "Transform"] = s;
            style[p[i] + "TransformOrigin"] = origin;
        }

        style["transform"] = s;
        style["transformOrigin"] = origin;

        return style
    }

    /**
     * JSON
     * **/
    static isValidJson(jsonString: string) {
        try {
            let o = JSON.parse(jsonString);
            if (o && typeof o === "object") {
                return o;
            }
        } catch (e) {
        }

        return false;
    }

    /**
     * Error Handling
     */
    static getError(error: unknown) {
        if (typeof error === "object" && error instanceof Error) {
            return error;
        } else if (typeof error === "string") {
            return new Error(error);
        }

        // else turn this unknown thing into a string
        return new Error(JSON.stringify(error));
    }
}
