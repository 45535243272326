import {TooltipText} from "../Tooltips";

export class RibbonButtonData {
    id: string
    text?: string
    icon: string
    sort: number
    colorPrimary: boolean
    tooltip?: TooltipText

    navigateTo?: string
    onClick?: () => void
    shownOnCondition?: () => boolean


    constructor(id: string, icon: string, sort: number, colorPrimary: boolean, text?: string, tooltip?: TooltipText, onClick?: () => void, shownOnCondition?: () => boolean) {
        this.id = id
        this.text = text
        this.sort = sort
        this.icon = icon
        this.colorPrimary = colorPrimary
        this.tooltip = tooltip

        this.onClick = onClick
        this.shownOnCondition = shownOnCondition
    }
}
