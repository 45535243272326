import Entity from "../../_model/Entity";

export default class CountryListItem extends Entity {
    isoCode: string
    languages: string
    status: string
    modifiedOn: Date
    owner: string
    createdBy: string

    constructor(id: number, name: string, owner: string, modifiedOn: Date, initial: string, languages: string,
                status: string, createdBy: string) {

        super(name, id);

        this.isoCode = initial
        this.languages = languages
        this.modifiedOn = modifiedOn
        this.owner = owner
        this.status = status
        this.createdBy = createdBy
    }
}
