import React from 'react';
import {ColumnDefinition} from "../../Components/List/List";
import translations from "../../Framework/translations.json";
import {AdminObjectType, ColumnRenderOption, Status} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import Exercise from "../../_model/Exercise";
import {CloneExercise, GetExercise, GetExerciseList, UpdateExercise} from "../../_endpoint/ExerciseEndpoint";
import ExerciseListItem from "./ExerciseListItem";
import User from "../../_model/User";
import Const from "../../Framework/Const";
import {WSContextType} from "../../Designer/Elements/WSContext";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: Exercise[]
    listItems?: ExerciseListItem[]
}

export default class ExerciseList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.title), "name", 13, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.lesson_subject), "lessonSubject", 7, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.exercise.category), "exerciseCategory", 7, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.exercise.difficulty), "difficulty", 9, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.exercise.menuRelation), "menuRelation", 10, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.school_level), "schoolLevel", 13, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 13, false, true, ColumnRenderOption.DateTime)
    ]
    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ items: [] })

        GetExerciseList().then(
            (itemData) => {

                const listItems = itemData.map(item => {
                    // Status-Text (translated)
                    let status = translations.enum.status[item.status]

                    let schoolLevel = ""
                    if (item.schoolLevels) {
                        schoolLevel = item.schoolLevels
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(r => r.name).join(", ")
                    }

                    let menuRelation = ""
                    if(item.menuEntries) {
                        menuRelation = item.menuEntries
                            .map(r => r.name).join(", ")
                    }

                    let createdBy = ""
                    if (item.createdBy) {
                        createdBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    return new ExerciseListItem(
                        item.id!,
                        item.name,
                        this.context.translate(status),
                        owner,
                        item.modifiedOn || item.createdOn!,
                        item.lessonSubject ? item.lessonSubject.name : "",
                        schoolLevel,
                        item.exerciseCategory ? item.exerciseCategory.name : "",
                        item.difficulty ? this.context.translate(translations.enum.difficulty[item.difficulty]) : "",
                        menuRelation,
                        createdBy
                    )
                })

                this.setState({ items: itemData, listItems: listItems })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    onCopyItem = async(items: number[]) => {
        try {
            for (const id of items) {
                const exercise = await GetExercise(id)
                if (exercise && exercise.status !== Status.deactivated) {

                    let clone: Exercise = { ...exercise }
                    clone.id = undefined
                    clone.status = Status.draft

                    await CloneExercise(clone, clone.name + " - " + this.context.translate(translations.text_fragment.copy), WSContextType.exercise)
                }
            }

            this.fetchData()
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))
        }
        catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
        }
    }

    onSetState = async(item: number, status: Status, user?: User) => {
        try {
            const exercise = this.state.items.find(d => d.id === item)
            if (exercise) {
                Exercise.setStatus(exercise, status, user)
                await UpdateExercise(exercise)

                return true
            }
        } catch (e) { }

        return false
    }
    onRefresh = () => {
        this.fetchData()
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              newItemUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.exercise) + Const.CreateNewDataUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.exercise) + "{0}"}
                              objectType={AdminObjectType.exercise}
                              onCopyItem={this.onCopyItem}
                              onSetStatus={this.onSetState}
                              onRefresh={this.onRefresh}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              showActivate={true}
                              showDeactivate={true}
                              searchOptions={{ showSearch: true, typeAheadSearch: true, showSearchOptions: false }}
                              paginationOptions={{ showPagination: true }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "modifiedOn",
                                  sortDescending: true
                              }}
                              filterOptions={{ externalFilter: false }}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}

