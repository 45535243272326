import React from "react";
import {MainContext} from "../../../../_base/MainContext";
import translations from "../../../../Framework/translations.json";
import Word, {WordDefinition} from "../../../../_model/Dictionary/Word";
import {SyllableDefinition, WDSyllableWord} from "../../../Elements/Textbox/WDSyllableWord";
import {Difficulty, EnumValueToEntity, Status} from "../../../../Framework/Enums";
import {
    SidebarContentBase,
    SidebarContentBaseListFilter,
    SidebarContentBaseProps,
    SidebarContentBaseState
} from "../../SidebarContentBase";
import {ImageSmallListItem} from "../../../../Admin/Images/ImageSmallListItem";
import Image, {ImageFilter} from "../../../../_model/Image";
import {GetImageGalleryFiltered} from "../../../../_endpoint/ImageEndpoint";
import {WSContextType} from "../../../Elements/WSContext";
import {ImageListFilter} from "../../../../Admin/Images/ImageListFilter";
import { SidebarDictionaryWordFormNoun } from "./SidebarDictionaryWordFormNoun";
import { SidebarDictionaryWordFormVerb } from "./SidebarDictionaryWordFormVerb";
import { SidebarDictionaryWordFormAdjective } from "./SidebarDictionaryWordFormAdjective";
import { SidebarDictionaryWordFormArticle } from "./SidebarDictionaryWordFormArticle";
import { SidebarDictionaryWordFormPronoun } from "./SidebarDictionaryWordFormPronoun";
import { SidebarDictionaryWordFormNumeral } from "./SidebarDictionaryWordFormNumeral";
import { SidebarDictionaryWordFormAdverb } from "./SidebarDictionaryWordFormAdverb";
import { SidebarDictionaryWordFormPreposition } from "./SidebarDictionaryWordFormPreposition";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {WDUtils} from "../../../Utils/WDUtils";
import ListSortSetting from "../../../../Components/List/ListSortSetting";

interface IProps extends SidebarContentBaseProps {
    word: Word
    syllableDefinition: SyllableDefinition
    onClickNewWord: (wordId: number) => void
}
interface IState extends SidebarContentBaseState {
    images: Image[]
    pageImage: number
    lastImage: boolean
}

export class SidebarDictionaryWordForm extends SidebarContentBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            images: [],
            filter: SidebarContentBaseListFilter.initFilter(),
            filterHistory: [],
            filterHistoryIndex: 0,
            page: 0,
            lastItem: true,
            pageImage: 0,
            lastImage: false
        }
    }

    componentDidMount() {
        this.fetchImages(this.state.pageImage)
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(this.props.word !== prevProps.word) {
            this.fetchData(true)
            this.fetchImages(this.state.pageImage)
        }
    }

    fetchImages = (pageNumber: number) => {
        const filter = new ImageFilter()
        filter.search = this.props.word.name
        filter.alignment = ImageListFilter.all_alignments
        filter.property = ImageListFilter.all_properties
        filter.color = ImageListFilter.all_colors
        filter.exactMatch = true
        filter.sort = new ListSortSetting("rating", true)

        GetImageGalleryFiltered(filter, WSContextType.standard, pageNumber, 10).then(
            (itemData) => {
                let items = this.state.images
                items = items.concat((itemData.content as Image[]).filter(item => item.url !== null))

                let lastImage = WDUtils.lastItemResultReached(itemData.content.length, this.PAGE_SIZE, 10)

                this.setState({images: items, lastImage: lastImage, pageImage: pageNumber})
            }
        )
    }
    onClickMoreImages = () => {
        this.fetchImages(this.state.pageImage + 1)
    }

    renderWordLinks = () : any => {
        let links = this.props.word.wordLinks
            ?.filter(wLink => wLink.status === Status.published)
            .map((wLink, i, arr) => {
                return <div className={" ws-designer-dictionary-click"}
                            key={"word-link-" + wLink.id}
                            onClick={() => wLink.id && this.props.onClickNewWord(wLink.id)}>
                    <WDSyllableWord
                        syllableDefinition={this.props.syllableDefinition}
                        syllableWord={wLink.syllabification || wLink.name}/>

                    {(i < arr.length - 1) &&
                        <div style={{marginRight: "5px"}}>,</div>
                    }
                </div>
            })

        if(links === undefined || links.length === 0) {
            return <div>
                {this.context.translate(translations.text.no_links)}
            </div>
        }

        return links
    }
    renderWordHighlights = () : string => {
        let highlights: string | undefined = undefined

        highlights = this.props.word.wordHighlights
            ?.map(highlight => {
                return highlight.name
            })
            ?.join(", ")

        if(highlights === undefined || highlights === "") {
            highlights = this.context.translate(translations.text.not_special)
        }

        return highlights!
    }
    renderWordTopics = () : string => {
        let topics: string | undefined = undefined

        topics = this.props.word.wordTopics
            ?.map(wordTopics => {
                return wordTopics.name
            })
            ?.join(", ")

        if(topics === undefined || topics === "") {
            topics = this.context.translate(translations.text.no_topic)
        }

        return topics!
    }
    renderWordInfos = () : JSX.Element => {
        let additionalInfo: string | undefined = undefined

        if(this.props.word.phonemic) {
            additionalInfo = this.context.translate(translations.fields.word.phonemic)
        }
        if(this.props.word.foreignWord) {
            additionalInfo = additionalInfo !== undefined
                ? additionalInfo.concat(", ", this.context.translate(translations.fields.word.foreign))
                : this.context.translate(translations.fields.word.foreign)
        }
        if(this.props.word.exceptionWord) {
            additionalInfo = additionalInfo !== undefined
                ? additionalInfo.concat(", ", this.context.translate(translations.fields.word.exception))
                : this.context.translate(translations.fields.word.exception)
        }
        if(this.props.word.basicVocabulary) {
            additionalInfo = additionalInfo !== undefined
                ? additionalInfo.concat(", ", this.context.translate(translations.fields.word.basic_vocabulary))
                : this.context.translate(translations.fields.word.basic_vocabulary)
        }

        // if none of the above is true
        if(additionalInfo === undefined) {
            additionalInfo = this.context.translate(translations.text.no_additional_info)
        }

        return <div className={"ws-designer-dictionary-form-entry-row-text"}>
            {additionalInfo}
        </div>
    }
    render() {
        let additionalInfos = this.renderWordInfos()

        return <div className={"ws-designer-dictionary-form-entry"}>
            <div className={"ws-designer-dictionary-form-entry-word"}>
                <WDSyllableWord
                        syllableDefinition={this.props.syllableDefinition}
                        onDragStart={this.onDragStart}
                        syllableWord={this.props.word.syllabification || this.props.word.name}/>
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.wordDefinition) + ":"}
                </div>
                {this.props.word.wordDefinition &&
                    <div className={"ws-designer-dictionary-form-entry-row-text"}>
                        {EnumValueToEntity(WordDefinition, this.props.word.wordDefinition, translations.enum.wordDefinition, this.context.translate).name}
                    </div>
                }
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.additional_infos) + ":"}
                </div>
                    {additionalInfos}
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.difficulty) + ":"}
                </div>
                {this.props.word.difficulty &&
                    <div className={"ws-designer-dictionary-form-entry-row-text"}>
                        {EnumValueToEntity(Difficulty, this.props.word.difficulty, translations.enum.difficulty, this.context.translate).name}
                    </div>
                }
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.word_topics) + ":"}
                </div>
            </div>
            <div className={"ws-designer-dictionary-form-entry-row-text-new-line"}>
                {this.renderWordTopics()}
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.word_links) + ":"}
                </div>
            </div>
            <div className={"ws-designer-dictionary-form-entry-row-text-new-line"}>
                {this.renderWordLinks()}
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.word_highlights) + ":"}
                </div>
            </div>
            <div className={"ws-designer-dictionary-form-entry-row-text-new-line"}>
                {this.renderWordHighlights()}
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.image.images) + ":"}
                </div>
            </div>
            <ImageSmallListItem images={this.state.images}
                                onDragStart={this.onDragStart}
            />
            {this.state.images && !this.state.lastImage &&
                <div className={"ws-designer-sidebar-more-link"} onClick={this.onClickMoreImages}>
                    {this.context.translate(translations.text.more_images)}
                    &nbsp;<img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "double_arrow_left.svg"}
                               alt={""} height={"13px"}
                               style={{transform: "rotateY(180deg)"}}/>
                </div>
            }

            {/* Specific word parts depending on word definition */}
            {this.props.word.wordDefinition === WordDefinition.noun &&
                <SidebarDictionaryWordFormNoun
                    wordId={this.props.word.id}
                    syllableDefinition={this.props.syllableDefinition}
                    onDragStart={this.onDragStart}
                />
            }
            {this.props.word.wordDefinition === WordDefinition.verb &&
                <SidebarDictionaryWordFormVerb
                    wordId={this.props.word.id}
                    syllableDefinition={this.props.syllableDefinition}
                    onDragStart={this.onDragStart}
                />
            }
            {this.props.word.wordDefinition === WordDefinition.adjective &&
                <SidebarDictionaryWordFormAdjective
                    wordId={this.props.word.id}
                    syllableDefinition={this.props.syllableDefinition}
                    onDragStart={this.onDragStart}
                    onClickNewWord={this.props.onClickNewWord}
                />
            }
            {this.props.word.wordDefinition === WordDefinition.article &&
                <SidebarDictionaryWordFormArticle
                    wordId={this.props.word.id}
                    onDragStart={this.onDragStart}
                />
            }
            {this.props.word.wordDefinition === WordDefinition.pronoun &&
                <SidebarDictionaryWordFormPronoun
                    wordId={this.props.word.id}
                    onDragStart={this.onDragStart}
                />
            }
            {this.props.word.wordDefinition === WordDefinition.numeral &&
                <SidebarDictionaryWordFormNumeral
                    wordId={this.props.word.id}
                    onDragStart={this.onDragStart}
                />
            }
            {this.props.word.wordDefinition === WordDefinition.adverb &&
                <SidebarDictionaryWordFormAdverb
                    wordId={this.props.word.id}
                    onDragStart={this.onDragStart}
                />
            }
            {this.props.word.wordDefinition === WordDefinition.preposition &&
                <SidebarDictionaryWordFormPreposition
                    wordId={this.props.word.id}
                    onDragStart={this.onDragStart}
                />
            }
        </div>
    }
}
