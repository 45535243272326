import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButton} from "../WDToolbarButton";
import {ImagePath} from "../../../../Framework/CategoryImage";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";
import {WDColorPicker} from "../../General/WDColorPicker";
import Const from "../../../../Framework/Const";
import {OptionSettings} from "../../General/OptionSettings";

/**
 * Action-Button in Toolbar with submenu
 * Effects selected math lineature cell
 * On click the reckon bracket is added to the cell at the top or bottom of the cell
 */

interface IProps {
    id: number
    clickable: boolean
    options: OptionSettings

    onToggleButton: (id: number, showCorrectionMargin?: boolean) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    position: BracketPosition
    color: string
}

export enum BracketPosition {
    top = "top",
    bottom = "bottom"
}

export class WDToolbarButtonReckonBracket extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            position: BracketPosition.top,
            color: Const.COLOR_RED
        }
    }

    getButtonIcon = () => {
        switch (this.state.position) {
            case BracketPosition.bottom:
                return "reckon_check_down.svg"

            default:
                return "reckon_check_up.svg"
        }
    }

    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    onChangePosition = (position: BracketPosition) => {
        this.onToggleButton(position, this.state.color)
        this.setState({position: position})

        // close submenu
        this.props.onToggleButton(-1)
    }
    onChangeColor = (color: string) => {
        this.onToggleButton(this.state.position, color)
        this.setState({color: color})
    }

    onToggleButton = (position: BracketPosition, color: string) => {
        this.props.onActionButton(WDToolbarAction.RECKON_BRACKET, {"position": position, "color": color})
    }

    render() {
        return <div
            key={"ws-designer-toolbar-button-reckon-bracket"}
            className={"ws-designer-toolbar-item"}
            id={"reckonBracket"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + this.getButtonIcon()}
                             iconClassSelected={"ws-designer-toolbar-button-img"}
                             iconClassUnselected={"ws-designer-toolbar-button-img"}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.reckon_bracket),
                                 this.context.translate(translations.tooltip.reckon_bracket))}
                             onToggleButton={() => this.onToggleButton(this.state.position, this.state.color)}
                             buttonWidth={28}
                             arrow={false}
                             color={this.state.color}
                             enabled={this.props.clickable}
                             pressed={false} />

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.reckon_bracket),
                                 this.context.translate(translations.tooltip.reckon_bracket))}

                             iconClassSelected={"ws-designer-toolbar-button-options"}
                             iconClassUnselected={"ws-designer-toolbar-button-options"}

                             arrow={false}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             buttonHeight={32}
                             buttonWidth={15}
                             imageHeight={5}
                             imageWidth={5}

                             onToggleButton={this.props.onToggleButton}
                             pressed={this.props.options.open} />

            {this.props.options.open &&
                <div className={"ws-designer-toolbar-options"}>
                    <div className={"ws-designer-options-section-row"}
                         style={{
                             width: this.props.options.width,
                             height: this.props.options.height
                         }}>

                        <WDToolbarButton id={this.props.id}
                                         key={this.props.id + "-up"}
                                         icon={ImagePath.getButtonUrl() + "reckon_check_up.svg"}
                                         iconClassSelected={"ws-designer-toolbar-button-img"}
                                         iconClassUnselected={"ws-designer-toolbar-button-img"}
                                         onToggleButton={() => this.onChangePosition(BracketPosition.top)}
                                         color={this.state.color}
                                         arrow={false}
                                         enabled={this.props.clickable}
                                         pressed={false} />

                        <WDToolbarButton id={this.props.id}
                                         key={this.props.id + "-down"}
                                         icon={ImagePath.getButtonUrl() + "reckon_check_down.svg"}
                                         iconClassSelected={"ws-designer-toolbar-button-img"}
                                         iconClassUnselected={"ws-designer-toolbar-button-img"}
                                         onToggleButton={() => this.onChangePosition(BracketPosition.bottom)}
                                         color={this.state.color}
                                         arrow={false}
                                         enabled={this.props.clickable}
                                         pressed={false} />
                    </div>

                    <WDColorPicker transparency={false} onChangeColor={this.onChangeColor} />
                </div>
            }
        </div>
    }
}
