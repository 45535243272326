import React from "react";
import {TooltipText} from "../../../Components/Tooltips";
import {WDToolbarButton} from "../Button/WDToolbarButton";

interface IProps {
    id: number
    icon: string
    tooltip: TooltipText
    enabled: boolean
}

export class WDToolbarIconLabel extends React.Component<IProps> {
    render() {
        return <WDToolbarButton id={12}
                                icon={this.props.icon}
                                tooltip={this.props.tooltip}
                                cssClass={"ws-designer-toolbar-button-ml"}
                                buttonClass={"ws-designer-toolbar-button-image-no-hover"}
                                iconClassSelected={"svg-color-1E1F28"}
                                iconClassUnselected={"svg-color-1E1F28"}
                                onToggleButton={() => {}}
                                buttonWidth={25}
                                imageWidth={13}
                                arrow={false}
                                enabled={this.props.enabled}
                                pressed={false}
        />
    }
}