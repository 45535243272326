import React from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";
import {WSContext, WSContextType} from "../Elements/WSContext";

interface IProps {
    onOpenSidebar: (sidebar: SidebarElement) => void
    wsContext: WSContextType
    isEditingAllowed: boolean
}
interface IState {
    activeId: SidebarElement
}

export enum SidebarElement {
    None,
    PageManager,
    Favorites,
    Frames,
    Images,
    Help,
    SendErrorReport,
    Names,
    Dictionary,
}

export class Sidebar extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            activeId: SidebarElement.None
        }
    }

    onItemClick = (id: SidebarElement) => {
        if(id === SidebarElement.None && this.state.activeId === id) {
            return
        }

        // Click on selected item removes selection
        if (this.state.activeId === id) {
            id = SidebarElement.None
        }

        this.setState({ activeId: id })

        // Propagate click notification
        this.props.onOpenSidebar(id)
    }

    render() {
        return  <div className="ws-designer-sidebar">

            {/* Sidebar buttons */}
            <ul className={"ws-designer-sidebar-buttons"}>
                {/*<li className={"ws-designer-sidebar-button" + (this.state.activeId === SidebarElement.Favorites ? " ws-designer-sidebar-button-selected" : " ws-designer-sidebar-button-hover")}
                    onClick={() => this.onItemClick(SidebarElement.Favorites)}>
                    <img src={process.env.PUBLIC_URL + ImagePath.getSidebarUrl() + "favorites.svg"}
                         draggable="false"
                         alt={this.context.translate(translations.sidebar.favorites)}
                         onContextMenu={(e) => e.preventDefault() }
                    />
                    <span className={"ws-designer-sidebar-button-label"}>{this.context.translate(translations.sidebar.favorites)}</span>
                </li>*/}

                <li className={"ws-designer-sidebar-button" + (this.state.activeId === SidebarElement.PageManager ? " ws-designer-sidebar-button-selected" : " ws-designer-sidebar-button-hover")}
                    onClick={() => this.onItemClick(SidebarElement.PageManager)}>
                    <img src={process.env.PUBLIC_URL + ImagePath.getSidebarUrl() + "page_manager.svg"}
                         draggable="false"
                         alt={this.context.translate(translations.sidebar.page_manager)}
                         onContextMenu={(e) => e.preventDefault() }
                    />
                    <span className={"ws-designer-sidebar-button-label"}>{this.context.translate(translations.sidebar.page_manager)}</span>
                </li>

                {this.props.isEditingAllowed &&
                    <li className={"ws-designer-sidebar-button" + (this.state.activeId === SidebarElement.Frames ? " ws-designer-sidebar-button-selected" : " ws-designer-sidebar-button-hover")}
                        onClick={() => this.onItemClick(SidebarElement.Frames)}>
                        <img src={process.env.PUBLIC_URL + ImagePath.getSidebarUrl() + "page_frame.svg"}
                             draggable="false"
                             alt={this.context.translate(translations.sidebar.frames)}
                             onContextMenu={(e) => e.preventDefault()}
                        />
                        <span
                            className={"ws-designer-sidebar-button-label"}>{this.context.translate(translations.sidebar.frames)}</span>
                    </li>
                }

                {this.props.isEditingAllowed &&
                    <li className={"ws-designer-sidebar-button" + (this.state.activeId === SidebarElement.Images ? " ws-designer-sidebar-button-selected" : " ws-designer-sidebar-button-hover")}
                        onClick={() => this.onItemClick(SidebarElement.Images)}>
                        <img src={process.env.PUBLIC_URL + ImagePath.getSidebarUrl() + "pictures.svg"}
                             draggable="false"
                             alt={this.context.translate(translations.sidebar.images)}
                             onContextMenu={(e) => e.preventDefault()}
                        />
                        <span
                            className={"ws-designer-sidebar-button-label"}>{this.context.translate(translations.sidebar.images)}</span>
                    </li>
                }

                {this.props.isEditingAllowed &&
                    <li className={"ws-designer-sidebar-button" + (this.state.activeId === SidebarElement.Dictionary ? " ws-designer-sidebar-button-selected" : " ws-designer-sidebar-button-hover")}
                        onClick={() => this.onItemClick(SidebarElement.Dictionary)}>
                        <img src={process.env.PUBLIC_URL + ImagePath.getSidebarUrl() + "dictionary.svg"}
                             draggable="false"
                             alt={this.context.translate(translations.sidebar.dictionary)}
                             onContextMenu={(e) => e.preventDefault()}
                        />
                        <span
                            className={"ws-designer-sidebar-button-label"}>{this.context.translate(translations.sidebar.dictionary)}</span>
                    </li>
                }

                {this.props.wsContext === WSContextType.standard &&
                <li className={"ws-designer-sidebar-button" + (this.state.activeId === SidebarElement.Help ? " ws-designer-sidebar-button-selected" : " ws-designer-sidebar-button-hover")}
                    onClick={() => this.onItemClick(SidebarElement.Help)}>
                    <img src={process.env.PUBLIC_URL + ImagePath.getSidebarUrl() + "help.svg"}
                         draggable="false"
                         alt={this.context.translate(translations.sidebar.help)}
                         onContextMenu={(e) => e.preventDefault() }
                    />
                    <span className={"ws-designer-sidebar-button-label"}>{this.context.translate(translations.sidebar.help)}</span>
                </li>
                }

                {WSContext.showSidebarNames(this.props.wsContext) &&
                <li className={"ws-designer-sidebar-button" + (this.state.activeId === SidebarElement.Names ? " ws-designer-sidebar-button-selected" : " ws-designer-sidebar-button-hover")}
                    onClick={() => this.onItemClick(SidebarElement.Names)}>
                    <img src={process.env.PUBLIC_URL + ImagePath.getMenuUrl() + "name.svg"}
                         draggable="false"
                         alt={this.context.translate(translations.sidebar.names)}
                         onContextMenu={(e) => e.preventDefault() }
                    />
                    <span className={"ws-designer-sidebar-button-label"}>{this.context.translate(translations.sidebar.names)}</span>
                </li>
                }
            </ul>
        </div>
    }
}

