/**
 * Defines all necessary values to show correct correction margin
 * @param showCorrectionMargin - defines if correction margin should be shown or not
 * @param position - defines in which area the margin should be shown and on which side of the margin it is not allowed to write
 * @param margin - number of pixels from position (left/right), no affect if middle position is selected; string because % or px is used
 */
export class WDWritingLineatureCorrectionMargin {
    showCorrectionMargin: boolean
    position: CorrectionMarginPosition
    margin: string

    constructor(showCorrectionMargin: boolean, position: CorrectionMarginPosition, margin: string) {
        this.showCorrectionMargin = showCorrectionMargin
        this.position = position
        this.margin = margin
    }
}

export enum CorrectionMarginPosition {
    left= "left",
    right = "right"
}

export function getDefaultCorrectionMargin(): WDWritingLineatureCorrectionMargin {
    return new WDWritingLineatureCorrectionMargin(false, CorrectionMarginPosition.right, "91px")
}
