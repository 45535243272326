import React from "react";
import {
    WDToolbarElement,
    WDToolbarElementProps,
    WDToolbarElementState
} from "../../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbar, WDToolbarOrientation} from "../../../Toolbar/Toolbar/WDToolbar";
import {WDToolbarButtonDeleteRow} from "../../../Toolbar/Button/Lineature/WDToolbarButtonDeleteRow";
import {WDRowPosition, WDToolbarButtonInsertRow} from "../../../Toolbar/Button/Lineature/WDToolbarButtonInsertRow";
import {WDToolbarButtonGraphics} from "../../../Toolbar/Button/General/WDToolbarButtonGraphics";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../../Framework/CategoryImage";
import translations from "../../../../Framework/translations.json";
import {WDToolbarAction} from "../../../Toolbar/WDToolbarAction";
import Converter from "../../../../Framework/Converter";
import {MainContext} from "../../../../_base/MainContext";
import {WDToolbarSectionObjectGeneral} from "../../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarSectionTransformation} from "../../../Toolbar/Section/WDToolbarSectionTransformation";
import {ElementLayout} from "../../WDElementContainer";
import {WDTextboxToolbarProps} from "../../Textbox/WDTextboxToolbar";
import {WDMathLineature} from "./WDMathLineature";
import {
    WDColumnPosition,
    WDToolbarButtonInsertColumn
} from "../../../Toolbar/Button/Lineature/WDToolbarButtonInsertColumn";
import {WDToolbarButtonDeleteColumn} from "../../../Toolbar/Button/Lineature/WDToolbarButtonDeleteColumn";
import {NumberInput} from "../../../../Components/Controls/NumberInput";
import {WDMathLineatureData, WDMathLineatureLineType} from "./WDMathLineatureData";
import {WDToolbarButtonColor} from "../../../Toolbar/Button/General/WDToolbarButtonColor";
import {IconType, WDToolbarButtonInsertIcon} from "../../../Toolbar/Button/Lineature/WDToolbarButtonInsertIcon";
import {WDToolbarButtonSeparator} from "../../../Toolbar/Button/General/WDToolbarButtonSeparator";
import {WDToolbarFontType} from "../../../Toolbar/Button/Text/WDToolbarFontType";
import {WDToolbarButtonTextDecoration} from "../../../Toolbar/Button/Text/WDToolbarButtonTextDecoration";
import {BorderType, WDToolbarButtonCellBorder} from "../../../Toolbar/Button/Lineature/WDToolbarButtonCellBorder";
import {TooltipPosition, Tooltips, TooltipText} from "../../../../Components/Tooltips";
import {WDToolbarButtonSolution} from "../../../Toolbar/Button/Admin/WDToolbarButtonSolution";
import {WSContext, WSContextType} from "../../WSContext";
import {WDToolbarButtonReckonBracket} from "../../../Toolbar/Button/Math/WDToolbarButtonReckonBracket";
import {WDToolbarButton} from "../../../Toolbar/Button/WDToolbarButton";
import {WDToolbarButtonInsertGlyph} from "../../../Toolbar/Button/Text/WDToolbarButtonInsertGlyph";
import {LinkBracketInfo, WDToolbarLinkBracket} from "../../../Toolbar/General/WDToolbarLinkBracket";
import _ from "lodash";
import {Coords} from "../../../../Framework/Coords";
import {ManualBorderMode} from "../../../../Framework/Enums";
import {WDToolbarButtonHelpLine} from "../../../Toolbar/Button/Math/WDToolbarButtonHelpLine";

export interface WDMathLineatureProps extends WDToolbarElementProps { }
export interface WDMathLineatureState extends WDToolbarElementState {
    link: boolean
    icon: string
    borderType: string
    borderMode: ManualBorderMode
    operatorMode: boolean
}

export class WDMathLineatureToolbar extends WDToolbarElement<WDMathLineatureProps, WDMathLineatureState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: WDMathLineatureProps) {
        super(props)

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            link: true,
            icon: IconType.dot.toUpperCase(),
            borderType: BorderType.bottom.toUpperCase(),
            borderMode: ManualBorderMode.Off,
            operatorMode: true,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef()
        }
    }

    componentDidUpdate(prevProps: WDTextboxToolbarProps) {
        this.evaluateToolbarType(prevProps.editMode, this.props.editMode)

        if (!this.props.editMode && this.state && this.state.borderMode !== ManualBorderMode.Off) {
            this.setState({borderMode: ManualBorderMode.Off})
        }
    }
    shouldComponentUpdate(nextProps: Readonly<WDMathLineatureProps>, nextState: Readonly<WDMathLineatureState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    evaluateToolbarType = (prevEditMode: boolean, newEditMode: boolean) =>  {

    }
    onChangeToolbarTab = (type: WDToolbarTypeEnum) => {
        if(this.state.activeToolbarType !== type) {
            if (type === WDToolbarTypeEnum.TEXT) {
                this.props.changeEditModeWithClickTab(this.props.worksheetItemKeys[0], true)
            }
            this.setState({activeToolbarType: type, activeId: -1})
        }
    }

    onChangeCellWidth = (width: number, height: number, value: number) => {
        let newHeight = height

        // Proportional resizing
        if (this.state.link) {
            newHeight = Math.round((value / width) * height)
            if (newHeight < WDMathLineatureData.defaultCellHeight) {
                newHeight = WDMathLineatureData.defaultCellHeight
            }
        }

        this.props.onToolbarAction(WDToolbarAction.CELL_SIZE, {width: value, height: newHeight})
    }
    onChangeCellHeight = (width: number, height: number, value: number) => {
        let newWidth = width

        // Proportional resizing
        if (this.state.link) {
            newWidth = Math.round((value / height) * width)
            if (newWidth < WDMathLineatureData.defaultCellWidth) {
                newWidth = WDMathLineatureData.defaultCellWidth
            }
        }

        this.props.onToolbarAction(WDToolbarAction.CELL_SIZE, {width: newWidth, height: value})
    }
    onToggleLink = () => {
        this.setState({link: !this.state.link})
    }
    onToggleOperator = () => {
        let newValue = !this.state.operatorMode

        this.setState({operatorMode: newValue})

        this.props.onToolbarAction(newValue ? WDToolbarAction.OPERATOR_MODE_ACTIVE : WDToolbarAction.OPERATOR_MODE_INACTIVE)
    }
    onToolbarAction = (action: WDToolbarAction, data?: any) => {
        this.props.onToolbarAction(action, data)

        let borderMode: ManualBorderMode = this.state.borderMode
        if (this.props.editMode) {
            if (action === WDToolbarAction.CELL_BORDER_MANUAL) {
                borderMode = (this.state.borderMode === ManualBorderMode.Border ? ManualBorderMode.Off : ManualBorderMode.Border)
            } else if (action === WDToolbarAction.CELL_BORDER_ERASE) {
                borderMode = (this.state.borderMode === ManualBorderMode.Erase ? ManualBorderMode.Off : ManualBorderMode.Erase)
            }
        }

        if (action !== WDToolbarAction.CELL_BORDER_MANUAL_COLOR) {
            this.setState({activeId: -1, borderMode: borderMode})
        }
    }

    onIconChange = (action: WDToolbarAction, data?: any) => {
        if (data && data["icon"]) {
            this.setState({icon: data["icon"].toUpperCase()})
        }

        this.onToolbarAction(action, data)
    }
    onBorderTypeChange = (action: WDToolbarAction, data?: any) => {
        if (data && data["type"]) {
            this.setState({borderType: data["type"].toUpperCase()})
        }

        this.onToolbarAction(action, data)
    }

    getElementGraphic = (obj: any) => {
        // #4266 Graphic of changeGraphic button should change matching to the element
        let lineStyle = WDMathLineatureLineType[obj.newValue]
        let iconString = ImagePath.getLineatureUrl() + "m-at-5x5-line-thumb.svg"
        if(lineStyle) {
            lineStyle = lineStyle.replace("_","-").toUpperCase()
            let imagePath = CategoryImageValue.getImageByKey([ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY], "M-AT-5X5-" + lineStyle + "-THUMB")
            iconString = ImagePath.getLineatureUrl() + imagePath
        }

        return iconString
    }

    getSelectedCells = (): Coords[] => {
        const selectedCellsObj = this.multiselectSearchForValueFromObject(this.props.additionalToolbarData, "selectedCells")
        const selectedCells = (selectedCellsObj?.valueEqual && selectedCellsObj.newValue !== undefined) ? selectedCellsObj.newValue : [] as Coords[]

        return JSON.parse(selectedCells) as Coords[]
    }
    getNumberOfSelectedCellsPerDimension = () => {
        let rows: number[] = []
        let cols: number[] = []

        let selected = this.getSelectedCells()
        for(let i = 0; i < selected.length; i++) {
            if (rows.find(r => r === selected[i].x) === undefined) {
                rows.push(selected[i].x)
            }
            if (cols.find(c => c === selected[i].y) === undefined) {
                cols.push(selected[i].y)
            }
        }

        return new Coords (new Set(cols).size, new Set(rows).size)
    }

    render() {
        // Get values to display data - important if more than one element is selected
        let colCount = this.multiselectSearchForValue("cols")
        let rowCount = this.multiselectSearchForValue("rows")
        let cellWidth = this.multiselectSearchForValue("cellWidth")
        let cellHeight = this.multiselectSearchForValue("cellHeight")
        let lineType = this.multiselectSearchForValue("lineType")
        let borderModeObj = this.multiselectSearchForValueFromObject(this.props.additionalToolbarData, "borderMode")

        const cols = (colCount?.valueEqual && colCount.newValue !== undefined) ? colCount.newValue : 1
        const rows = (rowCount?.valueEqual && rowCount.newValue !== undefined) ? rowCount.newValue : 1
        const width = (cellWidth?.valueEqual && cellWidth.newValue !== undefined) ? cellWidth.newValue : 5
        const height = (cellHeight?.valueEqual && cellHeight.newValue !== undefined) ? cellHeight.newValue : 5
        const borderMode = (borderModeObj?.valueEqual && borderModeObj.newValue !== undefined) ? borderModeObj.newValue : ManualBorderMode.Off

        let selected = this.getNumberOfSelectedCellsPerDimension()
        let deleteColumnEnabled = cols > selected.x
        let deleteRowEnabled = rows > selected.y

        let isCellBorderPressed = (borderMode === ManualBorderMode.Border && this.state.borderType === BorderType.manual.toUpperCase())
        isCellBorderPressed = isCellBorderPressed || (borderMode === ManualBorderMode.Erase && this.state.borderType === BorderType.erase.toUpperCase())

        let operatorTooltip = this.state.operatorMode ?
            new TooltipText(
                this.context.translate(translations.toolbar.operator_deactivate),
                this.context.translate(translations.tooltip.operator_deactivate)
            )
            :
            new TooltipText(
                this.context.translate(translations.toolbar.operator_activate),
                this.context.translate(translations.tooltip.operator_activate)
            )

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, true, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minWidth: Math.round(Converter.pxToMm(WDMathLineature.getDefaultWidth(undefined))),
                        minHeight: Math.round(Converter.pxToMm(WDMathLineature.getDefaultHeight(undefined))),
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={this.props.context === WSContextType.text_exercise_child}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={true}
                    canChangeLinkState={true}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.TEXT &&
            <ul className={"ws-designer-toolbar-buttons"}>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarFontType
                            id={201}
                            action={WDToolbarAction.FONT_TYPE}
                            readonly={true}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonTextDecoration
                            id={301}
                            clickable={true}
                            colorPicker={false}
                            command={"bold"}
                            action={WDToolbarAction.BOLD}
                            icon={ImagePath.getButtonUrl() + "bold.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.bold))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonTextDecoration
                            id={302}
                            clickable={true}
                            colorPicker={false}
                            command={"italic"}
                            action={WDToolbarAction.ITALIC}
                            icon={ImagePath.getButtonUrl() + "italic.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.italic))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonColor
                            id={307}
                            clickable={true}
                            command={"foreColor"}
                            options={{
                                action: WDToolbarAction.FONT_COLOR,
                                open: this.state.activeId === 307,
                                width: 70,
                                height: 150
                            }}
                            tooltipPosition={TooltipPosition.above}
                            showColor={true}
                            transparency={false}
                            pressed={false}
                            initialColor={"#000000"}
                            action={WDToolbarAction.FONT_COLOR}
                            icon={ImagePath.getButtonUrl() + "font_color.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.fontColor))}
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                        />

                        <li key={"ws-designer-toolbar-button-operator"} id={"operator"}>
                            <WDToolbarButton
                                id={308}
                                arrow={false}
                                pressed={this.state.operatorMode}
                                enabled={true}
                                tooltip={operatorTooltip}
                                onToggleButton={() => this.onToggleOperator()}
                                icon={ImagePath.getButtonUrl() + "operator.svg"}
                            />
                        </li>
                    </div>
                </div>

                <WDToolbarButtonSeparator/>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonHelpLine
                            id={101}
                            options={{
                                action: WDToolbarAction.CELL_SUM,
                                open: this.state.activeId === 101,
                                width: 210,
                                height: 50
                            }}
                            clickable={true}
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                        />

                        <WDToolbarButtonInsertIcon
                            id={102}
                            iconImageKey={this.state.icon}
                            open={this.state.activeId === 102}
                            width={210}
                            height={80}
                            clickable={true}
                            onActionButton={this.onIconChange}
                            onToggleButton={this.onToggleButton}
                        />
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonReckonBracket
                            id={103}
                            options={{
                                action: WDToolbarAction.RECKON_BRACKET,
                                open: this.state.activeId === 103,
                                width: 210,
                                height: 50
                            }}
                            clickable={true}
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                        />

                        <WDToolbarButtonInsertGlyph
                            id={202}
                            open={this.state.activeId === 202}
                            clickable={true}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}
                        />
                    </div>
                </div>

                {/* Only in admin mode! */}
                {WSContext.showToolbarButtonMarkSolution(this.props.context) &&
                <>
                    <WDToolbarButtonSeparator/>

                    <div className={"ws-designer-toolbar-section"}>
                        <WDToolbarButtonSolution
                            id={602}
                            clickable={true}
                            icon={"solution_mark.svg"}
                            onActionButton={this.onToolbarAction}
                            action={WDToolbarAction.SOLUTION}
                        />
                    </div>
                </>
                }
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&

            <ul className={"ws-designer-toolbar-buttons"}>
                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row tooltip-above"}>
                        <li className={"ws-designer-toolbar-item tooltip-control"}>
                            <label className={"label tooltip-control"} style={{width: 14}}>
                                {this.context.translate(translations.toolbar.width_short)}:
                            </label>
                            <NumberInput width={80}
                                         clickable={this.props.context !== WSContextType.text_exercise_child}
                                         required={false}
                                         maxlength={2}
                                         minValue={5}
                                         maxValue={20}
                                         unit={"mm"}
                                         unitWidth={18}
                                         value={width}
                                         onChangeValue={value => this.onChangeCellWidth(width, height, value)}/>
                        </li>
                        <Tooltips
                            text={new TooltipText(
                                this.context.translate(translations.toolbar.cell_width),
                                this.context.translate(translations.tooltip.cell_width)
                            )}
                            translateX={-30} translateY={-38}
                        />
                    </div>
                    <div className={"ws-designer-toolbar-row tooltip-above"}>
                        <li className={"ws-designer-toolbar-item tooltip-control"}>
                            <label className={"label tooltip-control"} style={{width: 14}}>
                                {this.context.translate(translations.toolbar.height_short)}:
                            </label>
                            <NumberInput width={80}
                                         clickable={this.props.context !== WSContextType.text_exercise_child}
                                         required={false}
                                         maxlength={4}
                                         minValue={5}
                                         maxValue={20}
                                         unit={"mm"}
                                         unitWidth={18}
                                         value={height}
                                         onChangeValue={value => this.onChangeCellHeight(width, height, value)}/>
                        </li>
                        <Tooltips
                            text={new TooltipText(
                                this.context.translate(translations.toolbar.cell_height),
                                this.context.translate(translations.tooltip.cell_height)
                            )}
                            translateX={-30} translateY={-38}
                        />
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <WDToolbarLinkBracket
                        readonly={false}
                        linked={this.state.link}
                        linkBracketInfo={new LinkBracketInfo(true, true, false, false)}
                        onClickLink={this.onToggleLink}/>
                </div>

                <div className={"ws-designer-toolbar-button-separator"}/>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonInsertColumn
                            id={1}
                            pressed={false}
                            clickable={true}
                            position={WDColumnPosition.LEFT}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonInsertRow
                            id={2}
                            pressed={false}
                            clickable={true}
                            position={WDRowPosition.ABOVE}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonInsertColumn
                            id={3}
                            pressed={false}
                            clickable={true}
                            position={WDColumnPosition.RIGHT}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonInsertRow
                            id={4}
                            pressed={false}
                            clickable={true}
                            position={WDRowPosition.BELOW}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonDeleteColumn
                            id={5}
                            pressed={false}
                            clickable={deleteColumnEnabled}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonDeleteRow
                            id={6}
                            pressed={false}
                            clickable={deleteRowEnabled}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <WDToolbarButtonGraphics
                        id={7}
                        pressed={this.state.activeId === 7}
                        clickable={this.props.context !== WSContextType.text_exercise_child}
                        onToggleButton={this.onToggleButton}
                        onActionButton={this.props.onToolbarAction}

                        icon={this.getElementGraphic(lineType)}
                        iconButtonWidth={80}
                        iconButtonHeight={69}
                        iconImageWidth={60}
                        iconImageHeight={60}

                        submenuWidth={250}
                        submenuHeight={240}

                        imageCategory={[ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY]}
                        imageWidth={100}
                        imagePath={ImagePath.getLineatureUrl()}
                        tooltip={new TooltipText(this.context.translate(translations.toolbar.change_style), this.context.translate(translations.tooltip.change_style))}
                    />
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonColor
                            id={104}
                            clickable={true}
                            command={"lineColor"}
                            options={{
                                action: WDToolbarAction.LINE_COLOR,
                                open: this.state.activeId === 104,
                                width: 70,
                                height: 150
                            }}
                            tooltipPosition={TooltipPosition.above}
                            showColor={true}
                            transparency={false}
                            pressed={false}
                            initialColor={"transparent"}
                            action={WDToolbarAction.LINE_COLOR}
                            icon={ImagePath.getButtonUrl() + "frame_color_table.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.lineColor), this.context.translate(translations.tooltip.line_color))}
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                        />

                        <WDToolbarButtonColor
                            id={103}
                            clickable={true}
                            command={"fillColor"}
                            options={{
                                action: WDToolbarAction.CELL_FILL_COLOR,
                                open: this.state.activeId === 103,
                                width: 70,
                                height: 150
                            }}
                            tooltipPosition={TooltipPosition.above}
                            showColor={true}
                            transparency={true}
                            pressed={false}
                            initialColor={"transparent"}
                            action={WDToolbarAction.CELL_FILL_COLOR}
                            icon={ImagePath.getButtonUrl() + "fill_color.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.cell_fill_color), this.context.translate(translations.tooltip.cell_fill_color))}
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                        />
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonCellBorder
                            id={102}
                            borderKey={this.state.borderType}
                            open={this.state.activeId === 102}
                            width={240}
                            height={80}
                            clickable={true}
                            pressed={isCellBorderPressed}
                            onActionButton={this.onBorderTypeChange}
                            onToggleButton={this.onToggleButton}
                        />
                    </div>
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={false}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    elementProps={this.props.elementProps}
                    onChangeBorder={this.props.onChangeBorder}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onElementDeleted={this.props.onElementDeleted}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                />
            </ul>
            }
        </WDToolbar>
    }
}
