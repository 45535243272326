import React from 'react';
import {ColumnDefinition} from "../../Components/List/List";
import {Resource} from "../../Framework/Util";
import translations from "../../Framework/translations.json";
import {AdminObjectType, ColumnRenderOption, Status} from "../../Framework/Enums";
import ImageListItem from "./ImageListItem";
import Image, {ImageFilter, ImageFilterAdmin} from "../../_model/Image";
import {
    CloneImage,
    GetImageDetails,
    GetImageListAdmin,
    UpdateImageState
} from "../../_endpoint/ImageEndpoint";
import Conf from "../../Framework/Conf";
import {Notification} from "../../Components/Notification/NotificationHandler";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import User from "../../_model/User";
import Const from "../../Framework/Const";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {ImageListFilter} from "./ImageListFilter";
import ListSortSetting from "../../Components/List/ListSortSetting";
import {AdminDataListSortSettings} from "../Maintenance/AdminDataListSortSettings";
import {ListFilter} from "../../Components/List/ListFilter";
import AdminDataListBase, {AdminDataListBaseProps, AdminDataListBaseState} from "../Maintenance/AdminDataListBase";

interface IProps extends AdminDataListBaseProps {
}

interface IState extends AdminDataListBaseState {
    items: Image[]
    listItems?: ImageListItem[]
}

export default class ImageList extends AdminDataListBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        let sortValue = this.getCurrentSortValue()
        let searchValue = this.getCurrentSearchTerm()

        this.state = {
            page: 1,
            pageSize: 100,
            itemCount: 0,
            sortField: sortValue.sortString,
            sortDescending: sortValue.descending,
            search: searchValue,
            items: []
        }
    }

    componentDidMount() {
        this.context.getUserSettings().then((userSettings) => {
            let listPageSize = userSettings.listPageSize > 0 ? userSettings.listPageSize : 100

            this.setState({ pageSize: listPageSize }, () => this.fetchData(this.state.search))
        })
    }

    getCurrentSortValue = () => {
        let sortValue: AdminDataListSortSettings | undefined = this.context.getListSortValue(AdminObjectType.image)
        if (sortValue === undefined) {
            sortValue = new AdminDataListSortSettings(
                AdminObjectType.image,
                "modifiedOn",
                true
            )
        }

        return sortValue
    }
    getCurrentSearchTerm = () => {
        let filter: ListFilter | undefined = this.context.getListFilter(AdminObjectType.image)
        if (filter !== undefined) {
            return filter.searchString
        }
    }

    cloneItem = async(itemId: number) => {
        let image = await GetImageDetails(itemId)
        if (image && image.status !== Status.deactivated) {
            let clone: Image = {...image}
            clone.id = undefined
            clone.status = Status.draft

            await CloneImage(clone, clone.name + " - " + this.context.translate(translations.text_fragment.copy))
        }
    }

    retrieveData = (search: string | undefined) => {
        const filter = new ImageFilter()
        filter.search = search === "" ? undefined : search
        filter.alignment = ImageListFilter.all_alignments
        filter.property = ImageListFilter.all_properties
        filter.color = ImageListFilter.all_colors
        filter.exactMatch = false

        if (this.state.sortField) {
            filter.sort = new ListSortSetting(this.state.sortField, this.state.sortDescending ?? true)
        }

        filter.adminFilter = new ImageFilterAdmin()

        let listFilter: ListFilter | undefined = this.context.getListFilter(AdminObjectType.image)
        if (listFilter) {
            listFilter.filterValues.forEach((value, key) => {
                switch (value.filter.name) {
                    case "status":
                        switch (value.value) {
                            case -1:
                                filter.adminFilter!.status = [Status.published, Status.draft, Status.approval]
                                break
                            case 0:
                                filter.adminFilter!.status = [Status.draft]
                                break
                            case 1:
                                filter.adminFilter!.status = [Status.published]
                                break
                            case 2:
                                filter.adminFilter!.status = [Status.approval]
                                break
                            case 3:
                                filter.adminFilter!.status = [Status.deactivated]
                                break
                        }
                        break

                    case "owner":
                        if (value.value !== -1) {
                            filter.adminFilter!.ownerId = value.value
                        }
                        break

                    case "createdBy":
                        if (value.value !== -1) {
                            filter.adminFilter!.createdById = value.value
                        }
                        break

                }
            })
        }

        GetImageListAdmin(filter, this.state.page - 1, this.state.pageSize).then(
            (itemData) => {
                const listItems = itemData.content.map(item => {
                    let image = item as Image

                    // Status-Text (translated)
                    let status = translations.enum.status[image.status]

                    let color: Resource | null = null
                    if (image.color) {
                        color = translations.enum.image_color[image.color]
                    }

                    let owner = ""
                    if (image.ownerId) {
                        owner = image.ownerId.firstname + " " + image.ownerId.lastname
                    }

                    let createdBy = ""
                    if (image.createdBy) {
                        createdBy = image.createdBy.firstname + " " + image.createdBy.lastname
                    }

                    let tags = ""
                    if (image.tags) {
                        tags = image.tags
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(r => r.name).join(", ")
                    }

                    let family = ""
                    if (image.family) {
                        family = image.family.name
                    }

                    let collection = ""
                    if (image.collection) {
                        collection = image.collection.name
                    }

                    let thumbnailUrl = Image.getThumbnailUrl(image)
                    return new ImageListItem(
                        item.id!,
                        item.name,
                        this.context.translate(status),
                        owner,
                        image.modifiedOn || image.createdOn!,
                        color !== null ? this.context.translate(color) : "",
                        tags,
                        family,
                        collection,
                        createdBy,
                        (thumbnailUrl !== null && thumbnailUrl !== undefined) ? Conf.IMAGE_URL() + thumbnailUrl : undefined,
                        image.rating
                    )
                })

                this.setState({
                    items: itemData.content as Image[],
                    itemCount: itemData.totalElements,
                    listItems: listItems
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    onCopyItem = async (items: number[]) => {
        try {
            for (const id of items) {
                let image = await GetImageDetails(id)
                if (image && image.status !== Status.deactivated) {
                    let clone: Image = {...image}
                    clone.id = undefined
                    clone.status = Status.draft

                    await CloneImage(clone, clone.name + " - " + this.context.translate(translations.text_fragment.copy))
                }
            }

            this.fetchData(this.state.search)
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
        }
    }

    onSetState = async (item: number, status: Status, user?: User) => {
        try {
            const image = this.state.items.find(d => d.id === item)
            if (image) {
                Image.setStatus(image, status, user)
                await UpdateImageState(image)

                return true
            }
        } catch (e) {
        }

        return false
    }
    onRefresh = () => {
        this.fetchData(this.state.search)
    }
    onPageChange = (page: number) => {
        let pageCount = Math.ceil(this.state.itemCount / this.state.pageSize)

        if (page > 0 && page <= pageCount) {
            this.setState({page: page}, () => this.fetchData(this.state.search))
        }
    }
    onSearch = (search: string) => {
        let stateObj = {page: 1, search: search}
        if (this.state.search === undefined || this.state.search === "") {
            stateObj["sortField"] = "rating"
            stateObj["sortAscending"] = false
        }
        this.setState(stateObj, () => this.fetchData(search))
    }
    onSort = (fieldName: string, descending: boolean) => {
        this.setState({sortField: fieldName, sortDescending: descending}, () => this.fetchData(this.state.search))
    }
    onFilter = () => {
        this.setState({page: 1}, () => this.fetchData(this.state.search))
    }

    render() {
        let searched = this.state.search !== undefined && this.state.search !== ""

        let columnDefinition: ColumnDefinition[] = []
        columnDefinition.push(new ColumnDefinition(this.context.translate(translations.fields.image.image), "url", 7, false, false, ColumnRenderOption.Thumbnail))
        if (searched) {
            columnDefinition.push(new ColumnDefinition(this.context.translate(translations.fields.rating_list), "rating", 3, true, true))
        }
        columnDefinition = columnDefinition.concat([
            new ColumnDefinition(this.context.translate(translations.fields.name), "name", searched ? 14 : 15, true, true),
            new ColumnDefinition(this.context.translate(translations.fields.image.color), "colorProperties", 10, false, true),
            new ColumnDefinition(this.context.translate(translations.fields.image.family), "family", 10, false, true),
            new ColumnDefinition(this.context.translate(translations.fields.image.collection), "collection", searched ? 9 : 10, true, true),
            new ColumnDefinition(this.context.translate(translations.fields.image.searchTerms), "searchTerms", searched ? 14 : 15, true, false),
            new ColumnDefinition(this.context.translate(translations.fields.status), "status", 7, false, true),
            new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 9, false, true),
            new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 9, false, true),
            new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 8, false, true, ColumnRenderOption.DateTime)
        ])

        return <AdminDataList items={this.state.listItems}
                              definitions={columnDefinition}
                              newItemUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.image) + Const.CreateNewDataUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.image) + "{0}"}
                              objectType={AdminObjectType.image}
                              onCopyItem={this.onCopyItem}
                              onSetStatus={this.onSetState}
                              onRefresh={this.onRefresh}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              searchOptions={{
                                  showSearch: true,
                                  showSearchOptions: true,
                                  typeAheadSearch: false,
                                  onSearch: this.onSearch
                              }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: this.state.sortField || "modifiedOn",
                                  sortDescending: this.state.sortDescending === undefined ? true : this.state.sortDescending,
                                  onSort: this.onSort
                              }}
                              paginationOptions={{
                                  showPagination: true,
                                  externalPagination: true,
                                  itemCount: this.state.itemCount,
                                  currentPage: this.state.page,
                                  onPageChange: this.onPageChange
                              }}
                              filterOptions={{
                                  externalFilter: true,
                                  onFilterChange: this.onFilter
                              }}
                              showActivate={true}
                              showDeactivate={true}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}

