import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Action-Button in Toolbar
 * Effects selected element(s)
 * Click: Calculation is removed from the text exercise
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltipDisabled?: TooltipText
    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data?: any) => void
    onShowConfirmation?: (title: string, description: string, onSubmit: () => void) => void
}

export class WDToolbarButtonRemoveCalculation extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    showModal = () => {
        if (this.props.onShowConfirmation) {
            this.props.onShowConfirmation(
                this.context.translate(translations.text.remove_calculation_title),
                this.context.translate(translations.text.remove_calculation_description),
                this.onFormSubmit
            )
        }
        else {
            this.onFormSubmit()
        }
    }

    onFormSubmit = () => {
        this.props.onActionButton(WDToolbarAction.REMOVE_CALCULATION_PROCESS)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-solution-show"}
            id={"solutionShow"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getMathUrl() + "remove_calculation_process.svg"}
                             iconClassSelected={"ws-designer-toolbar-button-img"}
                             iconClassUnselected={"ws-designer-toolbar-button-img"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.section_calculation_remove), this.context.translate(translations.tooltip.section_calculation_remove))}
                             tooltipDisabled={this.props.tooltipDisabled}
                             onToggleButton={() => this.showModal()}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={false} />
        </li>
    }
}
