import React, {CSSProperties, FormEvent, PropsWithChildren} from "react";
import {ButtonInfo, ButtonList} from "./Controls/ButtonList";

export class MenuInfo {
    names: {id: string, value: string}[]
    selectedId: string
    onClick: (id: string) => void

    constructor(
        names: {id: string, value: string}[],
        selectedId: string,
        onClick: (id: string) => void,
    ) {
        this.names = names
        this.selectedId = selectedId
        this.onClick = onClick
    }
}

interface IProps extends PropsWithChildren {
    id: string
    contentAlignment: string
    dialogStyle: CSSProperties
    contentStyle?: CSSProperties

    onFormSubmit?: (event: React.FormEvent) => void
    onFormCancel?: () => void

    title?: string
    buttons?: ButtonInfo[]
    menu?: MenuInfo
}

interface IState {
    loading: boolean
}

export class Modal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)

        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown)
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(prevProps.buttons !== this.props.buttons) {
            this.setState({loading: false})
        }
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown)
    }

    onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            e.preventDefault()

            this.props.onFormCancel?.()
        }
    }
    onSubmit = (e: FormEvent) => {
        e.preventDefault()

        this.setState({loading: true}, () => {
            this.props.onFormSubmit?.(e)
        })
        return false
    }

    render() {
        let numberOfMenuItems = this.props.menu?.names.length
        let numberOfRenderedMenuItems = 0
        let classNameItem = "modal-dialog-menu-item"
        let classNameMenu = ""

        return <div className={"modal"}>
            <div className="modal-dialog-overlay" />
            <div className="modal-dialog" style={this.props.dialogStyle}>

                {this.props.title &&
                <div className={"modal-dialog-title modal-dialog-border-bottom"} style={{ paddingLeft: "20px" }}>
                    {this.props.title}
                </div>}

                <form id={"formModal" + this.props.id} onSubmit={this.onSubmit} className={"modal-form"}>
                    {this.props.menu &&
                    <div className={"modal-dialog-menu"}>
                        {this.props.menu.names.map(name => {
                            numberOfRenderedMenuItems++
                            classNameItem += (numberOfRenderedMenuItems !== numberOfMenuItems) ? " modal-dialog-menu-item-border" : ""
                            classNameMenu = (this.props.menu?.selectedId === name.id ? "modal-dialog-menu-item-row-selected" : "")

                            return <div className={classNameMenu} key={name.id}>
                                <div className={classNameItem} onClick={() => this.props.menu?.onClick(name.id)}>
                                    {name.value}
                                </div>
                            </div>
                        })}
                    </div>
                    }
                    <div className="modal-dialog-container" style={{alignItems: this.props.contentAlignment}}>
                        <div className={"modal-dialog-content"} style={this.props.contentStyle}>
                            {this.props.children}
                        </div>

                        {this.props.buttons && <ButtonList buttons={this.props.buttons}
                                                           buttonGroupStyle={{width: "100%"}}
                                                           containerStyle={{width: "100%", paddingRight: 10}}
                                                           loading={this.state.loading}
                        />}
                    </div>
                </form>

            </div>
        </div>
    }
}
