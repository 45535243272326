import Entity from "../../_model/Entity";
import {ChildStatus} from "../Exercise/TextExerciseListItem";

export class WritingCourseListItem extends Entity {
    status: string
    exerciseCount: ChildStatus
    characterType: string
    modifiedOn: Date
    createdBy: string
    owner: string

    constructor(id: number, name: string, status: string, exerciseCount: ChildStatus, characterType: string, owner: string, modifiedOn: Date, createdBy: string) {
        super(name, id);

        this.exerciseCount = exerciseCount
        this.characterType = characterType

        this.owner = owner
        this.modifiedOn = modifiedOn
        this.createdBy = createdBy
        this.status = status
    }
}

export default WritingCourseListItem
