import jwt_decode from 'jwt-decode';

export enum Role {
    ADMIN,
    USER
}

export default class Auth {

    public static isLoggedIn(): boolean {

        try {
            const tokenExpiration = this.getTokenExpirationDate()
            if (tokenExpiration === null) {
                return false
            }

            return tokenExpiration.valueOf() > new Date().valueOf();
        }
        catch (e) {
            return false
        }
    }

    public static isAdmin(): boolean {
        return this.getUserRole() === Role.ADMIN;
    }

    /**
     * Logout current user by removing the auth token
     */
    public static Logout() {
        localStorage.removeItem('authToken');
    }

    /**
     * Check if a valid JWT token is saved in the localStorage
     */
    public static getToken(): string {
        const token = localStorage.getItem('authToken');
        if (token === null) {
            throw new Error("Token not set");
        }

        return token
    }

    public static isTokenRefreshable(): boolean {
        const token = localStorage.getItem('authToken');
        if (token === null) {
            return false;
        }

        const tokenExpiration = this.getTokenExpirationDate()
        if (tokenExpiration === null) {
            return false
        }

        // 2 hours after expiration the token can be refreshed
        tokenExpiration.setHours(tokenExpiration.getHours() + 2);

        return tokenExpiration.valueOf() > new Date().valueOf()
    }

    public static hasToken(): boolean {
        const token = localStorage.getItem('authToken');
        return (token !== null)
    }

    /**
     * Sets the token with the answer of the authentication request
     */
    public static setToken(authResponse: string) {
        localStorage.setItem('authToken', authResponse);
    }

    public static getUserRole(): Role {
        const decoded: any = jwt_decode(this.getToken());
        const authInfo: string[] = decoded.rol;

        if (authInfo.includes('ROLE_ADMIN')) {
            return Role.ADMIN;
        }

        return Role.USER;
    }
    public static getUserId() {
        const decoded: any = jwt_decode(this.getToken());
        const authInfo: string = decoded.sub;

        if (authInfo.length > 0) {
            return +authInfo
        }

        return 0
    }

    public static getTokenExpirationDate(): Date | null {

        const decoded: any = jwt_decode(this.getToken())
        if (decoded.exp === undefined) {
            return null
        }

        const date = new Date(0)
        date.setUTCSeconds(decoded.exp)
        return date
    }
}
