import {WorksheetPage} from "../../_model/WorksheetPage";

export class WorksheetPageHistory {
    pageKey: string
    before?: Partial<WorksheetPage>
    after?: Partial<WorksheetPage>

    constructor(pageKey: string, before?: Partial<WorksheetPage>, after?: Partial<WorksheetPage>) {
        this.pageKey = pageKey
        this.before = before
        this.after = after
    }
}
