import React from "react";
import {MainContext} from "../../../_base/MainContext";
import {SyllableDefinition, WDSyllableWord} from "../../Elements/Textbox/WDSyllableWord";
import {WordDefinition} from "../../../_model/Dictionary/Word";

interface IProps {
    wordDefinition: WordDefinition
    syllableWord: string
    syllableDefinition: SyllableDefinition
    details1?: string
    details2?: string
    details3?: string
    links?: string
}
interface IState {
    details?: string
    word: string
}

export class SidebarDictionaryListEntry extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            details: this.mergeDetails(),
            word: this.props.syllableDefinition ? this.props.syllableWord : this.props.syllableWord.replaceAll("/","")
        }
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(prevProps.syllableWord !== this.props.syllableWord || prevProps.syllableDefinition !== this.props.syllableDefinition) {
            this.setState({word: this.props.syllableDefinition ? this.props.syllableWord : this.props.syllableWord.replaceAll("/","")})
        }
    }

    mergeDetails = () => {
        let details = ""
        details = details.concat((this.props.details1 !== null && this.props.details1 !== "") ? ", " + this.props.details1 : "")

        if(this.props.details2 !== null && this.props.details2 !== "") {
            details = details.concat(", ")
            if(this.props.wordDefinition === WordDefinition.adjective) {
                details = details.concat("am ")
            }

            details = details.concat(this.props.details2!)
        }

        details = details.concat((this.props.details3 !== null && this.props.details3 !== "") ? ", " + this.props.details3 : "")

        return details
    }

    render() {
        return <div className={"ws-designer-sidebar-dictionary-list-entry"}>
            <div className={"ws-designer-sidebar-dictionary-list-entry-word"}>
                <div className={"ws-designer-sidebar-dictionary-list-entry-word-name"}>
                    <WDSyllableWord
                        syllableDefinition={this.props.syllableDefinition}
                        syllableWord={this.state.word}/>
                </div>
                <div className={"ws-designer-sidebar-dictionary-list-entry-word-details"}>
                    {this.state.details}
                </div>
            </div>
            {this.props.links !== undefined && this.props.links !== null &&
                <div className={"ws-designer-sidebar-dictionary-list-entry-word-link"}>
                    {this.props.links}
                </div>
            }
        </div>
    }
}
