import {MainContext} from "../../_base/MainContext";
import React from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";

interface IProps {
    title: string
    getValue: () => Promise<string>
}
interface IState {
    value?: string
}

export default class KPI extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
        }
    }

    componentDidMount() {
        this.props.getValue().then(value => {
            this.setState({value: value})
        }, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })
    }

    renderValue = () => {
        if (this.state && this.state.value) {
            return <div>{this.state.value}</div>
        }
        return <img width={50}
                    className={"svg-color-primary"}
                    src={process.env.PUBLIC_URL + ImagePath.getNotificationUrl() + "loading.gif"}
                    alt={"Loading"}
                    draggable={"false"}
                    onContextMenu={(e) => e.preventDefault() }
        />
    }

    render() {
        return <div className={"kpi-item"}>
            <div className={"kpi-item-value"}>
                {this.renderValue()}
            </div>
            <div className={"kpi-item-title"}>{this.props.title}</div>
        </div>
    }
}

