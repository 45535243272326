import React from 'react';
import DualListBox from 'react-dual-listbox';
import translations from "../../Framework/translations.json";
import {ImagePath} from "../../Framework/CategoryImage";
import {MainContext} from "../../_base/MainContext";

interface IProps {
    options: any[]
    selected: any[]
    width: number
    marginTop: number
    marginLeft: number
    label: string
    disabled: boolean
    onChange: (selected: any) => void
}

export default class DualList extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(this.props.selected === prevProps.selected && this.props.options === prevProps.options) {
            return
        }
    }

    render() {
        return <div className="form-row" style={{marginLeft: this.props.marginLeft}}>
            <div className="form-group" style={{width: this.props.width, marginTop: this.props.marginTop}}>
                <label className="bold-label" htmlFor={"dlbLanguages"}>{this.props.label}
                    <DualListBox
                        id={"dblLanguages"}
                        className="dual-list-box"
                        options={this.props.options}
                        onChange={this.props.onChange}
                        selected={this.props.selected}
                        disabled={this.props.disabled}
                        showNoOptionsText
                        canFilter
                        filterPlaceholder = {this.context.translate(translations.command.search)}
                        icons={{
                            moveLeft: <input type="image"
                                             className = "dual-list-box-icon"
                                             src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_previous.svg"}
                                             alt={this.context.translate(translations.command.first)} />,
                            moveAllLeft: <input type="image"
                                                className = "dual-list-box-icon"
                                                src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_first.svg"}
                                                alt={this.context.translate(translations.command.prev)} />,
                            moveRight: <input type="image"
                                              className = "dual-list-box-icon"
                                              src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_next.svg"}
                                              alt={this.context.translate(translations.command.next)} />,
                            moveAllRight: <input type="image"
                                                 className = "dual-list-box-icon"
                                                 src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_last.svg"}
                                                 alt={this.context.translate(translations.command.last)}/>,
                        }}/>
                </label>
            </div>
        </div>
    }

}
