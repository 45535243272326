import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Triggers converting process of the textelement - should be lineature afterwards
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean

    onActionButton: (action: WDToolbarAction) => void
    onToggleButton: (id: number) => void
    onShowConfirmation?: (title: string, description: string, onSubmit: () => void) => void
}

interface IState { }

export class WDToolbarButtonConvertToLineText extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    showModal = () => {
        if (this.props.onShowConfirmation) {
            this.props.onShowConfirmation(
                this.context.translate(translations.text.lineature_conversion_title),
                this.context.translate(translations.text.lineature_conversion_description),
                this.onFormSubmit
            )
        }
        else {
            this.onFormSubmit()
        }
    }

    onFormSubmit = () => {
        this.props.onActionButton(WDToolbarAction.CONVERT_TO_LINE)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-convert-line"}
            id={"convertLine"}>

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + "convert_line.svg"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.convertToLineature))}
                             arrow={false}
                             onToggleButton={() => this.showModal()}
                             pressed={this.props.pressed} />
        </li>
    }
}
