import React from "react";
import {Menu, MenuContext, MenuType} from "../../Components/Menu/Menu";
import AppHeader from "../../_base/AppHeader";
import ApprovalList from "../Approval/ApprovalList";
import {GetImageList} from "../../_endpoint/ImageEndpoint";
import translations from "../../Framework/translations.json";
import KPI from "../../Components/Controls/KPI";
import {Status} from "../../Framework/Enums";
import Auth from "../../Framework/Auth";
import Image from "../../_model/Image";
import {MainContext} from "../../_base/MainContext";
import {EntityData} from "../../_model/Entity";
import SearchResultDashboardList from "../SearchResult/SearchResultDashboardList";
import {RouteComponentProps} from "react-router-dom";
import {GetBackendVersion} from "../../_endpoint/MetadataEndpoint";
import {Version} from "../../_model/Version";
import {FE_VERSION} from "../../version";

interface IProps extends RouteComponentProps {
}

interface IState {
    imagesPromise: Promise<Image[]>
    backendVersion?: string
}

export class AdminDashboard extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            imagesPromise: GetImageList()
        }
    }

    componentDidMount() {
        GetBackendVersion().then((version: Version) => {
            this.setState({backendVersion: version.version})
        })
    }

    getRecordsByStatus = (promise: Promise<EntityData[]>, status: Status) => {
        return new Promise<string>((resolve, reject) => {
            promise.then((records) => {
                let value = records.filter(i => i.status === status && i.createdBy?.id === Auth.getUserId()).length.toString()
                resolve(value)
            }, (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                reject(error)
            })
        })
    }

    render() {
        return <div className="admin-dashboard">

                <AppHeader isAdminArea={true}/>
                <div className="admin-dashboard-main">
                    <div className="menu-container">
                        <Menu menuType={MenuType.app} menuContext={MenuContext.admin}/>
                    </div>

                    <div className={"admin-dashboard-content"}>
                        <h1>{this.context.translate(translations.text.welcome)}</h1>
                        <div className={"admin-dashboard-version"}>frontend: {FE_VERSION} / backend: {this.state.backendVersion}</div>

                        <div className={"admin-dashboard-items"}>
                            <div className="admin-dashboard-item admin-dashboard-item-list"
                                 style={{width: 1200, height: 700}}>
                                <ApprovalList
                                    history={this.props.history}
                                    location={this.props.location}
                                    match={this.props.match}
                                />
                            </div>

                            <div className="admin-dashboard-item admin-dashboard-item-kpis"
                                 style={{width: 200, height: 700}}>
                                <h2>{this.context.translate(translations.text.my_images)}</h2>

                                <KPI title={this.context.translate(translations.enum.status.published)}
                                     getValue={() => this.getRecordsByStatus(this.state.imagesPromise, Status.published)}/>
                                <KPI title={this.context.translate(translations.text.approval)}
                                     getValue={() => this.getRecordsByStatus(this.state.imagesPromise, Status.approval)}/>
                                <KPI title={this.context.translate(translations.enum.status.draft)}
                                     getValue={() => this.getRecordsByStatus(this.state.imagesPromise, Status.draft)}/>
                                <KPI title={this.context.translate(translations.enum.status.deactivated)}
                                     getValue={() => this.getRecordsByStatus(this.state.imagesPromise, Status.deactivated)}/>
                            </div>

                            <div className="admin-dashboard-item admin-dashboard-item-list"
                                 style={{width: 1200, height: 700}}>
                                <SearchResultDashboardList
                                    history={this.props.history}
                                    location={this.props.location}
                                    match={this.props.match}
                                />
                            </div>
                        </div>
                    </div>
                </div>
        </div>

    }
}
