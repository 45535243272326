import React from "react";
import {MainContext} from "../../../_base/MainContext";
import {SearchInput} from "../../../Components/Controls/SearchInput";

export interface IProps {
    onChangeSearch: (search: string) => void
}
export interface IState {
}

export class WDWritingCourseToolbarSearch extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        return <div className={"ws-designer-toolbar-button-filter"}>
                <SearchInput
                    typeAheadSearch={true}
                    showFieldOptions={false}
                    maxLength={5}
                    width={290}
                    onSearch={this.props.onChangeSearch}/>
        </div>
    }
}
