import {Worksheet, WorksheetMarketplaceFilter} from "../_model/Worksheet";
import API from "../Framework/API";
import NameConfigElement from "../_model/NameConfigElement";
import {WorksheetItemType} from "../_model/WorksheetItemType";
import {WSContextType} from "../Designer/Elements/WSContext";
import Auth from "../Framework/Auth";
import Conf from "../Framework/Conf";
import {WorksheetLog} from "../_model/WorksheetLog";
import {WorksheetRating} from "../_model/WorksheetRating";
import {WorksheetPage} from "../_model/WorksheetPage";
import {WorksheetItem} from "../_model/WorksheetItem";
import {NotFoundError} from "../Framework/Error/NotFoundError";
import {PrintSolutionMode} from "../Framework/Enums";
import {WDUtils} from "../Designer/Utils/WDUtils";

const URL = "worksheet"

export function GetUserWorksheetList() {
    return API.Get<Worksheet[]>(URL)
}
export function GetWorksheetListDashboard() {
    return API.Get<Worksheet[]>(URL + "/dashboard")
}
export function GetMPWorksheetListAdmin() {
    return API.Get<Worksheet[]>(URL + "/marketplace/admin")
}
export function GetMPWorksheetList(filter: WorksheetMarketplaceFilter, page?: number, size?: number) {
    let url = URL + "/marketplace/filter"

    let params = ""
    if (page !== null) {
        params = "?page=" + page
    }
    if (size) {
        params += ((params === "") ? "?" : "&") + "size=" + size
    }

    return API.Post<WorksheetMarketplaceFilter, Worksheet[]>(url + params, filter)
}
export function DownloadMPWorksheet(worksheetId: number) {
    return API.Post<object, Worksheet>(URL + "/" + worksheetId + "/marketplace/download/", {})
}
export function PublishMPWorksheet(worksheetId: number) {
    return API.Post<object, Worksheet>(URL + "/" + worksheetId + "/marketplace/publish/", {})
}
export function RejectMPWorksheet(worksheetId: number) {
    return API.Post<object, Worksheet>(URL + "/" + worksheetId + "/marketplace/reject/", {})
}

export function GetWorksheetById(worksheetId: number) {
    return API.Get<Worksheet>(URL + "/" + worksheetId)
}
export function GetWorksheetLogsById(worksheetId: number) {
    return API.Get<WorksheetLog[]>(URL + "/" + worksheetId + "/logs")
}
export function CreateWorksheet(worksheet: Worksheet) {
    return API.Post<object, Worksheet>(URL, worksheet)
}
export async function CloneWorksheet(worksheetId: number, name: string, context: WSContextType) {
    return API.Post<object, Worksheet>(URL + "/" + worksheetId + "/clone", {name: name, context: context})
}
export async function DeleteWorksheet(worksheetId: number) {
    return API.Delete<Worksheet>(URL + "/" + worksheetId)
}

export async function GetPrintableWorksheet(worksheet: Worksheet, solutionMode: PrintSolutionMode) {
    const left = 0
    const right = 0
    const top = 0
    const bottom = 0
    const orientation = (worksheet.pages && worksheet.pages.length > 0) ? WDUtils.getPagesSorted(worksheet.pages)[0].orientation : worksheet.orientation

    const itemData = {
        solution: solutionMode,
        orientation: orientation,
        format: worksheet.format,
        margin: {
            left: left,
            right: right,
            top: top,
            bottom: bottom
        }
    }

    const url = Conf.PRINT_SERVICE_URL() + "worksheet/" + worksheet.id
    const response = await fetch(url, {
        headers: {
            'Authorization': Auth.getToken(),
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        method: "POST",
        body: JSON.stringify(itemData)
    })

    await API.checkReturnValue(response)

    return response
}

export function SetWorksheetStatus(worksheet: Worksheet) {
    const itemData = {
        id: worksheet.id,
        status: worksheet.status,
    }

    return API.Put<object, Worksheet>(URL + "/" + worksheet.id, itemData)
}

export function GetMarketplaceWorksheetById(worksheetId: number) {
    return API.Get<Worksheet>(URL + "/" + worksheetId + "/marketplace/")
}
export function UpdateWorksheet(worksheet: Worksheet) {
    let orientation = worksheet.pages && worksheet.pages.length > 0 ? WDUtils.getPagesSorted(worksheet.pages)[0].orientation : worksheet.orientation

    const itemData = {
        id: worksheet.id,
        name: worksheet.name,
        description: worksheet.description,
        difficulty: worksheet.difficulty,
        subject: worksheet.subject,
        language: worksheet.language,
        format: worksheet.format,
        orientation: orientation,
        schoolLevels: worksheet.schoolLevels,
        blackAndWhite: worksheet.blackWhite,
        status: worksheet.status,
        isEditingAllowed: worksheet.editingAllowed,
        marketplaceStatus: worksheet.marketplaceStatus,
        marketplaceShowName: worksheet.marketplaceShowName,
        marketplaceShowEmail: worksheet.marketplaceShowEmail,
        marketplaceIsEditingAllowed: worksheet.marketplaceIsEditingAllowed,
        marketplaceTargetSchool: worksheet.marketplaceTargetSchool,
        marketplaceTargetPupils: worksheet.marketplaceTargetPupils,
        marketplaceTargetTeacher: worksheet.marketplaceTargetTeacher,
        marketplaceTargetParents: worksheet.marketplaceTargetParents,
        marketplaceKeywords: worksheet.marketplaceKeywords,
        marketplaceAdvertisement: worksheet.marketplaceAdvertisement,
        tags: worksheet.tags
    }

    return API.Put<object, Worksheet>(URL + "/" + worksheet.id, itemData)
}
export function UpdateMarketplaceWorksheet(worksheet: Worksheet) {
    const itemData = {
        id: worksheet.id,
        marketplaceShowName: worksheet.marketplaceShowName,
        marketplaceShowEmail: worksheet.marketplaceShowEmail,
        marketplaceStatus: worksheet.marketplaceStatus,
        tags: worksheet.tags
    }

    return API.Put<object, Worksheet>(URL + "/" + worksheet.id, itemData)
}

export function CreateWorksheetPage(page: WorksheetPage, worksheetId: number) {
    return API.Post<object, WorksheetPage>(URL + "/" + worksheetId + "/page/", page)
}
export function UpdateWorksheetPage(page: WorksheetPage, worksheetId: number) {
    return API.Put<object, WorksheetPage>(URL + "/" + worksheetId + "/page/" + page.id, page)
}
export function DeleteWorksheetPage(pageId: number, worksheetId: number) {
    return API.Delete<WorksheetPage>(URL + "/" + worksheetId + "/page/" + pageId)
}

export function GetWorksheetItems(worksheetId: number, pageId: number) {
    return API.Get<WorksheetItem[]>(URL + "/" + worksheetId + "/page/" + pageId + "/item")
}
export function GetWorksheetItemsOfFirstPage(worksheetId: number) {
    return API.Get<WorksheetItem[]>(URL + "/" + worksheetId + "/page/first/item")
}
export async function SaveWorksheetItem(item: WorksheetItem, worksheetId: number, pageId: number) {
    const itemData = {
        itemKey: item.itemKey,
        worksheetItemTypeId: item.worksheetItemTypeId,
        posX: item.posX,
        posY: item.posY,
        posZ: item.posZ,
        width: item.width,
        height: item.height,
        content: item.content,
        rotation: item.rotation,
        flipHorizontal: item.flipHorizontal,
        flipVertical: item.flipVertical,
        fillColor: item.fillColor,
        borderStyle: item.borderStyle,
        borderColor: item.borderColor,
        borderWeight: item.borderWeight,
        paddingTop: item.paddingTop,
        paddingRight: item.paddingRight,
        paddingBottom: item.paddingBottom,
        paddingLeft: item.paddingLeft,
        transparency: item.transparency,
        skew: item.skew,
        locked: item.locked,
        groupId: item.groupId ? item.groupId : null,
        linkedWorksheetItemId: item.linkedWorksheetItemId ? item.linkedWorksheetItemId : null,
        sourceRecordId: item.sourceRecordId
    }

    let url = URL + "/" + worksheetId + "/page/" + pageId + "/item"
    if (item.id && item.id > 0) {
        url += "/" + item.id
        return await API.Put<object, WorksheetItem>(url, itemData)
    }
    else {
        return await API.Post<object, WorksheetItem>(url, itemData)
    }
}
export async function DeleteWorksheetItem(worksheetId: number, pageId: number, itemId: number) {
    try {
        return await API.Delete<object>(URL + "/" + worksheetId + "/page/" + pageId + "/item/" + itemId)
    }
    catch (e) {
        if (e instanceof NotFoundError) {
            console.error(e)
        }
        else {
            throw e
        }
    }
}

export function GetWorksheetItemTypes() {
    return API.Get<WorksheetItemType[]>(URL + "/item-types")
}

export function GetAllNameConfigElements(worksheetId: number) {
    let url = URL + "/" + worksheetId + "/name-config-element/"
    return API.Get<NameConfigElement[]>(url)
}
export function MergeNameConfigElements(worksheetId: number, pageId: number, worksheetItemId: number, nameConfigElement: number[]) {
    let url = URL + "/" + worksheetId + "/page/" + pageId + "/item/" + worksheetItemId + "/name-config-element"
    return API.Post<number[], NameConfigElement[]>(url, nameConfigElement)
}

export function GetWorksheetThumbnail(worksheetId: number) {
    return API.Get<Worksheet>(URL + "/" + worksheetId + "/thumbnail/")
}
export function SaveWorksheetThumbnail(worksheetId: number, thumbnail: string) {
    return API.Post<object, Worksheet>(URL + "/" + worksheetId + "/thumbnail/", {id: worksheetId, thumbnail: thumbnail})
}

export function GetWorksheetRatings(worksheetId: number) {
    return API.Get<WorksheetRating[]>(URL + "/" + worksheetId + "/ratings/")
}
export function CreateWorksheetRating(worksheetId: number, rating: WorksheetRating) {
    let newRating = {
        rating: rating.rating,
        showName: rating.showName,
        description: rating.description
    }

    return API.Post<object, WorksheetRating>(URL + "/" + worksheetId + "/ratings/", newRating)
}
export function RejectWorksheetRating(worksheetId: number) {
    return API.Post<object, Worksheet>(URL + "/" + worksheetId + "/ratings/reject", {})
}
