import Entity from "../../_model/Entity";

export class RuleListItem extends Entity {
    status: string
    lessonSubject: string
    ruleType: string
    ruleCategory: string
    schoolLevel: string
    modifiedOn: Date
    owner: string
    createdBy: string

    constructor(id: number, name: string, status: string, owner: string, modifiedOn: Date, ruleType: string,
                lessonSubject: string, schoolLevel: string, ruleCategory: string, createdBy: string) {

        super(name, id);

        this.lessonSubject = lessonSubject
        this.ruleType = ruleType
        this.ruleCategory = ruleCategory
        this.schoolLevel = schoolLevel

        this.owner = owner
        this.modifiedOn = modifiedOn
        this.createdBy = createdBy
        this.status = status
    }
}

export default RuleListItem
