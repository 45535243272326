import React from "react";
import {Dialog} from "./Dialog";
import {NotificationStatus} from "../../Framework/Enums";
import {ValidationError} from "../../Framework/Error/ValidationError";
import {SecurityError} from "../../Framework/Error/SecurityError";
import translations from "../../Framework/translations.json";
import {Resource, Util} from "../../Framework/Util";
import {ServerNotReachableError} from "../../Framework/Error/ServerNotReachableError";
import {AuthError} from "../../Framework/Error/AuthError";
import {SystemError} from "../../Framework/Error/SystemError";

export class Notification {
    id: string
    title?: string
    message: string
    status: NotificationStatus
    notificationText?: string

    constructor(message: string, status: NotificationStatus, notificationText?: string, title?: string) {
        this.id = "notify-" + status + "-" + Date.now()
        this.title = title
        this.message = message
        this.status = status
        this.notificationText = notificationText
    }

    static handleError = (error: Error, notification: string, translate:(r: Resource) => string, title?: string): Notification => {
        let notifyText = notification
        if (error instanceof ValidationError || error instanceof AuthError) {
            notifyText = (error === undefined ? "unknown error" : error.message)
        }
        else if (error instanceof SecurityError) {
            notifyText = translate(translations.error.not_allowed)
        }
        else if (error instanceof ServerNotReachableError) {
            notifyText = translate(translations.error.server_not_reachable)
        }
        else if (error instanceof SystemError) {
            notifyText = notification //translate(translations.error.unexpected_error)
        }

        return new Notification(
            (error === undefined ? "unknown error" : error.message),
            NotificationStatus.error,
            notifyText,
            title
        )
    }

    static handleSuccess = (notification: string, title?: string): Notification => {
        return new Notification(
            "",
            NotificationStatus.ok,
            notification,
            title
        )
    }

    static handleLoading = (notification: string): Notification => {
        return new Notification(
            "",
            NotificationStatus.loading,
            notification
        )
    }

    static handleInfo = (text: string, title?: string): Notification => {
        return new Notification(
            "",
            NotificationStatus.info,
            text,
            title
        )
    }

    /**
     * Returns the duration of showing the success notification in ms
     */
    static getSuccessNotificationDuration = (): number => {
        return 2000
    }

    /**
     * Returns the duration of showing the information notification in ms
     */
    static getInformationNotificationDuration = (): number => {
        return 6500
    }

    /**
     * Returns the duration of showing the error notification in ms
     */
    static getErrorNotificationDuration = (): number => {
        return 10000
    }
}

interface IProps {
    id: string
    notification: Notification
    removeNotification: (notificationId: string) => void
    sendErrorReport?: (message: string) => void
}

interface IState {
    shouldRemoveNotification?: boolean
}

/**
 * Notification handler if error, success or info happens (from methods in backend)
 */
export class NotificationHandler extends React.Component<IProps, IState> {
    timeOutID: any = undefined

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {}
    }

    componentDidMount() {
        this.defineRemovalDelay()
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(this.props.notification !== prevProps.notification) {
            this.defineRemovalDelay()
        }
    }

    defineRemovalDelay = () => {
        let shouldRemove = this.props.notification.status !== NotificationStatus.loading

        this.setState({shouldRemoveNotification: shouldRemove}, () => {
            switch (this.props.notification.status) {
                case NotificationStatus.ok:
                    this.removeNotification(Notification.getSuccessNotificationDuration())
                    break

                case NotificationStatus.info:
                    this.removeNotification(Notification.getInformationNotificationDuration())
                    break

                case NotificationStatus.error:
                    this.removeNotification(Notification.getErrorNotificationDuration())
                    break
            }
        })
    }

    removeNotification = (time?: number) => {
        if(time && this.timeOutID === undefined) {
            this.timeOutID = setTimeout(() => {
                this.state.shouldRemoveNotification && this.props.removeNotification(this.props.id)
            },time)

        } else {
            this.props.removeNotification(this.props.id)
        }
    }
    onClickRemoveNotification = (event: MouseEvent) => {
        if (Util.isChildOfClass(event.target as HTMLElement, "notification")) {
            return
        }

        event.preventDefault();
        event.stopPropagation();

        if(!this.state.shouldRemoveNotification) {
            this.setState({shouldRemoveNotification: true})
        }

        this.props.removeNotification(this.props.id)
        this.removeResizeEventListener()
    }
    stayActive = () => {
        if(this.state.shouldRemoveNotification) {
            this.setState({shouldRemoveNotification: false})
        }

        if(this.timeOutID) {
            clearTimeout(this.timeOutID -1)
            this.timeOutID = undefined
        }
        this.addEventListener()
    }

    addEventListener = () => {
        window.addEventListener('mouseup', this.onClickRemoveNotification, false);
    }
    removeResizeEventListener = () => {
        window.removeEventListener('mouseup', this.onClickRemoveNotification, false);
    }

    render() {
        if(this.props.notification.status === NotificationStatus.error && this.props.notification.notificationText) {
            console.error(this.props.notification.message)
            return <Dialog id={this.props.id} notificationType={NotificationStatus.error}
                           title={this.props.notification.title}
                           message={this.props.notification.notificationText}
                           onClickStayActive={this.stayActive}
                           onClickCancel={() => this.props.removeNotification(this.props.id)}
                           onClickSendErrorReport={this.props.sendErrorReport ? (message: string) => this.props.sendErrorReport?.(message): undefined}
                           notificationDelay={this.state.shouldRemoveNotification ? 5000 : undefined}/>

        } else if(this.props.notification.status === NotificationStatus.loading && this.props.notification.notificationText) {
                return <Dialog id={this.props.id} notificationType={NotificationStatus.loading}
                               title={this.props.notification.title}
                               message={this.props.notification.notificationText}
                               notificationDelay={undefined}/>

        } else if(this.props.notification.status === NotificationStatus.ok && this.props.notification.notificationText) {
            return <Dialog id={this.props.id} notificationType={NotificationStatus.ok}
                           title={this.props.notification.title}
                           message={this.props.notification.notificationText}
                           notificationDelay={this.state.shouldRemoveNotification ? 500 : undefined}/>

        } else if(this.props.notification.status === NotificationStatus.info && this.props.notification.notificationText) {
            return <Dialog id={this.props.id} notificationType={NotificationStatus.info}
                           title={this.props.notification.title}
                           message={this.props.notification.notificationText}
                           onClickStayActive={this.stayActive}
                           onClickCancel={() => this.props.removeNotification(this.props.id)}
                           notificationDelay={this.state.shouldRemoveNotification ? 5000 : undefined}/>
        }
    }
}
