export enum ProperNameTypeEnum {
    continent = "continent",
    country = "country",
    state = "state",
    city = "city",
    sea = "sea",
    lake = "lake",
    river = "river",
    name = "name",
    salutation = "salutation",
    polite_phrase = "polite_phrase"
}

export class ProperName {
    wordId?: number
    syllabification?: string
    properNameType?: string

    toString = () : string => {
        return "- Proper name"
    }
}

export default ProperName
