import {WorksheetItem} from "../../_model/WorksheetItem";

export class WorksheetItemHistory {
    itemKey: string
    before?: Partial<WorksheetItem>
    after?: Partial<WorksheetItem>

    constructor(itemKey: string, before?: Partial<WorksheetItem>, after?: Partial<WorksheetItem>) {
        this.itemKey = itemKey
        this.before = before
        this.after = after
    }
}
