import WordLookup from "./WordLookup";
import AdjectiveUseCases from "./AdjectiveUseCases";

export class Adjective {
    wordId?: number
    syllabificationSingular?: string
    irregular?: boolean = false
    basicForm?: string
    comparative?: string
    superlative?: string

    declensionStrongMasculineFirst?: string
    declensionStrongMasculineSecond?: string
    declensionStrongMasculineThird?: string
    declensionStrongMasculineFourth?: string

    declensionStrongFeminineFirst?: string
    declensionStrongFeminineSecond?: string
    declensionStrongFeminineThird?: string
    declensionStrongFeminineFourth?: string

    declensionStrongNeutralFirst?: string
    declensionStrongNeutralSecond?: string
    declensionStrongNeutralThird?: string
    declensionStrongNeutralFourth?: string

    declensionStrongPluralFirst?: string
    declensionStrongPluralSecond?: string
    declensionStrongPluralThird?: string
    declensionStrongPluralFourth?: string

    declensionWeakMasculineFirst?: string
    declensionWeakMasculineSecond?: string
    declensionWeakMasculineThird?: string
    declensionWeakMasculineFourth?: string

    declensionWeakFeminineFirst?: string
    declensionWeakFeminineSecond?: string
    declensionWeakFeminineThird?: string
    declensionWeakFeminineFourth?: string

    declensionWeakNeutralFirst?: string
    declensionWeakNeutralSecond?: string
    declensionWeakNeutralThird?: string
    declensionWeakNeutralFourth?: string

    declensionWeakPluralFirst?: string
    declensionWeakPluralSecond?: string
    declensionWeakPluralThird?: string
    declensionWeakPluralFourth?: string

    declensionMixedMasculineFirst?: string
    declensionMixedMasculineSecond?: string
    declensionMixedMasculineThird?: string
    declensionMixedMasculineFourth?: string

    declensionMixedFeminineFirst?: string
    declensionMixedFeminineSecond?: string
    declensionMixedFeminineThird?: string
    declensionMixedFeminineFourth?: string

    declensionMixedNeutralFirst?: string
    declensionMixedNeutralSecond?: string
    declensionMixedNeutralThird?: string
    declensionMixedNeutralFourth?: string

    declensionMixedPluralFirst?: string
    declensionMixedPluralSecond?: string
    declensionMixedPluralThird?: string
    declensionMixedPluralFourth?: string

    adjectiveUseCases?: AdjectiveUseCases[]
    opposites?: WordLookup[]

    toString = () : string => {
        return "- Adjektiv"
    }
}

export default Adjective
