import React from "react";
import {MainContext} from "../../_base/MainContext";
import Converter from "../../Framework/Converter";
import {NumberInput} from "../Controls/NumberInput";
import translations from "../../Framework/translations.json";
import Const from "../../Framework/Const";
import {LinkBracketInfo, WDToolbarLinkBracket} from "../../Designer/Toolbar/General/WDToolbarLinkBracket";
import {Tooltips, TooltipText} from "../Tooltips";

/**
 * Effects page border
 * Includes the distance to the page end in each direction (top, right, left, bottom)
 * Change Input:   The distance changes (for each or separately depending on toggle button)
 * Toggle Button:  Open - distances can be changes separately, Closed - all distances are effected by the change
 */

export enum PageBorderDirection {
    left,
    top,
    right,
    bottom
}

export class PageBorderPosition {
    left: number
    top: number
    right: number
    bottom: number
    link: boolean

    constructor(left: number, top: number, right: number, bottom: number, link: boolean) {
        this.left = left
        this.top = top
        this.right = right
        this.bottom = bottom
        this.link = link
    }

    static getNoPageBorder(): PageBorderPosition {
        return new PageBorderPosition(0, 0, 0, 0, true)
    }
    static getDefaultPageBorder(): PageBorderPosition {
        const defaultBorder = Converter.mmToPx(Const.WorksheetDesignBorderMM)
        return new PageBorderPosition(defaultBorder, defaultBorder, defaultBorder, defaultBorder, true)
    }
}

interface IProps {
    border: PageBorderPosition
    linkReadOnly: boolean
    smallRendering: boolean

    readonly?: boolean
    onChangeBorder: (border: PageBorderPosition) => void
}

export class WDPageBorder extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onClickLink = () => {
        this.props.border.link = !this.props.border.link
        this.props.onChangeBorder(this.props.border);
    }

    onChangeBorder = (value: number, direction: PageBorderDirection) => {
        let b = this.props.border;

        value = Math.round(Converter.mmToPx(value))

        if (this.props.border.link) {
            b.left = value;
            b.right = value;
            b.top = value;
            b.bottom = value;
        }
        else {
            switch (direction) {
                case PageBorderDirection.left:
                    b.left = value; break;
                case PageBorderDirection.top:
                    b.top = value; break;
                case PageBorderDirection.right:
                    b.right = value; break;
                case PageBorderDirection.bottom:
                    b.bottom = value; break;
            }
        }

        this.props.onChangeBorder(b);
    }

    render() {
        let width = this.props.smallRendering ? 180 : 400
        let numberInputWidth = this.props.smallRendering ? 69 : 95
        let unitWidth = 16
        let maxValue = 90

        let generalTooltipTitle = this.context.translate(translations.toolbar.border.border_margin)

        return <table className={"ws-designer-page-border-settings"} width={width}>
            <tbody>
            <tr className={"tooltip-below"}>
                <td>
                    <label htmlFor={"txtPageBorderLeft"} className={"tooltip-control"}>
                        {this.props.smallRendering ? this.context.translate(translations.toolbar.left_small) : this.context.translate(translations.toolbar.left)}
                    </label>
                    <Tooltips
                        text={new TooltipText(
                            generalTooltipTitle + ": " + this.context.translate(translations.toolbar.left),
                            this.context.translate(translations.tooltip.border_margin))}
                        translateX={-25} translateY={0}/>
                </td>
                <td>
                    <NumberInput width={numberInputWidth} maxlength={3} minValue={0} maxValue={maxValue}
                                 clickable={this.props.readonly === undefined || !this.props.readonly}
                                 required={false}
                                 unitPaddingRight={-4}
                                 unit={"mm"} unitWidth={unitWidth} value={Math.round(Converter.pxToMm(this.props.border.left))}
                                 onChangeValue={(value) => this.onChangeBorder(value, PageBorderDirection.left)} />
                </td>
                <td rowSpan={2}>
                    <WDToolbarLinkBracket
                        readonly={this.props.linkReadOnly}
                        linked={this.props.border.link}
                        linkBracketInfo={new LinkBracketInfo(true, true, true, true)}
                        onClickLink={this.onClickLink}/>
                </td>
                <td>
                    <label htmlFor={"txtPageBorderTop"} className={"tooltip-control"}>
                        {this.props.smallRendering ? this.context.translate(translations.toolbar.top_small) : this.context.translate(translations.toolbar.top)}
                    </label>
                    <Tooltips
                        text={new TooltipText(
                            generalTooltipTitle + ": " + this.context.translate(translations.toolbar.top),
                            this.context.translate(translations.tooltip.border_margin))}
                        translateX={90} translateY={0}/>
                </td>
                <td>
                    <NumberInput width={numberInputWidth} maxlength={3} minValue={0} maxValue={maxValue}
                                 clickable={this.props.readonly === undefined || !this.props.readonly}
                                 required={false}
                                 unitPaddingRight={-4}
                                 unit={"mm"} unitWidth={unitWidth} value={Math.round(Converter.pxToMm(this.props.border.top))}
                                 onChangeValue={(value) => this.onChangeBorder(value, PageBorderDirection.top)} />
                </td>
            </tr>
            <tr style={{borderTop: "solid 8px transparent"}} className={"tooltip-below"}>
                <td>
                    <label htmlFor={"txtPageBorderRight"} className={"tooltip-control"}>
                        {this.props.smallRendering ? this.context.translate(translations.toolbar.right_small) : this.context.translate(translations.toolbar.right)}
                    </label>
                    <Tooltips
                        text={new TooltipText(
                            generalTooltipTitle + ": " + this.context.translate(translations.toolbar.right),
                            this.context.translate(translations.tooltip.border_margin))}
                        translateX={-25} translateY={0}/>
                </td>
                <td>
                    <NumberInput width={numberInputWidth} maxlength={3} minValue={0} maxValue={maxValue}
                                 clickable={this.props.readonly === undefined || !this.props.readonly}
                                 required={false}
                                 unitPaddingRight={-4}
                                 unit={"mm"} unitWidth={unitWidth} value={Math.round(Converter.pxToMm(this.props.border.right))}
                                 onChangeValue={(value) => this.onChangeBorder(value, PageBorderDirection.right)} />
                </td>
                <td>
                    <label htmlFor={"txtPageBorderBottom"} className={"tooltip-control"}>
                        {this.props.smallRendering ? this.context.translate(translations.toolbar.bottom_small) : this.context.translate(translations.toolbar.bottom)}
                    </label>
                    <Tooltips
                        text={new TooltipText(
                            generalTooltipTitle + ": " + this.context.translate(translations.toolbar.bottom),
                            this.context.translate(translations.tooltip.border_margin))}
                        translateX={90} translateY={0}/>
                </td>
                <td>
                    <NumberInput width={numberInputWidth} maxlength={3} minValue={0} maxValue={maxValue}
                                 clickable={this.props.readonly === undefined || !this.props.readonly}
                                 required={false}
                                 unitPaddingRight={-4}
                                 unit={"mm"} unitWidth={unitWidth} value={Math.round(Converter.pxToMm(this.props.border.bottom))}
                                 onChangeValue={(value) => this.onChangeBorder(value, PageBorderDirection.bottom)} />
                </td>
            </tr>
            </tbody>
        </table>
    }
}

