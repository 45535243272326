import {MainContext} from "../_base/MainContext";
import React from "react";
import {ImagePath} from "../Framework/CategoryImage";

interface IProps extends React.PropsWithChildren {
    loading: boolean
    alternative: boolean
    style?: React.CSSProperties

    width?: number
    height?: number

    onClick?: () => void
}
interface IState {
}

export default class DashboardKPI extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
        }
    }

    render() {
        let style = this.props.style || {}

        if (this.props.width) {
            style.width = this.props.width
        }
        if (this.props.height) {
            style.height = this.props.height
        }

        return <div className={"dashboard-kpi-item" + (this.props.alternative ? " dashboard-kpi-item-alternative" : "")}
                    style={style}
                    onClick={this.props.onClick}>

            {this.props.loading ?
                <img src={process.env.PUBLIC_URL + ImagePath.getNotificationUrl() + "loading.gif"}
                     alt="loading"
                     className={this.props.alternative ? "svg-color-white" : "svg-color-primary"}
                     draggable={"false"}
                     onContextMenu={(e) => e.preventDefault()}
                />
                :
                this.props.children
            }
        </div>
    }
}

