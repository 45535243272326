import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";

/**
 * Checkbox (Yes/No) in Toolbar
 * Effects selected element(s)
 * Only possible if exercise is generated by the logic of the element
 * Yes: if activated (Yes) digit exceeding is possible (8 + 7 = 15)
 * No:  only values not exceeding digit are valid (5 + 4 = 9)
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    onActionButton: (action: WDToolbarAction, data: any) => void
}

export class WDToolbarButtonDigitExceeded extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    changeDigitExceeded = () => {
        let valuePressed: any = {"digitExceeded": !this.props.pressed}
        this.props.onActionButton(WDToolbarAction.CHANGE_DIGIT_EXCEEDED, valuePressed)
    }

    render() {
        return <div className={"checkbox-group"}>
            <div>{this.context.translate(translations.toolbar.digit_exceeded)}</div>
            <input type={"checkbox"}
                   className={"switch"}
                   id={"chkDigit" + this.props.id}
                   value={"chkDigit" + this.props.id}
                   disabled={!this.props.clickable}
                   checked={this.props.pressed}
                   onChange={this.changeDigitExceeded}
            />
        </div>
    }
}
