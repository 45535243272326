import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButtonColorPicker} from "../General/WDToolbarButtonColorPicker";
import Const from "../../../../Framework/Const";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Triggers cross out effect of element
 */

class OptionSettings {
    open: boolean
    width: number
    height: number
    color?: string

    constructor(action: WDToolbarAction, open: boolean) {
        this.open = open
        this.width = 100
        this.height = 100
        this.color = Const.COLOR_DARK_GREY
    }
}

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    options: OptionSettings

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    color: string
}

export class WDToolbarButtonCrossOut extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            color: this.props.options.color || Const.COLOR_DARK_GREY
        }
    }

    onAction = () => {
        this.props.onActionButton(WDToolbarAction.CROSS_OUT, {color: this.state.color})
    }

    onChangeColor = (color: string) => {
        this.setState({color: color})

        this.props.onActionButton(WDToolbarAction.CROSS_OUT, {color: color})
    }
    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    render() {
        let tooltip = new TooltipText(this.context.translate(translations.toolbar.cross_out), this.context.translate(translations.tooltip.cross_out))

        return <li
            key={"ws-designer-toolbar-button-cross-out"}
            className={"ws-designer-toolbar-item"}
            id={"crossOutElement"}>

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + "cross_out.svg"}
                             tooltip={tooltip}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             color={this.state.color}
                             arrow={false}
                             onToggleButton={this.onAction}
                             pressed={this.props.pressed} />

            <WDToolbarButtonColorPicker
                id={this.props.id}
                tooltip={tooltip}
                open={this.props.options.open}
                clickable={this.props.clickable}
                transparency={false}
                onToggleButton={this.onToggleList}
                onChangeColor={this.onChangeColor}
            />
        </li>
    }
}
