import {TooltipPosition} from "../../Components/Tooltips";

export class SidebarFilterButtonData {
    id: number
    title: string
    tooltip: string
    tooltipPosition: TooltipPosition
    tooltipTranslateX: number
    tooltipTranslateY: number
    image: string
    readonly: boolean
    onClick: () => void

    constructor(id: number, title: string, tooltip: string, image: string, readonly: boolean, onClick: () => void,
                tooltipPosition?: TooltipPosition, tooltipTranslateX?: number, tooltipTranslateY?: number) {
        this.id = id
        this.title = title
        this.tooltip = tooltip
        this.tooltipPosition = tooltipPosition || TooltipPosition.below
        this.tooltipTranslateX = tooltipTranslateX !== undefined ? tooltipTranslateX : -10
        this.tooltipTranslateY = tooltipTranslateY !== undefined ? tooltipTranslateY : 20

        this.image = image
        this.readonly = readonly
        this.onClick = onClick
    }
}
