import React from "react";
import {MainContext} from "../../_base/MainContext";
import Note from "../../_model/Note";
import translations from "../../Framework/translations.json";
import {CreateNote, DeleteNote, GetNoteList} from "../../_endpoint/NoteEndpoint";
import TextBox from "../../Components/Controls/TextBox";
import {Util} from "../../Framework/Util";
import {ImagePath} from "../../Framework/CategoryImage";
import Auth from "../../Framework/Auth";
import {AdminObjectType} from "../../Framework/Enums";

interface IProps {
    objectId?: number
    objectType: AdminObjectType
}
interface IState {
    items: Note[]

    title: string
    description: string
}

export class NoteList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            items: [],
            title: "",
            description: ""
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.objectId !== this.props.objectId) {
            this.fetchData()
        }
    }

    refresh = () => {
        this.fetchData()
    }
    fetchData = () => {
        if (this.props.objectId) {
            this.context.log.info("Fetch note data for object " + this.props.objectId + " of type " + this.props.objectType)

            GetNoteList(this.props.objectId, this.props.objectType).then(
                (itemData) => {
                    this.context.log.info("Retrieved " + itemData.length + " items.")
                    this.context.log.flush()

                    this.setState({items: itemData})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        } else {
            this.context.log.info("No object id provided, skip loading notes ...")
        }

        this.context.log.flush()
    }

    onChangeTitle = (value: string) => {
        this.setState({title: value})
    }
    onChangeDescription = (value: string) => {
        this.setState({description: value})
    }

    isFormValid = () => {
        const form = document.getElementById("formNewNote")  as HTMLFormElement;
        return form && form.reportValidity()
    }
    createNote = () => {

        if (this.isFormValid()) {
            this.context.log.info("Create Note")
            this.context.log.info(this.state.title + " - " + this.state.description)

            if (this.props.objectId) {
                let note = new Note(this.state.title, this.state.description, this.props.objectId, this.props.objectType, false)
                CreateNote(note).then(
                    () => {
                        this.setState({title: "", description: ""}, () => this.fetchData())
                    },
                    (error) => {
                        this.context.handleError(error, this.context.translate(translations.notification.saving_error))
                    }
                )
            }
        }
        this.context.log.flush()
    }
    deleteNote = (id: number) => {
        this.context.log.info("Delete Note " + id)

        DeleteNote(id).then(
            () => {
                this.fetchData()
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.delete_error))
            }
        )
    }

    canDelete = (note: Note) => {
        // Only manually created notes can be deleted if the creator is the current user
        return !note.automatic && note.createdBy?.id === Auth.getUserId()
    }

    render() {
        if (this.props.objectId === undefined) {
            return <></>
        }

        return <div className="note-list">
            <label className="bold-label">Notizen</label>

            <form id={"formNewNote"} className={"note-list-new"}>

                <TextBox id={"txtNewNoteTitle"}
                         width={300}
                         required={true}
                         readonly={false}
                         value={this.state.title}
                         placeholder={this.context.translate(translations.text.title)}
                         onChange={this.onChangeTitle}
                />

                <TextBox id={"txtNewNoteDescription"}
                         width={300}
                         required={false}
                         readonly={false}
                         multiline={true}
                         value={this.state.description}
                         placeholder={this.context.translate(translations.text.description)}
                         onChange={this.onChangeDescription}
                />

                <input id={"btnSaveNote"} className={"form-button"} type={"button"} value={"OK"}
                       onClick={this.createNote} />
            </form>

            {this.state.items.map(i => {
                return <div className={"note-list-item"} key={i.id}>
                    <b>{i.title}</b>
                    {this.canDelete(i) &&
                    <img className={"note-list-item-delete svg-color-primary"}
                         alt={this.context.translate(translations.toolbar.delete)}
                         src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "clear.svg"}
                         width={14}
                         onClick={() => this.deleteNote(i.id!)}
                         draggable={"false"}
                         onContextMenu={(e) => e.preventDefault() }
                    />
                    }
                    <br/>
                    {i.description}
                    <div className={"note-list-item-date"}>
                        {i.createdBy && i.createdOn &&
                           i.createdBy.firstname + " " + i.createdBy.lastname + ", " + Util.formatDate(i.createdOn, true)
                        }
                    </div>
                </div>
            })}
        </div>
    }
}

