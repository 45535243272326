import React from "react";
import Entity from "../../_model/Entity";
import {ColumnDefinition} from "./List";
import {MainContext} from "../../_base/MainContext";
import {ColumnRenderOption, WSMarketplaceStatus} from "../../Framework/Enums";
import {Util} from "../../Framework/Util";
import {ChildStatus} from "../../Admin/Exercise/TextExerciseListItem";
import {MPStatusIcon} from "../../Marketplace/MPStatusIcon";
import {ImageToggleButton} from "../Controls/ImageToggleButton";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import Rating from "../Controls/Rating";
import Highlighter from "react-highlight-words";
import { TooltipPosition } from "../Tooltips";

interface IProps {
    item: Entity
    definitions: ColumnDefinition[]
    selected: boolean
    cacheKey: string
    searchTerms?: string

    onUnselectItem?: (itemId: number) => void
    onSelectItem?: (itemId: number, multiSelectPossible: boolean) => void
    onOpenItem?: (itemId: number, area: string) => void
}

export class ListEntry extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    propagateClick = (multiple: boolean) => {
        if (this.props.selected && multiple) {
            this.props.onUnselectItem?.(this.props.item.id!)
        } else {
            this.props.onSelectItem?.(this.props.item.id!, multiple)
        }
    }

    onRowClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        this.propagateClick(event.ctrlKey)
    }

    handleImageToggleClick = () => {
        this.propagateClick(true)
    }
    // getRedirectUrl = () => {
    //     let id = this.props.item.id!.toString()
    //     let area = this.props.item["area"]
    //
    //     return this.props.redirectUrl?.replace("{0}", id).replace("{1}", area)
    // }


    renderValue = (key: string, renderOption: ColumnRenderOption, value: any, searchable: boolean) => {
        if (value === null || value === undefined) {
            return ""
        }

        switch (renderOption) {
            case ColumnRenderOption.Thumbnail:
                if (this.props.onOpenItem) {
                    return <div
                        onClick={() => this.props.onOpenItem!(this.props.item.id!, this.props.item["area"])}>
                        <img className={"list-content-thumbnail"} src={value + "?" + this.props.cacheKey}
                             alt={key}/>
                    </div>
                } else {
                    return <img className={"list-content-thumbnail"} src={value + "?" + this.props.cacheKey}
                                alt={key}/>
                }

            case ColumnRenderOption.Icon:
                return <img className={"list-content-icon"} src={value} alt={key}/>

            case ColumnRenderOption.Date:
                return Util.formatDate(value, false)

            case ColumnRenderOption.DateTime:
                return Util.formatDate(value, true)

            case ColumnRenderOption.Status:
                let status = value as ChildStatus
                return <div className={"admin-list-status"}>
                    <div className={"admin-list-status-published"}>{status.published}</div>
                    &nbsp;/&nbsp;
                    <div className={"admin-list-status-approval"}>{status.approval}</div>
                    &nbsp;/&nbsp;
                    <div className={"admin-list-status-draft"}>{status.draft}</div>
                </div>

            case ColumnRenderOption.MarketplaceStatus:
                let marketplaceStatus = value as WSMarketplaceStatus
                return <MPStatusIcon mpStatus={marketplaceStatus} tooltipPosition={TooltipPosition.above}
                                     translateX={-80} translateY={-18}/>

            case ColumnRenderOption.Rating:
                return <Rating rating={value}/>
        }

        if (typeof (value) == "string" && this.props.searchTerms && searchable) {
            return <Highlighter
                searchWords={this.props.searchTerms.split(' ')}
                highlightClassName="search-term"
                autoEscape={true}
                textToHighlight={value}
            />
        }

        return value
    }

    render() {
        let rowClassName = "list-row"

        if (this.props.selected) {
            rowClassName += " row-selected"
        }

        return <tr className={rowClassName}>
            {this.props.onSelectItem &&
                <td className="list-content" style={{width: "20px"}}>
                    <ImageToggleButton
                        id={"list-entry-toggle"}
                        defaultStatus={this.props.selected}
                        imageUrlSelected={ImagePath.getButtonUrl() + "checkmark.svg"}
                        imageClassSelected={"row-selection-image-checked"}
                        imageUrlUnselected={ImagePath.getButtonUrl() + "checkmark.svg"}
                        imageClassUnselected={"row-selection-image-unchecked"}
                        imageWidth={16}
                        imageHeight={16}
                        arrow={false}
                        altText={this.context.translate(translations.command.select)}
                        toggleHandler={this.handleImageToggleClick}
                    />
                </td>
            }

            {/*render all values*/}
            {this.props.definitions.map((col) => {
                return Object.entries(this.props.item)
                    .map(([key, value]) => {

                        // Found property that matches column definition
                        if (key === col.logicalName) {

                            if (key === "name") {
                                return <td key={this.props.item.id + key} className="list-content"
                                           onClick={this.onRowClick}>
                                    {this.props.onOpenItem ?
                                        <div
                                            onClick={() => this.props.onOpenItem!(this.props.item.id!, this.props.item["area"])}>
                                            {this.renderValue(key, col.renderOption, value, col.searchable)}
                                        </div>
                                        :
                                        <>{this.renderValue(key, col.renderOption, value, col.searchable)}</>
                                    }
                                </td>
                            }

                            return <td key={this.props.item.id + key} className="list-content" align={"left"}
                                       onClick={this.onRowClick}>

                                {this.renderValue(key, col.renderOption, value, col.searchable)}
                            </td>
                        }

                        return undefined
                    })
            })
            }
        </tr>
    }
}
