// case insensitive search of n-number properties of type T
// returns true if at least one of the property values includes the query value

export function genericSearch<T>(
    object: T,
    properties: Array<keyof T>,
    query: any
): boolean {
    if (query === "") {
        return true;
    }
    return properties.some((property) => {
        const value = object[property]
        if (typeof value === "string") {
            return value.toString().toLowerCase().includes(query.toLowerCase())
        }
        if (typeof value === "number") {
            return value === +query
        }
        return false;
    });
}
