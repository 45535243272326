import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {DropDown, DropDownItem, DropDownType} from "../../../../Components/Controls/DropDown";
import translations from "../../../../Framework/translations.json";
import WDWritingLineatureDropDownValues from "../../../Elements/Lineature/WritingLineature/WDWritingLineatureDropDownValues";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {TooltipText} from "../../../../Components/Tooltips";
import {MainContext} from "../../../../_base/MainContext";
import {WDToolbarButton} from "../WDToolbarButton";

/**
 * Button (Dropdown) in Toolbar
 * Effects selected element(s)
 * Shows lineature sizes as dropdown (4-4-4, 8-8-8)
 * Dropdown: Change of image should be done according to the selected value
 */

export interface IProps {
    id: number
    size: string
    readonly: boolean
    onActionButton: (action: WDToolbarAction, data: any) => void
    onToggleButton: (id: number) => void
}
export interface IState {

}

export class WDToolbarButtonLineatureSize extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    dropDownItems: DropDownItem[] = []

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.dropDownItems = WDWritingLineatureDropDownValues.getLineatureSizeValues().map(item => {return new DropDownItem(item.id!.toString(), item.name.concat(" mm"))})
    }

    onChangeLineatureSize = (size: string) => {
        let sizeDropDown = this.dropDownItems.filter(item => item.value === size)

        if(sizeDropDown.length === 1) {
            this.props.onActionButton(WDToolbarAction.CHANGE_LINEATURE_SIZE, {"lineatureSize": sizeDropDown[0].name.substring(0, sizeDropDown[0].name.indexOf(" mm"))})
            this.props.onToggleButton(this.props.id)
        }
    }

    render() {
        return <li
            className={"ws-designer-toolbar-item"}
            key={"ws-designer-toolbar-button-lineature-size"}
            style={{marginRight: "5px"}}
            id={"changeLineatureSize"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + "writing_high.svg"}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.lineature_type),
                                 this.context.translate(translations.tooltip.lineature_type))}
                             cssClass={"ws-designer-toolbar-button-ml"}
                             buttonClass={"ws-designer-toolbar-button-image-no-hover"}
                             iconClassSelected={"svg-color-1E1F28"}
                             iconClassUnselected={"svg-color-1E1F28"}
                             onToggleButton={() => {}}
                             imageWidth={13}
                             buttonWidth={25}
                             arrow={false}
                             enabled={!this.props.readonly}
                             pressed={false} />

            <DropDown
                id={"lineature-size"}
                width={90}
                required={false}
                readonly={this.props.readonly}
                type={DropDownType.TEXT}
                value={this.props.size.concat(" mm")}
                items={this.dropDownItems}
                style={{textAlign: "right"}}
                autocomplete={false}
                onChangeValue={this.onChangeLineatureSize} />
        </li>
    }
}
