import WDContextMenuItem from "./WDContextMenuItem";

export default class WDContextMenuGroup {
    id: number
    items: WDContextMenuItem[]

    constructor(id: number, items: WDContextMenuItem[]) {
        this.id = id
        this.items = items
    }
}
