import API from "../Framework/API";
import {TutorialData} from "../Designer/Tutorial/TutorialData";

const URL = "tutorials"

export function GetTutorials() {
    return API.Get<TutorialData[]>(URL)
}
export function CompleteTutorial(tutorialId: number) {
    let url = URL + "/" + tutorialId + "/complete"
    return API.Post<object, object>(url, {})
}
export function ResetTutorials() {
    return API.Delete<object>(URL)
}
