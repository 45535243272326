export enum WSContextType {
    standard = "standard",
    maintenance = "maintenance",
    exercise = "exercise",
    rule = "rule",
    sales_material = "sales_material",
    text_exercise_main = "text_exercise_main",
    text_exercise_child = "text_exercise_child",
    writing_course_main = "writing_course_main",
    writing_course_child = "writing_course_child",
    exercise_pattern = "exercise_pattern"
}

export class WSContext {

    static showToolbarButtonMarkSolution(context: WSContextType) {
        return (context === WSContextType.rule || context === WSContextType.exercise || context === WSContextType.text_exercise_child)
    }
    static showToolbarButtonVariableText(context: WSContextType) {
        return (context === WSContextType.text_exercise_main)
    }

    static showSidebarNames(context: WSContextType) {
        return (context !== WSContextType.standard)
    }
}
