import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import React from "react";
import {MainContext} from "../../_base/MainContext";
import RadioButtonList from "./RadioButtonList";
import {EntityToEnumValue, EnumValuesToEntityArray, EnumValueToEntity} from "../../Framework/Enums";
import Entity from "../../_model/Entity";
import {Util} from "../../Framework/Util";

export enum SearchFields {
    Name= "name",
    SearchField = "search_fields"
}

interface IProps {
    width?: number
    value?: string
    fieldOption?: SearchFields
    readonly?: boolean
    typeAheadSearch: boolean
    showFieldOptions: boolean
    maxLength: number

    onSearch: (searchValue: string, option?: SearchFields) => void
}

interface IState {
    searchValue: string
    fieldOption: SearchFields
    fieldOptionsVisible: boolean
}

export class SearchInput extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            searchValue: this.props.value || "",
            fieldOption: this.props.fieldOption || SearchFields.SearchField,
            fieldOptionsVisible: false
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.value !== this.props.value) {
            this.setState({searchValue: this.props.value || ""})
        }
    }

    onChange = (event: React.FormEvent) => {
        const searchValue = (event.target as HTMLInputElement).value

        if (this.props.typeAheadSearch) {
            this.props.onSearch(searchValue, this.state.fieldOption)
        }

        this.setState({searchValue: searchValue })
    }
    onKeyDown = (event: React.KeyboardEvent) => {
        // event.preventDefault()
        event.stopPropagation()

        if (event.key === 'Enter' || event.key === 'Tab') {
            (event.target as HTMLInputElement).blur()
        }
    }
    onBlur = () => {
        if (!this.props.typeAheadSearch) {
            this.props.onSearch(this.state.searchValue, this.state.fieldOption)
        }
    }
    onChangeSearchFieldOption = (value: Entity) => {
        this.setState({
            fieldOption: EntityToEnumValue(value, SearchFields),
            fieldOptionsVisible: false
        }, () => this.props.onSearch(this.state.searchValue, this.state.fieldOption))
    }

    onClickSearchRemove = () => {
        this.setState({searchValue: "" }, () => {
            this.props.onSearch("", this.state.fieldOption)
        })
    }
    onClickSearch = () => {
        this.props.onSearch(this.state.searchValue, this.state.fieldOption)
    }
    onClickSearchOptions = () => {
        if (this.state.fieldOptionsVisible) {
            document.removeEventListener("mousedown", this.onMouseDown)
        }
        else {
            document.addEventListener("mousedown", this.onMouseDown)
        }

        this.setState({fieldOptionsVisible: !this.state.fieldOptionsVisible})
    }

    onMouseDown = (event: MouseEvent) => {
        if (!Util.isChildOfClass(event.target as HTMLElement, "search-options")) {
            document.removeEventListener("mousedown", this.onMouseDown)
            this.setState({fieldOptionsVisible: false})
        }
    }

    render() {
        let dropDownArrowClass = "search-drop-down"
        let width = this.props.width ? this.props.width + 35 : 285

        if(this.props.readonly) {
            dropDownArrowClass += " drop-down-read-only"
        }

        return <div className={"search"} style={{width: width}}>
            <input  type="image"
                    className={this.props.readonly ? "cursor-disabled" : ""}
                    src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "search.svg"}
                    alt={this.context.translate(translations.command.search)}
                    draggable={"false"}
                    onClick={() => {!this.props.readonly && this.onClickSearch()}}
                    onContextMenu={(e) => e.preventDefault() }
            />
            <input id="search" type="text" name="search"
                   readOnly={this.props.readonly}
                   maxLength={this.props.maxLength}
                   onChange={this.onChange} onKeyDown={this.onKeyDown} onBlur={this.onBlur}
                   value={this.state.searchValue} style={{width: width - 80}}
            />

            {this.state.searchValue !== "" &&
                <input  type="image"
                        className={this.props.readonly ? "search-remove cursor-disabled" : "search-remove"}
                        src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "coupling.svg"}
                        alt={this.context.translate(translations.command.search_remove)}
                        draggable={"false"}
                        onClick={() => {!this.props.readonly && this.onClickSearchRemove()}}
                        onContextMenu={(e) => e.preventDefault() }
                />
            }

            {this.props.readonly &&
                <div className={"search-disabled-overlay"} style={{width: width}}/>
            }

            {this.props.showFieldOptions && this.state.fieldOption === SearchFields.Name &&
                <div className={"search-option-active"}>in Name</div>
            }

            {this.props.showFieldOptions &&
                <img
                    src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                    className={dropDownArrowClass}
                    style={{right: 3}}
                    alt={this.context.translate(translations.command.select)}
                    onClick={!this.props.readonly ? this.onClickSearchOptions : undefined}
                    draggable={"false"}
                    onContextMenu={(e) => e.preventDefault() }
                />
            }

            {this.state.fieldOptionsVisible &&
                <div className={"search-options"} style={{width: width}}>
                    <RadioButtonList id={"search-options-search-field"}
                                     data={EnumValuesToEntityArray(SearchFields, Object.values(SearchFields), translations.enum.search_field_options, this.context.translate)}
                                     value={EnumValueToEntity(SearchFields, this.state.fieldOption, translations.enum.search_field_options, this.context.translate).id}
                                     required={false}
                                     readonly={false}
                                     onChange={this.onChangeSearchFieldOption}
                    />
                </div>
            }
        </div>
    }
}
