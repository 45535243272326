import React from "react";
import translations from "../../../Framework/translations.json";
import {MainContext} from "../../../_base/MainContext";
import {Difficulty, Gender, NotificationStatus} from "../../../Framework/Enums";
import {Hint, NotificationData} from "../../../Components/Notification/Hint";
import NameConfig from "../../../_model/NameConfig";
import {
    CreateUserNameConfig,
    CreateWSNameConfig,
    DeleteNameConfig,
    GetAllNameConfigUser,
} from "../../../_endpoint/NameEndpoint";
import {ImagePath} from "../../../Framework/CategoryImage";
import {Worksheet} from "../../../_model/Worksheet";
import {DropDown, DropDownItem, DropDownType} from "../../../Components/Controls/DropDown";
import Language from "../../../_model/Language";
import {GetAllLanguages} from "../../../_endpoint/MetadataEndpoint";
import {Util} from "../../../Framework/Util";

interface IProps {
    worksheetId: Worksheet
    nameConfigWS: NameConfig[]
    addWSConfig: (nameConfig: NameConfig) => void
    removeWSConfig: (nameConfig: NameConfig) => void
}
interface IState {
    nameConfigUser: NameConfig[]
    createNameConfigMode: boolean
    newNameConfig?: NameConfig

    languages?: Language[]
    dropDownItems?: DropDownItem[]
}

export class SidebarNames extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            nameConfigUser: [],
            createNameConfigMode: false
        }
    }

    componentDidMount() {
        this.fetchUserData()
        this.fetchLanguages()
    }

    fetchUserData = () => {
        GetAllNameConfigUser().then(
            (names) => {
                this.setState({nameConfigUser: names})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }
    fetchLanguages = () => {
        GetAllLanguages().then(
            (languages) => {
                this.setState({
                    languages: languages,
                    dropDownItems: languages.map(language => new DropDownItem(language.id!.toString(), language.name))})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    toggleCreateMode = () => {
        let createConf = this.state.newNameConfig ? undefined : new NameConfig("")
        this.setState({
            createNameConfigMode: !this.state.createNameConfigMode,
            newNameConfig: createConf
        })
    }
    createUserNameConfig = (nameConfig: NameConfig) => {
        CreateUserNameConfig(nameConfig).then(
            (nameConf) => {
                let nameConfUser = this.state.nameConfigUser
                nameConfUser.push(nameConf)
                this.setState({nameConfigUser: nameConfUser})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.creating_error))
            }
        )
    }
    createWSNameConfig = (nameConfig: NameConfig) => {
        CreateWSNameConfig(nameConfig, this.props.worksheetId).then(
            (nameConf) => {
                this.props.addWSConfig(nameConf)
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.creating_error))
            }
        )
    }
    createNameTag = (nameConfig: NameConfig, genetive: boolean) => {
        const text = "Name " + nameConfig.id + (genetive ? " 's" : "")

        let nameTag = document.createElement("input")
        nameTag.className = "name-config-tag"
        nameTag.placeholder = text
        nameTag.type = "text"
        nameTag.size = text.length
        nameTag.readOnly = true
        nameTag.disabled = true

        // custom tags
        nameTag.setAttribute("nameConfigId", nameConfig.id!.toString())
        nameTag.setAttribute("genetive", genetive.toString())

        // Add name tag at the current cursor position
        Util.addElementAtSelection(nameTag)

        let event = new Event('input', { bubbles: true, cancelable: true })

        document.activeElement?.dispatchEvent(event)
    }
    deleteConfig = (nameConfig: NameConfig, wsData: boolean) => {
        DeleteNameConfig(nameConfig).then(
            () => {
                wsData ? this.props.removeWSConfig(nameConfig) : this.setState({nameConfigUser: this.state.nameConfigUser.filter(conf => conf !== nameConfig)})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.delete_error))
            }
        )
    }

    createSetGenderActive = (gender: Gender) => {
        if(this.state.newNameConfig) {
            let nameConf = this.state.newNameConfig

            if(this.state.newNameConfig.gender !== gender) {
                nameConf.gender = gender
            } else {
                nameConf.gender = undefined
            }
            this.setState({newNameConfig: nameConf})
        }
    }
    createSetPhonemicActive = () => {
        if(this.state.newNameConfig) {
            let nameConf = this.state.newNameConfig
            nameConf.phonemic = this.state.newNameConfig.phonemic ? undefined : true
            this.setState({newNameConfig: nameConf})
        }
    }
    createSetDifficultyActive = (diff: Difficulty) => {
        if(this.state.newNameConfig) {
            let nameConf = this.state.newNameConfig

            if(this.state.newNameConfig.difficulty !== diff) {
                nameConf.difficulty = diff
            } else {
                nameConf.difficulty = undefined
            }
            this.setState({newNameConfig: nameConf})
        }
    }
    createSetLanguage = (value: string) => {
        if(this.state.languages) {
            let nameConf = this.state.newNameConfig
            let language = this.state.languages.find((language) => language.id === +value)

            if(nameConf) {
                nameConf.language = language
                this.setState({newNameConfig: nameConf})
            }
        }
    }

    renderConfigText = (nameConfig: NameConfig, wsData: boolean) => {
        let config = wsData ? ("Name " + nameConfig.id + ": ") : ""

        if (nameConfig.gender === Gender.male) {
            config += this.context.translate(translations.fields.name_fields.name_config_male) + "; "
        }
        else if (nameConfig.gender === Gender.female) {
            config += this.context.translate(translations.fields.name_fields.name_config_female) + "; "
        }
        if (nameConfig.language) {config += nameConfig.language.name + "; "}
        if (nameConfig.phonemic) {config += this.context.translate(translations.fields.name_fields.name_config_phonemic) + "; "}
        if (nameConfig.difficulty === Difficulty.easy) {
            config += this.context.translate(translations.fields.name_fields.name_config_diff_easy) + "; "
        }
        else if (nameConfig.difficulty === Difficulty.medium) {
            config += this.context.translate(translations.fields.name_fields.name_config_diff_medium) + "; "
        }
        else if (nameConfig.difficulty === Difficulty.hard) {
            config += this.context.translate(translations.fields.name_fields.name_config_diff_hard) + "; "
        }

        if(!nameConfig.gender && !nameConfig.language && !nameConfig.phonemic && !nameConfig.difficulty) {
            config += this.context.translate(translations.fields.name_fields.name_random)
        }

        return config
    }
    renderConfig = (nameConfig: NameConfig, wsData: boolean, create: (nameConfig: NameConfig, genetive: boolean) => void, key: number) => {
        let config = this.renderConfigText(nameConfig, wsData)

        return <tr key={"config-" + key} className={"ws-designer-sidebar-name-config-row"}>
            <td style={{width: "70%"}}>
                {config}
            </td>

            <td style={{width: "10%"}} onClick={() => create(nameConfig, false)}>
                <img className={"ws-designer-sidebar-button-image"} src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "coupling.svg"} alt={""}/>
            </td>

            {wsData &&
            <td style={{width: "10%"}} onClick={() => create(nameConfig, true)}>
                <img className={"ws-designer-sidebar-button-image"} src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "coupling.svg"} alt={""}/>
            </td>}

            <td style={{width: "10%"}} onClick={() => this.deleteConfig(nameConfig, wsData)}>
                <img className={"ws-designer-sidebar-button-image"} style={{marginRight: 0}} src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "delete.svg"} alt={""}/>
            </td>
        </tr>
    }

    render() {
        let keyNumber = 0
        let buttonClass = "ws-designer-sidebar-name-button-create "
        let femaleClass = buttonClass + ((this.state.newNameConfig && this.state.newNameConfig.gender === Gender.female) && "ws-designer-sidebar-button-active")
        let maleClass = buttonClass + ((this.state.newNameConfig && this.state.newNameConfig.gender === Gender.male) && "ws-designer-sidebar-button-active")
        let phonemicClass = buttonClass + ((this.state.newNameConfig && this.state.newNameConfig.phonemic) && "ws-designer-sidebar-button-active")
        let diffEasyClass = buttonClass + ((this.state.newNameConfig && this.state.newNameConfig.difficulty === Difficulty.easy) && "ws-designer-sidebar-button-active")
        let diffMediumClass = buttonClass + ((this.state.newNameConfig && this.state.newNameConfig.difficulty === Difficulty.medium) && "ws-designer-sidebar-button-active")
        let diffHardClass = buttonClass + ((this.state.newNameConfig && this.state.newNameConfig.difficulty === Difficulty.hard) && "ws-designer-sidebar-button-active")

        return <div className={"ws-designer-sidebar-name-config-area"}>

                {/* nameConfigUser list */}
                <div className={"ws-designer-sidebar-name-config-section"}>
                    <table className={"ws-designer-sidebar-table"}>
                        <thead>
                            <tr key={"header-name-config"}>
                                <th className={"bold-label ws-designer-sidebar-name-config-header ws-designer-sidebar-name-config-header-align-left"}>
                                    {this.context.translate(translations.fields.name_fields.name_config_user)}
                                </th>
                                <th className={"bold-label ws-designer-sidebar-name-config-header"}>{}</th>
                                <th className={"bold-label ws-designer-sidebar-name-config-header"}>{}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.nameConfigUser.length > 0 &&

                            this.state.nameConfigUser.map(
                                (nameConfig) => {
                                    return this.renderConfig(nameConfig, false, this.createWSNameConfig, keyNumber++)
                                }
                            )
                            }
                        </tbody>

                    </table>

                    {this.state.nameConfigUser.length < 0 &&
                    <div className={"ws-designer-sidebar-images-list-empty"}>
                        <Hint id={"sidebar-name-user-config-noresult"}
                              notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.image_search_no_result))}/>
                    </div>
                    }
                </div>

                {/* nameConfigWS list */}
                <div className={"ws-designer-sidebar-name-config-section"}>
                    <table className={"ws-designer-sidebar-table"}>
                        <thead>
                            <tr key={"header-name-config-ws"}>
                                <th className={"bold-label ws-designer-sidebar-name-config-header ws-designer-sidebar-name-config-header-align-left"}>
                                    {this.context.translate(translations.fields.name_fields.name_config_ws)}
                                </th>
                                <th className={"bold-label ws-designer-sidebar-name-config-header"}>{}</th>
                                <th className={"bold-label ws-designer-sidebar-name-config-header"}>'s</th>
                                <th className={"bold-label ws-designer-sidebar-name-config-header"}>{}</th>
                            </tr>
                        </thead>

                        <tbody>
                        {this.props.nameConfigWS.length > 0 &&

                            this.props.nameConfigWS.map(
                                (nameConfig) => {
                                    return this.renderConfig(nameConfig, true, this.createNameTag, keyNumber++)
                                }
                            )
                        }
                        </tbody>

                    </table>

                    {this.props.nameConfigWS.length < 0 &&
                    <div className={"ws-designer-sidebar-images-list-empty"}>
                        <Hint id={"sidebar-name-ws-config-noresult"}
                              notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.image_search_no_result))}/>
                    </div>
                    }
                </div>

                {/* nameConfig create */}
                {this.state.createNameConfigMode && this.state.newNameConfig &&
                <div className={"ws-designer-sidebar-name-config-section"}>
                    <div className={"ws-designer-sidebar-name-create-section"}>
                        <div className={"ws-designer-sidebar-button-section"}>
                            <button key={"btnFemale"} id={"btnFemale"} className={femaleClass} onClick={() => this.createSetGenderActive(Gender.female)}>
                                {this.context.translate(translations.fields.name_fields.name_config_female)}
                            </button>
                            <button key={"btnMale"} id={"btnMale"} className={maleClass} onClick={() => this.createSetGenderActive(Gender.male)}>
                                {this.context.translate(translations.fields.name_fields.name_config_male)}
                            </button>
                            <button key={"btnPhonemic"} id={"btnPhonemic"} className={phonemicClass} onClick={this.createSetPhonemicActive}>
                                {this.context.translate(translations.fields.name_fields.name_config_phonemic)}
                            </button>
                            <button key={"btnDiffEasy"} id={"btnDiffEasy"} className={diffEasyClass} onClick={() => this.createSetDifficultyActive(Difficulty.easy)}>
                                {this.context.translate(translations.fields.name_fields.name_config_diff_easy)}
                            </button>
                            <button key={"btnDiffMedium"} id={"btnDiffMedium"} className={diffMediumClass} onClick={() => this.createSetDifficultyActive(Difficulty.medium)}>
                                {this.context.translate(translations.fields.name_fields.name_config_diff_medium)}
                            </button>
                            <button key={"btnDiffHard"} id={"btnDiffHard"} className={diffHardClass} onClick={() => this.createSetDifficultyActive(Difficulty.hard)}>
                                {this.context.translate(translations.fields.name_fields.name_config_diff_hard)}
                            </button>

                            {this.state.dropDownItems &&
                            <DropDown
                                id={"language"}
                                type={DropDownType.TEXT}
                                readonly={false}
                                required={false}
                                autocomplete={true}
                                width={250}
                                placeholder={this.context.translate(translations.fields.name_fields.name_config_language)}
                                value={this.state.newNameConfig.language?.id?.toString() || ""}
                                items={this.state.dropDownItems}
                                onChangeValue={this.createSetLanguage}
                            />}

                        </div>

                        <div className={"ws-designer-sidebar-result-section"}>
                            <div className={"ws-designer-sidebar-result ws-designer-sidebar-name-button-create"}>
                                {"Name: " + this.renderConfigText(this.state.newNameConfig, false)}
                            </div>
                            <button id={"btnCreateUserConf"} className={"ws-designer-sidebar-name-button-create"}
                                    onClick={() => this.state.newNameConfig && this.createUserNameConfig(this.state.newNameConfig)}>
                                {this.context.translate(translations.fields.name_fields.name_config_create_user)}
                            </button>
                            <button id={"btnCreateWSConf"} className={"ws-designer-sidebar-name-button-create"}
                                    onClick={() => this.state.newNameConfig && this.createWSNameConfig(this.state.newNameConfig)}>
                                {this.context.translate(translations.fields.name_fields.name_config_create_ws)}
                            </button>
                        </div>
                    </div>
                </div>}


                {/* Create new NameConfig Button */}
                <button className={"ws-designer-sidebar-name-button ws-designer-sidebar-name-button-create"} onClick={this.toggleCreateMode}>
                    {this.state.createNameConfigMode
                        ? this.context.translate(translations.fields.name_fields.name_config_discard)
                        : this.context.translate(translations.fields.name_fields.name_config_create)}
                </button>
            </div>
    }
}
