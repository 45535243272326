import React from 'react';
import {Link} from "react-router-dom";
import {ImagePath} from "../../Framework/CategoryImage";
import {MainContext} from "../../_base/MainContext";
import {ListItem} from "./List";
import {TooltipText} from "../Tooltips";

export class CardDefinition {
    orientation?: string
    renderData?: (item: any) => JSX.Element

    constructor(renderData: (item: any) => JSX.Element, orientation?: string) {
        this.renderData = renderData
        this.orientation = orientation
    }
}

export class CardAction {
    id: number
    image: string
    tooltip: TooltipText
    action: (id: number) => void

    constructor(id: number, image: string, tooltip: TooltipText, action: (id: number) => void) {
        this.id = id
        this.image = image
        this.tooltip = tooltip
        this.action = action
    }
}

interface IProps {
    value: ListItem
    selected: boolean
    cardDefinition: CardDefinition
    cardActions: CardAction[]
    redirectUrl?: string
    redirectTarget?: string

    onUnselectItem: (itemId: number) => void
    onSelectItem: (itemId: number) => void
}

interface IState {
    hover: boolean
}

export default class Card extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            hover: this.props.selected,
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.selected !== this.props.selected) {
            this.setState({hover: this.props.selected})
        }
    }

    onMouseOver = (event: React.MouseEvent) => {
        !this.state.hover && this.setState({hover: true})
    }
    onMouseLeave = (event: React.MouseEvent) => {
        !this.props.selected && this.setState({hover: false})
    }
    onChangeCheckbox = (e?: React.ChangeEvent) => {
        e?.stopPropagation()

        if (this.props.selected) {
            this.props.onUnselectItem(this.props.value.item.id!)
        } else {
            this.props.onSelectItem(this.props.value.item.id!)
        }
    }

    render() {
        let cardContent = this.props.cardDefinition.renderData?.(this.props.value.item)
        let orientation = true

        if (this.props.cardDefinition.orientation) {
            orientation = this.props.value.item[this.props.cardDefinition.orientation]
        }

        if (this.props.redirectUrl) {
            cardContent =
                <Link to={this.props.redirectUrl.replace("{0}", this.props.value.item.id!.toString())} draggable="false"
                      target={this.props.redirectTarget} style={{textDecoration: "none"}}>
                    {cardContent}
                </Link>
        } else {
            cardContent = <div className={"card-content"} onClick={() => this.onChangeCheckbox()} draggable="false">
                {cardContent}
            </div>
        }

        return <div className={"card" + (orientation ? "" : " card-landscape")} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
            {cardContent}

            {this.state.hover &&
                <div className={"card-hover"}>
                    <div className={"card-hover-buttons"}>
                        <div className={"card-hover-button-column"}>
                            <div className={"card-hover-button-row"}>
                                {this.props.cardActions.map(
                                    action => {
                                        return <div className={"card-hover-button"} key={"card-button-" + action.id}>
                                            <img className={"card-hover-button-img"}
                                                 id={"card-image-" + action.id}
                                                 src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + action.image}
                                                 alt={action.tooltip.text}
                                                 draggable={"false"}
                                                 onClick={() => action.action(this.props.value.item.id!)}
                                                 onContextMenu={(e) => e.preventDefault()}
                                            />
                                            {/*<Tooltips text={action.tooltip}/>*/}
                                        </div>
                                    }
                                )}
                            </div>
                        </div>
                        <div className={"card-hover-button-column"}>
                            <div className={"card-hover-button-checkbox"}>
                                <input
                                    id={"checked-" + this.props.value.item.id}
                                    type={"checkbox"}
                                    checked={this.props.selected}
                                    readOnly={false}
                                    onChange={this.onChangeCheckbox}
                                />
                                <label htmlFor={"checked-" + this.props.value.item.id}> </label>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    }
}
