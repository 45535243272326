import Entity from "../../_model/Entity";

export class ChildStatus {
    published: number
    approval: number
    draft: number

    constructor(published: number, approval: number, draft: number) {
        this.published = published
        this.approval = approval
        this.draft = draft
    }
}

export class TextExerciseListItem extends Entity {
    status: string
    exerciseCount: ChildStatus
    calculationType: string
    schoolLevel: string
    modifiedOn: Date
    createdBy: string
    owner: string

    constructor(id: number, name: string, status: string, exerciseCount: ChildStatus, calculationType: string, owner: string, modifiedOn: Date, createdBy: string, schoolLevel: string) {
        super(name, id);

        this.schoolLevel = schoolLevel
        this.exerciseCount = exerciseCount
        this.calculationType = calculationType

        this.owner = owner
        this.modifiedOn = modifiedOn
        this.createdBy = createdBy
        this.status = status
    }
}

export default TextExerciseListItem
