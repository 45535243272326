export enum ImageCategory {
    THUMBNAIL,
    CALCULATION_TRIANGLE,
    CALCULATION_TRIANGLE_SOLUTION_BAR,
    UNORDERED_BULLET_LIST,
    UNORDERED_BULLET_LIST_BULLET,
    ORDERED_BULLET_LIST,
    WRITING_LINEATURE,
    WRITING_LINEATURE_THUMBNAIL_EVENLY,
    WRITING_LINEATURE_THUMBNAIL_STANDARD,
    WRITING_LINEATURE_THUMBNAIL_UNEVENLY,
    WRITING_LINEATURE_CORRECTION_MARGIN,
    BALLOON,
    BALLOON_THINK,
    BALLOON_COMIC,
    MATH_LINEATURE_THUMBNAIL_EVENLY,
    MATH_LINEATURE_THUMBNAIL_UNEVENLY,
    MATH_LINEATURE_ICON,
    MATH_LINEATURE_ICON_BUTTON,
    CALCULATION_TOWER,
    CALCULATION_TOWER_THUMBNAIL,
    CALCULATION_TOWER_SOLUTION_BAR,
    TEXT_EXERCISE_ZR10,
    TEXT_EXERCISE_ZR100,
    TEXT_EXERCISE_ZR1000,
    RECKON_BRACKET,
    FORMS_2D,
    FORMS_3D,
    FORMS_BUILDING_BRICK,
    FORMS_CRAFT_PATTERN,
    LINE
}

export default class CategoryImage {
    id: string
    path: string
    category: number

    constructor(id: string, path: string, category: ImageCategory) {
        this.id = id
        this.path = path
        this.category = category
    }
}

export class ImagePath {

    static getLineatureUrl() {
        return "/images/designer/lineature/"
    }
    static getMathUrl() {
        return "/images/designer/math/"
    }
    static getTriangleUrl() {
        return "/images/designer/math/triangle/"
    }
    static getTowerUrl() {
        return "/images/designer/math/tower/"
    }
    static getTextExerciseUrl() {
        return "/images/designer/text_exercise/"
    }
    static getFormsUrl() {
        return "/images/designer/forms/"
    }
    static getLineUrl() {
        return "/images/designer/line/"
    }
    static getAdminUrl() {
        return "/images/admin/"
    }
    static getButtonUrl() {
        return "/images/buttons/"
    }
    static getDecorationUrl() {
        return "/images/decoration/"
    }
    static getBulletsUrl() {
        return "/images/bullets/"
    }
    static getBalloonUrl() {
        return "/images/designer/balloon/"
    }
    static getListStyleUrl() {
        return "/images/designer/liststyle/"
    }
    static getIconUrl() {
        return "/images/designer/icons/"
    }
    static getCursorUrl() {
        return "/images/cursor/"
    }
    static getPageUrl() {
        return "/images/designer/page/"
    }
    static getMenuUrl() {
        return "/images/menu/"
    }
    static getSidebarUrl() {
        return "/images/sidebar/"
    }
    static getBorderUrl() {
        return "/images/border/"
    }
    static getDashboardUrl() {
        return "/images/dashboard/"
    }
    static getToolbarUrl() {
        return "/images/toolbar/"
    }
    static getNotificationUrl() {
        return "/images/notification/"
    }
    static getDesignerUrl() {
        return "/images/designer/"
    }
    static getDefaultUrl() {
        return "/images/"
    }
    static getTutorialUrl() {
        return "/images/tutorial/"
    }
    static getLoginWallpaperUrl() {
        return "/images/wallpaper/login/"
    }

    static getThumbnailUrl() {
        return "data:image/jpeg;base64,"
    }
}

export class CategoryImageValue {
    static getImageByKey(category: ImageCategory[], key: string, placeholders?: string[]) {
        let image: CategoryImage | undefined = undefined

        for (let categoryAmount = category.length; categoryAmount >= 0; categoryAmount--) {
            image = values.find(value => value.id === key && value.category === category[categoryAmount])

            if (image) {
                let imageString = image.path

                if(placeholders !== undefined) {
                    for(let i = 0; i < placeholders.length; i++) {
                        imageString = imageString.replace("**" + i + "**", placeholders[i])
                    }
                }
                return imageString
            }
        }

        return ""
    }

    static getImagesByCategory(category: ImageCategory, placeholders?: string[]) {
        return values
            .filter(value => value.category === category)
            .map(value => {
                let path = value.path
                if(placeholders !== undefined) {
                    for(let i = 0; i < placeholders.length;  i++){
                        path = path.replace("**" + i + "**", placeholders[i])
                    }
                }
                return path
            })
    }
    static getImageEntriesByCategory(category: ImageCategory) {
        return values
            .filter(value => value.category === category)
    }

    static getKeyOfImage(category: ImageCategory, image: string, categoryImagePlaceholder?: string[]) {
        if(categoryImagePlaceholder !== undefined) {
            for(let i = 0; i < categoryImagePlaceholder.length; i++) {
                let replaceValue = "**" + i + "**"
                image = image.replace(categoryImagePlaceholder[i], replaceValue)
            }
        }

        let categoryImage = values.find(value => value.path === image && value.category === category)
        return categoryImage ? categoryImage.id : ""
    }
}

const values = [
    // Calculation Triangle
    new CategoryImage("M-AT-RD-STANDARD1", "m-at-rd-standard01.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-STANDARD2", "m-at-rd-standard02.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-STANDARD3", "m-at-rd-standard03.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-STANDARD4", "m-at-rd-standard04.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-STANDARD5", "m-at-rd-standard05.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-STANDARD6", "m-at-rd-standard06.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-STANDARD7", "m-at-rd-standard07.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-STANDARD8", "m-at-rd-standard08.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-HANDLINE1", "m-at-rd-handline01.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-HANDLINE2", "m-at-rd-handline02.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-HANDLINE3", "m-at-rd-handline03.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-HANDLINE4", "m-at-rd-handline04.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-HANDLINE5", "m-at-rd-handline05.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-HANDLINE6", "m-at-rd-handline06.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-BACKCOLOR1", "m-at-rd-backgroundcolor01.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-BACKCOLOR2", "m-at-rd-backgroundcolor02.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-BACKCOLOR3", "m-at-rd-backgroundcolor03.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-LINECOLOR1", "m-at-rd-linecolor01.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-LINECOLOR2", "m-at-rd-linecolor02.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-LINECOLOR3", "m-at-rd-linecolor03.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-TALE1", "m-at-rd-tale01.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-TALE2", "m-at-rd-tale02.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-TALE3", "m-at-rd-tale03.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-ZOO1", "m-at-rd-zoo01.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-ZOO2", "m-at-rd-zoo02.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-ZOO3", "m-at-rd-zoo03.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-SPACE1", "m-at-rd-space01.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-SPACE2", "m-at-rd-space02.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-SPACE3", "m-at-rd-space03.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-KNIGHT1", "m-at-rd-knight.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-KNIGHT2", "m-at-rd-knight02.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-KNIGHT3", "m-at-rd-knight03.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-ESKIMO1", "m-at-rd-eskimo01.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-ESKIMO2", "m-at-rd-eskimo02.svg", ImageCategory.CALCULATION_TRIANGLE),
    new CategoryImage("M-AT-RD-ESKIMO3", "m-at-rd-eskimo03.svg", ImageCategory.CALCULATION_TRIANGLE),

    // Calculation Triangle Solution Bar
    new CategoryImage("M-AT-RD-STANDARD1", "m-at-rd-standard01-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-STANDARD2", "m-at-rd-standard02-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-STANDARD3", "m-at-rd-standard03-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-STANDARD4", "m-at-rd-standard04-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-STANDARD5", "m-at-rd-standard05-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-STANDARD6", "m-at-rd-standard06-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-STANDARD7", "m-at-rd-standard07-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-STANDARD8", "m-at-rd-standard08-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-HANDLINE1", "m-at-rd-handline01-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-HANDLINE2", "m-at-rd-handline02-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-HANDLINE3", "m-at-rd-handline03-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-HANDLINE4", "m-at-rd-handline04-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-HANDLINE5", "m-at-rd-handline05-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-HANDLINE6", "m-at-rd-handline06-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-BACKCOLOR1", "m-at-rd-backgroundcolor01-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-BACKCOLOR2", "m-at-rd-backgroundcolor02-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-BACKCOLOR3", "m-at-rd-backgroundcolor03-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-LINECOLOR1", "m-at-rd-linecolor01-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-LINECOLOR2", "m-at-rd-linecolor02-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-LINECOLOR3", "m-at-rd-linecolor03-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-TALE1", "m-at-rd-tale01-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-TALE2", "m-at-rd-tale02-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-TALE3", "m-at-rd-tale03-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-ZOO1", "m-at-rd-zoo01-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-ZOO2", "m-at-rd-zoo02-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-ZOO3", "m-at-rd-zoo03-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-SPACE1", "m-at-rd-space01-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-SPACE2", "m-at-rd-space02-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-SPACE3", "m-at-rd-space03-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-KNIGHT1", "m-at-rd-knight-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-KNIGHT2", "m-at-rd-knight02-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-KNIGHT3", "m-at-rd-knight03-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-ESKIMO1", "m-at-rd-eskimo01-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-ESKIMO2", "m-at-rd-eskimo02-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    new CategoryImage("M-AT-RD-ESKIMO3", "m-at-rd-eskimo03-solutions.svg", ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR),
    
    // Bullet list icons
    new CategoryImage("NONE", "num_ohne.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("DOTS", "a_dots.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("FLOWER", "a_flower.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("QUADER", "a_quader.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("RING", "a_ring.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("STAR", "a_star.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("SUN", "a_sun.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("TRIANGLE", "a_triangle.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("PEN", "a_pen.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("CHECK", "a_check.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),
    new CategoryImage("CROSS", "a_cross.svg", ImageCategory.UNORDERED_BULLET_LIST_BULLET),

    // Bullet list graphics
    new CategoryImage("NONE", "num_ohne.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("DOTS", "aufz_dots.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("FLOWER", "aufz_flower.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("QUADER", "aufz_quader.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("RING", "aufz_ring.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("STAR", "aufz_star.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("SUN", "aufz_sun.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("TRIANGLE", "aufz_triangle.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("PEN", "aufz_pen.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("CHECK", "aufz_check.svg", ImageCategory.UNORDERED_BULLET_LIST),
    new CategoryImage("CROSS", "aufz_cross.svg", ImageCategory.UNORDERED_BULLET_LIST),

    new CategoryImage("NONE", "num_ohne.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("DECIMAL1", "num_dez_01.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("DECIMAL2", "num_dez_02.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("DECIMAL3", "num_dez_03.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("DECIMAL4", "num_dez_04.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("DECIMAL5", "num_dez_05.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("DECIMAL6", "num_dez_06.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("ROMAN1", "num_roem_02.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("ROMAN2", "num_roem_01.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("ALPHA1", "num_alpha_01.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("ALPHA2", "num_alpha_02.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("ALPHA3", "num_alpha_03.svg", ImageCategory.ORDERED_BULLET_LIST),
    new CategoryImage("ALPHA4", "num_alpha_04.svg", ImageCategory.ORDERED_BULLET_LIST),

    // LINEATURES
    new CategoryImage("D-AT-10-STANDARD-L","d-at-10-standard-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD-M","d-at-10-standard-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD-R","d-at-10-standard-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD1-L","d-at-10-standard1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD1-M","d-at-10-standard1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD1-R","d-at-10-standard1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD2-L","d-at-10-standard2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD2-M","d-at-10-standard2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD2-R","d-at-10-standard2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD3-L","d-at-10-standard3-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD3-M","d-at-10-standard3-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD3-R","d-at-10-standard3-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD4-L","d-at-10-standard4-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD4-M","d-at-10-standard4-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD4-R","d-at-10-standard4-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD5-L","d-at-10-standard5-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD5-M","d-at-10-standard5-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD5-R","d-at-10-standard5-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD6-L","d-at-10-standard6-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD6-M","d-at-10-standard6-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD6-R","d-at-10-standard6-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD7-L","d-at-10-standard7-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD7-M","d-at-10-standard7-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-10-STANDARD7-R","d-at-10-standard7-r.svg", ImageCategory.WRITING_LINEATURE),

    new CategoryImage("D-AT-12-6-ESKIMO-IGLU-L","d-at-12-6-eskimo-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ESKIMO-IGLU-M","d-at-12-6-eskimo-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ESKIMO-IGLU-R","d-at-12-6-eskimo-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ESKIMO-SHADE-IGLU-L","d-at-12-6-eskimo-shade-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ESKIMO-SHADE-IGLU-M","d-at-12-6-eskimo-shade-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ESKIMO-SHADE-IGLU-R","d-at-12-6-eskimo-shade-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-HOUSE-MODERN-L","d-at-12-6-house-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-HOUSE-MODERN-M","d-at-12-6-house-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-HOUSE-MODERN-R","d-at-12-6-house-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-HOUSE-SHADE-MODERN-L","d-at-12-6-house-shade-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-HOUSE-SHADE-MODERN-M","d-at-12-6-house-shade-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-HOUSE-SHADE-MODERN-R","d-at-12-6-house-shade-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-CASTLE-L","d-at-12-6-knight-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-CASTLE-M","d-at-12-6-knight-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-CASTLE-R","d-at-12-6-knight-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-CASTLE1-L","d-at-12-6-knight-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-CASTLE1-M","d-at-12-6-knight-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-CASTLE1-R","d-at-12-6-knight-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-FLAG-L","d-at-12-6-knight-flag-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-FLAG-M","d-at-12-6-knight-flag-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-FLAG-R","d-at-12-6-knight-flag-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-FLAG1-L","d-at-12-6-knight-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-FLAG1-M","d-at-12-6-knight-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-FLAG1-R","d-at-12-6-knight-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-CASTLE-L","d-at-12-6-knight-shade-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-CASTLE-M","d-at-12-6-knight-shade-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-CASTLE-R","d-at-12-6-knight-shade-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-CASTLE1-L","d-at-12-6-knight-shade-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-CASTLE1-M","d-at-12-6-knight-shade-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-CASTLE1-R","d-at-12-6-knight-shade-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-FLAG1-L","d-at-12-6-knight-shade-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-FLAG1-M","d-at-12-6-knight-shade-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-FLAG1-R","d-at-12-6-knight-shade-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-FLAG-L","d-at-12-6-knight-shade-flag-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-FLAG-M","d-at-12-6-knight-shade-flag-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-KNIGHT-SHADE-FLAG-R","d-at-12-6-knight-shade-flag-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-AERIAL-L","d-at-12-6-space-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-AERIAL-M","d-at-12-6-space-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-AERIAL-R","d-at-12-6-space-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-UFO-L","d-at-12-6-space-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-UFO-M","d-at-12-6-space-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-UFO-R","d-at-12-6-space-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-LIGHT-L","d-at-12-6-space-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-LIGHT-M","d-at-12-6-space-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-LIGHT-R","d-at-12-6-space-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-AERIAL-L","d-at-12-6-space-shade-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-AERIAL-M","d-at-12-6-space-shade-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-AERIAL-R","d-at-12-6-space-shade-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-UFO-L","d-at-12-6-space-shade-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-UFO-M","d-at-12-6-space-shade-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-UFO-R","d-at-12-6-space-shade-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-LIGHT-L","d-at-12-6-space-shade-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-LIGHT-M","d-at-12-6-space-shade-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-LIGHT-R","d-at-12-6-space-shade-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-STAR-L","d-at-12-6-space-shade-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-STAR-M","d-at-12-6-space-shade-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-SHADE-STAR-R","d-at-12-6-space-shade-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-STAR-L","d-at-12-6-space-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-STAR-M","d-at-12-6-space-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-SPACE-STAR-R","d-at-12-6-space-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-DOT-L","d-at-12-6-standard-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-DOT-M","d-at-12-6-standard-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-DOT-R","d-at-12-6-standard-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-L","d-at-12-6-standard-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-M","d-at-12-6-standard-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-R","d-at-12-6-standard-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-SHADE-DOT-L","d-at-12-6-standard-shade-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-SHADE-DOT-M","d-at-12-6-standard-shade-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-SHADE-DOT-R","d-at-12-6-standard-shade-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-SHADE-L","d-at-12-6-standard-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-SHADE-M","d-at-12-6-standard-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-STANDARD-SHADE-R","d-at-12-6-standard-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-FLOWER1-L","d-at-12-6-tale-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-FLOWER1-M","d-at-12-6-tale-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-FLOWER1-R","d-at-12-6-tale-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-FLOWER2-L","d-at-12-6-tale-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-FLOWER2-M","d-at-12-6-tale-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-FLOWER2-R","d-at-12-6-tale-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-FLOWER-L","d-at-12-6-tale-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-FLOWER-M","d-at-12-6-tale-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-FLOWER-R","d-at-12-6-tale-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-CASTLE2-L","d-at-12-6-tale-shade-castle2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-CASTLE2-M","d-at-12-6-tale-shade-castle2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-CASTLE2-R","d-at-12-6-tale-shade-castle2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-CASTLE3-L","d-at-12-6-tale-shade-castle3-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-CASTLE3-M","d-at-12-6-tale-shade-castle3-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-CASTLE3-R","d-at-12-6-tale-shade-castle3-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-FLOWER1-L","d-at-12-6-tale-shade-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-FLOWER1-M","d-at-12-6-tale-shade-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-FLOWER1-R","d-at-12-6-tale-shade-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-FLOWER2-L","d-at-12-6-tale-shade-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-FLOWER2-M","d-at-12-6-tale-shade-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-FLOWER2-R","d-at-12-6-tale-shade-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-FLOWER-L","d-at-12-6-tale-shade-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-FLOWER-M","d-at-12-6-tale-shade-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-TALE-SHADE-FLOWER-R","d-at-12-6-tale-shade-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-VICI-L","d-at-12-6-vici-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-VICI-M","d-at-12-6-vici-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-VICI-R","d-at-12-6-vici-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-VICI-SHADE-L","d-at-12-6-vici-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-VICI-SHADE-M","d-at-12-6-vici-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-VICI-SHADE-R","d-at-12-6-vici-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-GIRAFFE-L","d-at-12-6-zoo-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-GIRAFFE-M","d-at-12-6-zoo-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-GIRAFFE-R","d-at-12-6-zoo-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-SHADE-GIRAFFE-L","d-at-12-6-zoo-shade-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-SHADE-GIRAFFE-M","d-at-12-6-zoo-shade-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-SHADE-GIRAFFE-R","d-at-12-6-zoo-shade-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-MONKEY-L","d-at-12-6-zoo-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-MONKEY-M","d-at-12-6-zoo-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-MONKEY-R","d-at-12-6-zoo-monkey-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-SHADE-MONKEY-L","d-at-12-6-zoo-shade-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-SHADE-MONKEY-M","d-at-12-6-zoo-shade-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-12-6-ZOO-SHADE-MONKEY-R","d-at-12-6-zoo-shade-monkey-r.svg", ImageCategory.WRITING_LINEATURE),

    new CategoryImage("D-AT-16-8-ESKIMO-IGLU-L","d-at-16-8-eskimo-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ESKIMO-IGLU-M","d-at-16-8-eskimo-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ESKIMO-IGLU-R","d-at-16-8-eskimo-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ESKIMO-SHADE-IGLU-L","d-at-16-8-eskimo-shade-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ESKIMO-SHADE-IGLU-M","d-at-16-8-eskimo-shade-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ESKIMO-SHADE-IGLU-R","d-at-16-8-eskimo-shade-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-HOUSE-MODERN-L","d-at-16-8-house-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-HOUSE-MODERN-M","d-at-16-8-house-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-HOUSE-MODERN-R","d-at-16-8-house-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-HOUSE-SHADE-MODERN-L","d-at-16-8-house-shade-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-HOUSE-SHADE-MODERN-M","d-at-16-8-house-shade-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-HOUSE-SHADE-MODERN-R","d-at-16-8-house-shade-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-CASTLE-L","d-at-16-8-knight-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-CASTLE-M","d-at-16-8-knight-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-CASTLE-R","d-at-16-8-knight-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-CASTLE1-L","d-at-16-8-knight-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-CASTLE1-M","d-at-16-8-knight-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-CASTLE1-R","d-at-16-8-knight-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-FLAG1-L","d-at-16-8-knight-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-FLAG1-M","d-at-16-8-knight-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-FLAG1-R","d-at-16-8-knight-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-CASTLE-L","d-at-16-8-knight-shade-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-CASTLE-M","d-at-16-8-knight-shade-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-CASTLE-R","d-at-16-8-knight-shade-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-CASTLE1-L","d-at-16-8-knight-shade-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-CASTLE1-M","d-at-16-8-knight-shade-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-CASTLE1-R","d-at-16-8-knight-shade-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-FLAG1-L","d-at-16-8-knight-shade-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-FLAG1-M","d-at-16-8-knight-shade-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-FLAG1-R","d-at-16-8-knight-shade-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-FLAG-L","d-at-16-8-knight-shade-flag-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-FLAG-M","d-at-16-8-knight-shade-flag-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-KNIGHT-SHADE-FLAG-R","d-at-16-8-knight-shade-flag-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-AERIAL-L","d-at-16-8-space-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-AERIAL-M","d-at-16-8-space-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-AERIAL-R","d-at-16-8-space-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-UFO-L","d-at-16-8-space-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-UFO-M","d-at-16-8-space-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-UFO-R","d-at-16-8-space-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-LIGHT-L","d-at-16-8-space-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-LIGHT-M","d-at-16-8-space-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-LIGHT-R","d-at-16-8-space-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-AERIAL-L","d-at-16-8-space-shade-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-AERIAL-M","d-at-16-8-space-shade-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-AERIAL-R","d-at-16-8-space-shade-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-UFO-L","d-at-16-8-space-shade-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-UFO-M","d-at-16-8-space-shade-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-UFO-R","d-at-16-8-space-shade-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-LIGHT-L","d-at-16-8-space-shade-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-LIGHT-M","d-at-16-8-space-shade-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-LIGHT-R","d-at-16-8-space-shade-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-STAR-L","d-at-16-8-space-shade-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-STAR-M","d-at-16-8-space-shade-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-SHADE-STAR-R","d-at-16-8-space-shade-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-STAR-L","d-at-16-8-space-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-STAR-M","d-at-16-8-space-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-SPACE-STAR-R","d-at-16-8-space-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-DOT-L","d-at-16-8-standard-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-DOT-M","d-at-16-8-standard-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-DOT-R","d-at-16-8-standard-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-L","d-at-16-8-standard-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-M","d-at-16-8-standard-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-R","d-at-16-8-standard-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-SHADE-DOT-L","d-at-16-8-standard-shade-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-SHADE-DOT-M","d-at-16-8-standard-shade-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-SHADE-DOT-R","d-at-16-8-standard-shade-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-SHADE-L","d-at-16-8-standard-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-SHADE-M","d-at-16-8-standard-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-STANDARD-SHADE-R","d-at-16-8-standard-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-FLOWER1-L","d-at-16-8-tale-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-FLOWER1-M","d-at-16-8-tale-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-FLOWER1-R","d-at-16-8-tale-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-FLOWER2-L","d-at-16-8-tale-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-FLOWER2-M","d-at-16-8-tale-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-FLOWER2-R","d-at-16-8-tale-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-FLOWER-L","d-at-16-8-tale-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-FLOWER-M","d-at-16-8-tale-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-FLOWER-R","d-at-16-8-tale-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-CASTLE2-L","d-at-16-8-tale-shade-castle2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-CASTLE2-M","d-at-16-8-tale-shade-castle2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-CASTLE2-R","d-at-16-8-tale-shade-castle2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-CASTLE3-L","d-at-16-8-tale-shade-castle3-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-CASTLE3-M","d-at-16-8-tale-shade-castle3-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-CASTLE3-R","d-at-16-8-tale-shade-castle3-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-FLOWER1-L","d-at-16-8-tale-shade-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-FLOWER1-M","d-at-16-8-tale-shade-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-FLOWER1-R","d-at-16-8-tale-shade-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-FLOWER2-L","d-at-16-8-tale-shade-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-FLOWER2-M","d-at-16-8-tale-shade-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-FLOWER2-R","d-at-16-8-tale-shade-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-FLOWER-L","d-at-16-8-tale-shade-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-FLOWER-M","d-at-16-8-tale-shade-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-TALE-SHADE-FLOWER-R","d-at-16-8-tale-shade-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-VICI-L","d-at-16-8-vici-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-VICI-M","d-at-16-8-vici-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-VICI-R","d-at-16-8-vici-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-VICI-SHADE-L","d-at-16-8-vici-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-VICI-SHADE-M","d-at-16-8-vici-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-VICI-SHADE-R","d-at-16-8-vici-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-GIRAFFE-L","d-at-16-8-zoo-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-GIRAFFE-M","d-at-16-8-zoo-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-GIRAFFE-R","d-at-16-8-zoo-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-SHADE-GIRAFFE-L","d-at-16-8-zoo-shade-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-SHADE-GIRAFFE-M","d-at-16-8-zoo-shade-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-SHADE-GIRAFFE-R","d-at-16-8-zoo-shade-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-MONKEY-L","d-at-16-8-zoo-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-MONKEY-M","d-at-16-8-zoo-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-MONKEY-R","d-at-16-8-zoo-monkey-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-SHADE-MONKEY-L","d-at-16-8-zoo-shade-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-SHADE-MONKEY-M","d-at-16-8-zoo-shade-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-16-8-ZOO-SHADE-MONKEY-R","d-at-16-8-zoo-shade-monkey-r.svg", ImageCategory.WRITING_LINEATURE),

    new CategoryImage("D-AT-4-4-4-ESKIMO-IGLU-L","d-at-4-4-4-eskimo-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ESKIMO-IGLU-M","d-at-4-4-4-eskimo-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ESKIMO-IGLU-R","d-at-4-4-4-eskimo-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ESKIMO-SHADE-IGLU-L","d-at-4-4-4-eskimo-shade-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ESKIMO-SHADE-IGLU-M","d-at-4-4-4-eskimo-shade-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ESKIMO-SHADE-IGLU-R","d-at-4-4-4-eskimo-shade-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-HOUSE-MODERN-L","d-at-4-4-4-house-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-HOUSE-MODERN-M","d-at-4-4-4-house-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-HOUSE-MODERN-R","d-at-4-4-4-house-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-HOUSE-SHADE-MODERN-L","d-at-4-4-4-house-shade-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-HOUSE-SHADE-MODERN-M","d-at-4-4-4-house-shade-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-HOUSE-SHADE-MODERN-R","d-at-4-4-4-house-shade-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-CASTLE-L","d-at-4-4-4-knight-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-CASTLE-M","d-at-4-4-4-knight-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-CASTLE-R","d-at-4-4-4-knight-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-CASTLE1-L","d-at-4-4-4-knight-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-CASTLE1-M","d-at-4-4-4-knight-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-CASTLE1-R","d-at-4-4-4-knight-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-FLAG1-L","d-at-4-4-4-knight-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-FLAG1-M","d-at-4-4-4-knight-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-FLAG1-R","d-at-4-4-4-knight-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-CASTLE-L","d-at-4-4-4-knight-shade-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-CASTLE-M","d-at-4-4-4-knight-shade-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-CASTLE-R","d-at-4-4-4-knight-shade-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-CASTLE1-L","d-at-4-4-4-knight-shade-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-CASTLE1-M","d-at-4-4-4-knight-shade-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-CASTLE1-R","d-at-4-4-4-knight-shade-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-FLAG1-L","d-at-4-4-4-knight-shade-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-FLAG1-M","d-at-4-4-4-knight-shade-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-FLAG1-R","d-at-4-4-4-knight-shade-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-FLAG-L","d-at-4-4-4-knight-shade-flag-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-FLAG-M","d-at-4-4-4-knight-shade-flag-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-FLAG-R","d-at-4-4-4-knight-shade-flag-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-AERIAL-L","d-at-4-4-4-space-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-AERIAL-M","d-at-4-4-4-space-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-AERIAL-R","d-at-4-4-4-space-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-UFO-L","d-at-4-4-4-space-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-UFO-M","d-at-4-4-4-space-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-UFO-R","d-at-4-4-4-space-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-LIGHT-L","d-at-4-4-4-space-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-LIGHT-R","d-at-4-4-4-space-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-AERIAL-L","d-at-4-4-4-space-shade-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-AERIAL-M","d-at-4-4-4-space-shade-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-AERIAL-R","d-at-4-4-4-space-shade-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-UFO-L","d-at-4-4-4-space-shade-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-UFO-M","d-at-4-4-4-space-shade-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-UFO-R","d-at-4-4-4-space-shade-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-LIGHT-L","d-at-4-4-4-space-shade-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-LIGHT-R","d-at-4-4-4-space-shade-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-STAR-L","d-at-4-4-4-space-shade-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-STAR-M","d-at-4-4-4-space-shade-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-STAR-R","d-at-4-4-4-space-shade-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-STAR-L","d-at-4-4-4-space-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-STAR-M","d-at-4-4-4-space-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-SPACE-STAR-R","d-at-4-4-4-space-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-DOT-L","d-at-4-4-4-standard-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-DOT-M","d-at-4-4-4-standard-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-DOT-R","d-at-4-4-4-standard-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-L","d-at-4-4-4-standard-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-M","d-at-4-4-4-standard-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-R","d-at-4-4-4-standard-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-SHADE-DOT-L","d-at-4-4-4-standard-shade-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-SHADE-DOT-M","d-at-4-4-4-standard-shade-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-SHADE-DOT-R","d-at-4-4-4-standard-shade-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-SHADE-L","d-at-4-4-4-standard-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-SHADE-M","d-at-4-4-4-standard-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-STANDARD-SHADE-R","d-at-4-4-4-standard-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER1-L","d-at-4-4-4-tale-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER1-M","d-at-4-4-4-tale-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER1-R","d-at-4-4-4-tale-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER2-L","d-at-4-4-4-tale-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER2-M","d-at-4-4-4-tale-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER2-R","d-at-4-4-4-tale-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER-L","d-at-4-4-4-tale-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER-M","d-at-4-4-4-tale-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER-R","d-at-4-4-4-tale-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-CASTLE2-L","d-at-4-4-4-tale-shade-castle2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-CASTLE2-M","d-at-4-4-4-tale-shade-castle2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-CASTLE2-R","d-at-4-4-4-tale-shade-castle2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-CASTLE3-L","d-at-4-4-4-tale-shade-castle3-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-CASTLE3-M","d-at-4-4-4-tale-shade-castle3-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-CASTLE3-R","d-at-4-4-4-tale-shade-castle3-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER1-L","d-at-4-4-4-tale-shade-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER1-M","d-at-4-4-4-tale-shade-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER1-R","d-at-4-4-4-tale-shade-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER2-L","d-at-4-4-4-tale-shade-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER2-M","d-at-4-4-4-tale-shade-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER2-R","d-at-4-4-4-tale-shade-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER-L","d-at-4-4-4-tale-shade-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER-M","d-at-4-4-4-tale-shade-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER-R","d-at-4-4-4-tale-shade-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-VICI-L","d-at-4-4-4-vici-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-VICI-M","d-at-4-4-4-vici-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-VICI-R","d-at-4-4-4-vici-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-VICI-SHADE-L","d-at-4-4-4-vici-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-VICI-SHADE-M","d-at-4-4-4-vici-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-VICI-SHADE-R","d-at-4-4-4-vici-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-GIRAFFE-L","d-at-4-4-4-zoo-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-GIRAFFE-M","d-at-4-4-4-zoo-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-GIRAFFE-R","d-at-4-4-4-zoo-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-SHADE-GIRAFFE-L","d-at-4-4-4-zoo-shade-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-SHADE-GIRAFFE-M","d-at-4-4-4-zoo-shade-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-SHADE-GIRAFFE-R","d-at-4-4-4-zoo-shade-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-MONKEY-L","d-at-4-4-4-zoo-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-MONKEY-M","d-at-4-4-4-zoo-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-MONKEY-R","d-at-4-4-4-zoo-monkey-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-SHADE-MONKEY-L","d-at-4-4-4-zoo-shade-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-SHADE-MONKEY-M","d-at-4-4-4-zoo-shade-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-4-4-4-ZOO-SHADE-MONKEY-R","d-at-4-4-4-zoo-shade-monkey-r.svg", ImageCategory.WRITING_LINEATURE),

    new CategoryImage("D-AT-6-6-6-ESKIMO-IGLU-L","d-at-6-6-6-eskimo-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ESKIMO-IGLU-M","d-at-6-6-6-eskimo-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ESKIMO-IGLU-R","d-at-6-6-6-eskimo-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ESKIMO-SHADE-IGLU-L","d-at-6-6-6-eskimo-shade-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ESKIMO-SHADE-IGLU-M","d-at-6-6-6-eskimo-shade-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ESKIMO-SHADE-IGLU-R","d-at-6-6-6-eskimo-shade-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-HOUSE-MODERN-L","d-at-6-6-6-house-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-HOUSE-MODERN-M","d-at-6-6-6-house-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-HOUSE-MODERN-R","d-at-6-6-6-house-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-HOUSE-SHADE-MODERN-L","d-at-6-6-6-house-shade-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-HOUSE-SHADE-MODERN-M","d-at-6-6-6-house-shade-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-HOUSE-SHADE-MODERN-R","d-at-6-6-6-house-shade-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-CASTLE-L","d-at-6-6-6-knight-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-CASTLE-M","d-at-6-6-6-knight-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-CASTLE-R","d-at-6-6-6-knight-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-CASTLE1-L","d-at-6-6-6-knight-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-CASTLE1-M","d-at-6-6-6-knight-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-CASTLE1-R","d-at-6-6-6-knight-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-FLAG1-L","d-at-6-6-6-knight-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-FLAG1-M","d-at-6-6-6-knight-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-FLAG1-R","d-at-6-6-6-knight-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-CASTLE-L","d-at-6-6-6-knight-shade-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-CASTLE-M","d-at-6-6-6-knight-shade-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-CASTLE-R","d-at-6-6-6-knight-shade-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-CASTLE1-L","d-at-6-6-6-knight-shade-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-CASTLE1-M","d-at-6-6-6-knight-shade-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-CASTLE1-R","d-at-6-6-6-knight-shade-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-FLAG1-L","d-at-6-6-6-knight-shade-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-FLAG1-M","d-at-6-6-6-knight-shade-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-FLAG1-R","d-at-6-6-6-knight-shade-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-FLAG-L","d-at-6-6-6-knight-shade-flag-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-FLAG-M","d-at-6-6-6-knight-shade-flag-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-KNIGHT-SHADE-FLAG-R","d-at-6-6-6-knight-shade-flag-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-AERIAL-L","d-at-6-6-6-space-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-AERIAL-M","d-at-6-6-6-space-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-AERIAL-R","d-at-6-6-6-space-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-UFO-L","d-at-6-6-6-space-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-UFO-M","d-at-6-6-6-space-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-UFO-R","d-at-6-6-6-space-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-LIGHT-L","d-at-6-6-6-space-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-LIGHT-M","d-at-6-6-6-space-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-LIGHT-R","d-at-6-6-6-space-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-AERIAL-L","d-at-6-6-6-space-shade-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-AERIAL-M","d-at-6-6-6-space-shade-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-AERIAL-R","d-at-6-6-6-space-shade-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-UFO-L","d-at-6-6-6-space-shade-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-UFO-M","d-at-6-6-6-space-shade-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-UFO-R","d-at-6-6-6-space-shade-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-LIGHT-L","d-at-6-6-6-space-shade-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-LIGHT-M","d-at-6-6-6-space-shade-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-LIGHT-R","d-at-6-6-6-space-shade-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-STAR-M","d-at-6-6-6-space-shade-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-SHADE-STAR-R","d-at-6-6-6-space-shade-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-STAR-L","d-at-6-6-6-space-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-STAR-M","d-at-6-6-6-space-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-SPACE-STAR-R","d-at-6-6-6-space-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-DOT-L","d-at-6-6-6-standard-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-DOT-M","d-at-6-6-6-standard-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-DOT-R","d-at-6-6-6-standard-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-L","d-at-6-6-6-standard-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-M","d-at-6-6-6-standard-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-R","d-at-6-6-6-standard-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-SHADE-DOT-L","d-at-6-6-6-standard-shade-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-SHADE-DOT-M","d-at-6-6-6-standard-shade-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-SHADE-DOT-R","d-at-6-6-6-standard-shade-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-SHADE-L","d-at-6-6-6-standard-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-SHADE-M","d-at-6-6-6-standard-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-STANDARD-SHADE-R","d-at-6-6-6-standard-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-FLOWER1-L","d-at-6-6-6-tale-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-FLOWER1-M","d-at-6-6-6-tale-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-FLOWER1-R","d-at-6-6-6-tale-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-FLOWER2-L","d-at-6-6-6-tale-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-FLOWER2-M","d-at-6-6-6-tale-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-FLOWER2-R","d-at-6-6-6-tale-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-FLOWER-L","d-at-6-6-6-tale-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-FLOWER-M","d-at-6-6-6-tale-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-FLOWER-R","d-at-6-6-6-tale-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-CASTLE2-L","d-at-6-6-6-tale-shade-castle2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-CASTLE2-M","d-at-6-6-6-tale-shade-castle2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-CASTLE2-R","d-at-6-6-6-tale-shade-castle2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-CASTLE3-L","d-at-6-6-6-tale-shade-castle3-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-CASTLE3-M","d-at-6-6-6-tale-shade-castle3-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-CASTLE3-R","d-at-6-6-6-tale-shade-castle3-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-FLOWER1-L","d-at-6-6-6-tale-shade-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-FLOWER1-M","d-at-6-6-6-tale-shade-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-FLOWER1-R","d-at-6-6-6-tale-shade-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-FLOWER2-L","d-at-6-6-6-tale-shade-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-FLOWER2-M","d-at-6-6-6-tale-shade-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-FLOWER2-R","d-at-6-6-6-tale-shade-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-FLOWER-L","d-at-6-6-6-tale-shade-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-FLOWER-M","d-at-6-6-6-tale-shade-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-TALE-SHADE-FLOWER-R","d-at-6-6-6-tale-shade-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-VICI-L","d-at-6-6-6-vici-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-VICI-M","d-at-6-6-6-vici-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-VICI-R","d-at-6-6-6-vici-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-VICI-SHADE-L","d-at-6-6-6-vici-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-VICI-SHADE-M","d-at-6-6-6-vici-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-VICI-SHADE-R","d-at-6-6-6-vici-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-GIRAFFE-L","d-at-6-6-6-zoo-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-GIRAFFE-M","d-at-6-6-6-zoo-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-GIRAFFE-R","d-at-6-6-6-zoo-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-SHADE-GIRAFFE-L","d-at-6-6-6-zoo-shade-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-SHADE-GIRAFFE-M","d-at-6-6-6-zoo-shade-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-SHADE-GIRAFFE-R","d-at-6-6-6-zoo-shade-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-MONKEY-L","d-at-6-6-6-zoo-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-MONKEY-M","d-at-6-6-6-zoo-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-MONKEY-R","d-at-6-6-6-zoo-monkey-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-SHADE-MONKEY-L","d-at-6-6-6-zoo-shade-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-SHADE-MONKEY-M","d-at-6-6-6-zoo-shade-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-6-6-6-ZOO-SHADE-MONKEY-R","d-at-6-6-6-zoo-shade-monkey-r.svg", ImageCategory.WRITING_LINEATURE),

    new CategoryImage("D-AT-8-4-ESKIMO-IGLU-L","d-at-8-4-eskimo-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ESKIMO-IGLU-M","d-at-8-4-eskimo-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ESKIMO-IGLU-R","d-at-8-4-eskimo-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ESKIMO-SHADE-IGLU-L","d-at-8-4-eskimo-shade-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ESKIMO-SHADE-IGLU-M","d-at-8-4-eskimo-shade-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ESKIMO-SHADE-IGLU-R","d-at-8-4-eskimo-shade-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-HOUSE-MODERN-L","d-at-8-4-house-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-HOUSE-MODERN-M","d-at-8-4-house-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-HOUSE-MODERN-R","d-at-8-4-house-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-HOUSE-SHADE-MODERN-L","d-at-8-4-house-shade-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-HOUSE-SHADE-MODERN-M","d-at-8-4-house-shade-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-HOUSE-SHADE-MODERN-R","d-at-8-4-house-shade-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-CASTLE-L","d-at-8-4-knight-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-CASTLE-M","d-at-8-4-knight-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-CASTLE-R","d-at-8-4-knight-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-CASTLE1-L","d-at-8-4-knight-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-CASTLE1-M","d-at-8-4-knight-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-CASTLE1-R","d-at-8-4-knight-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-FLAG1-L","d-at-8-4-knight-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-FLAG1-M","d-at-8-4-knight-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-FLAG1-R","d-at-8-4-knight-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-CASTLE-L","d-at-8-4-knight-shade-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-CASTLE-M","d-at-8-4-knight-shade-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-CASTLE-R","d-at-8-4-knight-shade-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-CASTLE1-L","d-at-8-4-knight-shade-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-CASTLE1-M","d-at-8-4-knight-shade-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-CASTLE1-R","d-at-8-4-knight-shade-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-FLAG1-L","d-at-8-4-knight-shade-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-FLAG1-M","d-at-8-4-knight-shade-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-FLAG1-R","d-at-8-4-knight-shade-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-FLAG-L","d-at-8-4-knight-shade-flag-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-FLAG-M","d-at-8-4-knight-shade-flag-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-FLAG-R","d-at-8-4-knight-shade-flag-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-AERIAL-L","d-at-8-4-space-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-AERIAL-M","d-at-8-4-space-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-AERIAL-R","d-at-8-4-space-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-UFO-L","d-at-8-4-space-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-UFO-M","d-at-8-4-space-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-UFO-R","d-at-8-4-space-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-LIGHT-L","d-at-8-4-space-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-LIGHT-M","d-at-8-4-space-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-LIGHT-R","d-at-8-4-space-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-AERIAL-L","d-at-8-4-space-shade-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-AERIAL-M","d-at-8-4-space-shade-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-AERIAL-R","d-at-8-4-space-shade-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-UFO-L","d-at-8-4-space-shade-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-UFO-M","d-at-8-4-space-shade-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-UFO-R","d-at-8-4-space-shade-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-LIGHT-L","d-at-8-4-space-shade-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-LIGHT-M","d-at-8-4-space-shade-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-LIGHT-R","d-at-8-4-space-shade-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-STAR-L","d-at-8-4-space-shade-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-STAR-M","d-at-8-4-space-shade-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-STAR-R","d-at-8-4-space-shade-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-STAR-L","d-at-8-4-space-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-STAR-M","d-at-8-4-space-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-SPACE-STAR-R","d-at-8-4-space-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-DOT-L","d-at-8-4-standard-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-DOT-M","d-at-8-4-standard-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-DOT-R","d-at-8-4-standard-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-L","d-at-8-4-standard-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-M","d-at-8-4-standard-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-R","d-at-8-4-standard-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-SHADE-DOT-L","d-at-8-4-standard-shade-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-SHADE-DOT-M","d-at-8-4-standard-shade-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-SHADE-DOT-R","d-at-8-4-standard-shade-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-SHADE-L","d-at-8-4-standard-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-SHADE-M","d-at-8-4-standard-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-STANDARD-SHADE-R","d-at-8-4-standard-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-FLOWER1-L","d-at-8-4-tale-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-FLOWER1-M","d-at-8-4-tale-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-FLOWER1-R","d-at-8-4-tale-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-FLOWER2-L","d-at-8-4-tale-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-FLOWER2-M","d-at-8-4-tale-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-FLOWER2-R","d-at-8-4-tale-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-FLOWER-L","d-at-8-4-tale-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-FLOWER-M","d-at-8-4-tale-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-FLOWER-R","d-at-8-4-tale-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-CASTLE2-L","d-at-8-4-tale-shade-castle2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-CASTLE2-M","d-at-8-4-tale-shade-castle2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-CASTLE2-R","d-at-8-4-tale-shade-castle2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-CASTLE3-L","d-at-8-4-tale-shade-castle3-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-CASTLE3-M","d-at-8-4-tale-shade-castle3-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-CASTLE3-R","d-at-8-4-tale-shade-castle3-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER1-L","d-at-8-4-tale-shade-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER1-M","d-at-8-4-tale-shade-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER1-R","d-at-8-4-tale-shade-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER2-L","d-at-8-4-tale-shade-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER2-M","d-at-8-4-tale-shade-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER2-R","d-at-8-4-tale-shade-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER-L","d-at-8-4-tale-shade-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER-M","d-at-8-4-tale-shade-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER-R","d-at-8-4-tale-shade-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-VICI-L","d-at-8-4-vici-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-VICI-M","d-at-8-4-vici-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-VICI-R","d-at-8-4-vici-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-VICI-SHADE-L","d-at-8-4-vici-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-VICI-SHADE-M","d-at-8-4-vici-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-VICI-SHADE-R","d-at-8-4-vici-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-GIRAFFE-L","d-at-8-4-zoo-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-GIRAFFE-M","d-at-8-4-zoo-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-GIRAFFE-R","d-at-8-4-zoo-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-SHADE-GIRAFFE-L","d-at-8-4-zoo-shade-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-SHADE-GIRAFFE-M","d-at-8-4-zoo-shade-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-SHADE-GIRAFFE-R","d-at-8-4-zoo-shade-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-MONKEY-L","d-at-8-4-zoo-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-MONKEY-M","d-at-8-4-zoo-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-MONKEY-R","d-at-8-4-zoo-monkey-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-SHADE-MONKEY-L","d-at-8-4-zoo-shade-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-SHADE-MONKEY-M","d-at-8-4-zoo-shade-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-4-ZOO-SHADE-MONKEY-R","d-at-8-4-zoo-shade-monkey-r.svg", ImageCategory.WRITING_LINEATURE),

    new CategoryImage("D-AT-8-8-8-ESKIMO-IGLU-L","d-at-8-8-8-eskimo-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ESKIMO-IGLU-M","d-at-8-8-8-eskimo-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ESKIMO-IGLU-R","d-at-8-8-8-eskimo-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ESKIMO-SHADE-IGLU-L","d-at-8-8-8-eskimo-shade-iglu-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ESKIMO-SHADE-IGLU-M","d-at-8-8-8-eskimo-shade-iglu-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ESKIMO-SHADE-IGLU-R","d-at-8-8-8-eskimo-shade-iglu-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-HOUSE-MODERN-L","d-at-8-8-8-house-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-HOUSE-MODERN-M","d-at-8-8-8-house-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-HOUSE-MODERN-R","d-at-8-8-8-house-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-HOUSE-SHADE-MODERN-L","d-at-8-8-8-house-shade-modern-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-HOUSE-SHADE-MODERN-M","d-at-8-8-8-house-shade-modern-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-HOUSE-SHADE-MODERN-R","d-at-8-8-8-house-shade-modern-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-CASTLE-L","d-at-8-8-8-knight-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-CASTLE-M","d-at-8-8-8-knight-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-CASTLE-R","d-at-8-8-8-knight-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-CASTLE1-L","d-at-8-8-8-knight-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-CASTLE1-M","d-at-8-8-8-knight-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-CASTLE1-R","d-at-8-8-8-knight-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-FLAG1-L","d-at-8-8-8-knight-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-FLAG1-M","d-at-8-8-8-knight-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-FLAG1-R","d-at-8-8-8-knight-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-CASTLE-L","d-at-8-8-8-knight-shade-castle-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-CASTLE-M","d-at-8-8-8-knight-shade-castle-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-CASTLE-R","d-at-8-8-8-knight-shade-castle-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-CASTLE1-L","d-at-8-8-8-knight-shade-castle1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-CASTLE1-M","d-at-8-8-8-knight-shade-castle1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-CASTLE1-R","d-at-8-8-8-knight-shade-castle1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-FLAG1-L","d-at-8-8-8-knight-shade-flag1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-FLAG1-M","d-at-8-8-8-knight-shade-flag1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-FLAG1-R","d-at-8-8-8-knight-shade-flag1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-FLAG-L","d-at-8-8-8-knight-shade-flag-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-FLAG-M","d-at-8-8-8-knight-shade-flag-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-KNIGHT-SHADE-FLAG-R","d-at-8-8-8-knight-shade-flag-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-AERIAL-L","d-at-8-8-8-space-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-AERIAL-M","d-at-8-8-8-space-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-AERIAL-R","d-at-8-8-8-space-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-UFO-L","d-at-8-8-8-space-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-UFO-M","d-at-8-8-8-space-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-UFO-R","d-at-8-8-8-space-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-LIGHT-L","d-at-8-8-8-space-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-LIGHT-M","d-at-8-8-8-space-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-LIGHT-R","d-at-8-8-8-space-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-AERIAL-L","d-at-8-8-8-space-shade-aerial-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-AERIAL-M","d-at-8-8-8-space-shade-aerial-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-AERIAL-R","d-at-8-8-8-space-shade-aerial-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-UFO-L","d-at-8-8-8-space-shade-ufo-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-UFO-M","d-at-8-8-8-space-shade-ufo-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-UFO-R","d-at-8-8-8-space-shade-ufo-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-LIGHT-L","d-at-8-8-8-space-shade-light-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-LIGHT-M","d-at-8-8-8-space-shade-light-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-LIGHT-R","d-at-8-8-8-space-shade-light-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-STAR-L","d-at-8-8-8-space-shade-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-SHADE-STAR-M","d-at-8-8-8-space-shade-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-STAR-L","d-at-8-8-8-space-star-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-STAR-M","d-at-8-8-8-space-star-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-SPACE-STAR-R","d-at-8-8-8-space-star-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-DOT-L","d-at-8-8-8-standard-dot-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-DOT-M","d-at-8-8-8-standard-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-DOT-R","d-at-8-8-8-standard-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-L","d-at-8-8-8-standard-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-M","d-at-8-8-8-standard-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-R","d-at-8-8-8-standard-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-SHADE-DOT-M","d-at-8-8-8-standard-shade-dot-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-SHADE-DOT-R","d-at-8-8-8-standard-shade-dot-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-SHADE-L","d-at-8-8-8-standard-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-SHADE-M","d-at-8-8-8-standard-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-STANDARD-SHADE-R","d-at-8-8-8-standard-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-FLOWER1-L","d-at-8-8-8-tale-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-FLOWER1-M","d-at-8-8-8-tale-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-FLOWER1-R","d-at-8-8-8-tale-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-FLOWER2-L","d-at-8-8-8-tale-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-FLOWER2-M","d-at-8-8-8-tale-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-FLOWER2-R","d-at-8-8-8-tale-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-FLOWER-L","d-at-8-8-8-tale-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-FLOWER-M","d-at-8-8-8-tale-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-FLOWER-R","d-at-8-8-8-tale-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-CASTLE2-L","d-at-8-8-8-tale-shade-castle2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-CASTLE2-M","d-at-8-8-8-tale-shade-castle2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-CASTLE2-R","d-at-8-8-8-tale-shade-castle2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-CASTLE3-L","d-at-8-8-8-tale-shade-castle3-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-CASTLE3-M","d-at-8-8-8-tale-shade-castle3-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-CASTLE3-R","d-at-8-8-8-tale-shade-castle3-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-FLOWER1-L","d-at-8-8-8-tale-shade-flower1-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-FLOWER1-M","d-at-8-8-8-tale-shade-flower1-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-FLOWER1-R","d-at-8-8-8-tale-shade-flower1-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-FLOWER2-L","d-at-8-8-8-tale-shade-flower2-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-FLOWER2-M","d-at-8-8-8-tale-shade-flower2-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-FLOWER2-R","d-at-8-8-8-tale-shade-flower2-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-FLOWER-L","d-at-8-8-8-tale-shade-flower-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-FLOWER-M","d-at-8-8-8-tale-shade-flower-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-TALE-SHADE-FLOWER-R","d-at-8-8-8-tale-shade-flower-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-VICI-L","d-at-8-8-8-vici-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-VICI-M","d-at-8-8-8-vici-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-VICI-R","d-at-8-8-8-vici-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-VICI-SHADE-L","d-at-8-8-8-vici-shade-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-VICI-SHADE-M","d-at-8-8-8-vici-shade-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-VICI-SHADE-R","d-at-8-8-8-vici-shade-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-GIRAFFE-L","d-at-8-8-8-zoo-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-GIRAFFE-M","d-at-8-8-8-zoo-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-GIRAFFE-R","d-at-8-8-8-zoo-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-SHADE-GIRAFFE-L","d-at-8-8-8-zoo-shade-giraffe-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-SHADE-GIRAFFE-M","d-at-8-8-8-zoo-shade-giraffe-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-SHADE-GIRAFFE-R","d-at-8-8-8-zoo-shade-giraffe-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-MONKEY-L","d-at-8-8-8-zoo-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-MONKEY-M","d-at-8-8-8-zoo-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-MONKEY-R","d-at-8-8-8-zoo-monkey-r.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-SHADE-MONKEY-L","d-at-8-8-8-zoo-shade-monkey-l.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-SHADE-MONKEY-M","d-at-8-8-8-zoo-shade-monkey-m.svg", ImageCategory.WRITING_LINEATURE),
    new CategoryImage("D-AT-8-8-8-ZOO-SHADE-MONKEY-R","d-at-8-8-8-zoo-shade-monkey-r.svg", ImageCategory.WRITING_LINEATURE),

    // Lineature thumbs
    new CategoryImage("D-AT-10-STANDARD-THUMB","d-at-10-standard-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD),
    new CategoryImage("D-AT-10-STANDARD1-THUMB","d-at-10-standard1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD),
    new CategoryImage("D-AT-10-STANDARD2-THUMB","d-at-10-standard2-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD),
    new CategoryImage("D-AT-10-STANDARD3-THUMB","d-at-10-standard3-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD),
    new CategoryImage("D-AT-10-STANDARD4-THUMB","d-at-10-standard4-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD),
    new CategoryImage("D-AT-10-STANDARD5-THUMB","d-at-10-standard5-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD),
    new CategoryImage("D-AT-10-STANDARD6-THUMB","d-at-10-standard6-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD),
    new CategoryImage("D-AT-10-STANDARD7-THUMB","d-at-10-standard7-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD),

    new CategoryImage("D-AT-4-4-4-STANDARD-THUMB","d-at-4-4-4-standard-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-STANDARD-SHADE-THUMB","d-at-4-4-4-standard-shade-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-STANDARD-DOT-THUMB","d-at-4-4-4-standard-dot-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-STANDARD-SHADE-DOT-THUMB","d-at-4-4-4-standard-shade-dot-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-HOUSE-MODERN-THUMB","d-at-4-4-4-house-modern-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-HOUSE-SHADE-MODERN-THUMB","d-at-4-4-4-house-shade-modern-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER-THUMB","d-at-4-4-4-tale-flower-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER-THUMB","d-at-4-4-4-tale-shade-flower-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER1-THUMB","d-at-4-4-4-tale-flower1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-TALE-FLOWER2-THUMB","d-at-4-4-4-tale-flower2-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER1-THUMB","d-at-4-4-4-tale-shade-flower1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-FLOWER2-THUMB","d-at-4-4-4-tale-shade-flower2-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-TALE-CASTLE3-THUMB","d-at-4-4-4-tale-castle3-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-TALE-SHADE-CASTLE3-THUMB","d-at-4-4-4-tale-shade-castle3-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-SPACE-STAR-THUMB","d-at-4-4-4-space-star-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-STAR-THUMB","d-at-4-4-4-space-shade-star-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-SPACE-LIGHT-THUMB","d-at-4-4-4-space-light-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-LIGHT-THUMB","d-at-4-4-4-space-shade-light-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-SPACE-AERIAL-THUMB","d-at-4-4-4-space-aerial-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-SPACE-UFO-THUMB","d-at-4-4-4-space-ufo-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-AERIAL-THUMB","d-at-4-4-4-space-shade-aerial-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-SPACE-SHADE-UFO-THUMB","d-at-4-4-4-space-shade-ufo-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-KNIGHT-CASTLE-THUMB","d-at-4-4-4-knight-castle-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-KNIGHT-CASTLE1-THUMB","d-at-4-4-4-knight-castle1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-CASTLE-THUMB","d-at-4-4-4-knight-shade-castle-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-CASTLE1-THUMB","d-at-4-4-4-knight-shade-castle1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-KNIGHT-FLAG-THUMB","d-at-4-4-4-knight-flag-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-FLAG-THUMB","d-at-4-4-4-knight-shade-flag-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-KNIGHT-FLAG1-THUMB","d-at-4-4-4-knight-flag1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-KNIGHT-SHADE-FLAG1-THUMB","d-at-4-4-4-knight-shade-flag1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-ESKIMO-SHADE-IGLU-THUMB","d-at-4-4-4-eskimo-shade-iglu-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-ESKIMO-IGLU-THUMB","d-at-4-4-4-eskimo-iglu-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-VICI-THUMB","d-at-4-4-4-vici-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-VICI-SHADE-THUMB","d-at-4-4-4-vici-shade-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-ZOO-GIRAFFE-THUMB","d-at-4-4-4-zoo-giraffe-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-ZOO-MONKEY-THUMB","d-at-4-4-4-zoo-monkey-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-ZOO-SHADE-GIRAFFE-THUMB","d-at-4-4-4-zoo-shade-giraffe-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("D-AT-4-4-4-ZOO-SHADE-MONKEY-THUMB","d-at-4-4-4-zoo-shade-monkey-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY),

    new CategoryImage("D-AT-8-4-STANDARD-THUMB","d-at-8-4-standard-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-STANDARD-SHADE-THUMB","d-at-8-4-standard-shade-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-STANDARD-DOT-THUMB","d-at-8-4-standard-dot-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-STANDARD-SHADE-DOT-THUMB","d-at-8-4-standard-shade-dot-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-HOUSE-MODERN-THUMB","d-at-8-4-house-modern-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-HOUSE-SHADE-MODERN-THUMB","d-at-8-4-house-shade-modern-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-TALE-FLOWER-THUMB","d-at-8-4-tale-flower-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER-THUMB","d-at-8-4-tale-shade-flower-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-TALE-FLOWER1-THUMB","d-at-8-4-tale-flower1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-TALE-FLOWER2-THUMB","d-at-8-4-tale-flower2-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER1-THUMB","d-at-8-4-tale-shade-flower1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-TALE-SHADE-FLOWER2-THUMB","d-at-8-4-tale-shade-flower2-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-TALE-CASTLE3-THUMB","d-at-8-4-tale-castle3-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-TALE-SHADE-CASTLE3-THUMB","d-at-8-4-tale-shade-castle3-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-SPACE-STAR-THUMB","d-at-8-4-space-star-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-STAR-THUMB","d-at-8-4-space-shade-star-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-SPACE-LIGHT-THUMB","d-at-8-4-space-light-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-LIGHT-THUMB","d-at-8-4-space-shade-light-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-SPACE-AERIAL-THUMB","d-at-8-4-space-aerial-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-SPACE-UFO-THUMB","d-at-8-4-space-ufo-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-AERIAL-THUMB","d-at-8-4-space-shade-aerial-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-SPACE-SHADE-UFO-THUMB","d-at-8-4-space-shade-ufo-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-KNIGHT-CASTLE-THUMB","d-at-8-4-knight-castle-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-KNIGHT-CASTLE1-THUMB","d-at-8-4-knight-castle1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-CASTLE-THUMB","d-at-8-4-knight-shade-castle-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-KNIGHT-FLAG-THUMB","d-at-8-4-knight-flag-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-FLAG-THUMB","d-at-8-4-knight-shade-flag-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-KNIGHT-FLAG1-THUMB","d-at-8-4-knight-flag1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-KNIGHT-SHADE-FLAG1-THUMB","d-at-8-4-knight-shade-flag1-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-ESKIMO-SHADE-IGLU-THUMB","d-at-8-4-eskimo-shade-iglu-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-ESKIMO-IGLU-THUMB","d-at-8-4-eskimo-iglu-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-VICI-THUMB","d-at-8-4-vici-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-VICI-SHADE-THUMB","d-at-8-4-vici-shade-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-ZOO-GIRAFFE-THUMB","d-at-8-4-zoo-giraffe-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-ZOO-MONKEY-THUMB","d-at-8-4-zoo-monkey-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-ZOO-SHADE-GIRAFFE-THUMB","d-at-8-4-zoo-shade-giraffe-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("D-AT-8-4-ZOO-SHADE-MONKEY-THUMB","d-at-8-4-zoo-shade-monkey-thumb.svg", ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY),

    // Correction margin
    new CategoryImage("CORR_MARGIN_LEFT", "insert_margin_l.svg", ImageCategory.WRITING_LINEATURE_CORRECTION_MARGIN),
    new CategoryImage("CORR_MARGIN_RIGHT", "insert_margin_r.svg", ImageCategory.WRITING_LINEATURE_CORRECTION_MARGIN),

    // Balloon
    new CategoryImage("SPEAK01", "d-at-speak01-thumb.svg", ImageCategory.BALLOON),
    new CategoryImage("SPEAK02", "d-at-speak02-thumb.svg", ImageCategory.BALLOON),
    new CategoryImage("SPEAK03", "d-at-speak03-thumb.svg", ImageCategory.BALLOON),
    new CategoryImage("SPEAK04", "d-at-speak04-thumb.svg", ImageCategory.BALLOON),
    new CategoryImage("SPEAK05", "d-at-speak05-thumb.svg", ImageCategory.BALLOON),
    new CategoryImage("SPEAK06", "d-at-speak06-thumb.svg", ImageCategory.BALLOON),
    new CategoryImage("SPEAK07", "d-at-speak07-thumb.svg", ImageCategory.BALLOON),
    new CategoryImage("SPEAK08", "d-at-speak08-thumb.svg", ImageCategory.BALLOON),
    new CategoryImage("SPEAK09", "d-at-speak09-thumb.svg", ImageCategory.BALLOON),
    new CategoryImage("SPEAK10", "d-at-speak10-thumb.svg", ImageCategory.BALLOON),

    new CategoryImage("THINK01", "d-at-think01-thumb.svg", ImageCategory.BALLOON_THINK),
    new CategoryImage("THINK04", "d-at-think04-thumb.svg", ImageCategory.BALLOON_THINK),

    new CategoryImage("COMIC00", "d-at-comic00-thumb.svg", ImageCategory.BALLOON_COMIC),
    new CategoryImage("COMIC01", "d-at-comic01-thumb.svg", ImageCategory.BALLOON_COMIC),
    new CategoryImage("COMIC02", "d-at-comic02-thumb.svg", ImageCategory.BALLOON_COMIC),
    new CategoryImage("COMIC03", "d-at-comic03-thumb.svg", ImageCategory.BALLOON_COMIC),
    new CategoryImage("COMIC04", "d-at-comic04-thumb.svg", ImageCategory.BALLOON_COMIC),
    new CategoryImage("COMIC05", "d-at-comic05-thumb.svg", ImageCategory.BALLOON_COMIC),
    new CategoryImage("COMIC06", "d-at-comic06-thumb.svg", ImageCategory.BALLOON_COMIC),
    new CategoryImage("COMIC07", "d-at-comic07-thumb.svg", ImageCategory.BALLOON_COMIC),

    // Math Lineature
    new CategoryImage("M-AT-5X5-LINE-THUMB","m-at-5x5-line-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("M-AT-5X5-LINE-FRAME-THUMB","m-at-5x5-line-frame-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("M-AT-5X5-LINE-DOTS-THUMB","m-at-5x5-line-dots-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("M-AT-5X5-LINE-QUAD-THUMB","m-at-5x5-line-quadrat-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("M-AT-5X5-DOTS-THUMB","m-at-5x5-dots-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY),
    new CategoryImage("M-AT-5X5-QUAD-THUMB","m-at-5x5-quadrat-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY),

    new CategoryImage("M-AT-6X10-LINE-THUMB","m-at-6x10-line-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("M-AT-6X10-LINE-FRAME-THUMB","m-at-6x10-line-frame-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("M-AT-6X10-LINE-DOTS-THUMB","m-at-6x10-line-dots-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("M-AT-6X10-LINE-QUAD-THUMB","m-at-6x10-line-quadrat-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("M-AT-6X10-DOTS-THUMB","m-at-6x10-dots-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_UNEVENLY),
    new CategoryImage("M-AT-6X10-QUAD-THUMB","m-at-6x10-quadrat-thumb.svg", ImageCategory.MATH_LINEATURE_THUMBNAIL_UNEVENLY),

    new CategoryImage("DOT","insert_dot.svg", ImageCategory.MATH_LINEATURE_ICON_BUTTON),
    new CategoryImage("CHECK","insert_check.svg", ImageCategory.MATH_LINEATURE_ICON_BUTTON),
    new CategoryImage("PEN","insert_pen.svg", ImageCategory.MATH_LINEATURE_ICON_BUTTON),
    new CategoryImage("CROSS","insert_cross.svg", ImageCategory.MATH_LINEATURE_ICON_BUTTON),
    new CategoryImage("STAR1","insert_star1.svg", ImageCategory.MATH_LINEATURE_ICON_BUTTON),
    new CategoryImage("RING","insert_ring.svg", ImageCategory.MATH_LINEATURE_ICON_BUTTON),

    new CategoryImage("DOT","dot.svg", ImageCategory.MATH_LINEATURE_ICON),
    new CategoryImage("CHECK","check.svg", ImageCategory.MATH_LINEATURE_ICON),
    new CategoryImage("PEN","pen.svg", ImageCategory.MATH_LINEATURE_ICON),
    new CategoryImage("CROSS","cross.svg", ImageCategory.MATH_LINEATURE_ICON),
    new CategoryImage("STAR1","star1.svg", ImageCategory.MATH_LINEATURE_ICON),
    new CategoryImage("RING","ring.svg", ImageCategory.MATH_LINEATURE_ICON),

    // Calculation tower
    new CategoryImage("M-AT-RT-STANDARD-THUMB", "m-at-rt-standard-thumb.svg", ImageCategory.CALCULATION_TOWER_THUMBNAIL),
    new CategoryImage("M-AT-RT-SPACE-THUMB", "m-at-rt-space-thumb.svg", ImageCategory.CALCULATION_TOWER_THUMBNAIL),
    new CategoryImage("M-AT-RT-TALE-CASTLE3-THUMB", "m-at-rt-tale-castle3-thumb.svg", ImageCategory.CALCULATION_TOWER_THUMBNAIL),

    new CategoryImage("M-AT-RT-STANDARD-FL", "m-at-rt-standard-floor.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-STANDARD-KY", "m-at-rt-standard-key.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-STANDARD-OP", "m-at-rt-standard-operator.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-STANDARD-RF", "m-at-rt-standard-roof.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-STANDARD-RW", "m-at-rt-standard-row.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-TALE-CASTLE3-FL", "m-at-rt-tale-castle3-floor.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-TALE-CASTLE3-KY", "m-at-rt-tale-castle3-key.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-TALE-CASTLE3-OP", "m-at-rt-tale-castle3-operator.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-TALE-CASTLE3-RF", "m-at-rt-tale-castle3-roof.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-TALE-CASTLE3-RW", "m-at-rt-tale-castle3-row.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-SPACE-FL", "m-at-rt-space-floor.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-SPACE-KY", "m-at-rt-space-key.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-SPACE-OP", "m-at-rt-space-operator.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-SPACE-RF", "m-at-rt-space-roof.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-SPACE-RW", "m-at-rt-space-row.svg", ImageCategory.CALCULATION_TOWER),

    // Calculation Tower Solution Bars
    new CategoryImage("M-AT-RT-TALE-CASTLE3-SOLUTION1", "m-at-rt-tale03-solution1.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-TALE-CASTLE3-SOLUTION2", "m-at-rt-tale03-solution2.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-TALE-CASTLE3-SOLUTION3", "m-at-rt-tale03-solution3.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-SPACE-SOLUTION1", "m-at-rt-space01-solution1.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-SPACE-SOLUTION2", "m-at-rt-space01-solution2.svg", ImageCategory.CALCULATION_TOWER),
    new CategoryImage("M-AT-RT-SPACE-SOLUTION3", "m-at-rt-space01-solution3.svg", ImageCategory.CALCULATION_TOWER),

    // Text Exercise
    new CategoryImage("M-AT-S4-A1-10", "m-at-s4-a1-10-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR10),
    new CategoryImage("M-AT-S4-S1-10", "m-at-s4-s1-10-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR10),

    new CategoryImage("M-AT-S4-A1-100", "m-at-s4-a1-100-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR100),
    new CategoryImage("M-AT-S4-S1-100", "m-at-s4-s1-100-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR100),
    new CategoryImage("M-AT-S4-M1-100", "m-at-s4-m1-100-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR100),
    new CategoryImage("M-AT-S4-D1-100", "m-at-s4-d1-100-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR100),
    new CategoryImage("M-AT-S4-G1-100", "m-at-s4-g1-100-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR100),
    new CategoryImage("M-AT-S4-G2-100", "m-at-s4-g2-100-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR100),
    new CategoryImage("M-AT-S4-G3-100", "m-at-s4-g3-100-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR100),

    new CategoryImage("M-AT-S4-A1-1000", "m-at-s4-a1-1000-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR1000),
    new CategoryImage("M-AT-S4-S1-1000", "m-at-s4-s1-1000-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR1000),
    new CategoryImage("M-AT-S4-M1-1000", "m-at-s4-m1-1000-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR1000),
    new CategoryImage("M-AT-S4-D1-1000", "m-at-s4-d1-1000-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR1000),
    new CategoryImage("M-AT-S4-G1-1000", "m-at-s4-g1-1000-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR1000),
    new CategoryImage("M-AT-S4-G2-1000", "m-at-s4-g2-1000-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR1000),
    new CategoryImage("M-AT-S4-G3-1000", "m-at-s4-g3-1000-thumb.svg", ImageCategory.TEXT_EXERCISE_ZR1000),

    new CategoryImage("RECKON_BRACKET_UP", "reckon_check_up.svg", ImageCategory.RECKON_BRACKET),
    new CategoryImage("RECKON_BRACKET_DOWN", "reckon_check_down.svg", ImageCategory.RECKON_BRACKET),

    // Forms 2D
    new CategoryImage("FORM_SQUARE", "m-at-2d-form-quadrat-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_RECTANGLE", "m-at-2d-form-rechteck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_CIRCLE", "m-at-2d-form-kreis-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_ELLIPSE", "m-at-2d-form-elypse-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_TRIANGLE_ISOSCELES", "m-at-2d-form-gleichschenkeliges_dreieck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_TRIANGLE_EQUILATERAL", "m-at-2d-form-gleichseitiges_dreieck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_TRIANGLE_RIGHT_ANGLE", "m-at-2d-form-rechtwinkeliges_dreieck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_TRIANGLE_OBTUSE", "m-at-2d-form-stumpfwinkeliges_dreieck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_TRIANGLE_ACUTE", "m-at-2d-form-spitzwinkeliges_dreieck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_TRIANGLE_INEQUILATERAL", "m-at-2d-form-ungleichseitiges_dreieck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_CROSS", "m-at-2d-form-kreuz-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_ARROW", "m-at-2d-form-pfeil-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_STAR", "m-at-2d-form-stern-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_HEXAGRAM", "m-at-2d-form-stern_hexagramm-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_SEMICIRCLE", "m-at-2d-form-halbkreis-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_PENTAGON", "m-at-2d-form-fuenfeck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_HEXAGON", "m-at-2d-form-sechseck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_OCTAGON", "m-at-2d-form-achteck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_KITE_SQUARE", "m-at-2d-form-drachenviereck-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_RHOMBUS", "m-at-2d-form-raute-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_PARALLELOGRAM", "m-at-2d-form-parallelogramm-thumb.svg", ImageCategory.FORMS_2D),
    new CategoryImage("FORM_TRAPEZOID", "m-at-2d-form-trapez-thumb.svg", ImageCategory.FORMS_2D),

    // Forms 3D
    new CategoryImage("FORM_CUBE", "m-at-3d-form-wuerfel1-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_CUBE_LINES", "m-at-3d-form-wuerfel2-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_CUBOID", "m-at-3d-form-quader1-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_CUBOID_LINES", "m-at-3d-form-quader2-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_CUBOID_HIGH", "m-at-3d-form-quader-hoch1-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_CUBOID_HIGH_LINES", "m-at-3d-form-quader-hoch2-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_SPHERE", "m-at-3d-form-kugel1-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_SPHERE_LINES", "m-at-3d-form-kugel2-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_PRISMA_3SIDED", "m-at-3d-form-dreieckiges-prisma1-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_PRISMA_3SIDED_LINES", "m-at-3d-form-dreieckiges-prisma2-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_CONE", "m-at-3d-form-kegel1-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_CONE_LINES", "m-at-3d-form-kegel2-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_PYRAMID", "m-at-3d-form-pyramide1-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_PYRAMID_LINES", "m-at-3d-form-pyramide2-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_PRISMA_6SIDED", "m-at-3d-form-sechseckiges-prisma1-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_PRISMA_6SIDED_LINES", "m-at-3d-form-sechseckiges-prisma2-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_CYLINDER", "m-at-3d-form-zylinder1-thumb.svg", ImageCategory.FORMS_3D),
    new CategoryImage("FORM_CYLINDER_LINES", "m-at-3d-form-zylinder2-thumb.svg", ImageCategory.FORMS_3D),

    // Shape Building Bricks
    new CategoryImage("FORM_BRICK_2", "m-at-3d-baukloetz-2-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_3", "m-at-3d-baukloetz-3-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_0", "m-at-3d-baukloetz-0-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_1", "m-at-3d-baukloetz-1-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_4", "m-at-3d-baukloetz-4-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_5", "m-at-3d-baukloetz-5-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_6", "m-at-3d-baukloetz-6-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_7", "m-at-3d-baukloetz-7-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_8", "m-at-3d-baukloetz-8-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_9", "m-at-3d-baukloetz-9-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_10", "m-at-3d-baukloetz-10-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_11", "m-at-3d-baukloetz-11-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_12", "m-at-3d-baukloetz-12-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_13", "m-at-3d-baukloetz-13-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_14", "m-at-3d-baukloetz-14-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_15", "m-at-3d-baukloetz-15-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_16", "m-at-3d-baukloetz-16-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_17", "m-at-3d-baukloetz-17-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_18", "m-at-3d-baukloetz-18-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_19", "m-at-3d-baukloetz-19-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_20", "m-at-3d-baukloetz-20-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_21", "m-at-3d-baukloetz-21-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_22", "m-at-3d-baukloetz-22-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),
    new CategoryImage("FORM_BRICK_23", "m-at-3d-baukloetz-23-**0**.svg", ImageCategory.FORMS_BUILDING_BRICK),

    // Shape Craft pattern
    new CategoryImage("FORM_CRAFT_F_CUBE", "m-at-bastelvorlage-wuerfel-f-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),
    new CategoryImage("FORM_CRAFT_B_DE_CUBE", "m-at-bastelvorlage-wuerfel-b-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),
    new CategoryImage("FORM_CRAFT_F_CUBOID", "m-at-bastelvorlage-quader-f-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),
    new CategoryImage("FORM_CRAFT_B_DE_CUBOID", "m-at-bastelvorlage-quader-b-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),
    new CategoryImage("FORM_CRAFT_F_CONE", "m-at-bastelvorlage-kegel-f-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),
    new CategoryImage("FORM_CRAFT_B_DE_CONE", "m-at-bastelvorlage-kegel-b-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),
    new CategoryImage("FORM_CRAFT_F_PYRAMID", "m-at-bastelvorlage-pyramide-f-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),
    new CategoryImage("FORM_CRAFT_B_DE_PYRAMID", "m-at-bastelvorlage-pyramide-b-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),
    new CategoryImage("FORM_CRAFT_F_CYLINDER", "m-at-bastelvorlage-zylinder-f-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),
    new CategoryImage("FORM_CRAFT_B_DE_CYLINDER", "m-at-bastelvorlage-zylinder-b-thumb.svg", ImageCategory.FORMS_CRAFT_PATTERN),

    // Line
    new CategoryImage("AT_ALG_LINE_SOLID_1", "at-alg-standard_linie-thumb.svg", ImageCategory.LINE),
    new CategoryImage("AT_ALG_LINE_DOTTED_1", "at-alg-rhythmuslinie_punke01-thumb.svg", ImageCategory.LINE),
    new CategoryImage("AT_ALG_LINE_DOTTED_2", "at-alg-rhythmuslinie_punke02-thumb.svg", ImageCategory.LINE),
    new CategoryImage("AT_ALG_LINE_DASHED_2", "at-alg-rhythmuslinie_strich02-thumb.svg", ImageCategory.LINE),
    new CategoryImage("AT_ALG_LINE_DASHED_3", "at-alg-rhythmuslinie_strich03-thumb.svg", ImageCategory.LINE),
    new CategoryImage("AT_ALG_LINE_DASHED_1", "at-alg-rhythmuslinie_strich01-thumb.svg", ImageCategory.LINE),
]
