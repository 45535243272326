import Entity from "../../_model/Entity";

export default class SearchResultListItem extends Entity {
    url: string
    type: string
    status: string
    resultAmount?: number
    owner: string
    createdOn: Date

    constructor(id: number, name: string, status: string, url: string, type: string, createdOn: Date, owner: string, resultAmount?: number) {
        super(name, id);

        this.url = url
        this.type = type
        this.resultAmount = resultAmount
        this.status = status
        this.createdOn = createdOn
        this.owner = owner
    }
}
