import {WDToolbar, WDToolbarOrientation, WDToolbarState} from "./WDToolbar";
import React, {RefObject} from "react";
import {WDToolbarAction} from "../WDToolbarAction";
import {ElementBorder, ElementFillStyle, ElementLayout, ElementTransformation} from "../../Elements/WDElementContainer";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "./WDToolbarTabs";
import {WSContextType} from "../../Elements/WSContext";
import {WDElementArrange} from "../../Elements/Enum/WDElementArrange";
import {WDElementAlignment} from "../../Elements/Enum/WDElementAlignment";
import {WDElementDistributeMode} from "../../Elements/Enum/WDElementDistributeMode";
import {WorksheetItemUpdate, WorksheetItemUpdateOptions} from "../../Utils/WorksheetItemUpdate";
import {ProductLicense} from "../../../Framework/Enums";
import {MainContext} from "../../../_base/MainContext";

// Class representing the definition of a WD element with all layout information, type, key and content
export class ElementDefinition {
    itemKey: string
    worksheetItemTypeId: number
    props: ElementProps
    content: string

    constructor(itemKey: string, worksheetItemTypeId: number, props: ElementProps, content: string) {
        this.worksheetItemTypeId = worksheetItemTypeId
        this.itemKey = itemKey
        this.props = props
        this.content = content
    }
}

// Class defining all WD element properties necessary for rendering
export class ElementProps {
    layout: ElementLayout
    transformation: ElementTransformation
    border: ElementBorder
    color: ElementFillStyle
    minLeft?: number
    minTop?: number
    minWidth: number
    minHeight: number
    maxLeft?: number
    maxTop?: number
    maxWidth: number
    maxHeight: number
    locked: boolean

    constructor(layout, transformation, border, color, minWidth, minHeight, maxWidth, maxHeight, locked) {
        this.layout = layout
        this.transformation = transformation
        this.border = border
        this.color = color
        this.minWidth = minWidth
        this.minHeight = minHeight
        this.maxWidth = maxWidth
        this.maxHeight = maxHeight
        this.locked = locked
    }
}

export interface WDToolbarElementProps {
    worksheetItemTypeId?: number
    worksheetItemData: string[]
    worksheetItemKeys: string[]

    left: number,
    top: number,
    elementProps: ElementProps
    editMode: boolean
    context: WSContextType
    selectedElementCount: number
    elementsOnSameSheet: boolean

    // Data needed in the toolbar that is not part of the element data (non-persistent data like current selection)
    additionalToolbarData: string[]

    changeEditModeWithClickTab: (id: string, editMode: boolean) => void

    onToolbarAction: (action: WDToolbarAction, data?: any) => void
    onUpdateSelectedElements: (update: WorksheetItemUpdate, options?: WorksheetItemUpdateOptions) => void
    onChangeBorder: (border: ElementBorder) => void
    onChangeGroupingStatus?: () => void
    onChangeLockingStatus?: () => void
    onArrange?: (mode: WDElementArrange) => void
    onAlign?: (mode: WDElementAlignment) => void
    onDistribute?: (mode: WDElementDistributeMode) => void

    onShowConfirmation?: (title: string, description: string, onSubmit: () => void) => void
    onAddRule?: (ruleId: number) => void

    onFlipHorizontal: () => void
    onFlipVertical: () => void

    onElementDeleted?: () => void
    onDuplicate: () => void
    onCut: () => void
    onCopy: () => void
    onPaste: () => void
}

export interface WDToolbarElementState extends WDToolbarState {
    isLicenseValidForElement: boolean
    refToolbar?: RefObject<WDToolbar>
}

export class WDToolbarElement <T extends WDToolbarElementProps = WDToolbarElementProps,
    U extends WDToolbarElementState = WDToolbarElementState> extends React.Component<T, U> {

    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    static toolbarWidth: number = 84
    static toolbarHeight: number = 72

    hasValidLicenseForElement = async (elementLicenses: ProductLicense[]) => {
        return this.context.getUserProductLicense().then(
            (userLicenses) => {
                let foundValidLicense = false

                if(userLicenses && userLicenses.length > 0) {
                    elementLicenses.forEach(el => {
                        if (userLicenses.find(ul => ul.id === el)) {
                            foundValidLicense = true
                            return
                        }
                    })
                }
                this.setState({isLicenseValidForElement: foundValidLicense})
            }
        )
    }

    evaluateToolbarType(prevEditMode: boolean, newEditMode: boolean) {
        // Evaluate current toolbar type
        let toolbarType = this.state ? this.state.activeToolbarType : WDToolbarTypeEnum.OBJECT
        if (newEditMode && !prevEditMode) {
            toolbarType = WDToolbarTypeEnum.TEXT
        } else if (!newEditMode && prevEditMode && this.state.activeToolbarType === WDToolbarTypeEnum.TEXT) {
            toolbarType = WDToolbarTypeEnum.OBJECT
        }

        if (this.state && this.state.activeToolbarType !== toolbarType) {
            this.setState({activeToolbarType: toolbarType})
        }
    }

    resetActiveId = () => {
        if (this.state && this.state.activeId !== -1) {
            this.setState({activeId: -1})
        }
    }

    /**
     * Calculate values to display in toolbar if one or more elements are selected
     * @param buttonData is the key for which the value is searched in worksheetItemData
     * @return object containing new value and if it should be displayed
     */
    multiselectSearchForValue = (buttonData: string) => {
        return this.multiselectSearchForValueFromObject(this.props.worksheetItemData, buttonData)

    }
    multiselectSearchForValueFromObject = (arrayData: string[], buttonData: string) => {
        if(arrayData.length > 1 || arrayData[0] !== "") {
            let newValue: any | undefined = undefined
            let valueEqual: boolean = true

            arrayData.forEach(data => {
                let dataObject = JSON.parse(data)
                let dataObjectValue = dataObject[buttonData]

                // Search in data (textbox data within lineature, ...)
                if(dataObjectValue === undefined && dataObject["data"] !== undefined) {
                    dataObjectValue = dataObject["data"][buttonData]
                }

                // Check if value is equal for each selected element
                if (newValue === undefined) {
                    newValue = dataObjectValue
                    valueEqual = true
                } else if (newValue !== dataObjectValue) {
                    valueEqual = false
                }

            })
            return {newValue: newValue, valueEqual: valueEqual}
        }
    }

    onToggleButton = (id: number) => {
        this.setState({activeId: id})
    }
    onToolbarAction = (action: WDToolbarAction, data?: any) => {
        this.setState({ activeId: -1 }, () => {
            this.props.onToolbarAction(action, data)
        })
    }
    onChangeToolbarTab = (type: WDToolbarTypeEnum) => {
        if(this.state.activeToolbarType !== type) {
            this.props.changeEditModeWithClickTab(this.props.worksheetItemKeys[0], type === WDToolbarTypeEnum.TEXT)
            this.setState({activeToolbarType: type})
        }
    }

    setVisibility = (visible: boolean, resetPosition: boolean) => {
        this.state.refToolbar?.current?.setVisibility(visible, resetPosition)
    }
    isUsedInTextExerciseChild = () => {
        return this.props.context === WSContextType.text_exercise_child
    }
    isUsedInChildExercise = () => {
        return (this.props.context === WSContextType.text_exercise_child || this.props.context === WSContextType.writing_course_child)
    }

    getElementGraphic = (obj: any) => {}

    render () {

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, false, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

        </WDToolbar>
    }
}
