import React from "react";
import Entity from "../../_model/Entity";
import {Hint, NotificationData} from "../Notification/Hint";
import {NotificationStatus} from "../../Framework/Enums";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";
import Label from "./Label";

interface IProps {
    id: string,
    width?: number,
    elementWidth?: number,
    label?: string,
    data: Entity[],
    selectedData?: Entity[],
    value?: number,

    isNothingCheckedAllowed?: boolean

    required: boolean,
    readonly: boolean,

    onChange?: (values: Entity[]) => void
}

interface IState {
    required: boolean
    showNothingCheckedHint: boolean
}

export default class CheckBoxList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        // Initial required state is defined by the form
        this.state = {
            required: this.props.required && this.props.selectedData?.length === 0,
            showNothingCheckedHint: false
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if ((prevProps.selectedData === null || prevProps.selectedData?.length === 0) &&
            (this.props.selectedData && this.props.selectedData?.length > 0)) {

            this.setState({required: false})
        }
    }

    onChange = (value: number, text: string) => {
        if (this.props.selectedData) {
            let selected: Entity[] = []

            if(this.isChecked(value) && !this.props.isNothingCheckedAllowed && this.props.selectedData.length === 1) {
                this.setState({showNothingCheckedHint: true}, () => this.removeNothingCheckedHint())
                return
            }

            if (this.isChecked(value)) {
                selected = this.props.selectedData.filter(i => i.id !== value)
            }
            else {
                selected = this.props.selectedData

                let element = this.props.data.find(item => item.id === value)
                if (element) {
                    selected.push(element)
                }
            }

            // Required is true when field is defined as required and no input is checked
            this.setState({required: (this.props.required && selected.length === 0)})

            this.props.onChange?.(selected)
        }
    }

    isChecked = (value?: number) => {
        if (value !== undefined && this.props.selectedData) {
            const found = this.props.selectedData.find(i => i.id === value)
            return (found !== undefined)
        }

        return false
    }

    removeNothingCheckedHint = () => {
        setTimeout(() => this.setState({showNothingCheckedHint: false}), 3000)
    }

    render() {
        let checkboxWidth = this.props.elementWidth !== undefined ? this.props.elementWidth : 210

        return <div className="form-group" style={{width: this.props.width}}>
            {this.props.label &&
                <Label labelText={this.props.label} htmlFor={this.props.id} requiredField={this.props.required} className={"bold-label"}/>
            }

            <div id={this.props.id} className={"form-group-checkbox-list"}>
                {this.props.data.map(value => {
                    return <div key={this.props.id + value.id} className={"checkbox-element"} style={{width: checkboxWidth}}>
                        <input type={"checkbox"}
                               id={this.props.id + value.id}
                               value={value.id}
                               disabled={this.props.readonly}
                               required={this.state.required}
                               checked={this.isChecked(value.id)}
                               readOnly={true}
                               // onChange={() => this.onChange(value.id!, value.name)}
                        />
                        <label className={"label " + (this.props.readonly ? "label-disabled" : "")}
                               htmlFor={this.props.id + value.id}
                               onClick={() => this.onChange(value.id!, value.name)}
                        >
                            {value.name}
                        </label>
                    </div>
                })}
            </div>

            {this.state.showNothingCheckedHint &&
                <Hint id={"checked-hint"} notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.something_must_be_checked))}/>
            }
        </div>
    }
}
