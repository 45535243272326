export default class Converter {
    static oneMMinPx = 3.7795275590551185 //96 dpi
    
    static mmToPx = (mm: number) => {
        return mm * Converter.oneMMinPx
    }
    static pxToMm = (px: number) => {
        return px / Converter.oneMMinPx
    }

    static ptToPx = (pt: number) => {
        return pt * 4 / 3
    }

    static toMmGrid = (px: number) => {
        return Converter.mmToPx(Math.round(Converter.pxToMm(px)))
    }
}
