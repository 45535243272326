import React from "react";
import {WDToolbarTabs, WDToolbarTabsConfig, WDToolbarTypeEnum} from "./WDToolbarTabs";
import {Util} from "../../../Framework/Util";
import {Coords} from "../../../Framework/Coords";
import _ from "lodash";
import {ImagePath} from "../../../Framework/CategoryImage";
import {MainContext} from "../../../_base/MainContext";

export enum WDToolbarOrientation {
    none = "none",
    top = "top",
    left = "left",
    bottom = "bottom",
    right = "right"
}

export enum WDToolbarMode {
    DEFAULT,
    MOVE
}

export interface WDToolbarProps extends React.PropsWithChildren {
    id: string,
    left: number,
    top: number,
    isLocked: boolean
    className?: string
    calculatePosition: boolean
    activeToolbarType: WDToolbarTypeEnum
    toolbarTabsConfig: WDToolbarTabsConfig
    orientation: WDToolbarOrientation,

    onCloseDialog?: (() => void)
}

export interface WDToolbarState {
    activeId: number
    activeToolbarType: WDToolbarTypeEnum
}

export interface WDToolbarContainerState extends WDToolbarState {
    mode: WDToolbarMode
    visible: boolean

    // Coords changes while moving the toolbar
    movingCoords?: Coords

    // Store the original coordinates of the toolbar so it doesn't get moved when the props change
    // due to changes with arrow in Toolbar
    startCoords?: Coords
}

export class WDToolbar extends React.Component<WDToolbarProps, WDToolbarContainerState> {
    clickCoords: Coords = new Coords(0, 0)

    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: WDToolbarProps) {
        super(props)

        this.state = {
            activeId: -1,
            activeToolbarType: this.props.activeToolbarType,
            startCoords: new Coords(this.props.left, this.props.top),
            visible: true,
            mode: WDToolbarMode.DEFAULT
        }
    }

    componentDidMount() {
        if (this.props.onCloseDialog) {
            window.addEventListener('mousedown', this.handleClick);
        }
    }
    componentDidUpdate(prevProps: Readonly<WDToolbarProps>) {
        // make sure component is rendered 2 times to make sure calculation of positioning is made with the right values
        // (first rendering sets new toolbar and second rendering calculates correctly)
        if (this.props.activeToolbarType !== prevProps.activeToolbarType || this.props.isLocked !== prevProps.isLocked) {
            this.setState({
                activeToolbarType: this.props.activeToolbarType,
                movingCoords: undefined
            }, () => {
                setTimeout(() => { this.forceUpdate() }, 50)
            })
        }

        if (this.state.startCoords === undefined) {
            this.setState({ startCoords: new Coords(this.props.left, this.props.top)})
        }
    }
    shouldComponentUpdate(nextProps: Readonly<WDToolbarProps>, nextState: Readonly<WDToolbarState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    handleClick = (e: MouseEvent) => {
        if (this.props.onCloseDialog) {
            const dialog = document.getElementById("toolbar" + this.props.id);
            if (!(dialog && dialog.contains(e.target as Element))) {
                this.closeToolbar()
            }
        }
    }

    setVisibility = (visible: boolean, resetPosition: boolean) => {
        if (this.state.visible !== visible) {
            let stateObj: any = {
                visible: visible
            }

            if (resetPosition) {
                stateObj.startCoords = undefined
                stateObj.movingCoords = undefined
            }

            this.setState(stateObj)
        }
    }

    onMouseDown = (e: React.MouseEvent) => {
        let target = e.target as HTMLElement

        // Ignore mouse down on toolbar buttons
        if (!Util.isChildOfClass(target, "ws-designer-toolbar-button") &&
            !Util.isChildOfClass(target, "ws-designer-toolbar-submenu") &&
            !Util.isChildOfClass(target, "ws-designer-toolbar-tabs") &&
            !Util.isChildOfClass(target, "number-input") &&
            !Util.isChildOfClass(target, "search") &&
            !Util.isChildOfClass(target, "drop-down") &&
            !Util.isChildOfClass(target, "slider") &&
            !Util.isChildOfClass(target, "ws-designer-toolbar-options")) {

            document.addEventListener("mousemove", this.onMouseMove)
            document.addEventListener("mouseup", this.onMouseUp)

            this.clickCoords = new Coords(e.clientX, e.clientY)

            this.setState({
                mode: WDToolbarMode.MOVE
            })
        }
    }
    onMouseMove = (e: MouseEvent) => {
        let left = e.clientX - this.clickCoords.x
        let top = e.clientY - this.clickCoords.y

        this.setState({
            movingCoords: new Coords(left, top)
        })
    }
    onMouseUp = (e: MouseEvent) => {
        document.removeEventListener("mousemove", this.onMouseMove)
        document.removeEventListener("mouseup", this.onMouseUp)

        // Start coords of toolbar center before moving
        let result = this.calculateToolbarCoords()

        let rect = this.getToolbarBoundingRect()
        result.x += rect.width

        this.context.setManualToolbarCoords(result)

        this.setState({
            movingCoords: undefined,
            mode: WDToolbarMode.DEFAULT
        })
    }

    onPinClicked = () => {
        this.context.setManualToolbarCoords(undefined)
    }

    closeToolbar = () => {
        if (this.props.onCloseDialog) {
            window.removeEventListener('mousedown', this.handleClick);
            this.props.onCloseDialog()
        }
    }

    isMovedManually = () => {
        return this.context.getManualToolbarCoords() !== undefined
    }

    /**
     * Returns the left top corner coordinate of the toolbar
     */
    calculateToolbarCoordinate = () => {
        const rect = this.getToolbarBoundingRect()

        // Recalculate toolbar position if it wasn't displayed before
        if (rect.width === 0 || rect.height === 0) {
            setTimeout(() => { this.forceUpdate() }, 50)
            return undefined
        }

        // Calculate toolbar coordinates
        const manualCoords = this.context.getManualToolbarCoords()
        if (manualCoords && this.state.movingCoords === undefined) {
            return new Coords(manualCoords.x - rect.width, manualCoords.y)
        }
        else {
            return this.calculateToolbarCoords()
        }
    }

    getToolbarTabHeight = () => {
        return this.props.isLocked ? 0 : 28
    }
    getToolbarBoundingRect = () => {
        let rect = new DOMRect(0, 0, 0, 0)

        // calculate position
        if (this.props.calculatePosition) {
            let toolbar = document.getElementById("toolbar" + this.props.id);
            if (toolbar) {
                rect = toolbar.getBoundingClientRect()
                rect.height += 10   // few pixel for the arrow
            }
        }

        rect.width = Math.round(rect.width)
        rect.height = Math.round(rect.height)

        return rect
    }

    calculateToolbarCoords = () => {
        const rect = this.getToolbarBoundingRect()

        let result = new Coords(this.state.startCoords?.x || this.props.left, this.state.startCoords?.y || this.props.top)

        const manualCoords = this.context.getManualToolbarCoords()
        if (manualCoords) {
            result = new Coords(manualCoords.x - (rect.width / 2), manualCoords.y + rect.height + this.getToolbarTabHeight())
        }

        // Calculate top left corner
        result.x = Math.max(result.x - (rect.width / 2), 0)
        result.y = Math.max(result.y - rect.height, this.getToolbarTabHeight())

        // Add moving coords
        result.x = Math.max(result.x + (this.state.movingCoords?.x || 0), 0)
        result.y = Math.max(result.y + (this.state.movingCoords?.y || 0), this.getToolbarTabHeight())

        // Change coords so toolbar doesn't go out of the screen
        if (result.x + rect.width > window.innerWidth) {
            result.x = window.innerWidth - rect.width
        }
        if (result.y + rect.height > window.innerHeight) {
            result.y = window.innerHeight - rect.height
        }

        // Toolbar can't go above the toolbar tabs
        result.y -= this.getToolbarTabHeight()

        return result
    }

    render() {
        if (!this.state.visible) {
            return <></>
        }

        const id = "toolbar" + this.props.id

        let className = "ws-designer-toolbar"
        if (this.props.className) {
            className += " " + this.props.className
        }

        const rect = this.getToolbarBoundingRect()
        const coords = this.props.calculatePosition ? this.calculateToolbarCoordinate() : this.state.startCoords
        // this.setManualCoords(coords)

        if (this.props.isLocked) {
            return <></>
        }

        return <div className={"ws-designer-toolbar-container"} id={"toolbar-container"}
                    style={{
                        left: coords ? coords.x : 0,
                        top: coords ? coords.y : 0,
                        visibility: coords ? "visible": "hidden"}}
                    onMouseDown={this.onMouseDown}
        >
            <>
                <WDToolbarTabs
                    activeToolbarType={this.props.activeToolbarType}
                    showTabConfig={this.props.toolbarTabsConfig}
                />

                <div id={id} className={className}>
                    {this.props.children}

                    { this.isMovedManually() &&
                        <div className={"ws-designer-toolbar-pin"}
                             onClick={this.onPinClicked}
                        >
                            <img
                                src={process.env.PUBLIC_URL + ImagePath.getToolbarUrl() + "pin.svg"}
                                alt={"Pin Toolbar"} width={22} height={22} draggable={"false"}
                                className={rect.left < 0 ? "svg-red" : ""}
                                onContextMenu={(e) => e.preventDefault()}
                            />
                        </div>
                    }
                </div>
            </>
        </div>
    }
}
