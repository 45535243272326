import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";
import {WDTextExerciseSection} from "../../../Elements/TextExercise/WDTextExerciseData";

/**
 * Button (Yes/No) in Toolbar
 * Effects selected element(s)
 * Only possible if exercise is generated by the logic of the element
 * Yes: solutions of exercises should be shown but in different color
 * No:  solutions should be hidden
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltipDisabled?: TooltipText
    section: WDTextExerciseSection
    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

export class WDToolbarButtonSectionShow extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    changeShowSolution = () => {
        let data: any = {"section": this.props.section, "showSolution": !this.props.pressed}
        this.props.onActionButton(WDToolbarAction.TOGGLE_SECTION, data)
        this.props.onToggleButton(this.props.id)
    }

    getImageUrl = () => {
        switch(this.props.section) {
            case WDTextExerciseSection.IMAGES:
                return this.props.pressed ? "section_images_show.svg" : "section_images_hide.svg"
            case WDTextExerciseSection.CALCULATION:
                return this.props.pressed ? "section_calculation_show.svg" : "section_calculation_hide.svg"
            case WDTextExerciseSection.ANSWER:
                return this.props.pressed ? "section_answer_show.svg" : "section_answer_hide.svg"
        }
    }
    getTooltip = () => {
        let text, tooltip
        switch(this.props.section) {

            case WDTextExerciseSection.IMAGES:
                text = this.props.pressed ? translations.toolbar.section_images_show : translations.toolbar.section_images_hide
                tooltip = translations.tooltip.section_images_toggle
                break
            case WDTextExerciseSection.CALCULATION:
                text = this.props.pressed ? translations.toolbar.section_calculation_show : translations.toolbar.section_calculation_hide
                tooltip = translations.tooltip.section_calculation_toggle
                break
            case WDTextExerciseSection.ANSWER:
                text = this.props.pressed ? translations.toolbar.section_answer_show : translations.toolbar.section_answer_hide
                tooltip = translations.tooltip.section_answer_toggle
        }

        return new TooltipText(this.context.translate(text), this.context.translate(tooltip))
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-section-" + this.props.section + "show"}
            id={"section" + this.props.section + "Show"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + this.getImageUrl()}
                             iconClassSelected={"ws-designer-toolbar-button-img"}
                             iconClassUnselected={"ws-designer-toolbar-button-img"}
                             tooltip={this.getTooltip()}
                             tooltipDisabled={this.props.tooltipDisabled}
                             onToggleButton={this.changeShowSolution}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={false} />
        </li>
    }
}
