import React, {PropsWithChildren} from "react";
import {Util} from "../../Framework/Util";
import {WorksheetItem} from "../../_model/WorksheetItem";
import {Coords} from "../../Framework/Coords";
import {RESIZE_NODE} from "./Enum/WDElementContainerResizeNode";
import {PositioningLine, PositioningLineCheck, PositioningLineType, PositioningMode, WDUtils} from "../Utils/WDUtils";
import {MainContext} from "../../_base/MainContext";
import Converter from "../../Framework/Converter";
import _ from "lodash";
import {WorksheetItemUpdate} from "../Utils/WorksheetItemUpdate";
import {WDTextboxResizeHandler} from "./Textbox/WDTextboxResizeHandler";
import {WDToolbarButtonLock} from "../Toolbar/Button/General/WDToolbarButtonLock";

export class ResizeInfo {
    topLeft: boolean
    topRight: boolean
    bottomLeft: boolean
    bottomRight: boolean
    left: boolean
    top: boolean
    right: boolean
    bottom: boolean

    minWidth: number
    maxWidth: number
    minHeight: number
    maxHeight: number

    displaySelectionBorder: boolean = true

    constructor(
        topLeft: boolean,
        topRight: boolean,
        bottomLeft: boolean,
        bottomRight: boolean,
        left: boolean,
        top: boolean,
        right: boolean,
        bottom: boolean,
        minWidth: number,
        maxWidth: number,
        minHeight: number,
        maxHeight: number
    ) {

        this.topLeft = topLeft
        this.topRight = topRight
        this.bottomLeft = bottomLeft
        this.bottomRight = bottomRight
        this.left = left
        this.top = top
        this.right = right
        this.bottom = bottom
        this.minWidth = minWidth
        this.maxWidth = maxWidth
        this.minHeight = minHeight
        this.maxHeight = maxHeight
    }
}

export class ElementLayout {
    left: number
    top: number
    width: number
    height: number

    constructor(
        left: number,
        top: number,
        width: number,
        height: number) {

        this.left = left
        this.top = top
        this.width = width
        this.height = height
    }

    static defaultLayout() {
        return new ElementLayout(0, 0, 0, 0)
    }

    static createFromWorksheetItem(item: WorksheetItem) {
        return new ElementLayout(item.posX, item.posY, item.width, item.height)
    }

    static createFromDOMRect(rect: DOMRect) {
        return new ElementLayout(rect.left, rect.top, rect.width, rect.height)
    }

    static clone(layout: ElementLayout) {
        return new ElementLayout(layout.left, layout.top, layout.width, layout.height)
    }

    toString() {
        return `left: ${this.left}, top: ${this.top}, width: ${this.width}, height: ${this.height}`
    }
}

export class ElementTransformation {
    rotation: number
    flipHorizontal: boolean
    flipVertical: boolean
    skew: number

    constructor(
        rotation?: number,
        flipHorizontal?: boolean,
        flipVertical?: boolean,
        skew?: number) {

        this.rotation = rotation || 0
        this.flipHorizontal = flipHorizontal || false
        this.flipVertical = flipVertical || false
        this.skew = skew || 0
    }

    static defaultTransformation() {
        return new ElementTransformation(0, false, false, 0)
    }

    /**
     * Calculate values to display in toolbar if one or more elements are selected
     * @param selectedItems array of WorksheetItems which are currently selected
     * @param buttonData is the key for which the value is searched in worksheetItemData
     * @return boolean if data should be shown in toolbar
     */
    static multiselectSearchForValue(selectedItems: WorksheetItem[], buttonData: string) {
        if (selectedItems.length > 1) {
            let newValue: any | undefined = undefined
            let valueEqual: boolean = true

            // Check if value is equal for each selected element
            selectedItems.forEach(item => {

                if (newValue === undefined) {
                    // set start value
                    newValue = item[buttonData]

                } else if (newValue !== item[buttonData]) {
                    valueEqual = false
                }
            })
            return valueEqual
        }
    }
}

export class ElementBorder {
    visible: boolean
    color: string
    style: string
    weight: number

    paddingTop: number
    paddingRight: number
    paddingBottom: number
    paddingLeft: number
    linkPadding: boolean

    constructor(visible: boolean,
                color: string,
                style: string,
                weight: number,
                paddingLeft: number,
                paddingTop: number,
                paddingRight: number,
                paddingBottom: number,
                linkPadding: boolean) {

        this.visible = visible
        this.color = color
        this.style = style
        this.weight = weight
        this.paddingLeft = paddingLeft
        this.paddingTop = paddingTop
        this.paddingRight = paddingRight
        this.paddingBottom = paddingBottom
        this.linkPadding = linkPadding
    }

    static defaultBorder = () => {
        return new ElementBorder(false, "transparent", "none", 0, 0, 0, 0, 0, true)
    }

    static createBorderOptions = (
        visible: boolean,
        style: string,
        color: string,
        weight: number,
        paddingLeft: number,
        paddingTop: number,
        paddingRight: number,
        paddingBottom: number,
        linkPadding: boolean) => {

        if (style === "none" && weight === 0 && color === "transparent") {
            let border = ElementBorder.defaultBorder()
            border.paddingLeft = paddingLeft
            border.paddingTop = paddingTop
            border.paddingRight = paddingRight
            border.paddingBottom = paddingBottom
            border.linkPadding = linkPadding
            return border
        } else {
            return new ElementBorder(visible, color, style, weight, paddingLeft, paddingTop, paddingRight, paddingBottom, linkPadding)
        }
    }

    onToggleSetBorderOptions = () => {
        if (this.style === "none") {
            return new ElementBorder(true, "#000000", "solid", 1,
                this.paddingLeft, this.paddingTop, this.paddingRight, this.paddingBottom, this.linkPadding)
        } else {
            this.visible = !this.visible
            return this
        }
    }
}

export class ElementFillStyle {
    fillColor: string
    transparency: number

    constructor(fillColor?: string, transparency?: number) {
        this.fillColor = fillColor || "transparent"
        this.transparency = transparency === undefined ? 100 : transparency
    }

    static defaultColor = () => {
        return new ElementFillStyle("transparent", 100)
    }
}

export enum ResizerState {
    NONE,
    OK,
    ERROR
}

/**
 * All elements include WSDesignerElementContainer
 * includes behaviour for around all elements (for example resize)
 */

interface IProps extends PropsWithChildren {
    id: string
    element: WorksheetItem
    hasResizeOnCreate: boolean
    isReadOnly: boolean

    renderWrapper: boolean
    renderBackgroundColor?: boolean
    renderBorder?: boolean

    resizeInfo: ResizeInfo
    resizerState?: ResizerState

    isEditModeAllowed: () => boolean

    onUnlockElement: ((locked: boolean) => void)
    onResizeElement?: (proportional: boolean, x: number, y: number, nodeType?: RESIZE_NODE) => void
    onResizeStateChanged?: (state: boolean, nodeType?: RESIZE_NODE) => void
    onEdit?: (editMode: boolean) => void
    onGetData?: (id: string) => string

    onMouseMove?: (e: React.MouseEvent) => void
    onDragEnter?: (e: React.DragEvent) => void
    onDragLeave?: (e: React.DragEvent) => void
    onDragOver?: (e: React.DragEvent) => void
    onDrop?: (e: React.DragEvent) => void
    onContextMenu?: (id: string, e: React.MouseEvent) => void
    onAutoResize?: () => void
}

interface IState {
    createMode: boolean

    currentNode?: RESIZE_NODE
    editMode: boolean
    editCoords?: Coords

    showTouch: boolean
    touches: any
}

export class WDElementContainer extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    nodeSize: number = 10.0
    borderSize: number = 0.0

    constructor(props: IProps) {
        super(props);

        this.state = {
            createMode: false,
            editMode: false,
            editCoords: new Coords(0, 0),

            showTouch: false,
            touches: ""
        }
    }

    componentDidMount() {
        if (this.props.hasResizeOnCreate) {
            this.setState({
                currentNode: RESIZE_NODE.bottomRight,
                createMode: true
            })
            this.addResizeEventListener()
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getEnumByNodeId(nodeId: string): RESIZE_NODE {
        let nodeType: string = nodeId.replace(this.props.id + "-node", "")
        return nodeType as RESIZE_NODE
    }

    getEditCoords = () => {
        return this.state.editCoords
    }
    setEditCoords = (coords: Coords | undefined) => {
        this.setState({ editCoords: coords })
    }

    onMouseDownSetCurrentElement = (event: React.MouseEvent | React.TouchEvent) => {
        event.preventDefault();
        event.stopPropagation();

        let targetElement = (event.target as Element)
        this.addResizeEventListener(targetElement)

        const nodeType = this.getEnumByNodeId(targetElement.id)
        this.setState({currentNode: nodeType})

        // Set element mode to not resizing
        this.props.onResizeStateChanged?.(true, nodeType)
    }
    onMouseUpSetCurrentElementNull = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();
        event.stopPropagation();

        this.removeResizeEventListener();

        // Set element mode to not resizing
        this.props.onResizeStateChanged?.(false, undefined)
    }
    onMouseMoveResize = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        let rect = WDUtils.getBoundingRectOfElement(this.props.element)

        let anchor = WDUtils.getWorksheetItemAnchorObjectCoordinates(this.props.id)
        let pos = new Coords(e.clientX - anchor.left, e.clientY - anchor.top)

        let right = (rect.left * this.context.getZoom()) + (rect.width * this.context.getZoom())
        let bottom = (rect.top * this.context.getZoom()) + (rect.height * this.context.getZoom())

        let x = (this.props.element.posX * this.context.getZoom())
        let y = (this.props.element.posY * this.context.getZoom())

        if (this.state.currentNode && this.props.onResizeElement) {
            switch (this.state.currentNode) {
                case RESIZE_NODE.bottomRight: {
                    if (this.props.resizeInfo.bottomRight) {
                        this.props.onResizeElement(!e.shiftKey, pos.x - right, pos.y - bottom)
                    }
                    break;
                }
                case RESIZE_NODE.bottomLeft: {
                    if (this.props.resizeInfo.bottomLeft) {
                        this.props.onResizeElement(!e.shiftKey, pos.x - x, pos.y - bottom)
                    }
                    break;
                }
                case RESIZE_NODE.topLeft: {
                    if (this.props.resizeInfo.topLeft) {
                        this.props.onResizeElement(!e.shiftKey, pos.x - x, pos.y - y)
                    }
                    break;
                }
                case RESIZE_NODE.topRight: {
                    if (this.props.resizeInfo.topRight) {
                        this.props.onResizeElement(!e.shiftKey, pos.x - right, pos.y - y)
                    }
                    break;
                }
                case RESIZE_NODE.top: {
                    if (this.props.resizeInfo.top) {
                        this.props.onResizeElement(false, 0, pos.y - y)
                    }
                    break;
                }
                case RESIZE_NODE.bottom: {
                    if (this.props.resizeInfo.bottom) {
                        this.props.onResizeElement(false, 0, pos.y - bottom)
                    }
                    break;
                }
                case RESIZE_NODE.right: {
                    if (this.props.resizeInfo.right) {
                        this.props.onResizeElement(false, pos.x - right, 0)
                    }
                    break;
                }
                case RESIZE_NODE.left: {
                    if (this.props.resizeInfo.left) {
                        this.props.onResizeElement(false, pos.x - x, 0)
                    }
                    break;
                }
            }
        }
    }
    onDoubleClickWrapper = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        let anchor = WDUtils.getWorksheetItemAnchorObjectCoordinates(this.props.id)
        let pos = new Coords(e.clientX - anchor.left, e.clientY - anchor.top)

        this.onEdit(pos)
    }
    onContextMenu = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        this.props.onContextMenu?.(this.props.id, e)
    }

    addResizeEventListener = (element?: Element) => {
        //TODO: touch events do not work
        // window.addEventListener('touchmove', this.onMouseMoveResize, false);

        window.addEventListener('mousemove', this.onMouseMoveResize, false);
        window.addEventListener('mouseup', this.onMouseUpSetCurrentElementNull, false);
    }
    removeResizeEventListener = () => {
        //TODO: touch events do not work
        // window.removeEventListener('touchmove', this.onMouseMoveResize, false);
        // window.removeEventListener('touchend', this.onMouseUpSetCurrentElementNull, false);

        window.removeEventListener('mousemove', this.onMouseMoveResize, false);
        window.removeEventListener('mouseup', this.onMouseUpSetCurrentElementNull, false);
    }

    setResizeState = (state: boolean, nodeType?: RESIZE_NODE) => {
        if (state) {
            this.setState({currentNode: nodeType})
        } else {
            WDUtils.ClearPositioningLines()

            this.setState({
                currentNode: undefined,
                createMode: false
            })

            // Set resize cursor globally to auto
            document.getElementById("ws-designer-document")!.className = "ws-designer-document"
        }
    }
    resizeElement = (proportional: boolean, dragOffsetX: number, dragOffsetY: number, nodeType?: RESIZE_NODE) => {
        const resizeLeft = (this.state.currentNode === RESIZE_NODE.topLeft || this.state.currentNode === RESIZE_NODE.bottomLeft || this.state.currentNode === RESIZE_NODE.left)
        const resizeTop = (this.state.currentNode === RESIZE_NODE.topLeft || this.state.currentNode === RESIZE_NODE.topRight || this.state.currentNode === RESIZE_NODE.top)
        const resizeRight = (this.state.currentNode === RESIZE_NODE.topRight || this.state.currentNode === RESIZE_NODE.bottomRight || this.state.currentNode === RESIZE_NODE.right)
        const resizeBottom = (this.state.currentNode === RESIZE_NODE.bottomLeft || this.state.currentNode === RESIZE_NODE.bottomRight || this.state.currentNode === RESIZE_NODE.bottom)

        const minUnit = Converter.mmToPx(1) / this.context.getZoom()
        if (Math.abs(dragOffsetX) <= minUnit && Math.abs(dragOffsetY) <= minUnit) {
            return
        }

        let container = WDUtils.getBoundingRectOfElement(this.props.element)
        const ratio = container.width / container.height
        // console.log("Ratio: " + Math.round(ratio * 100) / 100)

        const node = nodeType || this.state.currentNode
        if (node) {
            let dragWidth = container.width, dragHeight = container.height

            // Proportional resizing - calculate percentage of resize change
            if (proportional && (node === RESIZE_NODE.topLeft || node === RESIZE_NODE.topRight || node === RESIZE_NODE.bottomLeft || node === RESIZE_NODE.bottomRight)) {
                dragOffsetX = (dragOffsetY * ratio)
                if ((resizeLeft && resizeBottom) || (resizeRight && resizeTop)) {
                    dragOffsetX = -dragOffsetX
                }
            }

            let positioningLines: PositioningLine[] = []
            let targetLayout = ElementLayout.clone(container)

            if (WDUtils.getNumberOfSelectedWorksheetItems() === 1) {
                // Update position of the element to get the new target position (roughly) so the positioning lines can be evaluated
                if (resizeLeft) {
                    targetLayout.left += (dragOffsetX / this.context.getZoom())
                }
                if (resizeTop) {
                    targetLayout.top += (dragOffsetY / this.context.getZoom())
                }
                if (resizeRight) {
                    targetLayout.width += (dragOffsetX / this.context.getZoom())
                }
                if (resizeBottom) {
                    targetLayout.height += (dragOffsetY / this.context.getZoom())
                }

                // Evaluate positioning lines and draw them
                WDUtils.ClearPositioningLines()

                const sheet = WDUtils.getSheetById(this.props.id)
                if (sheet !== null && sheet !== undefined) {
                    let elements = sheet.querySelectorAll(".ws-designer-element-container") as NodeListOf<HTMLDivElement>
                    let layouts: ElementLayout[] = []

                    elements.forEach(el => {
                        if (el.id !== this.props.id + "-container" && !Util.isChildOfId(el ,this.props.id + "-container")) {
                            let layout = ElementLayout.createFromDOMRect(el.getBoundingClientRect())
                            layout = WDUtils.convertRectToAnchorObjectCoordinates(this.props.id, layout)

                            layout.left = layout.left / this.context.getZoom()
                            layout.top = layout.top / this.context.getZoom()
                            layout.width = layout.width / this.context.getZoom()
                            layout.height = layout.height / this.context.getZoom()

                            layouts.push(layout)
                        }
                    })

                    layouts.forEach(l => {
                        targetLayout = WDUtils.EvalPositioningLines(
                            PositioningMode.RESIZE,
                            l,
                            targetLayout,
                            positioningLines,
                            new PositioningLineCheck(true, true, true, true),
                            this.context.getZoom()
                        )
                    })
                }
                WDUtils.DrawPositioningLines(sheet!.id, positioningLines)
            }

            let docked = false

            // top
            if (node === RESIZE_NODE.top) {
                // Check if element is allowed to resize in that direction
                if (this.props.resizeInfo.top) {
                    proportional = false

                    if (positioningLines.find(x => x.type === PositioningLineType.VERTICAL_START)) {
                        docked = true
                        dragOffsetY = (targetLayout.top - container.top) * this.context.getZoom()
                    }
                    dragHeight = dragHeight - (dragOffsetY / this.context.getZoom())

                    document.getElementById("ws-designer-document")!.className = "ws-designer-document ws-designer-element-node-n-cursor"
                } else {
                    dragOffsetX = 0
                    dragOffsetY = 0
                }
            }
            // left
            else if (node === RESIZE_NODE.left) {
                // Check if element is allowed to resize in that direction
                if (this.props.resizeInfo.left) {
                    proportional = false

                    if (positioningLines.find(x => x.type === PositioningLineType.HORIZONTAL_START)) {
                        docked = true
                        dragOffsetX = (targetLayout.left - container.left) * this.context.getZoom()
                    }
                    dragWidth = dragWidth - (dragOffsetX / this.context.getZoom())

                    document.getElementById("ws-designer-document")!.className = "ws-designer-document ws-designer-element-node-e-cursor"
                } else {
                    dragOffsetX = 0
                    dragOffsetY = 0
                }
            }
            // right
            else if (node === RESIZE_NODE.right) {
                // Check if element is allowed to resize in that direction
                if (this.props.resizeInfo.right) {
                    proportional = false

                    if (positioningLines.find(x => x.type === PositioningLineType.HORIZONTAL_END)) {
                        docked = true
                        dragWidth = targetLayout.width
                    } else {
                        dragWidth = dragWidth + (dragOffsetX / this.context.getZoom())
                    }

                    document.getElementById("ws-designer-document")!.className = "ws-designer-document ws-designer-element-node-e-cursor"
                } else {
                    dragOffsetX = 0
                    dragOffsetY = 0
                }
            }
            // bottom
            else if (node === RESIZE_NODE.bottom) {
                proportional = false

                // Check if element is allowed to resize in that direction
                if (this.props.resizeInfo.bottom) {
                    if (positioningLines.find(x => x.type === PositioningLineType.VERTICAL_END)) {
                        docked = true
                        dragHeight = targetLayout.height
                    } else {
                        dragHeight = dragHeight + (dragOffsetY / this.context.getZoom())
                    }

                    document.getElementById("ws-designer-document")!.className = "ws-designer-document ws-designer-element-node-n-cursor"
                } else {
                    dragOffsetX = 0
                    dragOffsetY = 0
                }
            } else {
                if (node === RESIZE_NODE.topLeft) {
                    // Check if element is allowed to resize in that direction
                    if (this.props.resizeInfo.topLeft) {
                        // Dock at position line only for one direction if proportional
                        // Otherwise dock at both directions
                        if (positioningLines.find(x => x.type === PositioningLineType.HORIZONTAL_START)) {
                            docked = true

                            dragOffsetX = (targetLayout.left - container.left) * this.context.getZoom()
                            dragWidth = dragWidth - (dragOffsetX / this.context.getZoom())

                            if (proportional) {
                                dragOffsetY = (dragOffsetX / ratio)
                                dragHeight = dragHeight - (dragOffsetY / this.context.getZoom())
                            }
                        } else {
                            dragWidth = dragWidth - (dragOffsetX / this.context.getZoom())
                        }

                        if (!proportional || !docked) {
                            if (positioningLines.find(x => x.type === PositioningLineType.VERTICAL_START)) {
                                docked = true

                                dragOffsetY = (targetLayout.top - container.top) * this.context.getZoom()
                                dragHeight = dragHeight - (dragOffsetY / this.context.getZoom())

                                if (proportional) {
                                    dragWidth = dragWidth + (dragOffsetX / this.context.getZoom())

                                    dragOffsetX = (dragOffsetY * ratio)
                                    dragWidth = dragWidth - (dragOffsetX / this.context.getZoom())
                                }
                            } else {
                                dragHeight = dragHeight - (dragOffsetY / this.context.getZoom())
                            }
                        }

                        document.getElementById("ws-designer-document")!.className = "ws-designer-document ws-designer-element-node-nw-cursor"
                    } else {
                        dragOffsetX = 0
                        dragOffsetY = 0
                    }
                } else if (node === RESIZE_NODE.bottomRight) {
                    // Check if element is allowed to resize in that direction
                    if (this.props.resizeInfo.bottomRight) {

                        // Dock at position line only for one direction if proportional
                        // Otherwise dock at both directions
                        if (positioningLines.find(x => x.type === PositioningLineType.HORIZONTAL_END)) {
                            docked = true

                            dragWidth = targetLayout.width
                            dragOffsetX = targetLayout.width - container.width

                            if (proportional) {
                                dragOffsetY = (dragOffsetX * (1 / ratio)) * this.context.getZoom()
                            }
                            dragHeight = dragHeight + (dragOffsetY / this.context.getZoom())
                        }

                        if (!docked) {
                            if (positioningLines.find(x => x.type === PositioningLineType.VERTICAL_END)) {
                                docked = true

                                dragHeight = targetLayout.height
                                dragOffsetY = targetLayout.height - container.height

                                if (proportional) {
                                    dragOffsetX = (dragOffsetY * ratio) * this.context.getZoom()
                                }
                                dragWidth = dragWidth + (dragOffsetX / this.context.getZoom())
                            }
                        }

                        if (!docked) {
                            dragWidth = dragWidth + (dragOffsetX / this.context.getZoom())

                            if (proportional) {
                                dragOffsetY = (dragOffsetX * (1 / ratio))
                            }
                            dragHeight = dragHeight + (dragOffsetY / this.context.getZoom())
                        }

                        document.getElementById("ws-designer-document")!.className = "ws-designer-document ws-designer-element-node-nw-cursor"
                    } else {
                        dragOffsetX = 0
                        dragOffsetY = 0
                    }
                } else if (node === RESIZE_NODE.topRight) {
                    // Check if element is allowed to resize in that direction
                    if (this.props.resizeInfo.topRight) {
                        // Dock at position line only for one direction if proportional
                        // Otherwise dock at both directions
                        if (positioningLines.find(x => x.type === PositioningLineType.HORIZONTAL_END)) {
                            docked = true

                            dragWidth = targetLayout.width
                            dragOffsetX = container.width - targetLayout.width

                            if (proportional) {
                                dragOffsetY = dragOffsetX * ratio
                                dragHeight = dragHeight - (dragOffsetY / this.context.getZoom())
                            }
                        } else {
                            dragWidth = dragWidth + (dragOffsetX / this.context.getZoom())
                        }

                        if (!proportional || !docked) {
                            if (positioningLines.find(x => x.type === PositioningLineType.VERTICAL_START)) {
                                docked = true

                                dragOffsetY = (targetLayout.top - container.top) * this.context.getZoom()
                                dragHeight = dragHeight - (dragOffsetY / this.context.getZoom())

                                if (proportional) {
                                    dragWidth = dragWidth - (dragOffsetX / this.context.getZoom())

                                    dragOffsetX = (dragOffsetY * ratio)
                                    dragWidth = dragWidth - (dragOffsetX / this.context.getZoom())
                                }
                            } else {
                                dragHeight = -(dragOffsetY / this.context.getZoom()) + dragHeight
                            }
                        }

                        document.getElementById("ws-designer-document")!.className = "ws-designer-document ws-designer-element-node-ne-cursor"
                    } else {
                        dragOffsetX = 0
                        dragOffsetY = 0
                    }
                } else if (node === RESIZE_NODE.bottomLeft) {
                    // Check if element is allowed to resize in that direction
                    if (this.props.resizeInfo.bottomLeft) {
                        // Dock at position line only for one direction if proportional
                        // Otherwise dock at both directions
                        if (positioningLines.find(x => x.type === PositioningLineType.HORIZONTAL_START)) {
                            docked = true

                            dragOffsetX = (targetLayout.left - container.left) * this.context.getZoom()
                            dragWidth = dragWidth - (dragOffsetX / this.context.getZoom())

                            if (proportional) {
                                dragOffsetY = (dragOffsetX / ratio)
                                dragHeight = dragHeight - (dragOffsetY / this.context.getZoom())
                            }
                        } else {
                            dragWidth = dragWidth - (dragOffsetX / this.context.getZoom())
                        }

                        if (!proportional || !docked) {
                            if (positioningLines.find(x => x.type === PositioningLineType.VERTICAL_END)) {
                                docked = true

                                dragHeight = targetLayout.height
                                dragOffsetY = targetLayout.height - container.height

                                if (proportional) {
                                    dragWidth = dragWidth + (dragOffsetX / this.context.getZoom())

                                    dragOffsetX = (-dragOffsetY * ratio) * this.context.getZoom()
                                    dragWidth = dragWidth - (dragOffsetX / this.context.getZoom())
                                }
                            } else {
                                dragHeight = dragHeight + (dragOffsetY / this.context.getZoom())
                            }
                        }

                        document.getElementById("ws-designer-document")!.className = "ws-designer-document ws-designer-element-node-ne-cursor"
                    } else {
                        dragOffsetX = 0
                        dragOffsetY = 0
                    }
                }
            }

            if (this.props.element.rotation !== 0) {
                let innerSize = WDUtils.getSizeOfElementInBoundingRect(dragWidth, dragHeight, this.props.element.rotation)
                dragWidth = innerSize.x
                dragHeight = innerSize.y
            }

            // When item is not just created and div gets to small stop at minSize
            if (!this.state.createMode) {
                if (dragWidth < this.props.resizeInfo.minWidth) {
                    dragWidth = this.props.resizeInfo.minWidth
                    dragOffsetX = this.props.resizeInfo.minWidth - dragWidth

                    if (proportional) {
                        docked = true

                        dragHeight = dragWidth / ratio
                        dragOffsetY = container.height - dragHeight
                    }
                }
                if (dragHeight < this.props.resizeInfo.minHeight) {
                    dragHeight = this.props.resizeInfo.minHeight
                    dragOffsetY = dragHeight - this.props.resizeInfo.minHeight

                    if (proportional) {
                        docked = true
                        dragWidth = dragHeight * ratio
                        dragOffsetX = container.width - dragWidth
                    }
                }
            }

            //When div gets to tall stop at maxSize
            if (dragWidth > this.props.resizeInfo.maxWidth) {
                // resize to max width only if maxHeight is not reached yet otherwise do not resize!
                dragWidth = dragHeight < this.props.resizeInfo.maxHeight ? this.props.resizeInfo.maxWidth : container.width
                dragOffsetX = 0

                if (proportional) {
                    dragHeight = this.props.element.height
                    dragOffsetY = 0
                }
            }
            if (dragHeight > this.props.resizeInfo.maxHeight) {
                // resize to max height only if maxWidth is not reached yet otherwise do not resize!
                dragHeight = dragWidth < this.props.resizeInfo.maxWidth ? this.props.resizeInfo.maxHeight : container.height
                dragOffsetY = 0

                if (proportional) {
                    dragWidth = this.props.element.width
                    dragOffsetX = 0
                }
            }

            let posX = Converter.toMmGrid(this.props.element.posX - (resizeLeft ? -(dragOffsetX / this.context.getZoom()) : 0))
            let posY = Converter.toMmGrid(this.props.element.posY - (resizeTop ? -(dragOffsetY / this.context.getZoom()) : 0))

            if (!docked) {
                dragWidth = Converter.toMmGrid(dragWidth)

                if (proportional) {
                    dragHeight = dragWidth / ratio
                    dragOffsetY = (container.height - dragHeight) * this.context.getZoom()
                    if (resizeTop) {
                        posY = this.props.element.posY + (dragOffsetY / this.context.getZoom())
                    }
                } else {
                    dragHeight = Converter.toMmGrid(dragHeight)
                }
            }
            else {
                // Docked to positioning line => don't adjust to mm grid
                if (resizeLeft) {
                    posX = this.props.element.posX + (dragOffsetX / this.context.getZoom())
                }
            }

            // Set container size after resize and return new layout
            return new WorksheetItemUpdate(this.props.id, {
                posX: posX,
                posY: posY,
                width: dragWidth,
                height: dragHeight
            })
        }
    }

    getAbsoluteElementRotation = (transformerElement: HTMLElement): number => {
        let parentTransformer = Util.getParentByClass(transformerElement, "ws-designer-element-transformer")
        let angle = 0
        while (parentTransformer) {
            let angleParent = Util.getRotationOfStyle(parentTransformer)
            angle += angleParent
            parentTransformer = Util.getParentByClass(parentTransformer, "ws-designer-element-transformer")
        }

        return angle
    }

    isChildElement = () => {
        let element = document.getElementById(this.props.id)
        if (element) {
            let group = Util.getParentByClass(element, "ws-designer-element-group")
            return group !== null
        }

        return false
    }
    isRotatedElement = () => {
        let transformer = document.getElementById(this.props.id + "-transformer")
        if (transformer) {
            return (this.getAbsoluteElementRotation(transformer) !== 0)
        }

        return false
    }

    deselectItem = (e: MouseEvent) => {
        // Check if the same element was clicked or anywhere else
        const element = e.target as HTMLElement

        if (!Util.isChildOfId(element, this.props.id) &&
            !Util.isChildOfId(element, "toolbar-container") &&
            !Util.isChildOfClass(element, "ws-designer-sidebar-container-show") &&
            !Util.isChildOfClass(element, "modal") &&
            !e.ctrlKey) {

            this.onStopEdit()

            this.props.onEdit?.(false)
        }
    }
    onEdit = (coords?: Coords) => {
        if (this.props.isEditModeAllowed() && !this.props.element.locked) {

            // Toggle edit mode and reset it if item is deselected
            this.setState({editMode: true, editCoords: coords}, () => {
                window.addEventListener('mousedown', this.deselectItem);
                this.props.onEdit?.(true)
            })
        }
    }
    onStopEdit = () => {
        // Remove global click handler to deselect item
        window.removeEventListener('mousedown', this.deselectItem);

        // Unselect all text
        window.getSelection()?.removeAllRanges();

        // Set edit mode to false
        this.setState({editMode: false})
    }
    onPropagateEvent = (e: React.UIEvent) => {
        if (e.type === "mousedown") {
            let wrapper = document.getElementById(this.props.id + "-wrapper")
            wrapper?.dispatchEvent(new MouseEvent("mousedown", e.nativeEvent))
        }
    }

    // checkMouseOrTouchEvent(event: MouseEvent | TouchEvent) {
    //     let e: MouseEvent | Touch | undefined = undefined
    //     if(window.TouchEvent && event instanceof TouchEvent) {
    //         e = (event as TouchEvent).touches[0]
    //         this.setState({showTouch: true, touches: ("X: " + e.pageX + " Y: " + e.pageY)})
    //     } else if(event instanceof MouseEvent) {
    //         e = event as MouseEvent
    //         this.setState({showTouch: false})
    //     }
    //     return e
    // }

    renderGrabber() {
        let showGrabber = this.isChildElement() && this.isRotatedElement()
        let rect = WDUtils.getBoundingRectOfElement(this.props.element)

        if (this.props.element.locked) {
            return <div className="ws-designer-element-grabber">
                <div className={"ws-designer-toolbar-button-locked"}>
                    <div className={"ws-designer-toolbar-button-locked-background"}/>
                    <WDToolbarButtonLock
                        id={1}
                        locked={true}
                        clickable={true}
                        onChangeLockingStatus={this.props.onUnlockElement}
                        onToggleButton={() => {}}/>
                </div>
            </div>
        }

        let style: React.CSSProperties = {}
        if (!this.props.resizeInfo.displaySelectionBorder) {
            style.outlineWidth = 0
        }

        let nodeOffset = Math.floor(this.nodeSize / 2)
        return <div className="ws-designer-element-grabber" style={style}>

            {/* Top/Left corner */}
            {!showGrabber && this.props.resizeInfo.topLeft &&
                <div key="1" className="ws-designer-element-node ws-designer-element-node-nw-cursor"
                     id={this.props.id + "-node-tl"}
                     onMouseDown={this.onMouseDownSetCurrentElement}
                     style={{
                         width: this.nodeSize,
                         height: this.nodeSize,
                         left: -nodeOffset - 1,
                         top: -nodeOffset - 1,
                     }}
                />}

            {/* Top/Right corner */}
            {!showGrabber && this.props.resizeInfo.topRight &&
                <div key="2" className="ws-designer-element-node ws-designer-element-node-ne-cursor"
                     id={this.props.id + "-node-tr"}
                     onMouseDown={this.onMouseDownSetCurrentElement}
                     onTouchStart={this.onMouseDownSetCurrentElement}
                     style={{
                         width: this.nodeSize,
                         height: this.nodeSize,
                         left: rect.width - nodeOffset + 1,
                         top: -nodeOffset - 1,
                     }}/>
            }

            {/* Bottom/Left corner */}
            {!showGrabber && this.props.resizeInfo.bottomLeft &&
                <div key="3" className="ws-designer-element-node ws-designer-element-node-ne-cursor"
                     id={this.props.id + "-node-bl"}
                     onMouseDown={this.onMouseDownSetCurrentElement}
                     onTouchStart={this.onMouseDownSetCurrentElement}
                     style={{
                         width: this.nodeSize,
                         height: this.nodeSize,
                         left: -nodeOffset - 1,
                         top: rect.height - nodeOffset + 1,
                     }}
                />}

            {/* Bottom/Right corner */}
            {!showGrabber && this.props.resizeInfo.bottomRight &&
                <div key="4" className="ws-designer-element-node ws-designer-element-node-nw-cursor"
                     id={this.props.id + "-node-br"}
                     onMouseDown={this.onMouseDownSetCurrentElement}
                     onTouchStart={this.onMouseDownSetCurrentElement}
                     style={{
                         width: this.nodeSize,
                         height: this.nodeSize,
                         left: rect.width - nodeOffset + 1,
                         top: rect.height - nodeOffset + 1,
                     }}
                />}

            {/* Right edge */}
            {!showGrabber && this.props.resizeInfo.right &&
                <div key="5" className="ws-designer-element-node ws-designer-element-node-e-cursor"
                     id={this.props.id + "-node-r"}
                     onMouseDown={this.onMouseDownSetCurrentElement}
                     onTouchStart={this.onMouseDownSetCurrentElement}
                     style={{
                         width: this.nodeSize,
                         height: this.nodeSize,
                         left: rect.width - nodeOffset + 1,
                         top: rect.height / 2 - nodeOffset + 1,
                     }}
                />}

            {/* Top edge */}
            {!showGrabber && this.props.resizeInfo.top &&
                <div key="6" className="ws-designer-element-node ws-designer-element-node-n-cursor"
                     id={this.props.id + "-node-t"}
                     onMouseDown={this.onMouseDownSetCurrentElement}
                     onTouchStart={this.onMouseDownSetCurrentElement}
                     style={{
                         width: this.nodeSize,
                         height: this.nodeSize,
                         left: rect.width / 2 - nodeOffset + 1,
                         top: -nodeOffset - 1,
                     }}
                />}

            {/* Left edge */}
            {!showGrabber && this.props.resizeInfo.left &&
                <div key="7" className="ws-designer-element-node ws-designer-element-node-e-cursor"
                     id={this.props.id + "-node-l"}
                     onMouseDown={this.onMouseDownSetCurrentElement}
                     onTouchStart={this.onMouseDownSetCurrentElement}
                     style={{
                         width: this.nodeSize,
                         height: this.nodeSize,
                         left: -nodeOffset - 1,
                         top: rect.height / 2 - nodeOffset,
                     }}
                />}

            {/* Bottom edge */}
            {!showGrabber && this.props.resizeInfo.bottom &&
                <div key="8" className="ws-designer-element-node ws-designer-element-node-n-cursor"
                     id={this.props.id + "-node-b"}
                     onMouseDown={this.onMouseDownSetCurrentElement}
                     onTouchStart={this.onMouseDownSetCurrentElement}
                     style={{
                         width: this.nodeSize,
                         height: this.nodeSize,
                         left: rect.width / 2 - nodeOffset + 1,
                         top: rect.height - nodeOffset + 1,
                     }}
                />}
        </div>
    }

    render() {
        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }

        let transformerStyle: React.CSSProperties = {}
        if (this.props.renderBackgroundColor !== false) {
            transformerStyle.backgroundColor = this.props.element.fillColor
        }
        transformerStyle.width = this.props.element.width
        transformerStyle.height = this.props.element.height
        transformerStyle.opacity = (this.props.element.transparency / 100).toString()
        transformerStyle.transform = "rotateX(" + (this.props.element.flipHorizontal ? 180 : 0) + "deg) " +
            "rotateY(" + (this.props.element.flipVertical ? 180 : 0) + "deg) " +
            "rotate(" + this.props.element.rotation + "deg) " +
            "skewX(" + this.props.element.skew + "deg)"

        if (this.props.renderBorder !== false && this.props.element.borderVisible) {
            transformerStyle.outlineColor = this.props.element.borderColor
            transformerStyle.outlineStyle = this.props.element.borderStyle
            transformerStyle.outlineWidth = this.props.element.borderWeight + "px"
        } else {
            transformerStyle.outlineColor = "transparent"
            transformerStyle.outlineStyle = "none"
            transformerStyle.outlineWidth = "0px"
        }
        // For debugging transformerStyle.backgroundColor = "#FF0000"

        let className = "ws-designer-element"
        if (this.props.element.selected && this.props.resizeInfo.displaySelectionBorder) {
            className += " ws-designer-element-selected"
        }
        // show red border only if element is not selected
        if (this.props.onAutoResize && this.props.resizerState === ResizerState.ERROR) {
            className += " ws-designer-element-error"
        }

        return <div id={this.props.id}
                    className={className}
                    style={{width: "100%", height: "100%"}}
                    onDragEnter={e => {
                        e.preventDefault()
                        this.props.onDragEnter?.(e)
                    }}
                    onDragOver={e => {
                        e.preventDefault()
                        this.props.onDragOver?.(e)
                    }}
                    onDragLeave={e => {
                        e.preventDefault()
                        this.props.onDragLeave?.(e)
                    }}
                    onDrop={e => {
                        e.preventDefault()
                        this.props.onDrop?.(e)
                    }}
        >
            {(!this.props.isReadOnly && this.props.element.selected && !this.state.editMode) && this.renderGrabber()}

            {!this.state.editMode && this.props.renderWrapper &&
                <div id={this.props.id + "-wrapper"}
                     className="ws-designer-element-wrapper"
                     onDoubleClick={this.props.isReadOnly ? undefined : this.onDoubleClickWrapper}
                     onMouseMove={this.props.isReadOnly ? undefined : this.props.onMouseMove}
                     onContextMenu={this.props.isReadOnly ? undefined : this.onContextMenu}
                />
            }

            {/* Use the top code as template for the children below */}
            <div
                id={this.props.id + "-transformer"}
                className="ws-designer-element-transformer"
                style={transformerStyle}
            >
                {this.props.children}
            </div>

            {/* Check size of textbox (too small for content?) */}
            {(this.props.onAutoResize && this.props.resizerState !== ResizerState.NONE) &&
                <WDTextboxResizeHandler id={"txt-resize-" + this.props.id}
                                        resize={this.props.onAutoResize}
                                        error={this.props.resizerState === ResizerState.ERROR}
                />
            }

            {/*{this.state.showTouch && <div style={{backgroundColor: "red", position: "absolute", width: "250px", height: "250px"}}>hallo {this.state.touches}</div> }*/}
        </div>
    }
}
