import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";
import {SearchInput} from "../../../../Components/Controls/SearchInput";
import {GetImageGalleryFiltered} from "../../../../_endpoint/ImageEndpoint";
import {WSContextType} from "../../../Elements/WSContext";
import {ImageGallery} from "../../../../Components/Images/ImageGallery";
import {ImageListFilter} from "../../../../Admin/Images/ImageListFilter";
import {Image, ImageFilter, ImageFilterAdmin} from "../../../../_model/Image";
import ListSortSetting from "../../../../Components/List/ListSortSetting";

/**
 * Button (with submenu) in Toolbar
 * Effects selected element(s)
 * Shows image gallery with search function
 * Dropdown:   List of images as gallery
 */

export enum ImageToolbarButtonType {
    replace,
    insert,
    select
}

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    width: number
    height: number
    type: ImageToolbarButtonType
    worksheetType: WSContextType

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}
interface IState {
    items?: Image[]
    search: string

    page: number
    lastItem: boolean
}

export class WDToolbarButtonSelectImage extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    PAGE_SIZE: number = 50

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            search: (this.props.worksheetType === WSContextType.writing_course_child || this.props.worksheetType === WSContextType.writing_course_main)
                    ? "Buchstabe"
                    : "",
            page: 1,
            lastItem: false
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (!prevProps.pressed && this.props.pressed && this.state.items === undefined) {
            this.fetchData()
        }
    }

    lastItemResultReached = (amountOfResult: number, pageSize?: number) => {
        return (pageSize ? pageSize : this.PAGE_SIZE) > amountOfResult;
    }

    onSearch = (value: string) => {
        this.setState({search: value}, this.fetchData)
    }
    onFetchNextPage = (callback?: (page: number, lastPage: boolean) => void) => {
        this.fetchData(true, callback)
    }

    fetchData = (append: boolean = false, callback?: (page: number, lastPage: boolean) => void) => {
        const filter = new ImageFilter()

        if (this.state.search.length > 0) {
            filter.search = this.state.search
        }
        filter.alignment = ImageListFilter.all_alignments
        filter.property = ImageListFilter.all_properties
        filter.color = ImageListFilter.all_colors
        filter.sort = new ListSortSetting("rating", true)
        filter.adminFilter = new ImageFilterAdmin()

        let page = append ? this.state.page + 1 : 0
        GetImageGalleryFiltered(filter, this.props.worksheetType, page, this.PAGE_SIZE).then(
            (itemData) => {
                let items = (itemData.content as Image[]).filter(item => item.url !== null)
                if (append && this.state.items) {
                    // Page number would be increased by 1 but if no items are retrieved keep page number
                    if (items.length === 0) {
                        page --
                    }
                    items = this.state.items?.concat(items)
                }
                let lastItemReached = this.lastItemResultReached(itemData.content.length)

                this.setState({items: items, page: page, lastItem: lastItemReached},
                    () => callback?.(page, lastItemReached))
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    onClickImage = (image: Image) => {
        this.props.onActionButton(WDToolbarAction.SELECT_IMAGE, {image: image.id!})
    }

    render() {
        let tooltip = new TooltipText(this.context.translate(translations.toolbar.insert_image), this.context.translate(translations.tooltip.insert_image))
        let imageUrl = "add_image.svg"
        if (this.props.type === ImageToolbarButtonType.replace) {
            imageUrl = "replace_image.svg"
        }

        return <li
            key={"ws-designer-toolbar-button-replace-image"}
            className={"ws-designer-toolbar-item"}
            id={"toolbarImage"}>

            <WDToolbarButton key={"btnToolbarImage"}
                             id={this.props.id}
                             tooltip={tooltip}
                             icon={ImagePath.getButtonUrl() + imageUrl}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             onToggleButton={this.props.onToggleButton}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed}>
            </WDToolbarButton>

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                             tooltip={tooltip}

                             iconClassSelected={"ws-designer-toolbar-button-options"}
                             iconClassUnselected={"ws-designer-toolbar-button-options"}

                             arrow={false}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             buttonHeight={32}
                             buttonWidth={15}
                             imageHeight={5}
                             imageWidth={5}

                             onToggleButton={this.props.onToggleButton}
                             pressed={this.props.pressed} />

            {this.props.pressed &&
                <div className={"ws-designer-toolbar-options"}>
                    <div className={"ws-designer-options-section-column"}
                         style={{
                             width: this.props.width,
                             height: this.props.height
                         }}>

                        <SearchInput width={250}
                                     value={this.state.search}
                                     onSearch={this.onSearch}
                                     typeAheadSearch={false}
                                     readonly={false}
                                     showFieldOptions={false}
                                     maxLength={30}
                        />

                        <ImageGallery items={this.state.items}
                                      itemHeight={50}
                                      onFetchNextPage={this.onFetchNextPage}
                                      onClickImage={this.onClickImage}
                        />
                    </div>
                </div>
            }
        </li>
    }
}
