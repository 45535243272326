import Entity from "./Entity";
import React from "react";
import {WDTextbox} from "../Designer/Elements/Textbox/WDTextbox";
import {WDCalculationTriangle} from "../Designer/Elements/Math/CalculationTriangle/WDCalculationTriangle";
import {WDWritingLineature} from "../Designer/Elements/Lineature/WritingLineature/WDWritingLineature";
import {WDImage} from "../Designer/Elements/Image/WDImage";
import {WDBalloon} from "../Designer/Elements/Balloon/WDBalloon";
import {WDMathLineature} from "../Designer/Elements/Lineature/MathLineature/WDMathLineature";
import {WDCalculationTower} from "../Designer/Elements/Math/CalculationTower/WDCalculationTower";
import {WDTable} from "../Designer/Elements/Table/WDTable";
import {WDTextExercise} from "../Designer/Elements/TextExercise/WDTextExercise";
import {WDCalculationTriangleToolbar} from "../Designer/Elements/Math/CalculationTriangle/WDCalculationTriangleToolbar";
import {WDTextboxToolbar} from "../Designer/Elements/Textbox/WDTextboxToolbar";
import {WDWritingLineatureToolbar} from "../Designer/Elements/Lineature/WritingLineature/WDWritingLineatureToolbar";
import {WDImageToolbar} from "../Designer/Elements/Image/WDImageToolbar";
import {WDBalloonToolbar} from "../Designer/Elements/Balloon/WDBalloonToolbar";
import {WDMathLineatureToolbar} from "../Designer/Elements/Lineature/MathLineature/WDMathLineatureToolbar";
import {WDCalculationTowerToolbar} from "../Designer/Elements/Math/CalculationTower/WDCalculationTowerToolbar";
import {WDTableToolbar} from "../Designer/Elements/Table/WDTableToolbar";
import {WDTextExerciseToolbar} from "../Designer/Elements/TextExercise/WDTextExerciseToolbar";
import {WDGroupToolbar} from "../Designer/Elements/Group/WDGroupToolbar";
import {WDToolbarMixed} from "../Designer/Toolbar/Toolbar/WDToolbarMixed";
import {WDGroup} from "../Designer/Elements/Group/WDGroup";
import {WDWritingCourse} from "../Designer/Elements/WritingCourse/WDWritingCourse";
import {WDShapeToolbar2D} from "../Designer/Elements/Shape/WDShapeToolbar2D";
import {WDLine} from "../Designer/Elements/Line/WDLine";
import {WDShape2d} from "../Designer/Elements/Shape/WDShape2d";
import {WDShape3d} from "../Designer/Elements/Shape/WDShape3d";
import {WDShapeBuildingBrick} from "../Designer/Elements/Shape/WDShapeBuildingBrick";
import {WDShapeToolbar3D} from "../Designer/Elements/Shape/WDShapeToolbar3D";
import {WDShapeToolbarBuildingBrick} from "../Designer/Elements/Shape/WDShapeToolbarBuildingBrick";
import {WDShapeCraftPattern} from "../Designer/Elements/Shape/WDShapeCraftPattern";
import {WDShapeToolbarCraftPattern} from "../Designer/Elements/Shape/WDShapeToolbarCraftPattern";

// match with worksheetItemType in database (integers are ids)
export enum WorksheetItemTypeEnum {
    TEXTBOX = 1,
    CALCULATION_TRIANGLE = 3,
    WRITING_LINEATURE = 4,
    IMAGE = 5,
    BALLOON = 6,
    MATH_LINEATURE,
    CALCULATION_TOWER,
    GROUP,
    TABLE,
    TEXT_EXERCISE,
    SINGULAR_PLURAL,
    WRITING_COURSE,
    SHAPE2D,
    LINE,
    RULE = 16,
    WORKSHEET_ITEMS,
    SHAPE3D,
    SHAPE_BUILDING_BRICK,
    SHAPE_CRAFT_PATTERN,
    FRAME
}

export class WorksheetItemType extends Entity {
    usedForRules?: boolean

    static getReactRefByWorksheetItemType = (worksheetItemType: WorksheetItemTypeEnum) => {
        switch (worksheetItemType) {
            case WorksheetItemTypeEnum.TEXTBOX: return React.createRef<WDTextbox>()
            case WorksheetItemTypeEnum.CALCULATION_TRIANGLE: return React.createRef<WDCalculationTriangle>()
            case WorksheetItemTypeEnum.WRITING_LINEATURE: return React.createRef<WDWritingLineature>()
            case WorksheetItemTypeEnum.IMAGE: return React.createRef<WDImage>()
            case WorksheetItemTypeEnum.BALLOON: return React.createRef<WDBalloon>()
            case WorksheetItemTypeEnum.MATH_LINEATURE: return React.createRef<WDMathLineature>()
            case WorksheetItemTypeEnum.CALCULATION_TOWER: return React.createRef<WDCalculationTower>()
            case WorksheetItemTypeEnum.TABLE: return React.createRef<WDTable>()
            case WorksheetItemTypeEnum.TEXT_EXERCISE: return React.createRef<WDTextExercise>()
            case WorksheetItemTypeEnum.GROUP: return React.createRef<WDGroup>()
            case WorksheetItemTypeEnum.WRITING_COURSE: return React.createRef<WDWritingCourse>()
            case WorksheetItemTypeEnum.SHAPE2D: return React.createRef<WDShape2d>()
            case WorksheetItemTypeEnum.SHAPE3D: return React.createRef<WDShape3d>()
            case WorksheetItemTypeEnum.SHAPE_BUILDING_BRICK: return React.createRef<WDShapeBuildingBrick>()
            case WorksheetItemTypeEnum.SHAPE_CRAFT_PATTERN: return React.createRef<WDShapeCraftPattern>()
            case WorksheetItemTypeEnum.LINE: return React.createRef<WDLine>()
            default: throw new Error("Cannot create reference for " + worksheetItemType)
        }
    }

    static getToolbarReactRefByWorksheetItemType = (worksheetItemType: WorksheetItemTypeEnum) => {
        switch (worksheetItemType) {
            case WorksheetItemTypeEnum.TEXTBOX: return React.createRef<WDTextboxToolbar>()
            case WorksheetItemTypeEnum.CALCULATION_TRIANGLE: return React.createRef<WDCalculationTriangleToolbar>()
            case WorksheetItemTypeEnum.WRITING_LINEATURE: return React.createRef<WDWritingLineatureToolbar>()
            case WorksheetItemTypeEnum.IMAGE: return React.createRef<WDImageToolbar>()
            case WorksheetItemTypeEnum.BALLOON: return React.createRef<WDBalloonToolbar>()
            case WorksheetItemTypeEnum.MATH_LINEATURE: return React.createRef<WDMathLineatureToolbar>()
            case WorksheetItemTypeEnum.CALCULATION_TOWER: return React.createRef<WDCalculationTowerToolbar>()
            case WorksheetItemTypeEnum.TABLE: return React.createRef<WDTableToolbar>()
            case WorksheetItemTypeEnum.TEXT_EXERCISE: return React.createRef<WDTextExerciseToolbar>()
            case WorksheetItemTypeEnum.GROUP: return React.createRef<WDGroupToolbar>()
            case WorksheetItemTypeEnum.SHAPE2D: return React.createRef<WDShapeToolbar2D>()
            case WorksheetItemTypeEnum.SHAPE3D: return React.createRef<WDShapeToolbar3D>()
            case WorksheetItemTypeEnum.SHAPE_BUILDING_BRICK: return React.createRef<WDShapeToolbarBuildingBrick>()
            case WorksheetItemTypeEnum.SHAPE_CRAFT_PATTERN: return React.createRef<WDShapeToolbarCraftPattern>()
            default: return React.createRef<WDToolbarMixed>()
        }
    }
}
