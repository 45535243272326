import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbar, WDToolbarOrientation} from "../../Toolbar/Toolbar/WDToolbar";
import {ImagePath} from "../../../Framework/CategoryImage";
import translations from "../../../Framework/translations.json";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import Converter from "../../../Framework/Converter";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarSectionObjectGeneral} from "../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarSectionTransformation} from "../../Toolbar/Section/WDToolbarSectionTransformation";
import {ElementLayout} from "../WDElementContainer";
import {WDToolbarButtonColor} from "../../Toolbar/Button/General/WDToolbarButtonColor";
import {WDToolbarFontType} from "../../Toolbar/Button/Text/WDToolbarFontType";
import {WDToolbarButtonTextDecoration} from "../../Toolbar/Button/Text/WDToolbarButtonTextDecoration";
import {TooltipPosition, TooltipText} from "../../../Components/Tooltips";
import {WDToolbarButtonTaskGenerator} from "../../Toolbar/Button/Math/WDToolbarButtonTaskGenerator";
import {WDToolbarButtonSectionShow} from "../../Toolbar/Button/TextExercise/WDToolbarButtonSectionShow";
import {WDTextExerciseSection} from "./WDTextExerciseData";
import {WDToolbarButtonSolutionShow} from "../../Toolbar/Button/Math/WDToolbarButtonSolutionShow";
import {WDToolbarButtonRegenerate} from "../../Toolbar/Button/Math/WDToolbarButtonRegenerate";
import {WDToolbarButtonRemoveCalculation} from "../../Toolbar/Button/Math/WDToolbarButtonRemoveCalculation";
import {WDToolbarButtonDifficultyLevel} from "../../Toolbar/Button/Math/WDToolbarButtonDifficultyLevel";
import {WDToolbarButtonCalculationType} from "../../Toolbar/Button/Math/WDToolbarButtonCalculationType";
import {DropDownItem} from "../../../Components/Controls/DropDown";
import {WDToolbarButtonSolutionBar} from "../../Toolbar/Button/Math/WDToolbarButtonSolutionBar";
import {ValueRangeMode, WDToolbarButtonValueRange} from "../../Toolbar/Button/Math/WDToolbarButtonValueRange";
import WDTextExerciseDropDownValues from "./WDTextExerciseDropDownValues";
import {WDToolbarButtonTextExerciseSearch} from "../../Toolbar/Button/TextExercise/WDToolbarButtonTextExerciseSearch";
import {WDToolbarButtonTextExerciseTopics} from "../../Toolbar/Button/TextExercise/WDToolbarButtonTextExerciseTopics";
import {WDMathLineatureProps, WDMathLineatureState} from "../Lineature/MathLineature/WDMathLineatureToolbar";
import _ from "lodash";
import {ProductLicense} from "../../../Framework/Enums";

export interface WDTableProps extends WDToolbarElementProps { }
export interface WDTableState extends WDToolbarElementState { }

export class WDTextExerciseToolbar extends WDToolbarElement<WDTableProps, WDTableState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    elementLicenses: ProductLicense[] = [ProductLicense.employee]
    calculationDropDownValues: DropDownItem[] = []

    constructor(props: WDTableProps) {
        super(props)

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: this.mapEditModeToToolbarType(),
            refToolbar: React.createRef()
        }
    }

    componentDidMount() {
        this.calculationDropDownValues = WDTextExerciseDropDownValues.getCalculationType().map(type => {
            return new DropDownItem(type.key.toString(), this.context.translate(type.value))
        })
        this.hasValidLicenseForElement(this.elementLicenses)
        this.setState({activeId: -1})
    }
    shouldComponentUpdate(nextProps: Readonly<WDMathLineatureProps>, nextState: Readonly<WDMathLineatureState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    mapEditModeToToolbarType = () => {
        let objectMode = WDToolbarTypeEnum.OBJECT

        // Keep the current mode if it is set
        if (this.state) {
            objectMode = this.state.activeToolbarType
        }

        return objectMode
    }
    onChangeToolbarTab = (type: WDToolbarTypeEnum) => {
        if(this.state.activeToolbarType !== type) {
            this.setState({activeToolbarType: type})
        }
    }

    render() {
        const images = this.multiselectSearchForValue("images")
        const calculation = this.multiselectSearchForValue("calculation")
        const answer = this.multiselectSearchForValue("answer")
        const difficultyLevelValueObj = this.multiselectSearchForValue("difficulty")
        const calculationTypeValueObj = this.multiselectSearchForValue("calculationType")
        const zrValueObj = this.multiselectSearchForValue("numberRange")
        const topicValueObj = this.multiselectSearchForValue("topicId")

        const difficultyLevel = (difficultyLevelValueObj?.valueEqual && difficultyLevelValueObj.newValue !== undefined) ? difficultyLevelValueObj.newValue : undefined
        const calculationType = (calculationTypeValueObj?.valueEqual && calculationTypeValueObj.newValue !== undefined) ? calculationTypeValueObj.newValue : undefined
        const zr = (zrValueObj?.valueEqual && zrValueObj.newValue !== undefined) ? zrValueObj.newValue : -1
        const topic = (topicValueObj?.valueEqual && topicValueObj.newValue !== undefined) ? topicValueObj.newValue : undefined

        const imagesVisible = images?.newValue.visible
        const calculationVisible = calculation?.newValue.visible
        const answerVisible = answer?.newValue.visible

        const imagesToggleEnabled = images?.newValue.toggleEnabled
        const calculationToggleEnabled = calculation?.newValue.toggleEnabled
        const answerToggleEnabled = answer?.newValue.toggleEnabled

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, true, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={false}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={true}
                    canChangeLinkState={true}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.TEXT &&
            <ul className={"ws-designer-toolbar-buttons"}>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarFontType
                            id={201}
                            action={WDToolbarAction.FONT_TYPE}
                            readonly={true}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonTextDecoration
                            id={301}
                            clickable={true}
                            colorPicker={false}
                            command={"bold"}
                            action={WDToolbarAction.BOLD}
                            icon={ImagePath.getButtonUrl() + "bold.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.bold))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonTextDecoration
                            id={302}
                            clickable={true}
                            colorPicker={false}
                            command={"italic"}
                            action={WDToolbarAction.ITALIC}
                            icon={ImagePath.getButtonUrl() + "italic.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.italic))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonColor
                            id={307}
                            clickable={true}
                            command={"foreColor"}
                            options={{
                                action: WDToolbarAction.FONT_COLOR,
                                open: this.state.activeId === 307,
                                width: 70,
                                height: 150
                            }}
                            showColor={true}
                            transparency={true}
                            tooltipPosition={TooltipPosition.above}
                            pressed={false}
                            initialColor={"#000000"}
                            action={WDToolbarAction.FONT_COLOR}
                            icon={ImagePath.getButtonUrl() + "font_color.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.fontColor))}
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                        />
                    </div>
                </div>
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&

            <ul className={"ws-designer-toolbar-buttons"}>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonTaskGenerator
                            id={1}
                            pressed={false}
                            enabled={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.task_generator_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonRegenerate
                            id={2}
                            pressed={false}
                            clickable={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.regenerate_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonValueRange
                            id={3}
                            dropDownValues={WDTextExerciseDropDownValues.getNumberRange().map(zr => {
                                return new DropDownItem(zr.key.toString(), zr.value)
                            })}
                            mode={ValueRangeMode.simple}
                            zr={zr}
                            pressed={this.state.activeId === 3}
                            clickable={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.change_value_range_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonCalculationType
                            id={4}
                            type={calculationType}
                            pressed={this.state.activeId === 4}
                            dropDownValues={this.calculationDropDownValues}
                            clickable={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.calculation_type_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonDifficultyLevel
                            id={5}
                            pressed={this.state.activeId === 5}
                            clickable={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.difficulty_level_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            difficulty={difficultyLevel}
                            showEmptyValue={true}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>

                    </div>
                </div>

                <div className={"ws-designer-toolbar-button-separator"} />

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonTextExerciseSearch
                            id={20}
                            enabled={this.state.isLicenseValidForElement}
                            onActionButton={this.onToolbarAction}
                            difficulty={difficultyLevel}
                            calculationType={calculationType}
                            zr={zr}
                            topic={topic}
                        />
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonTextExerciseTopics id={21}
                                                           enabled={this.state.isLicenseValidForElement}
                                                           topic={topic}
                                                           onActionButton={this.onToolbarAction}
                        />
                    </div>
                </div>

                <div className={"ws-designer-toolbar-button-separator"} />

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonSectionShow
                            id={8}
                            pressed={!imagesVisible}
                            clickable={this.state.isLicenseValidForElement && imagesToggleEnabled}
                            tooltipDisabled={!this.state.isLicenseValidForElement ?
                                new TooltipText(
                                    this.context.translate(translations.toolbar.section_images_show_disabled),
                                    this.context.translate(translations.tooltip.no_license))
                                :
                                new TooltipText(
                                    this.context.translate(translations.toolbar.section_images_show_disabled),
                                    this.context.translate(translations.tooltip.section_images_toggle_disabled)
                                )}
                            section={WDTextExerciseSection.IMAGES}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}
                        />

                        <WDToolbarButtonSectionShow
                            id={9}
                            pressed={!answerVisible}
                            clickable={this.state.isLicenseValidForElement && answerToggleEnabled}
                            tooltipDisabled={!this.state.isLicenseValidForElement ?
                                new TooltipText(
                                    this.context.translate(translations.toolbar.section_answer_show_disabled),
                                    this.context.translate(translations.tooltip.no_license))
                                :
                                new TooltipText(
                                    this.context.translate(translations.toolbar.section_answer_show_disabled),
                                    this.context.translate(translations.tooltip.section_answer_toggle_disabled)
                                )}
                            section={WDTextExerciseSection.ANSWER}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}
                        />

                    </div>

                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonRemoveCalculation
                            id={10}
                            pressed={false}
                            clickable={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.section_calculation_remove_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}
                            onShowConfirmation={this.props.onShowConfirmation}
                        />

                        <WDToolbarButtonSectionShow
                            id={11}
                            pressed={!calculationVisible}
                            clickable={this.state.isLicenseValidForElement && calculationToggleEnabled}
                            tooltipDisabled={!this.state.isLicenseValidForElement ?
                                new TooltipText(
                                this.context.translate(translations.toolbar.section_calculation_show_disabled),
                                this.context.translate(translations.tooltip.no_license))
                                :
                                new TooltipText(
                                this.context.translate(translations.toolbar.section_calculation_show_disabled),
                                this.context.translate(translations.tooltip.section_calculation_toggle_disabled)
                                )}
                            section={WDTextExerciseSection.CALCULATION}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}
                        />

                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonSolutionShow
                            id={12}
                            pressed={false}
                            clickable={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.solution_show_hide_disabled),
                                this.context.translate(translations.tooltip.no_license)
                            )}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonSolutionBar
                            id={13}
                            pressed={false}
                            clickable={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.solution_bar_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />
                    </div>
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={true}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    elementProps={this.props.elementProps}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onChangeBorder={this.props.onChangeBorder}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onElementDeleted={this.props.onElementDeleted}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                />
            </ul>
            }
        </WDToolbar>
    }
}
