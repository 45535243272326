import {CorrectionMarginPosition} from "./WDWritingLineatureCorrectionMargin";
import {WDWritingLineatureSize} from "./WDWritingLineature";
import {EnumToEntityArrayWithoutTranslate} from "../../../../Framework/Enums";

export default class WDWritingLineatureDropDownValues {

    static getLineatureSizeValues() {
        return EnumToEntityArrayWithoutTranslate(WDWritingLineatureSize)
    }

    static getLineatureDefaultSizeValue() {
        return WDWritingLineatureSize["10-"]
    }

    static getLineatureCorrectionMarginValues() {
        return Object.values(CorrectionMarginPosition)
    }
}
