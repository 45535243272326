import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButton} from "../WDToolbarButton";
import {ImagePath} from "../../../../Framework/CategoryImage";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";
import {WDColorPicker} from "../../General/WDColorPicker";
import Const from "../../../../Framework/Const";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button (Yes/No) in Toolbar with submenu
 * Effects selected element(s)
 * Yes: correcting margin should be shown at specific position
 * No:  correcting margin should be hidden
 */

interface IProps {
    id: number
    borderKey: string
    width: number
    height: number
    open: boolean
    clickable: boolean
    pressed: boolean

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data?: any) => void
}

interface IState {
    color: string
}

export enum BorderType {
    all = "all",
    no = "no",
    left = "left",
    right = "right",
    top = "top",
    bottom = "bottom",
    inner = "inner",
    outer = "outer",
    middle = "middle",
    bottom_double = "bottom_double",
    manual = "manual",
    erase = "erase"
}

export class WDToolbarButtonCellBorder extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            color: Const.COLOR_PRIMARY
        }
    }

    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    onChangeBorderType = (id: string) => {
        let valuePressed: any = {"type": id.toUpperCase(), "color": this.state.color}
        if (id === BorderType.manual) {
            this.props.onActionButton(WDToolbarAction.CELL_BORDER_MANUAL, valuePressed)
        }
        else if (id === BorderType.erase) {
            this.props.onActionButton(WDToolbarAction.CELL_BORDER_ERASE, valuePressed)
        }
        else {
            this.props.onActionButton(WDToolbarAction.CELL_BORDER_COLOR, valuePressed)
        }

        // close submenu
        this.props.onToggleButton(-1)
    }
    onChangeColor = (color: string) => {
        this.setState({color: color})

        this.props.onActionButton(WDToolbarAction.CELL_BORDER_MANUAL_COLOR, {"color": color})
    }

    onToggleBorderType = () => {
        let valuePressed: any = {"type": this.props.borderKey, "color": this.state.color}
        this.props.onActionButton(WDToolbarAction.CELL_BORDER_COLOR, valuePressed)
    }

    render() {
        let borderTypes: BorderType[] = [
            BorderType.all, BorderType.outer, BorderType.top, BorderType.left, BorderType.manual,
            BorderType.no, BorderType.inner, BorderType.bottom, BorderType.right, BorderType.erase
        ]
        let buttons: JSX.Element[] = []

        borderTypes.forEach(type => {
            buttons.push(<WDToolbarButton  id={this.props.id}
                                           key={this.props.id + "-" + type}
                                           icon={ImagePath.getButtonUrl() + "frame_" + type + ".svg"}
                                           iconClassSelected={"ws-designer-toolbar-button-img"}
                                           cssClass={"ws-designer-toolbar-button"}
                                           onToggleButton={() => this.onChangeBorderType(type)}
                                           tooltip={new TooltipText(
                                               this.context.translate(translations.toolbar["cell_border_" + type]),
                                               this.context.translate(translations.tooltip["cell_border_" + type])
                                           )}
                                           arrow={false}
                                           enabled={this.props.clickable}
                                           pressed={false} />)
        })

        return <li
            key={"ws-designer-toolbar-button"}
            className={"ws-designer-toolbar-item"}
            id={"cellBorder"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + "frame_" + this.props.borderKey.toLowerCase() + ".svg"}
                             iconClassSelected={"ws-designer-toolbar-button-img"}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.cell_border), this.context.translate(translations.tooltip.cell_border))}
                             onToggleButton={this.onToggleBorderType}
                             color={this.state.color}
                             buttonWidth={28}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed} />

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.cell_border), this.context.translate(translations.tooltip.cell_border))}

                             iconClassSelected={"ws-designer-toolbar-button-options"}
                             iconClassUnselected={"ws-designer-toolbar-button-options"}

                             arrow={false}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             buttonHeight={32}
                             buttonWidth={15}
                             imageHeight={5}
                             imageWidth={5}

                             onToggleButton={this.props.onToggleButton}
                             pressed={this.props.open} />

            {this.props.open &&
                <div className={"ws-designer-toolbar-options"} style={{overflow: "visible"}}>
                    <div className={"ws-designer-options-section-options"}
                        style={{
                             width: this.props.width - 30,
                             height: this.props.height
                         }}>

                        {buttons}
                    </div>

                    <WDColorPicker transparency={false} onChangeColor={this.onChangeColor} />
                </div>
            }
        </li>
    }
}
