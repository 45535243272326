import React, {PropsWithChildren} from "react";
import Auth from "../Framework/Auth";
import {BrowserRouter as Router, RouteComponentProps} from "react-router-dom";
import translations from "../Framework/translations.json";
import {MainContext} from "./MainContext";
import {GetAnnouncements} from "../_endpoint/MetadataEndpoint";
import Announcement, {AnnouncementType} from "../_model/Announcement";
import {FloatingHint} from "../Components/Notification/FloatingHint";
import {NotificationStatus} from "../Framework/Enums";
import {withRouter} from "react-router";
import {Util} from "../Framework/Util";
import {AppLogo} from "./AppLogo";
import {Profile} from "../Profile/Profile";
import {UserSettings} from "../_model/UserSettings";
import {ImagePath} from "../Framework/CategoryImage";
import PopoutMenu, {PopupMenuItem} from "../Components/Controls/PopoutMenu";
import {NotificationData} from "../Components/Notification/Hint";
import Conf from "../Framework/Conf";

interface IProps extends RouteComponentProps, PropsWithChildren {
    items?: PopupMenuItem[]
    notification?: NotificationData
    isAdminArea: boolean
    app?: string
}
interface IState {
    announcements: Announcement[]
    userSettings?: UserSettings
    showProfile: boolean

    popoutRef: React.RefObject<PopoutMenu>
}

class AppHeader extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            announcements: [],
            showProfile: false,
            popoutRef: React.createRef<PopoutMenu>()
        }
        window.addEventListener('online', this.handleOnline);
        window.addEventListener('offline', this.handleOffline);
    }

    componentDidMount() {
        this.context.getUserSettings().then((settings) => {
            this.setState({userSettings: { ...settings } })
        })

        // Get announcements
        let announce = this.context.getGlobalAnnouncements()

        GetAnnouncements().then((result) => {
            announce = announce.concat(result)
            this.setState({announcements: announce})

        }, (error) => { this.context.handleError(error) })
    }
    componentWillUnmount() {
        window.removeEventListener('online', this.handleOnline);
        window.removeEventListener('offline', this.handleOffline);
    }

    handleOnline = () => {
        let filteredAnnouncement = this.state.announcements.filter(
            (announce) => announce.name !== this.context.translate(translations.notification.no_internet_connection_name)
        )

        if(filteredAnnouncement.length !== this.state.announcements.length) {
            this.setState({announcements: filteredAnnouncement})
        }
    }
    handleOffline = () => {
        let announcements = this.state.announcements
        announcements.push(new Announcement(0,
            this.context.translate(translations.notification.no_internet_connection_name),
            true, AnnouncementType.info,
            this.context.translate(translations.notification.no_internet_connection)))

        this.setState({announcements: announcements})
    }

    onClickNavigateToOdoo = (e: MouseEvent, url: string) => {
        e.stopPropagation()
        e.preventDefault()

        this.state.popoutRef.current?.closeProfileMenu(e)
        window.open(url, '_blank');
    }
    onClickProfile = (e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()

        this.state.popoutRef.current?.closeProfileMenu(e)
        this.setState({showProfile: true})
    }
    onClickLogout = (e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()

        this.context.removeContext()
        Auth.Logout()

        this.props.history.push("/login")
    }

    render() {
        let headerClass = "app-header";
        let headerProfileClass = "app-header-profile"
        let profilePicture = process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "a-at-mask-vici-hello.svg"

        if(this.props.isAdminArea) {
            headerClass += " app-header-admin"
            headerProfileClass += " app-header-profile-admin"
        }
        if(this.state.userSettings && this.state.userSettings.profilePicture !== null && this.state.userSettings.profilePicture !== undefined) {
            profilePicture = "data:image/png;base64, " + this.state.userSettings.profilePicture
        }

        let items = this.props.items || []
        items.push(new PopupMenuItem(this.context.translate(translations.text.profile), (event) => {this.onClickNavigateToOdoo(event.nativeEvent, Conf.WEBSITE_URL() + "my/home")}))
        items.push(new PopupMenuItem(this.context.translate(translations.text.settings), (event) => this.onClickProfile(event.nativeEvent)))
        items.push(new PopupMenuItem(this.context.translate(translations.text.help_support), (event) => {}))
        items.push(new PopupMenuItem(this.context.translate(translations.text.extend_app), (event) => {this.onClickNavigateToOdoo(event.nativeEvent, Conf.WEBSITE_URL() + "shop")}))
        items.push(new PopupMenuItem(this.context.translate(translations.text.logout), (event) => this.onClickLogout(event.nativeEvent)))

        return <Router basename={process.env.PUBLIC_URL}>
            <header className={headerClass}>

                <div className={"app-header-content"}>
                    <AppLogo adminArea={this.props.isAdminArea} app={this.props.app} />

                    {this.props.children}

                    <div className="app-header-profile-menu" style={{width: "115px"}}>
                        <img className={headerProfileClass}
                             src={profilePicture}
                             alt={this.context.translate(translations.text.profile_picture)}
                             onClick={event => this.state.popoutRef.current?.openProfileMenu(event.nativeEvent)}
                             draggable={"false"}
                             onContextMenu={(e) => e.preventDefault() }
                        />

                        <PopoutMenu id={"app-header-profile"} width={220} items={items} ref={this.state.popoutRef} />
                    </div>
                </div>

                <div className={"app-header-announcements"}>
                    {this.state.announcements.map(item => {
                        let message = "", type: NotificationStatus = NotificationStatus.info

                        if (item.type === AnnouncementType.maintenance && item.from && item.to) {
                            message = this.context.translate(translations.notification.planned_maintenance)
                            message = message.replace("%1%", Util.formatDate(item.from, true))
                            message = message.replace("%2%", Util.formatDate(item.to, true))

                            if (item.description) {
                                message += "<p>" + item.description + "</p>"
                            }
                        } else if (item.type === AnnouncementType.problem) {
                            type = NotificationStatus.error
                            message = item.description
                        } else if (item.type === AnnouncementType.info) {
                            message = item.description
                        }

                        return <FloatingHint
                            id={"announcement" + item.id}
                            key={item.id}
                            notificationData={new NotificationData(type, message)}
                            style={{margin: "-5px", width: "100%", position: "relative"}}/>
                    })}
                    {this.props.notification &&
                        <FloatingHint
                            id={"announcement-notification"}
                            notificationData={this.props.notification}
                            style={{margin: "-5px", width: "100%", position: "relative"}}/>
                    }
                </div>
            </header>
            {this.state.showProfile &&
                <Profile onCancel={() => this.setState({showProfile: false})}/>
            }
        </Router>
    }
}

export default withRouter(AppHeader)
