import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import {Util} from "../../../../Framework/Util";
import {WDToolbarButtonColorPicker} from "../General/WDToolbarButtonColorPicker";
import {TooltipText} from "../../../../Components/Tooltips";
import {OptionSettings} from "../../General/OptionSettings";

interface IProps {
    id: number

    clickable: boolean
    icon: string
    tooltip: TooltipText
    tooltipDisabled?: TooltipText
    options: OptionSettings

    command: string
    action: WDToolbarAction

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void

    // Used when text decoration button is used globally and not on text selection (Calculation Triangle)
    pressed?: boolean
}

interface IState {
    pressed: boolean
    color: string
}

/**
 * Button (Yes/No) in Text Toolbar
 * Effects selected text of an element
 * Yes: marked text should get the text decoration given as command
 *      by now all entered text should get bold
 * No:  marked text should get normal
 *      by now all entered text should get normal
 */
export class WDToolbarButtonHighlight extends React.Component<IProps, IState> {

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            pressed: this.props.pressed || false,
            color: "#FFFF00"
        }
    }

    componentDidMount() {
        document.addEventListener('selectionchange', this.onSelectionChange, false);
    }
    componentWillUnmount() {
        document.removeEventListener('selectionchange', this.onSelectionChange, false);
    }

    isHighlighted = () => {
        const selection = window.getSelection()
        if (selection && selection.focusNode) {
            const selectedElement = selection.focusNode as HTMLElement

            // Get the textbox element and check if selected item is in text
            const parent = Util.getParentByClass(selectedElement, "ws-designer-textbox")
            if (parent) {
                if (Util.getParentByStyle(selectedElement, "background-color", "", parent.id,
                    ["#ffffff", "initial", "transparent", "rgb(255,255,255)"])) {
                    return true
                }
            }
        }

        return false
    }

    onSelectionChange = () => {
        this.setState({pressed: this.isHighlighted() })
    }

    onChangeColor = (color: string) => {
        this.setState({color: color})

        this.props.onActionButton(this.props.action, {command: this.props.command, color: color})
    }
    onToggle = () => {
        let color = this.state.color
        if (this.isHighlighted()) {
            color = "transparent"
        }

        this.props.onActionButton(this.props.action, {command: this.props.command, color: color})
    }
    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-" + this.props.command}
            className={"ws-designer-toolbar-item"}
            id={this.props.command}>

            <WDToolbarButton id={this.props.id}
                             icon={this.props.icon}
                             iconClassSelected={"ws-designer-toolbar-button-img svg-color-primary"}
                             iconClassUnselected={"ws-designer-toolbar-button-img svg-color-primary"}
                             tooltip={this.props.tooltip}
                             tooltipDisabled={this.props.tooltipDisabled}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             onToggleButton={this.onToggle}
                             arrow={false}
                             buttonWidth={28}
                             color={this.state.color}
                             enabled={this.props.clickable}
                             pressed={this.state.pressed} />

            <WDToolbarButtonColorPicker
                id={this.props.id}
                tooltip={this.props.tooltip}
                open={this.props.options.open}
                clickable={this.props.clickable}
                transparency={true}
                onToggleButton={this.onToggleList}
                onChangeColor={this.onChangeColor}
            />

        </li>
    }
}
