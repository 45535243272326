import API from "../Framework/API";
import Name from "../_model/Name";
import NameConfig from "../_model/NameConfig";
import {Worksheet} from "../_model/Worksheet";

const URL = "names"

export function GetAllNamesAdmin() {
    let url = URL + "/"
    return API.Get<Name[]>(url)
}
export function GetName(nameId: number) {
    let url = URL + "/" + nameId
    return API.Get<Name>(url)
}

export async function CreateName(name: Name) {
    return API.Post<object, Name>(URL, name)
}
export async function CloneName(item: Name, name: string) {
    item.name = name
    return CreateName(item)
}
export async function UpdateName(name: Name) {
    return API.Put<object, Name>(URL + "/" + name.id, name)
}

/**
 * Name Config
 */
export function GetAllNameConfigUser() {
    let url = URL + "/config"
    return API.Get<NameConfig[]>(url)
}
export function GetAllNameConfigWS(worksheetId: number) {
    let url = URL + "/config/" + worksheetId
    return API.Get<NameConfig[]>(url)
}

export function GetNameByNameConfig(nameConfigId: number) {
    let url = URL + "/config/" + nameConfigId + "/name"
    return API.Get<Name>(url)
}

export function DeleteNameConfig(nameConfig: NameConfig) {
    let url = URL + "/config/" + nameConfig.id
    return API.Delete<NameConfig>(url)
}
export function CreateWSNameConfig(nameConfig: NameConfig, worksheetId: Worksheet) {
    let url = URL + "/config"
    nameConfig.worksheetId = worksheetId
    return API.Post<NameConfig, NameConfig>(url, nameConfig)
}
export function CreateUserNameConfig(nameConfig: NameConfig) {
    let url = URL + "/config"
    return API.Post<NameConfig, NameConfig>(url, nameConfig)
}

