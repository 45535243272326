import React from "react";
import {MainContext} from "../../_base/MainContext";
import {EntityData} from "../../_model/Entity";
import Image, {ImageAlignment} from "../../_model/Image";
import {WDImageData} from "../Elements/Image/WDImage";
import Const from "../../Framework/Const";
import {WDTableCellData, WDTableColumnData, WDTableData, WDTableRowData} from "../Elements/Table/WDTableData";
import {WDTextboxData} from "../Elements/Textbox/WDTextbox";
import {Coords} from "../../Framework/Coords";
import {Util} from "../../Framework/Util";
import {WorksheetItemTypeEnum} from "../../_model/WorksheetItemType";
import translations from "../../Framework/translations.json";
import {VerticalAlignment} from "../../Framework/Enums";
import {WDFrameData} from "./SidebarBorderManager/WDFrameData";

export enum SidebarContentDragElementType {
    TABLE,
    TEXT,
    IMAGE,
    FRAME
}
export class SidebarContentBaseListFilter {
    search: string = ""
    filterChanged: boolean = false
    activeElement?: EntityData

    static initFilter() {
        return new SidebarContentBaseListFilter()
    }
}

export interface SidebarContentBaseProps {
}
export interface SidebarContentBaseState {
    filter: SidebarContentBaseListFilter
    filterHistory: SidebarContentBaseListFilter[]
    filterHistoryIndex: number

    onMouseOverElementId?: string
    advancedSettingsVisible?: boolean

    page: number
    lastItem: boolean
}

export class SidebarContentBase<T extends SidebarContentBaseProps = SidebarContentBaseProps,
    U extends SidebarContentBaseState = SidebarContentBaseState> extends React.Component<T, U> {

    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    PAGE_SIZE: number = 100

    // Prevents loading multiple pages
    loading: boolean = false

    applyFilter = (newFilter: SidebarContentBaseListFilter) => {
        this.setState({
            filter: newFilter
        })
    }
    deleteFilter = () => {
        this.setState({
            filter: new SidebarContentBaseListFilter()
        }, () => this.pushHistory(this.state.filter))
    }

    pushHistory = (filter: SidebarContentBaseListFilter) => {
        let elements = this.state.filterHistory
        let index = this.state.filterHistoryIndex + 1

        elements.splice(index, (elements.length - index), {...filter})

        this.setState({
            filterHistory: elements,
            filterHistoryIndex: elements.length - 1
        })
    }

    fetchData = (addHistory: boolean, append: boolean = false) => {
    }

    onSearch = (value: string) => {
        let filter = this.state.filter
        filter.search = value
        filter.activeElement = undefined
        this.loading = true
        this.setState({ filter: filter, lastItem: false }, () => this.fetchData(true))
    }
    onClickBackwards = () => {
        if (this.state.filterHistoryIndex > 0) {
            // console.log({...this.state.filterHistory[this.state.filterHistoryIndex - 1]})

            this.setState({
                filter: {...this.state.filterHistory[this.state.filterHistoryIndex - 1]},
                filterHistoryIndex: this.state.filterHistoryIndex - 1,
                lastItem: false
            }, () => this.fetchData(false))
        }
    }
    onClickForwards = () => {
        if (this.state.filterHistoryIndex <= this.state.filterHistory.length - 2) {

            this.setState({
                filter: this.state.filterHistory[this.state.filterHistoryIndex + 1],
                filterHistoryIndex: this.state.filterHistoryIndex + 1,
                lastItem: false
            }, () => this.fetchData(false))
        }
    }
    onClickReport = (id: number, object: string) => {
        window.location.href = 'mailto:' + Const.SUPPORT_EMAIL + '?subject=' + this.context.translate(translations.text_fragment.report) + '%20' + object + ':%20' + id + '&body=' + object +
            ' ID: ' + id + '%0D%0A%0D%0A' + this.context.translate(translations.notification.report_object).replace("%1%", object) + ':%0D%0A'
    }

    onToggleSettings = () => {
        this.setState({advancedSettingsVisible: !this.state.advancedSettingsVisible})
    }

    onDragStart = (event: React.DragEvent,
                   dragElementType: SidebarContentDragElementType,
                   dragElementId: string,
                   image?: Image,
                   spellCheck: boolean = true) => {

        // Set what is visible while dragging
        if (event.dataTransfer && event.target) {
            let canvas = document.getElementById("dragImageContainer")
            let dragElement = document.getElementById(dragElementId)
            let dragImage = dragElement!.cloneNode(true) as HTMLImageElement

            if (canvas === null || canvas === undefined) {
                throw new Error("Cannot find drag image canvas.")
            }

            // width and height of object
            let width = 210, height = 210

            if((dragElementType === SidebarContentDragElementType.IMAGE || dragElementType === SidebarContentDragElementType.FRAME) && image) {
                const alignment = image.alignment || ImageAlignment.quadratic
                let renderCoords = Image.getSizeByAlignment(alignment)
                if (image.width && image.height) {
                    renderCoords = Util.calculateRenderSize(new Coords(image.width, image.height), alignment)
                }
                width = renderCoords.x
                height = renderCoords.y

            } else if(dragElementType === SidebarContentDragElementType.TABLE && dragElement) {
                let element = event.target as HTMLTableElement
                width = element.clientWidth
                height = element.clientHeight

            } else if(dragElementType === SidebarContentDragElementType.TEXT && dragElement) {
                width = dragElement.clientWidth
                height = dragElement.clientHeight
            }

            // Clear content of drag image container
            canvas.innerHTML = ""

            // Setting drag img size
            dragImage.style.width = width + "px"
            dragImage.style.height = height + "px"
            canvas.append(dragImage)

            // Drawing to canvas with a smaller size
            canvas.style.width = width + "px"
            canvas.style.height = height + "px"

            if(dragElementType === SidebarContentDragElementType.IMAGE && image) {
                let data = new WDImageData(image.id!, width, height, image.status)
                event.dataTransfer.setData(Const.DATA_TRANSFER_WS_ITEM_ID, WorksheetItemTypeEnum.IMAGE.toString())
                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))

            } else if(dragElementType === SidebarContentDragElementType.TABLE && dragElement) {
                let element = event.target as HTMLTableElement
                let rowTable = element.querySelectorAll("tr")
                let columnTable: NodeListOf<HTMLTableCellElement> | undefined = undefined

                if(rowTable.length > 0) {
                    columnTable = rowTable[0].querySelectorAll("td")

                    // if length is still 0 table maybe has header element
                    if(columnTable.length === 0) {
                        columnTable = rowTable[0].querySelectorAll("th")
                    }
                }

                let rows: WDTableRowData[] = []
                let columns: WDTableColumnData[] = []
                let cells: WDTableCellData[][] = []

                let rowAmount = 0

                if(rowTable) {
                    rowTable.forEach(row => {
                        rows.push(new WDTableRowData(34))
                        cells[rowAmount] = []

                        let rowTds = row.querySelectorAll("td")
                        let rowTh = row.querySelectorAll("th")

                        cells.concat(this.fillTableCells(rowTh,cells,rowAmount, spellCheck))
                        cells.concat(this.fillTableCells(rowTds,cells,rowAmount, spellCheck))

                        rowAmount++
                    })
                }
                if(columnTable) {
                    columnTable.forEach(column => {
                        columns.push(new WDTableColumnData(column.clientWidth))
                    })
                }

                let syllable = dragElement.outerHTML.includes("syllabification")
                let data = new WDTableData(rows, columns, cells, syllable)
                event.dataTransfer.setData(Const.DATA_TRANSFER_WS_ITEM_ID, WorksheetItemTypeEnum.TABLE.toString())
                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))

            } else if(dragElementType === SidebarContentDragElementType.TEXT && dragElement) {
                let syllable = dragElement.outerHTML.includes("syllabification")
                let data = new WDTextboxData(dragElement.outerHTML, true, syllable, VerticalAlignment.top)
                event.dataTransfer.setData(Const.DATA_TRANSFER_WS_ITEM_ID, WorksheetItemTypeEnum.TEXTBOX.toString())
                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))

            } else if(dragElementType === SidebarContentDragElementType.FRAME && image) {
                let data = new WDFrameData(image.id!)
                event.dataTransfer.setData(Const.DATA_TRANSFER_WS_ITEM_ID, WorksheetItemTypeEnum.FRAME.toString())
                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
            }

            // Setting drag image with drawn canvas image
            event.dataTransfer.setDragImage(canvas, 0, 0)
        }
    }
    private fillTableCells = ( htmlCells: NodeListOf<HTMLTableDataCellElement>, cells: WDTableCellData[][], rowAmount: number, spellCheck: boolean) : WDTableCellData[][] => {
        let amount = 0
        htmlCells.forEach(td => {
            cells[rowAmount][amount] = new WDTableCellData()

            cells[rowAmount][amount].data = new WDTextboxData(td.innerHTML, false,
                td.outerHTML.includes("syllabification"), VerticalAlignment.top)
            cells[rowAmount][amount].spellCheck = spellCheck

            amount++
        })
        return cells
    }
    onScrollContent = (e: React.UIEvent<HTMLDivElement>) => {
        let element = e.target as HTMLDivElement

        if (element.scrollTop + 10 >= (element.scrollHeight - element.offsetHeight) && !this.loading) {
            this.context.log.info("Loading page: " + (this.state.page + 1))
            this.context.log.flush()

            this.loading = true
            this.fetchData(false, true)
        }
    }
}

