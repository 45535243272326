import {EntityData} from "./Entity";
import User from "./User";
import {Status} from "../Framework/Enums";
import Originator from "./Originator";
import ImageCollection from "./ImageCollection";
import Tag from "./Tag";
import ImageFamily from "./ImageFamily";
import {Coords} from "../Framework/Coords";
import {MenuItem} from "./MenuItem";
import ImageAlternative from "./ImageAlternative";
import ListSortSetting from "../Components/List/ListSortSetting";

export enum ImageProperty {
    vector = "vector",
    illustration = "illustration",
    photo = "photo"
}

export enum ImageAlignment {
    quadratic = "quadratic",
    landscape = "landscape",
    portrait = "portrait",
    panorama = "panorama"
}

export enum ImageColor {
    color = "color",
    black_white = "black_white",
    contouring = "contouring",
    coloring_picture = "coloring_picture"
}

export enum ImageBackground {
    withBackground = "with_background",
    withoutBackground = "without_background"
}

export enum ImageLicense {
    free = "free",
    license = "license"
}
export enum ImageAI {
    aiGenerated = "aiGenerated",
    manualGenerated = "manualGenerated"
}

export class Image extends EntityData {
    url?: string
    filename?: string
    image?: string
    thumbnailFilename?: string
    thumbnail?: string
    color?: ImageColor
    alignment?: ImageAlignment
    width?: number
    height?: number
    thumbAlignment?: ImageAlignment
    thumbWidth?: number
    thumbHeight?: number
    property?: ImageProperty
    originator?: Originator
    collection?: ImageCollection
    family?: ImageFamily
    alternative?: ImageAlternative
    tags?: Tag[]
    toolboxItems?: MenuItem[]
    background: boolean
    internal: boolean
    licenseRequired: boolean
    colorable: boolean
    pageFrame: boolean
    aiGenerated: boolean
    counterpartImage?: Image
    rating?: number

    constructor(name: string, status: Status, createdOn?: Date, createdBy?: User, modifiedOn?: Date, modifiedBy?: User,
                ownerId?: User, id?: number) {

        super(name, status, createdOn, createdBy, modifiedOn, modifiedBy, ownerId, id);

        this.background = false
        this.internal = false
        this.licenseRequired = false
        this.colorable = false
        this.pageFrame = false
        this.aiGenerated = false
    }

    static getSizeByAlignment (alignment: ImageAlignment): Coords {
        switch (alignment) {
            case ImageAlignment.quadratic: return new Coords(210, 210)
            case ImageAlignment.landscape: return new Coords(297, 210)
            case ImageAlignment.portrait: return new Coords(210, 297)
            case ImageAlignment.panorama: return new Coords(420, 210)
        }
    }

    static getThumbnailUrl (image: Image) {
        return image.thumbnail ? image.thumbnail : image.url
    }
    static getThumbnailWidth (image: Image) {
        return image.thumbWidth ? image.thumbWidth : image.width
    }
}

export class ImageState {
    status: Status
    ownerId?: User

    constructor(status: Status, ownerId?: User) {
        this.status = status
        if (ownerId) {
            this.ownerId = ownerId
        }
    }
}

export class ImageFilter {
    search?: string
    color?: ImageColor[]
    alignment?: ImageAlignment[]
    property?: ImageProperty[]
    originatorId?: number
    collectionId?: number
    background?: boolean
    licenseRequired?: boolean
    exactMatch?: boolean
    pageFrame?: boolean
    hasCounterpartImage?: boolean
    aiGenerated?: boolean
    sort?: ListSortSetting
    adminFilter?: ImageFilterAdmin
}
export class ImageFilterAdmin {
    createdById?: number
    ownerId?: number
    status?: Status[]

    constructor() {
        this.status = [Status.published, Status.approval, Status.draft]
    }
}
export class ImageToolboxFilter {
    toolboxItemId: number
    search?: string

    constructor(toolboxItemId: number, search?: string) {
        this.toolboxItemId = toolboxItemId
        this.search = search
    }
}

export default Image
