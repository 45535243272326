import {ImageCategory} from "../../../Framework/CategoryImage";
import {Difficulty, ExerciseCalculationType, ExerciseType} from "../../../Framework/Enums";
import {WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import {WDElementBaseData} from "../WDElementBase";
import {WDTextboxData} from "../Textbox/WDTextbox";
import {WDMathLineatureData} from "../Lineature/MathLineature/WDMathLineatureData";
import {WDTableData} from "../Table/WDTableData";

export enum WDTextExerciseSection {
    INTRODUCTION,
    IMAGES,
    CALCULATION,
    TABLE,
    ANSWER
}

export class WDTextExerciseSectionVariables {
    id: string
    value: string

    constructor(id: string, value: string) {
        this.id = id
        this.value = value
    }
}

export class WDTextExerciseSectionData {
    height: number
    worksheetElementType: WorksheetItemTypeEnum
    data: string
    visible: boolean
    toggleEnabled: boolean
    variable: WDTextExerciseSectionVariables[]

    constructor(height: number, data: string, worksheetItemType: WorksheetItemTypeEnum) {
        this.height = height
        this.data = data
        this.visible = true
        this.toggleEnabled = true
        this.worksheetElementType = worksheetItemType
        this.variable = []
    }
}
export class WDTextExerciseData extends WDElementBaseData {
    exerciseId?: number
    worksheetItemId?: number
    calculationType: ExerciseCalculationType
    numberRange: string
    exerciseType: ExerciseType
    toolboxItemId?: number
    topicId?: number
    difficulty?: Difficulty
    showSolution: boolean

    introduction: WDTextExerciseSectionData
    images: WDTextExerciseSectionData
    calculation: WDTextExerciseSectionData
    table: WDTextExerciseSectionData
    answer: WDTextExerciseSectionData

    constructor(numberRange: string, calculationType: ExerciseCalculationType, exerciseType: ExerciseType, toolboxItemId: number, difficulty?: Difficulty, exerciseId?: number) {
        super()
        this.numberRange = numberRange
        this.calculationType = calculationType

        this.exerciseId = exerciseId
        if (toolboxItemId < 13) {
            this.toolboxItemId = toolboxItemId
        }
        else {
            this.topicId = toolboxItemId
        }
        this.exerciseType = exerciseType
        this.difficulty = difficulty
        this.showSolution = true

        const textData = WDTextboxData.defaultContent()
        const mathLinData = new WDMathLineatureData(5, 5, 2, 6)
        const tableData = WDTableData.createEmptyTableData(2, 2, 100, 100)

        this.introduction = new WDTextExerciseSectionData(100, JSON.stringify(textData), WorksheetItemTypeEnum.TEXTBOX)
        this.images = new WDTextExerciseSectionData(100, "", WorksheetItemTypeEnum.IMAGE)
        this.calculation = new WDTextExerciseSectionData(100, JSON.stringify(mathLinData), WorksheetItemTypeEnum.MATH_LINEATURE)
        this.table = new WDTextExerciseSectionData(100, JSON.stringify(tableData), WorksheetItemTypeEnum.TABLE)
        this.answer = new WDTextExerciseSectionData(100, JSON.stringify(textData), WorksheetItemTypeEnum.TEXTBOX)
    }
    static getImageCategory(level: string) {
        switch (level) {
            case "10": return ImageCategory.TEXT_EXERCISE_ZR10
            case "20": return ImageCategory.TEXT_EXERCISE_ZR10
            case "30": return ImageCategory.TEXT_EXERCISE_ZR10
            case "100": return ImageCategory.TEXT_EXERCISE_ZR100
            default: return ImageCategory.TEXT_EXERCISE_ZR1000
        }
    }
    static serialize(data: WDTextExerciseData) {
        return JSON.stringify(data, (key, value) => {
            if (key === "variable") return undefined;
            else return value;
        })
    }
}
