import React from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import {NumberInput} from "./NumberInput";
import {WDToolbarButton} from "../../Designer/Toolbar/Button/WDToolbarButton";
import {WDToolbarButtonColorPicker} from "../../Designer/Toolbar/Button/General/WDToolbarButtonColorPicker";
import {TooltipPosition, TooltipText} from "../Tooltips";
import {DropDown, DropDownItem, DropDownType} from "./DropDown";
import {MainContext} from "../../_base/MainContext";

export class BorderStyle {
    visible: boolean
    color: string
    style: string
    weight: number

    constructor(visible: boolean, color: string, style: string, weight: number) {
        this.visible = visible
        this.color = color
        this.style = style
        this.weight = weight
    }

    static getNonBorderStyle(): BorderStyle {
        return new BorderStyle(false, "transparent", "none", 0)
    }
    static getDefaultBorderStyle(): BorderStyle {
        return new BorderStyle(false, "#d8d8d8", "dashed", 1)
    }
}

interface IProps {
    id: number
    clickableBorderStyle: boolean
    clickableBorderColor: boolean
    clickableBorderWeight: boolean

    width?: number
    padding?: number

    tooltipDisabled?: TooltipText

    borderOptions: BorderStyle
    onChangeBorder: (border: BorderStyle) => void
}

interface IState {
    colorPickerOpen: boolean
}

export class BorderStylingOptions extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            colorPickerOpen: false
        }
    }

    onChangeColor = (color: string) => {
        let borderOptions = this.props.borderOptions
        borderOptions.color = color
        borderOptions.visible = true
        if (color !== "transparent" && borderOptions.style === "none") {
            borderOptions.style = "solid"
            borderOptions.weight = 1
        }

        this.setState({colorPickerOpen: false}, () => {this.props.onChangeBorder(borderOptions)})
    }
    onChangeLineWeight = (value: number) => {
        let borderOptions = this.props.borderOptions
        borderOptions.weight = value

        if(value !== undefined && value !== 0) {
            if (borderOptions.style === "none") {
                borderOptions.style = "solid"
            }
            if (borderOptions.color === "transparent") {
                borderOptions.color = "#000000"
            }
            borderOptions.visible = true
        }

        this.props.onChangeBorder(borderOptions)
    }
    onChangeLineStyle = (style: string) => {
        let borderOptions = this.props.borderOptions
        borderOptions.style = style
        if (borderOptions.color === "transparent") {
            borderOptions.color = "#000000"
        }
        if (borderOptions.weight === 0) {
            borderOptions.weight = 1
        }
        borderOptions.visible = true

        this.props.onChangeBorder(borderOptions)
    }

    onToggleColorPicker = () => {
        this.setState({colorPickerOpen: !this.state.colorPickerOpen})
    }

    render() {
        let borderColor = this.props.borderOptions.color
        if (borderColor === "transparent") {
            borderColor = "#000000"
        }

        let width = this.props.width ? this.props.width : 255

        let lineStyles: DropDownItem[] = [
            { value: "none", name: <div className={"drop-down-list-item"}>
                    <div style={{marginLeft: 4}}>{this.context.translate(translations.toolbar.border.line_style_none)}</div>
                </div>
            },
            { value: "solid", name: <div className={"drop-down-list-item"}>
                    <div style={{height: 8, width: "100%", marginLeft: 4, borderColor: borderColor, borderBottomStyle: "solid", borderWidth: "medium"}} />
                </div>
            },
            { value: "double", name: <div className={"drop-down-list-item"}>
                    <div style={{height: 8, width: "100%", marginLeft: 4, borderColor: borderColor, borderBottomStyle: "double", borderWidth: "medium"}} />
                </div>
            },
            { value: "dotted", name: <div className={"drop-down-list-item"}>
                    <div style={{height: 8, width: "100%", marginLeft: 4, borderColor: borderColor, borderBottomStyle: "dotted", borderWidth: "medium"}} />
                </div>
            },
            { value: "dashed", name: <div className={"drop-down-list-item"}>
                    <div style={{height: 8, width: "100%", marginLeft: 4, borderColor: borderColor, borderBottomStyle: "dashed", borderWidth: "medium"}} />
                </div> },
        ]


        return <div className={"ws-designer-border-styling-options"} style={{width: width}}>

            {/* line weight 157*/}
            <div className={"ws-designer-border-style"} style={{paddingTop: 0, padding: this.props.padding}}>
                <label style={{paddingLeft: 2, paddingRight: 5}}>
                    {this.context.translate(translations.toolbar.border.line_weight)}
                </label>

                <NumberInput clickable={this.props.clickableBorderWeight}
                             width={width - 90}
                             maxlength={2}
                             required={false}
                             style={{marginLeft: "4px", marginRight: 0}}
                             minValue={1}
                             maxValue={99}
                             unit={"pt"}
                             unitWidth={10}
                             onChangeValue={this.onChangeLineWeight}
                             value={this.props.borderOptions.weight} />
            </div>

            <div className={"ws-designer-border-style"} style={{padding: 0}}>
            <div className={"ws-designer-border-style"}>
                <WDToolbarButton id={this.props.id}
                                 icon={ImagePath.getButtonUrl() + "edit.svg"}
                                 buttonWidth={32}
                                 buttonHeight={32}
                                 cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                                 color={borderColor}
                                 onToggleButton={this.onToggleColorPicker}
                                 tooltip={new TooltipText(
                                     this.context.translate(translations.toolbar.border.border_color),
                                     this.context.translate(translations.tooltip.border_color)
                                 )}
                                 tooltipPosition={TooltipPosition.belowLeft}
                                 tooltipDisabled={this.props.tooltipDisabled}
                                 arrow={false}
                                 enabled={this.props.clickableBorderColor}
                                 pressed={false} />

                <WDToolbarButtonColorPicker
                    id={this.props.id}
                    tooltip={new TooltipText(
                        this.context.translate(translations.toolbar.border.border_color),
                        this.context.translate(translations.tooltip.border_color)
                    )}
                    tooltipDisabled={this.props.tooltipDisabled}
                    open={this.state.colorPickerOpen}
                    clickable={this.props.clickableBorderColor}
                    transparency={false}
                    onToggleButton={this.onToggleColorPicker}
                    onChangeColor={this.onChangeColor}
                />
            </div>

            {/* border color */}
            <div className={"ws-designer-border-style"} style={{padding: 0}}>
                {/* line style 155*/}
                <label style={{paddingRight: 10}}>{this.context.translate(translations.toolbar.border.line_style)}</label>

                <DropDown
                    id={"border-type"}
                    type={DropDownType.JSX}
                    required={false}
                    value={this.props.borderOptions.style}
                    readonly={!this.props.clickableBorderStyle}
                    autocomplete={false}
                    width={width - 90}
                    items={lineStyles}
                    onChangeValue={this.onChangeLineStyle} />

            </div>
            </div>
        </div>
    }
}
