import {MainContext} from "../../_base/MainContext";
import React from "react";
import {ImagePath} from "../../Framework/CategoryImage";

enum RenderOption {
    outline = "outline",
    half = "half",
    full = "full"
}

interface IProps {
    rating: number
    legend?: string
    description?: string

    iconSize?: number
    fontSize?: number

    onChange?: (rating: number) => void
}
interface IState {
    current?: number
}

export default class Rating extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            current: undefined
        }
    }

    onMouseOver = (index: number) => {
        this.setState({current: index})
    }
    onMouseOut = (e: React.MouseEvent) => {
        this.setState({current: undefined})
    }

    renderValue = (option: RenderOption, index: number) => {
        if (this.state.current) {
            option = index <= this.state.current ? RenderOption.full : RenderOption.outline
        }

        let iconUrl = process.env.PUBLIC_URL + ImagePath.getButtonUrl()
        switch (option) {
            case RenderOption.outline:
                iconUrl += "star_outline.svg"
                break
            case RenderOption.half:
                iconUrl += "star_half.svg"
                break
            case RenderOption.full:
                iconUrl += "star.svg"
                break
        }

        let cursorClass = this.props.onChange ? "cursor-pointer" : ""

        return <img className={"rating-image " + cursorClass}
                    key={index}
                    style={{width: this.props.iconSize || 16, height: this.props.iconSize || 16}}
                    src={iconUrl}
                    alt={"Star"}
                    draggable={"false"}
                    onContextMenu={(e) => e.preventDefault() }
                    onMouseOver={this.props.onChange ? () => this.onMouseOver(index) : undefined}
                    onMouseOut={this.props.onChange ? this.onMouseOut : undefined}
                    onClick={this.props.onChange ? () => this.props.onChange!(index) : undefined}
        />
    }

    render() {
        let images: JSX.Element[] = []

        let rating = this.props.rating
        let boundary = this.props.rating - Math.floor(this.props.rating)
        if (boundary < 0.25) {
            rating = Math.floor(this.props.rating)
        }
        else { //if (boundary > 0.74) {
            rating = Math.ceil(this.props.rating)
        }

        for (let i = 0; i < rating; i++) {
            let diff = this.props.rating - i
            images.push(this.renderValue(diff > 0.25 && diff < 0.74 ? RenderOption.half : RenderOption.full, i + 1))
        }
        for (let i = rating; i < 5; i++) {
            images.push(this.renderValue(RenderOption.outline, i + 1))
        }

        return <div className={"rating"} style={{fontSize: (this.props.fontSize || 16)}}>
            <div className={"rating-data"}>
                {images.map(i => i)}
                {this.props.legend &&
                    <div className={"rating-legend"}>{this.props.legend}</div>
                }
            </div>
            {this.props.description &&
                <div className={"rating-description"} style={{fontSize: (this.props.fontSize || 16) - 1}}>
                    {this.props.description}
                </div>
            }
        </div>
    }
}

