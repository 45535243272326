import User from "./User";
import {AdminObjectType} from "../Framework/Enums";

export class Note {
    id?: number
    title?: string
    description?: string
    objectId?: number
    objectType?: AdminObjectType
    automatic: boolean
    createdBy?: User
    createdOn?: Date
    modifiedOn?: Date

    constructor(title: string, description: string, objectId: number, objectType: AdminObjectType, automatic: boolean) {
        this.title = title
        this.description = description
        this.objectId = objectId
        this.objectType = objectType
        this.automatic = automatic
    }
}
export default Note
