import Converter from "./Converter";

export class Coords {
    x: number
    y: number

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
    }

    toMmGrid = () => {
        this.x = Converter.toMmGrid(this.x)
        this.y = Converter.toMmGrid(this.y)
    }

    toString = () => {
        return "(" + this.x + " / " + this.y + ")"
    }
}
