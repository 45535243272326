import React from 'react';
import {ImagePath} from "../../Framework/CategoryImage";
import {MainContext} from "../../_base/MainContext";
import {WDElementMode} from "../../Designer/Elements/Enum/WDElementMode";
import translations from "../../Framework/translations.json";
import {Util} from "../../Framework/Util";
import {Draw, DrawBorder, DrawBorderStyle} from "../../Framework/Draw";
import {Coords} from "../../Framework/Coords";
import Const from "../../Framework/Const";
import Converter from "../../Framework/Converter";
import {TutorialArrow, TutorialContainer} from "../../Designer/Tutorial/TutorialContainer";

interface IProps {
    setElementMode?: (mode: WDElementMode) => void

    positionRight?: string

    zoom: number
    onChangeZoom: (zoom: number) => void
}

interface IState {
    showButtons: boolean
    showDetails: boolean
    moveSlider: boolean

    sliderCoords: Coords
}

export default class PlusButton extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    center = new Coords(-13, 40)
    buttonRadius = 5

    zoomPlusPos = new Coords(6, 9)
    zoomMinusPos = new Coords(6, 70)

    constructor(props: IProps) {
        super(props)

        this.state = {
            showButtons: false,
            showDetails: false,
            moveSlider: false,
            sliderCoords: this.getSliderCoords(this.props.zoom)
        }
    }

    componentDidMount() {
        this.drawZoom()
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.zoom !== this.props.zoom) {
            this.drawZoom()
            this.setState({sliderCoords: this.getSliderCoords(this.props.zoom)})
        }
        if (prevState.sliderCoords.x !== this.state.sliderCoords.x || prevState.sliderCoords.y !== this.state.sliderCoords.y) {
            this.drawZoom()
        }
        if (prevState.showDetails !== this.state.showDetails) {
            this.drawZoom()
        }
    }
    componentWillUnmount() {
        window.removeEventListener('mousemove', this.removeEventListener)
    }

    mapAngleToZoom = (angle: number) => {
        if (angle <= -0.7) { return 2.75 }
        if (angle <= -0.6) { return 2.50 }
        if (angle <= -0.5) { return 2.25 }
        if (angle <= -0.4) { return 2.00 }
        if (angle <= -0.3) { return 1.75 }
        if (angle <= -0.2) { return 1.50 }
        if (angle <= -0.1) { return 1.25 }
        if (angle <= 0) { return 1 }
        if (angle <= 0.1) { return 0.9 }
        if (angle <= 0.2) { return 0.85 }
        if (angle <= 0.3) { return 0.8 }
        if (angle <= 0.4) { return 0.75 }
        if (angle <= 0.5) { return 0.7 }
        if (angle <= 0.6) { return 0.65 }
        if (angle <= 0.7) { return 0.6 }

        return 0.55
    }
    mapZoomToAngle = (zoom: number) => {
        if (zoom >= 2.75) { return -0.7 }
        if (zoom >= 2.5) { return -0.6 }
        if (zoom >= 2.25) { return -0.5 }
        if (zoom >= 2.00) { return -0.4 }
        if (zoom >= 1.75) { return -0.3 }
        if (zoom >= 1.5) { return -0.2 }
        if (zoom >= 1.25) { return -0.1 }
        if (zoom >= 1) { return 0 }
        if (zoom >= 0.9) { return 0.1 }
        if (zoom >= 0.85) { return 0.2 }
        if (zoom >= 0.8) { return 0.3 }
        if (zoom >= 0.75) { return 0.4 }
        if (zoom >= 0.7) { return 0.5 }
        if (zoom >= 0.65) { return 0.6 }
        if (zoom >= 0.6) { return 0.7 }

        return 0.8
    }
    calculateAngleByMouseCoords = (pos: Coords) => {
        let angle = Math.atan((pos.y - this.center.y) / (pos.x - this.center.x))

        angle = Math.min(0.8, angle)
        angle = Math.max(-0.8, angle)

        return angle
    }

    getDraw = () => {
        const canvas = document.getElementById("plus-button-zoom-canvas") as HTMLCanvasElement;
        if (canvas === null) {
            return
        }
        return new Draw(canvas)
    }
    getMouseCoords = (pos: Coords) => {
        let rect = document.getElementById("plus-button-zoom-canvas")?.getBoundingClientRect()
        return new Coords(Converter.toMmGrid(pos.x - (rect?.left || 0)), Converter.toMmGrid(pos.y - (rect?.top || 0)))
    }
    getSliderCoords = (zoom: number) => {
        let angle = this.mapZoomToAngle(zoom)
        return new Coords(Math.cos(angle) * 35 - 13, Math.sin(angle) * 35 + 39)
    }

    onShowMain = (e: React.MouseEvent) => {
        window.addEventListener('mousemove', this.removeEventListener);

        this.context.setTutorialStep("plus-button", 1)

        this.setState({showButtons: true})
    }
    onMouseOverMain = (e: React.MouseEvent) => {
        let delay = setTimeout(() => this.onShowMain(e),300);
        e.target.addEventListener('mouseout', () => {
            clearTimeout(delay)
        })
    }

    onMouseOverZoom = (e: React.MouseEvent) => {
        this.setState({showDetails: true})
    }
    onMouseOutZoom = (e: React.MouseEvent) => {
        this.setState({showDetails: false})
    }
    onDoubleClickZoom = (e: React.MouseEvent) => {
        let pos = this.getMouseCoords(new Coords(e.clientX, e.clientY))
        if (pos.x <= this.state.sliderCoords.x + 5 && pos.x >= this.state.sliderCoords.x - 5 &&
            pos.y <= this.state.sliderCoords.y + 5 && pos.y >= this.state.sliderCoords.y - 5) {

            if (1 !== this.props.zoom) {
                this.props.onChangeZoom(1)
                this.setState({sliderCoords: this.getSliderCoords(1)})
            }
        }
    }
    onMouseDownZoom = (e: React.MouseEvent) => {
        let pos = this.getMouseCoords(new Coords(e.clientX, e.clientY))
        if (pos.x <= this.state.sliderCoords.x + 5 && pos.x >= this.state.sliderCoords.x - 5 &&
            pos.y <= this.state.sliderCoords.y + 5 && pos.y >= this.state.sliderCoords.y - 5) {

            document.addEventListener('mousemove', this.onMouseMoveZoom)
            document.addEventListener('mouseup', this.onMouseUpZoom)

            this.setState({moveSlider: true})
        }
        // On mouse click plus zoom button
        else if (pos.x <= (this.zoomPlusPos.x + this.buttonRadius / 2) && pos.x >= (this.zoomPlusPos.x - this.buttonRadius / 2) &&
            pos.y <= (this.zoomPlusPos.y + this.buttonRadius / 2) && pos.y >= (this.zoomPlusPos.y - this.buttonRadius / 2))
        {
            this.onClickZoomButton(true)
        }
        // On mouse click minus zoom button
        else if (pos.x <= (this.zoomMinusPos.x + this.buttonRadius / 2) && pos.x >= (this.zoomMinusPos.x - this.buttonRadius / 2) &&
            pos.y <= (this.zoomMinusPos.y + this.buttonRadius / 2) && pos.y >= (this.zoomMinusPos.y - this.buttonRadius / 2))
        {
            this.onClickZoomButton(false)
        }
    }
    onMouseUpZoom = (e: MouseEvent) => {
        if (this.state.moveSlider) {
            document.removeEventListener('mousemove', this.onMouseMoveZoom)
            document.removeEventListener('mouseup', this.onMouseUpZoom)

            this.props.onChangeZoom(this.mapAngleToZoom(this.calculateAngleByMouseCoords(this.state.sliderCoords)))

            this.setState({moveSlider: false})
        }
    }
    onMouseMoveZoom = (e: MouseEvent) => {
        if (this.state.moveSlider) {
            let pos = this.getMouseCoords(new Coords(e.clientX, e.clientY))
            let angle = this.calculateAngleByMouseCoords(pos)
            let zoom = this.mapAngleToZoom(angle)

            if (zoom !== this.props.zoom) {
                this.props.onChangeZoom(zoom)
                this.setState({sliderCoords: this.getSliderCoords(zoom)})
            }
        }
    }
    onClickZoomButton = (add: boolean) => {
        let coords = this.getSliderCoords(this.props.zoom)
        let angle = this.calculateAngleByMouseCoords(coords)

        angle = add ? angle - 0.1 : angle + 0.1

        let zoom = this.mapAngleToZoom(angle)

        if (zoom !== this.props.zoom) {
            this.props.onChangeZoom(zoom)
            this.setState({sliderCoords: this.getSliderCoords(zoom)})
        }
    }

    removeEventListener = (e: MouseEvent) => {
        let element = e.target as HTMLElement
        let child = Util.isChildOfClass(element, 'plus-button-container')

        if(!child && !element.className.includes('plus-button-container')) {
            window.removeEventListener('mousemove', this.removeEventListener)
            this.setState({showButtons: false})
        }
    }

    drawZoom = () => {
        let draw = this.getDraw()

        draw?.Clear()
        draw?.setBorder(new DrawBorder(Const.COLOR_RED, DrawBorderStyle.miter, 1))
        draw?.Arc(this.center, 35, Math.PI * 1.72, Math.PI * 0.28, false)

        draw?.setFillColor(Const.COLOR_RED)
        draw?.Ellipse(this.state.sliderCoords, 5, 3)


        if (this.state.showDetails) {
            draw?.setBorder(new DrawBorder(Const.COLOR_RED, DrawBorderStyle.miter, 1))
            draw?.setFillColor(Const.COLOR_APP_BACKGROUND)
            draw?.Circle(this.zoomPlusPos, this.buttonRadius)
            draw?.Circle(this.zoomMinusPos, this.buttonRadius)

            draw?.setFillColor("transparent")
            draw?.Line(new Coords(3, 9), new Coords(9, 9))
            draw?.Line(new Coords(6, 6), new Coords(6, 12))

            draw?.Line(new Coords(3, 70), new Coords(9, 70))

            draw?.Text(Math.round(this.props.zoom * 100) + "%", new Coords(30,42))
        }
    }

    render() {
        let widthOfContainer = this.state.showButtons ? "180px" : ""
        let heightOfContainer = this.state.showButtons ? "180px" : ""
        let positionBottom = this.state.showButtons ? "30px" : ""

        let tutorial = this.context.getTutorial("plus-button")

        return <div className={"plus-button-container"} style={{width: widthOfContainer, height: heightOfContainer, bottom: positionBottom, right: this.props.positionRight}}>
            <div className={"plus-button-content"}>

                {tutorial &&
                    <TutorialContainer id={"plus-button"}
                                       arrow={TutorialArrow.bottomLeft}
                                       data={tutorial}
                                       translateX={-120} translateY={-250}
                    />
                }

                {this.props.setElementMode !== undefined &&
                    <div className={"plus-button"}>
                        <img alt={this.context.translate(translations.command.add)} className="plus-button-main"
                             src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "plus.svg"}
                             draggable={"false"}
                             onMouseOver={this.onMouseOverMain}
                         onContextMenu={(e) => e.preventDefault() }
                    />

                    {this.state.showButtons &&
                        <div className="plus-button-items">
                            <div className="plus-button-text">
                            <img id={"add_textbox"} alt={this.context.translate(translations.command.add)}
                                 src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "text.svg"}
                                 draggable={"false"}
                                 onClick={() => this.props.setElementMode!(WDElementMode.ADD_TEXTBOX)}
                                 onContextMenu={(e) => e.preventDefault() }
                            />
                            </div>

                            <div className="plus-button-balloon">
                            <img id={"add_balloon"} alt={this.context.translate(translations.command.add)}
                                 src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "speech_bubble.svg"}
                                 draggable={"false"}
                                 onClick={() => this.props.setElementMode!(WDElementMode.ADD_BALLOON)}
                                 onContextMenu={(e) => e.preventDefault() }
                            />
                            </div>

                            <div className="plus-button-table">
                            <img id={"add_table"} alt={this.context.translate(translations.command.add)}
                                 src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "table.svg"}
                                 draggable={"false"}
                                 onClick={() => this.props.setElementMode!(WDElementMode.ADD_TABLE)}
                                 onContextMenu={(e) => e.preventDefault() }
                            />
                            </div>
                        </div>
                    }
                </div>
                }

                <div className={"plus-button-zoom"}>
                    <canvas id={"plus-button-zoom-canvas"} width={60} height={80}
                            onMouseOver={this.onMouseOverZoom}
                            onMouseOut={this.onMouseOutZoom}
                            onMouseDown={this.onMouseDownZoom}
                            onDoubleClick={this.onDoubleClickZoom}
                    />
                </div>
            </div>
        </div>
    }
}
