import React from "react";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {ElementLayout, ResizeInfo, WDElementContainer} from "../WDElementContainer";
import Const from "../../../Framework/Const";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {Draw, DrawBorder, DrawBorderStyle, DrawLineStyle} from "../../../Framework/Draw";
import {Coords} from "../../../Framework/Coords";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {NameValueToEnumKey} from "../../../Framework/Enums";

export enum WDShapeType2d {
    Square = "square",
    Rectangle = "rectangle",
    Circle = "circle",
    Ellipse = "ellipse",
    Triangle_isosceles = "triangle_isosceles",
    Triangle_equilateral = "triangle_equilateral",
    Triangle_right_angle = "triangle_right_angle",
    Triangle_obtuse = "triangle_obtuse",
    Triangle_acute = "triangle_acute",
    Triangle_inequilateral = "triangle_inequilateral",
    Cross = "cross",
    Arrow = "arrow",
    Star = "star",
    Hexagram = "hexagram",
    Semicircle = "semicircle",
    Pentagon = "pentagon",
    Hexagon = "hexagon",
    Octagon = "octagon",
    Kite_Square = "kite_square",
    Rhombus = "rhombus",
    Parallelogram = "parallelogram",
    Trapezoid = "trapezoid",
}

export class WDShapeData2d extends WDElementBaseData {
    type: WDShapeType2d

    constructor(type: WDShapeType2d) {
        super()
        this.type = type
    }

    static serialize = (data: WDShapeData2d): string => {
        return JSON.stringify(data, (key, value) => {
            if (key === "url" || key === "width" || key === "height") return undefined;
            else return value;
        })
    }
}

interface IProps extends WDElementBaseProps {
    data: WDShapeData2d
    isReadOnly: boolean
}

interface IState extends WDElementBaseState {
}

export class WDShape2d extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef()
        }
    }

    componentDidMount() {
        this.renderShape()
    }
    componentDidUpdate() {
        this.renderShape()
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getDefaultWidth = (configData: string) => {
        let dataObject = JSON.parse(configData)

        if (dataObject.type) {
            let typ = WDShapeType2d[NameValueToEnumKey(dataObject.type, WDShapeType2d)]
            switch (typ) {
                case WDShapeType2d.Rectangle:
                    return 250
                case WDShapeType2d.Ellipse:
                    return 250
                case WDShapeType2d.Triangle_isosceles:
                    return 250
                case WDShapeType2d.Triangle_obtuse:
                    return 250
                case WDShapeType2d.Triangle_acute:
                    return 100
                case WDShapeType2d.Triangle_inequilateral:
                    return 250
                case WDShapeType2d.Arrow:
                    return 250
                case WDShapeType2d.Semicircle:
                    return 250
                case WDShapeType2d.Kite_Square:
                    return 250
                case WDShapeType2d.Rhombus:
                    return 100
                case WDShapeType2d.Parallelogram:
                    return 250
                case WDShapeType2d.Trapezoid:
                    return 250
                default:
                    return 150
            }
        }
        return 150
    }
    static getDefaultHeight = () => {
        return 150
    }
    static mapImageKeyToShapeType = (imageKey: string) : WDShapeType2d => {
        let type = imageKey.replace("FORM_", "").toLowerCase()
        return WDShapeType2d[NameValueToEnumKey(type, WDShapeType2d)]
    }

    getMinWidth = () => {
        return 10
    }
    getMinHeight = () => {
        return 10
    }

    isOnlyProportionalResizeAllowed = (type: WDShapeType2d) => {
        switch (type) {
            case WDShapeType2d.Square:
                return true
            case WDShapeType2d.Rectangle:
                return false
            case WDShapeType2d.Circle:
                return true
            case WDShapeType2d.Ellipse:
                return false
            case WDShapeType2d.Triangle_isosceles:
                return false
            case WDShapeType2d.Triangle_equilateral:
                return false
            case WDShapeType2d.Triangle_right_angle:
                return false
            case WDShapeType2d.Triangle_obtuse:
                return false
            case WDShapeType2d.Triangle_acute:
                return false
            case WDShapeType2d.Triangle_inequilateral:
                return false
            case WDShapeType2d.Cross:
                return true
            case WDShapeType2d.Arrow:
                return false
            case WDShapeType2d.Star:
                return true
            case WDShapeType2d.Hexagram:
                return true
            case WDShapeType2d.Semicircle:
                return false
            case WDShapeType2d.Pentagon:
                return true
            case WDShapeType2d.Hexagon:
                return true
            case WDShapeType2d.Octagon:
                return true
            case WDShapeType2d.Kite_Square:
                return false
            case WDShapeType2d.Rhombus:
                return false
            case WDShapeType2d.Parallelogram:
                return false
            case WDShapeType2d.Trapezoid:
                return false
            default:
                return true
        }
    }

    doAction = (action: WDToolbarAction, data?: string) => {
        let newData = {...this.props.data}

        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.CHANGE_GRAPHIC:
                if(data && data["image"]) {
                    let typeString = WDShape2d.mapImageKeyToShapeType(data["image"])
                    newData.type = WDShapeType2d[NameValueToEnumKey(typeString, WDShapeType2d)]

                    // if only proportional is allowed - change width to fit proportion again
                    if(this.isOnlyProportionalResizeAllowed(newData.type) && !this.isOnlyProportionalResizeAllowed(this.props.data.type)) {
                        update.value.width = this.props.element.height
                    }
                }
                break

            default:
                break
        }

        update.value.content = this.serializeElementData(newData)
        return update
    }

    onResizeElement = (proportional: boolean, x: number, y: number) => {
        // Shapes are resized like textboxes - default not proportional
        this.props.onElementResize?.(this.isOnlyProportionalResizeAllowed(this.props.data.type), x, y)
    }

    /**
     * Overridden methods
     */
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDShapeData2d.serialize(data as WDShapeData2d)
    }

    getCanvasId = () => {
        return this.props.id + "-canvas-container"
    }

    mapBorderStyle = () => {
        switch (this.props.element.borderStyle) {
            case "solid": return DrawLineStyle.solid
            case "dashed": return DrawLineStyle.dashed
            case "dotted": return DrawLineStyle.dotted
        }
    }

    renderShape = () => {
        let canvas = document.getElementById(this.getCanvasId()) as HTMLCanvasElement

        if (canvas === null || canvas === undefined) { return }

        // Set canvas width and height
        canvas.width = Math.ceil(this.props.element.width) - 1
        canvas.height = Math.ceil(this.props.element.height) - 1

        const borderWidth = this.props.element.borderWeight

        let draw = new Draw(canvas)
        draw.setBorder(new DrawBorder(this.props.element.borderColor, DrawBorderStyle.round, this.props.element.borderWeight))
        draw.setLineStyle(this.mapBorderStyle() || DrawLineStyle.solid)
        draw.setFillColor(this.props.element.fillColor)
        
        switch (this.props.data.type) {
            case WDShapeType2d.Square:
                let dim = new ElementLayout(
                    borderWidth / 2,
                    borderWidth / 2,
                    canvas.width - borderWidth / 2,
                    canvas.height - borderWidth / 2)

                draw.Rect(dim)
                break

            case WDShapeType2d.Rectangle:
                let rect = new ElementLayout(
                    borderWidth,
                    borderWidth,
                    canvas.width - borderWidth / 2,
                    canvas.height - borderWidth / 2)

                draw.Rect(rect)
                break

            case WDShapeType2d.Circle:
                let r = Math.min(canvas.width, canvas.height) / 2 - borderWidth / 2 - 1
                draw.Circle(new Coords(canvas.width / 2, canvas.height / 2), r)
                break

            case WDShapeType2d.Ellipse:
                let pos = new Coords(canvas.width / 2, canvas.height / 2)
                let r1 = canvas.width / 2 - borderWidth / 2 - 1
                let r2 = canvas.height / 2 - borderWidth / 2 - 1

                draw.Ellipse(pos, r1, r2)
                break

            case WDShapeType2d.Triangle_isosceles:
                let p1 = new Coords(canvas.width / 2, borderWidth / 2)
                let p2 = new Coords(canvas.width - borderWidth / 2, canvas.height - borderWidth / 2)
                let p3 = new Coords(borderWidth / 2, canvas.height - borderWidth / 2)

                draw.Polygon([p1, p2, p3])
                break

            case WDShapeType2d.Triangle_equilateral:
                let TE_p1 = new Coords(canvas.width / 2, borderWidth / 2)
                let TE_p2 = new Coords(canvas.width - borderWidth / 2, canvas.height - borderWidth / 2)
                let TE_p3 = new Coords(borderWidth / 2, canvas.height - borderWidth / 2)

                draw.Polygon([TE_p1, TE_p2, TE_p3])
                break

            case WDShapeType2d.Triangle_right_angle:
                let TR_p1 = new Coords(borderWidth / 2, borderWidth / 2)
                let TR_p2 = new Coords(canvas.width - borderWidth / 2, canvas.height - borderWidth / 2)
                let TR_p3 = new Coords(borderWidth / 2, canvas.height - borderWidth / 2)

                draw.Polygon([TR_p1, TR_p2, TR_p3])
                break

            case WDShapeType2d.Triangle_obtuse:
                let TO_p1 = new Coords(canvas.width - borderWidth / 2, borderWidth / 2)
                let TO_p2 = new Coords(canvas.width * 2/3, canvas.height - borderWidth / 2)
                let TO_p3 = new Coords(borderWidth / 2, canvas.height - borderWidth / 2)

                draw.Polygon([TO_p1, TO_p2, TO_p3])
                break

            case WDShapeType2d.Triangle_acute:
                let TA_p1 = new Coords(canvas.width / 2, borderWidth / 2)
                let TA_p2 = new Coords(canvas.width - borderWidth / 2, canvas.height - borderWidth / 2)
                let TA_p3 = new Coords(borderWidth / 2, canvas.height - borderWidth / 2)

                draw.Polygon([TA_p1, TA_p2, TA_p3])
                break

            case WDShapeType2d.Triangle_inequilateral:
                let TI_p1 = new Coords(canvas.width / 3, borderWidth / 2)
                let TI_p2 = new Coords(canvas.width - borderWidth / 2, canvas.height - borderWidth / 2)
                let TI_p3 = new Coords(borderWidth / 2, canvas.height - borderWidth / 2)

                draw.Polygon([TI_p1, TI_p2, TI_p3])
                break

            case WDShapeType2d.Cross:
                let side = Math.min(canvas.width, canvas.height) / 8
                draw.Cross(new Coords(canvas.width / 2, canvas.height / 2),
                    side, Math.min(canvas.width, canvas.height) / 2 - side - borderWidth / 2)
                break

            case WDShapeType2d.Arrow:
                draw.Arrow(borderWidth)
                break

            case WDShapeType2d.Star:
                draw.Star(
                    new Coords(canvas.width / 2, canvas.height / 2), 5,
                    Math.min(canvas.width, canvas.height) / 5,
                    Math.min(canvas.width, canvas.height) / 2 - borderWidth / 2)
                break

            case WDShapeType2d.Hexagram:
                draw.Star(
                    new Coords(canvas.width / 2, canvas.height / 2), 6,
                    Math.min(canvas.width, canvas.height) / 3.5,
                    Math.min(canvas.width, canvas.height) / 2 - borderWidth / 2)
                break

            case WDShapeType2d.Semicircle:
                let SC_startPos = new Coords(borderWidth / 2 + 2, canvas.height)
                let SC_middlePos = new Coords(canvas.width / 2, canvas.height - borderWidth / 2)
                let SC_endPos = new Coords(canvas.width - borderWidth / 2 - 2, canvas.height)
                let SC_r1 = canvas.width / 2 - borderWidth - 1
                let SC_r2 = canvas.height - borderWidth - 1

                draw.SemiCircle(SC_startPos, SC_middlePos, SC_endPos, SC_r1, SC_r2)
                break

            case WDShapeType2d.Pentagon:
                draw.Star(
                    new Coords(canvas.width / 2, canvas.height / 2), 5,
                    Math.min(canvas.width, canvas.height) / 2.5,
                    Math.min(canvas.width, canvas.height) / 2 - borderWidth / 2)
                break

            case WDShapeType2d.Hexagon:
                draw.Star(
                    new Coords(canvas.width / 2, canvas.height / 2), 6,
                    Math.min(canvas.width, canvas.height) / 2.3,
                    Math.min(canvas.width, canvas.height) / 2 - borderWidth / 2,
                    0.222)
                break

            case WDShapeType2d.Octagon:
                draw.Star(
                    new Coords(canvas.width / 2, canvas.height / 2), 8,
                    Math.min(canvas.width, canvas.height) / 2.2,
                    Math.min(canvas.width, canvas.height) / 2 - borderWidth / 2,
                    0.92)
                break

            case WDShapeType2d.Kite_Square:
                let KS_p1 = new Coords(borderWidth / 2, canvas.height / 2)
                let KS_p2 = new Coords(canvas.width / 3, borderWidth / 2)
                let KS_p3 = new Coords(canvas.width - borderWidth / 2, canvas.height / 2)
                let KS_p4 = new Coords(canvas.width / 3, canvas.height - borderWidth / 2)

                draw.Polygon([KS_p1, KS_p2, KS_p3, KS_p4])
                break

            case WDShapeType2d.Rhombus:
                let RH_p1 = new Coords(canvas.width / 2, borderWidth / 2)
                let RH_p2 = new Coords(canvas.width - borderWidth / 2, canvas.height / 2)
                let RH_p3 = new Coords(canvas.width / 2, canvas.height - borderWidth / 2)
                let RH_p4 = new Coords(borderWidth / 2, canvas.height / 2)

                draw.Polygon([RH_p1, RH_p2, RH_p3, RH_p4])
                break

            case WDShapeType2d.Parallelogram:
                let PL_p1 = new Coords(canvas.width / 5, borderWidth / 2)
                let PL_p2 = new Coords(canvas.width - borderWidth / 2, borderWidth / 2)
                let PL_p3 = new Coords(canvas.width * 4/5, canvas.height - borderWidth / 2)
                let PL_p4 = new Coords(borderWidth / 2, canvas.height - borderWidth / 2)

                draw.Polygon([PL_p1, PL_p2, PL_p3, PL_p4])
                break

            case WDShapeType2d.Trapezoid:
                let TZ_p1 = new Coords(canvas.width / 5, borderWidth / 2)
                let TZ_p2 = new Coords(canvas.width * 4/5, borderWidth / 2)
                let TZ_p3 = new Coords(canvas.width - borderWidth / 2, canvas.height - borderWidth / 2)
                let TZ_p4 = new Coords(borderWidth / 2, canvas.height - borderWidth / 2)

                draw.Polygon([TZ_p1, TZ_p2, TZ_p3, TZ_p4])
                break
        }
    }
    render() {
        let onlyProportionalResize = this.isOnlyProportionalResizeAllowed(this.props.data.type)
        let resizeInfo: ResizeInfo =
            new ResizeInfo(!this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly,
                !onlyProportionalResize, !onlyProportionalResize, !onlyProportionalResize, !onlyProportionalResize,
                this.getMinWidth(), Const.MaxElementSize, this.getMinHeight(), Const.MaxElementSize)
        resizeInfo.displaySelectionBorder = false

        // if element is marked as deleted, do not render
        if(this.props.element.deleted) {
            return <></>
        }

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            hasResizeOnCreate={false}
            resizeInfo={resizeInfo}
            renderWrapper={true}
            renderBackgroundColor={false}
            renderBorder={false}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.onResizeElement}
            isEditModeAllowed={() => false}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            ref={this.state.elementRef}
        >

            <div className={"ws-designer-shape print"}>
                <canvas className={"ws-designer-shape-canvas"} id={this.getCanvasId()}></canvas>
            </div>

        </WDElementContainer>
    }
}
