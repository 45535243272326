import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Triggers locking/unlocking of element
 */

interface IProps {
    id: number
    locked: boolean
    clickable: boolean
    onChangeLockingStatus: (locked: boolean) => void
    onToggleButton: (id: number) => void
}

interface IState {}

export class WDToolbarButtonLock extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    changeLockingStatus = () => {
        this.props.onChangeLockingStatus(!this.props.locked)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-lock"}
            id={"lockElement"}>

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + (this.props.locked ? "unlock_object.svg" : "lock_object.svg")}
                             tooltip={this.props.locked ?
                                 new TooltipText(this.context.translate(translations.toolbar.unlock), this.context.translate(translations.tooltip.unlock)) :
                                 new TooltipText(this.context.translate(translations.toolbar.lock), this.context.translate(translations.tooltip.lock))
                             }
                             arrow={false}
                             onToggleButton={this.changeLockingStatus}
                             pressed={false} />
        </li>
    }
}
