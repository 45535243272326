import Entity from "./Entity";

export class RuleCategory extends Entity {

    static RULE: number = 1
    static TASK_DESCRIPTION: number = 2

    static getRuleCategoryRule() {
        return new RuleCategory("Regel", RuleCategory.RULE)
    }
    static getRuleCategoryDescription() {
        return new RuleCategory("Aufgabenbeschreibung", RuleCategory.TASK_DESCRIPTION)
    }
}

export default RuleCategory
