import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbar, WDToolbarOrientation} from "../../Toolbar/Toolbar/WDToolbar";
import Converter from "../../../Framework/Converter";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarSectionObjectGeneral} from "../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarSectionTransformation} from "../../Toolbar/Section/WDToolbarSectionTransformation";
import {ElementLayout} from "../WDElementContainer";
import {WDTextboxToolbarProps} from "../Textbox/WDTextboxToolbar";
import {WDToolbarButtonTaskGenerator} from "../../Toolbar/Button/Math/WDToolbarButtonTaskGenerator";
import {WDToolbarButtonRegenerate} from "../../Toolbar/Button/Math/WDToolbarButtonRegenerate";
import {TooltipText} from "../../../Components/Tooltips";
import translations from "../../../Framework/translations.json";
import {
    GetAllChildWritingCoursesByParent, GetAllWritingCourseParentList
} from "../../../_endpoint/WritingCourseEndpoint";
import {GetWorksheetThumbnail} from "../../../_endpoint/WorksheetEndpoint";
import WritingCourse from "../../../_model/WritingCourse";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {WritingCourseFilter} from "../../../_model/WritingCourseFilter";
import {WDToolbarButtonThumbnail} from "../../Toolbar/Button/General/WDToolbarButtonThumbnail";
import {WDWritingCourseToolbarFilter} from "./WDWritingCourseToolbarFilter";
import {Worksheet} from "../../../_model/Worksheet";
import {GetLessonSubject} from "../../../_endpoint/MetadataEndpoint";
import {WDWritingCourseToolbarSearch} from "./WDWritingCourseToolbarSearch";
import {ProductLicense} from "../../../Framework/Enums";

export interface WDWritingCourseProps extends WDToolbarElementProps {
    onUngroup?: () => void
    getElementExerciseWorksheet?: () => Worksheet | undefined
}
export interface WDWritingCourseState extends WDToolbarElementState {
    writingCourseFilter: WritingCourseFilter
    childWritingCourseFilter: WritingCourseFilter
    currentThumb?: string

    parentWritingCourses: WritingCourse[]
    filteredParentWritingCourses: WritingCourse[]
    childWritingCourses: WritingCourse[]
    filteredChildWritingCourses: WritingCourse[]
}

export class WDWritingCourseToolbar extends WDToolbarElement<WDWritingCourseProps, WDWritingCourseState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    elementLicenses: ProductLicense[] = [ProductLicense.employee]

    constructor(props: WDWritingCourseProps, state: WDWritingCourseState) {
        super(props, state);

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef(),

            writingCourseFilter: this.initFilter(),
            childWritingCourseFilter: new WritingCourseFilter(),
            parentWritingCourses: [],
            filteredParentWritingCourses: [],
            childWritingCourses: [],
            filteredChildWritingCourses: [],
        }
    }

    async componentDidMount() {
        let parentWorksheet = this.props.getElementExerciseWorksheet ? this.props.getElementExerciseWorksheet() : undefined
        let thumb: string | undefined = undefined

        if(parentWorksheet) {
            if(parentWorksheet.thumbnail) {
                thumb = parentWorksheet.thumbnail
            } else {
                parentWorksheet = await GetWorksheetThumbnail(parentWorksheet.id)

                if(parentWorksheet && parentWorksheet.thumbnail) {
                    thumb = parentWorksheet.thumbnail
                }
            }
        }

        let lessonSubjects = await GetLessonSubject()
        let currentFilter = this.state.writingCourseFilter
        let matchingLessonSubjects = lessonSubjects.filter(l => l.id === currentFilter.lessonSubject)

        if(matchingLessonSubjects.length > 0) {
            currentFilter.lessonSubject = matchingLessonSubjects[0]
        }

        this.hasValidLicenseForElement(this.elementLicenses)

        this.setState({activeId: -1, currentThumb: thumb, writingCourseFilter: currentFilter})
    }
    componentDidUpdate(prevProps: WDTextboxToolbarProps) {

        // Check if new selected element is different from previous selected element by data
        if (this.props.worksheetItemData.length === prevProps.worksheetItemData.length &&
            this.props.worksheetItemData.every((value, index) => {
                return value === prevProps.worksheetItemData[index]
            })) {

            return
        }

        this.updateToolbarValues()
    }
    initFilter = () => {
        let lessonSubjectObj = this.multiselectSearchForValue("lessonSubject")
        let difficultyObj = this.multiselectSearchForValue("difficulty")
        let writingLineatureSizeObj = this.multiselectSearchForValue("writingLineatureSize")
        let mathLineatureSizeObj = this.multiselectSearchForValue("mathLineatureSize")
        let characterTypeObj = this.multiselectSearchForValue("characterType")
        let syllableMethodObj = this.multiselectSearchForValue("syllableMethod")
        let previewSymbolObj = this.multiselectSearchForValue("previewSymbol")
        let previewSymbolTraceObj = this.multiselectSearchForValue("previewSymbolTrace")
        let previewSymbolImageObj = this.multiselectSearchForValue("previewSymbolImage")
        let traceArrowObj = this.multiselectSearchForValue("traceArrow")
        let sequenceNumberObj = this.multiselectSearchForValue("sequenceNumber")
        let startNumberObj = this.multiselectSearchForValue("startNumber")
        let colorObj = this.multiselectSearchForValue("color")
        let upperLowerLetterMixedObj = this.multiselectSearchForValue("upperLowerLetterMixed")

        let writingCourseFilter = new WritingCourseFilter()
        writingCourseFilter.lessonSubject = (lessonSubjectObj?.valueEqual && lessonSubjectObj.newValue !== undefined) ? lessonSubjectObj.newValue : undefined
        writingCourseFilter.difficulty = (difficultyObj?.valueEqual && difficultyObj.newValue !== undefined) ? difficultyObj.newValue : undefined
        writingCourseFilter.writingLineatureSize = (writingLineatureSizeObj?.valueEqual && writingLineatureSizeObj.newValue !== undefined) ? writingLineatureSizeObj.newValue : undefined
        writingCourseFilter.mathLineatureSize = (mathLineatureSizeObj?.valueEqual && mathLineatureSizeObj.newValue !== undefined) ? mathLineatureSizeObj.newValue : undefined
        writingCourseFilter.characterType = (characterTypeObj?.valueEqual && characterTypeObj.newValue !== undefined) ? characterTypeObj.newValue : undefined
        writingCourseFilter.syllableMethod = (syllableMethodObj?.valueEqual && syllableMethodObj.newValue !== undefined) ? syllableMethodObj.newValue : undefined
        writingCourseFilter.previewSymbol = (previewSymbolObj?.valueEqual && previewSymbolObj.newValue !== undefined) ? previewSymbolObj.newValue : undefined
        writingCourseFilter.previewSymbolTrace = (previewSymbolTraceObj?.valueEqual && previewSymbolTraceObj.newValue !== undefined) ? previewSymbolTraceObj.newValue : undefined
        writingCourseFilter.previewSymbolImage = (previewSymbolImageObj?.valueEqual && previewSymbolImageObj.newValue !== undefined) ? previewSymbolImageObj.newValue : undefined
        writingCourseFilter.traceArrow = (traceArrowObj?.valueEqual && traceArrowObj.newValue !== undefined) ? traceArrowObj.newValue : undefined
        writingCourseFilter.sequenceNumber = (sequenceNumberObj?.valueEqual && sequenceNumberObj.newValue !== undefined) ? sequenceNumberObj.newValue : undefined
        writingCourseFilter.startNumber = (startNumberObj?.valueEqual && startNumberObj.newValue !== undefined) ? startNumberObj.newValue : undefined
        writingCourseFilter.color = (colorObj?.valueEqual && colorObj.newValue !== undefined) ? colorObj.newValue : undefined
        writingCourseFilter.upperLowerLetterMixed = (upperLowerLetterMixedObj?.valueEqual && upperLowerLetterMixedObj.newValue !== undefined) ? upperLowerLetterMixedObj.newValue : undefined

        return writingCourseFilter
    }

    updateToolbarValues = () => {
        this.setState({activeId: -1})
    }
    onChangeToolbarTab = (type: WDToolbarTypeEnum) => {
        if(this.state.activeToolbarType !== type) {
            this.setState({activeToolbarType: type})
        }
    }

    onToggleLoadParentWritingCourses = async (buttonId: number) => {
        buttonId = this.state.activeId === buttonId ? -1 : buttonId
        this.onToggleButton(buttonId)

        if(buttonId !== -1 && this.state.parentWritingCourses.length === 0) {
            GetAllWritingCourseParentList().then(
                (writingCourses) => {
                    this.setState({
                        parentWritingCourses: writingCourses,
                    },  () => this.loadParentThumbnails(writingCourses))
                })
        }
        else if(buttonId !== -1 && this.state.parentWritingCourses.length > 0) {
            let writingCourses = this.state.parentWritingCourses
            await this.loadParentThumbnails(writingCourses)
        }
    }
    onToggleLoadChildWritingCourses = (buttonId: number) => {
        buttonId = this.state.activeId === buttonId ? -1 : buttonId
        this.onToggleButton(buttonId)

        if(buttonId !== -1) {
            const parentId = this.multiselectSearchForValue("parentWritingCourse")

            if (parentId) {
                GetAllChildWritingCoursesByParent(parentId.newValue.id).then(
                    async (writingCourses) => {
                        await this.loadThumbnails(writingCourses, false)

                        this.setState({
                            childWritingCourses: writingCourses,
                        })
                    }
                )
            }
        }
    }
    loadParentThumbnails = async (writingCourses: WritingCourse[]) => {
        let courses = writingCourses.filter(course =>
            (this.state.writingCourseFilter.lessonSubject === undefined || course.lessonSubject?.id === this.state.writingCourseFilter.lessonSubject.id) &&
            (this.state.writingCourseFilter.difficulty === undefined || course.difficulty === this.state.writingCourseFilter.difficulty) &&
            (this.state.writingCourseFilter.writingLineatureSize === undefined || course.writingLineatureSize === this.state.writingCourseFilter.writingLineatureSize) &&
            (this.state.writingCourseFilter.mathLineatureSize === undefined || course.mathLineatureSize === this.state.writingCourseFilter.mathLineatureSize) &&
            (this.state.writingCourseFilter.characterType === undefined || course.characterType === this.state.writingCourseFilter.characterType) &&
            (this.state.writingCourseFilter.syllableMethod === undefined || course.syllableMethod === this.state.writingCourseFilter.syllableMethod) &&
            (this.state.writingCourseFilter.previewSymbol === undefined || course.previewSymbol === this.state.writingCourseFilter.previewSymbol) &&
            (this.state.writingCourseFilter.previewSymbolTrace === undefined || course.previewSymbolTrace === this.state.writingCourseFilter.previewSymbolTrace) &&
            (this.state.writingCourseFilter.previewSymbolImage === undefined || course.previewSymbolImage === this.state.writingCourseFilter.previewSymbolImage) &&
            (this.state.writingCourseFilter.traceArrow === undefined || course.traceArrow === this.state.writingCourseFilter.traceArrow) &&
            (this.state.writingCourseFilter.sequenceNumber === undefined || course.sequenceNumber === this.state.writingCourseFilter.sequenceNumber) &&
            (this.state.writingCourseFilter.startNumber === undefined || course.startNumber === this.state.writingCourseFilter.startNumber) &&
            (this.state.writingCourseFilter.color === undefined || course.color === this.state.writingCourseFilter.color) &&
            (this.state.writingCourseFilter.upperLowerLetterMixed === undefined || course.upperLowerLetterMixed === this.state.writingCourseFilter.upperLowerLetterMixed))

        this.loadThumbnails(courses, true)
    }
    loadThumbnails = async (writingCourses: WritingCourse[], isParent: boolean) => {
        for (let course of writingCourses) {
            if (course.id && course.worksheet && course.worksheet.id && course.thumbnail === undefined) {
                let thumbnail = await GetWorksheetThumbnail(course.worksheet?.id)

                if (thumbnail && thumbnail.thumbnail) {
                    course.thumbnail = thumbnail.thumbnail
                }
            }
        }

        if(isParent) {
            this.setState({filteredParentWritingCourses: writingCourses})
        } else {
            this.setState({filteredChildWritingCourses: writingCourses})
        }
    }
    onChangeParentImage = (action: WDToolbarAction, data: string) => {
        this.onChangeWritingCourseImage(action, data, "parentWritingCourses", true)
    }
    onChangeChildImage = (action: WDToolbarAction, data: string) => {
        this.onChangeWritingCourseImage(action, data, "childWritingCourses", false)
    }
    onChangeWritingCourseImage = (action: WDToolbarAction, data: string, stateVariable: string, isParent: boolean) => {
        if(data && data["task"]) {
            let courses = this.state[stateVariable].filter(child => child.id === +data["task"])

            if(courses.length > 0) {
                if(isParent) {
                    this.setState({currentThumb: courses[0].thumbnail})
                }
                this.props.onToolbarAction(action, {"writingCourse": courses[0], "isParent": isParent})
            }
        }
    }
    onChangeFilter = (filter: WritingCourseFilter) => {
        this.setState({writingCourseFilter: filter}, () => {
            this.loadParentThumbnails(this.state.parentWritingCourses)
        })
    }
    onChangeSearch = (searchString: string) => {
        let children = this.state.childWritingCourses.filter(child => child.name.includes(searchString))
        this.setState({filteredChildWritingCourses: children})
    }

    render() {
        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, false, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={false}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={true}
                    canChangeLinkState={true}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&

            <ul className={"ws-designer-toolbar-buttons"}>
                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonTaskGenerator
                            id={1}
                            pressed={true}
                            enabled={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.task_generator_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}
                            onUngroup={this.props.onUngroup}
                            onShowConfirmation={this.props.onShowConfirmation}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonRegenerate
                            id={2}
                            pressed={false}
                            clickable={this.state.isLicenseValidForElement}
                            tooltip={new TooltipText(
                                this.context.translate(translations.toolbar.regenerate_design),
                                this.context.translate(translations.tooltip.regenerate_design))}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.regenerate_design_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-button-separator"} />

                <div className={"ws-designer-toolbar-section"}>
                    <WDToolbarButtonThumbnail
                        id={11}
                        thumbnail={this.state.currentThumb || "icon"}
                        pressed={this.state.activeId === 11}
                        clickable={this.state.isLicenseValidForElement}
                        images={this.state.filteredParentWritingCourses}
                        filter={<WDWritingCourseToolbarFilter filter={this.state.writingCourseFilter} onChangeFilter={this.onChangeFilter}/>}
                        onToggleButton={() => this.onToggleLoadParentWritingCourses(11)}
                        onActionButton={this.onChangeParentImage}
                        imageWidth={100}
                        submenuHeight={550}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic),
                            this.context.translate(translations.tooltip.change_graphic)
                        )}
                        tooltipDisabled={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic_disabled),
                            this.context.translate(translations.tooltip.no_license)
                        )}
                        thumbnailImageWidth={80}
                        thumbnailImageHeight={80}
                        thumbnailButtonWidth={100}
                        thumbnailButtonHeight={69}
                    />
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <WDToolbarButtonThumbnail
                        id={10}
                        thumbnail={"icon"}
                        pressed={this.state.activeId === 10}
                        clickable={this.state.isLicenseValidForElement}
                        images={this.state.filteredChildWritingCourses}
                        filter={<WDWritingCourseToolbarSearch onChangeSearch={this.onChangeSearch}/>}
                        onToggleButton={() => this.onToggleLoadChildWritingCourses(10)}
                        onActionButton={this.onChangeChildImage}
                        imageWidth={100}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic),
                            this.context.translate(translations.tooltip.change_graphic)
                        )}
                        tooltipDisabled={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic_disabled),
                            this.context.translate(translations.tooltip.no_license)
                        )}
                        thumbnailImageWidth={80}
                        thumbnailImageHeight={80}
                        thumbnailButtonWidth={100}
                        thumbnailButtonHeight={69}
                    />
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={true}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    elementProps={this.props.elementProps}
                    onChangeBorder={this.props.onChangeBorder}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onAddRule={this.props.onAddRule}
                    onElementDeleted={this.props.onElementDeleted}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                />
            </ul>
            }
        </WDToolbar>
    }
}
