import React from "react";
import {MainContext} from "../../../_base/MainContext";
import {ImagePath} from "../../../Framework/CategoryImage";
import Converter from "../../../Framework/Converter";
import translations from "../../../Framework/translations.json";
import {NumberInput} from "../../../Components/Controls/NumberInput";
import {WDToolbarButton} from "../Button/WDToolbarButton";
import {DropDown, DropDownType} from "../../../Components/Controls/DropDown";
import {ElementProps} from "../Toolbar/WDToolbarElement";
import {WDToolbarState} from "../Toolbar/WDToolbar";
import {WDToolbarTypeEnum} from "../Toolbar/WDToolbarTabs";
import {Tooltips, TooltipText} from "../../../Components/Tooltips";
import {WDToolbarButtonGroup} from "../Button/General/WDToolbarButtonGroup";
import {WDToolbarButtonSeparator} from "../Button/General/WDToolbarButtonSeparator";
import {WDToolbarButtonLock} from "../Button/General/WDToolbarButtonLock";
import {WDToolbarButtonDelete} from "../Button/General/WDToolbarButtonDelete";
import {WDToolbarSectionObjectColor} from "./WDToolbarSectionObjectColor";
import {ElementBorder} from "../../Elements/WDElementContainer";
import {WDToolbarAction} from "../WDToolbarAction";
import {WSContextType} from "../../Elements/WSContext";
import {WDElementArrange} from "../../Elements/Enum/WDElementArrange";
import {WDElementAlignment} from "../../Elements/Enum/WDElementAlignment";
import {WDElementDistributeMode} from "../../Elements/Enum/WDElementDistributeMode";
import {LinkBracketInfo, WDToolbarLinkBracket} from "../General/WDToolbarLinkBracket";
import {WorksheetItemUpdate, WorksheetItemUpdateOptions} from "../../Utils/WorksheetItemUpdate";
import {WDHistoryAction} from "../../History/Enum/WDHistoryAction";
import {WDToolbarIconLabel} from "../General/WDToolbarIconLabel";
import {WDActionLogCategory} from "../../ActionLog/WDActionLogEntry";

export interface IProps {
    elementProps: ElementProps
    showColorOptions: boolean

    isReadonly: boolean
    isEdited: boolean
    initialLinkState: boolean
    canChangeLinkState: boolean
    context: WSContextType
    selectedElementCount: number
    elementsOnSameSheet: boolean

    onUpdateSelectedElements: (update: WorksheetItemUpdate, options?: WorksheetItemUpdateOptions) => void
    onFlipHorizontal: () => void
    onFlipVertical: () => void

    onToolbarAction?: (action: WDToolbarAction, data?: Text) => void
    onChangeActive?: () => void
    onChangeBorder?: (border: ElementBorder) => void

    onChangeGroupingStatus?: (grouped: boolean) => void
    onChangeLockingStatus?: (locked: boolean) => void
    onElementDeleted?: () => void
    onDuplicate?: () => void
    onCopy?: () => void
    onCut?: () => void
    onPaste?: () => void
    grouped?: boolean

    onArrange?: (mode: WDElementArrange) => void
    onAlign?: (mode: WDElementAlignment) => void
    onDistribute?: (mode: WDElementDistributeMode) => void
}

export interface IState extends WDToolbarState {
    link: boolean
}

export class WDToolbarSectionTransformation extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            link: this.props.initialLinkState,
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.TRANSFORMATION
        }
    }

    onChangeWidth = (value: number) => {
        let update = new WorksheetItemUpdate("", {
            width: Math.round(Converter.mmToPx(value)),
            height: undefined
        })
        this.props.onUpdateSelectedElements?.(update, {
            proportional: this.state.link,
            historyAction: WDHistoryAction.RESIZE
        })
    }
    onChangeHeight = (value: number) => {
        let update = new WorksheetItemUpdate("", {
            width: undefined,
            height: Math.round(Converter.mmToPx(value))
        })
        this.props.onUpdateSelectedElements?.(update, {
            proportional: this.state.link,
            historyAction: WDHistoryAction.RESIZE
        })
    }
    onChangeLeft = (value: number) => {
        let update = new WorksheetItemUpdate("", {
            posX: Math.round(Converter.mmToPx(value)),
            posY: undefined
        })
        this.props.onUpdateSelectedElements?.(update, {
            historyAction: WDHistoryAction.MOVE,
            applyRecursive: false,
            applyToChildren: true
        })
    }
    onChangeTop = (value: number) => {
        let update = new WorksheetItemUpdate("", {
            posX: undefined,
            posY: Math.round(Converter.mmToPx(value))
        })
        this.props.onUpdateSelectedElements?.(update, {
            historyAction: WDHistoryAction.MOVE,
            applyRecursive: false,
            applyToChildren: true
        })
    }
    onChangeAngleManually = (value: string) => {
        let update = new WorksheetItemUpdate("", {
            rotation: +value
        })
        this.props.onUpdateSelectedElements?.(update, {
            historyAction: WDHistoryAction.ROTATE,
            actionCategory: WDActionLogCategory.rotate
        })
    }
    onChangeRotation = (angle: number) => {
        let update = new WorksheetItemUpdate("", {
            rotation: this.props.elementProps.transformation.rotation + angle
        })
        this.props.onUpdateSelectedElements?.(update, {
            historyAction: WDHistoryAction.ROTATE,
            actionCategory: WDActionLogCategory.rotate
        })
    }
    onChangeSkew = (angle: string) => {
        let update = new WorksheetItemUpdate("", {
            skew: +angle
        })
        this.props.onUpdateSelectedElements?.(update, {
            historyAction: WDHistoryAction.SKEW,
            actionCategory: WDActionLogCategory.skew
        })
    }

    onToggleButton = (id: number) => {
        this.setState({activeId: id})
    }
    onToggleLink = () => {
        if (this.props.canChangeLinkState) {
            this.setState({link: !this.state.link})
        }
    }
    onFlip = (horizontal: boolean, vertical: boolean) => {
        if (horizontal) {
            this.props.onFlipHorizontal()
        }
        if (vertical) {
            this.props.onFlipVertical()
        }
    }

    render () {
        return <div className={"ws-designer-toolbar-row"} style={{height: "100%"}}>

            <div className={"ws-designer-toolbar-section"}>
                <div className={"ws-designer-toolbar-row tooltip-above"}>
                    <li className={"ws-designer-toolbar-item tooltip-control"}>
                        <label className={"label"} style={{width: 14}}>
                            {this.context.translate(translations.toolbar.width_short)}:
                        </label>
                        <NumberInput width={90}
                                     clickable={!this.props.isReadonly}
                                     required={false}
                                     maxlength={4}
                                     minValue={this.props.elementProps.minWidth}
                                     maxValue={this.props.elementProps.maxWidth}
                                     unit={"mm"}
                                     unitWidth={18}
                                     value={this.props.elementProps.layout.width}
                                     onChangeValue={this.onChangeWidth}
                        />
                    </li>
                    <Tooltips
                        text={new TooltipText(
                            this.context.translate(translations.toolbar.width),
                            this.context.translate(translations.tooltip.width)
                        )}
                        translateX={-30} translateY={-38} />
                </div>
                <div className={"ws-designer-toolbar-row tooltip-above"}>
                    <li className={"ws-designer-toolbar-item tooltip-control"}>
                        <label className={"label"} style={{width: 14}}>
                            {this.context.translate(translations.toolbar.height_short)}:
                        </label>
                        <NumberInput width={90}
                                     clickable={!this.props.isReadonly}
                                     required={false}
                                     maxlength={4}
                                     minValue={this.props.elementProps.minHeight}
                                     maxValue={this.props.elementProps.maxHeight}
                                     unit={"mm"}
                                     unitWidth={18}
                                     value={this.props.elementProps.layout.height}
                                     onChangeValue={this.onChangeHeight}
                        />
                    </li>
                    <Tooltips
                        text={new TooltipText(
                            this.context.translate(translations.toolbar.height),
                            this.context.translate(translations.tooltip.height)
                        )}
                        translateX={-30} translateY={-38} />
                </div>
            </div>

            <div className={"ws-designer-toolbar-section"}>
                <WDToolbarLinkBracket
                    readonly={!this.props.canChangeLinkState}
                    linked={this.state.link}
                    linkBracketInfo={new LinkBracketInfo(true, true, false, false)}
                    onClickLink={this.onToggleLink}/>
            </div>

            <div className={"ws-designer-toolbar-section"} style={{marginRight: "8px"}}>
                <div className={"ws-designer-toolbar-row tooltip-above"}>
                    <li className={"ws-designer-toolbar-item tooltip-control"}>
                        <label className={"label"} style={{width: 12}}>
                            {this.context.translate(translations.toolbar.x)}:
                        </label>
                        <NumberInput width={90}
                                 clickable={!this.props.isReadonly}
                                 required={false}
                                 maxlength={4}
                                 minValue={this.props.elementProps.minLeft !== undefined ? this.props.elementProps.minLeft : -500}
                                 maxValue={this.props.elementProps.maxLeft !== undefined ? this.props.elementProps.maxLeft : 2000}
                                 unit={"mm"}
                                 unitWidth={18}
                                 value={this.props.elementProps.layout.left}
                                 onChangeValue={this.onChangeLeft}
                        />
                    </li>
                    <Tooltips
                        text={new TooltipText(
                            this.context.translate(translations.toolbar.x_position),
                            this.context.translate(translations.tooltip.x_position)
                        )}
                        translateX={-30} translateY={-38} />
                </div>
                <div className={"ws-designer-toolbar-row tooltip-above"}>
                    <li className={"ws-designer-toolbar-item tooltip-control"}>
                        <label className={"label"} style={{width: 12}}>
                            {this.context.translate(translations.toolbar.y)}:
                        </label>
                        <NumberInput width={90}
                                     clickable={!this.props.isReadonly}
                                     required={false}
                                     maxlength={4}
                                     minValue={this.props.elementProps.minTop !== undefined ? this.props.elementProps.minTop : -500}
                                     maxValue={this.props.elementProps.maxTop !== undefined ? this.props.elementProps.maxTop : 2000}
                                     unit={"mm"}
                                     unitWidth={18}
                                     value={this.props.elementProps.layout.top}
                                     onChangeValue={this.onChangeTop}
                        />
                    </li>
                    <Tooltips
                        text={new TooltipText(
                            this.context.translate(translations.toolbar.y_position),
                            this.context.translate(translations.tooltip.y_position)
                        )}
                        translateX={-30} translateY={-38} />
                </div>
            </div>

            <div className={"ws-designer-toolbar-section"}>
                <div className={"ws-designer-toolbar-row"}>
                    <li className={"ws-designer-toolbar-item"}>
                        <WDToolbarButton id={1}
                                         icon={ImagePath.getButtonUrl() + "turn_left.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.turn_left),
                                             this.context.translate(translations.tooltip.turn_left))}
                                         onToggleButton={() => this.onChangeRotation(-90)}
                                         arrow={false}
                                         enabled={!this.props.isReadonly}
                                         pressed={false} />

                    </li>
                    <li className={"ws-designer-toolbar-item"}>
                        <WDToolbarButton id={2}
                                         icon={ImagePath.getButtonUrl() + "turn_right.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.turn_right),
                                             this.context.translate(translations.tooltip.turn_right))}
                                         onToggleButton={() => this.onChangeRotation(90)}
                                         arrow={false}
                                         enabled={!this.props.isReadonly}
                                         pressed={false} />
                    </li>
                    <li className={"ws-designer-toolbar-item"}>
                        <WDToolbarIconLabel id={12}
                                            icon={ImagePath.getButtonUrl() + "rotation.svg"}
                                            tooltip={new TooltipText(
                                                this.context.translate(translations.toolbar.rotation),
                                                this.context.translate(translations.tooltip.rotation))
                                            }
                                            enabled={!this.props.isReadonly}
                        />

                        {/*<WDToolbarButton id={12}*/}
                        {/*                 cssClass={"ws-designer-toolbar-button-ml"}*/}
                        {/*                 buttonClass={"ws-designer-toolbar-button-image-no-hover"}*/}
                        {/*                 iconClassSelected={"svg-color-1E1F28"}*/}
                        {/*                 iconClassUnselected={"svg-color-1E1F28"}*/}
                        {/*                 onToggleButton={() => {}}*/}
                        {/*                 buttonWidth={25}*/}
                        {/*                 imageWidth={13}*/}
                        {/*                 arrow={false}*/}
                        {/*                 enabled={!this.props.isReadonly}*/}
                        {/*                 pressed={false} />*/}

                        <DropDown id={"rotation"}
                                  width={80}
                                  readonly={this.props.isReadonly}
                                  required={false}
                                  autocomplete={false}
                                  type={DropDownType.NUMBER}
                                  unit={"°"} unitWidth={6} unitPaddingRight={4}
                                  minValue={-359} maxValue={359}
                                  value={this.props.elementProps.transformation.rotation.toString()}
                                  items={[
                                      { value: "0", name: "0 °" },
                                      { value: "30", name: "30 °" },
                                      { value: "60", name: "60 °" },
                                      { value: "90", name: "90 °" },
                                      { value: "120", name: "120 °" },
                                      { value: "150", name: "150 °" },
                                      { value: "180", name: "180 °" },
                                      { value: "210", name: "210 °" },
                                      { value: "240", name: "240 °" },
                                      { value: "270", name: "270 °" },
                                      { value: "300", name: "300 °" },
                                      { value: "330", name: "330 °" },
                                  ]}
                                  onChangeValue={this.onChangeAngleManually} />
                    </li>
                </div>
                <div className={"ws-designer-toolbar-row"}>
                    <li className={"ws-designer-toolbar-item"}>
                        <WDToolbarButton id={3}
                                         icon={ImagePath.getButtonUrl() + "flip_horizontally.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.flip_horizontal),
                                             this.context.translate(translations.tooltip.flip_horizontal))}
                                         onToggleButton={() => this.onFlip(true, false)}
                                         arrow={false}
                                         enabled={!this.props.isReadonly}
                                         pressed={this.props.elementProps.transformation.flipHorizontal} />
                    </li>
                    <li className={"ws-designer-toolbar-item"}>
                        <WDToolbarButton id={4}
                                         icon={ImagePath.getButtonUrl() + "flip_vertically.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.flip_vertical),
                                             this.context.translate(translations.toolbar.flip_vertical))}
                                         onToggleButton={() => this.onFlip(false, true)}
                                         arrow={false}
                                         enabled={!this.props.isReadonly}
                                         pressed={this.props.elementProps.transformation.flipVertical} />
                    </li>
                    <li className={"ws-designer-toolbar-item"}>
                        <WDToolbarIconLabel id={13}
                                            icon={ImagePath.getButtonUrl() + "skew.svg"}
                                            tooltip={new TooltipText(
                                                this.context.translate(translations.toolbar.skew),
                                                this.context.translate(translations.tooltip.skew))
                                            }
                                            enabled={!this.props.isReadonly}
                        />

                        <DropDown id={"skew"}
                                  width={80}
                                  type={DropDownType.NUMBER}
                                  required={false}
                                  readonly={this.props.isReadonly}
                                  autocomplete={false}
                                  unit={"°"} unitWidth={6} unitPaddingRight={4}
                                  minValue={-89} maxValue={89}
                                  value={this.props.elementProps.transformation.skew.toString()}
                                  items={[
                                      { value: "-80", name: "-80 °" },
                                      { value: "-70", name: "-70 °" },
                                      { value: "-60", name: "-60 °" },
                                      { value: "-50", name: "-50 °" },
                                      { value: "-40", name: "-40 °" },
                                      { value: "-30", name: "-30 °" },
                                      { value: "-20", name: "-20 °" },
                                      { value: "-10", name: "-10 °" },
                                      { value: "0", name: "0 °" },
                                      { value: "10", name: "10 °" },
                                      { value: "20", name: "20 °" },
                                      { value: "30", name: "30 °" },
                                      { value: "40", name: "40 °" },
                                      { value: "50", name: "50 °" },
                                      { value: "60", name: "60 °" },
                                      { value: "70", name: "70 °" },
                                      { value: "80", name: "80 °" },
                                  ]}
                                  onChangeValue={this.onChangeSkew} />
                    </li>
                </div>
            </div>

            {this.props.onArrange &&
                <div className={"ws-designer-toolbar-section"} style={{marginLeft: 5}}>
                    <div className={"ws-designer-toolbar-row"}>
                        <li className={"ws-designer-toolbar-item"}>
                            <WDToolbarButton id={31}
                                             icon={ImagePath.getButtonUrl() + "arrange_top.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.arrange_top),
                                                 this.context.translate(translations.tooltip.arrange_top))}
                                             onToggleButton={() => this.props.onArrange?.(WDElementArrange.top)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && !this.props.isEdited && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                        </li>

                        <li className={"ws-designer-toolbar-item"}>
                            <WDToolbarButton id={32}
                                             icon={ImagePath.getButtonUrl() + "arrange_up.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.arrange_up),
                                                 this.context.translate(translations.tooltip.arrange_up))}
                                             onToggleButton={() => this.props.onArrange?.(WDElementArrange.up)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && !this.props.isEdited && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                        </li>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <li className={"ws-designer-toolbar-item"}>
                            <WDToolbarButton id={33}
                                             icon={ImagePath.getButtonUrl() + "arrange_bottom.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.arrange_bottom),
                                                 this.context.translate(translations.tooltip.arrange_bottom))}
                                             onToggleButton={() => this.props.onArrange?.(WDElementArrange.bottom)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && !this.props.isEdited && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                        </li>

                        <li className={"ws-designer-toolbar-item"}>
                            <WDToolbarButton id={34}
                                             icon={ImagePath.getButtonUrl() + "arrange_down.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.arrange_down),
                                                 this.context.translate(translations.tooltip.arrange_down))}
                                             onToggleButton={() => this.props.onArrange?.(WDElementArrange.down)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && !this.props.isEdited && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                        </li>
                    </div>
                </div>
            }

            {this.props.onAlign && this.props.selectedElementCount > 1 &&
                <div className={"ws-designer-toolbar-section"} style={{marginLeft: 5}}>
                    <div className={"ws-designer-toolbar-row"}>
                        <li className={"ws-designer-toolbar-item"}>
                            <WDToolbarButton id={41}
                                             icon={ImagePath.getButtonUrl() + "align_horizontal_left.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.align_horizontal_left),
                                                 this.context.translate(translations.tooltip.align_horizontal_left))}
                                             onToggleButton={() => this.props.onAlign?.(WDElementAlignment.left)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                            <WDToolbarButton id={42}
                                             icon={ImagePath.getButtonUrl() + "align_horizontal_right.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.align_horizontal_right),
                                                 this.context.translate(translations.tooltip.align_horizontal_right))}
                                             onToggleButton={() => this.props.onAlign?.(WDElementAlignment.right)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                            <WDToolbarButton id={43}
                                             icon={ImagePath.getButtonUrl() + "align_horizontal_center.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.align_horizontal_middle),
                                                 this.context.translate(translations.tooltip.align_horizontal_middle))}
                                             onToggleButton={() => this.props.onAlign?.(WDElementAlignment.horizontal_center)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                        </li>
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                        <li className={"ws-designer-toolbar-item"}>
                            <WDToolbarButton id={44}
                                             icon={ImagePath.getButtonUrl() + "align_vertical_up.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.align_vertical_top),
                                                 this.context.translate(translations.tooltip.align_vertical_top))}
                                             onToggleButton={() => this.props.onAlign?.(WDElementAlignment.top)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                            <WDToolbarButton id={45}
                                             icon={ImagePath.getButtonUrl() + "align_vertical_down.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.align_vertical_bottom),
                                                 this.context.translate(translations.tooltip.align_vertical_bottom))}
                                             onToggleButton={() => this.props.onAlign?.(WDElementAlignment.bottom)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                            <WDToolbarButton id={46}
                                             icon={ImagePath.getButtonUrl() + "align_vertical_center.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.align_vertical_middle),
                                                 this.context.translate(translations.tooltip.align_vertical_middle))}
                                             onToggleButton={() => this.props.onAlign?.(WDElementAlignment.vertical_center)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                        </li>
                    </div>
                </div>
            }

            {this.props.onAlign && this.props.selectedElementCount > 2 &&
                <div className={"ws-designer-toolbar-section"} style={{marginLeft: 5}}>
                    <div className={"ws-designer-toolbar-row"}>
                        <li className={"ws-designer-toolbar-item"}>
                            <WDToolbarButton id={51}
                                             icon={ImagePath.getButtonUrl() + "distribute_horizontal.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.distribute_horizontal),
                                                 this.context.translate(translations.tooltip.distribute_horizontal))}
                                             onToggleButton={() => this.props.onDistribute?.(WDElementDistributeMode.horizontal)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && this.props.elementsOnSameSheet}
                                             pressed={false}
                             />
                        </li>
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                        <li className={"ws-designer-toolbar-item"}>
                            <WDToolbarButton id={52}
                                             icon={ImagePath.getButtonUrl() + "distribute_vertical.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.distribute_vertical),
                                                 this.context.translate(translations.tooltip.distribute_vertical))}
                                             onToggleButton={() => this.props.onDistribute?.(WDElementDistributeMode.vertical)}
                                             arrow={false}
                                             enabled={!this.props.isReadonly && this.props.elementsOnSameSheet}
                                             pressed={false}
                            />
                        </li>
                    </div>
                </div>
            }

            {this.props.showColorOptions &&
            <WDToolbarSectionObjectColor
                activeId={this.state.activeId}
                isReadonly={this.props.isReadonly}
                showBorderPadding={false}
                elementProps={this.props.elementProps}
                onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                onToolbarAction={this.props.onToolbarAction!}
                onChangeActive={this.props.onChangeActive!}
                onChangeBorder={this.props.onChangeBorder!}
            />
            }

            {(this.props.onDuplicate || this.props.onChangeGroupingStatus || this.props.onChangeLockingStatus || this.props.onElementDeleted) &&
            <WDToolbarButtonSeparator />
            }

            <div className={"ws-designer-toolbar-section"}>
                <div className={"ws-designer-toolbar-row"}>
                    {this.props.onDuplicate &&
                        <WDToolbarButton id={21}
                                         enabled={!this.props.isReadonly}
                                         icon={ImagePath.getButtonUrl() + "duplicate.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.duplicate),
                                             this.context.translate(translations.tooltip.duplicate)
                                         )}
                                         arrow={false}
                                         onToggleButton={() => this.props.onDuplicate!()}
                                         pressed={false} />
                    }
                    {this.props.onCut &&
                        <WDToolbarButton id={22}
                                         enabled={!this.props.isReadonly}
                                         icon={ImagePath.getButtonUrl() + "cut.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.cut),
                                             this.context.translate(translations.tooltip.cut)
                                         )}
                                         arrow={false}
                                         onToggleButton={() => this.props.onCut!()}
                                         pressed={false} />
                    }
                    {this.props.onChangeGroupingStatus &&
                        <WDToolbarButtonGroup id={31}
                                              grouped={this.props.grouped || false}
                                              clickable={!this.props.isReadonly && this.props.elementsOnSameSheet}
                                              onChangeGroupingStatus={this.props.onChangeGroupingStatus}
                                              onToggleButton={this.onToggleButton}
                        />
                    }
                </div>
                <div className={"ws-designer-toolbar-row"}>
                    {this.props.onCopy &&
                        <WDToolbarButton id={22}
                                         enabled={!this.props.isReadonly}
                                         icon={ImagePath.getButtonUrl() + "copy.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.copy),
                                             this.context.translate(translations.tooltip.copy)
                                         )}
                                         arrow={false}
                                         onToggleButton={() => this.props.onCopy!()}
                                         pressed={false} />
                    }

                    {this.props.onPaste &&
                        <WDToolbarButton id={23}
                                         enabled={!this.props.isReadonly}
                                         icon={ImagePath.getButtonUrl() + "paste.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.paste),
                                             this.context.translate(translations.tooltip.paste)
                                         )}
                                         arrow={false}
                                         onToggleButton={() => this.props.onPaste!()}
                                         pressed={false} />
                    }
                </div>
            </div>

            {(this.props.onChangeGroupingStatus || this.props.onChangeLockingStatus || this.props.onElementDeleted) &&
            <WDToolbarButtonSeparator />
            }

            <div className={"ws-designer-toolbar-section"}>
                <div className={"ws-designer-toolbar-row"}>
                    {this.props.onChangeLockingStatus &&
                        <WDToolbarButtonLock
                            id={32}
                            locked={this.props.elementProps.locked}
                            clickable={!this.props.isReadonly}
                            onChangeLockingStatus={this.props.onChangeLockingStatus}
                            onToggleButton={this.onToggleButton}/>
                    }
                </div>
                <div className={"ws-designer-toolbar-row"}>
                    {this.props.onElementDeleted &&
                        <WDToolbarButtonDelete
                            id={33}
                            pressed={false}
                            clickable={!this.props.isReadonly && this.props.context !== WSContextType.text_exercise_main && this.props.context !== WSContextType.text_exercise_child}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onElementDeleted}/>
                    }
                </div>
            </div>
        </div>
    }
}

