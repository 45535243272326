import API from "../Framework/API";
import {WDActionLogEntry} from "../Designer/ActionLog/WDActionLogEntry";

const URL = "support"

export function CreateSupportTicket(worksheetId: number, description: string, logs: WDActionLogEntry[]) {
    let object = {
        date: new Date(),
        worksheet: { id: worksheetId },
        description: description,
        items: logs
    }

    return API.Post<object, WDActionLogEntry[]>(URL + "/action-log", object)
}
