import {WDHistoryItem} from "./WDHistoryItem";
import {WorksheetItemUpdate} from "../Utils/WorksheetItemUpdate";

export class WDElementHistoryItem implements WDHistoryItem {
    updates: WorksheetItemUpdate[]

    constructor(elements: WorksheetItemUpdate[]) {
        this.updates = elements
    }
}
