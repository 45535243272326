import React, {CSSProperties} from "react";

interface IProps {
    id: string,
    width?: number,
    widthLabel?: number,
    style?: CSSProperties
    label: string,
    labelClass?: string
    description?: string
    checked: boolean,
    readonly: boolean,
    required?: boolean,

    onChange?: () => void
}

interface IState {
}

export default class CheckBoxSwitch extends React.Component<IProps, IState> {

    onChange = () => {
        this.props.onChange?.()
    }

    render() {
        let style: CSSProperties = this.props.style ? this.props.style : {}
        style.width = this.props.width

        return <div className="form-group" style={style}>
                <div className={"checkbox-group"}>
                    <label htmlFor={this.props.id} className={(this.props.labelClass || "label") + (this.props.readonly ? " label-disabled" : "")}>
                        {this.props.label}
                    </label>
                    <input type={"checkbox"}
                           className={"switch"}
                           id={this.props.id}
                           value={this.props.id}
                           disabled={this.props.readonly}
                           required={this.props.required}
                           checked={this.props.checked}
                           onChange={this.onChange}
                           style={{left: this.props.widthLabel}}
                    />
                </div>

                {this.props.description &&
                    <div className={"checkbox-description" + (this.props.readonly ? " checkbox-description-disabled" : "")}>
                        {this.props.description.split(/\n/).map((line, i) => <div key={i}>{line}&nbsp;</div>)}
                    </div>
                }
        </div>
    }
}
