export class Syllable {
    wordId?: number
    originalValue?: string
    syllabification?: string

    constructor(wordId?: number, originalValue?: string, syllabification?: string) {
        this.wordId = wordId
        this.originalValue = originalValue
        this.syllabification = syllabification
    }
}

export default Syllable
