import React from "react";
import '../assets/css/layout.min.css';
import Const from "../Framework/Const";
import {WDToolbarOrientation} from "./Toolbar/Toolbar/WDToolbar";
import {WorksheetPage} from "../_model/WorksheetPage";
import {MainContext} from "../_base/MainContext";
import {ImagePath} from "../Framework/CategoryImage";
import translations from "../Framework/translations.json";
import {WDUtils} from "./Utils/WDUtils";
import {RenderingMedia, SolutionForceMode, WSPageFormat} from "../Framework/Enums";
import {WorksheetItem} from "../_model/WorksheetItem";
import {WSContext, WSContextType} from "./Elements/WSContext";
import {WorksheetItemTypeEnum} from "../_model/WorksheetItemType";
import {WDTextbox} from "./Elements/Textbox/WDTextbox";
import {WDWritingLineature} from "./Elements/Lineature/WritingLineature/WDWritingLineature";
import {WDCalculationTriangle} from "./Elements/Math/CalculationTriangle/WDCalculationTriangle";
import {WDImage} from "./Elements/Image/WDImage";
import {WDBalloon} from "./Elements/Balloon/WDBalloon";
import {WDMathLineature} from "./Elements/Lineature/MathLineature/WDMathLineature";
import {WDCalculationTower} from "./Elements/Math/CalculationTower/WDCalculationTower";
import {WDGroup} from "./Elements/Group/WDGroup";
import {WDTable} from "./Elements/Table/WDTable";
import {SidebarElement} from "./Sidebar/Sidebar";
import {WDTextExercise} from "./Elements/TextExercise/WDTextExercise";
import {WDWritingCourse} from "./Elements/WritingCourse/WDWritingCourse";
import {WDLine} from "./Elements/Line/WDLine";
import {RESIZE_NODE} from "./Elements/Enum/WDElementContainerResizeNode";
import _ from "lodash";
import {WorksheetItemUpdate, WorksheetItemUpdateOptions} from "./Utils/WorksheetItemUpdate";
import {WDElementHistoryItem} from "./History/WDElementHistoryItem";
import {WDShape2d} from "./Elements/Shape/WDShape2d";
import {WDShape3d} from "./Elements/Shape/WDShape3d";
import {WDShapeBuildingBrick} from "./Elements/Shape/WDShapeBuildingBrick";
import {WDShapeCraftPattern} from "./Elements/Shape/WDShapeCraftPattern";
import {GetImageWithCounterpartImageInfo} from "../_endpoint/ImageEndpoint";
import Conf from "../Framework/Conf";
import Image, {ImageAlignment} from "../_model/Image";
import {Util} from "../Framework/Util";

interface IProps {
    worksheetPage: WorksheetPage
    format: WSPageFormat
    context?: WSContext
    elements?: WorksheetItem[]
    worksheetOrientation: boolean
    orientation: boolean
    solution: boolean
    isEditingAllowed: boolean
    showNonPrintableObjects: boolean
    solutionForceMode: SolutionForceMode
    inPresentationMode: boolean
    renderingMedia: RenderingMedia
    zoom: number

    onChangePageSettings: (id: number, sheet: WorksheetPage) => void
    onChangeSolution?: (pages: WorksheetPage) => void
    onPageAdd: (pageBefore: WorksheetPage, amount: number, scroll: boolean) => void
    onDropElement: (page: WorksheetPage, pageX: number, pageY: number, dataTransfer: DataTransfer) => void

    addElementToGroup: (groupKey: string, child: WorksheetItem) => void
    addElementToDesigner: (element: WorksheetItem) => void

    onElementLoaded: (id: string) => void
    onElementSelect: (id: string, resetSelection: boolean, newState: boolean) => void
    onElementEdit: (itemKey: string, editMode: boolean) => void
    onElementResize: (proportional: boolean, x: number, y: number) => void
    onElementConvert: (itemKey: string, elementType: WorksheetItemTypeEnum, data: any) => void

    deleteChildren: (itemKey: string) => void

    openSidebar: (sidebar: SidebarElement) => void
    onResizeStateChanged: (state: boolean, nodeType?: RESIZE_NODE) => void

    onUpdateElement: (update: WorksheetItemUpdate, options?: WorksheetItemUpdateOptions) => void
    onPropagateElementEvent: (event: React.UIEvent, itemKey: string) => void

    onContextMenu: (id: string, e: React.MouseEvent) => void
    updateElementData: (itemKey: string, data: string) => void

    updateHistory: (value: WDElementHistoryItem) => void
    pushHistory: (before: WorksheetItemUpdate[], after: WorksheetItemUpdate[]) => void
}

interface IState {
    toolbarOrientation?: WDToolbarOrientation
    toolbarX?: number
    toolbarY?: number
    image?: Image
}

export class WDSheet extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = ({
            toolbarX: undefined,
            toolbarY: undefined,
            toolbarOrientation: undefined
        })
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState) && _.isEqual(this.props.worksheetPage, nextProps.worksheetPage))
    }

    componentDidMount() {
        this.getBorderImage()
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.worksheetPage.borderImage !== prevProps.worksheetPage.borderImage && this.props.worksheetPage.borderImage !== this.state.image?.counterpartImage?.id) {
            this.getBorderImage()
        }
    }

    getBorderImage = () => {
        if (this.props.worksheetPage.borderImage) {
            GetImageWithCounterpartImageInfo(this.props.worksheetPage.borderImage).then(
                (image) => {
                    this.setState({image: image})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
                }
            )
        }
    }

    onDropElement = (e: React.DragEvent) => {
        this.props.onDropElement?.(this.props.worksheetPage, e.pageX, e.pageY, e.dataTransfer)
    }

    getBorderImageUrl = () : string | undefined => {
        let borderImageUrl: string | undefined = undefined

        if (this.props.worksheetPage.borderImage) {
            if (this.props.orientation && this.state.image?.alignment === ImageAlignment.portrait) {
                borderImageUrl = this.state.image.url
            } else if (!this.props.orientation && this.state.image?.alignment === ImageAlignment.landscape) {
                borderImageUrl = this.state.image.url
            } else {
                borderImageUrl = this.state.image?.counterpartImage?.url
            }
        }

        return borderImageUrl
    }

    renderDefaultBorder = (): boolean => {
        return (this.props.worksheetPage.borderImage === undefined || this.props.worksheetPage.borderImage === null)
            && this.props.showNonPrintableObjects
            && (this.props.worksheetPage.borderStyle === undefined
                || this.props.worksheetPage.borderStyle === null
                || this.props.worksheetPage.borderStyle === "none")
    }
    renderElement = (element: WorksheetItem) => {
        let props: any = WorksheetItem.getProps(element)
        props.isReadonly = !this.props.isEditingAllowed || this.props.context === WSContextType.text_exercise_child
        props.onElementSelect = this.props.onElementSelect
        props.onElementResize = this.props.onElementResize
        props.onElementEdit = this.props.onElementEdit
        props.onResizeStateChanged = this.props.onResizeStateChanged
        props.onUpdateElement = this.props.onUpdateElement
        props.onContextMenu = this.props.onContextMenu
        props.solutionForceMode = this.props.solutionForceMode
        props.showNonPrintableObjects = this.props.showNonPrintableObjects
        props.context = this.props.context
        props.updateElementData = this.props.updateElementData
        props.inPresentationMode = this.props.inPresentationMode

        props.updateHistory = this.props.updateHistory
        props.pushHistory = this.props.pushHistory

        let reactElement = <></>
        props.ref = element.ref
        if (element.worksheetItemTypeId === WorksheetItemTypeEnum.TEXTBOX) {
            props.isIndependentElement = true
            props.hasResizeOnCreate = element.resized
            props.placeholder = this.context.translate(translations.text.textbox_placeholder)
            props.resizeOptions = {showResizer: true, showError: true}
            props.onConvertElement = this.props.onElementConvert
            reactElement = React.createElement(WDTextbox, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.WRITING_LINEATURE) {
            props.isIndependentElement = true
            props.onLoadedElement = this.props.onElementLoaded
            reactElement = React.createElement(WDWritingLineature, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.CALCULATION_TRIANGLE) {
            reactElement = React.createElement(WDCalculationTriangle, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.IMAGE) {
            props.onElementLoaded = this.props.onElementLoaded
            props.renderingMedia = this.props.renderingMedia
            reactElement = React.createElement(WDImage, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.BALLOON) {
            props.borderColor = element.borderColor
            props.hasResizeOnCreate = element.resized
            reactElement = React.createElement(WDBalloon, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.MATH_LINEATURE) {
            props.onElementLoaded = this.props.onElementLoaded
            props.isIndependentElement = true
            reactElement = React.createElement(WDMathLineature, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.CALCULATION_TOWER) {
            reactElement = React.createElement(WDCalculationTower, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.GROUP) {
            props.elements = element.children
            props.renderElement = this.renderElement
            reactElement = React.createElement(WDGroup, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.TABLE) {
            props.isIndependentElement = true
            props.addElementToDesigner = this.props.addElementToDesigner
            props.addElementToGroup = this.props.addElementToGroup
            props.hasResizeOnCreate = element.resized
            reactElement = React.createElement(WDTable, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.TEXT_EXERCISE) {
            props.images = element.children
            props.renderElement = this.renderElement
            props.addElementToDesigner = this.props.addElementToDesigner
            props.addElementToGroup = this.props.addElementToGroup
            props.deleteChildren = this.props.deleteChildren

            props.onSelectImage = () => this.props.openSidebar(SidebarElement.Images)
            reactElement = React.createElement(WDTextExercise, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.WRITING_COURSE) {
            props.elements = element.children
            props.renderElement = this.renderElement
            props.addElementToGroup = this.props.addElementToGroup
            props.deleteChildren = this.props.deleteChildren
            reactElement = React.createElement(WDWritingCourse, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.SHAPE2D) {
            props.border = WorksheetItem.getElementBorder(element)
            reactElement = React.createElement(WDShape2d, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.SHAPE3D) {
            props.border = WorksheetItem.getElementBorder(element)
            reactElement = React.createElement(WDShape3d, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.SHAPE_BUILDING_BRICK) {
            reactElement = React.createElement(WDShapeBuildingBrick, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.SHAPE_CRAFT_PATTERN) {
            props.border = WorksheetItem.getElementBorder(element)
            reactElement = React.createElement(WDShapeCraftPattern, props, null)

        } else if (element.worksheetItemTypeId === WorksheetItemTypeEnum.LINE) {
            props.propagateEvent = this.props.onPropagateElementEvent
            reactElement = React.createElement(WDLine, props, null)
        }

        let size = WorksheetItem.getElementSize(element)
        // #4977 Keep z-index in the container even when element is selected
        let elementContainerStyle: React.CSSProperties = {
            left: size.left,
            top: size.top,
            height: size.height,
            width: size.width,
            zIndex: (element.edited ? WDUtils.getMaxPosZOfElements(this.props.elements) + 1 : element.posZ)
        }

        return <div id={element.itemKey + "-container"}
                    className={"ws-designer-element-container"}
                    key={element.itemKey}
                    style={elementContainerStyle}
                    onDragOver={e => e.preventDefault()}
                    onDragEnter={e => e.preventDefault()}
                    onDragLeave={e => e.preventDefault()}
        >
            {reactElement}
        </div>
    }

    render() {
        let width = WDUtils.getWorksheetPageWidth(this.props.format, this.props.orientation)
        let height = WDUtils.getWorksheetPageHeight(this.props.format, this.props.orientation)
        const pageWidth = width
        const pageHeight = height

        const sheetId = WorksheetPage.getUniqueElementIdentifier(this.props.worksheetPage)
        const sheetClassname = this.props.solution ? "ws-designer-sheet ws-designer-sheet-solution" : "ws-designer-sheet"

        let borderImageUrl = this.getBorderImageUrl()
        let borderOpacity = this.props.worksheetPage.borderTransparency ? 1 - this.props.worksheetPage.borderTransparency / 100 : undefined
        let borderColorClass = this.props.worksheetPage.borderColor ? "svg-color-" + this.props.worksheetPage.borderColor.replace("#", "") : undefined

        let style: React.CSSProperties = {
            width: width,
            height: height
        }

        // Rotate landscape page when printing
        if (this.props.renderingMedia === RenderingMedia.print) {
            style.margin = 0
            style.boxShadow = "unset"
            if (this.props.worksheetOrientation && !this.props.orientation) {
                width = WDUtils.getWorksheetPageWidth(this.props.format, !this.props.orientation)
                height = WDUtils.getWorksheetPageHeight(this.props.format, !this.props.orientation)

                style.transform = "rotate(-90deg)"
                style.transformOrigin = "left top"
                style.marginTop = height + "px"
                style.marginBottom = -width + "px"
            } else if (!this.props.worksheetOrientation && this.props.orientation) {
                width = WDUtils.getWorksheetPageWidth(this.props.format, !this.props.orientation)
                height = WDUtils.getWorksheetPageHeight(this.props.format, !this.props.orientation)
                style.width = height
                style.height = width

                style.transform = "rotate(-90deg)"
                style.transformOrigin = "left top"
                style.marginTop = height + "px"
                style.marginBottom = -width + "px"
            }
        }

        let sheet = <div id={sheetId} className={sheetClassname}
                         style={style}
                         onDragOver={e => e.preventDefault()}
                         onDragEnter={e => e.preventDefault()}
                         onDragLeave={e => e.preventDefault()}
        >
            {this.props.solution && this.props.showNonPrintableObjects &&
                <div
                    className={"ws-designer-sheet-solution-text"}>{this.context.translate(translations.text.solution_sheet)}</div>
            }

            <canvas id={sheetId + "-canvas"} className={"ws-designer-sheet-canvas"} width={width}
                    height={height}></canvas>

            {this.props.worksheetPage.borderImage && borderImageUrl &&
                <img id="ws-designer-sheet-border-image"
                     className={"ws-designer-sheet-page-border-image print " + borderColorClass}
                     style={{
                         margin: (this.props.worksheetPage.borderLeft) + "px",
                         width: "calc(100% - " + (this.props.worksheetPage.borderLeft * 2) + "px)",
                         height: "calc(100% - " + (this.props.worksheetPage.borderLeft * 2) + "px)",
                         opacity: borderOpacity
                     }}
                     src={Conf.IMAGE_URL() + borderImageUrl}
                     alt={this.context.translate(translations.text.frame)}
                     draggable={false}
                     onContextMenu={(e) => e.preventDefault()}
                />
            }

            {/* Show red border - just for positioning */}
            {this.renderDefaultBorder() &&
                <div id="ws-designer-sheet-page-border" className="ws-designer-sheet-page-border"
                     style={{
                         left: Const.WorksheetPageBorderPX,
                         right: Const.WorksheetPageBorderPX,
                         top: Const.WorksheetPageBorderPX,
                         bottom: Const.WorksheetPageBorderPX,
                         borderStyle: "dotted"
                     }}
                />
            }

            {/* Show default border - just for positioning */}
            {this.renderDefaultBorder() &&
                <div id="ws-designer-sheet-design-border" className="ws-designer-sheet-design-border"
                     style={{
                         left: (this.props.worksheetPage.borderLeft) + "px",
                         right: (this.props.worksheetPage.borderRight) + "px",
                         top: (this.props.worksheetPage.borderTop) + "px",
                         bottom: (this.props.worksheetPage.borderBottom) + "px",
                         borderStyle: "dashed"
                     }}>
                </div>
            }

            {/* Show user border */}
            {!this.props.worksheetPage.borderImage
                && this.props.worksheetPage.borderStyle !== undefined
                && this.props.worksheetPage.borderStyle !== null
                && this.props.worksheetPage.borderStyle !== "transparent" &&

                <div id="ws-designer-sheet-design-border" className="ws-designer-sheet-design-border print"
                     style={{
                         left: (this.props.worksheetPage.borderLeft) + "px",
                         right: (this.props.worksheetPage.borderRight) + "px",
                         top: (this.props.worksheetPage.borderTop) + "px",
                         bottom: (this.props.worksheetPage.borderBottom) + "px",
                         borderStyle: this.props.worksheetPage.borderStyle,
                         borderColor: this.props.worksheetPage.borderColor,
                         borderWidth: this.props.worksheetPage.borderWeight,
                         borderRadius: this.props.worksheetPage.borderRadius + "%",
                         opacity: borderOpacity
                     }}>
                </div>
            }

            {/* Loading image until all elements are loaded when opening worksheet */}
            {this.props.elements === undefined ?
                <div id={"ws-designer-loading"} className={"ws-designer-loading"}>
                    <img className={"svg-color-primary"}
                         src={process.env.PUBLIC_URL + ImagePath.getNotificationUrl() + "loading.gif"}
                         alt={this.context.translate(translations.notification.loading)}
                         draggable={"false"}
                         onContextMenu={(e) => e.preventDefault()}
                    />
                </div>
                :
                this.props.elements
                .filter(e => !e.deleted)
                .map(element => {
                    return {
                        element: element,
                        size: WorksheetItem.getElementSize(element)
                    }
                })
                // #5108 Hide elements that are outside the page to prevent overflow issue on printing
                .filter(i => (this.props.renderingMedia !== RenderingMedia.print || (
                    (i.size.left + i.size.width > 0) && (i.size.left < pageWidth) &&
                    (i.size.top + i.size.height > 0) && (i.size.top < pageHeight)
                )))
                .map(i => this.renderElement(i.element)
            )}
        </div>

        if (this.props.renderingMedia === RenderingMedia.print) {
            return <div className={"ws-designer-sheet-print-container"}
                        style={{width: width, height: height}}>{sheet}</div>
        }

        let containerId = "ws-designer-thumbnail"
        if (this.props.worksheetPage.sort > 1) {
            containerId += WorksheetPage.getUniqueElementIdentifier(this.props.worksheetPage)
        }

        return <div
            className={"ws-designer-sheet-workspace"}
            style={{
                height: (height + 165) * this.context.getZoom(),
                minWidth: (width + 165) * this.context.getZoom(),
                paddingTop: 70 * this.context.getZoom(),
                paddingBottom: 60 * this.context.getZoom(),
            }}
            onDrop={this.onDropElement}
            onDragOver={e => e.preventDefault()}
            onDragEnter={e => e.preventDefault()}
            onDragLeave={e => e.preventDefault()}
        >
            <div id={containerId}
                 className={"ws-designer-thumbnail"}
                 style={Util.getZoomStyle(this.props.zoom)}
                 onDragOver={e => e.preventDefault()}
                 onDragEnter={e => e.preventDefault()}
                 onDragLeave={e => e.preventDefault()}
            >
                {sheet}
            </div>

            {this.props.isEditingAllowed && this.props.onPageAdd &&
                <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "page_plus.svg"}
                     className={"ws-designer-sheet-workspace-add-page"}
                     style={{
                         width: 30,
                         bottom: -15
                     }}
                     alt={this.context.translate(translations.command.add_page)}
                     onClick={() => this.props.onPageAdd(this.props.worksheetPage, 1, true)}
                />
            }
        </div>
    }
}
