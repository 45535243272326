import API from "../Framework/API";
import Country from "../_model/Country";

const URL = "metadata/countries"

export function GetAllCountriesAdmin() {
    let url = URL + "/"
    return API.Get<Country[]>(url)
}
export function GetCountry(countryId: number) {
    let url = URL + "/" + countryId
    return API.Get<Country>(url)
}
export async function CreateCountry(country: Country) {
    return API.Post<object, Country>(URL, country)
}
export async function UpdateCountry(country: Country) {
    return API.Put<object, Country>(URL + "/" + country.id, country)
}
