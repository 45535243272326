import Entity from "../../_model/Entity";

export class WritingCourseChildListItem extends Entity {
    status: string
    modifiedOn: Date
    owner: string

    constructor(id: number, name: string, status: string, owner: string, modifiedOn: Date) {
        super(name, id);

        this.owner = owner
        this.modifiedOn = modifiedOn
        this.status = status
    }
}

export default WritingCourseChildListItem
