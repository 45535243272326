import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {DropDown, DropDownItem, DropDownType} from "../../../../Components/Controls/DropDown";
import {TooltipText} from "../../../../Components/Tooltips";

export enum CalculationType {
    NONE = -1,
    ADD,
    SUBTRACT,
    DIVIDE,
    MULTIPLY,
    COMPLEMENT
}

/**
 * Button (with submenu) in Toolbar
 * Effects selected element(s)
 * Shows calculation type as dropdown (+, *, /, -)
 * Dropdown:   Recalculation with different calculation type logic should be done
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltipDisabled?: TooltipText
    type: string
    dropDownValues: DropDownItem[]
    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

export class WDToolbarButtonCalculationType extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onChangeCalculationType = (type: string) => {
        this.props.onActionButton(WDToolbarAction.CHANGE_CALCULATION_TYPE, {"calculationType": type})
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-calculation-type"}
            id={"changeCalcType"}>

            <WDToolbarButton key={"btnChangeCalcType"}
                             id={this.props.id}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.calculation_type),
                                 this.context.translate(translations.tooltip.calculation_type))}
                             tooltipDisabled={this.props.tooltipDisabled}
                             icon={ImagePath.getMathUrl() + "calculation_type.svg"}
                             onToggleButton={this.props.onToggleButton}
                             arrow={true}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed}>
            </WDToolbarButton>

            {this.props.pressed &&
                <div className="ws-designer-toolbar-submenu">
                    <label className={"ws-designer-options-section-label-normal"}>
                        {this.context.translate(translations.toolbar.calculation_type) + '\n'}
                    </label>

                    <DropDown
                        id={"calculation-type"}
                        type={DropDownType.TEXT}
                        readonly={!this.props.clickable}
                        required={false}
                        value={this.props.type}
                        width={300}
                        autocomplete={false}
                        items={this.props.dropDownValues}
                        onChangeValue={(e) => this.onChangeCalculationType(e)} />
                </div>
            }
        </li>
    }
}
