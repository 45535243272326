import Entity from "./Entity";

class User extends Entity {
    firstname: string
    lastname: string
    email: string

    constructor(id: number, email: string, firstname: string, lastname: string) {
        super(lastname, id);
        this.firstname = firstname
        this.lastname = lastname
        this.email = email
    }

    static getFullName (user?: User) {
        if (user) {
            return user.firstname + " " + user.lastname
        }
        return ""
    }
}

export default User
