import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButtonGraphics} from "../General/WDToolbarButtonGraphics";
import {TooltipText} from "../../../../Components/Tooltips";
import {GraphicOptionSettings} from "../../General/GraphicOptionSettings";

/**
 * Button (Yes/No) in Text Toolbar
 * Effects selected text of an element
 * Yes: marked text should get the text decoration given as command
 *      by now all entered text should get bold
 * No:  marked text should get normal
 *      by now all entered text should get normal
 */

interface IProps {
    id: number
    clickable: boolean
    icon: string
    tooltip: TooltipText
    options: GraphicOptionSettings

    command: string
    action: WDToolbarAction

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    pressed: boolean
}

export class WDToolbarButtonList extends React.Component<IProps, IState> {

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            pressed: false,
        }
    }

    componentDidMount() {
        document.addEventListener('selectionchange', this.onSelectionChange, false);
    }
    componentWillUnmount() {
        document.removeEventListener('selectionchange', this.onSelectionChange, false);
    }

    onSelectionChange = () => {
        this.props.onToggleButton(-1)

        this.setState({
            pressed: document.queryCommandState(this.props.command)
        })
    }

    onToggle = () => {
        this.props.onActionButton(this.props.action, {command: this.props.command})
    }
    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }
    onChangeGraphic = (key: string) => {
        this.props.onActionButton(
            this.props.options.action, {command: this.props.command, key: key}
        )
    }

    render() {
        return <div
            key={"ws-designer-toolbar-button-" + this.props.command}
            id={this.props.command}
            className={"ws-designer-toolbar-item"}
        >

            { /* Icon of list command */}
            <WDToolbarButton id={this.props.id}
                             tooltip={this.props.tooltip}
                             icon={this.props.icon}
                             iconClassSelected={"ws-designer-toolbar-button-img"}
                             iconClassUnselected={"ws-designer-toolbar-button-img"}
                             arrow={false}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             enabled={this.props.clickable}
                             pressed={this.state.pressed}
                             onToggleButton={this.onToggle}
            />

            { /* DropDown arrow to change graphic */}
            <WDToolbarButtonGraphics
                id={this.props.id}
                pressed={this.props.options.open}
                clickable={this.props.clickable}
                tooltip={this.props.tooltip}
                imageCategory={[this.props.options.imageCategory]}
                imagePath={this.props.options.imagePath}
                imageWidth={this.props.options.itemWidth}
                imageHeight={this.props.options.itemHeight}
                iconClassSelected={"ws-designer-toolbar-button-options"}
                iconClassUnselected={"ws-designer-toolbar-button-options"}
                submenuWidth={this.props.options.width}
                submenuHeight={this.props.options.height}
                onToggleButton={this.onToggleList}
                onClickItem={this.onChangeGraphic}
            />
        </div>
    }
}
