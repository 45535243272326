import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Marks an element as deleted - element should not be visible anymore
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    onActionButton: (action: WDToolbarAction) => void
    onToggleButton: (id: number) => void
}

export class WDToolbarButtonDelete extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    delete = () => {
        this.props.onActionButton(WDToolbarAction.DELETE)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-delete"}
            id={"deleteElement"}>

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + "clear.svg"}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.delete),
                                 this.context.translate(translations.tooltip.delete)
                             )}
                             arrow={false}
                             onToggleButton={this.delete}
                             pressed={this.props.pressed} />
        </li>
    }
}
