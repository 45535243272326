import React from 'react';
import {GetAllWordsAdmin, UpdateWord,} from "../../_endpoint/WordEndpoint";
import {ColumnDefinition} from "../../Components/List/List";
import {Resource} from "../../Framework/Util";
import translations from "../../Framework/translations.json";
import {
    AdminObjectType,
    ColumnRenderOption,
    EnumToEntityArray,
    EnumToListFilterDefinitionItem,
    Status
} from "../../Framework/Enums";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import Word, {
    getSyllableString,
    getSynonymString,
    getWordLinkString,
    WordDefinition
} from "../../_model/Dictionary/Word";
import WordListItem from "./WordListItem";
import User from "../../_model/User";
import Const from "../../Framework/Const";
import {ListFilterDefinition, ListFilterDefinitionItem} from "../../Components/List/ListFilter";
import Entity from "../../_model/Entity";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}

interface IState {
    items: Word[]
    listItems?: WordListItem[]
    filterDefinition: ListFilterDefinition[]
}

export default class WordList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.name), "name", 10, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.word.wordDefinition), "wordDefinition", 10, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.word.syllable), "syllable", 9, true, false),
        new ColumnDefinition(this.context.translate(translations.fields.word.synonyms), "synonyms", 18, true, false),
        new ColumnDefinition(this.context.translate(translations.fields.word.word_links), "wordLinks", 18, true, false),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 8, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 9, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 10, false, true, ColumnRenderOption.DateTime)
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            filterDefinition: this.getFilterDefinition()
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    getFilterDefinition = (): ListFilterDefinition[] => {
        let filterDefinition: ListFilterDefinition[] = []

        let wordDefinitionValues: ListFilterDefinitionItem[] = EnumToListFilterDefinitionItem(WordDefinition, Object.values(WordDefinition), translations.enum.wordDefinition, this.context.translate)
        wordDefinitionValues.unshift(new ListFilterDefinitionItem(-1, this.context.translate(translations.fields.filter.all_word_definition)))

        filterDefinition.push(new ListFilterDefinition(
            "wordDefinition",
            wordDefinitionValues,
            this.onFilterWordDefinition,
            -1))

        return filterDefinition
    }

    fetchData = () => {
        this.setState({items: []})

        GetAllWordsAdmin().then(
            (itemData) => {
                const listItems = itemData.map(item => {
                    // Enums (translated)
                    let status = translations.enum.status[item.status]

                    let wordDefinition: Resource | null = null
                    if (item.wordDefinition) {
                        wordDefinition = translations.enum.wordDefinition[item.wordDefinition]
                    }

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    let createdBy = ""
                    if (item.createdBy) {
                        createdBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    return new WordListItem(
                        item.id!,
                        item.name,
                        getSyllableString(item),
                        getSynonymString(item),
                        owner,
                        item.modifiedOn || item.createdOn!,
                        wordDefinition !== null ? this.context.translate(wordDefinition) : "",
                        getWordLinkString(item),
                        this.context.translate(status),
                        createdBy
                    )
                })

                this.setState({items: itemData, listItems: listItems})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    onSetStatus = async (item: number, status: Status, user?: User) => {
        try {
            let word = this.state.items.find(d => d.id === item)

            if (word && word.status !== status) {
                Word.setStatus(word, status, user)
                await UpdateWord(word)

                return true
            }
        } catch (e) {
        }

        return false
    }
    onRefresh = () => {
        this.fetchData()
    }

    onFilterWordDefinition = (items: Entity[], value: number): Entity[] => {
        let wordDefinitionArray = EnumToEntityArray(WordDefinition, translations.enum.wordDefinition, this.context.translate)
        let definition = wordDefinitionArray.find(d => d.id === value)

        if (definition) {
            return items.filter(item => item["wordDefinition"] === definition!.name)
        }

        return items
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              newItemUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.word) + Const.CreateNewDataUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.word) + "{0}"}
                              objectType={AdminObjectType.word}
                              onSetStatus={this.onSetStatus}
                              onRefresh={this.onRefresh}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              showActivate={true}
                              showDeactivate={true}
                              paginationOptions={{showPagination: true}}
                              searchOptions={{showSearch: true, typeAheadSearch: true, showSearchOptions: false}}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "name",
                                  sortDescending: false
                              }}
                              filterOptions={{
                                  externalFilter: false,
                                  filterDefinitions: this.state.filterDefinition
                              }}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}
