import {GlyphType} from "../Designer/Toolbar/Button/Text/WDToolbarButtonInsertGlyph";

class Glyph {
    code: string
    type: GlyphType
    positioning: WDMathLineaturePositioning

    constructor(code: string, type: GlyphType, positioning: WDMathLineaturePositioning) {
        this.code = code
        this.type = type
        this.positioning = positioning
    }
}
export enum WDMathLineaturePositioning {
    left = "left",
    middle = "middle",
    right = "right"
}

export class GlyphData {

    // Convert 5 digit Unicode to hex:
    // http://www.russellcottrell.com/greek/utilities/SurrogatePairCalculator.htm
    static glyphData: Glyph[] = [
        // Operators
        new Glyph("\u002B", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2212", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\uE013", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2022", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u00B1", GlyphType.math, WDMathLineaturePositioning.middle),    //+-
        new Glyph("\u00B7", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u00D7", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u00F7", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u002A", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u002E", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u002C", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u002D", GlyphType.math, WDMathLineaturePositioning.middle),     // -
        new Glyph("\u2013", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2014", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u0021", GlyphType.math, WDMathLineaturePositioning.middle),     // !
        new Glyph("\u003F", GlyphType.math, WDMathLineaturePositioning.middle),     // ?
        new Glyph("\u0026", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u0023", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2044", GlyphType.math, WDMathLineaturePositioning.middle),     // /
        new Glyph("\u007C", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u005C", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u007E", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u003A", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u003B", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2212", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u005F", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u005E", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u02C6", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u02C7", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u005E", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u00B0", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u02DC", GlyphType.math, WDMathLineaturePositioning.middle),

        // Quotes
        new Glyph("\u0027", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u0060", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u0022", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u201E", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u201C", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u201D", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2026", GlyphType.math, WDMathLineaturePositioning.middle),

        // Brackets
        new Glyph("\u007B", GlyphType.math, WDMathLineaturePositioning.left),
        new Glyph("\u0028", GlyphType.math, WDMathLineaturePositioning.left),
        new Glyph("\u0029", GlyphType.math, WDMathLineaturePositioning.right),
        new Glyph("\u005B", GlyphType.math, WDMathLineaturePositioning.left),
        new Glyph("\u005D", GlyphType.math, WDMathLineaturePositioning.right),
        new Glyph("\u007D", GlyphType.math, WDMathLineaturePositioning.left),

        // Equality signs
        new Glyph("\u003C", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2264", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u003D", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2260", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2248", GlyphType.math, WDMathLineaturePositioning.right),
        new Glyph("\u2265", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u003E", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u0025", GlyphType.math, WDMathLineaturePositioning.middle),    // %
        new Glyph("\u2030", GlyphType.math, WDMathLineaturePositioning.middle),

        // Other math signs
        new Glyph("\u221A", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2211", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u221E", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u03B1", GlyphType.math, WDMathLineaturePositioning.middle),    // Alpha
        new Glyph("\u03B2", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u03B3", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u03B4", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u03C0", GlyphType.math, WDMathLineaturePositioning.middle),    // Pi
        new Glyph("\u00BF", GlyphType.math, WDMathLineaturePositioning.middle),

        new Glyph("\u2190", GlyphType.math, WDMathLineaturePositioning.middle),    // Arrows
        new Glyph("\u2191", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2193", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2192", GlyphType.math, WDMathLineaturePositioning.middle),

        new Glyph("\u2039", GlyphType.math, WDMathLineaturePositioning.middle),    // sharp brackets
        new Glyph("\u00AB", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u00BB", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u203A", GlyphType.math, WDMathLineaturePositioning.middle),

        new Glyph("\u20AC", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u0024", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u00A3", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u0040", GlyphType.math, WDMathLineaturePositioning.middle),     // @
        new Glyph("\u2122", GlyphType.math, WDMathLineaturePositioning.middle),     // tm
        new Glyph("\u00AE", GlyphType.math, WDMathLineaturePositioning.middle),     // r
        new Glyph("\u00A9", GlyphType.math, WDMathLineaturePositioning.middle),     // c
        new Glyph("\u25CB", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2295", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2296", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2713", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2717", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2115", GlyphType.math, WDMathLineaturePositioning.middle),     // N
        new Glyph("\u211A", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u211D", GlyphType.math, WDMathLineaturePositioning.middle),
        new Glyph("\u2124", GlyphType.math, WDMathLineaturePositioning.middle),

        // Fractions
        new Glyph("\u00BD", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 1/2
        new Glyph("\uE000", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u2153", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 1/3
        new Glyph("\u2154", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE001", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u00BC", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 1/4
        new Glyph("\uE002", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u00BE", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE003", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u2155", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 1/5
        new Glyph("\u2156", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u2157", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u2158", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE004", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u2159", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 1/6
        new Glyph("\uE005", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 2/6
        new Glyph("\uE012", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 3/6
        new Glyph("\uE006", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 4/6
        new Glyph("\u215A", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 5/6
        new Glyph("\uE007", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 6/6
        new Glyph("\u2150", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 1/7
        new Glyph("\uE008", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE009", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE00A", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE00B", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE00C", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE00D", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u215B", GlyphType.fractions, WDMathLineaturePositioning.middle),    // 1/8
        new Glyph("\uE00E", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u215C", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE00F", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u215D", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE010", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\u215E", GlyphType.fractions, WDMathLineaturePositioning.middle),
        new Glyph("\uE011", GlyphType.fractions, WDMathLineaturePositioning.middle),

        // A-Z
        new Glyph("\u0041", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0042", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0043", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0044", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0045", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0046", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0047", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0048", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0049", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u004A", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u004B", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u004C", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u004D", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u004E", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u004F", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0050", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0051", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0052", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0053", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0054", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0055", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0056", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0057", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0058", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0059", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u005A", GlyphType.letters, WDMathLineaturePositioning.middle),  // Z

        new Glyph("\u00C4", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00D6", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00DC", GlyphType.letters, WDMathLineaturePositioning.middle),

        new Glyph("\u00C0", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00C1", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00C2", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00C3", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00D0", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00C8", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00C9", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00CA", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00CB", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00CC", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00CD", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00CE", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00CF", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00D2", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00D3", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00D4", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00D5", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00D9", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00DA", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00DB", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00D8", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00D1", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0160", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0178", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00DD", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u017D", GlyphType.letters, WDMathLineaturePositioning.middle),

        // a-z
        new Glyph("\u0061", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0062", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0063", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0064", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0065", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0066", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0067", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0068", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0069", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u006A", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u006B", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u006C", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u006D", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u006E", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u006F", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0070", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0071", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0072", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0073", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0074", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0075", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0076", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0077", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0078", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0079", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u007A", GlyphType.letters, WDMathLineaturePositioning.middle),  // z

        new Glyph("\u00E4", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00F6", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00FC", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00DF", GlyphType.letters, WDMathLineaturePositioning.middle),

        new Glyph("\u00E0", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00E1", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00E2", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00E3", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00EA", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00EB", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00E8", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00E9", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0192", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00A1", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0131", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00EE", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00EF", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00ED", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00EC", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00F2", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00F3", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00F4", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00F5", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00F1", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u0161", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00F9", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00FA", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00FB", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00FD", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u00FF", GlyphType.letters, WDMathLineaturePositioning.middle),
        new Glyph("\u017E", GlyphType.letters, WDMathLineaturePositioning.middle),

        // Numbers
        new Glyph("\u0030", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u0031", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u0032", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u0033", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u0034", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u0035", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u0036", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u0037", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u0038", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u0039", GlyphType.numbers, WDMathLineaturePositioning.middle),
        new Glyph("\u00B9", GlyphType.numbers, WDMathLineaturePositioning.right),
        new Glyph("\u00B2", GlyphType.numbers, WDMathLineaturePositioning.right),
        new Glyph("\u00B3", GlyphType.numbers, WDMathLineaturePositioning.right),

        // Smileys
        new Glyph("\u263A", GlyphType.smileys, WDMathLineaturePositioning.middle),
        new Glyph("\uD83D\uDE42", GlyphType.smileys, WDMathLineaturePositioning.middle),
        new Glyph("\uD83D\uDE10", GlyphType.smileys, WDMathLineaturePositioning.middle),
        new Glyph("\uD83D\uDE2C", GlyphType.smileys, WDMathLineaturePositioning.middle),
        new Glyph("\u2639", GlyphType.smileys, WDMathLineaturePositioning.middle),
    ]

    static getGlyphCodesByPositioning = (positioning: WDMathLineaturePositioning) : string => {
        let glyphValueString = ""
        GlyphData.glyphData
            .filter(glyph => glyph.positioning === positioning)
            .forEach(glyph => glyphValueString = glyphValueString.concat(glyph.code))

        return glyphValueString
    }

    static getGlyphPositionByCode = (code: string) : WDMathLineaturePositioning => {
        return GlyphData.glyphData.filter(glyph => glyph.code === code)[0].positioning
    }
}
