import {MenuItem} from "../_model/MenuItem";
import {useEffect, useState} from "react";
import API from "../Framework/API";
import {MenuContext, MenuExerciseType, MenuType} from "../Components/Menu/Menu";

const URL = "menu"

export function GetMenuGroups(menuType: MenuType, menuContext: MenuContext, groupItem?: MenuItem) {
    return GetMenuGroupsByParentId(menuType, menuContext, groupItem?.id)
}
export function GetMenuGroupsByParentId(menuType: MenuType, menuContext: MenuContext, groupItemId?: number) {
    let url = URL + "/" + menuType;

    if(groupItemId != null) {
        url += "?parentId=" + groupItemId;

        if(menuContext !== MenuContext.toolbox) {
            url += "&menuContext=" + menuContext
        }
    } else if(menuContext !== MenuContext.toolbox) {
        url += "?menuContext=" + menuContext
    }

    return API.Get<MenuItem[]>(url)
}

export function GetMenuItem(parentItemId: number, menuType: MenuType) {
    const [item, setItem] = useState<MenuItem>();
    const [error, setError] = useState<string>();

    let url = URL + "/" + menuType + "/" + parentItemId;

    useEffect(() => {
        API.Get<MenuItem>(url).then(
            (result) => {
                setItem(result);
            },
            (error) => {
                setError(error.message);
            }
        )
    })

    return { item, error };
}

export function GetToolboxItemsBySubject(subjectId: number) {
    let url = URL + "/toolbox/subject/" + subjectId;
    return API.Get<MenuItem[]>(url)
}
export function GetThirdLayerToolboxItemsByExerciseType(exerciseType: MenuExerciseType) {
    let url = URL + "/toolbox/third-layer?exerciseType=" + exerciseType;
    return API.Get<MenuItem[]>(url)
}
