import React from "react";
import Image from "../../_model/Image";
import Conf from "../../Framework/Conf";
import {SidebarContentDragElementType} from "../../Designer/Sidebar/SidebarContentBase";
import {Util} from "../../Framework/Util";

interface IProps {
    images: Image[]
    currentImageId?: number

    onClickImage?: (image: Image) => void
    onDragStart?: (event: React.DragEvent, dragElementType: SidebarContentDragElementType, imageElementId: string, image: Image) => void
}

export class ImageSmallListItem extends React.Component<IProps> {

    onDragStart = (event: React.DragEvent, image: Image, imageElementId: string) => {
        this.props.onDragStart?.(event, SidebarContentDragElementType.IMAGE, imageElementId, image)
    }

    render() {
        return <div className={"image-family-images"}>
                {this.props.images
                    .filter(i => i.url !== null)
                    .map(i => {
                        return <div className={i.id === this.props.currentImageId ? "image-family-images-item-active" : "image-family-images-item"} key={i.id}
                                    onClick={() => this.props.onClickImage?.(i)}>
                            <img id={"image-family-item-" + i.id} src={Conf.IMAGE_URL() + Image.getThumbnailUrl(i)} alt={i.name}
                                 style={{height: "60px", width: Util.calculateProportionalImageThumbWidth(i, 60)}}
                                 onDragStart={event => this.onDragStart(event, i, "image-family-item-" + i.id)}
                                 onContextMenu={(e) => e.preventDefault() }
                            />
                        </div>
                    }
                )}
            </div>
    }
}
