import {AdminObjectType} from "../../Framework/Enums";

export class AdminUtils {
    static getObjectTypeUrl(objectType: AdminObjectType): string {
        switch (objectType) {
            case AdminObjectType.name: return "/maintenance/names/"
            case AdminObjectType.rule: return "/maintenance/rules/"
            case AdminObjectType.exercise: return "/maintenance/exercises/"
            case AdminObjectType.exercise_pattern: return "/maintenance/exercise-pattern/"
            case AdminObjectType.text_exercise_main: return "/maintenance/text-exercises/"
            case AdminObjectType.text_exercise_child: return "/maintenance/text-exercises/"
            case AdminObjectType.writing_course_main: return "/maintenance/writing-course/"
            case AdminObjectType.writing_course_child: return "/maintenance/writing-course/"
            case AdminObjectType.image: return "/maintenance/images/"
            case AdminObjectType.word: return "/maintenance/dictionary/"
            case AdminObjectType.country: return "/maintenance/countries/"
            case AdminObjectType.search_result: return "/maintenance/search-results/"
            case AdminObjectType.marketplace_worksheet: return "/maintenance/marketplace-worksheets/"
            case AdminObjectType.sales_material: return "/maintenance/marketplace-sales-material/"
        }

        return ""
    }
}
