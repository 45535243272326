import React from 'react';
import Entity from "../../_model/Entity";
import {ColumnDefinition} from "../../Components/List/List";
import AppHeader from "../../_base/AppHeader";
import {Menu, MenuContext, MenuType} from "../../Components/Menu/Menu";
import {MainContext} from "../../_base/MainContext";
import DataList from "../../Components/List/DataList";
import {CardDefinition} from "../../Components/List/Card";
import User from "../../_model/User";
import {AdminObjectType, EnumToEntityArray, Status} from "../../Framework/Enums";
import Note from "../../_model/Note";
import {CreateNote} from "../../_endpoint/NoteEndpoint";
import {Notification} from "../../Components/Notification/NotificationHandler";
import translations from "../../Framework/translations.json";
import {GetAllAdmins} from "../../_endpoint/UserEndpoint";
import {ListFilterDefinition, ListFilterDefinitionItem} from "../../Components/List/ListFilter";
import {RibbonButtonData} from "../../Components/Ribbon/RibbonButtonData";
import {ImagePath} from "../../Framework/CategoryImage";
import {RouteComponentProps} from "react-router-dom";
import ListPaginationOptions from "../../Components/List/ListPaginationOptions";
import {ListSearchOptions} from "../../Components/List/ListSearchOptions";
import {ListSortOptions} from "../../Components/List/ListSortOptions";
import ListFilterOptions from "../../Components/List/ListFilterOptions";

interface IProps extends RouteComponentProps {
    items?: Entity[]
    definitions: ColumnDefinition[]
    cardDefinition?: CardDefinition
    newItemUrl?: string
    redirectUrl: string
    redirectTarget?: string
    pageSize?: number
    objectType: AdminObjectType
    customRibbonButtons?: RibbonButtonData[]

    paginationOptions: ListPaginationOptions
    filterOptions: ListFilterOptions

    allowStatusChangeToUserItself: boolean
    allowMultiSelect: boolean

    onCopyItem?: (items: number[]) => void
    onSetStatus?: (id: number, status: Status, user?: User) => Promise<boolean>
    onRefresh: () => void

    searchOptions: ListSearchOptions
    sortOptions: ListSortOptions

    showActivate: boolean
    showDeactivate: boolean

    renderHeader?: () => void
}

interface IState {
    admins?: User[]
    listFilter: ListFilterDefinition[]
}

export default class AdminDataList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            listFilter: [],
        }
    }

    componentDidMount() {
        GetAllAdmins().then((result) => {
            this.setState({admins: result}, this.setFilter)
        }, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if((prevProps.filterOptions.filterDefinitions === null && this.props.filterOptions.filterDefinitions != null)
            || (prevProps.filterOptions.filterDefinitions?.length !== this.props.filterOptions.filterDefinitions?.length)) {
            this.setFilter()
        }
    }

    setFilter = () => {
        let filter: ListFilterDefinition[] = []

        // Set specific filters for admin area
        if(this.props.filterOptions.filterDefinitions) {
            for(let filterItem of this.props.filterOptions.filterDefinitions) {
                filter.push(filterItem)
            }
        }

        // End method if default filters should not be added
        if(this.props.filterOptions.removeDefaultFilter) {
            this.setState({listFilter: filter})
            return;
        }

        // Prepare filter list for status
        let status = [ new ListFilterDefinitionItem(-1,this.context.translate(translations.fields.filter.all_status))]
        for (const item of Object.values(Status)) {
            const i = Object.values(Status).indexOf(item);
            status.push( new ListFilterDefinitionItem(i, this.context.translate(translations.enum.status[item])))
            }
        filter.push(new ListFilterDefinition("status", status, this.onFilterStatus, -1))

        // Prepare owner for filter
        if (this.state.admins) {
            let creatorList = [
                new ListFilterDefinitionItem(-1, this.context.translate(translations.fields.filter.all_created_by)),
                ...this.state.admins.map(s => new ListFilterDefinitionItem(s.id!, s.firstname + " " + s.lastname))
            ]
            filter.push(new ListFilterDefinition("createdBy", creatorList, (items, value) => this.onFilterUser(items, value,"createdBy"), -1))

            let ownerList = [
                new ListFilterDefinitionItem(-1, this.context.translate(translations.fields.filter.all_owner)),
                ...this.state.admins.map(s => new ListFilterDefinitionItem(s.id!, s.firstname + " " + s.lastname))
            ]
            filter.push(new ListFilterDefinition("owner", ownerList, (items, value) => this.onFilterUser(items, value,"owner"), -1))
        }

        this.setState({listFilter: filter})
    }
    onSetStatus = async(items: number[], status: Status, user?: User, title?: string, comment?: string) => {
        if (this.props.onSetStatus === undefined) {
            return
        }

        let message = this.context.translate(translations.notification.processing_start).replace("%1%", items.length.toString())
        let notification = Notification.handleLoading(message)

        try {
            this.context.setNotification(notification)

            let updateCount = 0
            for (let i = 0; i < items.length; i++){
                const id = items[i];
                // Set state on record (populate
                const updated = await this.props.onSetStatus?.(id, status, user)

                // Create note if user and comment are provided
                if (updated && title) {
                    updateCount ++

                    let note = new Note(title, comment || "", id, this.props.objectType, true)
                    await CreateNote(note)
                }

                // Processing message
                let message: string = this.context.translate(translations.notification.processing_end)
                message = message.replace("%1%", i.toString())
                message = message.replace("%2%", items.length.toString())
                this.context.setNotification(Notification.handleLoading( message), notification.id)
            }

            // Processing result
            message = this.context.translate(translations.notification.processing_end)
            message = message.replace("%1%", updateCount.toString())
            message = message.replace("%2%", items.length.toString())

            this.context.setNotification(Notification.handleSuccess(message), notification.id)
        }
        catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error), notification.id)
        }

        this.props.onRefresh()
    }

    onFilterStatus = (items: Entity[], value: number) => {
        if (this.props.filterOptions.externalFilter) {
            // this.props.filterOptions.onFilterChange?.()
            return items
        }
        else {
            let statusArray = EnumToEntityArray(Status, translations.enum.status, this.context.translate)
            let status = statusArray.find(s => s.id === value)

            if (status) {
                return items.filter(item => item["status"] === status!.name)
            }

            // Filter deactivated items if no specific status is chosen
            return items.filter(item => item["status"] !== translations.enum.status.deactivated.de)
        }
    }
    onFilterUser = (items: Entity[], value: number, field: string) => {
        if (this.props.filterOptions.externalFilter) {
            // this.props.filterOptions.onFilterChange?.()
            return items
        }
        else {
            if (value < 0) {
                return items
            }

            return items.filter(e => {
                let admin = this.state.admins?.find(i => i.id === value)

                if (admin) {
                    return e[field] === admin.firstname + " " + admin.lastname
                }
                return false
            })
        }
    }

    render() {
        return <div className="admin-list">
            <AppHeader isAdminArea={true}/>

            <div className="admin-list-main">
                <div className="menu-container">
                    <Menu menuType={MenuType.app} menuContext={MenuContext.admin}/>
                </div>

                <DataList
                    objectType={this.props.objectType}
                    userList={false}
                    items={this.props.items}
                    definitions={this.props.definitions}
                    cardDefinition={this.props.cardDefinition}
                    cardActions={[]}
                    buttonNew={this.props.newItemUrl ? {
                        id: "new",
                        text: this.context.translate(translations.command.add),
                        sort: 10,
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "coupling.svg",
                        colorPrimary: false,
                        navigateTo: this.props.newItemUrl
                    } : undefined}
                    buttonCopy={{
                        id: "copy",
                        sort: 20,
                        text: this.context.translate(translations.command.copy),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "copy.svg",
                        colorPrimary: true
                    }}
                    buttonActivate={this.props.showActivate? {
                        id: "activate",
                        sort: 30,
                        text: this.context.translate(translations.command.activate),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "checkmark.svg",
                        colorPrimary: true
                    } : undefined}
                    buttonDeactivate={this.props.showDeactivate? {
                        id: "deactivate",
                        sort: 40,
                        text: this.context.translate(translations.command.deactivate),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "clear.svg",
                        colorPrimary: true
                    } : undefined}
                    buttonApproval={this.props.onSetStatus ? {
                        id: "approval",
                        sort: 50,
                        text: this.props.objectType === AdminObjectType.search_result ? this.context.translate(translations.command.to_verify) : this.context.translate(translations.command.approval),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "checkmark.svg",
                        colorPrimary: true
                    } : undefined}
                    redirectUrl={this.props.redirectUrl}
                    redirectTarget={this.props.redirectTarget}
                    onCopyItem={this.props.onCopyItem}
                    onSetState={this.onSetStatus}
                    paginationOptions={this.props.paginationOptions}
                    searchOptions={this.props.searchOptions}
                    sortOptions={this.props.sortOptions}
                    filterOptions={this.props.filterOptions}
                    allowStatusChangeToUserItself={this.props.allowStatusChangeToUserItself}
                    allowMultiSelect={this.props.allowMultiSelect}
                    showRowSelector={true}
                    filter={this.state.listFilter}
                    renderHeader={this.props.renderHeader}
                    customRibbonButtons={this.props.customRibbonButtons}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}
                />
            </div>
        </div>
    }
}

