import {WDHistory} from "./WDHistory";
import {WDPageHistoryItem} from "./WDPageHistoryItem";
import {WDHistoryDirection} from "./Enum/WDHistoryDirection";

export class WDPageHistory implements WDHistory {
    oldValue: WDPageHistoryItem
    newValue?: WDPageHistoryItem
    restoreCallback: (history: WDPageHistory, direction: WDHistoryDirection) => Promise<void>

    constructor(restoreCallback: (history: WDPageHistory, direction: WDHistoryDirection) => Promise<void>,
                oldValue: WDPageHistoryItem, newValue: WDPageHistoryItem) {

        this.oldValue = oldValue
        this.newValue = newValue
        this.restoreCallback = restoreCallback
    }

    restoreElements = async(direction: WDHistoryDirection) => {
        await this.restoreCallback(this, direction)
    }

    update = (newValue: WDPageHistoryItem) => {
        this.newValue = newValue
    }
}
