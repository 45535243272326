import React from "react";
import Const from "../Framework/Const";
import translations from "../Framework/translations.json";
import {MainContext} from "../_base/MainContext";
import {RouteComponentProps} from "react-router-dom";
import {LoginForm} from "./LoginForm";
import {ImagePath} from "../Framework/CategoryImage";
import Conf from "../Framework/Conf";

interface IProps extends RouteComponentProps { }
interface IState {
    slide: number
}

export class Login extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            slide: 1
        }
    }

    onPostLogin = () => {
        this.props.history.push("/dashboard")
    }

    getImageName = () => {
        let name = process.env.PUBLIC_URL + ImagePath.getLoginWallpaperUrl() + "background" + this.state.slide.toString().padStart(2, "0")
        return name + ".jpg"
    }

    onChangeCurrentSlide = (slide: number) => {
        this.setState({slide: slide})
    }

    render() {
        return <div className="login">
            <div className="login-main">
                <div className={"login-background-wrapper login-background-fade-out"}>
                    <img
                        className={"login-background"}
                        src={this.getImageName()}
                        alt={"VICIDo background"}
                        draggable={"false"}
                        onContextMenu={(e) => e.preventDefault()}
                    />

                    <div className="login-background-overlay">
                    {
                        Array.from({length: 6}, (_, i) => i + 1).map(i => {
                            return <div
                                key={"background-" + i}
                                className={"login-background-switch " + (this.state.slide === i ? "login-background-switch-active" : "")}
                                onClick={_ => this.onChangeCurrentSlide(i)}>
                            </div>
                        })
                    }
                    </div>
                </div>


                <div className="login-content">
                    <div className="login-content-form">
                        <div className={"login-content-form-information"}>
                            <img className={"login-logo"}
                                 src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "00_Logo_4c.svg"}
                                 alt={Const.ApplicationName}/>
                            <h1 className="login-greeting">{this.context.translate(translations.text.welcome)}</h1>
                            <LoginForm showLoginButton={true}
                                       onPostLogin={this.onPostLogin}
                            />
                        </div>

                        <div className={"login-content-footer"}>
                            <div className={"form-row"} style={{fontSize: "10pt", marginBottom: "5px"}}>
                                <div
                                    style={{marginRight: "5px"}}>{this.context.translate(translations.text.new_at_vicido)}</div>
                                <a href={Conf.WEBSITE_URL() + "web/signup"} target="_blank"
                                   rel="noreferrer">{this.context.translate(translations.text.register)}</a>
                            </div>
                            <div className={"form-row copyright"}>
                                <div style={{marginRight: "5px"}}>&copy;</div>
                                by VICIDo GmbH
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
