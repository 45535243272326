import React, {CSSProperties} from "react";
import {MainContext} from "../../_base/MainContext";
import translations from "../../Framework/translations.json";
import {ImagePath} from "../../Framework/CategoryImage";
import {TutorialData, TutorialStepData} from "./TutorialData";

export enum TutorialArrow {
    topLeft = "top-left",
    topRight = "top-right",
    bottomLeft = "bottom-left",
    bottomRight = "bottom-right",
}

export interface ITutorialContainerProps extends React.PropsWithChildren {
    id: string
    data: TutorialData
    arrow?: TutorialArrow

    translateX?: number
    translateY?: number
    style?: CSSProperties
}

export interface ITutorialContainerState {
}

/**
 * Render the tutorial container
 */
export class TutorialContainer extends React.Component<ITutorialContainerProps, ITutorialContainerState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onNext = () => {
        this.context.setTutorialStep(this.props.data.tutorialKey, this.props.data.currentStep + 1)
    }
    onFinish = () => {
        this.context.closeTutorial(this.props.data.tutorialKey)
    }

    replacePlaceholder = (textFragments: (string | JSX.Element)[], placeholder: string, element: JSX.Element) => {
        textFragments.forEach((item, index) => {
            if (typeof item === "string") {
                let textFrag2 = item.split(placeholder).flatMap((item2) => [item2, element]).slice(0, -1)
                textFragments.splice(index, 1, ...textFrag2)
            }
        })
        return textFragments
    }
    convertText = (text: string) => {
        let resultList: (string | JSX.Element)[] = [text]

        this.replacePlaceholder(resultList, "%plus-button%",
            <span key={"plus-button"}><b>Plus-Button</b>&nbsp;
                <img alt={this.context.translate(translations.command.add)}
                     src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "plus.svg"}
                     style={{width: 12, height: 12}}
                     draggable={"false"}
                     onContextMenu={(e) => e.preventDefault()}
                />&nbsp;
            </span>)

        this.replacePlaceholder(resultList, "%tipp%",
            <img key={"action"}
                 alt={this.context.translate(translations.text.tipp)}
                 src={process.env.PUBLIC_URL + ImagePath.getTutorialUrl() + "idea.svg"}
                 style={{
                     width: 20,
                     height: 20,
                     float: "left",
                     paddingLeft: 5,
                     marginRight: 10,
                     paddingTop: 5
                 }}
                 draggable={"false"}
                 onContextMenu={(e) => e.preventDefault()}
            />)

        this.replacePlaceholder(resultList, "%action%",
            <img key={"action"}
                 alt={this.context.translate(translations.text.action)}
                 src={process.env.PUBLIC_URL + ImagePath.getCursorUrl() + "mouse.svg"}
                 style={{
                     width: 32,
                     height: 32,
                     float: "left",
                     paddingLeft: 5,
                     marginRight: -5,
                     paddingTop: 5
                 }}
                 draggable={"false"}
                 onContextMenu={(e) => e.preventDefault()}
            />)

        return resultList
    }

    getTutorialArrowImage = () => {
        switch (this.props.arrow) {
            case TutorialArrow.topLeft:
                return "tutorial_arrow_tl.svg"
            case TutorialArrow.topRight:
                return "tutorial_arrow_tr.svg"
            case TutorialArrow.bottomLeft:
                return "tutorial_arrow_bl.svg"
            case TutorialArrow.bottomRight:
                return "tutorial_arrow_br.svg"
        }
    }

    renderButtons = (step: TutorialStepData) => {
        let steps = this.props.data.data.length

        if (step.index === steps - 1) {
            return <button className={"submit"}
                           onClick={this.onFinish}>{this.context.translate(translations.tutorial.finish)}</button>
        }
        if (step.showNextButton) {
            return <button className={"submit"}
                           onClick={this.onNext}>{this.context.translate(translations.tutorial.next)}</button>
        }
    }

    render() {
        let text = this.context.translate(translations.tutorial[this.props.data.tutorialKey + "-" + this.props.data.currentStep])
        let step = this.props.data.data[this.props.data.currentStep]

        let style: CSSProperties = this.props.style ? this.props.style : {}
        style.transform = "translate(" + (this.props.translateX || 0) + "px, " + (this.props.translateY || 0) + "px)"

        return <div id={"tutorial-" + this.props.id}
                    key={"tutorial-" + this.props.data.tutorialKey + "-" + this.props.data.currentStep}
                    className={"tutorial-container"}
                    style={style}>

            <div className={"tutorial-content"}>
                <div className={"tutorial-header"}>
                    <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "close.svg"}
                         className={"tutorial-cancel"}
                         width={12}
                         height={12}
                         alt={"Arrow"}
                         draggable={"false"}
                         onClick={this.onFinish}
                         onContextMenu={(e) => e.preventDefault()}
                    />
                </div>
                <div className={"tutorial-body"}>
                    {this.convertText(text).map((item, i) => {
                        if (typeof item === "string") {
                            return <span key={i} dangerouslySetInnerHTML={{__html: item}}></span>
                        }
                        return item
                    })}
                </div>
                <div className={"tutorial-footer"}>
                    {this.props.data.data.length > 1 &&
                        <div className={"tutorial-progress"}
                             style={{left: "calc(50% - " + this.props.data.data.length * 8 + "px)"}}>
                            {Array(this.props.data.data.length).fill(undefined).map((e, i) => {
                                return <div id={"progress-" + this.props.data.currentStep}
                                            key={"progress-" + i}
                                            className={(i === this.props.data.currentStep) ? "tutorial-progress-finished" : "tutorial-progress-open"}/>
                            })}
                        </div>
                    }

                    {this.renderButtons(step)}
                </div>
                {this.props.arrow &&
                    <div className={"tutorial-arrow-overlay tutorial-arrow-overlay-" + this.props.arrow}></div>
                }
            </div>

            {this.props.arrow &&
                <img className={"tutorial-arrow tutorial-arrow-" + this.props.arrow}
                     src={process.env.PUBLIC_URL + ImagePath.getTutorialUrl() + this.getTutorialArrowImage()}
                     alt={"Arrow"}
                     draggable={"false"}
                     onContextMenu={(e) => e.preventDefault()}
                />
            }
        </div>
    }
}
