import React from 'react';
import {CloneRule, GetAllRulesAdmin, GetRule, UpdateRule} from "../../_endpoint/RuleEndpoint";
import Rule from "../../_model/Rule";
import {ColumnDefinition} from "../../Components/List/List";
import translations from "../../Framework/translations.json";
import RuleListItem from "./RuleListItem";
import {AdminObjectType, ColumnRenderOption, Status} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import User from "../../_model/User";
import Const from "../../Framework/Const";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: Rule[]
    listItems?: RuleListItem[]
}

export default class RuleList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.title), "name", 20, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.lesson_subject), "lessonSubject", 12, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.rule.rule_type), "ruleType", 18, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.school_level), "schoolLevel", 14, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 10, false, true, ColumnRenderOption.DateTime)
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({items: []})

        GetAllRulesAdmin().then(
            (itemData) => {

                const listItems = itemData.map(item => {
                    // Status-Text (translated)
                    let status = translations.enum.status[item.status]

                    let ruleTypes = ""
                    if (item.ruleTypes) {
                        ruleTypes = item.ruleTypes
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(r => r.name).join(", ")
                    }

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    let schoolLevel = ""
                    if (item.schoolLevels) {
                        schoolLevel = item.schoolLevels
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(r => r.name).join(", ")
                    }

                    let createdBy = ""
                    if (item.createdBy) {
                        createdBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    return new RuleListItem(
                        item.id!,
                        item.name,
                        this.context.translate(status),
                        owner,
                        item.modifiedOn || item.createdOn!,
                        ruleTypes,
                        item.lessonSubject ? item.lessonSubject.name : "",
                        schoolLevel,
                        item.ruleCategory ? item.ruleCategory.name : "",
                        createdBy
                    )
                })

                this.setState({items: itemData, listItems: listItems})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    onCopyItem = async (items: number[]) => {
        try {
            for (const id of items) {
                const rule = await GetRule(id)
                if (rule && rule.status !== Status.deactivated) {

                    let clone: Rule = {...rule}
                    clone.id = undefined
                    clone.status = Status.draft

                    await CloneRule(clone, clone.name + " - " + this.context.translate(translations.text_fragment.copy))
                }
            }

            this.fetchData()
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))
        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
        }
    }

    onSetState = async(item: number, status: Status, user?: User) => {
        try {
            const rule = this.state.items.find(d => d.id === item)
            if (rule && rule.status !== status) {
                Rule.setStatus(rule, status, user)
                await UpdateRule(rule)

                return true
            }
        } catch (e) { }

        return false
    }
    onRefresh = () => {
        this.fetchData()
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              newItemUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.rule) + Const.CreateNewDataUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.rule) + "{0}"}
                              objectType={AdminObjectType.rule}
                              onCopyItem={this.onCopyItem}
                              onSetStatus={this.onSetState}
                              onRefresh={this.onRefresh}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              showActivate={true}
                              showDeactivate={true}
                              paginationOptions={{ showPagination: true }}
                              searchOptions={{ showSearch: true, typeAheadSearch: true, showSearchOptions: false }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "modifiedOn",
                                  sortDescending: true
                              }}
                              filterOptions={{ externalFilter: false }}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}
