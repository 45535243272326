import React from "react";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../../WDElementBase";
import {ResizeInfo, WDElementContainer} from "../../WDElementContainer";
import {WDCalculationValue} from "../WDCalculationValue";
import {WDToolbarAction} from "../../../Toolbar/WDToolbarAction";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../../Framework/CategoryImage";
import {CalculationType} from "../../../Toolbar/Button/Math/WDToolbarButtonCalculationType";
import translations from "../../../../Framework/translations.json";
import {Difficulty, SolutionForceMode} from "../../../../Framework/Enums";
import Const from "../../../../Framework/Const";
import {MainContext} from "../../../../_base/MainContext";
import {WDWritingLineature} from "../../Lineature/WritingLineature/WDWritingLineature";
import {Util} from "../../../../Framework/Util";
import {NumberPlacement} from "../../../Toolbar/Button/Math/WDToolbarButtonNumberPlacementDifficulty";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../../Utils/WorksheetItemUpdate";
import {WDPresentationAction} from "../../../Presentation/WDPresentationAction";
import Converter from "../../../../Framework/Converter";
import {WDActionLogCategory} from "../../../ActionLog/WDActionLogEntry";

export class WDCalculationTriangleTextData {
    fontSize: number
    color: string
    bold: boolean
    italic: boolean
    strikeThrough: boolean

    constructor(fontSize: number, color: string, bold: boolean, italic: boolean, strikeThrough: boolean) {
        this.fontSize = fontSize
        this.color = color
        this.bold = bold
        this.italic = italic
        this.strikeThrough = strikeThrough
    }
}

export class WDCalculationTriangleData extends WDElementBaseData {
    id?: number
    data: WDCalculationValue[]
    solution1: string
    solution2: string
    solution3: string
    zr: number
    maxValue: number
    minValue: number
    calculationType: CalculationType
    difficultyLevel: Difficulty
    numberPlacement: NumberPlacement
    imageKey: string
    solutionShow: boolean
    showSolutionBar: boolean
    solutionActionsPossible: boolean
    taskGenerator: boolean
    digitExceeded: boolean
    zeroCalculation: boolean
    textValue: WDCalculationTriangleTextData
    lineColor?: string

    constructor(zr: number, maxValue: number, minValue: number, calculationType: CalculationType, difficultyLevel: Difficulty, numberPlacement: NumberPlacement, data: WDCalculationValue[],
                solutionShow: boolean, showSolutionBar: boolean, solutionActionsPossible: boolean, taskGenerator: boolean, digitExceeded: boolean, zeroCalculation: boolean, image: string,
                textValue: WDCalculationTriangleTextData, lineColor?: string) {
        super()
        this.zr = zr
        this.data = data
        this.solution1 = ""
        this.solution2 = ""
        this.solution3 = ""
        this.maxValue = maxValue
        this.minValue = minValue
        this.calculationType = calculationType
        this.difficultyLevel = difficultyLevel
        this.numberPlacement = numberPlacement
        this.solutionShow = solutionShow
        this.showSolutionBar = showSolutionBar
        this.solutionActionsPossible = solutionActionsPossible
        this.taskGenerator = taskGenerator
        this.digitExceeded = digitExceeded
        this.zeroCalculation = zeroCalculation
        this.imageKey = image
        this.textValue = textValue
        this.lineColor = lineColor
    }
}

interface IProps extends WDElementBaseProps {
    data: WDCalculationTriangleData
}

interface IState extends WDElementBaseState {
}

export class WDCalculationTriangle extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    initialFontSize: number = 32

    resizeInfoTriangle: ResizeInfo =
        new ResizeInfo(
            true, true, true, true, false, false, false, false,
            250, Const.MaxElementSize, 250, Const.MaxElementSize)

    constructor(props: IProps) {
        super(props);

        let data = this.getData()
        let update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(data)})
        this.props.onUpdateElement(update,
            {actionCategory: WDActionLogCategory.content})

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef()
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getDefaultWidth = () => {
        return Converter.toMmGrid(300)
    }
    static getDefaultHeight = () => {
        return Converter.toMmGrid(300)
    }

    getMinWidth = () => {
        return Converter.toMmGrid(250)
    }
    getMinHeight = () => {
        return Converter.toMmGrid(250)
    }

    getData = () => {
        let data = this.props.data

        if (data.difficultyLevel === undefined) {
            data.difficultyLevel = Difficulty.none
        }
        if (data.zeroCalculation === undefined) {
            data.zeroCalculation = true
        }

        if (data.data.length === 0) {
            this.calculateValues(data)
        }

        // Always calculate fontsize - so it is right when dragging from menu!!
        let fontSize = this.calculateFontSize(this.props.element.width)

        if (data.textValue === undefined) {
            data.textValue = new WDCalculationTriangleTextData(
                fontSize,
                "#1e1f28",
                false,
                false,
                false
            )
        }
        data.textValue.fontSize = fontSize

        return data
    }

    calculateValues = (data: WDCalculationTriangleData) => {
        let calculationValues: WDCalculationValue[] = []
        let calculationValuesNum: number[] = []

        if (data.calculationType === CalculationType.ADD) {
            let min = Math.round(data.minValue / 2)
            if (min < 1 && !data.zeroCalculation) {
                min = 1
            }
            let max = data.maxValue - min

            calculationValuesNum[0] = Math.round(Math.random() * (max - min) + min)

            // if digitExceeded normal calculation
            if (data.digitExceeded) {
                calculationValuesNum[1] = Math.round(Math.random() * (max - calculationValuesNum[0]) + min)
                calculationValuesNum[2] = Math.round(Math.random() * (max - Math.max(calculationValuesNum[0], calculationValuesNum[1])) + min)

            } else {
                // if not digitExceeded new max must be calculated to prevent numbers where digit exceeding would happen
                max = (10 - (calculationValuesNum[0] % 10))
                calculationValuesNum[1] = Math.round(Math.random() * max + min)
                max = Math.min(max, (10 - (calculationValuesNum[1] % 10)))
                calculationValuesNum[2] = Math.round(Math.random() * max + min)
            }
            calculationValuesNum[3] = calculationValuesNum[0] + calculationValuesNum[1]
            calculationValuesNum[4] = calculationValuesNum[1] + calculationValuesNum[2]
            calculationValuesNum[5] = calculationValuesNum[0] + calculationValuesNum[2]

        } else if (data.calculationType === CalculationType.MULTIPLY) {
            // Value inside can max be 10 - values outside can max be 100
            calculationValuesNum[0] = Math.floor(Math.random() * 10 + 1)
            calculationValuesNum[1] = Math.floor(Math.random() * 10 + 1)
            calculationValuesNum[2] = Math.floor(Math.random() * 10 + 1)
            calculationValuesNum[3] = calculationValuesNum[0] * calculationValuesNum[1]
            calculationValuesNum[4] = calculationValuesNum[1] * calculationValuesNum[2]
            calculationValuesNum[5] = calculationValuesNum[0] * calculationValuesNum[2]
        }

        // Map number array to Calculation value array
        calculationValuesNum.forEach(value => {
            calculationValues.push(new WDCalculationValue(value.toString(), false))
        })

        let difficulty = data.difficultyLevel
        if (difficulty === Difficulty.none) {
            let randomNumber = Math.random()

            if (randomNumber < 0.4) {
                difficulty = Difficulty.easy
            } else if (randomNumber > 0.65) {
                difficulty = Difficulty.hard
            } else {
                difficulty = Difficulty.medium
            }
        }

        data.solutionActionsPossible = true

        switch (difficulty) {
            case Difficulty.easy:
                calculationValues[3].solution = true
                calculationValues[4].solution = true
                calculationValues[5].solution = true
                break

            case Difficulty.medium:
                let firstNumber = Math.floor(Math.random() * 6) // random number below 6 (6 input fields)

                // to get two inner and one outer solution
                if (data.numberPlacement === NumberPlacement.one_inner_two_outer
                    || (data.numberPlacement === NumberPlacement.none && firstNumber < 3)) {

                    // needed because first number is calculated before number placement takes action so first number could be above 2
                    if (firstNumber >= 3) {
                        firstNumber = Math.floor(Math.random() * 3)
                    }

                    // get the position of the second solution. Check if it is not already a solution
                    let secondNumber = Math.floor(Math.random() * 3)
                    while (secondNumber === firstNumber) {
                        secondNumber = Math.floor(Math.random() * 3)
                    }

                    // position of third number is between 3-5
                    let thirdNumber = Math.floor(Math.random() * 3 + 3)

                    calculationValues[firstNumber].solution = true
                    calculationValues[secondNumber].solution = true
                    calculationValues[thirdNumber].solution = true

                }
                // to get two outer and one inner solution
                else {
                    // needed because first number is calculated before number placement takes action so first number could be below 3
                    if (firstNumber < 3) {
                        firstNumber = Math.floor(Math.random() * 3 + 3)
                    }

                    // get the position of the second solution. Check if it is not already a solution
                    let secondNumber = Math.floor(Math.random() * 3 + 3)
                    while (secondNumber === firstNumber) {
                        secondNumber = Math.floor(Math.random() * 3 + 3)
                    }

                    // position of third number is below 3
                    let thirdNumber = Math.floor(Math.random() * 3)

                    calculationValues[firstNumber].solution = true
                    calculationValues[secondNumber].solution = true
                    calculationValues[thirdNumber].solution = true
                }
                break

            case Difficulty.hard:
                let chooseExercise = Math.floor(Math.random() * 3) // random number below 6

                // set all to solution and reset them depending on exercise
                calculationValues[0].solution = true
                calculationValues[1].solution = true
                calculationValues[2].solution = true
                calculationValues[3].solution = true
                calculationValues[4].solution = true
                calculationValues[5].solution = true

                // to get only inner solutions
                if (data.numberPlacement === NumberPlacement.all_outer
                    || (data.numberPlacement === NumberPlacement.none && chooseExercise === 0)) {

                    calculationValues[3].solution = false
                    calculationValues[4].solution = false
                    calculationValues[5].solution = false
                }
                // to get one outer and one inner solution
                else if (chooseExercise === 1) {
                    let outer = Math.floor(Math.random() * 3 + 3)
                    let inner = Math.floor(Math.random() * 3)

                    calculationValues[outer].solution = false
                    calculationValues[inner].solution = false

                    // remove all solution values to avoid showing solution for riddles
                    calculationValues = this.removeSolutionValuesForRiddles(calculationValues)
                    data.solutionActionsPossible = false
                }
                // to get only one solution
                else {
                    let noSolution = Math.floor(Math.random() * 6)
                    calculationValues[noSolution].solution = false

                    // remove all solution values to avoid showing solution for riddles
                    calculationValues = this.removeSolutionValuesForRiddles(calculationValues)
                    data.solutionActionsPossible = false
                }
                break
        }

        data.data = calculationValues
        let solutions = Util.shuffleArray(calculationValues.filter(item => item.solution).map(item => item.data))
        data.solution1 = (solutions && solutions.length > 0) ? solutions[0] : ""
        data.solution2 = (solutions && solutions.length > 1) ? solutions[1] : ""
        data.solution3 = (solutions && solutions.length > 2) ? solutions[2] : ""
    }
    removeSolutionValuesForRiddles = (calculationValues: WDCalculationValue[]): WDCalculationValue[] => {
        // remove all solution values to avoid showing solution for riddles
        return calculationValues.map(calcValue => {
            if (calcValue.solution) {
                calcValue.data = ""
            }

            return calcValue;
        })
    }

    onChangeInputValue = async (event: React.ChangeEvent, position: number) => {
        const value = (event.target as HTMLInputElement).value

        if (!isNaN(+value)) {
            let newData = {...this.props.data}
            newData.data[position].data = (event.target as HTMLInputElement).value

            this.props.onUpdateElement(new WorksheetItemUpdate(
                    this.props.id, {content: this.serializeElementData(newData)}),
                {actionCategory: WDActionLogCategory.content}
            )
        }
    }
    onChangeSolution = async (event: React.ChangeEvent, solution: string) => {
        const value = (event.target as HTMLInputElement).value

        if (!isNaN(+value)) {
            let newData = {...this.props.data}
            newData[solution] = value

            this.props.onUpdateElement(new WorksheetItemUpdate(
                    this.props.id, {content: this.serializeElementData(newData)}),
                {actionCategory: WDActionLogCategory.content}
            )
        }
    }
    onEdit = (editMode: boolean) => {
        this.forceUpdate()
        this.props.onElementEdit?.(this.props.id, editMode)
    }

    calculateFontSize = (width: number) => {
        let fontSize = this.initialFontSize / WDWritingLineature.getDefaultWidth()
        return fontSize * width
    }

    /**
     * Overridden methods
     */
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDCalculationTriangleData.serialize(data as WDCalculationTriangleData)
    }

    recalculateSize = (width: number, height: number): WorksheetItemUpdate => {
        let newData = {...this.props.data}
        newData.textValue.fontSize = this.calculateFontSize(width)

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(newData),
            width: width,
            height: height
        })
    }

    doAction = (action: WDToolbarAction, data?: any) => {
        let newData = {...this.props.data}

        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.GENERATE_TASK:
                if (data) {
                    let generateTask = data["generateTask"]

                    newData.data = [
                        new WDCalculationValue("", false),
                        new WDCalculationValue("", false),
                        new WDCalculationValue("", false),
                        new WDCalculationValue("", false),
                        new WDCalculationValue("", false),
                        new WDCalculationValue("", false)
                    ]
                    newData.solution1 = ""
                    newData.solution2 = ""
                    newData.solution3 = ""

                    if (generateTask) {
                        this.calculateValues(newData)
                    }
                    newData.taskGenerator = generateTask
                }
                break

            case WDToolbarAction.REGENERATE:
                if (newData.taskGenerator) {
                    this.calculateValues(newData)
                }
                break

            case WDToolbarAction.CHANGE_GRAPHIC:
                if (data) {
                    newData.imageKey = data.image

                    // #3113 make sure that image will not be colored when it is not standard
                    if (!newData.imageKey.toString().toLowerCase().includes("standard")
                        && !newData.imageKey.toString().toLowerCase().includes("handline")) {
                        newData.lineColor = undefined
                    }
                }
                break

            case WDToolbarAction.CHANGE_SOLUTION_SHOW:
                if (data) {
                    newData.solutionShow = data["showSolution"]
                }
                break

            case WDToolbarAction.SOLUTION_BAR:
                if (data) {
                    newData.showSolutionBar = data["showSolutionBar"]
                }
                break

            case WDToolbarAction.CHANGE_DIGIT_EXCEEDED:
                if (data) {
                    newData.digitExceeded = data["digitExceeded"]
                    newData.minValue = newData.digitExceeded ? newData.minValue : 0
                    this.calculateValues(newData)
                }
                break

            case WDToolbarAction.CHANGE_VALUE_RANGE:
                if (data) {
                    let maxValue = data["maxValue"] === -1 ? 10000 : data["maxValue"]
                    let minValue = data["minValue"]

                    newData.zr = data["zr"]
                    newData.maxValue = maxValue
                    newData.minValue = minValue
                    this.calculateValues(newData)
                }
                break

            case WDToolbarAction.CHANGE_ZERO_CALCULATION:
                if (data) {
                    newData.zeroCalculation = data["zeroCalculation"]
                    this.calculateValues(newData)
                }
                break

            case WDToolbarAction.CHANGE_CALCULATION_TYPE:
                if (data) {
                    newData.calculationType = +data["calculationType"]
                    this.calculateValues(newData)
                }
                break

            case WDToolbarAction.CHANGE_DIFFICULTY_LEVEL:
                if (data) {
                    newData.difficultyLevel = data["difficultyLevel"]
                    newData.numberPlacement = NumberPlacement.none
                    this.calculateValues(newData)
                }
                break

            case WDToolbarAction.CHANGE_NUMBER_PLACEMENT:
                if (data) {
                    newData.numberPlacement = data["numberPlacement"]

                    switch (newData.numberPlacement) {
                        case NumberPlacement.none:
                            break

                        case NumberPlacement.all_inner:
                            newData.difficultyLevel = Difficulty.easy
                            break

                        case NumberPlacement.one_inner_two_outer:
                            newData.difficultyLevel = Difficulty.medium
                            break

                        case NumberPlacement.two_inner_one_outer:
                            newData.difficultyLevel = Difficulty.medium
                            break

                        case NumberPlacement.all_outer:
                            newData.difficultyLevel = Difficulty.hard
                            break

                        case NumberPlacement.puzzle:
                            newData.difficultyLevel = Difficulty.hard
                            break
                    }

                    this.calculateValues(newData)
                }
                break

            case WDToolbarAction.LINE_COLOR:
                if (data) {
                    newData.lineColor = data["lineColor"]
                }
                break

            case WDToolbarAction.BOLD:
                newData.textValue.bold = !newData.textValue.bold
                break

            case WDToolbarAction.ITALIC:
                newData.textValue.italic = !newData.textValue.italic
                break

            case WDToolbarAction.STRIKETHROUGH:
                newData.textValue.strikeThrough = !newData.textValue.strikeThrough
                break

            case WDToolbarAction.FONT_COLOR:
                if (data) {
                    newData.textValue.color = data["color"]
                }
                break

            default:
                break
        }

        update.value.content = this.serializeElementData(newData)

        return update
    }
    doPresentationAction = (action: WDPresentationAction, data?: any): WorksheetItemUpdate => {
        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDPresentationAction.CHANGE_SOLUTION_SHOW:
                update.value.renderSolutionInPresentationMode = !this.props.element.renderSolutionInPresentationMode
                break
        }

        return update
    }

    isEditModeAllowed = (): boolean => {
        return !this.props.data.taskGenerator
    }

    renderSolution = (): boolean => {
        if (this.props.inPresentationMode) {
            return this.props.element.renderSolutionInPresentationMode
        } else {
            return (this.props.solutionForceMode === SolutionForceMode.ForceShow ||
                (this.props.solutionForceMode === SolutionForceMode.Off && this.props.data.solutionShow))
        }
    }

    render() {
        const imageUrl = process.env.PUBLIC_URL + ImagePath.getTriangleUrl()
        const editMode = this.state.elementRef.current?.state.editMode === true
        let data = this.props.data
        let renderSolution = this.renderSolution()

        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }

        // check if line color must be applied
        let colorClass = ""
        if (this.props.data.lineColor !== undefined) {
            colorClass = "svg-color-" + this.props.data.lineColor.toUpperCase()
        }

        let textValue = data.textValue
        if (textValue === undefined) {
            let fontSize = this.calculateFontSize(this.props.element.width)
            textValue = new WDCalculationTriangleTextData(fontSize, "#1e1f28", false, false, false)
        }

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            onEdit={this.onEdit}
            hasResizeOnCreate={false}
            resizeInfo={this.resizeInfoTriangle}
            renderWrapper={true}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.props.onElementResize}
            isEditModeAllowed={this.isEditModeAllowed}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            ref={this.state.elementRef}
        >

            <div id={"calc-triangle-" + this.props.id} className="ws-designer-calc-triangle print">
                <img id={"calc-triangle-" + this.props.id + "-image"}
                     className={"image-in-container " + colorClass}
                     src={imageUrl + CategoryImageValue.getImageByKey([ImageCategory.CALCULATION_TRIANGLE], this.props.data.imageKey)}
                     alt={this.context.translate(translations.math.elements.calculation_triangle)}/>

                {(renderSolution || !data.data[0].solution) &&
                    <input id={"calc-triangle-" + this.props.id + "-num0"} type={"text"} maxLength={4}
                           className={data.data[0].solution ? "top solution" : "top"}
                           readOnly={data.taskGenerator || !editMode}
                           style={{
                               fontSize: textValue.fontSize + "pt",
                               fontWeight: textValue.bold ? "bold" : "normal",
                               fontStyle: textValue.italic ? "italic" : "normal",
                               textDecoration: textValue.strikeThrough ? "line-through" : "none",
                               color: data.data[0].solution ? undefined : textValue.color
                           }}
                           value={data.data[0].data === "" ? "" : Util.formatNumber(+data.data[0].data, 'de-at')}
                           onChange={(event) => this.onChangeInputValue(event, 0)}/>
                }

                {(renderSolution || !data.data[1].solution) &&
                    <input id={"calc-triangle-" + this.props.id + "-num1"} type={"text"} maxLength={4}
                           className={data.data[1].solution ? "inner-right solution" : "inner-right"}
                           readOnly={data.taskGenerator || !editMode}
                           style={{
                               fontSize: textValue.fontSize + "pt",
                               fontWeight: textValue.bold ? "bold" : "normal",
                               fontStyle: textValue.italic ? "italic" : "normal",
                               textDecoration: textValue.strikeThrough ? "line-through" : "none",
                               color: data.data[1].solution ? undefined : textValue.color
                           }}
                           value={data.data[1].data === "" ? "" : Util.formatNumber(+data.data[1].data, 'de-at')}
                           onChange={(event) => this.onChangeInputValue(event, 1)}/>
                }

                {(renderSolution || !data.data[2].solution) &&
                    <input id={"calc-triangle-" + this.props.id + "-num2"} type={"text"} maxLength={4}
                           className={data.data[2].solution ? "inner-left solution" : "inner-left"}
                           readOnly={data.taskGenerator || !editMode}
                           style={{
                               fontSize: textValue.fontSize + "pt",
                               fontWeight: textValue.bold ? "bold" : "normal",
                               fontStyle: textValue.italic ? "italic" : "normal",
                               textDecoration: textValue.strikeThrough ? "line-through" : "none",
                               color: data.data[2].solution ? undefined : textValue.color
                           }}
                           value={data.data[2].data === "" ? "" : Util.formatNumber(+data.data[2].data, 'de-at')}
                           onChange={(event) => this.onChangeInputValue(event, 2)}/>
                }

                {(renderSolution || !data.data[3].solution) &&
                    <input id={"calc-triangle-" + this.props.id + "-num3"} type={"text"} maxLength={4}
                           className={data.data[3].solution ? "outer-right solution" : "outer-right"}
                           readOnly={data.taskGenerator || !editMode}
                           style={{
                               fontSize: textValue.fontSize + "pt",
                               fontWeight: textValue.bold ? "bold" : "normal",
                               fontStyle: textValue.italic ? "italic" : "normal",
                               textDecoration: textValue.strikeThrough ? "line-through" : "none",
                               color: data.data[3].solution ? undefined : textValue.color
                           }}
                           value={data.data[3].data === "" ? "" : Util.formatNumber(+data.data[3].data, 'de-at')}
                           onChange={(event) => this.onChangeInputValue(event, 3)}/>
                }

                {(renderSolution || !data.data[4].solution) &&
                    <input id={"calc-triangle-" + this.props.id + "-num4"} type={"text"} maxLength={4}
                           className={data.data[4].solution ? "bottom solution" : "bottom"}
                           readOnly={data.taskGenerator || !editMode}
                           style={{
                               fontSize: textValue.fontSize + "pt",
                               fontWeight: textValue.bold ? "bold" : "normal",
                               fontStyle: textValue.italic ? "italic" : "normal",
                               textDecoration: textValue.strikeThrough ? "line-through" : "none",
                               color: data.data[4].solution ? undefined : textValue.color
                           }}
                           value={data.data[4].data === "" ? "" : Util.formatNumber(+data.data[4].data, 'de-at')}
                           onChange={(event) => this.onChangeInputValue(event, 4)}/>
                }

                {(renderSolution || !data.data[5].solution) &&
                    <input id={"calc-triangle-" + this.props.id + "-num5"} type={"text"} maxLength={4}
                           className={data.data[5].solution ? "outer-left solution" : "outer-left"}
                           readOnly={data.taskGenerator || !editMode}
                           style={{
                               fontSize: textValue.fontSize + "pt",
                               fontWeight: textValue.bold ? "bold" : "normal",
                               fontStyle: textValue.italic ? "italic" : "normal",
                               textDecoration: textValue.strikeThrough ? "line-through" : "none",
                               color: data.data[5].solution ? undefined : textValue.color
                           }}
                           value={data.data[5].data === "" ? "" : Util.formatNumber(+data.data[5].data, 'de-at')}
                           onChange={(event) => this.onChangeInputValue(event, 5)}/>
                }
            </div>

            {data.showSolutionBar && data.solutionActionsPossible &&

                <div id={"calc-triangle-" + this.props.id + "-solution"}
                     className="ws-designer-calc-element-solution-bar print">

                    <img id={"calc-triangle-" + this.props.id + "-solution-bar-image"}
                         className={"image-in-container " + colorClass}
                         draggable={false}
                         src={imageUrl + CategoryImageValue.getImageByKey([ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR], data.imageKey)}
                         alt={this.context.translate(translations.math.elements.calculation_triangle)}/>

                    <div
                        className={"ws-designer-calc-element-solution-values ws-designer-calc-triangle-solution-values"}>

                        <div className={"ws-designer-calc-element-solution ws-designer-calc-triangle-solution"}>
                            <input id={"calc-triangle-" + this.props.id + "-solution0"}
                                   key={"calc-triangle-" + this.props.id + "-solution0"}
                                   type={"text"}
                                   readOnly={data.taskGenerator || !editMode}
                                   style={{
                                       fontSize: textValue.fontSize + "pt",
                                       fontWeight: textValue.bold ? "bold" : "normal",
                                       fontStyle: textValue.italic ? "italic" : "normal",
                                       textDecoration: textValue.strikeThrough ? "line-through" : "none",
                                       color: textValue.color
                                   }}
                                   onChange={(event) => this.onChangeSolution(event, "solution1")}
                                   value={data.solution1 === "" ? "" : Util.formatNumber(+data.solution1, 'de-at')}/>

                            {(data.taskGenerator && renderSolution) &&
                                <div className={"ws-designer-calc-element-solution-values-checked"}/>
                            }
                        </div>

                        <div className={"ws-designer-calc-element-solution ws-designer-calc-triangle-solution"}>
                            <input id={"calc-triangle-" + this.props.id + "-solution1"}
                                   key={"calc-triangle-" + this.props.id + "-solution1"}
                                   type={"text"}
                                   readOnly={data.taskGenerator || !editMode}
                                   style={{
                                       fontSize: textValue.fontSize + "pt",
                                       fontWeight: textValue.bold ? "bold" : "normal",
                                       fontStyle: textValue.italic ? "italic" : "normal",
                                       textDecoration: textValue.strikeThrough ? "line-through" : "none",
                                       color: textValue.color
                                   }}
                                   onChange={(event) => this.onChangeSolution(event, "solution2")}
                                   value={data.solution2 === "" ? "" : Util.formatNumber(+data.solution2, 'de-at')}/>

                            {(data.taskGenerator && renderSolution) &&
                                <div className={"ws-designer-calc-element-solution-values-checked"}/>
                            }
                        </div>

                        <div className={"ws-designer-calc-element-solution ws-designer-calc-triangle-solution"}>
                            <input id={"calc-triangle-" + this.props.id + "-solution2"}
                                   key={"calc-triangle-" + this.props.id + "-solution2"}
                                   type={"text"}
                                   readOnly={data.taskGenerator || !editMode}
                                   style={{
                                       fontSize: textValue.fontSize + "pt",
                                       fontWeight: textValue.bold ? "bold" : "normal",
                                       fontStyle: textValue.italic ? "italic" : "normal",
                                       textDecoration: textValue.strikeThrough ? "line-through" : "none",
                                       color: textValue.color
                                   }}
                                   onChange={(event) => this.onChangeSolution(event, "solution3")}
                                   value={data.solution3 === "" ? "" : Util.formatNumber(+data.solution3, 'de-at')}/>

                            {(data.taskGenerator && renderSolution) &&
                                <div className={"ws-designer-calc-element-solution-values-checked"}/>
                            }
                        </div>
                    </div>

                    {/*Overlay to prevent input values being selected*/}
                    {data.taskGenerator && <div className={"ws-designer-element-solution-bar-overlay"}/>}
                </div>
            }
        </WDElementContainer>
    }
}
