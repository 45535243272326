import React from "react";
import {MainContext} from "../../_base/MainContext";
import Entity from "../../_model/Entity";

interface IProps<T> {
    id: string,
    width?: number,
    label?: string,
    parent: string
    data: T[],
    selectedData?: T[],
    value?: number,
    style?: React.CSSProperties

    required: boolean,
    readonly: boolean,

    onChange?: (values: T[]) => void
}
interface IState {
    required: boolean
}

export class TreeView<T extends Entity> extends React.Component<IProps<T>, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps<T>, state: IState) {
        super(props, state);

        this.state = {
            required: this.props.required && this.props.selectedData?.length === 0
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps<T>>, prevState: Readonly<IState>, snapshot?: any) {
        if ((prevProps.selectedData === null || prevProps.selectedData?.length === 0) &&
            (this.props.selectedData && this.props.selectedData?.length > 0)) {

            this.setState({required: false})
        }
    }

    isChecked = (value?: number) => {
        if (value !== undefined && this.props.selectedData) {
            const found = this.props.selectedData.find(i => i.id === value)
            return (found !== undefined)
        }

        return false
    }

    changeSelection = (selected: T[], value: number, state: boolean): T[] => {
        if (state) {
            let element = this.props.data.find(item => item.id === value)
            if (element) {
                selected.push(element)
            }
        }
        else {
            let item = selected.find(i => i.id === value)
            if (item) {
                let parentId = item[this.props.parent] !== null ? item[this.props.parent].id : undefined

                // Uncheck current node
                selected = selected.filter(i => i.id !== value)

                // Check if any other sibling is checked, if not unselect parent
                if (parentId && (selected.find(i => i[this.props.parent] !== null && i[this.props.parent].id === parentId) === undefined)) {
                    selected = this.changeSelection(selected, parentId, state);
                }
            }
        }

        let subs = this.props.data.filter(item => item[this.props.parent] !== null && item[this.props.parent].id === value)
        for (let i = 0; i < subs.length; i++) {
            selected = this.changeSelection(selected, subs[i].id!, state);
        }

        return selected
    }

    onChange = (value: number) => {
        if (this.props.selectedData) {
            let selected = this.changeSelection(this.props.selectedData, value, !this.isChecked(value))

            // Required is true when field is defined as required and no input is checked
            this.setState({required: (this.props.required && selected.length === 0)})

            this.props.onChange?.(selected)
        }
    }

    renderItems = (parent?: number) => {
        let data = this.props.data

        if (parent) {
            data = data.filter(item => item[this.props.parent] !== null && item[this.props.parent].id === parent)
        }
        else {
            data = data.filter(item => item[this.props.parent] === null)
        }

        return <>
            {data.map(value => {
                let checked = this.isChecked(value.id)

                return <div key={this.props.id + value.id}>
                    <div className={"tree-view-item"} style={this.props.style}>
                        <input type={"checkbox"}
                               id={this.props.id + value.id}
                               value={value.id}
                               disabled={this.props.readonly}
                               required={this.state.required}
                               checked={checked}
                               readOnly={true}
                        />
                        <label className={"label " + (this.props.readonly ? "label-disabled" : "")}
                               htmlFor={this.props.id + value.id}
                               onClick={!this.props.readonly ? () => this.onChange(value.id!) : () => {}}
                        >
                            {value.name}
                        </label>
                    </div>
                    {checked && <div className={"tree-view-sub"}>{this.renderItems(value.id)}</div>}
                </div>
            })}
        </>
    }
    render () {
        return <div className={"tree-view"} style={{ width: this.props.width }}>
            {this.props.label &&
            <label className="bold-label" htmlFor={this.props.id}>{this.props.label}</label>
            }
            {this.renderItems()}
        </div>
    }
}

