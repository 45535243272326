import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {MainContext} from "../../../../_base/MainContext";
import {SearchInput} from "../../../../Components/Controls/SearchInput";
import {GetFilteredExerciseList} from "../../../../_endpoint/ExerciseEndpoint";
import {Difficulty, ExerciseCalculationType, ExerciseType} from "../../../../Framework/Enums";
import {ExerciseFilter} from "../../../../_model/ExerciseFilter";
import Exercise from "../../../../_model/Exercise";
import {MenuItem} from "../../../../_model/MenuItem";
import WDTextExerciseDropDownValues from "../../../Elements/TextExercise/WDTextExerciseDropDownValues";
import translations from "../../../../Framework/translations.json";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button (Yes/No) in Toolbar
 * Effects selected element(s)
 * Full-text search of text exercises
 */

interface IProps {
    id: number
    enabled: boolean
    tooltipDisabled?: TooltipText
    difficulty?: Difficulty
    calculationType?: ExerciseCalculationType
    zr?: number
    topic?: number
    onActionButton: (action: WDToolbarAction, data: any) => void
}
interface IState {
    exercises?: Exercise[]
}

export class WDToolbarButtonTextExerciseSearch extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onSearch = (value: string) => {
        if (value === "") {
            this.setState({exercises: undefined})
            return
        }

        let filter = new ExerciseFilter(ExerciseType.text_exercise)
        if (this.props.difficulty) {
            filter.difficulty = this.props.difficulty
        }
        if (this.props.calculationType !== undefined && this.props.calculationType !== ExerciseCalculationType.none) {
            filter.calculationType = this.props.calculationType
        }
        if (this.props.zr) {
            const menuEntryId = WDTextExerciseDropDownValues.mapNumberRange(+this.props.zr)
            if (menuEntryId > 0) {
                filter.menuEntry = new MenuItem(true, menuEntryId, "")
            }
        }
        if (this.props.topic && this.props.topic > 0) {
            filter.topicMenuEntry = new MenuItem(true, this.props.topic, "")
        }
        filter.search = value

        GetFilteredExerciseList(filter).then((exercises) => {
            this.setState({exercises: exercises})
        }, (error) => {
            this.context.handleError(error)
        })
    }

    onSelectItem = (id: number) => {
        this.props.onActionButton(WDToolbarAction.SELECT_ITEM, {id: id})
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-text-exercise-search"}
            id={"text-exercise-search"}
            className={"text-exercise-search"}
        >
            <SearchInput width={115}
                         onSearch={this.onSearch}
                         readonly={!this.props.enabled}
                         typeAheadSearch={false}
                         showFieldOptions={false}
                         maxLength={50}
            />

            {this.state && this.state.exercises &&
                <div className={"ws-designer-toolbar-search-result-list-popout"}>
                    {this.state.exercises.length === 0 &&
                        <div className={"ws-designer-toolbar-search-no-results"}>{this.context.translate(translations.text.no_result)}</div>
                    }

                    {this.state.exercises.map(e => {
                        return <div key={e.id} className={"ws-designer-toolbar-search-result-list-item"} onClick={() => this.onSelectItem(e.id!)}>
                            <div className={"ws-designer-toolbar-search-result-list-item-title"}>{e.name}</div>
                            <div className={"ws-designer-toolbar-search-result-list-item-description"}>{e.description}</div>
                        </div>
                    })}
                </div>
            }
        </li>
    }
}
