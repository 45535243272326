import Entity from "./Entity";
import {Worksheet} from "./Worksheet";

export class WorksheetRating extends Entity {
    worksheet: Worksheet
    rating: number
    showName: boolean
    oldVersion: boolean
    description?: string
    author?: string

    createdOn?: Date

    constructor(worksheet: Worksheet, rating: number, showName: boolean, oldVersion: boolean, description?: string, author?: string, id?: number) {
        super("", id);
        this.id = id
        this.worksheet = worksheet
        this.rating = rating
        this.showName = showName
        this.oldVersion = oldVersion
        this.description = description
        this.author = author
    }
}
