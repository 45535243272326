import API from "../Framework/API";
import {CloneWorksheet} from "./WorksheetEndpoint";
import {Worksheet} from "../_model/Worksheet";
import {WSContextType} from "../Designer/Elements/WSContext";
import WritingCourse from "../_model/WritingCourse";
import {WSPageFormat} from "../Framework/Enums";
import {WorksheetItem} from "../_model/WorksheetItem";
import {WritingCourseFilter} from "../_model/WritingCourseFilter";

const URL = "writing-courses"

export function GetWritingCourseList() {
    let url = URL + "/"
    return API.Get<WritingCourse[]>(url)
}
export function GetFilteredWritingCourseList(filter: WritingCourseFilter) {
    let url = URL + "/filter"
    return API.Post<WritingCourseFilter, WritingCourse[]>(url, filter)
}
export function GetAllWritingCourseParentList() {
    let url = URL + "/parents"
    return API.Get<WritingCourse[]>(url)
}
export function GetWritingCourseListByParent(parent: number) {
    let url = URL + "/"
    return API.Get<WritingCourse[]>(url + parent + "/list")
}
export function GetWritingCourse(courseId: number) {
    let url = URL + "/" + courseId
    return API.Get<WritingCourse>(url)
}
export function GetRandomWritingCourse(filter: WritingCourseFilter) {
    let url = URL + "/random"
    return API.Post<WritingCourseFilter, WritingCourse>(url, filter)
}
export function GetAllChildWritingCoursesByParent(parentId: number) {
    let url = URL + "/" + parentId + "/children"
    return API.Get<WritingCourse[]>(url)
}
export function GetChildByParentAndName(parentId: number, childName: string) {
    let url = URL + "/" + parentId + "/children/" + childName
    return API.Get<WritingCourse>(url)
}
export function GetMenuChildWritingCourse(parentId: number) {
    let url = URL + "/" + parentId + "/children/menu"
    return API.Get<WritingCourse>(url)
}
export function GetAlternativeWritingCourse(courseId: number, filter: WritingCourseFilter) {
    let url = URL + "/" + courseId + "/alternative"
    return API.Post<WritingCourseFilter, WritingCourse>(url, filter)
}

export function GetWritingCourseWorksheetItems(courseId: number) {
    let url = URL + "/" + courseId + "/worksheet-items"
    return API.Get<WorksheetItem[]>(url)
}

export async function CreateWritingCourse(writingCourse: WritingCourse) {
    return API.Post<object, WritingCourse>(URL, writingCourse)
}
export async function CloneWritingCourse(writingCourse: WritingCourse, name: string, context: WSContextType) {
    writingCourse.name = name

    if (writingCourse.worksheet) {
        const worksheetName = ("WS-Writing-Course: " + writingCourse.name).substr(0, 100)

        const worksheet: Worksheet = await CloneWorksheet(writingCourse.worksheet.id, worksheetName, context)
        writingCourse.worksheet = new Worksheet(worksheet.id, worksheet.name, worksheet.context, WSPageFormat.A4, worksheet.editingAllowed)
    }

    return CreateWritingCourse(writingCourse)
}
export async function UpdateWritingCourse(writingCourse: WritingCourse) {
    return API.Put<object, WritingCourse>(URL + "/" + writingCourse.id, writingCourse)
}
