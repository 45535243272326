import React, {CSSProperties} from "react";
import {MainContext} from "../../_base/MainContext";
import {ImagePath} from "../../Framework/CategoryImage";

interface SidebarFilterBaseProps {
    advancedSettingsVisible: boolean
    textMore: string
    textLess: string
    style?: CSSProperties
    className: string
    onToggle?: () => void
}
interface SidebarFilterBaseState {
}

export class SidebarAdvancedFilterArrow extends React.Component<SidebarFilterBaseProps, SidebarFilterBaseState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        return <div className={this.props.className} onClick={this.props.onToggle} style={this.props.style}>
            {this.props.advancedSettingsVisible ?
                <div className={"ws-designer-sidebar-more-link"}>
                    <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "double_arrow_left.svg"}
                         alt={""} height={"13px"}/>
                    &nbsp;{this.props.textLess}
                </div>
                :
                <div className={"ws-designer-sidebar-more-link"}>
                    {this.props.textMore}
                    &nbsp;<img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "double_arrow_left.svg"}
                               alt={""} height={"13px"}
                               style={{transform: "rotateY(180deg)"}}
                />

                </div>
            }
        </div>
    }
}

