import React from "react";
import {MainContext} from "../_base/MainContext";
import translations from "../Framework/translations.json";
import API from "../Framework/API";
import Auth from "../Framework/Auth";
import {AuthError} from "../Framework/Error/AuthError";
import {ButtonInfo, ButtonList} from "../Components/Controls/ButtonList";
import Conf from "../Framework/Conf";
import {FloatingHint} from "../Components/Notification/FloatingHint";
import {NotificationData} from "../Components/Notification/Hint";
import {NotificationStatus} from "../Framework/Enums";
import {SecurityError} from "../Framework/Error/SecurityError";
import {PaymentRequiredError} from "../Framework/Error/PaymentRequiredError";

interface IProps {
    showLoginButton: boolean
    onPostLogin?: () => void
}
interface IState {
    userEmail?: string
    userPassword?: string
    errorMessage?: string
    loading: boolean
    loadingMessage?: string
}

export class LoginForm extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    loginMessages: string[] = []
    button = [new ButtonInfo("btnSave", "button button-save", "submit", true, false, this.context.translate(translations.text.login), undefined, {})]

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            loading: false
        }

        this.loginMessages = [
            this.context.translate(translations.notification.login_message_0),
            this.context.translate(translations.notification.login_message_1),
            this.context.translate(translations.notification.login_message_2),
            this.context.translate(translations.notification.login_message_3),
            this.context.translate(translations.notification.login_message_4),
            this.context.translate(translations.notification.login_message_5),
            this.context.translate(translations.notification.login_message_6),
        ]
    }

    handleLoginSubmit = (event?: React.FormEvent) => {
        event?.preventDefault()

        this.setState({loading: true}, () => {
            // Check if inputs were made
            if (this.state.userEmail === null || this.state.userPassword === null) {
                return;
            }

            // Write login messages
            this.writeLoginMessages(0)

            API.login({email: this.state.userEmail, password: this.state.userPassword}).then(
                async (result: string) => {
                    Auth.setToken(result)

                    this.context.getUserSettings()
                    this.context.loadTutorials()
                    /*OdooLogin(this.state.userEmail!, this.state.userPassword!).then(
                        async (result: string) => {
                            console.log(result)
                        }
                    )*/

                    this.props.onPostLogin?.()
                },
                (error) => {
                    if (error instanceof AuthError) {
                        this.setState({
                            errorMessage: this.context.translate(translations.notification.login_error),
                            loading: false,
                            loadingMessage: undefined
                        })
                    }
                    else if (error instanceof PaymentRequiredError) {
                        this.setState({errorMessage: this.context.translate(translations.notification.login_missing_license), loading: false, loadingMessage: undefined})
                    }
                    else if (error instanceof SecurityError) {
                        this.setState({errorMessage: this.context.translate(translations.notification.login_user_disabled), loading: false, loadingMessage: undefined})
                    }
                    else {
                        this.setState({errorMessage: this.context.translate(translations.notification.unexpected_error), loading: false, loadingMessage: undefined})
                    }
                }
            )})
    }

    onChangeUserEmail = (event: React.FormEvent) => {
        this.setState({userEmail: (event.target as HTMLInputElement).value })
    }

    onChangeUserPassword = (event: React.FormEvent) => {
        this.setState({userPassword: (event.target as HTMLInputElement).value })
    }

    writeLoginMessages = (messageCount: number) => {
        if(this.state.loading && messageCount < this.loginMessages.length) {
            this.setState({loadingMessage: this.loginMessages[messageCount]}, () => {
                let time = 1000 + Math.random() * 1400
                setTimeout(this.writeLoginMessages, time, ++messageCount)
            })
        }
    }

    render() {

        const content = <>
            <div className="form-group" style={{marginBottom: "10px"}}>
                <label className="bold-label" htmlFor="userEmail">{this.context.translate(translations.text.email)}</label>
                <input id="userEmail" type="email" name="username" autoComplete={"username"} required onChange={this.onChangeUserEmail}/>
            </div>
            <div className="form-group">
                <label className="bold-label" htmlFor="userPassword">{this.context.translate(translations.text.password)}</label>
                <input id="userPassword" type="password" name="password" autoComplete={"password"} required onChange={this.onChangeUserPassword}/>
            </div>
            {this.state.errorMessage &&
                <FloatingHint
                    id={"login-error-message"}
                    notificationData={new NotificationData(NotificationStatus.error, this.state.errorMessage)}
                    style={{width: "100%", position: "relative"}}/>
            }

            <div className="form-group" style={{fontSize: "8pt"}}>
                <a href={Conf.WEBSITE_URL() + "web/reset_password?"} target="_blank" rel="noreferrer">{this.context.translate(translations.text.password_forgotten)}</a>
            </div>
        </>

        if (this.props.showLoginButton) {
            return <form onSubmit={this.handleLoginSubmit}>
                {content}

                <div className={"form-row"}>
                    <div className={"login-message"}>{this.state.loadingMessage}</div>
                    <ButtonList buttons={this.button}
                                buttonGroupStyle={{width: "100%"}}
                                containerStyle={{paddingRight: 10}}
                                loading={this.state.loading}/>
                </div>

            </form>
        }

        return content
    }
}
