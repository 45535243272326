import {EntityData} from "./Entity";
import User from "./User";
import LessonSubject from "./LessonSubject";
import RuleCategory from "./RuleCategory";
import RuleType from "./RuleType";
import RulePillar from "./RulePillar";
import SchoolLevel from "./SchoolLevel";
import {Worksheet} from "./Worksheet";
import {Status} from "../Framework/Enums";
import Tag from "./Tag";
import {WorksheetItemType} from "./WorksheetItemType";

export class Rule extends EntityData {
    description?: string
    worksheet?: Worksheet
    lessonSubject?: LessonSubject
    ruleCategory?: RuleCategory
    schoolLevels?: SchoolLevel[]
    ruleTypes?: RuleType[]
    rulePillars?: RulePillar[]
    tags?: Tag[]
    worksheetItemTypes?: WorksheetItemType[]

    constructor(name: string, status: Status, createdOn?: Date, createdBy?: User, modifiedOn?: Date, modifiedBy?: User,
                ownerId?: User, id?: number) {
        super(name, status, createdOn, createdBy, modifiedOn, modifiedBy, ownerId, id);

        this.schoolLevels = []
        this.ruleTypes = []
        this.rulePillars = []
        this.worksheetItemTypes = []
    }
}

export default Rule
