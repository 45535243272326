import {Util} from "./Util";
import Conf from "./Conf";

export enum LogLevel {
    DEBUG,
    INFO ,
    WARN,
    ERROR
}

export class LogEntry {
    time: Date
    name: string
    level: LogLevel
    content: any

    constructor(time, level, name, content) {
        this.time = time
        this.name = name
        this.level = level
        this.content = content
    }

}

export class Log {
    collection: LogEntry[]

    constructor() {
        this.collection = []
    }

    debug = (name: string, content?: any) => {
        Conf.CLIENT_LOG() && this.collection.push(new LogEntry(new Date(), LogLevel.DEBUG, name, content))
    }
    info = (name: string, content?: any) => {
        Conf.CLIENT_LOG() && this.collection.push(new LogEntry(new Date(), LogLevel.INFO, name, content))
    }
    warn = (name: string, content?: any) => {
        Conf.CLIENT_LOG() && this.collection.push(new LogEntry(new Date(), LogLevel.WARN, name, content))
    }
    error = (name: string, content?: any) => {
        Conf.CLIENT_LOG() && this.collection.push(new LogEntry(new Date(), LogLevel.ERROR, name, content))
    }

    flush = (level?: LogLevel, clear: boolean = true) => {
        this.collection
            .filter(entry => level === undefined || entry.level >= level)
            .forEach(entry => {
                let line = Util.formatTime(entry.time) + ": " + LogLevel[entry.level] + " - " + entry.name

                if (entry.level === LogLevel.ERROR) {
                    console.error(line)
                }
                else {
                    console.debug(line)
                }

                if (entry.content) {
                    console.table(entry.content)
                }
            })

        if (clear) {
            this.clear()
        }
    }

    clear = () => {
        this.collection = []
    }
}
