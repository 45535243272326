import {GenderEnum} from "./Noun";

export enum ArticleType {
    definite = 'definite',
    indefinite = 'indefinite'
}

export class Article {
    wordId?: number
    syllabification?: string
    gender?: GenderEnum
    type?: ArticleType

    toString = () : string => {
        return "- Artikel"
    }
}

export default Article
