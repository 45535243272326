import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {ElementBorder} from "../../../Elements/WDElementContainer";
import {TooltipText} from "../../../../Components/Tooltips";
import _ from "lodash";
import {BorderStyle, BorderStylingOptions} from "../../../../Components/Controls/BorderStylingOptions";
import {BorderPadding} from "../../../../Components/Controls/BorderPadding";

interface IProps {
    id: number

    clickable: boolean
    open: boolean

    borderOptions: ElementBorder
    showBorderPadding: boolean
    iconClass?: string

    onToggleButton: (id: number) => void
    onChangeBorder: (border: ElementBorder) => void
}

interface IState {
}

/**
 * Button Submenu in all Toolbars for border settings
 * Effects selected element border
 * selected element will change the border
 */
export class WDToolbarButtonBorderOptions extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    onChangeBorderStyle = (borderStyle: BorderStyle) => {
        let borderOptions = this.props.borderOptions
        borderOptions.visible = borderStyle.visible
        borderOptions.style = borderStyle.style
        borderOptions.color = borderStyle.color
        borderOptions.weight = borderStyle.weight

        this.props.onChangeBorder(borderOptions)
    }
    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    render() {
        return <div
            key={"ws-designer-toolbar-button-border"}
            className={"ws-designer-toolbar-item"}
            id={"ws-designer-toolbar-button-border"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                             iconClassSelected={"ws-designer-toolbar-button-options"}
                             iconClassUnselected={"ws-designer-toolbar-button-options"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.border.border_options))}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             buttonHeight={32}
                             buttonWidth={15}
                             imageHeight={5}
                             imageWidth={5}
                             onToggleButton={this.onToggleList}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.open}/>

            {this.props.open &&

            <div className={"ws-designer-toolbar-options ws-designer-toolbar-arrow-options"}
                 style={{width: 270, height: this.props.showBorderPadding ? 160 : 85, overflow: "visible"}}>

                <BorderStylingOptions
                    id={this.props.id}
                    borderOptions={this.props.borderOptions}
                    onChangeBorder={this.onChangeBorderStyle}
                    padding={4}
                    clickableBorderColor={this.props.clickable}
                    clickableBorderStyle={this.props.clickable}
                    clickableBorderWeight={this.props.clickable}
                />

                {/* border padding */}
                {this.props.showBorderPadding &&
                    <BorderPadding borderOptions={this.props.borderOptions}
                                   onChangeBorder={this.props.onChangeBorder}
                    />
                }
            </div>
            }

        </div>
    }
}
