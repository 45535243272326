import React, {CSSProperties} from "react";
import {ResizeInfo, ResizerState, WDElementContainer} from "../WDElementContainer";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {Selection, Util} from "../../../Framework/Util";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../Framework/CategoryImage";
import Const from "../../../Framework/Const";
import {MainContext} from "../../../_base/MainContext";
import {WDWritingLineatureData, WDWritingLineatureSize} from "../Lineature/WritingLineature/WDWritingLineature";
import {getDefaultFontName} from "../Lineature/WritingLineature/WDWritingLineatureFontValues";
import {getDefaultCorrectionMargin} from "../Lineature/WritingLineature/WDWritingLineatureCorrectionMargin";
import {WSContextType} from "../WSContext";
import {WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import {GetSyllabification} from "../../../_endpoint/WordEndpoint";
import Syllable from "../../../_model/Dictionary/Syllable";
import {SyllableDefinition, WDSyllableWord} from "./WDSyllableWord";
import * as ReactDOMServer from 'react-dom/server';
import {Hint, NotificationData} from "../../../Components/Notification/Hint";
import {NotificationStatus, SolutionForceMode, VerticalAlignment} from "../../../Framework/Enums";
import translations from "../../../Framework/translations.json";
import {UserSettings} from "../../../_model/UserSettings";
import {SyllableMethod} from "../../../_model/Dictionary/Word";
import {WorksheetItem} from "../../../_model/WorksheetItem";
import {SyllableManualDialog} from "../../../Components/Controls/SyllableManualDialog";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {WDElementHistoryItem} from "../../History/WDElementHistoryItem";
import {WDHistoryAction} from "../../History/Enum/WDHistoryAction";
import {LogLevel} from "../../../Framework/Log";
import Converter from "../../../Framework/Converter";
import {WDActionLogCategory} from "../../ActionLog/WDActionLogEntry";

export class WDTextboxData extends WDElementBaseData {
    text: string
    showSolution: boolean
    verticalAlignment: VerticalAlignment
    syllableActivated: boolean

    constructor(text: string, showSolution: boolean, syllableActivated: boolean, verticalAlignment: VerticalAlignment) {
        super()

        this.text = text
        this.showSolution = showSolution
        this.verticalAlignment = verticalAlignment

        // TODO: Activate syllabification when set
        this.syllableActivated = syllableActivated
    }

    static defaultContent = (): WDTextboxData => {
        return new WDTextboxData("", false, false, VerticalAlignment.top)
    }
}

export class WDTextboxResizeOptions {
    showError: boolean
    showResizer: boolean

    constructor() {
        this.showError = true
        this.showResizer = true
    }
}

interface WDTextboxProps extends WDElementBaseProps {
    data: WDTextboxData

    isIndependentElement: boolean
    hasResizeOnCreate: boolean
    hasSpellCheck: boolean
    fireUpdateOnBlur: boolean

    style?: React.CSSProperties
    className?: string
    placeholder?: string

    resizeOptions: WDTextboxResizeOptions
    onFocus?: () => void

    onAutoResizerStateChanged?: (resizerState: ResizerState) => void
    onConvertElement?: (itemKey: string, elementType: WorksheetItemTypeEnum, data: any) => void
    // only used if textbox is not independent
    onFinishSyllabification?: (notFoundWords?: Syllable[]) => void
}

interface WDTextboxState extends WDElementBaseState {
    textOverflow: boolean

    syllableLoading: boolean
    syllableManuallyClosedDialog: boolean
    syllableArray: Syllable[]
    currentTextArray: string[]

    text: string            // Current text - only preserved in state for history
    selection?: Selection

    userSettings?: UserSettings
}

export class WDTextbox extends WDElementBase<WDTextboxProps, WDTextboxState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    resizeNodesTextBox: ResizeInfo =
        new ResizeInfo(true, true, true, true, true, true, true, true,
            WDTextbox.getMinWidth(), Const.MaxElementSize, WDTextbox.getMinHeight(), Const.MaxElementSize)
    elementChangeTimestamp: Date | undefined = undefined
    isMouseDown: boolean = false
    exceptionKeyPressed: boolean = false

    constructor(props: WDTextboxProps) {
        super(props)

        this.state = {
            text: this.props.data.text,
            isEdited: !this.props.isIndependentElement,
            textOverflow: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef(),
            syllableLoading: false,
            syllableManuallyClosedDialog: false,
            syllableArray: [],
            currentTextArray: []
        }
    }

    static getMinWidth = () => {
        return 30
    }
    static getMinHeight = () => {
        return 30
    }

    static getDefaultContent = (): string => {
        let data = new WDTextboxData("", false, false, VerticalAlignment.top)
        return JSON.stringify(data)
    }

    componentDidMount() {
        // When the text-fields content is rendered add a onInput event handler to all inline inputs (variable text, solution, ...)
        // In the onInput event change the html attribute "value" so it is saved when the innerHtml is taken in onChange
        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            textarea.querySelectorAll("input").forEach(i => i.addEventListener("input", this.onSubInput))
        }

        this.context.getUserSettings().then(userSettings => {
            this.setState({userSettings: userSettings})
        })

        // Do not delete timeout!
        setTimeout(() => this.setTextOverflow(), 500)
        this.props.onAutoResizerStateChanged?.(ResizerState.OK)
    }

    componentDidUpdate(prevProps: Readonly<WDTextboxProps>) {
        if (this.props.element.height !== prevProps.element.height) {
            this.setTextOverflow()
        } else if (this.props.element.width !== prevProps.element.width) {
            this.setTextOverflow()
        } else if (this.props.element.paddingLeft !== prevProps.element.paddingLeft ||
            this.props.element.paddingRight !== prevProps.element.paddingRight ||
            this.props.element.paddingTop !== prevProps.element.paddingTop ||
            this.props.element.paddingBottom !== prevProps.element.paddingBottom) {

            this.setTextOverflow()
        }

        if (!this.isMouseDown && !this.exceptionKeyPressed && this.state.selection) {
            let textarea = document.getElementById("txt-" + this.props.id)
            if (textarea && textarea.id === document.activeElement?.id) {
                Util.setSelection(textarea, this.state.selection, undefined)
            }
        }
    }

    shouldComponentUpdate(nextProps: Readonly<WDTextboxProps>, nextState: Readonly<WDTextboxState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getDefaultWidth = () => {
        return Converter.toMmGrid(460)
    }
    static getDefaultHeight = () => {
        return Converter.toMmGrid(190)
    }

    onResizeElement = (proportional: boolean, x: number, y: number) => {
        // default resize is disproportionately (opposite of default proportional resize)
        this.props.onElementResize?.(!proportional, x, y)
    }
    onSubInput = (e: Event) => {
        let inputElement = (e.target as HTMLInputElement)

        inputElement.setAttribute("value", inputElement.value)
    }
    onTextInput = () => {
        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            let updateHistory = false
            if (this.elementChangeTimestamp) {
                const actionTime = new Date()
                const difference = actionTime.getTime() - this.elementChangeTimestamp.getTime()

                updateHistory = difference < 1500

                // Save current timestamp
                this.elementChangeTimestamp = actionTime
            } else {
                this.elementChangeTimestamp = new Date()
            }

            let newData = {...this.props.data}

            // Clear inner HTML if it is only a line break
            if (textarea.innerHTML === "<br>") {
                textarea.innerHTML = ""
            }

            // Clear HTML imported from Word
            Util.clearWordHtml(textarea)

            newData.text = textarea.innerHTML
            let update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})

            // Update the current history entry while typing or create new history entry when starting to type
            if (updateHistory) {
                this.props.updateHistory(new WDElementHistoryItem([update]))
            } else {
                let currentData = {...this.props.data}
                currentData.text = this.state.text

                this.props.pushHistory(
                    [new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(currentData)})],
                    [update]
                )
            }

            // Preserve current text
            this.setState({text: textarea.innerHTML})
        }
    }
    onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === " " && e.shiftKey) {
            e.preventDefault()
            e.stopPropagation()

            this.context.log.info("Adding non-breaking space")

            const htmlSpanElement = document.createElement("span")
            htmlSpanElement.style.whiteSpace = "nowrap"
            htmlSpanElement.innerHTML = "&nbsp";
            this.insertHTMLElement(htmlSpanElement)
            this.context.log.flush()

            this.updateHistory()
        } else if (e.key === "\"") {
            let textarea = document.getElementById("txt-" + this.props.id) as HTMLDivElement | null
            if (textarea) {
                // Cancel the current quote
                e.preventDefault()
                e.stopPropagation()

                // Add quote at bottom or top based on character before (space or non-breaking space) or beginning of a tag
                const character = Util.getCharacterPrecedingCaret(textarea)
                const isSpace = character.charCodeAt(0) === 32 || character.charCodeAt(0) === 160
                this.context.log.debug(`Character = ${character}, ${character.charCodeAt(0)}, isSpace = ${isSpace}`)

                const caretPos = Util.getCaretPositionInElement(textarea)
                this.context.log.debug(`Caret Offset = ${caretPos}`)

                const isLowerQuote = caretPos === 0 || isSpace
                this.context.log.debug(`isLowerQuote = ${isLowerQuote}`)

                this.insertTextElement(isLowerQuote ? "\u201E" : "\u201C", true)
                this.context.log.flush(LogLevel.INFO)

                this.updateHistory()
            }
        }

        if (this.isExceptionKey(e.key)) {
            this.exceptionKeyPressed = true
        }
        else {
            // Reset selection, it's set in the onKeyUp event
            this.setState({selection: undefined})
        }
    }
    onKeyUp = (e: React.KeyboardEvent) => {
        if (!this.exceptionKeyPressed) {
            this.onSelectionChanged()
        }

        this.exceptionKeyPressed = false
    }
    isExceptionKey = (key: string) => {
        return key === "Shift" || key === "Control" || key === "Alt" || key === "Enter" ||
            key === "ArrowLeft" || key === "ArrowRight" || key === "ArrowUp" || key === "ArrowDown"
    }

    updateHistory = () => {
        let newData = {...this.props.data}

        let textarea = document.getElementById("txt-" + this.props.id) as HTMLTextAreaElement | null
        if (textarea) {
            newData.text = textarea.innerHTML
            let update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
            this.props.updateHistory(new WDElementHistoryItem([update]))
        }
    }

    onAutoResize = () => {
        if (this.props.element.locked) {
            return
        }

        // If element is not selected, select it so the overflow logic works
        if (!this.props.element.selected) {
            this.props.onElementSelect?.(this.props.id, true, true)
        }

        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            // blue resizer
            textarea.style.height = "auto"

            const marginTop = +textarea.style.marginTop.replace("px", "")
            const marginBottom = +textarea.style.marginBottom.replace("px", "")
            let height = textarea.scrollHeight + (isNaN(marginTop) ? 0 : marginTop) + (isNaN(marginBottom) ? 0 : marginBottom)

            this.props.onUpdateElement(new WorksheetItemUpdate(this.props.id, {
                height: height,
                selected: true
            }), {historyAction: WDHistoryAction.RESIZE, actionCategory: WDActionLogCategory.resize})

            this.setTextOverflow()
        }
    }

    setTextOverflow = () => {
        if (this.isTextOverflow() && !this.state.textOverflow) {
            this.props.onAutoResizerStateChanged?.(ResizerState.ERROR)
            this.setState({textOverflow: true})
        } else if (!this.isTextOverflow() && this.state.textOverflow) {
            this.props.onAutoResizerStateChanged?.(ResizerState.OK)
            this.setState({textOverflow: false})
        }
    }
    getAutoResizerState = (): ResizerState => {
        let resizerState = ResizerState.NONE
        if (this.props.resizeOptions.showResizer && this.state.showNonPrintableObjects) {
            if (this.state.textOverflow) {
                resizerState = ResizerState.ERROR
            } else if (this.props.element.selected) {
                resizerState = ResizerState.OK
            }
        }

        return resizerState
    }
    isTextOverflow = () => {
        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            if (textarea.innerText === "") {
                return false
            }

            let scrollHeight = textarea.scrollHeight

            if (this.props.isIndependentElement) {
                return scrollHeight > textarea.clientHeight
            } else {
                const marginTop = +textarea.style.marginTop.replace("px", "")

                if (!isNaN(marginTop) && marginTop > 0) {
                    scrollHeight += marginTop
                }
                // If the textbox has a negative margin (= an overflow on top) it is ignored when checking the scroll height
                else if (marginTop < 0) {
                    scrollHeight += (marginTop - 1)
                }

                return scrollHeight - marginTop > textarea.clientHeight
            }
        }

        return false
    }
    isEditable = () => {
        let isEditable = (this.props.isIndependentElement || !this.props.isReadOnly)
        isEditable = isEditable && !this.props.element.locked && !this.state.syllableLoading
        isEditable = isEditable && (this.getNotFoundWords().length === 0 || this.state.syllableManuallyClosedDialog)

        return isEditable
    }

    // Overridden methods
    hasNameConfigInstancesEnabled = (): boolean => {
        return true
    }
    getNameConfigInstances = (): number[] => {
        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            let tags = textarea.getElementsByClassName("name-config-tag")

            let ids = Array.from(tags).map(item => {
                let value = item.getAttribute("nameconfigid")
                return value !== null ? +value : 0
            });

            // Get unique ids
            return ids
                .filter(item => item > 0)
                .filter((v, i, a) => a.indexOf(v) === i)
        }

        return []
    }

    blur = () => {
        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            Util.clearSelection()
            textarea.blur()
        }
    }
    setFocus = () => {
        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            textarea.focus()
        }
    }

    // Capture mouse down to prevent selection change capturing during dragging
    onMouseDown = () => {
        this.isMouseDown = true
        document.addEventListener('mouseup', this.onMouseUp)

        this.onFocus()
    }
    onMouseUp = () => {
        this.isMouseDown = false
        document.removeEventListener('mouseup', this.onMouseUp)

        this.onSelectionChanged()
    }

    onFocus = () => {
        if (this.isEditable()) {
            this.props.onFocus?.()
        }
    }
    onBlur = () => {
        if (this.props.fireUpdateOnBlur) {
            this.updateElementContent()
        }
        // this.setState({selection: undefined})

        this.setTextOverflow()
    }

    serializeElementData = (data: WDElementBaseData) => {
        return WDTextboxData.serialize(data as WDTextboxData)
    }
    updateElementContent = () => {
        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            if (this.props.data.text !== textarea.innerHTML) {
                let newData = {...this.props.data}
                newData.text = textarea.innerHTML

                this.props.onUpdateElement(new WorksheetItemUpdate(
                        this.props.id, {content: this.serializeElementData(newData)}),
                    {actionCategory: WDActionLogCategory.content}
                )
            }
        }
    }

    onEditElement = async (editMode: boolean) => {
        if (!this.props.isIndependentElement) {
            return
        }

        if (editMode !== this.state.isEdited) {
            let textarea = document.getElementById("txt-" + this.props.id)
            if (textarea) {

                // Set focus when entering edit mode
                if (editMode) {
                    textarea.focus()
                } else {
                    let newData = {...this.props.data}
                    newData.text = textarea.innerHTML

                    await this.props.onUpdateElement(new WorksheetItemUpdate(
                            this.props.id, {content: this.serializeElementData(newData)}),
                        {actionCategory: WDActionLogCategory.content}
                    )

                    this.state.elementRef.current?.onStopEdit()
                    this.setState({selection: undefined})
                }
            }

            this.setState({isEdited: editMode}, () => {
                // Tell designer this is in edit mode
                // Cancels all event handlers to avoid element being dragged while in edit mode
                if (this.props.isIndependentElement) {
                    this.props.onElementEdit?.(this.props.id, editMode)
                }
            })
        }
    }

    onSelectionChanged = () => {
        if (!this.isMouseDown && !this.exceptionKeyPressed) {
            let textarea = document.getElementById("txt-" + this.props.id) as HTMLDivElement | null
            if (textarea && textarea.id === document.activeElement?.id) {
                const selection = Util.getSelection(textarea)
                if (selection) {
                    this.setState({selection: selection.start !== selection.end ? selection : undefined})
                }
            }
        }
    }

    removeEmptyTextDecorationColorRecursive = (element: HTMLElement, removeFromCurrent: boolean) => {
        for (let i = 0; i < element.childNodes.length; i++) {
            if (element.childNodes[i].nodeType !== Node.TEXT_NODE) {
                const childElement = element.childNodes[i] as HTMLElement

                // Remove style from element and all child elements
                this.removeEmptyTextDecorationColorRecursive(childElement, true)
            }
        }

        if (removeFromCurrent) {
            this.removeEmptyTextDecorationColor(element)
        }
    }
    removeEmptyTextDecorationColor = (element: HTMLElement) => {
        let style = element.getAttribute("style")

        // Check style combination
        if (element.style["text-decoration-color"] !== "" &&
            (element.style["text-decoration-line"] === "none" || (style && style.indexOf("text-decoration-line:") < 0 && style.indexOf("text-decoration:") < 0))) {

            element.style.removeProperty("text-decoration-color")
            element.style.removeProperty("text-decoration-style")
            element.style.removeProperty("text-decoration-thickness")

            // If there are no style attributes left, unwrap the node (remove and keep children)
            Util.removeEmptyStyle(element)
        }
    }

    setUnderlineStyle = (element: HTMLElement, color: string, lineStyle: string, thickness?: string) => {
        for (let i = 0; i < element.childNodes.length; i++) {
            if (element.childNodes[i].nodeType !== Node.TEXT_NODE) {
                const childElement = element.childNodes[i] as HTMLElement

                // Remove style from element and all child elements
                this.setUnderlineStyle(childElement, color, lineStyle, thickness)
            }
        }

        // if text decoration is "underline" and no color is set, set the current color
        if ((element.style["text-decoration-line"] === "underline" || element.style["text-decoration"] === "underline") &&
            (element.style["text-decoration-color"] === "" || element.style["text-decoration-color"] === "currentcolor")) {

            // get current attributes to set underline attributes separated (necessary for safari)
            let attributes = element.attributes.getNamedItem("style")?.value
            let attributeData: {
                [key: string]: string
            }[] = []

            if (attributes) {
                let tempArray = attributes.split(";")
                for (let i = 0; i < tempArray.length; i++) {
                    let value = tempArray[i];
                    let item = value.split(":")
                    if (item[0] !== "") {
                        attributeData[item[0].trim()] = item[1].trim()
                    }
                }
            }

            attributeData["text-decoration"] = "underline"
            attributeData["text-decoration-color"] = color
            attributeData["text-decoration-style"] = lineStyle
            attributeData["text-decoration-thickness"] = thickness

            let elementStyle = Object.keys(attributeData)
                .filter(item => attributeData[item] && item !== "text-decoration-line")
                .map(item => {
                    return item + ": " + attributeData[item]
                })
                .join('; ')

            // let elementStyle = "text-decoration-line: underline; text-decoration-color: " + color + "; text-decoration-style: " + lineStyle + "; text-decoration-thickness: " + thickness + ";"
            element.setAttribute("style", elementStyle)
        }
    }
    setLineHeight = (textarea: HTMLElement, data: any) => {
        const selection = window.getSelection()

        // Set numbering style based on key provided
        if (selection && selection.focusNode) {
            const selectedElement = selection.focusNode as HTMLElement

            let parent = Util.getParentByTag(selectedElement, "span|div|font", textarea.id)
            if (parent) {
                parent.style.lineHeight = data.lineHeight
            }
        }
    }

    generateBullet = (textarea: HTMLElement, data: any) => {
        // Set unordered list image based on image key provided (default: "DOTS")
        const image = CategoryImageValue.getImageByKey([ImageCategory.UNORDERED_BULLET_LIST_BULLET], data.key || "DOTS")
        const imageUrl = "url('" + process.env.PUBLIC_URL + ImagePath.getBulletsUrl() + image + "')"
        const selection = window.getSelection()

        if (image && selection && selection.focusNode) {
            const selectedElement = selection.focusNode as HTMLElement

            if (selectedElement.tagName && selectedElement.tagName.toUpperCase() === "UL") {
                selectedElement.id = "ul_" + Date.now()
                selectedElement.style.setProperty("--image", imageUrl)

                return selectedElement.id
            } else {
                // get li element and set id to be able to set selection later
                const listItem = Util.getParentByTag(selectedElement, "li", textarea.id)
                if (listItem) {
                    listItem.id = "li_" + Date.now()

                    // Get the list tag "above" the current node, stop at textbox which is the container
                    const listNode = Util.getParentByTag(listItem, "ul", textarea.id)
                    if (listNode) {
                        // Set CSS variable used in the before-pseudo tag of the css class
                        listNode.style.setProperty("--image", imageUrl)
                    }

                    return listItem.id
                } else {
                    let ul = textarea.querySelector("ul:not([style])") as HTMLElement | null
                    if (ul) {
                        ul.id = "ul_" + Date.now()
                        ul.style.setProperty("--image", imageUrl)

                        return ul.id
                    }
                }
            }
        }
    }
    generateNumbering = (textarea: HTMLElement, data: any) => {
        const selection = window.getSelection()

        // Set numbering style based on key provided
        if (selection && selection.focusNode) {
            const selectedElement = selection.focusNode as HTMLElement

            if (selectedElement.tagName && selectedElement.tagName.toUpperCase() === "OL") {
                selectedElement.id = "ol_" + Date.now()
                selectedElement.className = data.key.toLowerCase()

                return selectedElement.id
            } else {
                // get li element and set id to be able to set selection later
                const listItem = Util.getParentByTag(selectedElement, "li", textarea.id)
                if (listItem) {
                    listItem.id = "li_" + Date.now()

                    if (data.key) {
                        const listNode = Util.getParentByTag(listItem, "ol", textarea.id)
                        if (listNode) {
                            listNode.className = data.key.toLowerCase()
                        }
                    }

                    return listItem.id
                }
            }
        }
    }

    textToSyllableText = async (changeToSyllableText: boolean): Promise<WDTextboxData> => {
        let newData = {...this.props.data}
        newData.syllableActivated = changeToSyllableText

        return new Promise((resolve, reject) => {
            this.setState({syllableLoading: true}, async () => {
                if (changeToSyllableText) {
                    let textElement = document.getElementById("txt-" + this.props.id)
                    if (textElement) {
                        // Duplicate text html and remove all formatting
                        let div = document.createElement("div")
                        div.innerHTML = textElement.innerHTML
                        Util.removeHtmlFormatting(div)
                        let text = div.innerHTML

                        // Splits text by space or any non-word character or html tag
                        // eslint-disable-next-line
                        let textArray = text.split(/([,.\s"!?:;_'\[\]{}()<>\\|\n\t]|&nbsp;)/g).filter(s => s.length > 0)

                        // Prepares all words for syllabification (array for sending to backend)
                        // Convert to set and again to array to remove duplicates
                        let findSyllableArray: Syllable[] = [...new Set(textArray)]
                            .filter(s => s.length > 1 && !s.match(new RegExp(/^(div|br|p|\/div|\/br|\/p|&nbsp;)$/)) && !s.match(new RegExp(/^[0-9]*$/)))
                            // Remove all words from findSyllableArray which were found previously in database (reduce amount of searching in database)
                            // or where entered manually by user (check that syllabification of user is same as originalValue because he could have typed anything
                            // z.B. original value = text, user input = zei/le => wrong for further usage)
                            .filter(word => !this.state.syllableArray.find(
                                arr => arr.originalValue === word && arr.originalValue.toLowerCase() === arr.syllabification?.replace("/", "").toLowerCase()))
                            .map(word => new Syllable(undefined, word, undefined))

                        // Get new words from database
                        if (findSyllableArray.length > 0) {
                            GetSyllabification(findSyllableArray, this.state.userSettings?.syllable || SyllableMethod.syllableDivide).then(
                                async (itemData) => {
                                    // #4524 remove unused words from array - otherwise user might get ask (manual dialog) for words which are not in the text anymore
                                    let syllableArrayWithoutUnusedWords = this.state.syllableArray.filter(word => textArray.find(arr => arr === word.originalValue))
                                    let newSyllableArray = itemData.concat(syllableArrayWithoutUnusedWords)

                                    // Syllabification, replace words and join split text array
                                    textArray = this.setSyllables(textArray, newSyllableArray, false)
                                    newData.text = textArray.join("")
                                    newData.syllableActivated = true

                                    this.setState({
                                        syllableLoading: false,
                                        syllableArray: newSyllableArray,
                                        currentTextArray: textArray
                                    }, () => {
                                        if (!this.props.isIndependentElement && this.props.onFinishSyllabification) {
                                            this.props.onFinishSyllabification(this.getNotFoundWords())
                                        }
                                    })

                                    resolve(newData)
                                },
                                (error) => {
                                    this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
                                    reject(error)
                                })
                        } else {
                            // Syllabification, replace words and join split text array
                            textArray = this.setSyllables(textArray, this.state.syllableArray, false)
                            newData.text = textArray.join("")
                            newData.syllableActivated = true

                            this.setState({
                                syllableLoading: false,
                                syllableManuallyClosedDialog: false,
                                currentTextArray: textArray
                            })

                            resolve(newData)
                        }
                    }
                } else {
                    // Remove all syllable tags
                    let textElement = document.getElementById("txt-" + this.props.id)

                    if (textElement) {
                        // Duplicate text html and remove all formatting
                        let div = document.createElement("div")
                        div.innerHTML = textElement.innerHTML
                        Util.removeHtmlFormatting(div)

                        newData.text = div.innerHTML
                        newData.syllableActivated = false

                        this.setState({syllableLoading: false, syllableManuallyClosedDialog: false})

                        resolve(newData)
                    }
                }
            })
        })
    }
    addSyllableTextManually = async (originalWord: string, currentSyllableManually: string) => {
        let newData = {...this.props.data}
        let syllables = this.state.syllableArray.map(s => {
            if (s.originalValue === originalWord) {
                s.syllabification = currentSyllableManually
            }
            return s
        })

        // Syllabification, replace words and join split text array
        let textArray = this.setSyllables(this.state.currentTextArray, syllables, true)
        newData.text = textArray.join("")
        newData.syllableActivated = true

        // TODO: collect updates in table and send them all at once
        let update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
        this.props.onUpdateElement(update, {
            historyAction: WDHistoryAction.CONTENT_CHANGED,
            actionCategory: WDActionLogCategory.content
        })

        this.setState({
            syllableLoading: false,
            syllableManuallyClosedDialog: false,
            currentTextArray: textArray,
        })
    }
    closeSyllableNotFoundDialog = () => {
        this.setState({syllableManuallyClosedDialog: true})
    }
    setSyllables = (textArray: string[], syllableArray: Syllable[], overwrite: boolean): string[] => {
        let currentWord = -1

        return textArray.map(word => {
            currentWord++

            let syllableWord = syllableArray.find(result => result.originalValue === word)

            if (syllableWord && syllableWord.syllabification) {
                let wordWithSyllable = "";

                if (overwrite) {
                    // Overwrite word with syllabification to show user input (manually) exactly
                    wordWithSyllable = syllableWord.syllabification

                } else {
                    // Manipulate word instead of using syllabification to keep upper and lower letters when searching in database
                    let syllables = syllableWord.syllabification.split("/")
                    let startCharIndex = 0

                    for (let i = 0; i < syllables.length; i++) {
                        wordWithSyllable = wordWithSyllable.concat(syllableWord.originalValue!.substring(startCharIndex, startCharIndex + syllables[i].length))
                        if (i < syllables.length - 1) {
                            wordWithSyllable = wordWithSyllable.concat("/")
                        }
                        startCharIndex += syllables[i].length
                    }
                }

                return ReactDOMServer.renderToStaticMarkup(
                    <WDSyllableWord syllableWord={wordWithSyllable}
                                    syllableDefinition={
                                        new SyllableDefinition(true,
                                            this.state.userSettings?.syllableColor1 || Const.COLOR_PRIMARY,
                                            this.state.userSettings?.syllableColor2 || Const.COLOR_RED
                                        )}
                    />
                )
            } else if (((word.length === 1 && !word.match(new RegExp(/^(<|>|&nbsp|\s|\n|\t|\r)$/)))
                    && !(word.length === 1 && word.match(/^p$/) && textArray[currentWord - 1] !== null && textArray[currentWord - 1].match(/^<$/)))
                || word.match(new RegExp(/^[0-9]*$/))
            ) {
                return ReactDOMServer.renderToStaticMarkup(
                    <WDSyllableWord syllableWord={word}
                                    syllableDefinition={
                                        new SyllableDefinition(true,
                                            this.state.userSettings?.syllableColor1 || Const.COLOR_PRIMARY,
                                            this.state.userSettings?.syllableColor2 || Const.COLOR_RED
                                        )}
                    />
                )
            }
            return word
        })
    }
    getNotFoundWords = () => {
        return this.state.syllableArray.filter(
            s => s.syllabification === null
        )
    }
    doAction = (action: WDToolbarAction, data: any) => {
        let update = new WorksheetItemUpdate(this.props.id, {})
        // In a locked state marking a solution as an admin is the only possible action
        if (this.props.element.locked && action !== WDToolbarAction.SOLUTION && action !== WDToolbarAction.CHANGE_SOLUTION_SHOW) {
            return update
        }

        this.context.log.info("Textbox Action: " + action + " on " + this.props.id)

        let selectedNodeId: string | undefined = undefined
        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            let wholeText = false
            // when wholeText property is set, apply action to the whole text
            if (data && data.wholeText) {
                wholeText = true
            } else {
                if (action !== WDToolbarAction.CHANGE_SOLUTION_SHOW) {
                    // Restore selection for safari
                    if (this.state.selection) { // && browser?.name === 'safari') {
                        Util.setSelection(textarea, this.state.selection, selectedNodeId)
                    } else {
                        wholeText = true
                    }
                }
            }

            if (wholeText) {
                Util.selectAllText(textarea)
            }

            // let activeElement = document.activeElement as HTMLElement

            // console.log("Before: " + JSON.stringify(this.props.data))
            // console.log("Action = " + action + ", data = " + JSON.stringify(data))
            let newData = {...this.props.data}
            switch (action) {
                case WDToolbarAction.FONT_TYPE:
                    // Setting to apply all styles without CSS
                    document.execCommand('styleWithCSS', false, 'true')

                    // Apply command to set font name
                    document.execCommand("fontName", false, data.name);
                    break;
                case WDToolbarAction.FONT_SIZE:
                    // Dummy font size value, replaced later
                    const fontSizeValue = "7"

                    // Setting to apply all styles without CSS
                    document.execCommand('styleWithCSS', false, 'false')

                    // Apply command to set font size
                    document.execCommand("fontSize", false, fontSizeValue);

                    // Find tag with "size" attribute and replace by font-size style in pt
                    let tags = textarea.querySelectorAll("font[size='" + fontSizeValue + "']")
                    for (let i = 0; i < tags.length; i++) {
                        const tag = tags[i] as HTMLElement

                        tag.removeAttribute("size");
                        tag.style.fontSize = data.size + "pt";

                        // Remove subsequent font-size settings so the new font size is applied to everything
                        // below this DOM node
                        Util.removeStyleFromElementsRecursive(tag, "font-size", false, [])
                    }

                    // reformat code to reduce number of HTML tags and apply formatting to li elements
                    // if the whole text was selected
                    Util.reformatHtml(textarea, "font-size")

                    break;
                case WDToolbarAction.FONT_COLOR:
                    document.execCommand('styleWithCSS', false, 'true')
                    document.execCommand(data.command, false, data.color);

                    // reformat code to reduce number of HTML tags and apply formatting to li elements
                    // if the whole text was selected
                    Util.reformatHtml(textarea, "color")
                    break;

                case WDToolbarAction.HILITE_COLOR:
                    document.execCommand('styleWithCSS', false, 'false')
                    document.execCommand(data.command, false, data.color);

                    // reformat code to reduce number of HTML tags and apply formatting to li elements
                    // if the whole text was selected
                    Util.reformatHtml(textarea, "background-color")

                    break;

                case WDToolbarAction.LINE_HEIGHT:
                    // document.execCommand('styleWithCSS', false, 'true')
                    // document.execCommand("increaseFontSize", false);

                    this.setLineHeight(textarea, data)

                    break;

                case WDToolbarAction.ORDERED_LIST:
                case WDToolbarAction.UNORDERED_LIST:
                    if (data.key !== "NONE" || document.queryCommandState(data.command)) {
                        // Apply command
                        document.execCommand('styleWithCSS', false, 'false')
                        document.execCommand(data.command, false)

                        if (action === WDToolbarAction.ORDERED_LIST) {
                            selectedNodeId = this.generateNumbering(textarea, data)
                        } else {
                            selectedNodeId = this.generateBullet(textarea, data)
                        }
                    }
                    break;
                case WDToolbarAction.UNORDERED_LIST_IMAGE:

                    // If style = NONE => Remove bullet OR
                    // If there is no list yet, create one
                    if (data.key === "NONE" || !document.queryCommandState(data.command)) {
                        document.execCommand('styleWithCSS', false, 'false')
                        document.execCommand(data.command, false)
                    }

                    // If style != NONE => Set bullet image
                    if (data.key !== "NONE") {
                        selectedNodeId = this.generateBullet(textarea, data);
                    }
                    break;
                case WDToolbarAction.ORDERED_LIST_IMAGE:

                    // If style = NONE => Remove bullet OR
                    // If there is no list yet, create one
                    if (data.key === "NONE" || !document.queryCommandState(data.command)) {
                        document.execCommand('styleWithCSS', false, 'false')
                        document.execCommand(data.command, false)
                    }

                    // If style != NONE => Set bullet image
                    if (data.key !== "NONE") {
                        selectedNodeId = this.generateNumbering(textarea, data)
                    }
                    break;

                case WDToolbarAction.UNDERLINE_STYLE:
                    const isUnderlined = document.queryCommandState(data.command)

                    // toggle underline style twice so the color is applied afterward
                    document.execCommand('styleWithCSS', false, 'true')
                    document.execCommand("underline", false)
                    if (isUnderlined) {
                        document.execCommand("underline", false)
                    }

                    this.removeEmptyTextDecorationColorRecursive(textarea, false)
                    this.setUnderlineStyle(textarea, data.color, data.lineStyle, data.thickness)

                    break;

                case WDToolbarAction.CONVERT_TO_LINE:
                    if (this.props.onConvertElement) {
                        let textarea = document.getElementById("txt-" + this.props.id)
                        if (textarea) {
                            const lineatureData = new WDWritingLineatureData(textarea.innerText,
                                new WDTextboxData(textarea.innerText, false, false, VerticalAlignment.top),
                                "D-AT-10-STANDARD", getDefaultFontName(),
                                WDWritingLineatureSize["10"], 5, getDefaultCorrectionMargin(), 0)

                            this.props.onConvertElement(this.props.id, WorksheetItemTypeEnum.WRITING_LINEATURE, JSON.stringify(lineatureData))
                        }
                    }
                    break
                case WDToolbarAction.CONVERT_TO_SYLLABLE:
                    if (data && data["syllableActivated"] !== undefined) {
                        this.textToSyllableText(data["syllableActivated"]).then(
                            (newData) => {
                                update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
                                this.props.onUpdateElement(update, {
                                    historyAction: WDHistoryAction.CONTENT_CHANGED,
                                    actionCategory: WDActionLogCategory.content
                                })
                            }
                        )
                    }
                    break

                case WDToolbarAction.SOLUTION:
                    // Set textarea editable so the execCommands sent work
                    textarea.contentEditable = "true";

                    // Apply command
                    document.execCommand('styleWithCSS', false, 'false')
                    document.execCommand("italic", false)

                    let smallTags = textarea.querySelectorAll("i")
                    for (let i = 0; i < smallTags.length; i++) {
                        const tag = smallTags[i] as HTMLElement

                        let solution = document.createElement("div")
                        solution.innerHTML = tag.innerHTML
                        solution.className = "ws-designer-element-solution"
                        Util.replaceNode(tag, solution)

                        // if the only element inside a solution is variable text swap the html tags so the solution
                        // is INSIDE the variable text
                        if (solution.childElementCount === 1) {
                            let firstChild = (solution.firstChild as HTMLElement)
                            if (firstChild.tagName && firstChild.tagName.toUpperCase() === "SPAN" && firstChild.className === "variable-text") {

                                Util.unwrapNode(solution)

                                let variableText = firstChild.innerHTML;
                                firstChild.innerHTML = ""

                                solution = document.createElement("div")
                                solution.innerHTML = variableText
                                solution.className = "ws-designer-element-solution"
                                firstChild.appendChild(solution)
                            }
                        }
                    }

                    // set content editable based on locked property
                    textarea.contentEditable = this.props.element.locked ? "false" : "true";
                    break

                case WDToolbarAction.CHANGE_SOLUTION_SHOW:
                    newData.showSolution = !newData.showSolution
                    break

                case WDToolbarAction.INSERT_GLYPH:
                    selectedNodeId = this.insertGlyph(data.code)

                    // Set selection to the inserted glyph
                    let selectionStart = this.state.selection?.start || 0
                    this.setState({selection: new Selection(selectionStart + 1, selectionStart + 1)})
                    break

                case WDToolbarAction.VERTICAL_ALIGN:
                    newData.verticalAlignment = data.verticalAlign
                    break

                default:
                    // Setting to apply all styles with CSS (except subscript and superscript)
                    const renderAsCss: boolean = !(action === WDToolbarAction.SUBSCRIPT || action === WDToolbarAction.SUPERSCRIPT)
                    document.execCommand('styleWithCSS', false, renderAsCss ? 'true' : 'false')

                    // Apply command
                    document.execCommand(data.command, false)

                    // set underline color
                    if (action === WDToolbarAction.UNDERLINE && data.color && data.lineStyle) {
                        this.removeEmptyTextDecorationColorRecursive(textarea, false)
                        this.setUnderlineStyle(textarea, data.color, data.lineStyle, data.thickness)
                    }
                    break;
            }
            this.context.log.flush()

            // Skip update for syllabification (asynchronous)
            if (action !== WDToolbarAction.CONVERT_TO_SYLLABLE && action !== WDToolbarAction.CONVERT_TO_LINE) {
                newData.text = textarea.innerHTML
                update.value.content = this.serializeElementData(newData)
            }

            // restore selection
            if (wholeText) {
                Util.clearSelection()
            } else if (this.state.selection && action !== WDToolbarAction.INSERT_GLYPH) {
                Util.setSelection(textarea, this.state.selection, selectedNodeId)
            }

            this.setTextOverflow()
        }
        this.context.log.flush()

        return update
    }
    queryCommandState = (command: string) => {
        let textarea = document.getElementById("txt-" + this.props.id)
        if (textarea) {
            Util.selectAllText(textarea)
            let commandState = document.queryCommandState(command)
            Util.clearSelection()

            return commandState
        }

        return false
    }

    insertGlyph = (code: string) => {
        return this.insertTextElement(code, true)
    }
    insertTextElement = (text: string, select: boolean) => {
        return Util.addElementAtSelection(document.createTextNode(text), select)
    }
    insertHTMLElement = (element: HTMLSpanElement) => {
        return Util.addElementAtSelection(element)
    }
    onAddVariableText = (onInput: (e: Event) => void) => {
        let selectedText = Util.getSelectedText()

        if (selectedText && selectedText.length > 0) {
            let variable = document.createElement("input")
            variable.id = "vt-" + WorksheetItem.getNewItemKey()
            variable.className = "variable-text"
            variable.type = "text"
            variable.size = selectedText.length
            variable.readOnly = false
            variable.disabled = false

            variable.setAttribute("value", selectedText)
            variable.addEventListener("input", onInput)

            // Add name tag at the current cursor position
            Util.addElementAtSelection(variable)

            return variable.id
        }
    }

    renderSolution = () : boolean => {
        if (this.props.inPresentationMode) {
            return this.props.element.renderSolutionInPresentationMode
        } else {
            return (this.props.solutionForceMode === SolutionForceMode.ForceShow ||
                (this.props.solutionForceMode === SolutionForceMode.Off && this.props.data.showSolution))
        }
    }

    render() {
        let textbox: JSX.Element
        let loading = <></>
        let syllableManually = <></>
        let overlay = <></>
        let notFoundWords: Syllable[] = []
        let renderValue: JSX.Element

        let textboxClassName = "ws-designer-textbox"
        if (this.props.className) {
            textboxClassName = this.props.className
        }
        if (this.state.isEdited && this.props.isIndependentElement) {
            textboxClassName += " ws-designer-textbox-edit"
        }
        textboxClassName += " print"

        // Only show manuallyDialog if textbox is not a sub element, or it is sub element but syllabification is handled as a whole in textbox (balloon)
        if (this.props.isIndependentElement || this.props.onFinishSyllabification === undefined) {
            // Check if loading should be active
            if (this.state.syllableLoading) {
                loading = <div className={"ws-designer-textbox-loading-container"}>
                    <Hint id={"list-loading"}
                          notificationData={new NotificationData(NotificationStatus.loading, this.context.translate(translations.notification.loading))}/>
                </div>
            }

            // Check if there are words which could not be found in database for syllabification
            if (this.props.data.syllableActivated && !this.state.syllableManuallyClosedDialog && this.state.syllableArray && this.props.element.selected) {
                notFoundWords = this.getNotFoundWords()

                if (notFoundWords.length > 0) {
                    overlay = <div className={"ws-designer-overlay"}/>

                    syllableManually = <SyllableManualDialog
                        notFoundWords={notFoundWords}
                        colorFirstSyllable={this.state.userSettings?.syllableColor1 || Const.COLOR_PRIMARY}
                        colorSecondSyllable={this.state.userSettings?.syllableColor2 || Const.COLOR_RED}
                        closeSyllableNotFoundDialog={this.closeSyllableNotFoundDialog}
                        addSyllableTextManually={this.addSyllableTextManually}/>
                }
            }
        }

        textboxClassName += (this.props.element.locked && this.props.context !== WSContextType.text_exercise_child ? " prevent-selection" : " allow-selection")
        if (!this.renderSolution()) {
            textboxClassName += " ws-designer-textbox-no-solution"
        }

        let style: CSSProperties = {...this.props.style} || {}
        style.width = this.props.element.width
        style.height = this.props.element.height
        if (this.props.data.verticalAlignment === VerticalAlignment.middle) {
            style.verticalAlign = "middle"
        } else if (this.props.data.verticalAlignment === VerticalAlignment.bottom) {
            style.verticalAlign = "bottom"
        } else if (this.props.data.verticalAlignment === VerticalAlignment.top) {
            style.verticalAlign = "baseline"
        }

        let marginLeft: number, marginRight: number, marginTop: number, marginBottom: number

        marginLeft = this.props.element.paddingLeft || 0
        marginTop = this.props.element.paddingTop || 0
        marginRight = this.props.element.paddingRight || 0
        marginBottom = this.props.element.paddingBottom || 0

        if (this.props.isIndependentElement) {
            style.marginLeft = marginLeft + "px"
            style.marginTop = marginTop + "px"
            style.marginRight = marginRight + "px"
            style.marginBottom = marginBottom + "px"
        }

        if (style.marginTop) {
            marginTop = +style.marginTop.toString().replace("px", "")
        }
        if (style.marginBottom) {
            marginTop = +style.marginBottom.toString().replace("px", "")
        }

        style.width = this.props.element.width - (marginLeft + marginRight)
        style.height = this.props.element.height - (marginTop + marginBottom)

        textbox = <div className={textboxClassName}
                       style={style}
                       contentEditable={this.isEditable()}
                       spellCheck={this.props.hasSpellCheck}
                       id={"txt-" + this.props.id}
                       placeholder={this.state.showNonPrintableObjects ? this.props.placeholder : undefined}
                       dangerouslySetInnerHTML={{__html: this.props.data.text}}
                       onInput={this.onTextInput}
                       onKeyDown={this.onKeyDown}
                       onKeyUp={this.onKeyUp}
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onMouseDown={this.onMouseDown}
        />

        // check if container is needed for rendering (textbox alone or within other element?)
        if (this.props.isIndependentElement) {
            renderValue = <WDElementContainer id={this.props.id}
                                              element={this.props.element}
                                              hasResizeOnCreate={this.props.hasResizeOnCreate}
                                              renderWrapper={true}
                                              onUnlockElement={this.unlockElement}
                                              onEdit={this.onEditElement}
                                              resizeInfo={this.resizeNodesTextBox}
                                              resizerState={this.getAutoResizerState()}
                                              onResizeStateChanged={this.props.onResizeStateChanged}
                                              onResizeElement={this.onResizeElement}
                                              isEditModeAllowed={this.isEditModeAllowed}
                                              isReadOnly={this.props.isReadOnly || this.state.syllableLoading}
                                              onContextMenu={this.props.onContextMenu}
                                              onAutoResize={this.onAutoResize}
                                              ref={this.state.elementRef}
            >
                {textbox}
                {loading}
                {syllableManually}
                {overlay}

            </WDElementContainer>

        } else {
            renderValue = <>
                {textbox}
                {loading}
                {syllableManually}
                {overlay}
            </>
        }

        return renderValue
    }
}
