import React from 'react';
import translations from "../../Framework/translations.json";
import {AdminObjectType, Status} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {MainContext} from "../../_base/MainContext";
import User from "../../_model/User";
import {RouteComponentProps} from "react-router-dom";
import {ListFilter} from "../../Components/List/ListFilter";
import {AdminDataListSortSettings} from "./AdminDataListSortSettings";
import Entity from "../../_model/Entity";

export interface AdminDataListBaseProps extends RouteComponentProps {
}

export interface AdminDataListBaseState {
    page: number
    pageSize: number
    search?: string

    sortField: string
    sortDescending: boolean

    itemCount: number
    items: Entity[]
    listItems?: Entity[]
}

export default class AdminDataListBase<T extends AdminDataListBaseProps = AdminDataListBaseProps,
    U extends AdminDataListBaseState = AdminDataListBaseState> extends React.Component<T, U> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    componentDidMount() {
        this.context.getUserSettings().then((userSettings) => {
            let listPageSize = userSettings.listPageSize > 0 ? userSettings.listPageSize : 100

            this.setState({ pageSize: listPageSize }, () => this.fetchData(this.state.search))
        })
    }

    getCurrentSortValue = (adminObjectType: AdminObjectType) => {
        let sortValue: AdminDataListSortSettings | undefined = this.context.getListSortValue(adminObjectType)
        if (sortValue === undefined) {
            sortValue = new AdminDataListSortSettings(
                adminObjectType,
                "modifiedOn",
                true
            )
        }

        return sortValue
    }
    getCurrentSearchTerm = (adminObjectType: AdminObjectType) => {
        let filter: ListFilter | undefined = this.context.getListFilter(adminObjectType)
        if (filter !== undefined) {
            return filter.searchString
        }
    }

    retrieveData = (search: string | undefined) => {

    }

    fetchData = (search: string | undefined) => {
        this.setState({items: [], listItems: undefined})

        this.retrieveData(search)
    }

    cloneItem = async(itemId: number) => {

    }

    onCopyItem = async (items: number[]) => {
        try {
            for (const id of items) {
                await this.cloneItem(id)
            }

            this.fetchData(this.state.search)
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
        }
    }

    onSetState = async (item: number, status: Status, user?: User) => {
        return false
    }
    onRefresh = () => {
        this.fetchData(this.state.search)
    }
    onPageChange = (page: number) => {
        let pageCount = Math.ceil(this.state.itemCount / this.state.pageSize)

        if (page > 0 && page <= pageCount) {
            this.setState({page: page}, () => this.fetchData(this.state.search))
        }
    }
    onSearch = (search: string) => {
        this.setState({page: 1, search: search}, () => this.fetchData(search))
    }
    onSort = (fieldName: string, descending: boolean) => {
        this.setState({sortField: fieldName, sortDescending: descending}, () => this.fetchData(this.state.search))
    }
}

