import React from "react";
import {Redirect} from "react-router-dom";
import {Tooltips} from "../Tooltips";
import {RibbonButtonData} from "./RibbonButtonData";

interface IProps {
    data: RibbonButtonData

    colorIconPrimary: boolean
}

interface IState {
    redirect: boolean
}

export class RibbonButton extends React.Component<IProps, IState> {

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {redirect: false}
    }

    onToggle = () => {
        this.props.data.onClick?.()

        if(this.props.data.navigateTo) {
            this.setState({redirect: true})
        }
    }

    render() {
        let className = this.props.data.tooltip ? "tooltip-below" : undefined
        let classNameButton = "ribbon-button" + (this.props.data.tooltip ? " tooltip-control" : "")
        let classNameImage = this.props.colorIconPrimary ? "svg-color-primary" : undefined

        return <div className={className}>
            <div id={this.props.data.id} className={classNameButton} onClick={this.onToggle}>
                <img src={this.props.data.icon}
                     className={classNameImage}
                     alt={this.props.data.text}
                     draggable={"false"}
                     onContextMenu={(e) => e.preventDefault()}
                />
                {this.props.data.text && <div>{this.props.data.text}</div> }

                {this.state.redirect && <Redirect to={this.props.data.navigateTo!} push={true}/>}
            </div>

            {this.props.data.tooltip &&
                <Tooltips text={this.props.data.tooltip}
                          translateX={-75 + ((this.props.data.text?.length || 0) * 3)}
                          translateY={0}/>}
        </div>
    }
}

