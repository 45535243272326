import React, {CSSProperties} from "react";
import {Hint, IHintProps} from "./Hint";

interface IFloatingHintProps extends IHintProps {
    style: CSSProperties
    animationTimeout?: number

    onHide?: () => void
}

export class FloatingHint extends React.Component<IFloatingHintProps> {

    render() {
        let timeOut = (this.props.animationTimeout !== undefined ? this.props.animationTimeout : 1500)

        let style = this.props.style
        if (this.props.onHide) {
            style.animation = " fade-out 1.2s forwards"
            style.animationDelay = timeOut + "ms"

            setTimeout(this.props.onHide, timeOut + 500)
        }

        return <div className={"hint-floating"} style={style}>
            <Hint id={this.props.id} notificationData={this.props.notificationData}/>
        </div>
    }
}

