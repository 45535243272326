export class TutorialStepData {
    index: number
    showNextButton: boolean = true

    constructor(index: number, showNextButton: boolean = true) {
        this.index = index
        this.showNextButton = showNextButton
    }
}

export class TutorialData {
    id: number
    tutorialKey: string
    data: TutorialStepData[]
    currentStep: number = 0

    constructor(id:number, key: string, data: TutorialStepData[], currentStep: number = 0) {
        this.id = id
        this.tutorialKey = key
        this.data = data
        this.currentStep = currentStep
    }
}