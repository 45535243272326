import React, {CSSProperties} from "react";
import translations from "../../../Framework/translations.json";
import {MainContext} from "../../../_base/MainContext";
import {Worksheet} from "../../../_model/Worksheet";
import {WorksheetItem} from "../../../_model/WorksheetItem";
import {
    GetDictionaryFiltered,
    GetWord,
    GetWordHighlights,
    GetWordKitFiltered,
    GetWordTopics
} from "../../../_endpoint/WordEndpoint";
import {SidebarFilterBase} from "../SidebarFilterBase";
import {SidebarAdvancedFilterArrow} from "../SidebarAdvancedFilterArrow";
import {
    SidebarContentBase,
    SidebarContentBaseListFilter,
    SidebarContentBaseProps,
    SidebarContentBaseState
} from "../SidebarContentBase";
import Dictionary from "../../../_model/Dictionary/Dictionary";
import {SidebarDictionaryListEntry} from "./SidebarDictionaryListEntry";
import {SidebarDictionaryWordForm} from "./SidebarDictionaryForm/SidebarDictionaryWordForm";
import Word, {SyllableMethod, WordDefinition} from "../../../_model/Dictionary/Word";
import {
    Difficulty,
    EnumToEntityArray,
    EnumValuesToEntityArray,
    EnumValueToValueNumber,
    NotificationStatus,
    VerticalAlignment
} from "../../../Framework/Enums";
import SelectBox from "../../../Components/Controls/SelectBox";
import Entity from "../../../_model/Entity";
import WordTopic from "../../../_model/Dictionary/WordTopic";
import {GenderEnum} from "../../../_model/Dictionary/Noun";
import CheckBox from "../../../Components/Controls/CheckBox";
import CheckBoxList from "../../../Components/Controls/CheckBoxList";
import WordHighlight from "../../../_model/Dictionary/WordHighlight";
import TextBox from "../../../Components/Controls/TextBox";
import Tag from "../../../_model/Tag";
import {ButtonInfo, ButtonList} from "../../../Components/Controls/ButtonList";
import {WDTextboxData} from "../../Elements/Textbox/WDTextbox";
import Converter from "../../../Framework/Converter";
import {Hint, NotificationData} from "../../../Components/Notification/Hint";
import {WDTableCellData, WDTableColumnData, WDTableData, WDTableRowData} from "../../Elements/Table/WDTableData";
import {SyllableDefinition} from "../../Elements/Textbox/WDSyllableWord";
import {SidebarFilterButtonData} from "../SidebarFilterButtonData";
import {WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import {WDUtils} from "../../Utils/WDUtils";
import {TooltipPosition} from "../../../Components/Tooltips";

export enum WordFilterCharAmountDefinitionEnum {
    exact = "exact",
    minimum = "minimum",
    maximum = "maximum"
}
export class WordFilter extends SidebarContentBaseListFilter {
    syllableSpeak?: boolean
    syllableDivide?: boolean
    wordDefinition?: WordDefinition
    difficulty?: Difficulty
    wordTopicId?: number
    highlightId?: number
    gender?: GenderEnum
    phonemic?: boolean
    basicVocabulary?: boolean
    foreignWord?: boolean
    exceptionWord?: boolean

    // This data is not sent to backend!!
    wordKitUsed: boolean
    foreignWordShow: Entity[]
    exceptionWordShow: Entity[]
    vocabularyWordShow: Entity[]
    phonemicWordShow: Entity[]

    wordFilterStart: string
    wordFilterMiddle: string
    wordFilterEnd: string
    wordFilterCharAmountDefinition: string
    wordFilterCharAmount?: number
    wordFilterLearnedLetters: string
    resultAmount: number

    constructor() {
        super();

        this.syllableSpeak = false
        this.syllableDivide = false
        this.wordKitUsed = false
        this.wordFilterStart = ""
        this.wordFilterMiddle = ""
        this.wordFilterEnd = ""
        this.wordFilterCharAmountDefinition = WordFilterCharAmountDefinitionEnum.exact
        this.wordFilterLearnedLetters = ""
        this.resultAmount = 20

        this.foreignWordShow = []
        this.exceptionWordShow = []
        this.vocabularyWordShow = []
        this.phonemicWordShow = []
    }
}

interface IProps extends SidebarContentBaseProps {
    worksheetId: Worksheet
    currentPageKey: string

    addElementToDesigner: (element: WorksheetItem) => void
}
interface IState extends SidebarContentBaseState {
    filter: WordFilter

    syllableDefinition: SyllableDefinition

    wordList: Dictionary[]
    wordKitList: Tag[]

    showWordFilterCharAmountInvalidDialog: boolean
    resultHint?: string
}

export class SidebarDictionary extends SidebarContentBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    userSettingSyllable: SyllableMethod = SyllableMethod.syllableDivide

    wordTopics: WordTopic[] = []
    wordHighlights: WordHighlight[] = []
    wordDefinitionData: Entity[] = []
    difficultyData: Entity[] = []
    genderData: Entity[] = []

    foreignData: Entity[] = [
        new Entity(this.context.translate(translations.fields.word.foreign_show), 0),
        new Entity(this.context.translate(translations.fields.word.native_show), 1)
    ]
    exceptionData: Entity[] = [
        new Entity(this.context.translate(translations.fields.word.exceptions), 0),
        new Entity(this.context.translate(translations.fields.word.norm_show), 1)
    ]
    vocabularyData: Entity[] = [
        new Entity(this.context.translate(translations.fields.word.basic_vocabulary), 0),
        new Entity(this.context.translate(translations.fields.word.extended_vocabulary), 1)
    ]
    phonemicData: Entity[] = [
        new Entity(this.context.translate(translations.fields.word.phonemic_words), 0),
        new Entity(this.context.translate(translations.fields.word.phonemic_no_words), 1)
    ]

    letterAmountDefinition: Entity[] = []

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            filterHistory: [],
            filterHistoryIndex: 0,
            page: 0,
            lastItem: false,
            wordList: [],
            wordKitList: [],
            filter: new WordFilter(),
            advancedSettingsVisible: false,
            syllableDefinition: new SyllableDefinition(false, "#6d80bf", "#D8394C"),
            showWordFilterCharAmountInvalidDialog: false,
        }
        this.loading = true

        this.wordDefinitionData.push(new Entity("- " + this.context.translate(translations.text.all) + " -", -1))
        this.wordDefinitionData = this.wordDefinitionData.concat(EnumToEntityArray(WordDefinition, translations.enum.wordDefinition, this.context.translate))

        this.difficultyData.push(new Entity("- " + this.context.translate(translations.text.all) + " -", -1))
        this.difficultyData = this.difficultyData.concat(
            EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate).filter(d => d.name !== ""))

        this.genderData.push(new Entity("- " + this.context.translate(translations.text.all) + " -", -1))
        this.genderData = this.genderData.concat(EnumToEntityArray(GenderEnum, translations.enum.noun_gender, this.context.translate))

        this.state.filter.foreignWordShow = this.initializeForeign()
        this.state.filter.exceptionWordShow = this.initializeException()
        this.state.filter.vocabularyWordShow = this.initializeVocabulary()
        this.state.filter.phonemicWordShow = this.initializePhonemic()

        this.letterAmountDefinition = EnumValuesToEntityArray(
            WordFilterCharAmountDefinitionEnum,
            ["exact", "minimum", "maximum"],
            translations.enum.word_filter_amount_definition, this.context.translate)
    }

    componentDidMount() {
        this.context.getUserSettings().then (userSettings => {
            this.userSettingSyllable = userSettings.syllable

            let newFilter = this.state.filter
            let newSyllable = this.state.syllableDefinition

            if(userSettings.syllable === SyllableMethod.syllableSpeak) {
                newFilter.syllableSpeak = true
                newFilter.syllableDivide = false
                newSyllable.showSyllable = newFilter.syllableSpeak || newFilter.syllableDivide || false

            } else if(userSettings.syllable === SyllableMethod.syllableDivide) {
                newFilter.syllableSpeak = false
                newFilter.syllableDivide = true
                newSyllable.showSyllable = newFilter.syllableSpeak || newFilter.syllableDivide || false
            }

            newSyllable.syllableColor1 = userSettings.syllableColor1
            newSyllable.syllableColor2 = userSettings.syllableColor2

            this.setState({filter: newFilter, syllableDefinition: newSyllable}, () => {
                this.fetchData(true)
                this.fetchWordTopics()
                this.fetchWordHighlights()
            })
        })
    }

    /** Initialize values **/
    initializeForeign = () : Entity[] => {
        return [new Entity(this.context.translate(translations.fields.word.foreign_show), 0),
            new Entity(this.context.translate(translations.fields.word.native_show), 1)]
    }
    initializeException = () : Entity[] => {
        return [new Entity(this.context.translate(translations.fields.word.exceptions), 0),
            new Entity(this.context.translate(translations.fields.word.norm_show), 1)]
    }
    initializeVocabulary = () : Entity[] => {
        return [new Entity(this.context.translate(translations.fields.word.basic_vocabulary), 0),
            new Entity(this.context.translate(translations.fields.word.extended_vocabulary), 1)]
    }
    initializePhonemic = () : Entity[] => {
        return [new Entity(this.context.translate(translations.fields.word.phonemic_words), 0),
            new Entity(this.context.translate(translations.fields.word.phonemic_no_words), 1)]
    }

    fetchData = (addHistory: boolean, append: boolean = false) => {
        const filter = new WordFilter()
        filter.syllableSpeak = this.state.filter.syllableSpeak
        filter.syllableDivide = this.state.filter.syllableDivide
        filter.search = ""
        filter.wordDefinition = this.state.filter.wordDefinition
        filter.difficulty = this.state.filter.difficulty
        filter.foreignWord = this.state.filter.foreignWord
        filter.foreignWordShow = this.state.filter.foreignWordShow
        filter.exceptionWord = this.state.filter.exceptionWord
        filter.exceptionWordShow = this.state.filter.exceptionWordShow
        filter.basicVocabulary = this.state.filter.basicVocabulary
        filter.vocabularyWordShow = this.state.filter.vocabularyWordShow
        filter.phonemic = this.state.filter.phonemic
        filter.phonemicWordShow = this.state.filter.phonemicWordShow

        // searchValue
        if(this.state.filter.search.length > 0) {
            filter.search = "^".concat(this.state.filter.search)
        }

        let searchStart = ""
        let searchMiddle = ""
        let searchEnd = ""
        let searchLearnedLetters = ""
        let searchAmountLetters = ""

        if(this.state.filter.wordFilterStart.length > 0) {
            searchStart = this.getSearchRegexPartForValues(this.state.filter.wordFilterStart)
        }
        if(this.state.filter.wordFilterMiddle.length > 0) {
            searchMiddle = this.getSearchRegexPartForValues(this.state.filter.wordFilterMiddle)
        }
        if(this.state.filter.wordFilterEnd.length > 0) {
            searchEnd = this.getSearchRegexPartForValues(this.state.filter.wordFilterEnd)
        }
        if(this.state.filter.wordFilterLearnedLetters.length > 0) {
            searchLearnedLetters = this.getSearchRegexPartForValues(this.state.filter.wordFilterLearnedLetters)
        }
        if(this.state.filter.wordFilterCharAmount) {
            searchAmountLetters = "{".concat(this.state.filter.wordFilterCharAmount.toString()).concat("}")

            switch (this.state.filter.wordFilterCharAmountDefinition) {
                case WordFilterCharAmountDefinitionEnum.minimum:
                    searchAmountLetters = "{".concat(this.state.filter.wordFilterCharAmount.toString()).concat(",}")
                    break

                case WordFilterCharAmountDefinitionEnum.maximum:
                    searchAmountLetters = "{0,".concat(this.state.filter.wordFilterCharAmount.toString()).concat("}")
                    break
            }
        }

        if(searchStart) {
            filter.search = "^(".concat(searchStart).concat(")+").concat(".*")
        }
        if(searchMiddle) {
            // if no searchStart is given - before middle letter there must be another letter (+)
            if(searchStart) {
                filter.search = filter.search.concat("(").concat(searchMiddle).concat(")+")
            } else {
                filter.search = filter.search.concat(".+").concat(searchMiddle)
            }

            // if no searchEnd is given - after middle letter there must be another letter (+)
            if(searchEnd) {
                filter.search = filter.search.concat(".*")
            } else {
                filter.search = filter.search.concat(".+")
            }
        }
        if(searchEnd) {
            filter.search = filter.search.concat("(").concat(searchEnd).concat(")+$")
        }
        if(searchLearnedLetters) {
            filter.search = ""

            if(searchStart) {
                if(this.isFilterInvalid(searchLearnedLetters, searchStart)) {
                    this.setState({resultHint: this.context.translate(translations.notification.filter_invalid)})
                    return;

                } else {
                    filter.search = "^(^".concat(searchStart).concat(")+(").concat(searchLearnedLetters).concat(")+")
                }
            } else {
                filter.search = "^(".concat(searchLearnedLetters).concat(")+")
            }

            if(searchEnd) {
                if(this.isFilterInvalid(searchLearnedLetters, searchEnd)) {
                    this.setState({resultHint: this.context.translate(translations.notification.filter_invalid)})
                    return;

                } else {
                    filter.search = filter.search.concat("(").concat(searchEnd).concat(")+$")
                }
            } else {
                filter.search = filter.search.concat("$")
            }
        }
        if(searchAmountLetters) {
            if(filter.search.length > 0) {
                let amountText = "^(?=." + searchAmountLetters + "$)"
                filter.search = amountText.concat("(").concat(filter.search).concat(")")
            } else {
                filter.search = "^." + searchAmountLetters + "$"
            }
        }

        if(this.state.filter.wordTopicId && this.state.filter.wordTopicId >= 0) {
            filter.wordTopicId = this.state.filter.wordTopicId
        }
        if(this.state.filter.highlightId && this.state.filter.highlightId >= 0) {
            filter.highlightId = this.state.filter.highlightId
        }
        if(this.state.filter.gender && this.state.filter.wordDefinition === WordDefinition.noun) {
            filter.gender = this.state.filter.gender
        }

        let page = append ? this.state.page + 1 : 0

        this.context.log.debug("Filter Regex: " + filter.search)
        this.context.log.flush()

        // if word-kit is started only a selection of words will be shown as result
        if(this.state.filter.wordKitUsed) {
            GetWordKitFiltered(filter, this.state.filter.resultAmount).then(
                (words) => {
                    if (addHistory) {
                        this.pushHistory(filter)
                    }

                    let newWordKitList = words.map(item => {
                        return new Tag(item.searchFoundSyllabification || item.name, item.id)
                    })
                    let noResultHint = newWordKitList.length > 0 ? undefined : this.context.translate(translations.notification.image_search_no_result)

                    this.loading = false
                    this.setState({wordKitList: newWordKitList, resultHint: noResultHint})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
                }
            )
        } else {
            GetDictionaryFiltered(filter, page, this.PAGE_SIZE).then(
                (words) => {
                    if (addHistory) {
                        this.pushHistory(filter)
                    }

                    let lastItemReached = WDUtils.lastItemResultReached(words.length, this.PAGE_SIZE)

                    if (append && this.state.wordList) {
                        // Page number would be increased by 1 but if no items are retrieved keep page number
                        if (words.length === 0) {
                            page--
                        }

                        words = this.state.wordList?.concat(words)
                    }

                    this.loading = false
                    this.setState({wordList: words, page: page, lastItem: lastItemReached})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
                }
            )
        }
    }
    fetchWord = (wordId: number) => {
        GetWord(wordId).then(
            (itemData) => {
                let filter = this.state.filter
                filter.activeElement = itemData
                this.pushHistory(filter)

                this.setState({ filter: filter})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }
    fetchWordTopics = () => {
        GetWordTopics().then(
            (wordTopic) => {
                this.wordTopics = [new WordTopic("- " + this.context.translate(translations.text.all) + " -", -1), ...wordTopic]
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }
    fetchWordHighlights = () => {
        GetWordHighlights().then(
            (data) => {
                this.wordHighlights = [new WordTopic("- " + this.context.translate(translations.text.not_special) + " -", -1), ...data]
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    deleteFilter = () => {
        let newFilter = new WordFilter()

        // Checkboxlists must be called separately because one value defines two checkboxes
        newFilter.syllableSpeak = this.userSettingSyllable === SyllableMethod.syllableSpeak
        newFilter.syllableDivide = this.userSettingSyllable === SyllableMethod.syllableDivide

        // If wordkit is used should not change after filter delete
        newFilter.wordKitUsed = this.state.filter.wordKitUsed

        newFilter.foreignWordShow = this.initializeForeign()
        newFilter.exceptionWordShow = this.initializeException()
        newFilter.vocabularyWordShow = this.initializeVocabulary()
        newFilter.phonemicWordShow = this.initializePhonemic()

        let currentSyllable = this.state.syllableDefinition
        currentSyllable.showSyllable = newFilter.syllableDivide || newFilter.syllableSpeak || false

        this.setState({
            filter: newFilter,
            syllableDefinition: currentSyllable,

        }, () => {
            this.fetchData(true)
        })
    }

    getSearchRegexPartForValues = (searchValues: string) => {
        let values = searchValues.split(",")
        if(values[values.length - 1] === "" && values.length >= 2) {
            values = values.slice(0, values.length - 1)
        }

        let searchText = ""
        values.forEach((value, i, arr) => {
            if(i === arr.length -1) {
                searchText = searchText.concat(value)
            } else {
                searchText = searchText.concat(value.concat("|"))
            }
        })

        return searchText
    }
    /**
     * Checks if lettersToCheck are in allLettersPossible included.
     * If letters are reduced to a certain amount, it must be checked that other letters from other filter criteria
     * are not against this restriction
     * @param allLettersPossible all letters which are allowed splitted by ','
     * @param lettersToCheck letters which must be included in all letters to have a valid filter
     */
    isFilterInvalid = (allLettersPossible: string, lettersToCheck: string) : boolean => {
        let letterArrayToCheck = lettersToCheck.split(",")
        let allLettersPossibleArray = allLettersPossible.split("|")

        letterArrayToCheck.forEach(letter => {
            if(!allLettersPossibleArray.includes(letter)) {
                return true
            }
        })

        return false
    }
    removeCharInvalidHint = () => {
        setTimeout(() => this.setState({showWordFilterCharAmountInvalidDialog: false}), 3000)
    }

    onToggleWordKit = () => {
        let currentFilter = this.state.filter
        currentFilter.wordKitUsed = !this.state.filter.wordKitUsed
        let advancedSettingsVisibleNew = !currentFilter.wordKitUsed ? false : this.state.advancedSettingsVisible

        if(currentFilter.wordKitUsed) {
            currentFilter.search = ""
        }
        this.pushHistory(currentFilter)

        this.setState({
            advancedSettingsVisible: advancedSettingsVisibleNew,
            filter: currentFilter
        })
    }

    /** OnChange Methods **/
    onChangeFilterEnum = (value: number, enumType: any, itemProperty: string, refreshSpecificWordFilters: boolean) => {
        // Get enum value by index (value)
        let currentItem = this.state.filter
        currentItem.filterChanged = true

        if(value === -1) {
            currentItem[itemProperty] = null
        } else {
            let enumValue = Object.values(enumType).find((e, i) => i === value)
            if (enumValue) {
                currentItem = this.state.filter
                currentItem[itemProperty] = enumValue
            }
        }

        if(refreshSpecificWordFilters) {
            currentItem.gender = undefined
        }

        this.setState({filter: currentItem}, () => this.fetchData(true))
    }
    onChangeFilterDropdownValue = (value: number, fieldName: string) => {
        let currentFilter = this.state.filter
        currentFilter.filterChanged = true
        currentFilter[fieldName] = value
        this.setState({filter: currentFilter}, () => this.fetchData(true))
    }
    onChangeTags = (tag: Tag[]) => {
        this.setState({wordKitList: tag})
    }
    onChangeFilterText = (value: string, textFieldName: string) => {
        let oldFilter = {...this.state.filter}
        let oldValue = oldFilter[textFieldName]

        let filter = this.state.filter
        filter[textFieldName] = value

        filter.filterChanged = true

        this.setState({filter: filter}, () => {
            this.onChangeStringStartSearch(oldValue, value)
        })
    }
    onChangeSyllable = (changedSyllable: string) => {
        let currentFilter = this.state.filter
        currentFilter.filterChanged = true
        currentFilter[changedSyllable] = !currentFilter[changedSyllable]

        let currentSyllable = this.state.syllableDefinition
        currentSyllable.showSyllable = currentFilter.syllableDivide || currentFilter.syllableSpeak || false

        this.setState({filter: currentFilter, syllableDefinition: currentSyllable}, () => this.fetchData(true))
    }
    onChangeForeign = (values: Entity[]) => {
        let currentFilter = this.state.filter
        currentFilter.filterChanged = true

        let showForeignWord = values.find(value => {return value.id === 0})
        let showNativeWord = values.find(value => {return value.id === 1})

        if((showForeignWord === undefined && showNativeWord === undefined) || (showForeignWord && showNativeWord)) {
            currentFilter.foreignWord = undefined
        } else {
            currentFilter.foreignWord = showForeignWord !== undefined
        }

        currentFilter.foreignWordShow = values

        this.setState({filter: currentFilter}, () => this.fetchData(true))
    }
    onChangeException = (values: Entity[]) => {
        let currentFilter = this.state.filter
        currentFilter.filterChanged = true

        let showExceptionWord = values.find(value => {return value.id === 0})
        let showNormWord = values.find(value => {return value.id === 1})

        if((showExceptionWord === undefined && showNormWord === undefined) || (showExceptionWord && showNormWord)) {
            currentFilter.exceptionWord = undefined
        } else {
            currentFilter.exceptionWord = showExceptionWord !== undefined
        }

        currentFilter.exceptionWordShow = values

        this.setState({filter: currentFilter}, () => this.fetchData(true))
    }
    onChangeVocabulary = (values: Entity[]) => {
        let currentFilter = this.state.filter
        currentFilter.filterChanged = true

        let showBasicVocabularyWord = values.find(value => {return value.id === 0})
        let showExtendedVocabularyWord = values.find(value => {return value.id === 1})

        if((showBasicVocabularyWord === undefined && showExtendedVocabularyWord === undefined) || (showBasicVocabularyWord && showExtendedVocabularyWord)) {
            currentFilter.basicVocabulary = undefined
        } else {
            currentFilter.basicVocabulary = showBasicVocabularyWord !== undefined
        }

        currentFilter.vocabularyWordShow = values

        this.setState({filter: currentFilter}, () => this.fetchData(true))
    }
    onChangePhonemic = (values: Entity[]) => {
        let currentFilter = this.state.filter
        currentFilter.filterChanged = true

        let showPhonemicWord = values.find(value => {return value.id === 0})
        let showNoPhonemicWord = values.find(value => {return value.id === 1})

        if((showPhonemicWord === undefined && showNoPhonemicWord === undefined) || (showPhonemicWord && showNoPhonemicWord)) {
            currentFilter.phonemic = undefined
        } else {
            currentFilter.phonemic = showPhonemicWord !== undefined
        }

        currentFilter.phonemicWordShow = values

        this.setState({filter: currentFilter}, () => this.fetchData(true))
    }
    onChangeWordFilterLetterAmount = (value: string) => {
        let regex = new RegExp("^[0-9]+$")
        let newFilter = this.state.filter
        newFilter.filterChanged = true

        if(regex.test(value)) {
            newFilter.wordFilterCharAmount = +value
            this.setState({filter: newFilter}, () => {this.fetchData(true)})
        } else if(value === "") {
            newFilter.wordFilterCharAmount = undefined
            this.setState({filter: newFilter}, () => {this.fetchData(true)})
        } else {
            this.setState({showWordFilterCharAmountInvalidDialog: true},
                () => this.removeCharInvalidHint())
        }
    }
    onChangeWordFilterLetterAmountDefinition = (value: number) => {
        let definition = Object.values(WordFilterCharAmountDefinitionEnum).find((e, i) => i === value)
        let newFilter = this.state.filter
        newFilter.filterChanged = true

        newFilter.wordFilterCharAmountDefinition = definition || WordFilterCharAmountDefinitionEnum.exact
        this.setState({filter: newFilter}, () => {this.fetchData(true)})
    }
    onChangeWordFilterResultAmount = (value: string) => {
        let regex = new RegExp("^[0-9]+$")
        let newFilter = this.state.filter
        newFilter.filterChanged = true

        if(regex.test(value)) {
            newFilter.resultAmount = +value
            this.setState({filter: newFilter}, () => {this.fetchData(true)})
        } else if(value === "") {
            newFilter.resultAmount = this.PAGE_SIZE
            this.setState({filter: newFilter}, () => {this.fetchData(true)})
        } else {
            this.setState({showWordFilterCharAmountInvalidDialog: true},
                () => this.removeCharInvalidHint())
        }
    }
    onChangeStringStartSearch = (oldValue: string, newValue: string) => {
        if(oldValue.length === newValue.length
            || oldValue.concat(",") === newValue
            || (newValue.length < oldValue.length && oldValue.charAt(oldValue.length-1) === ",")) {
            return
        }
        this.fetchData(true)
    }

    /** Button actions **/
    copyInText = () => {
        let tagContainer = document.getElementById("txtTags-tags")

        if(tagContainer) {
            let tags = tagContainer.querySelectorAll(".ws-designer-word")
            let text = ""
            let syllable = false

            if (tags.length > 0) {
                tags.forEach(word => {
                    text = text.concat(word.innerHTML).concat(", ")

                    if (word.innerHTML.includes("syllabification")) {
                        syllable = true
                    }
                })

                if (text !== "") {
                    text = text.substring(0, text.lastIndexOf(","))
                }

                let textboxData = JSON.stringify(new WDTextboxData(text, true, syllable, VerticalAlignment.top));

                let worksheetItem = new WorksheetItem(WorksheetItem.getNewItemKey(), this.props.currentPageKey,
                    WorksheetItemTypeEnum.TEXTBOX, Converter.toMmGrid(500),
                    Converter.toMmGrid(500), 400, 200, textboxData,
                    true, false, false, false)

                this.props.addElementToDesigner(worksheetItem)
            }
        }
    }
    copyInTable = () => {
        let rows: WDTableRowData[] = []
        let columns: WDTableColumnData[] = []
        let cells: WDTableCellData[][] = []

        columns.push(new WDTableColumnData(200))
        columns.push(new WDTableColumnData(200))

        let rowAmount = 0
        let tagContainer = document.getElementById("txtTags-tags")
        let showSyllable = false

        if(tagContainer) {
            let tags = tagContainer.querySelectorAll(".ws-designer-word")
            if (tags.length > 0) {
                tags.forEach(tag => {
                    rows.push(new WDTableRowData(34))

                    cells[rowAmount] = []
                    cells[rowAmount][0] = new WDTableCellData()
                    cells[rowAmount][0].data = new WDTextboxData(tag.innerHTML, false,
                        tag.innerHTML.includes("syllabification"), VerticalAlignment.top)

                    cells[rowAmount][1] = new WDTableCellData()
                    cells[rowAmount][1].data = new WDTextboxData("", false,
                        tag.innerHTML.includes("syllabification"), VerticalAlignment.top)

                    rowAmount++
                })

                let tableData = new WDTableData(rows, columns, cells, showSyllable);
                let width = WDTableData.calculateWidth(tableData)
                let height = WDTableData.calculateHeight(tableData)

                let worksheetItem = new WorksheetItem(WorksheetItem.getNewItemKey(),
                    this.props.currentPageKey, WorksheetItemTypeEnum.TABLE, Converter.toMmGrid(100),
                    Converter.toMmGrid(100), width, height, JSON.stringify(tableData),
                    true, false, false, false)

                this.props.addElementToDesigner(worksheetItem)
            }
        }
    }

    render() {
        let style: CSSProperties = {}
        style.overflowY = this.state.filter.wordKitUsed ? "scroll" : "unset"
        style.overflowX = this.state.filter.wordKitUsed ? "hidden" : "visible"

        if (this.state.filter.wordKitUsed) {
            style.height = "100%"
        }

        let wordDefinition = EnumValueToValueNumber(WordDefinition, this.state.filter.wordDefinition)

        return <div className={"ws-designer-sidebar-container"}>

            {/* Search and navigation */}
            <SidebarFilterBase
                onSearch={this.onSearch}
                searchValue={this.state.filter.search}
                searchReadOnly={this.state.filter.wordKitUsed}
                buttons={[
                    new SidebarFilterButtonData(
                        1, this.context.translate(translations.command.backward),
                        this.context.translate(translations.tooltip.backward),
                        "navigation_backward.svg",
                        this.state.filterHistoryIndex === 0,
                        this.onClickBackwards,
                        TooltipPosition.belowRight, -138, -5),
                    new SidebarFilterButtonData(
                        2, this.context.translate(translations.command.forward),
                        this.context.translate(translations.tooltip.forward),
                        "navigation_forward.svg",
                        this.state.filterHistoryIndex === this.state.filterHistory.length -1,
                        this.onClickForwards,
                        TooltipPosition.belowRight, -138, -5),
                    new SidebarFilterButtonData(
                        3, this.context.translate(translations.command.delete_filter),
                        this.context.translate(translations.tooltip.delete_filter),
                        "delete_filters.svg",
                        !this.state.filter.filterChanged,
                        this.deleteFilter,
                        TooltipPosition.belowRight, -138, -5),
                    new SidebarFilterButtonData(
                        4, this.context.translate(translations.command.report_word),
                        this.context.translate(translations.tooltip.report_word),
                        "mail.svg",
                        (this.state.filter.activeElement === undefined || this.state.filter.activeElement === null),
                        () => this.onClickReport(this.state.filter.activeElement!.id!, this.context.translate(translations.text.word)),
                        TooltipPosition.belowRight, -138, -5)
                ]}
            />

            {!this.state.filter.activeElement &&
                <>
                <div className={"ws-designer-sidebar-part"} style={style}>
                    <div className={"ws-designer-sidebar-column"}>
                        <div className={"ws-designer-sidebar-row"}>

                            {/* Filter options always visible */}
                            <div className={"ws-designer-sidebar-column"}>
                                <SelectBox id={"filter-definition"} width={190}
                                           label={this.context.translate(translations.fields.word.wordDefinition)}
                                           data={this.wordDefinitionData}
                                           required={false}
                                           value={wordDefinition !== undefined ? wordDefinition : -1}
                                           readonly={false}
                                           positioningRow={false}
                                           onChange={(value) => this.onChangeFilterEnum(value, WordDefinition, "wordDefinition", true)}/>
                            </div>
                            <div className={"ws-designer-sidebar-column"}>
                                <SelectBox id={"filter-difficulty"} width={190}
                                           label={this.context.translate(translations.fields.word.difficulty)}
                                           data={this.difficultyData}
                                           required={false}
                                           value={EnumValueToValueNumber(Difficulty, this.state.filter.difficulty) || -1}
                                           readonly={false}
                                           onChange={(value) => this.onChangeFilterEnum(value, Difficulty, "difficulty", false)}
                                           positioningRow={false}/>
                            </div>
                        </div>
                    </div>

                    <div
                        className={this.state.filter.activeElement === undefined ? "ws-designer-sidebar-filter-show" : "ws-designer-sidebar-filter-hide"}>

                        {/* Advanced filter settings */}
                        {this.state.advancedSettingsVisible !== undefined &&
                            <div className={
                                (this.state.advancedSettingsVisible || this.state.filter.wordKitUsed)
                                    ? "ws-designer-sidebar-filter-advanced-show ws-designer-sidebar-word-filter-animation-in"
                                    : "ws-designer-sidebar-filter-advanced-hide ws-designer-sidebar-word-filter-animation-out"
                            }>
                                <div className={"ws-designer-sidebar-column"}>
                                    <div className={"ws-designer-sidebar-row"}>
                                        <div className={"ws-designer-sidebar-column"}>
                                            <SelectBox id={"filter-word-gender"} width={190}
                                                       label={this.context.translate(translations.fields.word.noun.gender)}
                                                       value={EnumValueToValueNumber(GenderEnum, this.state.filter.gender)}
                                                       data={this.genderData}
                                                       required={false}
                                                       readonly={this.state.filter.wordDefinition !== WordDefinition.noun}
                                                       onChange={(value) => this.onChangeFilterEnum(value, GenderEnum, "gender", false)}
                                                       positioningRow={false}/>
                                        </div>
                                        <div className={"ws-designer-sidebar-column"}>
                                            <SelectBox id={"filter-word-topic"} width={190}
                                                       label={this.context.translate(translations.fields.word.word_topics)}
                                                       value={this.state.filter.wordTopicId || -1}
                                                       data={this.wordTopics}
                                                       required={false} readonly={false}
                                                       onChange={(value) => this.onChangeFilterDropdownValue(value, "wordTopicId")}
                                                       positioningRow={false}/>
                                        </div>
                                    </div>

                                    <div className={"ws-designer-sidebar-row"}>
                                        <div className={"ws-designer-sidebar-column"}>
                                            <CheckBoxList
                                                id={"filter-foreign-word"}
                                                data={this.foreignData}
                                                selectedData={this.state.filter.foreignWordShow}
                                                isNothingCheckedAllowed={false}
                                                required={false}
                                                readonly={false}
                                                onChange={this.onChangeForeign}
                                            />

                                            <CheckBoxList
                                                id={"filter-exception-word"}
                                                data={this.exceptionData}
                                                selectedData={this.state.filter.exceptionWordShow}
                                                isNothingCheckedAllowed={false}
                                                required={false}
                                                readonly={false}
                                                onChange={this.onChangeException}
                                            />
                                        </div>

                                        <div className={"ws-designer-sidebar-column"}>
                                            <CheckBoxList
                                                id={"filter-phonemic-word"}
                                                data={this.phonemicData}
                                                selectedData={this.state.filter.phonemicWordShow}
                                                isNothingCheckedAllowed={false}
                                                required={false}
                                                readonly={false}
                                                onChange={this.onChangePhonemic}
                                            />

                                            <CheckBoxList
                                                id={"filter-vocabulary-word"}
                                                data={this.vocabularyData}
                                                selectedData={this.state.filter.vocabularyWordShow}
                                                isNothingCheckedAllowed={false}
                                                required={false}
                                                readonly={false}
                                                onChange={this.onChangeVocabulary}
                                            />
                                        </div>
                                    </div>

                                    <div className={"ws-designer-sidebar-row"}>
                                        <div className={"ws-designer-sidebar-column"}>
                                            <CheckBox id={"filter-syllable-divide"}
                                                      label={this.context.translate(translations.enum.syllable.syllableDivide)}
                                                      onChange={() => this.onChangeSyllable("syllableDivide")}
                                                      checked={this.state.filter.syllableDivide || false}
                                                      readonly={false}
                                                      required={false}
                                            />
                                        </div>

                                        <div className={"ws-designer-sidebar-column"}>
                                            <CheckBox id={"filter-syllable-speak"}
                                                      label={this.context.translate(translations.enum.syllable.syllableSpeak)}
                                                      onChange={() => this.onChangeSyllable("syllableSpeak")}
                                                      checked={this.state.filter.syllableSpeak || false}
                                                      readonly={false}
                                                      required={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                        {this.state.filter.wordKitUsed &&
                            <>
                                <SelectBox id={"filter-word-highlight"} width={405}
                                           label={this.context.translate(translations.fields.word.word_highlights)}
                                           value={this.state.filter.highlightId || -1}
                                           data={this.wordHighlights}
                                           required={false} readonly={false}
                                           onChange={(value) => this.onChangeFilterDropdownValue(value, "highlightId")}
                                           positioningRow={false}/>

                                <div className={"form-group"}>
                                    <div className={"bold-label-gray"}>
                                        {this.context.translate(translations.fields.word.search_for_letter_combi)}
                                    </div>
                                    <div className={"sub-label"} style={{fontWeight: "normal"}}>
                                        {this.context.translate(translations.fields.word.input_eg)}
                                    </div>

                                    <div className={"form-row"} style={{marginBottom: 0}}>
                                        <TextBox
                                            id={"txt-filter-word-start"}
                                            width={130}
                                            placeholder={this.context.translate(translations.fields.word.word_start)}
                                            value={this.state.filter.wordFilterStart}
                                            onChange={(value) => this.onChangeFilterText(value, "wordFilterStart")}
                                            style={{marginLeft: "0"}}
                                            required={false}
                                            readonly={false}/>

                                        <TextBox
                                            id={"txt-filter-word-middle"}
                                            width={130}
                                            placeholder={this.context.translate(translations.fields.word.word_middle)}
                                            value={this.state.filter.wordFilterMiddle}
                                            onChange={(value) => this.onChangeFilterText(value, "wordFilterMiddle")}
                                            style={{marginLeft: "0"}}
                                            required={false}
                                            readonly={false}/>

                                        <TextBox
                                            id={"txt-filter-word-end"}
                                            width={130}
                                            placeholder={this.context.translate(translations.fields.word.word_end)}
                                            value={this.state.filter.wordFilterEnd}
                                            onChange={(value) => this.onChangeFilterText(value, "wordFilterEnd")}
                                            style={{marginLeft: "0"}}
                                            required={false}
                                            readonly={false}/>
                                    </div>

                                    <div className={"form-row"}  style={{marginBottom: 0}}>
                                        <TextBox
                                            id={"txt-filter-word-amount"}
                                            width={254}
                                            placeholder={this.context.translate(translations.fields.word.letter_amount)}
                                            value={this.state.filter.wordFilterCharAmount?.toString() || ""}
                                            onChange={this.onChangeWordFilterLetterAmount}
                                            style={{marginLeft: "0", marginTop: "3px"}}
                                            required={false}
                                            readonly={false}/>

                                        <SelectBox
                                            id={"sel-filter-word-amount-definition"}
                                            width={130}
                                            style={{marginTop: 0}}
                                            data={EnumToEntityArray(WordFilterCharAmountDefinitionEnum, translations.enum.word_filter_amount_definition, this.context.translate)}
                                            value={EnumValueToValueNumber(WordFilterCharAmountDefinitionEnum, this.state.filter.wordFilterCharAmountDefinition)}
                                            onChange={this.onChangeWordFilterLetterAmountDefinition}
                                            required={false} readonly={false}
                                            positioningRow={false}/>
                                    </div>

                                    {this.state.showWordFilterCharAmountInvalidDialog &&
                                        <Hint id={"invalid-char-hint"}
                                              notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.only_numbers_valid))}/>
                                    }
                                </div>

                                <div className={"form-group"}>
                                    <div className={"bold-label-gray"}>
                                        {this.context.translate(translations.fields.word.reduce_to_learned_letters)}
                                    </div>
                                    <div className={"sub-label"} style={{fontWeight: "normal"}}>
                                        {this.context.translate(translations.fields.word.input_eg)}
                                    </div>

                                    <div className={"form-row"} style={{marginBottom: 0}}>
                                        <TextBox
                                            id={"txt-filter-word-learned-letters"}
                                            width={350}
                                            placeholder={this.context.translate(translations.fields.word.learned_letters)}
                                            value={this.state.filter.wordFilterLearnedLetters}
                                            onChange={(value) => this.onChangeFilterText(value, "wordFilterLearnedLetters")}
                                            style={{marginLeft: "0"}}
                                            required={false}
                                            readonly={false}/>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {!this.state.filter.wordKitUsed &&
                        <div className={"ws-designer-sidebar-filter-more"}>
                            <SidebarAdvancedFilterArrow
                                advancedSettingsVisible={this.state.filter.wordKitUsed}
                                textMore={this.context.translate(translations.text.word_kit_open)}
                                textLess={this.context.translate(translations.text.word_kit_close)}
                                onToggle={this.onToggleWordKit}
                                className={"ws-designer-sidebar-filter-more-item-left"}
                            />

                            <SidebarAdvancedFilterArrow
                                advancedSettingsVisible={this.state.advancedSettingsVisible!}
                                textMore={this.context.translate(translations.text.more_settings)}
                                textLess={this.context.translate(translations.text.less_settings)}
                                onToggle={this.onToggleSettings}
                                className={"ws-designer-sidebar-filter-more-item-right"}
                            />
                        </div>
                    }
                </div>

                {/* RESULTS */}
                {this.state.filter.wordKitUsed &&
                <div className="ws-designer-sidebar-content" style={{overflowY: "hidden"}}>
                    <div className={"form-row"} style={{marginBottom: "0"}}>
                        <div className={"form-group bold-label"}>
                            {this.context.translate(translations.text.result)}
                        </div>
                        <TextBox
                            id={"txt-result-number"}
                            readonly={false}
                            required={false}
                            width={165}
                            style={{marginBottom: 10, marginLeft: 10, alignSelf: "end", marginTop: "7px"}}
                            placeholder={this.context.translate(translations.text.result_amount)}
                            value={this.state.filter.resultAmount === this.PAGE_SIZE ? "" : this.state.filter.resultAmount.toString()}
                            onChange={this.onChangeWordFilterResultAmount}/>
                    </div>
                    <div className={"ws-designer-sidebar-part"} style={{height: "25vh"}}>
                        {this.state.resultHint ?
                            <Hint
                                id={"hint-result"}
                                notificationData={new NotificationData(NotificationStatus.info, this.state.resultHint)}/>

                            :
                            <TextBox id={"txtTags"}
                                     required={false}
                                     readonly={false}
                                     tags={this.state.wordKitList || []}
                                     onChangeTags={this.onChangeTags}
                                     mode={{
                                             autoComplete: false,
                                             tags: true,
                                             restrictCreation: true,
                                             removeInputField: true
                                         }}
                                     style={{width: "100%", margin: 0, padding: 0, overflowY: "scroll"}}
                                     value={""}
                                     syllable={this.state.syllableDefinition}
                            />
                        }

                        <div className={"ws-designer-sidebar-filter-more"}
                             style={{position: "absolute", bottom: "0", flexDirection: "column", paddingLeft: 0}}>

                            <div className={"form-row"} style={{marginBottom: "0", paddingTop: "5px", justifyContent: "space-between"}}>
                                <ButtonList
                                    containerStyle={{width: "auto"}}
                                    buttonGroupStyle={{justifyContent: "flex-start", marginLeft: "20px"}}
                                    buttons={[
                                        new ButtonInfo("btnRetry",
                                            "button button-cancel",
                                            "button",
                                            false,
                                            false,
                                            this.context.translate(translations.command.retry_search),
                                            () => this.fetchData(false),
                                            {})]}
                                    loading={false}
                                />
                                <ButtonList
                                    containerStyle={{width: "auto"}}
                                    buttons={[
                                        new ButtonInfo("btnTxt",
                                            "button button-save",
                                            "button",
                                            true,
                                            false,
                                            this.context.translate(translations.command.copy_in_text),
                                            this.copyInText,
                                            {}),
                                        new ButtonInfo("btnTable",
                                            "button button-save",
                                            "button",
                                            true,
                                            false,
                                            this.context.translate(translations.command.copy_in_table),
                                            this.copyInTable,
                                            {marginLeft: "10px"})
                                    ]}
                                    loading={false}
                                />
                            </div>

                            <SidebarAdvancedFilterArrow
                                advancedSettingsVisible={this.state.filter.wordKitUsed}
                                textMore={this.context.translate(translations.text.word_kit_open)}
                                textLess={this.context.translate(translations.text.word_kit_close)}
                                style={{marginBottom: "5px"}}
                                className={"ws-designer-sidebar-filter-more-item-right"}
                                onToggle={this.onToggleWordKit}
                            />
                        </div>
                    </div>
                </div>
                }
            </>
            }


            {!this.state.filter.wordKitUsed &&
                <div className={"ws-designer-sidebar-content"}
                     onScroll={(event) => {
                         if (!this.state.lastItem && this.state.filter.activeElement === undefined) {
                             this.onScrollContent(event)
                         }
                     }}>

                    {this.state.filter.activeElement !== undefined

                        ?
                        <SidebarDictionaryWordForm
                            word={this.state.filter.activeElement as Word}
                            syllableDefinition={this.state.syllableDefinition}
                            onClickNewWord={this.fetchWord}
                        />

                        :

                        (this.state.wordList.length === 0
                            ?
                            (this.loading
                                ?
                                <div className={"ws-designer-images-gallery-list-empty"}>
                                    <Hint id={"sidebar-image-loading"}
                                          notificationData={new NotificationData(NotificationStatus.loading, this.context.translate(translations.notification.loading))}/>
                                </div>

                                :
                                <div className={"ws-designer-images-gallery-list-empty"}>
                                    <Hint id={"sidebar-image-noresult"}
                                          notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.image_search_no_result))}/>
                                </div>
                            )

                            :
                            this.state.wordList.map(word => {
                                return <div className={"ws-designer-dictionary-list"}
                                            key={"dictionary-entry-" + word.id!}
                                            onClick={() => word.id !== undefined
                                                ? this.fetchWord(word.id!)
                                                : undefined}>
                                    <SidebarDictionaryListEntry
                                        wordDefinition={word.wordDefinition!}
                                        syllableWord={word.syllabification || word.name}
                                        syllableDefinition={this.state.syllableDefinition}
                                        details1={word.details1}
                                        details2={word.details2}
                                        details3={word.details3}
                                        links={word.wordLink}/>
                                </div>
                            })
                        )
                    }
                </div>
            }
        </div>
    }
}
