import Auth from "./Auth";
import Conf from "./Conf";
import {ValidationError} from "./Error/ValidationError";
import {SystemError} from "./Error/SystemError";
import {AuthError} from "./Error/AuthError";
import {SecurityError} from "./Error/SecurityError";
import {NotFoundError} from "./Error/NotFoundError";
import {PaymentRequiredError} from "./Error/PaymentRequiredError";
import {ServerNotReachableError} from "./Error/ServerNotReachableError";

const API_ENDPOINT = Conf.BACKEND_URL();
const ODOO_ENDPOINT = Conf.ODOO_URL_DEVELOPMENT();

interface RequestInfo {
    url?: string
    method: string
    body?: string
}

export default class API {

    public static async Get<T>(url: string): Promise<T> {
        const request = {url: url, method: 'GET'}
        return await this.http<T>(request)
    }
    public static async Post<T, U>(url: string, data: T): Promise<U> {
        const request = {url: url, method: 'POST', body: JSON.stringify(data)}
        return await this.http<U>(request)
    }
    public static async Put<T, U>(url: string, data: T): Promise<U> {
        const request = {url: url, method: 'PUT', body: JSON.stringify(data)}
        return await this.http<U>(request)
    }
    public static async Patch<T, U>(url: string, data: T): Promise<U> {
        const request = {url: url, method: 'PATCH', body: JSON.stringify(data)}
        return await this.http<U>(request)
    }
    public static async Delete<T>(url: string): Promise<T> {
        const request = {url: url, method: 'DELETE'}
        return await this.http<T>(request)
    }

    private static async http<U>(requestInfo: RequestInfo): Promise<U> {
        let response: Response | undefined = undefined

        if (!Auth.hasToken()) {
            throw new AuthError("You are not authorized to do this")
        }

        try {
            response = await fetch(API_ENDPOINT + requestInfo.url, {
                headers: {
                    'Authorization': Auth.getToken(),
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                method: requestInfo.method,
                body: requestInfo.body
            })
        } catch (e) {
            throw new SystemError("An unexpected error occurred: " + e)
        }

        if(response) {
            await this.checkReturnValue(response)
        }
        return await this.parseJson(response)
    }
    public static async login(data: any): Promise<string> {
        let response: Response | undefined = undefined
        try {
            response = await fetch(API_ENDPOINT + "authentication", {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text'
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
        } catch (e) {
            throw new SystemError("An unexpected error occurred: " + e)
        }

        if(response) {
            await this.checkLoginReturnValue(response)
        }
        return response.text()
    }
    public static async odooLogin(data: any): Promise<string> {
        let response: Response | undefined = undefined
        try {
            response = await fetch(ODOO_ENDPOINT + "authenticate", {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
        } catch (e) {
            throw new SystemError("An unexpected error occurred: " + e)
        }

        if(response) {
            await this.checkReturnValue(response)
        }
        return response.text()
    }

    public static parseJson = async response => {
        const text = await response.text()
        try{
            return JSON.parse(text)
        } catch (e) { }

        return
    }

    public static async checkLoginReturnValue(response: Response) {
        if(!(response.status >= 200 && response.status < 300)) {
            let error = response.statusText;

            if(response.status === 401) {
                throw new AuthError(error)
            }
            else if(response.status === 402) {
                throw new PaymentRequiredError(error)
            }
            else if(response.status === 403) {
                throw new SecurityError(error)
            }
            else {
                throw new SystemError(error + " An unexpected error occurred")
            }
        }
    }
    public static async checkReturnValue(response: Response) {
        if(!(response.status >= 200 && response.status < 300)) {
            let error = response.statusText;

            if(response.status === 403) {
                let result = await response.json()
                throw new SecurityError(result.message)
            }
            else if(response.status === 404) {
                throw new NotFoundError("We could not find anything matching your request (404): " + error)
            }
            else if(response.status === 400) {
                throw new SystemError("There is something wrong with your request: " + error)
            }
            else if(response.status === 401) {
                throw new AuthError(error)
            }
            else if(response.status === 422) {
                let result = await response.json()
                throw new ValidationError(result.message)
            }
            else if(response.status === 500) {
                throw new SystemError("An unexpected error occurred (500): " + error)
            }
            else if(response.status === 503) {
                throw new ServerNotReachableError("The server is currently not reachable")
            }
            else {
                throw new SystemError(error + " Error")
            }
        }
    }
}
