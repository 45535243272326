import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {MainContext} from "../../../../_base/MainContext";
import Exercise from "../../../../_model/Exercise";
import {DropDown, DropDownItem, DropDownType} from "../../../../Components/Controls/DropDown";
import WDTextExerciseDropDownValues from "../../../Elements/TextExercise/WDTextExerciseDropDownValues";

/**
 * Button (Yes/No) in Toolbar
 * Effects selected element(s)
 * Full-text search of text exercises
 */

interface IProps {
    id: number
    enabled: boolean
    topic?: number
    onActionButton: (action: WDToolbarAction, data: any) => void
}
interface IState {
    exercises?: Exercise[]
}

export class WDToolbarButtonTextExerciseTopics extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onSelect = (value: string) => {
        this.props.onActionButton(WDToolbarAction.CHANGE_TOPIC, {"topicId": value})
    }

    render() {
        let topics = WDTextExerciseDropDownValues.getTopics()

        return <li
            key={"ws-designer-toolbar-button-text-exercise-topics"}
            id={"text-exercise-topics"}
            className={"text-exercise-topics"}
        >
            <DropDown id={"text-exercise-topic"}
                      type={DropDownType.TEXT}
                      value={this.props.topic !== undefined ? this.props.topic.toString() : "-1"}
                      readonly={!this.props.enabled}
                      autocomplete={false}
                      required={false}
                      width={150}
                      items={topics.map(i => new DropDownItem(i.key.toString(), i.value))}
                      onChangeValue={this.onSelect}
            />
        </li>
    }
}
