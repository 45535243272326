import API from "../Framework/API";
import SearchResult from "../_model/SearchResult";
import {Status} from "../Framework/Enums";

const URL = "search-results"

export function GetSearchResult(id: number) {
    let url = URL + "/" + id
    return API.Get<SearchResult>(url)
}
export function GetAllSearchResults() {
    let url = URL + "/"
    return API.Get<SearchResult[]>(url)
}
export function GetSearchResultsByStatus(status: Status) {
    const itemData = {
        status: status
    }

    let url = URL + "/filter"
    return API.Post<object, SearchResult[]>(url, itemData)
}
export function UpdateSearchResult(item: SearchResult) {
    return API.Put<object, SearchResult>(URL + "/" + item.id, item)
}
