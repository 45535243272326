import React, {CSSProperties} from "react";
import Entity from "../../_model/Entity";
import Tag from "../../_model/Tag";
import {ImagePath} from "../../Framework/CategoryImage";
import {SyllableDefinition, WDSyllableWord} from "../../Designer/Elements/Textbox/WDSyllableWord";
import {FloatingHint} from "../Notification/FloatingHint";
import {NotificationData} from "../Notification/Hint";
import {NotificationStatus} from "../../Framework/Enums";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";
import Label from "./Label";

export class FormTextBoxMode {
    autoComplete?: boolean
    tags?: boolean
    restrictCreation?: boolean
    removeInputField?: boolean
}

interface IProps {
    id: string
    width?: number
    className?: string
    style?: CSSProperties
    label?: string
    labelClass?: string
    value: string
    multiline?: boolean
    mode?: FormTextBoxMode
    placeholder?: string
    maxLength?: number
    rows?: number

    data?: Entity[]
    tags?: Tag[]
    syllable?: SyllableDefinition

    required: boolean
    readonly: boolean

    onChange?: (value: string, id?: number) => void
    onChangeTags?: (tags: Tag[]) => void
    onBlur?: () => void
}

interface IState {
    showData: boolean,
    value: string,
    selectedIndex: number,
    infoText?: string
}

export default class TextBox extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    maxLengthOfText = 100

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            showData: false,
            value: this.props.value,
            selectedIndex: -1,
        }

        // set maxLengthValue depending on usage
        if (this.props.maxLength) {
            this.maxLengthOfText = this.props.maxLength
        } else {
            this.maxLengthOfText = this.props.multiline ? 500 : 100
        }
    }

    setValue = (value: string, newIndex: number) => {
        let textTextAmountMaxReached: string | undefined = undefined
        if (value.length === this.maxLengthOfText) {
            textTextAmountMaxReached = this.context.translate(translations.error.max_text_length_reached)
        }

        if (this.props.onChange) {
            this.props.onChange(value)

            this.setState({value: "", selectedIndex: newIndex, infoText: textTextAmountMaxReached})
        } else {
            this.setState({value: value, selectedIndex: newIndex, infoText: textTextAmountMaxReached})
        }
    }
    applyEntry = (entity?: Entity) => {

        if (this.props.onChangeTags) {

            // Click on specific entry
            if (entity) {
                if (this.props.tags) {
                    this.props.onChangeTags([...this.props.tags, new Tag(entity.name, entity.id)])
                } else {
                    this.props.onChangeTags([new Tag(entity.name, entity.id)])
                }
                this.setValue("", 0)
            } else {

                // Add item selected in the list
                const items = this.getItems()
                if (items && this.state.selectedIndex >= 0 && items[this.state.selectedIndex]) {
                    this.applyEntry(items[this.state.selectedIndex])
                    this.setValue("", 0)
                }

                // Enter manually entered item (selectedIndex = -1)
                else if (!this.props.mode?.restrictCreation && (this.props.mode?.tags ? this.state.value : this.props.value !== "")) {
                    if (this.props.tags) {
                        this.props.onChangeTags([...this.props.tags, new Tag(this.state.value.trim())])
                    } else {
                        this.props.onChangeTags([new Tag(this.state.value.trim())])
                    }

                    this.setValue("", 0)
                }
            }
        } else {
            const items = this.getItems()
            if (items && this.state.selectedIndex >= 0 && items[this.state.selectedIndex]) {

                if (this.props.onChange) {
                    this.props.onChange(items[this.state.selectedIndex].name, items[this.state.selectedIndex].id)
                }

                this.setState({value: "", selectedIndex: -1})
            }
        }
    }

    onFocus = () => {
        if (this.props.mode?.autoComplete) {

            this.setState({showData: true, selectedIndex: 0}, () => {
                const element = document.getElementById("form-textbox-data" + this.props.id)
                if (element) {
                    element.scrollTop = 0
                }
            })
        }
    }
    onBlur = () => {
        if (this.props.mode?.autoComplete) {
            this.setState({showData: false})
        } else if (this.props.onBlur) {
            this.props.onBlur()
        }
    }
    onHideInfo = () => {
        this.setState({infoText: undefined})
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (this.props.data) {
            let selectedIndex = this.state.selectedIndex
            let tagShown = this.getItems()!.find(i => i.name.toLowerCase() === e.target.value.toLowerCase().trim())
            let tagSelected = this.props.data?.find(i => i.name.toLowerCase() === e.target.value.toLowerCase().trim())

            if (tagShown && tagSelected === undefined) {
                if (this.state.selectedIndex !== 0) {
                    selectedIndex = 0
                }
            }

            if (tagSelected) {
                if (this.state.selectedIndex < 0) {
                    selectedIndex = 0
                }
            }

            this.setValue(e.target.value, e.target.value === "" ? -1 : selectedIndex)
        } else {
            this.setValue(e.target.value, -1)
        }
    }
    onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !this.props.multiline) {
            e.preventDefault()

            this.applyEntry()

            if (this.props.mode?.tags === undefined || !this.props.mode?.tags) {
                this.setState({showData: false})
            }
        } else if ((e.key === 'ArrowLeft') || (e.key === 'ArrowRight') || (e.key === 'Delete')) {
            e.stopPropagation()
        }

        if (this.props.mode?.autoComplete) {
            const elementsOnPage = 6
            const elementSize = 22
            const element = document.getElementById("form-textbox-data" + this.props.id)
            let value = (this.props.mode?.tags ? this.state.value : this.props.value)

            if (element) {
                if (e.key === 'ArrowUp') {
                    e.preventDefault()

                    let newIndex = this.state.selectedIndex - 1
                    if ((newIndex * elementSize) < element.scrollTop) {
                        element.scrollTop = newIndex * elementSize
                    } else if (((newIndex - elementsOnPage) * elementSize) > element.scrollTop) {
                        element.scrollTop = (newIndex - elementsOnPage) * elementSize
                    }

                    if (this.state.selectedIndex > (value !== "" ? -1 : 0)) {
                        this.setState({selectedIndex: newIndex})
                    }
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault()

                    let newIndex = this.state.selectedIndex + 1
                    if (newIndex > elementsOnPage) {
                        element.scrollTop = (newIndex - elementsOnPage) * elementSize
                    } else if ((newIndex * elementSize) < element.scrollTop) {
                        element.scrollTop = newIndex * elementSize
                    }

                    if (this.state.selectedIndex < (this.getItems()?.length || 0) - 1) {
                        this.setState({selectedIndex: newIndex})
                    }
                }
            }
        }
    }
    onItemClick = (entity: Entity) => {
        if (this.props.mode?.tags) {
            this.applyEntry(entity)
        } else {
            this.props.onChange?.(entity.name, entity.id)
            this.setState({showData: false})
        }
    }
    onDeleteTag = (index: number) => {
        this.props.onChangeTags?.(this.props.tags!.filter((tag, i) => i !== index))
    }

    filterData = (item: Entity) => {
        // In tag-mode check if the tag was already added, then hide it
        if (this.props.mode?.tags && this.props.tags) {
            const tag = this.props.tags.find(i => i.id === item.id)
            if (tag) {
                return false
            }

            return this.getFilterCondition(item.name, this.state.value)
        }

        return this.getFilterCondition(item.name, this.props.value)
    }

    getFilterCondition = (compareValue: string, value: string | undefined) => {
        return value === undefined || value === "" || compareValue.toLowerCase().startsWith(value.toLowerCase().trim())
    }
    getItems = () => {
        return this.props.data?.filter(i => this.filterData(i))
    }
    getTop = () => {
        if (this.props.mode?.tags && this.props.tags && this.props.tags.length > 0) {
            return "60px"
        }

        return "40px"
    }
    //TODO should not be called in render -> error in console
    showManualEntry = (value: string) => {

        if (this.props.data) {
            let tagShown = this.getItems()!.find(i => i.name.toLowerCase() === value.toLowerCase().trim())
            let tagSelected = this.props.data.find(i => i.name.toLowerCase() === value.toLowerCase().trim())

            if (tagShown && tagSelected === undefined) {
                // if (this.state.selectedIndex !== 0) {
                //     this.setState({selectedIndex: 0})
                // }
                return false
            }

            if (tagSelected) {
                // if (this.state.selectedIndex < 0) {
                //     this.setState({selectedIndex: 0})
                // }

                return false
            }
        }

        return (value !== "")
    }

    render() {
        let manualEntry = this.showManualEntry(this.props.mode?.tags ? this.state.value : this.props.value)
        let selectedIndex = this.state.selectedIndex

        let cssProperties = {...this.props.style} || {}
        if (this.props.width) {
            cssProperties.width = this.props.width
        }

        return <div className="form-group" style={cssProperties}>
            {this.props.label &&
                <Label labelText={this.props.label} htmlFor={this.props.id} requiredField={this.props.required}
                       className={this.props.labelClass}/>
            }

            {this.props.multiline === true ?
                <textarea id={this.props.id}
                          className={this.props.className}
                          rows={this.props.rows ? this.props.rows : 3}
                          name={this.props.id}
                          required={this.props.required}
                          readOnly={this.props.readonly}
                          maxLength={this.maxLengthOfText}
                          onChange={this.onChange}
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onKeyDown={this.onKeyDown}
                          placeholder={this.props.placeholder}
                          value={this.props.mode?.tags === true ? this.state.value : this.props.value}/>
                :
                <div className={"tag-input-container"}>

                    {this.props.tags &&
                        <ul className={"tag-input-list"} id={this.props.id + "-tags"}>
                            {this.props.syllable !== undefined
                                ?
                                this.props.tags.map((i, ind) => {
                                    return <li key={i.id || i.name}
                                               className={this.props.readonly ? "tag-disabled" : ""}>
                                        <WDSyllableWord syllableWord={i.name}
                                                        syllableDefinition={this.props.syllable!}/>
                                        {!this.props.readonly &&
                                            <img
                                                src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "coupling.svg"}
                                                alt={"Delete"}
                                                draggable={"false"}
                                                onContextMenu={(e) => e.preventDefault()}
                                                onClick={() => this.onDeleteTag(ind)}
                                            />
                                        }
                                    </li>
                                })
                                :
                                this.props.tags.map((i, ind) => {
                                    return <li key={i.id || i.name}
                                               className={this.props.readonly ? "tag-disabled" : ""}>
                                        {i.name}
                                        {!this.props.readonly &&
                                            <img
                                                src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "coupling.svg"}
                                                alt={"Delete"}
                                                draggable={"false"}
                                                onContextMenu={(e) => e.preventDefault()}
                                                onClick={() => this.onDeleteTag(ind)}
                                            />
                                        }
                                    </li>
                                })
                            }
                        </ul>
                    }

                    {(this.props.mode?.removeInputField === undefined || !this.props.mode.removeInputField) &&
                        <>
                            <input type="text" id={this.props.id}
                                   className={this.props.className}
                                   name={this.props.id}
                                   required={this.props.required}
                                   readOnly={this.props.readonly}
                                   maxLength={this.maxLengthOfText}
                                   onChange={this.onChange}
                                   onFocus={this.onFocus}
                                   onBlur={this.onBlur}
                                   onKeyDown={this.onKeyDown}
                                   placeholder={this.props.placeholder}
                                   value={this.props.mode?.tags === true ? this.state.value : this.props.value}
                            />

                            {this.state.infoText &&
                                <FloatingHint id={"info-calculation-tower-row-number"}
                                              notificationData={new NotificationData(NotificationStatus.info, this.state.infoText)}
                                              style={{width: "400px", left: "0px", top: "90%"}}
                                              onHide={this.onHideInfo}/>
                            }
                        </>
                    }
                </div>
            }
            {this.props.data && !this.props.readonly &&
                <ul id={"form-textbox-data" + this.props.id} className={"form-textbox-data"}
                    style={{
                        width: this.props.width,
                        top: this.getTop(),
                        display: this.state.showData ? "block" : "none"
                    }}>

            {manualEntry &&
                <li className={selectedIndex < 0 ? "selected" : ""} onMouseDown={() => this.applyEntry()}>
                    {this.props.mode?.tags ? this.state.value : this.props.value}
                </li>
            }

            {this.getItems()?.map((i, index) => {
                return <li key={i.id} className={selectedIndex === index ? "selected" : ""}
                           onMouseDown={() => this.onItemClick(i)}>{i.name}</li>
            })}
        </ul>
    }
    </div>
    }
}
