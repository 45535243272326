import React from "react";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {ResizeInfo, WDElementContainer} from "../WDElementContainer";
import Const from "../../../Framework/Const";
import {MainContext} from "../../../_base/MainContext";
import {GetImage, GetImageDetails, GetRandomImageByToolboxId} from "../../../_endpoint/ImageEndpoint";
import translations from "../../../Framework/translations.json";
import Conf from "../../../Framework/Conf";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {RenderingMedia, SolutionForceMode, Status} from "../../../Framework/Enums";
import {Util} from "../../../Framework/Util";
import {Coords} from "../../../Framework/Coords";
import Image, {ImageAlignment} from "../../../_model/Image";
import {RESIZE_NODE} from "../Enum/WDElementContainerResizeNode";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {MenuItem} from "../../../_model/MenuItem";
import {WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import {ImageCacheObject} from "../../../Framework/Cache/ImageCacheObject";
import {WDHistoryAction} from "../../History/Enum/WDHistoryAction";
import {WDActionLogCategory} from "../../ActionLog/WDActionLogEntry";

export class WDImageData extends WDElementBaseData {
    id: number
    width: number
    height: number
    url?: string
    crossOut: boolean
    crossOutSolution: boolean
    crossOutColor: string
    solution: boolean
    lineColor?: string
    status: Status
    showSolution: boolean
    toolboxItems: MenuItem[]

    constructor(id: number, width: number, height: number, status: Status) {
        super()
        this.id = id
        this.width = width
        this.height = height
        this.crossOut = false
        this.crossOutSolution = false
        this.crossOutColor = Const.COLOR_DARK_GREY
        this.solution = false
        this.status = status
        this.showSolution = true
        this.toolboxItems = []
    }

    static serialize(data: WDImageData): string {
        return JSON.stringify(data, (key, value) => {
            if (key === "url" || key === "width" || key === "height") return undefined;
            else return value;
        })
    }
}

export class WDImageCacheData {
    url: string
    colorable: boolean

    constructor(url: string, colorable: boolean) {
        this.url = url
        this.colorable = colorable
    }
}

interface IProps extends WDElementBaseProps {
    data: WDImageData
    renderingMedia: RenderingMedia
}

interface IState extends WDElementBaseState {
    imageData?: WDImageCacheData
    colorable: boolean
}

export class WDImage extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            isEdited: false,
            colorable: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef()
        }
    }

    componentDidMount() {
        if (this.props.data.id) {
            this.getImageData()
        }
        // New instance of image created by user (menu level 3)
        else {
            this.getRandomImageData()
        }
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.data.id !== this.props.data.id) {
            this.getImageData()
        }
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getDefaultWidth = () => {
        return 100;
    }
    static getDefaultHeight = () => {
        return 100;
    }
    static getDefaultContent = () => {
        let data = new WDImageData(0, WDImage.getDefaultWidth(), WDImage.getDefaultHeight(), Status.published)
        return WDImageData.serialize(data)
    }

    getMinWidth = () => {
        return 8
    }
    getMinHeight = () => {
        return 8
    }

    getImageData = async () => {
        let image = this.context.getCacheObject(this.props.data.id, WorksheetItemTypeEnum.IMAGE)
        if (image) {
            let imageData = JSON.parse(image.data) as WDImageCacheData
            if (this.props.renderingMedia === RenderingMedia.print) {
                imageData.url = await Util.compressImage(Conf.IMAGE_URL() + imageData.url, this.props.element.width)
            }

            this.setState({imageData: imageData})
        } else {
            this.context.log.debug("Image " + this.props.data.id + " not found in cache")
            this.context.log.flush()

            GetImage(this.props.data.id).then(
                async (image) => {
                    if (image.url) {
                        let imageData = new WDImageCacheData(image.url, image.colorable)
                        this.context.setCacheObject(new ImageCacheObject(this.props.data.id, JSON.stringify(imageData)))

                        if (this.props.renderingMedia === RenderingMedia.print) {
                            imageData.url = await Util.compressImage(Conf.IMAGE_URL() + imageData.url, this.props.element.width)
                        }

                        this.setState({imageData: imageData}, () => {
                            this.props.onElementLoaded?.(this.props.id)
                        })
                    }
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
                }
            )
        }
    }
    getRandomImageData = () => {
        if (this.props.data.toolboxItems.length > 0) {
            GetRandomImageByToolboxId(this.props.data.toolboxItems[0].id).then(
                async (image) => {
                    if (image.url) {
                        let imageData = new WDImageCacheData(image.url, image.colorable)
                        this.setState({imageData: imageData}, () => {
                            let newData = {...this.props.data}
                            newData.id = image.id!
                            newData.height = image.height || newData.height
                            newData.width = image.width || newData.width
                            newData.status = Status.published

                            let update = new WorksheetItemUpdate(this.props.id, {
                                content: this.serializeElementData(newData),
                                width: newData.width,
                                height: newData.height
                            })
                            this.props.onUpdateElement(update,
                                {actionCategory: WDActionLogCategory.create})
                        })
                    }
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
                }
            )
        }
    }
    replaceImage = (imageId: number) => {
        GetImageDetails(imageId).then(
            async (image) => {
                if (image.url === undefined) {
                    return
                }
                const alignment = image.alignment || ImageAlignment.quadratic
                let renderCoords = Image.getSizeByAlignment(alignment)
                if (image.width && image.height) {
                    renderCoords = Util.calculateRenderSize(new Coords(image.width, image.height), alignment)
                }

                renderCoords.x = renderCoords.x * (this.props.element.height / renderCoords.y)
                renderCoords.y = this.props.element.height

                let newData = {...this.props.data}
                newData.id = imageId

                let update = new WorksheetItemUpdate(this.props.id, {
                    content: this.serializeElementData(newData),
                    width: renderCoords.x,
                    height: renderCoords.y
                })
                await this.props.onUpdateElement(update, {
                    historyAction: WDHistoryAction.TOOLBAR_ACTION,
                    actionCategory: WDActionLogCategory.toolbar_action,
                    actionData: { action: "REPLACE_IMAGE", id: imageId }
                })

                let imageData = new WDImageCacheData(image.url, image.colorable)
                this.setState({imageData: imageData})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    getAdditionalToolbarData = () => {
        return JSON.stringify({
            colorable: this.state.colorable
        })
    }

    doAction = (action: WDToolbarAction, data?: string) => {
        let newData = {...this.props.data}

        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.CROSS_OUT:
                if (data?.["color"] !== newData.crossOutColor) {
                    newData.crossOut = true
                } else {
                    newData.crossOut = !newData.crossOut
                }

                if (!newData.crossOut) {
                    newData.crossOutSolution = false
                }

                newData.crossOutColor = data?.["color"]
                break

            case WDToolbarAction.LINE_COLOR:
                if (data) {
                    newData.lineColor = data["lineColor"]
                }
                break

            case WDToolbarAction.SOLUTION_CROSS_OUT:
                if (!newData.crossOut && !newData.crossOutSolution) {
                    newData.crossOut = true
                }
                newData.crossOutSolution = !newData.crossOutSolution
                break

            case WDToolbarAction.SOLUTION:
                newData.solution = !newData.solution
                break

            case WDToolbarAction.CHANGE_SOLUTION_SHOW:
                newData.showSolution = !newData.showSolution
                break

            case WDToolbarAction.SELECT_IMAGE:
                let imageId = data?.["image"] as number
                this.replaceImage(imageId)
                break
        }

        if (action !== WDToolbarAction.SELECT_IMAGE) {
            update.value.content = this.serializeElementData(newData)
        }
        return update
    }

    onResizeElement = (proportional: boolean, x: number, y: number) => {
        // images can only get resized proportionally
        this.props.onElementResize?.(true, x, y)
    }
    resizeElement = (proportional: boolean, x: number, y: number, nodeType?: RESIZE_NODE) => {
        // overwrite element base method to set proportional always to true
        return this.state.elementRef.current?.resizeElement(true, x, y, nodeType)
    }

    /**
     * Overridden methods
     */
    getSourceRecordId = () => {
        return this.props.data.id
    }
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDImageData.serialize(data as WDImageData)
    }

    renderSolution = (): boolean => {
        if (this.props.inPresentationMode) {
            return this.props.element.renderSolutionInPresentationMode
        } else {
            return (this.props.solutionForceMode === SolutionForceMode.ForceShow ||
                (this.props.solutionForceMode === SolutionForceMode.Off && this.props.data.showSolution))
        }
    }
    renderCrossOut = () => {
        if (this.props.data.crossOutSolution && !this.renderSolution()) {
            return
        }

        const thickness = this.props.element.width / 50

        // bottom left
        let x1 = 0;
        let y1 = this.props.element.height;
        // top right
        let x2 = this.props.element.width;
        let y2 = 0;

        // distance
        let length = Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1))) - 20;

        // center
        let cx = ((x1 + x2) / 2) - (length / 2);
        let cy = ((y1 + y2) / 2) - (thickness / 2);

        // angle
        let angle = Math.atan2((y1 - y2), (x1 - x2)) * (180 / Math.PI);

        let color = (this.props.data.crossOutSolution ? "transparent" : this.props.data.crossOutColor)

        // make hr
        return <div
            className={"ws-designer-image-cross-out print" + (this.props.data.crossOutSolution ? " ws-designer-image-cross-out-solution" : "")}
            style={{
                height: thickness,
                left: cx,
                top: cy,
                width: length,
                backgroundColor: color,
                transform: "rotate(" + angle + "deg)"
            }}
        />
    }

    render() {
        let imageUrl = this.state.imageData?.url
        if (this.props.renderingMedia !== RenderingMedia.print) {
            imageUrl = Conf.IMAGE_URL() + imageUrl
        }

        let resizeInfo: ResizeInfo =
            new ResizeInfo(!this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly,
                false, false, false, false,
                this.getMinWidth(), Const.MaxElementSize, this.getMinHeight(), Const.MaxElementSize)

        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }
        let className = "image-in-container print"
        if (this.props.data.solution) {
            className = "ws-designer-element-solution"
        }
        if (this.props.data.status !== Status.published) {
            className = "ws-designer-element-inactive"
        }
        if (this.props.data.lineColor !== undefined) {
            className += " svg-color-" + this.props.data.lineColor.toUpperCase()
        }

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            hasResizeOnCreate={false}
            resizeInfo={resizeInfo}
            renderWrapper={true}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.onResizeElement}
            isEditModeAllowed={() => false}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            ref={this.state.elementRef}
        >

            {this.state.imageData?.url !== undefined && (!this.props.data.solution || this.renderSolution()) &&
                <img src={imageUrl}
                     className={className}
                     alt={""} style={{width: "100%", height: "100%"}} crossOrigin={"anonymous"}/>
            }
            {this.props.data.crossOut &&
                this.renderCrossOut()
            }

        </WDElementContainer>
    }
}
