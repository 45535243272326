import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbar, WDToolbarOrientation} from "../../Toolbar/Toolbar/WDToolbar";
import {WDToolbarSectionTransformation} from "../../Toolbar/Section/WDToolbarSectionTransformation";
import Converter from "../../../Framework/Converter";
import {MainContext} from "../../../_base/MainContext";
import {ElementLayout} from "../WDElementContainer";
import {WDToolbarSectionObjectGeneral} from "../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../Toolbar/Toolbar/WDToolbarTabs";
import {WorksheetItemType, WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import _ from "lodash";
import {WDToolbarButtonGraphics} from "../../Toolbar/Button/General/WDToolbarButtonGraphics";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../Framework/CategoryImage";
import {TooltipText} from "../../../Components/Tooltips";
import translations from "../../../Framework/translations.json";
import {Slider} from "../../../Components/Controls/Slider";
import {WDToolbarButton} from "../../Toolbar/Button/WDToolbarButton";
import {WDToolbarButtonColorPicker} from "../../Toolbar/Button/General/WDToolbarButtonColorPicker";
import Const from "../../../Framework/Const";

export interface WDToolbarLineProps extends WDToolbarElementProps {
}

export interface WDToolbarLineState extends WDToolbarElementState {
    colorPickerOpen: boolean
}

export class WDLineToolbar extends WDToolbarElement<WDToolbarLineProps, WDToolbarLineState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: WDToolbarLineProps) {
        super(props);

        this.state = {
            colorPickerOpen: false,

            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef()
        }
    }

    shouldComponentUpdate(nextProps: Readonly<WDToolbarLineProps>, nextState: Readonly<WDToolbarLineState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getElementGraphic = (obj: any) => {
        // #4266 Graphic of changeGraphic button should change matching to the element
        let iconString = ImagePath.getLineUrl() + "at-alg-standard_linie-thumb.svg"
        if(obj && obj.newValue) {
            let key = "AT_ALG_LINE_" + obj.newValue.toString().toUpperCase()
            iconString = ImagePath.getLineUrl() + CategoryImageValue.getImageByKey([ImageCategory.LINE], key).toString()
        }

        return iconString
    }

    onToggleColorPicker = () => {
        this.setState({colorPickerOpen: !this.state.colorPickerOpen})
    }

    onChangeBorderWidth = (value: number) => {
        let border = this.props.elementProps.border
        border.visible = true
        border.weight = value
        this.props.onChangeBorder(border)
    }
    onChangeBorderColor = (color: string) => {
        let borderOptions = this.props.elementProps.border
        borderOptions.color = color
        borderOptions.visible = true

        this.setState({colorPickerOpen: false}, () => {this.props.onChangeBorder(borderOptions)})
    }

    render () {
        let graphics = this.multiselectSearchForValue("type")
        let borderColor = this.props.elementProps.border.color

        if (borderColor === "transparent") {
            borderColor = Const.COLOR_PRIMARY
        }

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, false, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&

            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minLeft: this.props.elementProps.minLeft,
                        minTop: this.props.elementProps.minTop,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                        maxLeft: this.props.elementProps.maxLeft !== undefined ? Math.round(Converter.pxToMm(this.props.elementProps.maxLeft)) : undefined,
                        maxTop: this.props.elementProps.maxTop !== undefined ? Math.round(Converter.pxToMm(this.props.elementProps.maxTop)) : undefined,
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={this.isUsedInTextExerciseChild()}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={false}
                    canChangeLinkState={false}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&

            <ul className={"ws-designer-toolbar-buttons"}>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonGraphics
                            id={2}
                            icon={this.getElementGraphic(graphics)}
                            pressed={this.state.activeId === 2}
                            clickable={this.state.isLicenseValidForElement}
                            imageCategory={[ImageCategory.LINE]}
                            imagePath={ImagePath.getLineUrl()}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                            imageWidth={310}
                            tooltip={new TooltipText(
                                this.context.translate(translations.toolbar.change_graphic),
                                this.context.translate(translations.tooltip.change_graphic)
                            )}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.change_graphic_disabled),
                                this.context.translate(translations.tooltip.no_license))
                            }
                            text={this.context.translate(translations.toolbar.graphic)}
                            iconImageWidth={243}
                            iconImageHeight={39}
                            iconButtonWidth={250}
                            iconButtonHeight={39}
                        />
                    </div>

                    <div className={"ws-designer-toolbar-row"} style={{paddingLeft: "2px"}}>
                        <label className={"label"} style={{marginRight: "4px"}}>
                            {this.context.translate(translations.toolbar.border.weight)}
                        </label>
                        <Slider
                            value={this.props.elementProps.border.weight > 0 ? this.props.elementProps.border.weight : 3}
                            minValue={1}
                            maxValue={15}
                            width={158}
                            onChangeValue={this.onChangeBorderWidth}
                        />

                        <WDToolbarButton id={10}
                                         icon={ImagePath.getButtonUrl() + "edit.svg"}
                                         buttonWidth={32}
                                         buttonHeight={32}
                                         cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                                         color={borderColor}
                                         onToggleButton={this.onToggleColorPicker}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.border.border_color),
                                             this.context.translate(translations.tooltip.border_color)
                                         )}
                                         arrow={false}
                                         enabled={true}
                                         pressed={false} />

                        <WDToolbarButtonColorPicker
                            id={11}
                            tooltip={new TooltipText(
                                this.context.translate(translations.toolbar.border.border_color),
                                this.context.translate(translations.tooltip.border_color)
                            )}
                            open={this.state.colorPickerOpen}
                            clickable={true}
                            transparency={false}
                            onToggleButton={this.onToggleColorPicker}
                            onChangeColor={this.onChangeBorderColor}
                        />
                    </div>
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={false}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    worksheetItemType={new WorksheetItemType("", WorksheetItemTypeEnum.IMAGE)}
                    elementProps={this.props.elementProps}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onChangeBorder={this.props.onChangeBorder}
                    onElementDeleted={this.props.onElementDeleted}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onAddRule={this.props.onAddRule}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                />
            </ul>
            }
        </WDToolbar>
    }
}
