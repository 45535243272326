import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipPosition, TooltipText} from "../../../../Components/Tooltips";

/**
 * Button (Yes/No) in Toolbar
 * Effects selected element(s)
 * Only possible if exercise is generated by the logic of the element
 * Yes: solutions of exercises should be shown but in different color
 * No:  solutions should be hidden
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean

    tooltip?: TooltipText
    tooltipDisabled?: TooltipText
    tooltipPosition?: TooltipPosition

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

export class WDToolbarButtonSolutionShow extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    changeShowSolution = () => {
        let valuePressed: any = {"showSolution": !this.props.pressed}
        this.props.onActionButton(WDToolbarAction.CHANGE_SOLUTION_SHOW, valuePressed)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        let tooltip = this.props.tooltip

        if(!tooltip) {
            tooltip = new TooltipText(
                this.context.translate(translations.toolbar.solution_show_hide),
                this.context.translate(translations.tooltip.solution_show_hide)
            )
        }

        return <li
            key={"ws-designer-toolbar-button-solution-show"}
            className={"ws-designer-toolbar-item"}
            id={"solutionShow"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getMathUrl() + "solution_show.svg"}
                             tooltip={tooltip}
                             tooltipDisabled={this.props.tooltipDisabled}
                             tooltipPosition={this.props.tooltipPosition}
                             onToggleButton={this.changeShowSolution}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed} />
        </li>
    }
}
