import {GenderEnum} from "./Noun";

export enum CaseEnum {
    first= "first",
    second = "second",
    third = "third",
    fourth = "fourth"
}
export enum PersonEnum {
    ich = "ich",
    du = "du",
    er = "er",
    sie = "sie",
    es = "es",
    wir = "wir",
    ihr = "ihr",
    sie_pl = "sie_pl"
}

export class PossessivePronouns {
    id?: number
    gender?: GenderEnum
    casePos?: CaseEnum
    person?: PersonEnum
    possessiveValue?: string
    plural?: boolean
}

export default PossessivePronouns
