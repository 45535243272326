import Entity from "./Entity";

export class LessonSubject extends Entity {
    code: string

    constructor(name: string, code: string, id?: number) {
        super(name, id);

        this.code = code
    }
}

export default LessonSubject
