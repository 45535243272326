import React from 'react';
import translations from "../../../Framework/translations.json";
import TextBox from "../../../Components/Controls/TextBox";
import {
    EnumToEntityArray,
    EnumValueToValueNumber,
} from "../../../Framework/Enums";
import {MainContext} from "../../../_base/MainContext";
import {WordType, WordTypeProps, WordTypeState} from "../WordType";
import {
    CreateAdverb,
    GetAdverb,
    UpdateAdverb
} from "../../../_endpoint/WordEndpoint";
import Word from "../../../_model/Dictionary/Word";
import {EntityData} from "../../../_model/Entity";
import SelectBox from "../../../Components/Controls/SelectBox";
import Adverb, {AdverbType} from "../../../_model/Dictionary/Adverb";

export interface IProps extends WordTypeProps {
    wordId?: number
    readonly: boolean
}

interface IState extends WordTypeState {
    item: Adverb
    unsavedChanges: boolean
}

export default class AdverbFormPart extends WordType<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            item: new Adverb(),
            unsavedChanges: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        if (this.props.wordId) {
            GetAdverb(this.props.wordId).then(
                (itemData) => {
                    this.setState({item: itemData})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }

    // close and success messages are done in word
    onSave = async (word: Word) : Promise<EntityData | undefined> => {
        let result

        if (word.id) {
            result = await UpdateAdverb(this.state.item, word)
        } else {
            result = await CreateAdverb(this.state.item, word)
        }

        this.setState({unsavedChanges: false})
        return result
    }

    onChangeText = (fieldName: string, value: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeEnum = (value: number, enumType: any, itemProperty: string) => {
        // Get enum value by index (value)
        let enumValue = Object.values(enumType).find((e, i) => i === value)
        if (enumValue) {
            let currentItem = this.state.item
            currentItem[itemProperty] = enumValue
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }

    render() {
        return <>
            <div className={"form-row"}>
                <TextBox id={"txtSyllabification"}
                         label={this.context.translate(translations.fields.word.adverb.syllabification)}
                         width={400}
                         required={true}
                         readonly={this.props.readonly}
                         onChange={(text: string) => this.onChangeText("syllabification", text)}
                         value={this.state.item.syllabification || ""}
                />

                <SelectBox id={"selAdverbType"}
                           width={395}
                           label={this.context.translate(translations.fields.word.adverb.adverb_type)}
                           data={EnumToEntityArray(AdverbType, translations.enum.adverb_type, this.context.translate)}
                           required={true}
                           value={EnumValueToValueNumber(AdverbType, this.state.item.adverbType)}
                           readonly={this.props.readonly}
                           onChange={(value) => this.onChangeEnum(value, AdverbType, "adverbType")}
                           positioningRow={false}
                />
            </div>
        </>
    }
}

