import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Clickable without pressed version
 * After click - row should be deleted
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    onActionButton: (action: WDToolbarAction) => void
    onToggleButton: (id: number) => void
}

export class WDToolbarButtonDeleteColumn extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    deleteRow = () => {
        this.props.onActionButton(WDToolbarAction.DELETE_COLUMN)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-delete-column"}
            id={"deleteColumn"}>

            <WDToolbarButton id={this.props.id}
                                     enabled={this.props.clickable}
                                     icon={ImagePath.getButtonUrl() + "delete_column.svg"}
                                     tooltip={new TooltipText(this.context.translate(translations.toolbar.delete_col),
                                                              this.context.translate(translations.tooltip.delete_col))}
                                     arrow={false}
                                     onToggleButton={this.deleteRow}
                                     pressed={this.props.pressed} />
        </li>
    }
}
