import Entity from "../../_model/Entity";
import translations from "../../Framework/translations.json";
import {WorksheetLog} from "../../_model/WorksheetLog";
import {Resource} from "../../Framework/Util";

export class WorksheetLogListItem extends Entity {
    id: number
    marketplaceStatus: string

    createdOn: Date
    createdBy: string

    constructor(id: number, marketplaceStatus: string) {

        super("", id);
        this.id = id
        this.marketplaceStatus = marketplaceStatus
        this.createdOn = new Date()
        this.createdBy = ""
    }

    static convertWorksheetLogArray(logs: WorksheetLog[], T: (r: Resource) => string) {
        return logs
            .sort((a, b) => {
                return new Date(b.createdOn ? b.createdOn : Date.now()).getTime() - new Date(a.createdOn || Date.now()).getTime()
            })
            .map(log => WorksheetLogListItem.convertWorksheetLog(log, T))
    }

    static convertWorksheetLog(log: WorksheetLog, T: (r: Resource) => string) {
        let listItem = new WorksheetLogListItem(log.id, T(translations.enum.marketplace_ws_status[log.marketplaceStatus]))
        listItem.createdOn = log.createdOn || new Date()
        listItem.createdBy = log.createdBy ? log.createdBy.firstname + " " + log.createdBy.lastname : ""
        return listItem
    }
}
