import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbarButtonSeparator} from "../../Toolbar/Button/General/WDToolbarButtonSeparator";
import {WDToolbar, WDToolbarOrientation} from "../../Toolbar/Toolbar/WDToolbar";
import {WDToolbarButtonTextDecoration} from "../../Toolbar/Button/Text/WDToolbarButtonTextDecoration";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {ImageCategory, ImagePath} from "../../../Framework/CategoryImage";
import translations from "../../../Framework/translations.json";
import {WDToolbarButtonList} from "../../Toolbar/Button/Text/WDToolbarButtonList";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarSectionText, WDToolbarSectionTextSettings} from "../../Toolbar/Section/WDToolbarSectionText";
import {WDToolbarButton} from "../../Toolbar/Button/WDToolbarButton";
import {WDToolbarLineHeight} from "../../Toolbar/Button/Text/WDToolbarLineHeight";
import {WDToolbarButtonConvertToLineText} from "../../Toolbar/Button/Text/WDToolbarConvertToLineText";
import {TooltipText} from "../../../Components/Tooltips";
import {WDToolbarButtonSolution} from "../../Toolbar/Button/Admin/WDToolbarButtonSolution";
import {WDToolbarSectionTransformation} from "../../Toolbar/Section/WDToolbarSectionTransformation";
import {ElementLayout} from "../WDElementContainer";
import Converter from "../../../Framework/Converter";
import {WDToolbarSectionObjectGeneral} from "../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WSContext} from "../WSContext";
import {WDToolbarButtonVariableText} from "../../Toolbar/Button/Admin/WDToolbarButtonVariableText";
import {WDToolbarButtonConvertToSyllableText} from "../../Toolbar/Button/Text/WDToolbarButtonConvertToSyllableText";
import {SyllableMethod} from "../../../_model/Dictionary/Word";
import _ from "lodash";

export interface WDTextboxToolbarProps extends WDToolbarElementProps {
}

export interface WDTextboxToolbarState extends WDToolbarElementState {
    syllableMethod: SyllableMethod
}

export class WDTextboxToolbar extends WDToolbarElement<WDTextboxToolbarProps, WDTextboxToolbarState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    textToolbarSettings: WDToolbarSectionTextSettings = new WDToolbarSectionTextSettings(true, true,
        true, true, true, true, true, true
        , true, true, true, true, true)
    
    constructor(props: WDTextboxToolbarProps) {
        super(props);

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef(),
            syllableMethod: SyllableMethod.noSyllable
        }

        if(this.isUsedInTextExerciseChild()) {
            this.textToolbarSettings = new WDToolbarSectionTextSettings(false, false,
                false, false, false, false, false,
                false, false, false, false, false, false)
        }
    }

    componentDidMount() {
        this.context.getUserSettings().then (userSettings => {
            this.setState({syllableMethod: userSettings.syllable})
        })
    }
    componentDidUpdate(prevProps: WDTextboxToolbarProps) {
        this.evaluateToolbarType(prevProps.editMode, this.props.editMode)
    }
    shouldComponentUpdate(nextProps: Readonly<WDTextboxToolbarProps>, nextState: Readonly<WDTextboxToolbarState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    render () {
        // Get values to display data - important if more than one element is selected
        let activateSyllableValueObj = this.multiselectSearchForValue("syllableActivated")
        const activateSyllable = (activateSyllableValueObj?.valueEqual && activateSyllableValueObj.newValue !== undefined) ? activateSyllableValueObj.newValue : false

        return <WDToolbar
                id={"-element"}
                left={this.props.left}
                top={this.props.top - 4}
                isLocked={this.props.elementProps.locked}
                calculatePosition={true}
                className={"ws-designer-toolbar-two-line"}
                orientation={WDToolbarOrientation.top}
                activeToolbarType={this.state.activeToolbarType}
                toolbarTabsConfig={new WDToolbarTabsConfig(true, true, true, this.onChangeToolbarTab)}
                ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&
                <ul className={"ws-designer-toolbar-buttons"}>
                    <div className={"ws-designer-toolbar-section"}>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonConvertToSyllableText
                                id={604}
                                pressed={activateSyllable}
                                clickable={this.state.syllableMethod !== SyllableMethod.noSyllable}
                                onActionButton={this.onToolbarAction}
                                onToggleButton={this.onToggleButton}
                                onShowConfirmation={this.props.onShowConfirmation}
                            />
                        </div>
                        <div className={"ws-designer-toolbar-row"}>

                            <WDToolbarButtonConvertToLineText
                                id={601}
                                pressed={false}
                                clickable={!this.isUsedInTextExerciseChild()}
                                onActionButton={this.onToolbarAction}
                                onToggleButton={this.onToggleButton}
                                onShowConfirmation={this.props.onShowConfirmation}
                            />

                            {/* Show button to mark solution based on worksheet context */}
                            {WSContext.showToolbarButtonMarkSolution(this.props.context) &&
                                <WDToolbarButtonSolution
                                    id={602}
                                    clickable={!this.isUsedInTextExerciseChild()}
                                    icon={"solution_mark.svg"}
                                    onActionButton={this.onToolbarAction}
                                    action={WDToolbarAction.SOLUTION}
                                />
                            }

                            {/* Show button to select variable text based on worksheet context */}
                            {WSContext.showToolbarButtonVariableText(this.props.context) &&
                                <WDToolbarButtonVariableText
                                    id={603}
                                    clickable={!this.isUsedInTextExerciseChild()}
                                    onActionButton={this.onToolbarAction}
                                    action={WDToolbarAction.VARIABLE_TEXT}
                                />
                            }
                        </div>
                    </div>

                    <WDToolbarSectionObjectGeneral
                        activeId={this.state.activeId}
                        showColorOptions={true}
                        showBorderPadding={true}
                        onToolbarAction={this.onToolbarAction}
                        onChangeActive={() => this.setState({activeId: -1})}
                        context={this.props.context}
                        elementProps={this.props.elementProps}
                        onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                        onChangeBorder={this.props.onChangeBorder}
                        onDuplicate={this.props.onDuplicate}
                        onCopy={this.props.onCopy}
                        onCut={this.props.onCut}
                        onPaste={this.props.onPaste}
                        onElementDeleted={this.props.onElementDeleted}
                        onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                    />
                </ul>
            }
            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&
                <ul className={"ws-designer-toolbar-buttons"}>

                    <WDToolbarSectionTransformation
                        elementProps={{
                            layout: new ElementLayout(
                                Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                                Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                                Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                                Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                            ),
                            transformation: this.props.elementProps.transformation,
                            border: this.props.elementProps.border,
                            color: this.props.elementProps.color,
                            minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                            minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                            maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                            maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                            locked: this.props.elementProps.locked
                        }}
                        context={this.props.context}
                        selectedElementCount={this.props.selectedElementCount}
                        elementsOnSameSheet={this.props.elementsOnSameSheet}
                        isReadonly={this.isUsedInTextExerciseChild()}
                        isEdited={this.props.editMode}
                        initialLinkState={false}
                        showColorOptions={false}
                        canChangeLinkState={true}
                        onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                        onFlipHorizontal={this.props.onFlipHorizontal}
                        onFlipVertical={this.props.onFlipVertical}
                        onArrange={this.props.onArrange}
                        onAlign={this.props.onAlign}
                        onDistribute={this.props.onDistribute}
                    />
                </ul>
            }
            {this.state.activeToolbarType === WDToolbarTypeEnum.TEXT &&
                <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionText
                    toolbarSettings={this.textToolbarSettings}
                    onToolbarAction={this.onToolbarAction} />

                <WDToolbarButtonSeparator/>

                {/* Justify content */}
                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonTextDecoration
                            id={401}
                            clickable={!this.isUsedInTextExerciseChild()}
                            colorPicker={false}
                            command={"justifyLeft"}
                            action={WDToolbarAction.JUSTIFY_LEFT}
                            icon={ImagePath.getButtonUrl() + "justify_left.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.justify_left))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonTextDecoration
                            id={403}
                            clickable={!this.isUsedInTextExerciseChild()}
                            colorPicker={false}
                            command={"justifyRight"}
                            action={WDToolbarAction.JUSTIFY_RIGHT}
                            icon={ImagePath.getButtonUrl() + "justify_right.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.justify_right))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonList
                            id={501}
                            clickable={!this.isUsedInTextExerciseChild()}
                            command={"insertUnorderedList"}
                            action={WDToolbarAction.UNORDERED_LIST}
                            options={{
                                action: WDToolbarAction.UNORDERED_LIST_IMAGE,
                                open: this.state.activeId === 501,
                                imageCategory: ImageCategory.UNORDERED_BULLET_LIST,
                                imagePath: ImagePath.getListStyleUrl(),
                                width: 215,
                                height: 150,
                                itemWidth: 55,
                                itemHeight: 55
                            }}
                            icon={ImagePath.getButtonUrl() + "bulletpoints.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.unordered_list))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonList
                            id={502}
                            clickable={!this.isUsedInTextExerciseChild()}
                            command={"insertOrderedList"}
                            action={WDToolbarAction.ORDERED_LIST}
                            options={{
                                action: WDToolbarAction.ORDERED_LIST_IMAGE,
                                open: this.state.activeId === 502,
                                imageCategory: ImageCategory.ORDERED_BULLET_LIST,
                                imagePath: ImagePath.getListStyleUrl(),
                                width: 215,
                                height: 150,
                                itemWidth: 55,
                                itemHeight: 55
                            }}
                            icon={ImagePath.getButtonUrl() + "numbering.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.ordered_list))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonTextDecoration
                            id={402}
                            clickable={!this.isUsedInTextExerciseChild()}
                            colorPicker={false}
                            command={"justifyCenter"}
                            action={WDToolbarAction.JUSTIFY_CENTER}
                            icon={ImagePath.getButtonUrl() + "justify_center.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.justify_center))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonTextDecoration
                            id={404}
                            clickable={!this.isUsedInTextExerciseChild()}
                            colorPicker={false}
                            command={"justifyFull"}
                            action={WDToolbarAction.JUSTIFY_FULL}
                            icon={ImagePath.getButtonUrl() + "justify_block.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.justify_full))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButton id={12}
                                         icon={ImagePath.getButtonUrl() + "line_spacing.svg"}
                                         tooltip={new TooltipText(this.context.translate(translations.toolbar.line_height))}
                                         cssClass={"ws-designer-toolbar-button-ml"}
                                         buttonClass={"ws-designer-toolbar-button-image-no-hover"}
                                         iconClassSelected={"svg-color-1E1F28"}
                                         iconClassUnselected={"svg-color-1E1F28"}
                                         onToggleButton={() => {}}
                                         imageWidth={13}
                                         buttonWidth={24}
                                         arrow={false}
                                         enabled={!this.isUsedInTextExerciseChild()}
                                         pressed={false} />

                        <WDToolbarLineHeight id={203}
                                             readonly={this.isUsedInTextExerciseChild()}
                                             action={WDToolbarAction.LINE_HEIGHT}
                                             onActionButton={this.onToolbarAction}
                        />
                    </div>
                </div>
            </ul>
            }
        </WDToolbar>
    }
}
