import React from "react";
import {Route, Redirect} from "react-router-dom";
import translations from "../../Framework/translations.json";
import Auth from "../Auth";
import {Modal} from "../../Components/Modal";
import {LoginForm} from "../../Login/LoginForm";
import {MainContext} from "../../_base/MainContext";
import {ButtonInfo} from "../../Components/Controls/ButtonList";
import {ProductLicense} from "../Enums";
import {VicidoProduct} from "../../_model/VicidoProduct";

interface IProps {
    path: string
    admin: boolean
    component: React.ComponentClass<any, any>
    userLicenses: VicidoProduct[]

    productLicenses?: Array<ProductLicense>
}
interface IState {
    loginFormRef: React.RefObject<LoginForm>,
}

export class ProtectedRouter extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            loginFormRef: React.createRef()
        }
    }

    onLoginSubmit = () => {
        this.state.loginFormRef.current?.handleLoginSubmit()
    }
    onPostLogin = () => {
        this.forceUpdate()
        //location.reload();
    }
    onLoginCancel = () => {
        Auth.Logout()
        this.forceUpdate()
        //location.reload();
    }
    hasValidProductLicense = () : boolean => {
        if(this.props.productLicenses) {
            for (let counter = 0; counter < this.props.productLicenses.length; counter ++) {
                if (this.props.userLicenses.find(p => p.id === this.props.productLicenses![counter])) {
                    return true
                }
            }

            return false
        }
        return true
    }

    render() {
        if (this.props.admin && !Auth.isAdmin()) {
            return <Redirect to={"/dashboard"}/>
        }
        if (!this.hasValidProductLicense()) {
            return <Redirect to={"/dashboard"}/>
        }

        if(Auth.isLoggedIn()) {
            return <Route path={this.props.path} component={this.props.component}/>
        }
        else if (Auth.isTokenRefreshable()) {

            return <>
                <Route path={this.props.path} component={this.props.component}/>
                <Modal id={"login"}
                       onFormSubmit={this.onLoginSubmit}
                       title={this.context.translate(translations.notification.login_expired_title)}
                       buttons={[
                           new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onLoginCancel, {}),
                           new ButtonInfo("btnLogin", "button button-save", "submit", true, false, this.context.translate(translations.text.login), this.onLoginSubmit, {marginLeft: "30px"})
                       ]}
                       dialogStyle={{width: "400px", height: "300px"}}
                       contentAlignment={"flex-start"}
                >
                    <div style={{marginBottom: 30}}>
                        {this.context.translate(translations.notification.login_expired)}
                    </div>
                    <LoginForm showLoginButton={false} onPostLogin={this.onPostLogin} ref={this.state.loginFormRef} />
                </Modal>
            </>
        }

        return <Redirect to={"/login"} />
    }
}
