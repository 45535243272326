import React from "react";
import '../assets/css/layout.min.css';
import {MainContext} from "../_base/MainContext";
import translations from "../Framework/translations.json";
import {WSMarketplaceStatus} from "../Framework/Enums";
import {TooltipPosition, Tooltips, TooltipText} from "../Components/Tooltips";

interface IProps {
    translateX: number
    translateY: number
    tooltipPosition: TooltipPosition
    mpStatus: WSMarketplaceStatus
}

interface IState {
}

export class MPStatusIcon extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        if (this.props.mpStatus === WSMarketplaceStatus.rejected) {
            return <></>
        }

        return <div className={"tooltip-" + this.props.tooltipPosition.valueOf()}>
            <div className={"card-status card-status-" + this.props.mpStatus + " tooltip-control"}/>
            <Tooltips text={new TooltipText(
                this.context.translate(translations.enum.marketplace_ws_status[this.props.mpStatus]),
                this.context.translate(translations.tooltip.mp_status[this.props.mpStatus]))}
                      translateX={this.props.translateX} translateY={this.props.translateY}/>
        </div>
    }
}
