import Entity from "../../_model/Entity";

export default class WordListItem extends Entity {
    wordDefinition: string
    wordLinks: string
    syllable: string
    synonyms: string
    status: string
    modifiedOn: Date
    owner: string
    createdBy: string

    constructor(id: number, name: string, syllable: string, synonyms: string, owner: string, modifiedOn: Date,
                wordDefinition: string, wordLinks: string, status: string, createdBy: string) {
        super(name, id);

        this.wordDefinition = wordDefinition
        this.wordLinks = wordLinks
        this.owner = owner
        this.modifiedOn = modifiedOn
        this.status = status
        this.syllable = syllable
        this.synonyms = synonyms
        this.createdBy = createdBy
    }
}
