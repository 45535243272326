import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButtonGraphicsImage} from "./WDToolbarButtonGraphicsImage";
import {TooltipText} from "../../../../Components/Tooltips";
import Entity from "../../../../_model/Entity";

/**
 * Button (with submenu) in Toolbar
 * Effects selected element(s)
 * Shows all given images with given width
 * Click Image:   Checks which images was clicked - images of element should change to selected
 *
 * @param icon: if icon is provided, the arrow will be within the icon-button; otherwise the button is only the arrow
 * @param text: if text is provided, text with arrow will be shown
 * Hint: please only provide icon or text for button representation
 *
 * @param submenuWidth: if not provided, default value will be set
 *
 * @param onClickItem: method which will be called when item in submenu is selected
 * @param onActionButton: calls button action and returns data
 * Hint: please only provide one of the above methods
 *
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltip: TooltipText
    tooltipDisabled?: TooltipText

    imageWidth: number
    imageHeight?: number
    images: Entity[]

    thumbnail: string
    thumbnailButtonWidth?: number
    thumbnailButtonHeight?: number
    thumbnailImageWidth?: number
    thumbnailImageHeight?: number

    submenuWidth?: number
    submenuHeight?: number

    filter?: JSX.Element

    onToggleButton: (id: number) => void
    onActionButton?: (action: WDToolbarAction, data?: any) => void
    onClickItem?: (id: string) => void
}
interface IState {
}

export class WDToolbarButtonThumbnail extends React.Component<IProps, IState> {

    onClickItemAction = (key: string) => {
        if(this.props.onClickItem) {
            this.props.onClickItem(key)
        }
        else if(this.props.onActionButton) {
            this.props.onActionButton(WDToolbarAction.CHANGE_TASK, {task: key})
        }
    }

    render() {
        let optionsClass = "ws-designer-toolbar-options ws-designer-toolbar-thumbnail"

        return <li
            style={{position: "relative"}}
            key={"ws-designer-toolbar-button-thumbnail"}
            id={"change-thumbnail-" + this.props.id}>

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             thumbnail={this.props.thumbnail}
                             tooltip={this.props.tooltip}
                             tooltipDisabled={this.props.tooltipDisabled}

                             arrow={true}
                             buttonHeight={this.props.thumbnailButtonHeight}
                             buttonWidth={this.props.thumbnailButtonWidth}
                             imageHeight={this.props.thumbnailImageHeight}
                             imageWidth={this.props.thumbnailImageWidth}
                             iconClassDisabled={"ws-designer-toolbar-button-disabled-thumbnail"}

                             onToggleButton={this.props.onToggleButton}
                             pressed={this.props.pressed} />

            {this.props.pressed &&
                <div className={optionsClass}
                     style={{width: this.props.submenuWidth, height: this.props.submenuHeight, marginTop: this.props.thumbnailButtonHeight}}>

                    {this.props.filter && this.props.filter}

                    <div className={"zoom-animation"}>
                        {this.props.images
                            .filter(image => image.thumbnail !== undefined && image.id !== undefined)
                            .map(image => {
                                return <WDToolbarButtonGraphicsImage
                                    id={this.props.id}
                                    key={image.id}
                                    keyValue={image.id!.toString()}
                                    image={image.thumbnail!}
                                    imageWidth={this.props.imageWidth}
                                    imageHeight={this.props.imageHeight}
                                    onClickItem={this.onClickItemAction}
                                />
                        })}
                    </div>
                </div>
            }

        </li>
    }
}
