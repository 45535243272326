import translations from "../../Framework/translations.json";
import {ColumnDefinition} from "../../Components/List/List";
import {MainContext} from "../../_base/MainContext";
import {Resource} from "../../Framework/Util";
import {AdminContentType, AdminObjectType, ColumnRenderOption, EnumToEntityArray, Status} from "../../Framework/Enums";
import React from "react";
import {ListFilterDefinition, ListFilterDefinitionItem} from "../../Components/List/ListFilter";
import Entity from "../../_model/Entity";
import SearchResult from "../../_model/SearchResult";
import SearchResultListItem from "./SearchResultListItem";
import {GetSearchResultsByStatus} from "../../_endpoint/SearchResultEndpoint";
import ApprovalItem from "../../_model/ApprovalItem";
import AdminDashboardList from "../Maintenance/AdminDashboardList";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: SearchResult[]
    listItems: SearchResultListItem[]
    listFilter: ListFilterDefinition[]
}

export default class SearchResultDashboardList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    columnDefinition = [
        new ColumnDefinition("", "url", 7, true, true, ColumnRenderOption.Icon),
        new ColumnDefinition(this.context.translate(translations.fields.search_text), "name", 35, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.type), "type", 18, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_on), "createdOn", 20, false, true, ColumnRenderOption.DateTime),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 20, false, true)
    ]
    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            listItems: [],
            listFilter: []
        }
    }

    componentDidMount() {
        this.fetchData()
        this.setFilter()
    }
    setFilter = () => {
        let filter: ListFilterDefinition[] = []

        let types = [ new ListFilterDefinitionItem(-1, this.context.translate(translations.fields.filter.all_types))]
        for (const item of Object.values(AdminContentType)) {
            const i = Object.values(AdminContentType).indexOf(item);
            types.push( new ListFilterDefinitionItem(i, this.context.translate(translations.enum.admin_content_type[item])))
        }
        filter.push(new ListFilterDefinition("type", types, this.onFilterType, -1))

        this.setState({listFilter: filter})
    }
    onFilterType = (items: Entity[], value: number) => {
        let array = EnumToEntityArray(AdminContentType, translations.enum.admin_content_type, this.context.translate)
        let type = array.find(s => s.id === value)

        if (type) {
            return items.filter(item => item["type"] === type!.name)
        }

        return items
    }

    fetchData = () => {
        this.setState({ items: [] })

        GetSearchResultsByStatus(Status.draft).then(
            (itemData) => {
                const listItems = itemData
                    .filter(item => item.resultAmount === 0)
                    .map(item => {
                        let type: Resource | null = null
                        let config

                        let status = translations.enum.search_result_status[item.status]

                        if (item.type) {
                            type = translations.enum.admin_object_type[item.type]
                            config = ApprovalItem.getItemConfig(item)
                        }

                        let owner = ""
                        if (item.ownerId) {
                            owner = item.ownerId.firstname + " " + item.ownerId.lastname
                        }

                        return new SearchResultListItem(
                            item.id!,
                            item.name,
                            this.context.translate(status),
                            config ? config.url : "",
                            type ? this.context.translate(type) : "",
                            item.createdOn!,
                            owner
                        )
                })

                this.setState({ items: itemData, listItems: listItems })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    render() {
        return <AdminDashboardList title={this.context.translate(translations.text.search_no_result)}
                                   redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.search_result) + "{0}"}
                                   columnDefinition={this.columnDefinition}
                                   objectType={AdminObjectType.search_result}
                                   listFilter={this.state.listFilter}
                                   listItems={this.state.listItems}
                                   sortOptions={{
                                       allowSort: true,
                                       sortField: "createdOn",
                                       sortDescending: true
                                   }}
                                   history={this.props.history}
                                   location={this.props.location}
                                   match={this.props.match}
        />
    }
}

