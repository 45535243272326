import React from "react";
import {MainContext} from "../../../_base/MainContext";
import {SidebarFilterBase} from "../SidebarFilterBase";
import {SidebarFilterButtonData} from "../SidebarFilterButtonData";
import translations from "../../../Framework/translations.json";
import {WDUtils} from "../../Utils/WDUtils";
import {Coords} from "../../../Framework/Coords";
import {WorksheetPage} from "../../../_model/WorksheetPage";
import {TooltipPosition} from "../../../Components/Tooltips";
import {TutorialStepData} from "../../Tutorial/TutorialData";
import {TutorialArrow, TutorialContainer} from "../../Tutorial/TutorialContainer";
import {PagePreview} from "./PagePreview";

class PagePreviewData {
    key: string
    selected: boolean
    clicked: boolean
    dragging: boolean

    constructor(key: string, clicked: boolean, selected: boolean, dragging: boolean) {
        this.key = key
        this.selected = selected
        this.clicked = clicked
        this.dragging = dragging
    }
}

interface IProps {
    pages: WorksheetPage[]
    currentPageIndex: number

    onPageAdd: (page: WorksheetPage, amount: number) => void
    onPageDelete: (pages: WorksheetPage[]) => void
    onPageCopy: (pages: WorksheetPage[]) => void
    onPageMove: (pages: WorksheetPage[], pageBefore: WorksheetPage) => void
    onPageSolution: (pages: WorksheetPage[]) => void
    onChangePageOrientation: (pages: WorksheetPage[], orientation: boolean) => void

    isEditingAllowed: boolean
}
interface IState {
    data: PagePreviewData[]

    // Coords of click of last selected page
    selectPos: Coords

    // Key of the page the dragged item will be pasted before
    successorKey: string | undefined
}

export class SidebarPageManager extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    selectCoords: Coords = new Coords(0, 0)

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            data: [],
            selectPos: new Coords(0, 0),
            successorKey: undefined
        }
    }

    componentDidMount() {
        // initialize tutorials
        this.context.initTutorial("page-manager", [
            new TutorialStepData(0, true),
            new TutorialStepData(1, true),
            new TutorialStepData(2, true)
        ])
    }

    getPages = () => {
        return this.props.pages
            .filter(p => !p.deleted)
            .sort((a, b) => a.sort - b.sort)
    }
    getSelectedPages = () => {
        const selectedKeys = this.state.data.filter(d => d.selected).map(d => d.key)
        return this.props.pages.filter(p => selectedKeys.includes(WorksheetPage.getUniqueElementIdentifier(p)))
    }
    getDraggedPages = () => {
        const selectedKeys = this.state.data.filter(d => d.dragging).map(d => d.key)
        return this.props.pages.filter(p => selectedKeys.includes(WorksheetPage.getUniqueElementIdentifier(p)))
    }
    getSelectedPagesOrientation = (): boolean => {
        let pages = this.getSelectedPages()
        if (pages.length === 0) {
            return false
        }

        return pages.map(p => p.orientation).reduce((previousValue, currentValue) => previousValue && currentValue)
    }

    onPageAdd = () => {
        const selectedKeys = this.state.data.filter(d => d.selected).map(d => d.key)
        const selectedPageCount = selectedKeys.length
        this.context.log.info("Number of selected items = " + selectedPageCount)
        const selectedPages = this.props.pages.filter(p => selectedKeys.includes(WorksheetPage.getUniqueElementIdentifier(p)))

        if (selectedPages.length > 0) {

            let lastSelectedPage = selectedPages.reduce((previousValue, currentValue) => {
                return previousValue.sort > currentValue.sort ? previousValue : currentValue
            })
            this.context.log.info("Last selected page = " + lastSelectedPage.key)

            this.props.onPageAdd(lastSelectedPage, selectedPageCount)
        } else {
            const pages = this.props.pages.sort(((a, b) => (a.sort - b.sort)))
            this.props.onPageAdd(pages[pages.length - 1], 1)
        }
        this.context.log.flush()
    }
    onPageDelete = () => {
        this.props.onPageDelete(this.getSelectedPages())
    }
    onPageCopy = () => {
        const selectedKeys = this.state.data.filter(d => d.selected).map(d => d.key)
        const selectedPages = this.props.pages.filter(p => selectedKeys.includes(WorksheetPage.getUniqueElementIdentifier(p)))

        this.props.onPageCopy(selectedPages)
    }
    onChangePageOrientation = (orientation: boolean) => {
        this.props.onChangePageOrientation(this.getSelectedPages(), orientation)
    }
    onChangeSolution = () => {
        this.props.onPageSolution(this.getSelectedPages())
    }

    onMouseDown = (e: React.MouseEvent, key: string) => {
        e.preventDefault()

        let data = this.state.data
        let item = this.state.data.find(i => i.key === key)
        let selectPos = this.state.selectPos

        if (item === undefined || item === null) {
            item = new PagePreviewData(key, true, false, false)
        }

        if (item) {
            item.clicked = true
            if (this.state.data.find(i => i.key === item!.key) === undefined) {
                data.push(item)
            }

            let element = document.getElementById("page-preview-" + item.key)
            if (element) {
                let rect = element.getBoundingClientRect()
                selectPos = new Coords(rect.left, rect.top)
            }

            this.selectCoords = new Coords(e.pageX, e.pageY)
        }

        // Register mouse events
        if (this.props.isEditingAllowed) {
            window.addEventListener("mousemove", this.onMouseMove)
        }
        window.addEventListener("mouseup", this.onMouseUp)

        this.context.log.flush()
        this.setState({data: data, selectPos: selectPos})
    }
    onMouseMove = (e: MouseEvent) => {
        if (e.pageX >= this.selectCoords.x + 3 || e.pageX <= this.selectCoords.x - 3 ||
            e.pageY >= this.selectCoords.y + 3 || e.pageY <= this.selectCoords.y - 3) {

            let position = new Coords(
                e.pageX, e.pageY
                // this.state.selectPos.x + e.pageX - this.selectCoords.x,
                // this.state.selectPos.y + e.pageY - this.selectCoords.y
            )

            let data = this.state.data
            let selectedElements = data.filter(i => i.clicked && !i.selected)
            if (selectedElements.length === 0) {
                selectedElements = data.filter(i => i.selected)
            }

            // Selected elements are marked as "dragging" and drag image is created
            selectedElements = selectedElements.filter(i => !i.dragging)
            if (selectedElements.length > 0) {
                selectedElements.forEach(i => {
                    i.dragging = true
                    i.selected = true
                })
                data.filter(i => !i.dragging && i.selected).forEach(i => i.selected = false)

                this.setState({data: data})
            }

            let pages = this.getPages()
            let pageKey: string | undefined = undefined
            for (let i = 0; i < pages.length && pageKey === undefined; i++) {
                let key = WorksheetPage.getUniqueElementIdentifier(pages[i])
                let element = document.getElementById("page-preview-" + key)
                if (element) {
                    let rect = element.getBoundingClientRect()

                    if ((rect.left + rect.width) > position.x && rect.top <= position.y && (rect.top + rect.height) >= position.y) {
                        pageKey = key
                    }
                }
            }

            if (pageKey !== undefined && pageKey !== this.state.successorKey) {
                this.setState({successorKey: pageKey})
            }
        }
    }
    onMouseUp = (e: MouseEvent) => {
        let data = this.state.data
        let reset = !e.ctrlKey

        window.removeEventListener("mousemove", this.onMouseMove)
        window.removeEventListener("mouseup", this.onMouseUp)

        let dragPages = data.filter(d => d.dragging)
        let clicked = data.filter(i => i.clicked)
        let selected: PagePreviewData | undefined = undefined

        if (reset && dragPages.length === 0) {
            data = []
            if (clicked.length > 0) {
                selected = clicked[0]
                data.push(new PagePreviewData(selected.key, false, !selected.selected, false))
            }
        }

        data.filter(i => i.clicked).forEach(i => {
            i.clicked = false
            i.selected = !i.selected
        })

        let pageBefore = this.props.pages.find(p => WorksheetPage.getUniqueElementIdentifier(p) === this.state.successorKey)
        if (pageBefore && dragPages.length > 0) {
            this.props.onPageMove(this.getDraggedPages(), pageBefore)
        }

        // Stop dragging mode of elements
        data.filter(d => d.dragging).forEach(d => d.dragging = false)

        // Remove drag image
        let element = document.getElementById("page-drag-image")
        element?.remove()

        this.setState({data: data, successorKey: undefined})
    }
    onDblClick = (e: React.MouseEvent, key: string) => {
        // Scroll to the selected page
        WDUtils.scrollToPage(key, 0, this.context.log.info)
    }

    isSelected = (key: string) => {
        let item = this.state.data.find(i => i.key === key)
        if (item) {
            return item.selected
        }

        return false
    }
    isDragging = (key: string) => {
        return this.state.data.find(d => d.key === key && d.dragging) !== undefined
    }

    renderTutorial = () => {
        let tutorial = this.context.getTutorial("page-manager")

        if (tutorial && tutorial.currentStep === 0) {
            return <TutorialContainer id={"page-manager"}
                                      arrow={TutorialArrow.topLeft}
                                      data={tutorial}
                                      translateX={-110} translateY={160}
            />
        }
        else if (tutorial && tutorial.currentStep === 1) {
            return <TutorialContainer id={"page-manager"}
                                      arrow={TutorialArrow.topRight}
                                      data={tutorial}
                                      translateX={-22} translateY={160}
            />
        }
        else if (tutorial && tutorial.currentStep === 2) {
            return <TutorialContainer id={"page-manager"}
                                      data={tutorial}
                                      translateX={75} translateY={300}
            />
        }

        return <></>

    }
    render() {
        let selectedPages = this.state.data.filter(d => d.selected)
        let draggedPages = this.state.data.filter(d => d.dragging)
        let orientation = this.getSelectedPagesOrientation()

        let pages = this.getPages()
        // let lastPageOrientation = false

        let disableDelete = !this.props.isEditingAllowed || selectedPages.length === 0 || selectedPages.length === this.props.pages.length
        let disableCopy = !this.props.isEditingAllowed || selectedPages.length === 0
        let disableAdd = !this.props.isEditingAllowed
        let disableChangeOrientation = !this.props.isEditingAllowed || selectedPages.length === 0
        let disableSolution = !this.props.isEditingAllowed || selectedPages.length === 0

        let overviewClass = "ws-designer-sidebar-page-overview"
        if (draggedPages.length > 1) {
            overviewClass += " ws-designer-sidebar-dragging-3"
        } else if (draggedPages.length > 0) {
            overviewClass += " ws-designer-sidebar-dragging-1"
        }

        return <div className={"ws-designer-sidebar-container"}>

            {/* Tutorial */}
            {this.renderTutorial()}

            {/* Action buttons */}
            <SidebarFilterBase
                buttons={[
                    new SidebarFilterButtonData(1,
                        this.context.translate(translations.command.page_add),
                        this.context.translate(translations.tooltip.page_add),
                        "page_add.svg",
                        disableAdd,
                        this.onPageAdd,
                        TooltipPosition.belowLeft, 0, 0),
                    new SidebarFilterButtonData(2,
                        this.context.translate(translations.command.page_delete),
                        this.context.translate(translations.tooltip.page_delete),
                        "page_delete.svg",
                        disableDelete,
                        this.onPageDelete,
                        TooltipPosition.belowLeft, 0, 0),
                    new SidebarFilterButtonData(3,
                        this.context.translate(translations.command.page_copy),
                        this.context.translate(translations.tooltip.page_copy),
                        "page_copy.svg",
                        disableCopy,
                        this.onPageCopy,
                        TooltipPosition.belowLeft, 0, 0),
                    new SidebarFilterButtonData(4,
                        this.context.translate(translations.command.change_orientation),
                        this.context.translate(translations.tooltip.change_orientation),
                        orientation ? "switch_to_landscape_format.svg" : "switch_to_portrait_format.svg",
                        disableChangeOrientation,
                        () => this.onChangePageOrientation(!orientation),
                        TooltipPosition.belowLeft, 0, 0),
                    new SidebarFilterButtonData(5,
                        this.context.translate(translations.toolbar.mark_solution_sheet),
                        this.context.translate(translations.tooltip.mark_solution_sheet),
                        "page_solution_mark.svg",
                        disableSolution,
                        this.onChangeSolution,
                        TooltipPosition.belowLeft, 0, 0)
                ]}
            />

            {/* Pages */}
            <div id={"ws-designer-sidebar-page-manager-content"}
                 className={"ws-designer-sidebar-content"}
            >
                <div className={overviewClass}>
                    {pages
                        .filter(p => !p.deleted)
                        .map((p, i) => {
                        const key = WorksheetPage.getUniqueElementIdentifier(p)

                        let className = "ws-designer-sidebar-page-item"
                        if (draggedPages.length > 1) {
                            className += " ws-designer-sidebar-dragging-3"
                        } else if (draggedPages.length > 0) {
                            className += " ws-designer-sidebar-dragging-1"
                        }

                        if (this.isSelected(key)) {
                            className += " ws-designer-sidebar-page-item-selected"
                        }
                        if (this.isDragging(key)) {
                            className += " ws-designer-sidebar-page-item-dragging"
                        }
                        if (this.state.successorKey === key) {
                            className += " ws-designer-sidebar-page-item-drop-before"
                        }
                        if (i === this.props.currentPageIndex) {
                            className += " ws-designer-sidebar-page-item-current"
                        }

                        return <PagePreview key={key}
                                            page={p}
                                            pageIndex={i}
                                            className={className}
                                            onMouseDown={this.onMouseDown}
                                            onDblClick={this.onDblClick}
                        />
                    })}
                </div>
            </div>
        </div>
    }
}
