import Entity from "../../_model/Entity";
import {AdminObjectType} from "../../Framework/Enums";

export default class ApprovalListItem extends Entity {
    type: string
    area: string
    url?: string
    createdBy: string
    modifiedOn: Date
    itemType: AdminObjectType

    constructor(id: number, name: string, type: string, area: string, url: string, modifiedOn: Date, createdBy: string, itemType: AdminObjectType) {
        super(name, id);

        this.type = type
        this.area = area
        this.url = url
        this.modifiedOn = modifiedOn
        this.createdBy = createdBy
        this.itemType = itemType
    }
}
