import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";
import {SearchInput} from "../../../../Components/Controls/SearchInput";
import Rule from "../../../../_model/Rule";
import {GetFilteredRuleList} from "../../../../_endpoint/RuleEndpoint";
import {RuleFilter} from "../../../../_model/RuleFilter";
import RuleCategory from "../../../../_model/RuleCategory";
import {WorksheetItemType} from "../../../../_model/WorksheetItemType";

/**
 * Button (with submenu) in Toolbar
 * Effects selected element(s)
 * Shows matching rule section for the selected element
 */

interface IProps {
    id: number
    clickable: boolean
    pressed: boolean
    width: number
    height: number
    ruleCategory: RuleCategory
    worksheetItemType?: WorksheetItemType

    onAddRule: (ruleId: number) => void
    onToggleButton: (id: number) => void
}

interface IState {
    search: string

    rules?: Rule[]
}

export class WDToolbarButtonRules extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            search: ""
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (!prevProps.pressed && this.props.pressed && this.state.rules === undefined) {
            this.fetchData()
        }
    }

    onSearch = (value: string) => {
        this.setState({search: value}, this.fetchData)
    }
    onSelectItem = (ruleId: number) => {
        this.props.onAddRule(ruleId)
        this.props.onToggleButton(-1)
    }

    fetchData = () => {
        let filter = new RuleFilter()
        filter.search = this.state.search
        filter.ruleCategory = this.props.ruleCategory
        if (this.props.worksheetItemType) {
            filter.worksheetItemType = this.props.worksheetItemType
        }

        GetFilteredRuleList(filter).then((rules) => {
            this.setState({rules: rules})
        }, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })
    }

    isRule = () => {
        return this.props.ruleCategory.id === RuleCategory.RULE
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-rules"}
            className={"ws-designer-toolbar-item"}
            id={"addRule"}>

            <WDToolbarButton key={"btnAddRule"}
                             id={this.props.id}
                             tooltip={new TooltipText(
                                 this.isRule() ? this.context.translate(translations.toolbar.rules) : this.context.translate(translations.toolbar.task_description),
                                 this.isRule() ? this.context.translate(translations.tooltip.rules) : this.context.translate(translations.tooltip.task_description))}
                             icon={ImagePath.getButtonUrl() + (this.isRule() ? "rules.svg" : "task_description.svg")}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             onToggleButton={this.props.onToggleButton}
                             arrow={true}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed}>
            </WDToolbarButton>

            {/*<WDToolbarButton id={this.props.id}*/}
            {/*                 enabled={this.props.clickable}*/}
            {/*                 icon={ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}*/}
            {/*                 tooltip={new TooltipText(this.context.translate(translations.toolbar.replace_image), this.context.translate(translations.tooltip.replace_image))}*/}

            {/*                 iconClassSelected={"ws-designer-toolbar-button-options"}*/}
            {/*                 iconClassUnselected={"ws-designer-toolbar-button-options"}*/}

            {/*                 arrow={false}*/}
            {/*                 cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}*/}
            {/*                 buttonHeight={32}*/}
            {/*                 buttonWidth={10}*/}
            {/*                 imageHeight={5}*/}
            {/*                 imageWidth={5}*/}

            {/*                 onToggleButton={this.props.onToggleButton}*/}
            {/*                 pressed={this.props.pressed} />*/}

            {this.props.pressed &&
                <div className={"ws-designer-toolbar-options"}>
                    <div className={"ws-designer-options-section-column"}
                         style={{
                             width: this.props.width,
                             height: this.props.height
                         }}>

                        <SearchInput width={250}
                                     onSearch={this.onSearch}
                                     readonly={false}
                                     typeAheadSearch={false}
                                     showFieldOptions={false}
                                     maxLength={50}
                        />

                        {this.state && this.state.rules &&
                            <div className={"ws-designer-toolbar-search-result-list"}>
                                {this.state.rules.length === 0 &&
                                    <div className={"ws-designer-toolbar-search-no-results"}>{this.context.translate(translations.text.no_result)}</div>
                                }

                                {this.state.rules.map(r => {
                                    return <div key={r.id} className={"ws-designer-toolbar-search-result-list-item"} onClick={() => this.onSelectItem(r.id!)}>
                                        <div className={"ws-designer-toolbar-search-result-list-item-title"}>{r.name}</div>
                                        <div className={"ws-designer-toolbar-search-result-list-item-description"}>{r.description}</div>
                                    </div>
                                })}
                            </div>
                        }
                    </div>
                </div>
            }
        </li>
    }
}
