import React, {FormEvent} from "react";
import {MainContext} from "../_base/MainContext";
import translations from "../Framework/translations.json";
import SelectBox from "../Components/Controls/SelectBox";
import {EnumToEntityArray, EnumValueToValueNumber, UserSettingsAutoSave,} from "../Framework/Enums";
import {UpdateUserSettings} from "../_endpoint/UserEndpoint";
import {Notification} from "../Components/Notification/NotificationHandler";
import {GetAllLanguages} from "../_endpoint/MetadataEndpoint";
import {UserSettings} from "../_model/UserSettings";
import Entity from "../_model/Entity";
import {SyllableMethod} from "../_model/Dictionary/Word";
import {WDToolbarButtonColor} from "../Designer/Toolbar/Button/General/WDToolbarButtonColor";
import {WDToolbarAction} from "../Designer/Toolbar/WDToolbarAction";
import {ImagePath} from "../Framework/CategoryImage";
import {TooltipPosition, TooltipText} from "../Components/Tooltips";
import {MenuInfo, Modal} from "../Components/Modal";
import {ButtonInfo} from "../Components/Controls/ButtonList";
import CheckBoxSwitch from "../Components/Controls/CheckBoxSwitch";
import {ResetTutorials} from "../_endpoint/TutorialEndpoint";

interface IProps {
    onCancel: () => void
}

interface IState {
    userSettings?: UserSettings
    menuSettings: MenuInfo
    openSection: string
    tabHeight: number
    languages: any[]
    listPageSize: number
    activeButtonOptionId: number
    tutorialsReset: boolean
}

export class Profile extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            languages: [],
            openSection: "menu-setting-general",
            listPageSize: 100,
            activeButtonOptionId: -1,
            tutorialsReset: false,
            tabHeight: this.getTabHeight(),
            menuSettings: new MenuInfo(
                [{id: "menu-setting-general", value: "General"}],
                "menu-setting-general",
                this.onChangeSettingSection
            )
        }
    }

    componentDidMount() {
        this.context.getUserSettings().then((settings) => {
            this.setState({userSettings: {...settings}})
        })

        this.fetchLanguages()

        this.setState({
            menuSettings: new MenuInfo(
                [{
                    id: "menu-setting-general",
                    value: this.context.translate(translations.text.worksheet_settings.general)
                }],
                "menu-setting-general",
                this.onChangeSettingSection)
        })

        window.addEventListener("resize", this.onResizeWindow)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResizeWindow)
    }

    fetchLanguages = () => {
        GetAllLanguages().then(
            (data) => {
                this.setState({languages: data})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    getTabHeight = () => {
        return window.innerHeight * 0.7 - 120
    }

    onChangeSettingSection = (id: string) => {
        let newModalSettings = this.state.menuSettings
        newModalSettings.selectedId = id
        this.setState({openSection: id, menuSettings: newModalSettings})
    }

    onChangeEnumSelection = (value: number, enumClass: any, propertyName: string) => {
        // Get enum value by index (value)
        let autoSave = Object.values(enumClass).find((e, i) => i === value)
        if (autoSave) {
            let currentItem = this.state.userSettings
            if (currentItem) {
                currentItem[propertyName] = autoSave
                this.setState({userSettings: currentItem})
            }
        }
    }
    onChangeSelection = (value: number, propertyName: string) => {
        let currentItem = this.state.userSettings

        if (currentItem) {
            currentItem[propertyName] = value
            this.setState({userSettings: currentItem, activeButtonOptionId: -1})
        }
    }
    toggleButtonOption = (id: number) => {
        this.setState({activeButtonOptionId: id})
    }
    onChangeSyllableColor = (value: any, propertyName: string) => {
        let currentItem = this.state.userSettings

        if (currentItem) {
            currentItem[propertyName] = value.color
            this.setState({userSettings: currentItem, activeButtonOptionId: -1})
        }
    }
    onChangeAutoSave = () => {
        let currentItem = this.state.userSettings
        if (currentItem) {
            currentItem.autoSave = !currentItem.autoSave
            this.setState({userSettings: currentItem})
        }
    }

    onResetTutorials = () => {
        ResetTutorials().then(
            () => {
                this.context.loadTutorials()
                this.setState({tutorialsReset: true})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    onResizeWindow = () => {
        this.setState({tabHeight: this.getTabHeight()})
    }
    onFormSubmit = (event: FormEvent) => {
        event.preventDefault();

        // Update user settings, show notification and navigate back to previous page
        if (this.state.userSettings) {
            this.context.updateUserSettings(this.state.userSettings)

            UpdateUserSettings(this.state.userSettings).then(
                () => {
                    this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

                    this.props.onCancel()
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
                }
            )
        }
    }
    onFormCancel = () => {
        this.props.onCancel()
    }

    render() {
        let tabHeight = this.getTabHeight()

        return <Modal id={"profile"}
                      onFormSubmit={this.onFormSubmit}
                      onFormCancel={this.onFormCancel}
                      title={this.context.translate(translations.text.profile)}
                      buttons={[
                          new ButtonInfo("btnCancelProfile", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onFormCancel, {}),
                          new ButtonInfo("btnSaveProfile", "button button-save", "submit", true, false, this.context.translate(translations.command.save), undefined, {marginLeft: "10px"})
                      ]}
                      menu={this.state.menuSettings}
                      dialogStyle={{width: "70%", height: "70%"}}
                      contentAlignment={"flex-start"}
        >
            {/* Tab: General */}
            {this.state.openSection === "menu-setting-general" &&
                <div className={"modal-profile-tab"} style={{height: tabHeight}}>
                    <div className="form-setting">
                        <div className="form-row">
                            <label
                                className={"bold-label"}>{this.context.translate(translations.fields.user_settings.saving_options)}</label>
                        </div>
                        <div className="form-row">
                            <CheckBoxSwitch id={"selAutoSave"}
                                            width={400}
                                            label={this.context.translate(translations.fields.user_settings.auto_save)}
                                            checked={this.state.userSettings === undefined ? true : this.state.userSettings.autoSave}
                                            readonly={false}
                                            onChange={this.onChangeAutoSave}
                            />
                        </div>
                        <div className="form-row">
                            <SelectBox id={"selAutoSaveOnClose"}
                                       width={400}
                                       label={this.context.translate(translations.fields.user_settings.auto_save_on_close)}
                                       labelClass={"label " + (this.state.userSettings?.autoSave === true ? "label-disabled" : "")}
                                       data={EnumToEntityArray(UserSettingsAutoSave, translations.enum.user_settings_auto_save, this.context.translate)}
                                       required={false}
                                       value={EnumValueToValueNumber(UserSettingsAutoSave, this.state.userSettings?.autoSaveOnClose)}
                                       readonly={this.state.userSettings?.autoSave === true}
                                       onChange={(value) => this.onChangeEnumSelection(value, UserSettingsAutoSave, "autoSaveOnClose")}
                                       positioningRow={false}
                            />
                        </div>
                    </div>
                    {/*<div className="form-setting">*/}
                    {/*    <div className="form-row">*/}
                    {/*        <SelectBox id={"selLanguage"}*/}
                    {/*                   width={400}*/}
                    {/*                   label={this.context.translate(translations.fields.language)}*/}
                    {/*                   data={this.state.languages}*/}
                    {/*                   required={false}*/}
                    {/*                   value={this.state.userSettings?.languageId}*/}
                    {/*                   readonly={false}*/}
                    {/*                   onChange={(value) => this.onChangeSelection(value, "languageId")}*/}
                    {/*                   positioningRow={false}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="form-setting">
                        <div className="form-row">
                            <SelectBox id={"selSyllable"}
                                       width={400}
                                       label={this.context.translate(translations.fields.word.syllable)}
                                       data={EnumToEntityArray(SyllableMethod, translations.enum.syllable, this.context.translate)}
                                       required={false}
                                       value={EnumValueToValueNumber(SyllableMethod, this.state.userSettings?.syllable)}
                                       readonly={false}
                                       onChange={(value) => this.onChangeEnumSelection(value, SyllableMethod, "syllable")}
                                       positioningRow={false}
                            />
                        </div>
                        <div className={"form-row"}>
                            <div className={"text"} style={{marginRight: "5px", alignSelf: "center"}}>
                                {this.context.translate(translations.text.syllable_color_1) + ":"}
                            </div>

                            <WDToolbarButtonColor
                                id={1}
                                clickable={true}
                                command={"foreColor"}
                                options={{
                                    action: WDToolbarAction.FONT_COLOR,
                                    open: this.state.activeButtonOptionId === 1,
                                    width: 70,
                                    height: 150
                                }}
                                tooltipPosition={TooltipPosition.above}
                                showColor={true}
                                transparency={false}
                                pressed={false}
                                initialColor={this.state.userSettings?.syllableColor1 || "#6d80bf"}
                                action={WDToolbarAction.FONT_COLOR}
                                icon={ImagePath.getButtonUrl() + "font_color.svg"}
                                iconClass={"svg-dark-gray"}
                                tooltip={new TooltipText(this.context.translate(translations.toolbar.set_syllable_color), this.context.translate(translations.tooltip.set_syllable_color_1))}
                                onActionButton={(action, data) => this.onChangeSyllableColor(data, "syllableColor1")}
                                onToggleButton={() => this.toggleButtonOption(1)}/>

                            <div className={"text"}
                                 style={{marginRight: "5px", marginLeft: "40px", alignSelf: "center"}}>
                                {this.context.translate(translations.text.syllable_color_2) + ":"}
                            </div>
                            <WDToolbarButtonColor
                                id={2}
                                clickable={true}
                                command={"foreColor"}
                                options={{
                                    action: WDToolbarAction.FONT_COLOR,
                                    open: this.state.activeButtonOptionId === 2,
                                    width: 70,
                                    height: 150
                                }}
                                tooltipPosition={TooltipPosition.above}
                                showColor={true}
                                transparency={false}
                                pressed={false}
                                initialColor={this.state.userSettings?.syllableColor2 || "#D8394C"}
                                action={WDToolbarAction.FONT_COLOR}
                                icon={ImagePath.getButtonUrl() + "font_color.svg"}
                                iconClass={"svg-dark-gray"}
                                tooltip={new TooltipText(this.context.translate(translations.toolbar.set_syllable_color), this.context.translate(translations.tooltip.set_syllable_color_2))}
                                onActionButton={(action, data) => this.onChangeSyllableColor(data, "syllableColor2")}
                                onToggleButton={() => this.toggleButtonOption(2)}/>
                        </div>
                    </div>
                    <div className="form-setting">
                        <div className="form-row">
                            <SelectBox id={"selListPageSize"}
                                       width={400}
                                       label={this.context.translate(translations.fields.user_settings.listPageSize)}
                                       data={[
                                           new Entity("10", 10),
                                           new Entity("20", 20),
                                           new Entity("50", 50),
                                           new Entity("100", 100),
                                           new Entity("250", 250),
                                           new Entity("500", 500)
                                       ]}
                                       required={false}
                                       value={this.state.userSettings && this.state.userSettings?.listPageSize}
                                       readonly={false}
                                       onChange={(value) => this.onChangeSelection(value, "listPageSize")}
                                       positioningRow={false}
                            />
                        </div>
                    </div>

                    <div className="form-setting">
                        <div className="form-row">
                            <label
                                className={"bold-label"}>{this.context.translate(translations.fields.user_settings.tutorials)}</label>
                        </div>
                        <div className="form-row">
                            {this.state.tutorialsReset ?
                                <div
                                    className={"text text-success"}>{this.context.translate(translations.text.tutorials_reset)}</div>
                                :
                                <button id={"button-reset-tutorials"}
                                        className={"button button-save"} type={"button"}
                                        style={{width: "250px"}}
                                        onClick={this.onResetTutorials}>
                                    {this.context.translate(translations.fields.user_settings.reset_tutorials)}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            }
        </Modal>
    }
}
