import {EntityData} from "../Entity";
import {Difficulty} from "../../Framework/Enums";
import WordLookup from "./WordLookup";
import WordHighlight from "./WordHighlight";
import WordTopic from "./WordTopic";

export enum SyllableMethod {
    syllableDivide = "syllableDivide",
    syllableSpeak = "syllableSpeak",
    noSyllable = "noSyllable"
}
export enum WordDefinition {
    noun = "noun",
    verb = "verb",
    article = "article",
    adjective = "adjective",
    pronoun = "pronoun",
    numeral = "numeral",
    adverb = "adverb",
    preposition = "preposition",
    conjunction = "conjunction",
    interjection = "interjection",
    fusions = "fusions",
    properName = "properName"
}
export enum WordDefinitionNoun {
    Nomen = "Nomen",
    Namenwort = "Namenwort",
    Dingwort = "Dingwort",
    Hauptwort = "Hauptwort",
    Substantiv = "Substantiv",
}
export enum WordDefinitionVerb {
    Verb = "Verb",
    Zeitwort = "Zeitwort",
    Tunwort = "Tunwort",
}
export enum WordDefinitionArticle {
    Artikel = "Artikel",
    Begleiter = "Begleiter",
    Geschlechtswort = "Geschlechtswort",
}
export enum WordDefinitionAdjective {
    Adjektiv = "Adjektiv",
    Eigenschaftswort = "Eigenschaftswort",
    Wiewort = "Wiewort",
}
export enum WordDefinitionPronoun {
    Pronomen = "Pronomen",
    Fürwort = "Fürwort"
}
export enum WordDefinitionNumeral {
    Numeral = "Numeral",
    Zahlenwort = "Zahlenwort"
}
export enum WordDefinitionAdverb {
    Adverb = "Adverb",
    Umstandswort = "Umstandswort"
}
export enum WordDefinitionPreposition {
    Präposition = "Präposition",
    Verhältniswort = "Verhältniswort"
}
export enum WordDefinitionConjunction {
    Konjunktion = "Konjunktion",
    Bindewort = "Bindewort"
}
export enum WordDefinitionInterjection {
    Interjektion = "Interjektion",
    Empfindungswort = "Empfindungswort"
}
export enum SearchPosition {
    everywhere = "everywhere",
    start = "start",
    end = "end",
    except_start = "except_start",
    middle = "middle",
    nowhere = "nowhere"
}

export class Word extends EntityData {
    descriptionSimple?: string
    phonemic: boolean = false
    basicVocabulary: boolean = false
    foreignWord: boolean = false
    exceptionWord: boolean = false
    syllableDivide?: boolean
    syllableSpeak?: boolean
    syllabification?: string
    difficulty?: Difficulty
    wordDefinition?: WordDefinition
    showInDictionary: boolean = true

    synonymLinks?: WordLookup[]
    wordLinks?: WordLookup[]
    wordHighlights?: WordHighlight[]
    wordTopics?: WordTopic[]
}
export function getSynonymString(word: Word) : string {
    return word.synonymLinks?.map(w => w.name).join(", ") || ""
}
export function getWordLinkString(word: Word) : string {
    return word.wordLinks?.map(w => w.name).join(", ") || ""
}
export function getSyllableString(word: Word) : string {
    let syllableSpeak = word.syllableSpeak === true ? "S " : ""
    let syllableDivide = word.syllableDivide === true ? "A" : ""

    return syllableSpeak + syllableDivide
}

export default Word
