export class ToolboxElement {
    id: number
    name: string
    parent?: string

    constructor(id: number, name: string, parent?: string) {
        this.id = id
        this.name = name
        this.parent = parent
    }
}
export class ToolboxGroup {
    label: string
    options: ToolboxGroupElements[]

    constructor(label: string) {
        this.label = label
        this.options = []
    }
}
export class ToolboxGroupElements {
    value: string
    label: string

    constructor(value: string, label: string) {
        this.value = value
        this.label = label
    }
}
