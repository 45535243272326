import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Click should start transforming text into syllable divided text
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    onActionButton: (action: WDToolbarAction, data: any) => void
    onToggleButton: (id: number) => void
    onShowConfirmation?: (title: string, description: string, onSubmit: () => void) => void
}

interface IState {
}

export class WDToolbarButtonConvertToSyllableText extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {}
    }

    showModal = () => {
        if (this.props.onShowConfirmation) {
            let title = this.props.pressed ? this.context.translate(translations.text.syllable_to_text_conversion_title) : this.context.translate(translations.text.syllable_from_text_conversion_title)
            this.props.onShowConfirmation(
                title,
                this.context.translate(translations.text.syllable_conversion_description),
                this.onSubmit
            )
        }
        else {
            this.onSubmit()
        }
    }

    onSubmit = () => {
        let valuePressed: any = {"syllableActivated": !this.props.pressed}
        this.props.onActionButton(WDToolbarAction.CONVERT_TO_SYLLABLE, valuePressed)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-convert-to-syllable-text"}
            id={"convert-to-syllable-text"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + "syllable.svg"}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.convert_to_syllable),
                                 this.context.translate(translations.tooltip.convert_to_syllable))}
                             tooltipDisabled={new TooltipText(
                                 this.context.translate(translations.toolbar.convert_to_syllable_disabled),
                                 this.context.translate(translations.tooltip.convert_to_syllable_disabled))}
                             onToggleButton={this.showModal}
                             arrow={false}
                             iconClassSelected={"svg-color-original"}
                             iconClassUnselected={"svg-color-original"}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed} />
        </li>
    }
}
