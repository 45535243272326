export enum ArticleDefiniteEnum {
    der = "der",
    die = "die",
    das = "das"
}
export enum ArticleIndefiniteEnum {
    ein = "ein",
    eine = "eine",
    eines = "eines"
}
export enum CharacteristicEnum {
    creature = "creature",
    plant = "plant",
    substance = "substance",
    thing = "thing",
    abstractThings = "abstractThings"
}
export enum GenderEnum {
    male = "male",
    female = "female",
    neuter = "neuter"
}
export enum CaseFirstEnum {
    der = "der",
    die = "die",
    das = "das"
}
export enum CaseFirstIndefiniteEnum {
    ein = "ein",
    eine = "eine"
}
export enum CaseSecondEnum {
    des = "des",
    der = "der"
}
export enum CaseSecondIndefiniteEnum {
    eines = "eines",
    einer = "einer"
}
export enum CaseThirdEnum {
    dem = "dem",
    der = "der"
}
export enum CaseThirdIndefiniteEnum {
    einem = "einem",
    einer = "einer"
}
export enum CaseFourthEnum {
    den = "den",
    die = "die",
    das = "das"
}
export enum CaseFourthIndefiniteEnum {
    einen = "einen",
    eine = "eine",
    ein = "ein"
}

export class Noun {
    wordId?: number
    syllabificationSingular?: string
    syllabificationPlural?: string
    articleDefinite?: ArticleDefiniteEnum
    articleIndefinite?: ArticleIndefiniteEnum
    characteristic?: CharacteristicEnum
    gender?: GenderEnum
    caseFirst?: CaseFirstEnum
    caseFirstIndefinite?: CaseFirstIndefiniteEnum
    caseFirstValue?: string
    caseSecond?: CaseSecondEnum
    caseSecondIndefinite?: CaseSecondIndefiniteEnum
    caseSecondValue?: string
    caseThird?: CaseThirdEnum
    caseThirdIndefinite?: CaseThirdIndefiniteEnum
    caseThirdValue?: string
    caseFourth?: CaseFourthEnum
    caseFourthIndefinite?: CaseFourthIndefiniteEnum
    caseFourthValue?: string
    caseFirstValuePL?: string
    caseSecondValuePL?: string
    caseThirdValuePL?: string
    caseFourthValuePL?: string
    caseFirstPossessiveValue?: string
    caseFirstPossessiveValuePL?: string
    caseSecondPossessiveValue?: string
    caseSecondPossessiveValuePL?: string
    caseThirdPossessiveValue?: string
    caseThirdPossessiveValuePL?: string
    caseFourthPossessiveValue?: string
    caseFourthPossessiveValuePL?: string

    toString = () : string => {
        return "- Nomen; " + this.gender + "; " + this.caseFirstValuePL + ";"
    }
}

export default Noun
