import React from "react";
import '../../../assets/css/layout.min.css';
import Const from "../../../Framework/Const";
import {WorksheetPage} from "../../../_model/WorksheetPage";
import {MainContext} from "../../../_base/MainContext";
import {WDUtils} from "../../Utils/WDUtils";
import {WSPageFormat} from "../../../Framework/Enums";
import _ from "lodash";

interface IProps {
    worksheetPage: WorksheetPage
    orientation: boolean
    width: number

    showNonPrintableObjects: boolean
}

interface IState {

}

export class WDSheetPageBorderPreview extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState) && _.isEqual(this.props.worksheetPage, nextProps.worksheetPage))
    }

    render() {
        const width = WDUtils.getWorksheetPageWidth(WSPageFormat.A4, this.props.orientation)
        const height = WDUtils.getWorksheetPageHeight(WSPageFormat.A4, this.props.orientation)

        let pageRatio = height / width
        let previewRatio = this.props.width / width

        let borderOpacity = this.props.worksheetPage.borderTransparency ? 1 - this.props.worksheetPage.borderTransparency / 100 : undefined

        return <div id={"sheet-preview-page-border"} className={"ws-designer-sheet-page-border-preview"}
                     style={{width: this.props.width, height: this.props.width * pageRatio}}
        >
            {/* Show red border - just for positioning */}
            {!this.props.worksheetPage.borderImage && this.props.showNonPrintableObjects &&
                (this.props.worksheetPage.borderStyle === undefined || this.props.worksheetPage.borderStyle === "none") &&

                <div id="ws-designer-sheet-page-border" className="ws-designer-sheet-page-border"
                     style={{
                         left: Const.WorksheetPageBorderPX * previewRatio,
                         right: Const.WorksheetPageBorderPX * previewRatio,
                         top: Const.WorksheetPageBorderPX * previewRatio,
                         bottom: Const.WorksheetPageBorderPX * previewRatio,
                         borderStyle: "dotted"
                     }}
                />
            }

            {/* Show default border - just for positioning */}
            {!this.props.worksheetPage.borderImage && this.props.showNonPrintableObjects &&
                (this.props.worksheetPage.borderStyle === undefined || this.props.worksheetPage.borderStyle === "none") &&
                <div id="ws-designer-sheet-design-border" className="ws-designer-sheet-design-border"
                     style={{
                         left: (this.props.worksheetPage.borderLeft * previewRatio) + "px",
                         right: (this.props.worksheetPage.borderRight * previewRatio) + "px",
                         top: (this.props.worksheetPage.borderTop * previewRatio) + "px",
                         bottom: (this.props.worksheetPage.borderBottom * previewRatio) + "px",
                         borderStyle: "dashed"
                     }}>
                </div>
            }

            {/* Show user border */}
            {!this.props.worksheetPage.borderImage && this.props.worksheetPage.borderStyle !== undefined && this.props.worksheetPage.borderStyle !== "transparent" &&
                <div id="ws-designer-sheet-design-border" className="ws-designer-sheet-design-border"
                     style={{
                         left: (this.props.worksheetPage.borderLeft * previewRatio) + "px",
                         right: (this.props.worksheetPage.borderRight * previewRatio) + "px",
                         top: (this.props.worksheetPage.borderTop * previewRatio) + "px",
                         bottom: (this.props.worksheetPage.borderBottom * previewRatio) + "px",
                         borderStyle: this.props.worksheetPage.borderStyle,
                         borderColor: this.props.worksheetPage.borderColor,
                         borderWidth: this.props.worksheetPage.borderWeight ? this.props.worksheetPage.borderWeight * previewRatio : undefined,
                         borderRadius: this.props.worksheetPage.borderRadius + "%",
                         opacity: borderOpacity
                     }}>
                </div>
            }
        </div>
    }
}
