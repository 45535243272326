import WritingCourse, {WritingCourseCharacterTypeEnum} from "./WritingCourse";
import {LessonSubject} from "./LessonSubject";
import {WDWritingLineatureSize} from "../Designer/Elements/Lineature/WritingLineature/WDWritingLineature";
import {WDMathLineatureSize} from "../Designer/Elements/Lineature/MathLineature/WDMathLineatureData";
import {Difficulty} from "../Framework/Enums";

export class WritingCourseFilter {
    search?: string
    parentWritingCourse?: WritingCourse
    difficulty?: Difficulty
    lessonSubject?: LessonSubject
    characterType?: WritingCourseCharacterTypeEnum
    startNumber?: boolean
    sequenceNumber?: boolean
    traceArrow?: boolean
    syllableMethod?: boolean
    previewSymbol?: boolean
    previewSymbolTrace?: boolean
    previewSymbolImage?: boolean
    upperLowerLetterMixed?: boolean
    writingLineatureSize?: WDWritingLineatureSize
    mathLineatureSize?: WDMathLineatureSize
    color?: string
}
