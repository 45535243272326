import React from 'react';
import './index.css';
import './assets/fonts/OpenSans-Regular.ttf'
import './assets/fonts/OpenSans-Bold.ttf'
import './assets/fonts/OpenSans-Italic.ttf'
import './assets/fonts/OpenSans-BoldItalic.ttf'
import AppStart from './_base/AppStart';
import {createRoot} from "react-dom/client";

let root = document.getElementById('root')
if (root) {
    createRoot(root).render(
        <AppStart/>
    );
}

// Load specific css classes for browser
// let userAgent = navigator.userAgent;

// let b = "";
// if(userAgent.indexOf("Firefox") > -1){
//     b = "firefox";
// }
// if(userAgent.indexOf("Chrome") > -1){
//     b = "chrome";
// }
//
// let link = document.createElement('link');
// link.rel = "stylesheet";
// link.type = "text/css";
// link.media = "screen";
// link.href = "/css/options." + b + ".css";
//
// let head = document.head || document.getElementsByTagName('head')[0]
// head.appendChild(link);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
