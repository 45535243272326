import API from "../Framework/API";
import Note from "../_model/Note";
import {AdminObjectType} from "../Framework/Enums";

const URL = "notes"

export function GetNoteList(objectId: number, objectType: AdminObjectType) {
    let url = URL + "/?objectid=" + objectId + "&objecttype=" + objectType
    return API.Get<Note[]>(url)
}
export function CreateNote(item: Note) {
    return API.Post<object, Note>(URL, item)
}
export function DeleteNote(noteId: number) {
    return API.Delete<object>(URL + "/" + noteId)
}
