export enum WDToolbarAction {
    RESIZE = "RESIZE",
    DELETE = "DELETE",
    SELECT_ITEM = "SELECT_ITEM",

    BOLD = "BOLD",
    ITALIC = "ITALIC",
    UNDERLINE = "UNDERLINE",
    UNDERLINE_STYLE = "UNDERLINE_STYLE",
    STRIKETHROUGH = "STRIKETHROUGH",
    SUPERSCRIPT = "SUPERSCRIPT",
    SUBSCRIPT = "SUBSCRIPT",
    HILITE_COLOR = "HILITE_COLOR",
    LINE_HEIGHT = "LINE_HEIGHT",

    JUSTIFY_LEFT = "JUSTIFY_LEFT",
    JUSTIFY_CENTER = "JUSTIFY_CENTER",
    JUSTIFY_RIGHT = "JUSTIFY_RIGHT",
    JUSTIFY_FULL = "JUSTIFY_FULL",
    VERTICAL_ALIGN = "VERTICAL_ALIGN",

    CONVERT_TO_LINE = "CONVERT_TO_LINE",
    CONVERT_TO_SYLLABLE = "CONVERT_TO_SYLLABLE",

    TURN_LEFT = "TURN_LEFT",
    TURN_RIGHT = "TURN_RIGHT",

    UNORDERED_LIST = "UNORDERED_LIST",
    UNORDERED_LIST_IMAGE = "UNORDERED_LIST_IMAGE",
    ORDERED_LIST = "ORDERED_LIST",
    ORDERED_LIST_IMAGE = "ORDERED_LIST_IMAGE",

    FONT_SIZE = "FONT_SIZE",
    FONT_TYPE = "FONT_TYPE",
    FONT_COLOR = "FONT_COLOR",
    FILL_COLOR = "FILL_COLOR",

    ADD_ROW = "ADD_ROW",
    ADD_ROW_ABOVE = "ADD_ROW_ABOVE",
    ADD_ROW_BELOW = "ADD_ROW_BELOW",
    DELETE_ROW = "DELETE_ROW",
    ADD_COLUMN = "ADD_COLUMN",
    ADD_COLUMN_LEFT = "ADD_COLUMN_LEFT",
    ADD_COLUMN_RIGHT = "ADD_COLUMN_RIGHT",
    DELETE_COLUMN = "DELETE_COLUMN",

    REGENERATE = "REGENERATE",
    CHANGE_GRAPHIC = "CHANGE_GRAPHIC",
    CHANGE_TASK = "CHANGE_TASK",
    CHANGE_SOLUTION_SHOW = "CHANGE_SOLUTION_SHOW",
    SOLUTION_BAR = "SOLUTION_BAR",
    CHANGE_DIGIT_EXCEEDED = "CHANGE_DIGIT_EXCEEDED",
    CHANGE_ZERO_CALCULATION = "CHANGE_ZERO_CALCULATION",
    CHANGE_VALUE_RANGE = "CHANGE_VALUE_RANGE",
    GENERATE_TASK = "GENERATE_TASK",
    CHANGE_CALCULATION_TYPE = "CHANGE_CALCULATION_TYPE",
    CHANGE_DIFFICULTY_LEVEL = "CHANGE_DIFFICULTY_LEVEL",
    CHANGE_NUMBER_PLACEMENT = "CHANGE_NUMBER_PLACEMENT",
    CHANGE_TOPIC = "CHANGE_TOPIC",

    CELL_SIZE = "CELL_SIZE",
    DISTRIBUTE_COLUMNS = "DISTRIBUTE_COLUMNS",
    DISTRIBUTE_ROWS = "DISTRIBUTE_ROWS",

    CORRECTION_MARGIN = "CORRECTION_MARGIN",
    CHANGE_LINEATURE_SIZE = "CHANGE_LINEATURE_SIZE",
    ADD_ROW_SPACING = "ADD_ROW_SPACING",

    ERASER = "ERASER",
    LINE_COLOR = "LINE_COLOR",
    LINE_WIDTH = "LINE_WIDTH",
    BORDER_COLOR = "BORDER_COLOR",
    BORDER_OPTIONS = "BORDER_OPTIONS",

    CELL_FILL_COLOR = "CELL_FILL_COLOR",
    CELL_BORDER_COLOR = "CELL_BORDER_COLOR",
    CELL_SUM = "CELL_SUM",
    CELL_BORDER_MANUAL = "CELL_BORDER_MANUAL",
    CELL_BORDER_MANUAL_COLOR = "CELL_BORDER_MANUAL_COLOR",
    CELL_BORDER_DOUBLE = "CELL_BORDER_DOUBLE",
    CELL_BORDER_ERASE = "CELL_BORDER_ERASE",

    CELL_BORDER_ALL = "CELL_BORDER_ALL",
    CELL_BORDER_OUTER = "CELL_BORDER_OUTER",
    CELL_BORDER_INNER = "CELL_BORDER_INNER",
    CELL_BORDER_NONE = "CELL_BORDER_NONE",
    CELL_BORDER_LEFT = "CELL_BORDER_LEFT",
    CELL_BORDER_RIGHT = "CELL_BORDER_RIGHT",
    CELL_BORDER_TOP = "CELL_BORDER_TOP",
    CELL_BORDER_BOTTOM = "CELL_BORDER_BOTTOM",

    CONNECT_CELLS = "CONNECT_CELLS",
    DISCONNECT_CELLS = "DISCONNECT_CELLS",

    INSERT_ICON = "INSERT_ICON",
    INSERT_GLYPH = "INSERT_GLYPH",
    RECKON_BRACKET = "RECKON_BRACKET",

    OPERATOR_MODE_ACTIVE = "OPERATOR_MODE_ACTIVE",
    OPERATOR_MODE_INACTIVE = "OPERATOR_MODE_INACTIVE",

    DUPLICATE = "DUPLICATE",
    COPY_FORMAT = "COPY_FORMAT",
    DELETE_FORMAT = "DELETE_FORMAT",
    CROSS_OUT = "CROSS_OUT",
    SELECT_IMAGE = "SELECT_IMAGE",

    TOGGLE_SECTION = "TOGGLE_SECTION",
    REMOVE_CALCULATION_PROCESS = "REMOVE_CALCULATION_PROCESS",

    // Admin actions
    SOLUTION = "SOLUTION",
    SOLUTION_CROSS_OUT = "SOLUTION_CROSS_OUT",
    VARIABLE_TEXT = "VARIABLE_TEXT",
}
