import React from "react";
import {MainContext} from "../../_base/MainContext";
import {SearchInput} from "../../Components/Controls/SearchInput";
import {ImagePath} from "../../Framework/CategoryImage";
import {SidebarFilterButtonData} from "./SidebarFilterButtonData";
import {Tooltips, TooltipText} from "../../Components/Tooltips";

interface SidebarFilterBaseProps {
    searchValue?: string
    searchReadOnly?: boolean

    onSearch?: (searchValue: string) => void
    buttons: SidebarFilterButtonData[]
}

interface SidebarFilterBaseState {
}

export class SidebarFilterBase extends React.Component<SidebarFilterBaseProps, SidebarFilterBaseState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        return <div className={"ws-designer-sidebar-images-filter-navigation"}>

            {this.props.onSearch &&
                <SearchInput onSearch={this.props.onSearch!}
                             readonly={this.props.searchReadOnly}
                             value={this.props.searchValue}
                             typeAheadSearch={false}
                             showFieldOptions={false}
                             width={200}
                             maxLength={30}
                />
            }

            {this.props.buttons.map(b => {
                let buttonClass = "ws-designer-sidebar-images-filter-navigation-button tooltip-control"
                if (b.readonly) {
                    buttonClass += " svg-color-primary-light2 ws-designer-toolbar-button-disabled"
                }

                return <div key={b.id} className={"tooltip-" + b.tooltipPosition}>
                    <img className={buttonClass}
                         src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + b.image}
                         draggable={"false"}
                         alt={b.title} onClick={b.readonly ? undefined : b.onClick}
                         onContextMenu={(e) => e.preventDefault()}
                    />

                    <Tooltips text={new TooltipText(b.title, b.tooltip)}
                              translateX={b.tooltipTranslateX} translateY={b.tooltipTranslateY}/>
                </div>
            })}
        </div>
    }
}

