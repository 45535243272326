import API from "../Framework/API";
import Rule from "../_model/Rule";
import RuleCategory from "../_model/RuleCategory";
import RuleType from "../_model/RuleType";
import RulePillar from "../_model/RulePillar";
import {CloneWorksheet} from "./WorksheetEndpoint";
import {Worksheet} from "../_model/Worksheet";
import {WSContextType} from "../Designer/Elements/WSContext";
import {RuleFilter} from "../_model/RuleFilter";
import {WSPageFormat} from "../Framework/Enums";
import {WorksheetItem} from "../_model/WorksheetItem";

const URL = "rules"

export function GetAllRulesAdmin() {
    let url = URL + "/"
    return API.Get<Rule[]>(url)
}
export function GetRule(ruleId: number) {
    let url = URL + "/" + ruleId
    return API.Get<Rule>(url)
}
export function GetFilteredRuleList(filter: RuleFilter) {
    let url = URL + "/filter"
    return API.Post<RuleFilter, Rule[]>(url, filter)
}
export function GetRuleWorksheetItems(ruleId: number) {
    let url = URL + "/" + ruleId + "/worksheet-items"
    return API.Get<WorksheetItem[]>(url)
}
export function GetRuleCategory() {
    let url = URL + "/rule-category/"
    return API.Get<RuleCategory[]>(url)
}
export function GetRuleType(lessonSubjectId: number) {
    let url = URL + "/rule-type/?lesson-type-id=" + lessonSubjectId
    return API.Get<RuleType[]>(url)
}
export function GetRulePillar() {
    let url = URL + "/rule-pillar/"
    return API.Get<RulePillar[]>(url)
}

export async function CreateRule(rule: Rule) {
    return API.Post<object, Rule>(URL, rule)
}
export async function CloneRule(rule: Rule, name: string) {
    rule.name = name

    if (rule.worksheet) {
        const worksheetName = ("WS-Rule: " + rule.name).substring(0, 100)

        const worksheet: Worksheet = await CloneWorksheet(rule.worksheet.id, worksheetName, WSContextType.rule)
        rule.worksheet = new Worksheet(worksheet.id, worksheet.name, worksheet.context, WSPageFormat.A4, worksheet.editingAllowed)
    }

    return CreateRule(rule)
}
export async function UpdateRule(rule: Rule) {
    return API.Put<object, Rule>(URL + "/" + rule.id, rule)
}
