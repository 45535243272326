import React from "react";
import '../../assets/css/layout.min.css';
import {MainContext} from "../../_base/MainContext";
import {Modal} from "../../Components/Modal";
import translations from "../../Framework/translations.json";
import CheckBoxSwitch from "../../Components/Controls/CheckBoxSwitch";
import {ButtonInfo} from "../../Components/Controls/ButtonList";
import {WDPrintOptions} from "./WDPrintOptions";

interface IProps {
    numberOfSolutionSheets: number
    hasAutomaticSolutionElements: boolean

    onCancel: () => void
    onPrint: (printOptions: WDPrintOptions) => void
}

interface IState {
    printOptions: WDPrintOptions
    buttons: ButtonInfo[]
}

export class WDPrintModal extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = ({
            printOptions: new WDPrintOptions(true, false, false),
            buttons: []
        })
    }
    componentDidMount() {
        this.setState({buttons: [
                new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onFormCancel, {}),
                new ButtonInfo("btnSave", "button button-save", "submit", true, !this.state.printOptions.isValueSet(), this.context.translate(translations.command.print), undefined, {marginLeft: "10px"})
            ]})
    }

    onFormSubmit = () => {
        // only print if one checkbox is set to true
        if (this.state.printOptions.isValueSet()) {

            // set cancel button to read only to prevent closing and changing worksheet while printing pdf
            let buttons = this.state.buttons
            buttons[0].readonly = true

            this.setState({buttons: buttons}, () => {
                this.props.onPrint(this.state.printOptions)
            })
        }
    }
    onFormCancel = () => {
        this.props.onCancel()
    }

    onChangePrintOption = (optionName: string) => {
        let options = this.state.printOptions
        options[optionName] = !options[optionName]

        let buttons = this.state.buttons
        buttons[1].readonly = !options.isValueSet()

        this.setState({printOptions: options, buttons: buttons})
    }

    hasSolutionSheets = () => {
        return this.props.numberOfSolutionSheets > 0
    }

    render() {
        return <Modal id={"print"}
                      onFormSubmit={this.onFormSubmit}
                      onFormCancel={this.onFormCancel}
                      title={this.context.translate(translations.text.print_worksheet_dialog_header)}
                      buttons={this.state.buttons}
                      dialogStyle={{width: "30%", height: "470px", minWidth: "600px"}}
                      contentAlignment={"flex-start"}
        >

            <CheckBoxSwitch
                id={"chkPrintWS"}
                label={this.context.translate(translations.text.print_worksheet)}
                labelClass={"label-primary"}
                description={this.context.translate(translations.text.print_worksheet_description)}
                checked={this.state.printOptions.printWorksheet}
                widthLabel={0}
                readonly={false}
                style={{marginBottom: "8px"}}
                onChange={() => this.onChangePrintOption("printWorksheet")}
            />

            <h2>{this.context.translate(translations.text.solutions)}</h2>

            <CheckBoxSwitch
                id={"chkPrintSS"}
                label={this.context.translate(translations.text.print_solution_sheets)}
                labelClass={"label-primary"}
                description={this.context.translate(this.hasSolutionSheets() ?
                    translations.text.print_solution_sheets_description :
                    translations.text.print_no_solution_sheets_description
                )}
                checked={this.hasSolutionSheets() ? this.state.printOptions.printSolutionSheets : false}
                widthLabel={0}
                readonly={!this.hasSolutionSheets()}
                style={{marginBottom: "25px"}}
                onChange={() => this.onChangePrintOption("printSolutionSheets")}
            />

            <CheckBoxSwitch
                id={"chkPrintSL"}
                label={this.context.translate(translations.text.print_automatic_solutions)}
                labelClass={"label-primary"}
                description={this.context.translate(
                    this.props.hasAutomaticSolutionElements ?
                    translations.text.print_automatic_solutions_description :
                    translations.text.print_no_automatic_solutions_description
                )}
                checked={this.props.hasAutomaticSolutionElements ? this.state.printOptions.printAutomaticSolutions : false}
                widthLabel={0}
                readonly={!this.props.hasAutomaticSolutionElements}
                onChange={() => this.onChangePrintOption("printAutomaticSolutions")}
            />

        </Modal>
    }
}
