export default class WDBalloonPolygon {
    imageKey: string
    wrapperClass: string

    constructor(imageKey: string, wrapperClass: string) {
        this.imageKey = imageKey
        this.wrapperClass = wrapperClass
    }

    static getWrapperClassForImage(imageKey: string) {
        let value = values.find(value => value.imageKey === imageKey)
        return value ? value.wrapperClass : "ws-designer-balloon-wrapper-oval"
    }
}

const values = [
    new WDBalloonPolygon("SPEAK01", "ws-designer-balloon-wrapper-oval"),
    new WDBalloonPolygon("SPEAK02", "ws-designer-balloon-wrapper-oval"),
    new WDBalloonPolygon("SPEAK07", "ws-designer-balloon-wrapper-oval"),
    new WDBalloonPolygon("SPEAK09", "ws-designer-balloon-wrapper-oval"),
    new WDBalloonPolygon("SPEAK10", "ws-designer-balloon-wrapper-oval"),

    new WDBalloonPolygon("SPEAK03", "ws-designer-balloon-wrapper-rectangular"),
    new WDBalloonPolygon("SPEAK04", "ws-designer-balloon-wrapper-rectangular"),
    new WDBalloonPolygon("SPEAK05", "ws-designer-balloon-wrapper-rectangular"),
    new WDBalloonPolygon("SPEAK06", "ws-designer-balloon-wrapper-rectangular"),

    new WDBalloonPolygon("SPEAK08", "ws-designer-balloon-wrapper-round"),

    new WDBalloonPolygon("COMIC01", "ws-designer-balloon-wrapper-comic"),
    new WDBalloonPolygon("COMIC02", "ws-designer-balloon-wrapper-comic"),
    new WDBalloonPolygon("COMIC06", "ws-designer-balloon-wrapper-comic"),
    new WDBalloonPolygon("COMIC03", "ws-designer-balloon-wrapper-comic"),
    new WDBalloonPolygon("COMIC04", "ws-designer-balloon-wrapper-comic"),
    new WDBalloonPolygon("COMIC05", "ws-designer-balloon-wrapper-comic"),
    new WDBalloonPolygon("COMIC07", "ws-designer-balloon-wrapper-comic"),
]
