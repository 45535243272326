import React from "react";
import WordLookup from "../../_model/Dictionary/WordLookup";
import {getSynonymString, Word, WordDefinition} from "../../_model/Dictionary/Word";
import {
    GetAdjective, GetAdverb,
    GetArticle, GetConjunction,
    GetFusion,
    GetInterjection,
    GetNoun, GetNumeral, GetPreposition, GetPronoun, GetProperName,
    GetVerb,
    GetWord
} from "../../_endpoint/WordEndpoint";
import translations from "../../Framework/translations.json";
import {EnumValueToEntity} from "../../Framework/Enums";
import {MainContext} from "../../_base/MainContext";

export interface IProps {
    word: WordLookup
}
export interface IState {
    wordRenderer?: JSX.Element
}

export class WordDuplicate extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    componentDidMount() {
        if (this.props.word.id) {

            GetWord(this.props.word.id).then((word) => {
                let text = ""
                let definition = word.wordDefinition

                if(definition) {
                    text += EnumValueToEntity(WordDefinition, definition, translations.enum.wordDefinition, this.context.translate).name

                    switch (definition) {
                        case WordDefinition.noun:
                            GetNoun(this.props.word.id!).then((noun) => {
                                if (noun.articleDefinite) {
                                    text += noun.articleDefinite + " "
                                }
                                if (noun.syllabificationSingular) {
                                    text += noun.syllabificationSingular
                                }
                                if (noun.syllabificationPlural) {
                                    text += ", die " + noun.syllabificationPlural
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.verb:
                            GetVerb(this.props.word.id!).then((verb) => {
                                if (verb.baseForm1) {
                                    text += ", " + verb.baseForm1
                                }
                                if (verb.baseForm2) {
                                    text += ", " + verb.baseForm2
                                }
                                if (verb.baseForm3) {
                                    text += ", " + verb.baseForm3
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.adjective:
                            GetAdjective(this.props.word.id!).then((adjective) => {
                                if (adjective.syllabificationSingular) {
                                    text += ", " + adjective.syllabificationSingular
                                }
                                if (adjective.basicForm) {
                                    text += ", " + adjective.basicForm
                                }
                                if (adjective.comparative) {
                                    text += ", " + adjective.comparative
                                }
                                if (adjective.superlative) {
                                    text += ", " + adjective.superlative
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.article:
                            GetArticle(this.props.word.id!).then((article) => {
                                if (article.type) {
                                    text += ", " + article.type
                                }
                                if (article.syllabification) {
                                    text += " " + article.syllabification
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.fusions:
                            GetFusion(this.props.word.id!).then((fusion) => {
                                if (fusion.syllabification) {
                                    text += ", " + fusion.syllabification
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.interjection:
                            GetInterjection(this.props.word.id!).then((interjection) => {
                                if (interjection.syllabification) {
                                    text += ", " + interjection.syllabification
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.conjunction:
                            GetConjunction(this.props.word.id!).then((conjunction) => {
                                if (conjunction.syllabification) {
                                    text += ", " + conjunction.syllabification
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.preposition:
                            GetPreposition(this.props.word.id!).then((preposition) => {
                                if (preposition.syllabification) {
                                    text += ", " + preposition.syllabification
                                }
                                if (preposition.prepositionType) {
                                    text += ", " + preposition.prepositionType
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.adverb:
                            GetAdverb(this.props.word.id!).then((adverb) => {
                                if (adverb.syllabification) {
                                    text += ", " + adverb.syllabification
                                }
                                if (adverb.adverbType) {
                                    text += ", " + adverb.adverbType
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.numeral:
                            GetNumeral(this.props.word.id!).then((numeral) => {
                                if (numeral.syllabification) {
                                    text += ", " + numeral.syllabification
                                }
                                if (numeral.numeralType) {
                                    text += ", " + numeral.numeralType
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.pronoun:
                            GetPronoun(this.props.word.id!).then((pronoun) => {
                                if (pronoun.syllabification) {
                                    text += ", " + pronoun.syllabification
                                }
                                if (pronoun.pronounType) {
                                    text += ", " + pronoun.pronounType
                                }

                                this.generateItemData(word, text)
                            })
                            break
                        case WordDefinition.properName:
                            GetProperName(this.props.word.id!).then((properName) => {
                                if (properName.syllabification) {
                                    text += ", " + properName.syllabification
                                }
                                if (properName.properNameType) {
                                    text += ", " + properName.properNameType
                                }

                                this.generateItemData(word, text)
                            })
                            break
                    }
                }
            })
        }
    }

    generateItemData = (word: Word, text: string) => {
        let wordRenderer = <>
            <div className={"word-duplicate-item-header"}>
                <div className={"word-duplicate-item-title"}>
                    {word.name}:
                    <div className={"word-duplicate-item-text"}>{text}</div>
                </div>
                <div className={"word-duplicate-item-syllable"}>
                    Abtrennsilbe: {word.syllableDivide === true  ? "Ja" : "Nein"},
                    Sprechsilbe: {word.syllableSpeak === true ? "Ja" : "Nein"}
                </div>
            </div>
            <div className={"word-duplicate-item-details"}>{getSynonymString(word)}</div>
        </>

        this.setState({wordRenderer: wordRenderer})
    }

    render() {
        return <div className={"word-duplicate-item"}>

            {this.state && this.state.wordRenderer}
        </div>
    }
}
