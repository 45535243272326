import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import {LineStyle, WDToolbarButtonColorPicker} from "../General/WDToolbarButtonColorPicker";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button (Yes/No) in Text Toolbar
 * Effects selected text of an element
 * Yes: marked text should get the text decoration given as command
 *      by now all entered text should get bold
 * No:  marked text should get normal
 *      by now all entered text should get normal
 */

class OptionSettings {
    action: WDToolbarAction
    open: boolean

    width: number
    height: number

    constructor(action: WDToolbarAction, open: boolean) {
        this.action = action
        this.open = open

        this.width = 100
        this.height = 100
    }
}

interface IProps {
    id: number
    clickable: boolean
    icon: string
    tooltip: TooltipText
    tooltipDisabled?: TooltipText
    colorPicker: boolean
    colorPickerOptions?: OptionSettings

    buttonWidth?: number
    buttonHeight?: number

    command: string
    action: WDToolbarAction

    onActionButton: (action: WDToolbarAction, data: any) => void
    onToggleButton?: (id: number) => void

    // Used when text decoration button is used globally and not on text selection (Calculation Triangle)
    pressed?: boolean
}

interface IState {
    pressed: boolean
    color: string
    lineStyle: string
    thickness?: string
}

export class WDToolbarButtonTextDecoration extends React.Component<IProps, IState> {

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            pressed: this.props.pressed || false,
            color: "#000000",
            lineStyle: "solid",
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(prevProps.pressed !== this.props.pressed && this.props.pressed !== undefined) {
            this.setState({pressed: this.props.pressed})
        }
    }

    componentDidMount() {
        document.addEventListener('selectionchange', this.onSelectionChange, false);
    }
    componentWillUnmount() {
        document.removeEventListener('selectionchange', this.onSelectionChange, false);
    }

    onSelectionChange = () => {
        this.setState({pressed: document.queryCommandState(this.props.command) })
    }

    onToggle = () => {
        this.props.onActionButton(this.props.action, {
            command: this.props.command,
            color: this.state.color,
            lineStyle: this.state.lineStyle,
            thickness: this.state.thickness
        })
    }
    onToggleList = (id: number) => {
        this.props.onToggleButton?.(id)
    }
    onChangeColor = (color: string) => {
        this.setState({color: color})

        this.props.onActionButton(
            this.props.colorPickerOptions!.action,
            {command: this.props.command, color: color, lineStyle: this.state.lineStyle, thickness: this.state.thickness}
        )
    }
    onChangeLineStyle = (lineStyle: string, thickness?: number, thicknessUnit?: string) => {
        let thick: string | undefined = undefined
        if(thickness && thicknessUnit) {thick = (thickness + thicknessUnit)}
        this.setState({lineStyle: lineStyle, thickness: thick})

        this.props.onActionButton(
            this.state.pressed ? this.props.colorPickerOptions!.action : this.props.action,
            {command: this.props.command, color: this.state.color, lineStyle: lineStyle, thickness: thick}
        )
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-" + this.props.command}
            className={"ws-designer-toolbar-item"}
            id={this.props.command}>

            <WDToolbarButton id={this.props.id}
                             icon={this.props.icon}
                             iconClassSelected={"ws-designer-toolbar-button-img"}
                             iconClassUnselected={"ws-designer-toolbar-button-img"}
                             cssClass={this.props.colorPicker ? "ws-designer-toolbar-button ws-designer-toolbar-button-ml" : undefined}
                             tooltip={this.props.tooltip}
                             tooltipDisabled={this.props.tooltipDisabled}
                             onToggleButton={this.onToggle}
                             buttonWidth={this.props.buttonWidth}
                             buttonHeight={this.props.buttonHeight}
                             arrow={false}
                             color={this.props.colorPicker ? this.state.color : undefined}
                             enabled={this.props.clickable}
                             pressed={this.state.pressed} />

        {
                this.props.colorPicker &&

                <WDToolbarButtonColorPicker
                    id={this.props.id}
                    tooltip={this.props.tooltip}
                    open={this.props.colorPickerOptions!.open}
                    clickable={this.props.clickable}
                    lineStyle={this.props.command === "underline" ? new LineStyle(this.state.color, this.onChangeLineStyle) : undefined}
                    transparency={this.props.command === "color" || this.props.command === "background-color"}
                    onToggleButton={this.onToggleList}
                    onChangeColor={this.onChangeColor}
                />
            }
        </li>
    }
}
