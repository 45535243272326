import API from "../Framework/API";
import {CloneWorksheet} from "./WorksheetEndpoint";
import {Worksheet} from "../_model/Worksheet";
import Exercise from "../_model/Exercise";
import ExerciseCategory from "../_model/ExerciseCategory";
import {ExerciseFilter} from "../_model/ExerciseFilter";
import {WSContextType} from "../Designer/Elements/WSContext";
import {WSPageFormat} from "../Framework/Enums";
import {WorksheetItem} from "../_model/WorksheetItem";

const URL = "exercises"

export function GetExerciseList() {
    let url = URL + "/"
    return API.Get<Exercise[]>(url)
}
export function GetFilteredExerciseList(filter: ExerciseFilter) {
    let url = URL + "/filter"
    return API.Post<ExerciseFilter, Exercise[]>(url, filter)
}
export function GetFilteredExerciseLookupList(filter: ExerciseFilter) {
    let url = URL + "/filter/lookup"
    return API.Post<ExerciseFilter, Exercise[]>(url, filter)
}
export function GetPatternExerciseList() {
    let url = URL + "/?exercise_category_type=exercise_pattern"
    return API.Get<Exercise[]>(url)
}
export function GetTextExerciseList() {
    let url = URL + "/?exercise_category_type=text_exercise"
    return API.Get<Exercise[]>(url)
}
export function GetExerciseListByParent(parent: number) {
    let url = URL + "/"
    return API.Get<Exercise[]>(url + parent + "/list")
}
export function GetExercise(exerciseId: number) {
    let url = URL + "/" + exerciseId
    return API.Get<Exercise>(url)
}
export function GetRandomExercise(filter: ExerciseFilter) {
    let url = URL + "/random"
    return API.Post<ExerciseFilter, Exercise>(url, filter)
}
export function GetExerciseSibling(exerciseId: number, filter: ExerciseFilter) {
    let url = URL + "/" + exerciseId + "/sibling"
    return API.Post<ExerciseFilter, Exercise>(url, filter)
}

export function GetExerciseWorksheetItems(exerciseId: number) {
    let url = URL + "/" + exerciseId + "/worksheet-items"
    return API.Get<WorksheetItem[]>(url)
}

export async function CreateExercise(exercise: Exercise) {
    return API.Post<object, Exercise>(URL, exercise)
}
export async function CloneExercise(exercise: Exercise, name: string, context: WSContextType) {
    exercise.name = name

    if (exercise.worksheet) {
        const worksheetName = ("WS-Exercise: " + exercise.name).substr(0, 100)

        const worksheet: Worksheet = await CloneWorksheet(exercise.worksheet.id, worksheetName, context)
        exercise.worksheet = new Worksheet(worksheet.id, worksheet.name, worksheet.context, WSPageFormat.A4, worksheet.editingAllowed)
    }

    return CreateExercise(exercise)
}
export async function UpdateExercise(exercise: Exercise) {
    return API.Put<object, Exercise>(URL + "/" + exercise.id, exercise)
}
export async function GetExerciseCategories(subjectId: number) {
    return API.Get<ExerciseCategory[]>(URL + "/categories/subject/" + subjectId)
}
