import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {LineWidth, WDToolbarButtonColorPicker} from "./WDToolbarButtonColorPicker";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {WDToolbarAction} from "../../WDToolbarAction";
import {TooltipPosition, TooltipText} from "../../../../Components/Tooltips";

interface IProps {
    id: number
    clickable: boolean
    pressed: boolean
    open: boolean

    lineColor?: string
    lineWidth?: LineWidth

    icon?: string
    iconClass?: string
    tooltip?: TooltipText
    tooltipDisabled?: TooltipText
    tooltipPosition?: TooltipPosition

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    color: string
}

/**
 * Button Submenu for changing line color of element
 * Effects selected element border
 */
export class WDToolbarButtonLineColor extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            color: this.props.lineColor ? "#" + this.props.lineColor : "#000000"
        }
    }

    onChangeColor = (color: string) => {
        this.setState({color: color}, () => {
            let newColor: any = {"lineColor": color.replace("#", "")}
            this.props.onActionButton(WDToolbarAction.LINE_COLOR, newColor)
        })
    }

    onToggle = () => {
        let color = this.state.color

        let newColor: any
        if(this.props.pressed) {
            newColor = {"lineColor": undefined}
        } else {
            newColor = {"lineColor": color.replace("#", "")}
        }
        this.props.onActionButton(WDToolbarAction.LINE_COLOR, newColor)
    }
    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    render() {
        let tooltip = this.props.tooltip
        let tooltipDisabled = this.props.tooltipDisabled

        if(!tooltip) {
            tooltip = new TooltipText(
                this.context.translate(translations.toolbar.lineColor),
                this.context.translate(translations.tooltip.line_color)
            )
        }
        if(!tooltipDisabled) {
            tooltipDisabled = new TooltipText(
                this.context.translate(translations.toolbar.line_color_disabled),
                this.context.translate(translations.tooltip.line_color_disabled_only_default)
            )
        }

        let icon = this.props.icon || ImagePath.getButtonUrl() + "recolor.svg"

        return <li
            key={"ws-designer-toolbar-button-line-color"}
            className={"ws-designer-toolbar-item"}
            id={"ws-designer-toolbar-button-line-color"}>

            <WDToolbarButton id={this.props.id}
                             icon={icon}
                             buttonWidth={32}
                             buttonHeight={32}
                             tooltip={tooltip}
                             tooltipDisabled={tooltipDisabled}
                             tooltipPosition={this.props.tooltipPosition}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             onToggleButton={this.onToggle}
                             arrow={false}
                             color={this.state.color}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed} />

            <WDToolbarButtonColorPicker
                id={this.props.id}
                tooltip={tooltip}
                tooltipPosition={this.props.tooltipPosition}
                open={this.props.open}
                clickable={this.props.clickable}
                transparency={false}
                lineWidth={this.props.lineWidth}
                onToggleButton={this.onToggleList}
                onChangeColor={this.onChangeColor}
            />

        </li>
    }
}
