import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Clickable without pressed version
 * After click - row should be inserted
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltipDisabled?: TooltipText
    position: WDRowPosition
    onActionButton: (action: WDToolbarAction) => void
    onToggleButton: (id: number) => void
}

export enum WDRowPosition {
    NONE,
    ABOVE,
    BELOW
}

export class WDToolbarButtonInsertRow extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    insert = () => {
        switch (this.props.position) {
            case WDRowPosition.NONE:
                this.props.onActionButton(WDToolbarAction.ADD_ROW)
                break
            case WDRowPosition.ABOVE:
                this.props.onActionButton(WDToolbarAction.ADD_ROW_ABOVE)
                break
            case WDRowPosition.BELOW:
                this.props.onActionButton(WDToolbarAction.ADD_ROW_BELOW)
                break
        }

        this.props.onToggleButton(this.props.id)
    }

    getImageUrl = () => {
        switch (this.props.position) {
            case WDRowPosition.NONE:
                return "insert_line.svg"
            case WDRowPosition.ABOVE:
                return "insert_line_above.svg"
            case WDRowPosition.BELOW:
                return "insert_line_under.svg"
        }
    }
    getTooltip = () => {
        switch (this.props.position) {
            case WDRowPosition.NONE:
                return new TooltipText(
                    this.context.translate(translations.toolbar.insert_row),
                    this.context.translate(translations.tooltip.insert_row)
                )
            case WDRowPosition.ABOVE:
                return new TooltipText(
                    this.context.translate(translations.toolbar.insert_row_above),
                    this.context.translate(translations.tooltip.insert_row_above)
                )
            case WDRowPosition.BELOW:
                return new TooltipText(
                    this.context.translate(translations.toolbar.insert_row_below),
                    this.context.translate(translations.tooltip.insert_row_below)
                )
        }
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-insert-row"}
            id={"insertRow"}>

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + this.getImageUrl()}
                             tooltip={this.getTooltip()}
                             tooltipDisabled={this.props.tooltipDisabled}
                             arrow={false}
                             onToggleButton={this.insert}
                             pressed={this.props.pressed} />
        </li>
    }
}
