import React from "react";
import {MainContext} from "../../../../_base/MainContext";
import translations from "../../../../Framework/translations.json";
import Noun, {CharacteristicEnum, GenderEnum} from "../../../../_model/Dictionary/Noun";
import {GetNoun} from "../../../../_endpoint/WordEndpoint";
import {EnumValueToEntity} from "../../../../Framework/Enums";
import {SyllableDefinition, WDSyllableWord} from "../../../Elements/Textbox/WDSyllableWord";
import {SidebarContentDragElementType} from "../../SidebarContentBase";
import {ImagePath} from "../../../../Framework/CategoryImage";
import Image from "../../../../_model/Image";

interface IProps {
    wordId?: number
    syllableDefinition: SyllableDefinition
    onDragStart: (event: React.DragEvent, dragElementType: SidebarContentDragElementType, elementId: string, image?: Image, spellCheck?: boolean) => void
}
interface IState {
    noun?: Noun
}

export class SidebarDictionaryWordFormNoun extends React.Component<IProps, IState>{
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {}
    }

    componentDidMount() {
        this.fetchNoun()
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(this.props.wordId !== prevProps.wordId) {
            this.fetchNoun()
        }
    }

    fetchNoun = () => {
        if(this.props.wordId) {
            GetNoun(this.props.wordId).then(
                (itemData) => {
                    this.setState({noun: itemData}, () => {
                    })
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }

    render() {

        if (this.state.noun === null || this.state.noun === undefined) {
            return <></>
        }

        return <>
            <div className={"ws-designer-sidebar-row"}>
                <div className={"ws-designer-sidebar-column ws-designer-dictionary-form-entry-row-column"}>
                    <div className={"ws-designer-dictionary-form-entry-row-label"}>
                        {this.context.translate(translations.fields.word.noun.article_definite) + ":"}
                    </div>
                    <div className={"ws-designer-dictionary-form-entry-row-text"} style={{paddingRight: "25px"}}>
                        {this.state.noun.articleDefinite}
                    </div>
                </div>
                <div className={"ws-designer-sidebar-column ws-designer-dictionary-form-entry-row-column"}>
                    <div className={"ws-designer-dictionary-form-entry-row-label"}>
                        {this.context.translate(translations.fields.word.noun.article_indefinite) + ":"}
                    </div>
                    <div className={"ws-designer-dictionary-form-entry-row-text"}>
                        {this.state.noun.articleIndefinite}
                    </div>
                </div>
            </div>

            <div className={"ws-designer-sidebar-row"}>
                <div className={"ws-designer-sidebar-column ws-designer-dictionary-form-entry-row-column"}>
                    <div className={"ws-designer-dictionary-form-entry-row-label"}>
                        {this.context.translate(translations.fields.word.noun.gender) + ":"}
                    </div>
                    {this.state.noun.gender &&
                        <div className={"ws-designer-dictionary-form-entry-row-text"}>
                            {EnumValueToEntity(GenderEnum, this.state.noun.gender, translations.enum.noun_gender, this.context.translate).name}
                        </div>
                    }
                </div>

                <div className={"ws-designer-sidebar-column ws-designer-dictionary-form-entry-row-column"}>
                    <div className={"ws-designer-dictionary-form-entry-row-label"}>
                        {this.context.translate(translations.fields.word.noun.characteristic) + ":"}
                    </div>
                    {this.state.noun.characteristic &&
                        <div className={"ws-designer-dictionary-form-entry-row-text"}>
                            {EnumValueToEntity(CharacteristicEnum, this.state.noun.characteristic, translations.enum.characteristic, this.context.translate).name}
                        </div>
                    }
                </div>
            </div>

            {/* Table cases singular*/}
            <div className={"ws-designer-dictionary-table-label"}>
                {this.context.translate(translations.fields.word.noun.cases) + ":"}
            </div>
            <div className={"ws-designer-dictionary-table-container"}
                 draggable={true}
                 onDragStart={(event) =>
                    this.props.onDragStart(
                    event,
                    SidebarContentDragElementType.TABLE,
                    "ws-designer-dictionary-table-cases",
                        undefined,
                        !this.props.syllableDefinition)}>
                <div className={"table-grabber"}>
                    <img
                        draggable={"false"}
                        alt={this.context.translate(translations.command.move)}
                        src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                </div>
                <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-cases"}>
                    <thead>
                        <tr>
                            <th>{this.context.translate(translations.fields.word.noun.case)}</th>
                            <th>{this.context.translate(translations.fields.word.noun.singular)}</th>
                            <th>{this.context.translate(translations.fields.word.noun.plural)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.noun.first_case_simple)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.noun.caseFirst &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseFirst}/>}
                                    &nbsp;
                                    {this.state.noun.caseFirstValue &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseFirstValue}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.noun.caseFirstValuePL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={"die"}/>}
                                    &nbsp;
                                    {this.state.noun.caseFirstValuePL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseFirstValuePL}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.noun.second_case_simple)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.noun.caseSecond &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseSecond}/>}
                                    &nbsp;
                                    {this.state.noun.caseSecondValue &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseSecondValue}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.noun.caseSecondValuePL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={"der"}/>}
                                    &nbsp;
                                    {this.state.noun.caseSecondValuePL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseSecondValuePL}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.noun.third_case_simple)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.noun.caseThird &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseThird}/>}
                                    &nbsp;
                                    {this.state.noun.caseThirdValue &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseThirdValue}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.noun.caseThirdValuePL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={"den"}/>}
                                    &nbsp;
                                    {this.state.noun.caseThirdValuePL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseThirdValuePL}/>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.context.translate(translations.fields.word.noun.fourth_case_simple)}</td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.noun.caseFourth &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseFourth}/>}
                                    &nbsp;
                                    {this.state.noun.caseFourthValue &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseFourthValue}/>}
                                </div>
                            </td>
                            <td>
                                <div className={"ws-designer-dictionary-table-cell-row"}>
                                    {this.state.noun.caseFourthValuePL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={"die"}/>}
                                    &nbsp;
                                    {this.state.noun.caseFourthValuePL &&
                                        <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                        syllableWord={this.state.noun.caseFourthValuePL}/>}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Table cases plural*/}
            <div className={"ws-designer-dictionary-table-label"} id={"ws-designer-dictionary-table-cases-articles"}>
                {this.context.translate(translations.fields.word.noun.cases_article) + ":"}
            </div>
            <div className={"ws-designer-dictionary-table-container"}
                 draggable={true}
                 onDragStart={(event) => {
                     this.props.onDragStart(
                         event,
                         SidebarContentDragElementType.TABLE,
                         "ws-designer-dictionary-table-cases-articles",
                         undefined,
                         !this.props.syllableDefinition)}
                 }>
                <div className={"table-grabber"}>
                    <img
                        draggable={"false"}
                        alt={this.context.translate(translations.command.move)}
                        src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "move.svg"}/>
                </div>
                <table className={"ws-designer-dictionary-table"} id={"ws-designer-dictionary-table-cases-articles"}>
                    <thead>
                        <tr>
                            <th>{this.context.translate(translations.fields.word.noun.case)}</th>
                            <th>{this.context.translate(translations.fields.word.noun.article_definite)}</th>
                            <th>{this.context.translate(translations.fields.word.noun.article_indefinite)}</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{this.context.translate(translations.fields.word.noun.first_case_simple)}</td>
                        <td>
                            <div className={"ws-designer-dictionary-table-cell-row"}>
                                {this.state.noun.caseFirst &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseFirst}/>}
                                &nbsp;
                                {this.state.noun.caseFirstValue &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseFirstValue}/>}
                            </div>
                        </td>
                        <td>
                            <div className={"ws-designer-dictionary-table-cell-row"}>
                                <WDSyllableWord syllableDefinition={this.props.syllableDefinition} syllableWord={"ein"}/>
                                &nbsp;
                                {this.state.noun.caseFirstValue &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseFirstValue}/>}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.context.translate(translations.fields.word.noun.second_case_simple)}</td>
                        <td>
                            <div className={"ws-designer-dictionary-table-cell-row"}>
                                {this.state.noun.caseSecond &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseSecond}/>}
                                &nbsp;
                                {this.state.noun.caseSecondValue &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseSecondValue}/>}
                            </div>
                        </td>
                        <td>
                            <div className={"ws-designer-dictionary-table-cell-row"}>
                                <WDSyllableWord syllableDefinition={this.props.syllableDefinition} syllableWord={"eines"}/>
                                &nbsp;
                                {this.state.noun.caseSecondValue &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseSecondValue}/>}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.context.translate(translations.fields.word.noun.third_case_simple)}</td>
                        <td>
                            <div className={"ws-designer-dictionary-table-cell-row"}>
                                {this.state.noun.caseThird &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseThird}/>}
                                &nbsp;
                                {this.state.noun.caseThirdValue &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseThirdValue}/>}
                            </div>
                        </td>
                        <td>
                            <div className={"ws-designer-dictionary-table-cell-row"}>
                                <WDSyllableWord syllableDefinition={this.props.syllableDefinition} syllableWord={"einem"}/>
                                &nbsp;
                                {this.state.noun.caseThirdValue &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseThirdValue}/>}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.context.translate(translations.fields.word.noun.fourth_case_simple)}</td>
                        <td>
                            <div className={"ws-designer-dictionary-table-cell-row"}>
                                {this.state.noun.caseFourth &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseFourth}/>}
                                &nbsp;
                                {this.state.noun.caseFourthValue &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseFourthValue}/>}
                            </div>
                        </td>
                        <td>
                            <div className={"ws-designer-dictionary-table-cell-row"}>
                                <WDSyllableWord syllableWord={"einen"} syllableDefinition={this.props.syllableDefinition} />
                                &nbsp;
                                {this.state.noun.caseFourthValue &&
                                    <WDSyllableWord syllableDefinition={this.props.syllableDefinition}
                                                    syllableWord={this.state.noun.caseFourthValue}/>}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            </>

    }
}
