export default class Conf {
    static BACKEND_URL() {
        if (process.env.NODE_ENV === "production") {
            return "https://api.vicido.at/vicido-backend/api/v1/"
        }
        return "http://localhost:8080/api/v1/" //For test at tablet: specific ip-address of hosting dev PC
    }
    static PRINT_SERVICE_URL() {
        if (process.env.NODE_ENV === "production") {
            return "https://api.vicido.at/vicido-print/"
        }
        return "http://localhost:3002/vicido-print/" //For test at tablet: specific ip-address of hosting dev PC
    }
    static IMAGE_URL() {
        if (process.env.NODE_ENV === "production") {
            return "https://api.vicido.at/vicido-backend/img/"
        }
        return "http://localhost:8080/img/" //For test at tablet: specific ip-address of hosting dev PC
    }
    static WEBSITE_URL() {
        if (process.env.NODE_ENV === "production") {
            return "https://www.vicido.at/"
        }
        return "https://www.vicido.at/"
    }
    static ODOO_URL_DEVELOPMENT() {
        return "https://staging-vicido.odoo.com/web/session/"
    }
    static ODOO_DB() {
        return "moma0703-odoo-staging-7875458"
    }
    static PATH() {
        if (process.env.NODE_ENV === "production") {
            return "/";
        }
        return ""
    }
    static CLIENT_LOG() {
        // return (process.env.NODE_ENV === "production")
        return true
    }
}
