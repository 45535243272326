import Entity from "../Entity";
import {WordDefinition} from "./Word";
import {Status} from "../../Framework/Enums";

export class WordLookup extends Entity {
    wordDefinition?: WordDefinition
    syllableSpeak?: boolean
    syllableDivide?: boolean
    syllabification?: string
    status?: Status
}

export default WordLookup
