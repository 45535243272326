import {WorksheetPage} from "../../_model/WorksheetPage";

export class WorksheetPageUpdate {
    key: string
    value: Partial<WorksheetPage>

    constructor(key: string, value: Partial<WorksheetPage>) {
        this.key = key
        this.value = value
    }
}
