import React from "react";
import {MainContext} from "../../../../_base/MainContext";
import translations from "../../../../Framework/translations.json";
import {GetArticle} from "../../../../_endpoint/WordEndpoint";
import {EnumValueToEntity} from "../../../../Framework/Enums";
import {SidebarContentDragElementType} from "../../SidebarContentBase";
import Article, {ArticleType} from "../../../../_model/Dictionary/Article";
import {GenderEnum} from "../../../../_model/Dictionary/Noun";

interface IProps {
    wordId?: number
    onDragStart: (event: React.DragEvent, dragElementType: SidebarContentDragElementType, elementId: string) => void
}
interface IState {
    article?: Article
}

export class SidebarDictionaryWordFormArticle extends React.Component<IProps, IState>{
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
        }
    }

    componentDidMount() {
        this.fetchArticle()
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(this.props.wordId !== prevProps.wordId) {
            this.fetchArticle()
        }
    }

    fetchArticle = () => {
        if(this.props.wordId) {
            GetArticle(this.props.wordId).then(
                (itemData) => {
                    this.setState({article: itemData}, () => {
                    })
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }

    render() {
        if (this.state.article === null || this.state.article === undefined) {
            return <></>
        }

        return <>
            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.article.type) + ":"}
                </div>
                <div className={"ws-designer-dictionary-form-entry-row-text"}>
                    {this.state.article.type && EnumValueToEntity(ArticleType, this.state.article.type, translations.enum.article_type, this.context.translate).name}
                </div>
            </div>

            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.article.gender) + ":"}
                </div>
                <div className={"ws-designer-dictionary-form-entry-row-text"}>
                    {this.state.article.gender && EnumValueToEntity(GenderEnum, this.state.article.gender, translations.enum.noun_gender, this.context.translate).name}
                </div>
            </div>
        </>

    }
}
