import {EntityData} from "./Entity";
import User from "./User";
import {AdminObjectType, Status} from "../Framework/Enums";
import {ImagePath} from "../Framework/CategoryImage";

export default class ApprovalItem extends EntityData {
    type: string

    constructor(name: string, type: string, createdOn?: Date, createdBy?: User, modifiedOn?: Date, modifiedBy?: User, id?: number) {
        super(name, Status.approval, createdOn, createdBy, modifiedOn, modifiedBy, undefined, id);

        this.type = type
    }

    static getItemConfig(item: ApprovalItem) {
        let url: string = process.env.PUBLIC_URL + ImagePath.getMenuUrl()

        if (item.type) {
            if(item.type === AdminObjectType.word) {
                return { area: "dictionary", url: url + "dictionary.svg"}
            }
            else if(item.type === AdminObjectType.country) {
                return { area: "countries", url: url + "earth.svg"}
            }
            else if(item.type === AdminObjectType.text_exercise_main || item.type === AdminObjectType.text_exercise_child || item.type === AdminObjectType.text_exercise) {
                return { area: "text-exercises", url: url + "toolbox_group_maths.svg"}
            }
            else if(item.type === AdminObjectType.writing_course_main || item.type === AdminObjectType.writing_course_child) {
                return { area: "writing-course", url: url + "writing_course.svg"}
            }
            else if(item.type === AdminObjectType.name) {
                return { area: item.type + "s", url: url + "name.svg"}
            }
            else if(item.type === AdminObjectType.image) {
                return { area: item.type + "s", url: url + item.type + "s.svg"}
            }
            else if(item.type === AdminObjectType.rule) {
                return { area: item.type + "s", url: url + item.type + "s.svg"}
            }
            else if(item.type === AdminObjectType.exercise || item.type === AdminObjectType.exercise_pattern) {
                return { area: item.type + "s", url: url + "exercise.svg"}
            }
            else if(item.type === AdminObjectType.search_result) {
                return { area: "search-results", url: url + "search.svg"}
            }
            else if(item.type === AdminObjectType.sales_material) {
                return { area: "marketplace-sales-material", url: url + "marketplace.svg"}
            }
        }
    }
}
