import React from "react";
import {MainContext} from "../../../_base/MainContext";
import {ElementProps} from "../Toolbar/WDToolbarElement";
import {WDToolbarAction} from "../WDToolbarAction";
import {WDToolbarState} from "../Toolbar/WDToolbar";
import {WDToolbarButtonSeparator} from "../Button/General/WDToolbarButtonSeparator";
import {WDToolbarButtonDelete} from "../Button/General/WDToolbarButtonDelete";
import {WDToolbarTypeEnum} from "../Toolbar/WDToolbarTabs";
import {ElementBorder} from "../../Elements/WDElementContainer";
import {WDToolbarButtonRules} from "../Button/General/WDToolbarButtonRules";
import {WDToolbarButtonLock} from "../Button/General/WDToolbarButtonLock";
import {WDToolbarButtonGroup} from "../Button/General/WDToolbarButtonGroup";
import {WDToolbarSectionObjectColor} from "./WDToolbarSectionObjectColor";
import {WSContextType} from "../../Elements/WSContext";
import {WorksheetItemType} from "../../../_model/WorksheetItemType";
import RuleCategory from "../../../_model/RuleCategory";
import {WDToolbarButton} from "../Button/WDToolbarButton";
import {ImagePath} from "../../../Framework/CategoryImage";
import {TooltipText} from "../../../Components/Tooltips";
import translations from "../../../Framework/translations.json";
import {WorksheetItemUpdate, WorksheetItemUpdateOptions} from "../../Utils/WorksheetItemUpdate";

export interface IProps {
    // Active toolbar submenu of outer toolbar
    activeId: number
    elementProps: ElementProps
    showColorOptions: boolean
    showBorderPadding: boolean
    context: WSContextType

    worksheetItemType?: WorksheetItemType

    onUpdateSelectedElements: (update: WorksheetItemUpdate, options?: WorksheetItemUpdateOptions) => void
    onToolbarAction: (action: WDToolbarAction, data?: Text) => void
    onChangeActive: () => void
    onChangeBorder: (border: ElementBorder) => void
    onElementDeleted?: () => void

    onDuplicate: () => void
    onCut: () => void
    onCopy: () => void
    onPaste: () => void

    onChangeGroupingStatus?: () => void
    onAddRule?: (ruleId: number) => void
}

export interface IState extends WDToolbarState {
}

export class WDToolbarSectionObjectGeneral extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        // If submenu outside is opened, close all submenus of general
        if (this.props.activeId !== prevProps.activeId && this.props.activeId > 0) {
            this.setState({activeId: -1})
        }
    }

    isUsedInChildExercise = () => {
        return this.props.context === WSContextType.text_exercise_child || this.props.context === WSContextType.writing_course_child
    }

    onToggleButton = (id: number) => {
        this.setState({activeId: id}, () => this.props.onChangeActive())
    }
    onChangeLockingStatus = (locked: boolean) => {
        this.props.onUpdateSelectedElements(new WorksheetItemUpdate("", {
            locked: locked
        }))
        this.setState({activeId: -1})
    }

    render() {
        return <div className={"ws-designer-toolbar-row"} style={{height: "100%"}}>

            {this.props.showColorOptions &&
            <WDToolbarSectionObjectColor
                activeId={this.state.activeId}
                elementProps={this.props.elementProps}
                isReadonly={this.isUsedInChildExercise()}
                showBorderPadding={this.props.showBorderPadding}
                onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                onToolbarAction={this.props.onToolbarAction}
                onChangeActive={() => this.onToggleButton(-1)}
                onChangeBorder={this.props.onChangeBorder}
            />
            }

            <WDToolbarButtonSeparator/>

            <div className={"ws-designer-toolbar-section"}>
                <div className={"ws-designer-toolbar-row"}>
                    {this.props.onAddRule &&
                        <WDToolbarButtonRules
                            id={21}
                            width={310}
                            height={210}
                            pressed={this.state.activeId === 21}
                            clickable={!this.isUsedInChildExercise()}
                            ruleCategory={RuleCategory.getRuleCategoryRule()}
                            worksheetItemType={this.props.worksheetItemType}

                            onToggleButton={this.onToggleButton}
                            onAddRule={this.props.onAddRule}
                        />
                    }

                    <li id={"duplicateElement"}>
                        <WDToolbarButton id={7}
                                         enabled={!this.isUsedInChildExercise() && this.props.context !== WSContextType.text_exercise_main}
                                         icon={ImagePath.getButtonUrl() + "duplicate.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.duplicate),
                                             this.context.translate(translations.tooltip.duplicate)
                                         )}
                                         arrow={false}
                                         onToggleButton={() => this.props.onDuplicate()}
                                         pressed={false} />
                    </li>

                    <li id={"cutElement"}>
                        <WDToolbarButton id={8}
                                         enabled={!this.isUsedInChildExercise() && this.props.context !== WSContextType.text_exercise_main}
                                         icon={ImagePath.getButtonUrl() + "cut.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.cut),
                                             this.context.translate(translations.tooltip.cut)
                                         )}
                                         arrow={false}
                                         onToggleButton={() => this.props.onCut()}
                                         pressed={false} />
                    </li>

                    <WDToolbarButtonLock
                        id={23}
                        locked={this.props.elementProps.locked}
                        clickable={!this.isUsedInChildExercise()}
                        onChangeLockingStatus={this.onChangeLockingStatus}
                        onToggleButton={this.onToggleButton}/>
                </div>

                <div className={"ws-designer-toolbar-row"}>
                    {this.props.onAddRule &&
                        <WDToolbarButtonRules
                            id={22}
                            width={310}
                            height={210}
                            pressed={this.state.activeId === 22}
                            clickable={!this.isUsedInChildExercise()}
                            ruleCategory={RuleCategory.getRuleCategoryDescription()}
                            worksheetItemType={this.props.worksheetItemType}

                            onToggleButton={this.onToggleButton}
                            onAddRule={this.props.onAddRule}
                        />
                    }

                    <li id={"copyElement"}>
                        <WDToolbarButton id={9}
                                         enabled={!this.isUsedInChildExercise() && this.props.context !== WSContextType.text_exercise_main}
                                         icon={ImagePath.getButtonUrl() + "copy.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.copy),
                                             this.context.translate(translations.tooltip.copy)
                                         )}
                                         arrow={false}
                                         onToggleButton={() => this.props.onCopy()}
                                         pressed={false} />
                    </li>

                    <li id={"pasteElement"}>
                        <WDToolbarButton id={10}
                                         enabled={!this.isUsedInChildExercise() && this.props.context !== WSContextType.text_exercise_main}
                                         icon={ImagePath.getButtonUrl() + "paste.svg"}
                                         tooltip={new TooltipText(
                                             this.context.translate(translations.toolbar.paste),
                                             this.context.translate(translations.tooltip.paste)
                                         )}
                                         arrow={false}
                                         onToggleButton={() => this.props.onPaste()}
                                         pressed={false} />
                    </li>

                    <WDToolbarButtonDelete
                        id={24}
                        pressed={false}
                        clickable={this.props.context !== WSContextType.writing_course_child && this.props.onElementDeleted !== undefined}
                        onToggleButton={this.onToggleButton}
                        onActionButton={this.props.onElementDeleted !== undefined ? this.props.onElementDeleted : () => {}} />

                    {this.props.onChangeGroupingStatus &&
                        <WDToolbarButtonGroup id={25}
                                              grouped={false}
                                              clickable={!this.isUsedInChildExercise()}
                                              onChangeGroupingStatus={this.props.onChangeGroupingStatus}
                                              onToggleButton={this.onToggleButton}
                        />
                    }
                </div>
            </div>
        </div>
    }
}
