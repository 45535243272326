import {WDHistoryItem} from "./WDHistoryItem";
import {WorksheetPageUpdate} from "../Utils/WorksheetPageUpdate";

export class WDPageHistoryItem implements WDHistoryItem {
    updates: WorksheetPageUpdate[]

    constructor(elements: WorksheetPageUpdate[]) {
        this.updates = elements
    }
}
