import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Triggers grouping/ungrouping of element
 */

interface IProps {
    id: number
    grouped: boolean
    clickable: boolean
    onChangeGroupingStatus: (locked: boolean) => void
    onToggleButton: (id: number) => void
}

interface IState {}

export class WDToolbarButtonGroup extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    changeGroupingStatus = () => {
        this.props.onChangeGroupingStatus(!this.props.grouped)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-group"}
            id={"groupElement"}>

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + (this.props.grouped ? "group_open.svg" : "group.svg")}
                             tooltip={this.props.grouped ?
                                 new TooltipText(this.context.translate(translations.toolbar.ungroup), this.context.translate(translations.tooltip.ungroup)) :
                                 new TooltipText(this.context.translate(translations.toolbar.group), this.context.translate(translations.tooltip.group))
                             }
                             arrow={false}
                             onToggleButton={this.changeGroupingStatus}
                             pressed={false} />
        </li>
    }
}
