import {UserSettingsAutoSave} from "../Framework/Enums";
import {SyllableMethod} from "./Dictionary/Word";

export class UserSettings {

    id: number
    profilePicture: string
    languageId: number
    autoSave: boolean
    autoSaveOnClose: UserSettingsAutoSave
    listPageSize: number
    syllable: SyllableMethod
    syllableColor1: string
    syllableColor2: string

    constructor(
        id: number,
        profilePicture: string,
        languageId: number,
        autoSave: boolean,
        autoSaveOnClose: UserSettingsAutoSave,
        listPageSize: number,
        syllable: SyllableMethod,
        syllableColor1: string,
        syllableColor2: string
    ) {
        this.id = id
        this.profilePicture = profilePicture
        this.languageId = languageId
        this.autoSave = autoSave
        this.autoSaveOnClose = autoSaveOnClose
        this.listPageSize = listPageSize
        this.syllable = syllable
        this.syllableColor1 = syllableColor1
        this.syllableColor2 = syllableColor2
    }

    static getDefaultSettings(): UserSettings {
        return new UserSettings(-1, "", -1, false, UserSettingsAutoSave.alwaysSave,
            10, SyllableMethod.syllableDivide, "#000000", "#000000")
    }
}
