import {EntityData} from "./Entity";
import User from "./User";
import {Gender} from "../Framework/Enums";
import Language from "./Language";

export class Name extends EntityData {
    gender?: Gender
    notes?: string
    genitive?: string
    difficulty?: string
    phonemic?: boolean
    languages?: Language[]
    syllabificationDivide?: string
    syllabificationDivideGenitive?: string
    syllabificationSpeak?: string
    syllabificationSpeakGenitive?: string
    ownerId?: User
    createdBy?: User
    modifiedOn?: Date
}

export default Name
