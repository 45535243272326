import React, {CSSProperties, FormEvent} from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import {Util} from "../../Framework/Util";
import {NumberInput} from "./NumberInput";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";

export enum DropDownType {
    NUMBER,
    TEXT,
    JSX
}

export class DropDownItemStyle {
    value: string
    key: string

    constructor(key: string, value: string) {
        this.value = value
        this.key = key
    }
}

export class DropDownItem {
    value: string
    name: any
    style?: CSSProperties

    constructor(value: string, name: string) {
        this.value = value
        this.name = name
    }
}
export enum OpeningDirection {
    below = "below",
    above = "above"
}

interface IProps {
    id: string,
    type: DropDownType
    value: string
    readonly: boolean
    autocomplete: boolean
    placeholder?: string
    required: boolean
    className?: string
    style?: React.CSSProperties

    width: number
    height?: number
    items: DropDownItem[]
    openingDirection?: OpeningDirection

    minValue?: number
    maxValue?: number
    maxlength?: number
    stepSize?: number

    unit?: string
    unitWidth?: number
    unitPaddingRight?: number

    onChangeValue: (value: string) => void
}

interface IState {
    value: DropDownItem | undefined
    inputValue: string
    showList: boolean
    style: React.CSSProperties
}

export class DropDown extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        let style = this.props.style || {}
        if (style) {
            style.width = this.props.width
        }

        const item = this.props.items.find(item => item.value === this.props.value)
        this.state = {
            inputValue: item !== undefined ? item.value : this.props.value,
            value: item,
            style: style,
            showList: false
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if ((this.props.value !== prevProps.value) ||
            (this.props.items !== undefined && this.props.items.length !== prevProps.items.length)) {

            const item = this.props.items.find(item => item.value === this.props.value)

            this.setState({
                inputValue: item !== undefined ? item.value : this.props.value,
                value: item
            })
        }
    }

    hideList = () => {
        if (this.state.showList) {
            window.removeEventListener('mousedown', this.onUnselect);
            this.setState({showList: false})
        }
    }

    onInput = (e: FormEvent) => {
        e.preventDefault()
        e.stopPropagation()

        const control = e.target as HTMLInputElement
        this.setState({inputValue: control.value, value: undefined, showList: true})
    }
    onKeyDown = (e: React.KeyboardEvent) => {
        if ((e.key === 'ArrowLeft') || (e.key === 'ArrowRight') ||
            (e.key === 'ArrowUp') || (e.key === 'ArrowDown') ||
            (e.key === 'Delete') || (e.key === 'Backspace')) {

            e.stopPropagation()
        }
    }
    onChangeValue = (value: string) => {
        this.setState({inputValue: value, showList: (this.props.type !== DropDownType.NUMBER)})
        this.props.onChangeValue(value)
    }
    onUnselect = (e: MouseEvent) => {
        const child = Util.isChildOfId(e.target as HTMLElement, "drop-down-" + this.props.id)

        if (!child) {
            this.hideList()
        }
    }
    onListClick = () => {
        this.setState({showList: !this.state.showList}, () => {
            if (this.state.showList) {
                window.addEventListener('mousedown', this.onUnselect);
            }
        })
    }
    onListItemClick = (item: DropDownItem) => {
        this.setState({value: item, inputValue: item.name}, () => this.hideList())
        this.props.onChangeValue(item.value)
    }

    getListContainerStyle = () => {
        if(this.props.openingDirection) {
            switch (this.props.openingDirection) {
                case OpeningDirection.below:
                    return {width: this.props.width}

                case OpeningDirection.above:
                    return {width: this.props.width, top: "-115px"}
            }
        }
        else {
            return {width: this.props.width}
        }
    }

    render() {
        let dropDownClass = (this.props.className || "") + " drop-down"
        let dropDownTextClass = ""
        let dropDownArrowClass = "ws-designer-toolbar-button-arrow drop-down-button"

        if (this.props.readonly) {
            dropDownClass += " drop-down-read-only"
            dropDownTextClass = "drop-down-read-only"
            dropDownArrowClass += " drop-down-read-only"
        }

        return <div id={"drop-down-" + this.props.id}
                    className={dropDownClass}
                    style={this.state.style}>

            {this.props.type === DropDownType.NUMBER &&
                <NumberInput width={this.props.width}
                             clickable={!this.props.readonly}
                             required={this.props.required}
                             maxlength={this.props.maxlength || 3}
                             minValue={this.props.minValue}
                             maxValue={this.props.maxValue}
                             stepSize={this.props.stepSize}
                             unit={this.props.unit}
                             unitWidth={this.props.unitWidth}
                             unitPaddingRight={this.props.unitPaddingRight}
                             value={+this.state.inputValue}
                             onChangeValue={(value) => this.onChangeValue(value.toString())}/>
            }

            {this.props.type === DropDownType.TEXT &&
                <input type={"text"} className={dropDownTextClass}
                       placeholder={this.props.placeholder}
                       required={this.props.required}
                       readOnly={this.props.readonly}
                       onKeyDown={!this.props.readonly ? this.onKeyDown : undefined}
                       onClick={!this.props.readonly ? this.onListClick : undefined}
                       onInput={!this.props.readonly ? this.onInput : undefined}
                       style={{
                           width: this.props.width - 20,
                           textAlign: this.props.style?.textAlign
                       }}
                       value={this.state.value !== undefined ? this.state.value.name : this.state.inputValue}/>
            }

            {this.props.type === DropDownType.JSX &&
                <div onClick={!this.props.readonly ? this.onListClick : undefined}
                     style={{width: this.props.width - 20}}>
                    {this.state.value !== undefined ? this.state.value.name : ""}
                </div>
            }

            {!this.props.readonly &&
                <div
                    className={"drop-down-button-container"}
                    onClick={!this.props.readonly ? this.onListClick : undefined}
                >
                    <img
                        src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                        className={dropDownArrowClass}
                        style={{padding: 0}}
                        alt={this.context.translate(translations.command.select)}
                        draggable={"false"}
                        onContextMenu={(e) => e.preventDefault() }
                    />
                </div>
            }

            {this.state.showList &&
                <div className={"drop-down-list-container"}
                     style={this.getListContainerStyle()}
                >
                    <div className={"drop-down-list-scroll-container"}
                    >
                        <ul id={"listData"} className={"drop-down-list"}
                            style={{
                                width: (this.props.width - 15),
                                height: (this.props.height || 110),
                                textAlign: (this.props.style?.textAlign ? this.props.style.textAlign : (this.props.type === DropDownType.NUMBER ? "right" : "left")),
                                display: "block"
                            }}>

                            {this.props.items
                                .filter(item => !this.props.autocomplete
                                    || this.state.inputValue === ""
                                    || (this.state.value !== undefined ? true : item.name.toString().toUpperCase().startsWith(this.state.inputValue.toUpperCase())))
                                .map(item => {

                                    let itemStyle = {...item.style}

                                    // to allow multiline content (as well needed is display: 'block' in ul
                                    itemStyle.height = "fit-content"
                                    itemStyle.paddingBottom = "4px"

                                    return <li value={item.value} key={item.value} style={itemStyle}
                                               onClick={() => this.onListItemClick(item)}>
                                        {item.name}
                                    </li>
                                })}
                        </ul>
                    </div>
                </div>
            }
        </div>
    }
}

