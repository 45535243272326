import React from "react";
import Image from "../../_model/Image";
import ImageFamily from "../../_model/ImageFamily";
import {SidebarContentDragElementType} from "../../Designer/Sidebar/SidebarContentBase";
import {ImageSmallListItem} from "./ImageSmallListItem";
import _ from "lodash";
import {ImagePath} from "../../Framework/CategoryImage";
import Entity from "../../_model/Entity";

interface IProps {
    id: number
    name: string
    currentImageId?: number

    showTitle: boolean
    showHover: boolean
    showImages: boolean

    retrieveImages: (id: number) => Promise<Image[]>

    onSelectItem?: (imageFamily: ImageFamily) => void
    onClickItem?: (image: Image) => void
    onDragStart?: (event: React.DragEvent, dragElementType: SidebarContentDragElementType, imageElementId: string, image: Image) => void
}

interface IState {
    images: Image[]
    open: boolean
}

export class ImageGroupListItem extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = {
            images: [],
            open: !this.props.showTitle
        }
    }

    componentDidMount() {
        if (this.props.showImages) {
            this.getImages()
        }
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.id !== this.props.id && this.props.showImages) {
            this.setState({images: []}, () => this.getImages())
        }
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getImages = () => {
        this.props.retrieveImages(this.props.id).then((images) => {
            this.setState({images: images})
        })
    }

    onClickGroup = () => {
        this.props.onSelectItem?.(new Entity(this.props.name, this.props.id))
    }
    onClickImage = (image: Image) => {
        if (this.props.onClickItem && image.id !== this.props.currentImageId) {
            this.props.onClickItem(image)
        }
    }
    onOpenImages = () => {
        this.setState({open: !this.state.open}, () => {
            if (this.state.open && this.state.images.length === 0) {
                this.getImages()
            }
        })
    }


    render() {
        let result = <div key={this.props.id}
                          className={"image-family-list-item" + (this.props.showHover ? " image-family-list-item-hover" : "")}
                          onClick={() => this.onClickGroup()}>

            {this.props.showTitle && <div>{this.props.name}</div>}

            {this.state.open &&
                <ImageSmallListItem
                    images={this.state.images}
                    currentImageId={this.props.currentImageId}
                    onClickImage={this.onClickImage}
                    onDragStart={this.props.onDragStart}
                />
            }
        </div>

        if (this.props.showTitle) {
            return <div className={"image-family-list-item-container"} key={this.props.id}>
                <div>
                    <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + (this.state.open ? "dropdown_arrow_down.svg" : "arrow_next.svg")}
                         width={8}
                         alt="Open"
                         className={"image-family-list-item-toggle-button"}
                         onClick={() => this.onOpenImages()}
                         draggable={"false"}
                         onContextMenu={(e) => e.preventDefault() }
                    />&nbsp;
                </div>
                {result}
            </div>
        } else {
            return result
        }

    }
}
