import React, {CSSProperties} from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import {NotificationStatus} from "../../Framework/Enums";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";

export class NotificationData {
    notificationType: NotificationStatus
    message: string

    constructor(notificationTyp: NotificationStatus, message: string) {
        this.notificationType = notificationTyp
        this.message = message
    }
}

export interface IHintProps {
    id: string
    notificationData: NotificationData
    style?: CSSProperties
}

export interface IHintState { }

/**
 * Render notification dialog depending on notification status
 */
export class Hint extends React.Component<IHintProps, IHintState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    className: string = "hint"
    image: string = ""
    altText: string = ""

    constructor(props: IHintProps, state: IHintState) {
        super(props, state);

        this.getData()
    }

    componentDidUpdate(prevProps: Readonly<IHintProps>) {
        if(this.props.notificationData.notificationType !== prevProps.notificationData.notificationType) {
            this.resetVariables()
            this.getData()

            this.forceUpdate()
        }
    }

    resetVariables = () => {
        this.className = "hint"
        this.image = ""
        this.altText = ""
    }

    getData = () => {
        switch (this.props.notificationData.notificationType) {
            case NotificationStatus.ok:
                this.className += " hint-success"
                this.image = "all_right.svg"
                this.altText = this.context.translate(translations.notification.success)
                break

            case NotificationStatus.loading:
                this.className += " notification-loading"
                this.image = "loading.gif"
                this.altText = this.context.translate(translations.notification.processing)
                break

            case NotificationStatus.info:
                this.className += " hint-information"
                this.image = "callsign.svg"
                this.altText = this.context.translate(translations.notification.information)
                break

            case NotificationStatus.error:
                this.className += " hint-error"
                this.image = "warning.svg"
                this.altText = this.context.translate(translations.notification.error)
                break
        }
    }

    render() {
        return <div id={this.props.id} className={this.className} style={this.props.style}>
            <img src={process.env.PUBLIC_URL + ImagePath.getNotificationUrl() + this.image} alt={this.altText}/>
            <div dangerouslySetInnerHTML={{__html: this.props.notificationData.message}}/>
        </div>
    }
}
