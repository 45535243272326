import translations from "../../Framework/translations.json";
import {ColumnDefinition} from "../../Components/List/List";
import {MainContext} from "../../_base/MainContext";
import {GetApprovalItems} from "../../_endpoint/MaintenanceEndpoint";
import {Resource} from "../../Framework/Util";
import ApprovalItem from "../../_model/ApprovalItem";
import ApprovalListItem from "./ApprovalListItem";
import {AdminObjectType, ColumnRenderOption, EnumToEntityArray} from "../../Framework/Enums";
import React from "react";
import {ListFilterDefinition, ListFilterDefinitionItem} from "../../Components/List/ListFilter";
import {GetAllAdmins} from "../../_endpoint/UserEndpoint";
import User from "../../_model/User";
import Entity from "../../_model/Entity";
import AdminDashboardList from "../Maintenance/AdminDashboardList";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: ApprovalItem[]
    listItems: ApprovalListItem[]
    admins?: User[]
    listFilter: ListFilterDefinition[]
}

export default class ApprovalList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition("", "url", 4, true, true, ColumnRenderOption.Icon),
        new ColumnDefinition(this.context.translate(translations.fields.name), "name", 29, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.approval_item.type), "type", 17, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 23, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 17, false, true, ColumnRenderOption.DateTime)
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            listItems: [],
            listFilter: []
        }
    }

    componentDidMount() {
        this.fetchData()

        GetAllAdmins().then((result) => {
            this.setState({admins: result}, this.setFilter)
        }, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })
    }
    setFilter = () => {
        let filter: ListFilterDefinition[] = []

        let types = [ new ListFilterDefinitionItem(-1,this.context.translate(translations.fields.filter.all_types))]
        for (const item of Object.values(AdminObjectType)) {
            const i = Object.values(AdminObjectType).indexOf(item);
            if (translations.enum.admin_object_type[item]) {
                types.push(new ListFilterDefinitionItem(i, this.context.translate(translations.enum.admin_object_type[item])))
            }
        }
        filter.push(new ListFilterDefinition("type", types, this.onFilterType, -1))

        // Prepare owner for filter
        if (this.state.admins) {
            let creatorList = [
                new ListFilterDefinitionItem(-1, this.context.translate(translations.fields.filter.all_created_by)),
                ...this.state.admins.map(s => new ListFilterDefinitionItem(s.id!, s.firstname + " " + s.lastname))
            ]
            filter.push(new ListFilterDefinition("createdBy", creatorList, (items, value) => this.onFilterUser(items, value,"createdBy"), -1))
        }

        this.setState({listFilter: filter})
    }
    onFilterType = (items: Entity[], value: number) => {
        let array = EnumToEntityArray(AdminObjectType, translations.enum.admin_object_type, this.context.translate)
        let type = array.find(s => s.id === value)

        if (type) {
            return items.filter(item => item["type"] === type!.name)
        }

        return items
    }
    onFilterUser = (items: Entity[], value: number, field: string) => {
        if (value < 0) {
            return items
        }

        return items.filter(e => {
            let admin = this.state.admins?.find(i => i.id === value)

            if (admin) {
                return e[field] === admin.firstname + " " + admin.lastname
            }
            return false
        })
    }

    fetchData = () => {
        this.setState({ items: [] })

        GetApprovalItems().then(
            (itemData) => {
                const listItems = itemData.map(item => {
                    let type: Resource | null = null
                    let config

                    if (item.type) {
                        type = translations.enum.admin_object_type[item.type]
                        config = ApprovalItem.getItemConfig(item)
                    }

                    let createdBy = ""
                    if (item.createdBy) {
                        createdBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    return new ApprovalListItem(
                        item.id!,
                        item.name,
                        type ? this.context.translate(type) : "",
                        config ? config.area : "",
                        config ? config.url : "",
                        item.modifiedOn || item.createdOn!,
                        createdBy,
                        item.type as AdminObjectType
                    )
                })

                this.setState({ items: itemData, listItems: listItems })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    render() {

        return <AdminDashboardList title={this.context.translate(translations.text.approval_list_title)}
                                   redirectUrl={"/maintenance/{1}/{0}"}
                                   columnDefinition={this.columnDefinition}
                                   objectType={AdminObjectType.approval_item}
                                   listFilter={this.state.listFilter}
                                   listItems={this.state.listItems}
                                   sortOptions={{
                                       allowSort: true,
                                       sortField: "modifiedOn",
                                       sortDescending: false
                                   }}
                                   history={this.props.history}
                                   location={this.props.location}
                                   match={this.props.match}
        />
    }
}

