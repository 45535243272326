import API from "../Framework/API";
import KPI from "../_model/KPI";

const URL = "kpis"

export function GetNumberOfWorksheets() {
    let url = URL + "/worksheets"
    return API.Get<KPI>(url)
}
export function GetNumberOfMarketplaceWorksheets() {
    let url = URL + "/marketplace/worksheets"
    return API.Get<KPI>(url)
}
