import {WDDataCellBorder} from "../Elements/General/WDDataCellBorder";
import {ManualBorderMode} from "../../Framework/Enums";

export class BorderUtils {
    x: number
    y: number
    status: WDDataCellBorder

    constructor(x: number, y: number, status: WDDataCellBorder) {
        this.x = x
        this.y = y
        this.status = status
    }

    static getCursorClass = (borderMode: ManualBorderMode) => {
        let cursorURL: string | undefined = undefined
        if(borderMode === ManualBorderMode.Border) {
            cursorURL = "cursor-pen";
        }
        else if(borderMode === ManualBorderMode.Erase) {
            cursorURL = "cursor-eraser";
        }
        return cursorURL
    }
}
