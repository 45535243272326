import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import {TooltipText} from "../../../../Components/Tooltips";
import {ImagePath} from "../../../../Framework/CategoryImage";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";

/**
 * Button (Yes/No) in Text Toolbar
 * Effects selected text of an element
 * Yes: marked text should get the text decoration given as command
 *      by now all entered text should get bold
 * No:  marked text should get normal
 *      by now all entered text should get normal
 */


interface IProps {
    id: number
    clickable: boolean
    pressed?: boolean
    action: WDToolbarAction
    icon: string

    onActionButton: (action: WDToolbarAction) => void
}

interface IState {}

export class WDToolbarButtonSolution extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onToggle = () => {
        this.props.onActionButton(this.props.action)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-solution"}
            className={"ws-designer-toolbar-item"}
            id={"toolbar-button-solution"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + this.props.icon}
                             iconClassSelected={"ws-designer-toolbar-button-img svg-color-primary"}
                             iconClassUnselected={"ws-designer-toolbar-button-img"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.solution), this.context.translate(translations.tooltip.solution))}
                             onToggleButton={this.onToggle}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed || false} />

        </li>
    }
}
