import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import TextBox from "../../Components/Controls/TextBox";
import {
    GetMarketplaceWorksheetById,
    GetWorksheetById,
    GetWorksheetLogsById,
    GetWorksheetThumbnail,
    PublishMPWorksheet,
    RejectMPWorksheet,
    UpdateMarketplaceWorksheet
} from "../../_endpoint/WorksheetEndpoint";
import {Worksheet} from "../../_model/Worksheet";
import {AdminObjectType, ColumnRenderOption, Status, WSMarketplaceStatus} from "../../Framework/Enums";
import {MainContext} from "../../_base/MainContext";
import AdminDataForm from "../Maintenance/AdminDataForm";
import Tag from "../../_model/Tag";
import FormHeader from "../../Components/Form/FormHeader";
import {RibbonButtonData} from "../../Components/Ribbon/RibbonButtonData";
import {TooltipText} from "../../Components/Tooltips";
import {Notification} from "../../Components/Notification/NotificationHandler";
import List, {ColumnDefinition, ListItem} from "../../Components/List/List";
import {ListEntry} from "../../Components/List/ListEntry";
import {WorksheetLogListItem} from "./WorksheetLogListItem";
import {CreateTag, GetTags} from "../../_endpoint/MetadataEndpoint";
import CheckBoxSwitch from "../../Components/Controls/CheckBoxSwitch";
import Auth from "../../Framework/Auth";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> {
}

interface IState {
    item?: Worksheet
    worksheetThumbnail?: string

    marketplaceWorksheet?: Worksheet
    marketplaceWorksheetThumbnail?: string

    unsavedChanges: boolean

    logs?: WorksheetLogListItem[]
    tags?: Tag[]
}

export default class MPWorksheetForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.status), "marketplaceStatus", 40, false, false),
        new ColumnDefinition(this.context.translate(translations.fields.date), "createdOn", 35, false, false, ColumnRenderOption.DateTime),
        new ColumnDefinition(this.context.translate(translations.fields.user), "createdBy", 25, true, false),
    ]

    constructor(props: MatchProps, state: IState) {
        super(props, state)

        this.state = {
            unsavedChanges: false,
        }
    }

    componentDidMount() {
        this.initWorksheet()

        GetTags().then((result) => {
            this.setState({tags: result})
        })
    }
    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.initWorksheet()
        }
    }

    initWorksheet = () => {
        GetWorksheetById(+this.props.match.params.id).then(
            (itemData) => {
                GetWorksheetThumbnail(itemData.id).then((worksheet) => {
                    this.setState({worksheetThumbnail: worksheet.thumbnail})
                }, (error) => {
                    console.error(error)
                    // Ignore error, when no thumbnail is available the default image is shown
                })

                if (itemData.marketplaceStatus === WSMarketplaceStatus.updated) {
                    GetMarketplaceWorksheetById(itemData.id).then((result) => {
                        this.setState({marketplaceWorksheet: result})

                        GetWorksheetThumbnail(result.id).then((worksheet) => {
                            this.setState({marketplaceWorksheetThumbnail: worksheet.thumbnail})
                        }, (error) => {
                            console.error(error)
                            // Ignore error, when no thumbnail is available the default image is shown
                        })
                    }, (error) => {
                        console.error(error)
                        // Ignore error, when the marketplace worksheet is not available
                    })
                }

                this.setState({item: itemData}, () => this.fetchLists())
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    fetchLists = () => {
        GetWorksheetLogsById(+this.props.match.params.id).then(result => {
            this.setState({logs: WorksheetLogListItem.convertWorksheetLogArray(result, this.context.translate)})
        })
    }
    saveWorksheet = async () => {
        if (this.isFormValid() && this.state.item) {
            try {
                let currentItem = this.state.item

                // Added new collection, needs to be created
                let tags: Tag[] = []
                if (currentItem.tags) {
                    tags = currentItem.tags.filter(i => i.id !== undefined)
                    for (const i1 of currentItem.tags.filter(i => i.id === undefined)) {
                        tags.push(await CreateTag(i1));
                    }
                }
                currentItem.tags = tags

                return UpdateMarketplaceWorksheet(currentItem).then(_ => {
                    this.setState({unsavedChanges: false})

                    return Promise.resolve()
                })

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))

                return Promise.reject()
            }
        }
    }

    isFormValid = () => {
        return true
    }
    isReadonly = () => {
        let isCurrentUserCreator = this.state.item?.createdBy?.id === Auth.getUserId()
        return isCurrentUserCreator || this.state.item?.marketplaceStatus === WSMarketplaceStatus.updated
    }

    onSave = async (close: boolean) => {
        this.saveWorksheet().then(() => {
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            if (close) {
                this.onCancel()
            }
        })
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(this.getBackUrl())
        )
    }
    onClickWorksheet = async () => {
        if (this.state.item) {
            window.open(process.env.PUBLIC_URL + "/designer/" + this.state.item.id)
        }
    }
    onClickMarketplaceWorksheet = async () => {
        if (this.state.marketplaceWorksheet) {
            window.open(process.env.PUBLIC_URL + "/designer/" + this.state.marketplaceWorksheet.id)
        }
    }
    onPublishWorksheet = async () => {
        let notification = Notification.handleLoading(this.context.translate(translations.notification.saving))
        this.context.setNotification(notification)

        try {
            if (this.state.item && this.state.item.id) {
                await this.saveWorksheet()

                this.context.setNotification(Notification.handleLoading(this.context.translate(translations.notification.publishing)), notification.id)

                PublishMPWorksheet(this.state.item.id).then((result) => {
                    // show notification
                    this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.worksheet_published)), notification.id)

                    // redirect to published worksheet
                    this.props.history.push(this.getBackUrl() + "/" + result.id)
                }, (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.worksheet_not_published), notification.id)
                });
            }
        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.worksheet_not_published), notification.id)
        }
    }
    onRejectWorksheet = async () => {
        try {
            if (this.state.item && this.state.item.id) {
                await this.saveWorksheet()

                await RejectMPWorksheet(this.state.item.id).then((result) => {
                    // show notification
                    this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.worksheet_rejected)))

                    // redirect to published worksheet
                    this.setState({item: result})
                }, (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.worksheet_not_published))
                });
            }
        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.worksheet_not_published))
        }
    }

    onChangeFlag = (name: string) => {
        let item = this.state.item
        if (item) {
            item[name] = !item[name]
            this.setState({item: item})
        }
    }
    onChangeTags = (tags: Tag[]) => {
        let item = this.state.item
        if (item) {
            item.tags = tags
            this.setState({item: item, unsavedChanges: true})
        }
    }
    onOpenSourceWorksheet = () => {
        this.props.history.push(this.getBackUrl() + "/" + this.state.item?.sourceWorksheetId?.id)
    }

    getBackUrl = () => {
        return "/maintenance/marketplace-worksheets"
    }

    getStatus = () => {
        switch (this.state.item?.marketplaceStatus) {
            case WSMarketplaceStatus.approval:
                return Status.approval

            case WSMarketplaceStatus.published:
                return Status.published

            case WSMarketplaceStatus.rejected:
                return Status.deactivated

            default:
                return Status.approval
        }
    }

    render() {
        if (this.state.item === undefined) {
            return <></>
        }

        const marketplaceStatus = this.getStatus()
        let ribbonButtons: RibbonButtonData[] = []

        if (!this.isReadonly()) {
            ribbonButtons.push(
                new RibbonButtonData(
                    "save",
                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "save.svg",
                    10,
                    true,
                    this.context.translate(translations.command.save),
                    new TooltipText(this.context.translate(translations.command.save)),
                    () => this.onSave(false)
                )
            )
            ribbonButtons.push(
                new RibbonButtonData(
                    "save_and_close",
                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "save_and_close.svg",
                    20,
                    true,
                    this.context.translate(translations.command.save_and_close),
                    new TooltipText(this.context.translate(translations.command.save_and_close)),
                    () => this.onSave(true)
                )
            )
        }

        if (marketplaceStatus === Status.approval && this.state.item.createdBy?.id !== Auth.getUserId()) {
            ribbonButtons.push(
                new RibbonButtonData(
                    "publish",
                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "checkmark.svg",
                    40,
                    true,
                    this.context.translate(translations.command.publish),
                    new TooltipText(this.context.translate(translations.command.publish), this.context.translate(translations.tooltip.publish_to_marketplace)),
                    this.onPublishWorksheet
                ))
            ribbonButtons.push(
                new RibbonButtonData(
                    "reject",
                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "reject.svg",
                    50,
                    true,
                    this.context.translate(translations.command.reject),
                    new TooltipText(this.context.translate(translations.command.reject), this.context.translate(translations.tooltip.reject_marketplace)),
                    this.onRejectWorksheet
                ))
        }

        if (marketplaceStatus === Status.published && (this.state.item.ownerId === undefined || this.state.item.ownerId === null)) {
            ribbonButtons.push(new RibbonButtonData(
                "remove",
                process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "clear.svg",
                60,
                true,
                this.context.translate(translations.command.mp_remove),
                new TooltipText(this.context.translate(translations.command.mp_remove), this.context.translate(translations.tooltip.remove_marketplace)),
                this.onRejectWorksheet
            ))
        }

        let logs = this.state.logs ? this.state.logs
            .map(item => new ListItem(item, React.createRef<ListEntry>())) : []

        return <AdminDataForm
            onCancel={this.onCancel}
            allowStatusChangeToUserItself={false}
            item={Worksheet.ToEntityData(this.state.item)}
            itemType={AdminObjectType.marketplace_worksheet_admin}
            hasUnsavedChanges={this.state.unsavedChanges}
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
            customRibbonButtons={ribbonButtons}
        >
            <div className={"admin-form-content"}>
                <form id={"formData1"} className="admin-form-input" onSubmit={() => {
                    return false
                }}>

                    <FormHeader
                        number={this.state.item.id}
                        status={marketplaceStatus}
                        statusTranslationPath={translations.enum.marketplace_status_admin}
                        createdBy={this.state.item.ownerId || this.state.item.createdBy}
                    />

                    {this.state.item.sourceWorksheetId &&
                        <div className="form-group form-subheader">
                            <div className={"form-subheader-title"} onClick={this.onOpenSourceWorksheet}>
                                {this.context.translate(translations.text.source_worksheet)}: {this.state.item.sourceWorksheetId.name}
                            </div>
                        </div>
                    }

                    <div className="form-row">
                        <div className="form-group" style={{width: "500px", marginRight: "30px", paddingLeft: "0"}}>
                            <div className="form-row">
                                <TextBox id={"txtTitle"}
                                         width={500}
                                         label={this.context.translate(translations.fields.title)}
                                         required={false}
                                         readonly={true}
                                         value={this.state.item.name}
                                />
                            </div>

                            <div className="form-row">
                                <TextBox id={"txtDescription"}
                                         width={500}
                                         label={this.context.translate(translations.fields.description)}
                                         required={false}
                                         multiline={true}
                                         readonly={true}
                                         value={this.state.item.description || ""}
                                />
                            </div>

                            <div className="form-row">
                                <div className={"form-group"}>
                                    <CheckBoxSwitch id={"chkTargetPupil"}
                                                    width={250}
                                                    label={this.context.translate(translations.text.marketplace.target_pupils)}
                                                    readonly={true}
                                                    checked={this.state.item.marketplaceTargetPupils}/>
                                    <CheckBoxSwitch id={"chkTargetTeacher"}
                                                    width={250}
                                                    label={this.context.translate(translations.text.marketplace.target_teacher)}
                                                    readonly={true}
                                                    checked={this.state.item.marketplaceTargetTeacher}/>
                                </div>
                                <div className={"form-group"}>
                                    <CheckBoxSwitch id={"chkTargetSchool"}
                                                    width={250}
                                                    label={this.context.translate(translations.text.marketplace.target_school)}
                                                    readonly={true}
                                                    checked={this.state.item.marketplaceTargetSchool}/>
                                    <CheckBoxSwitch id={"chkTargetParent"}
                                                    width={250}
                                                    label={this.context.translate(translations.text.marketplace.target_parents)}
                                                    readonly={true}
                                                    checked={this.state.item.marketplaceTargetParents}/>
                                </div>
                            </div>

                            <div className="form-row">
                                <TextBox id={"txtMPKeywords"}
                                         width={500}
                                         label={this.context.translate(translations.text.worksheet_settings.keywords)}
                                         required={false}
                                         multiline={true}
                                         readonly={true}
                                         value={this.state.item.marketplaceKeywords || ""}
                                />
                            </div>

                            <div className={"form-row"}>
                                <TextBox id={"txtTags"}
                                         width={500}
                                         label={this.context.translate(translations.fields.image.tags)}
                                         required={false}
                                         readonly={this.isReadonly()}
                                         data={this.state.tags}
                                         tags={this.state.item.tags || []}
                                         onChangeTags={this.onChangeTags}
                                         mode={{autoComplete: true, tags: true}}
                                         value={""}
                                />
                            </div>

                            <div className={"form-row"}>
                                <CheckBoxSwitch id={"chkFlagShowName"}
                                                width={484}
                                                label={this.context.translate(translations.text.marketplace.show_name)}
                                                checked={this.state.item.marketplaceShowName}
                                                readonly={this.isReadonly() || !this.state.item.marketplaceShowName}
                                                onChange={() => this.onChangeFlag("marketplaceShowName")}
                                />
                            </div>
                            <div className={"form-row"}>
                                <CheckBoxSwitch id={"chkFlagShowEmail"}
                                                width={484}
                                                label={this.context.translate(translations.text.marketplace.show_email)}
                                                checked={this.state.item.marketplaceShowEmail}
                                                readonly={this.isReadonly() || !this.state.item.marketplaceShowEmail}
                                                onChange={() => this.onChangeFlag("marketplaceShowEmail")}
                                />
                            </div>

                            <div className="form-row admin-list-sublist">
                                <List items={logs}
                                      definitions={this.columnDefinition}
                                      objectType={AdminObjectType.worksheet_log}
                                      selectedItems={[]}
                                      paginationOptions={{ showPagination: true }}
                                      sortOptions={{
                                          allowSort: true,
                                          sortField: "createdOn",
                                          sortDescending: false
                                      }}
                                      allowMultiSelect={false}
                                      history={this.props.history}
                                      location={this.props.location}
                                      match={this.props.match}
                                />
                            </div>
                        </div>

                        <div className={"form-group"}>
                            {this.state.item.id && (
                                <div className={"admin-form-worksheet"}>
                                    <label className={"bold-label"}>Eingereichtes Update</label>
                                    {this.state.worksheetThumbnail ?
                                        <img src={"data:image/jpeg;base64," + this.state.worksheetThumbnail}
                                             width={350}
                                             alt={this.context.translate(translations.text.worksheet)}
                                             onClick={this.onClickWorksheet}
                                             draggable={"false"}
                                             onContextMenu={(e) => e.preventDefault() }
                                        />
                                        :
                                        <img
                                            src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                                            width={300} alt={this.context.translate(translations.text.worksheet)}
                                            onClick={this.onClickWorksheet}
                                            draggable={"false"}
                                            onContextMenu={(e) => e.preventDefault() }
                                        />
                                    }
                                </div>
                            )}

                            {this.state.marketplaceWorksheet && (
                                <div className={"admin-form-worksheet"} style={{marginTop: 20}}>
                                    <label className={"bold-label"}>Aktuelles Marktplatz-Arbeitsblatt</label>
                                    {this.state.marketplaceWorksheetThumbnail ?
                                        <img src={"data:image/jpeg;base64," + this.state.marketplaceWorksheetThumbnail} width={350}
                                             alt={this.context.translate(translations.text.worksheet)}
                                             onClick={this.onClickMarketplaceWorksheet}
                                             draggable={"false"}
                                             onContextMenu={(e) => e.preventDefault() }
                                        />
                                        :
                                        <img
                                            src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                                            width={300} alt={this.context.translate(translations.text.worksheet)}
                                            onClick={this.onClickWorksheet}
                                            draggable={"false"}
                                            onContextMenu={(e) => e.preventDefault() }
                                        />
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </AdminDataForm>
    }
}

