import React from "react";
import {MainContext} from "../../../_base/MainContext";
import translations from "../../../Framework/translations.json";
import {ImagePath} from "../../../Framework/CategoryImage";

export class LinkBracketInfo {
    topLeft: boolean
    bottomLeft: boolean
    topRight: boolean
    bottomRight: boolean

    constructor(topLeft: boolean, bottomLeft: boolean, topRight: boolean, bottomRight: boolean) {
        this.topLeft = topLeft
        this.bottomLeft = bottomLeft
        this.topRight = topRight
        this.bottomRight = bottomRight
    }
}
interface IProps {
    readonly: boolean
    linked: boolean
    linkBracketInfo: LinkBracketInfo
    onClickLink: () => void
}

export class WDToolbarLinkBracket extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        let additionalClass = (this.props.readonly ? " ws-designer-toolbar-button-link-disabled" : "")

        return <>
            <div className={"ws-designer-toolbar-button-link-bracket" + additionalClass} style={{paddingLeft: "4px"}}>
                {this.props.linkBracketInfo.topLeft &&
                    <img
                        src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "bracket_left_top.svg"}
                        className={"bracket"}
                        alt={""} />
                }
                {this.props.linkBracketInfo.topRight &&
                    <img
                        src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "bracket_right_top.svg"}
                        className={"bracket"}
                        alt={""} />
                }
            </div>
            <div className={"ws-designer-toolbar-button ws-designer-toolbar-button-link" + additionalClass}
                 onClick={!this.props.readonly ? this.props.onClickLink : () => {}}>
                <img
                    src={this.props.linked
                        ? (process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "link_closed.svg")
                        : (process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "link_open.svg")}
                    width={20} style={{padding: "2px"}}
                    alt={this.context.translate(translations.toolbar.link)}
                    draggable={"false"}
                    onContextMenu={(e) => e.preventDefault() }
                />
            </div>
            <div className={"ws-designer-toolbar-button-link-bracket" + additionalClass} style={{paddingLeft: "4px"}}>
                {this.props.linkBracketInfo.bottomLeft &&
                    <img
                        src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "bracket_left_bottom.svg"}
                        className={"bracket"}
                        alt={""}
                        draggable={"false"}
                        onContextMenu={(e) => e.preventDefault() }
                    />
                }
                {this.props.linkBracketInfo.bottomRight &&
                    <img
                        src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "bracket_right_bottom.svg"}
                        className={"bracket"}
                        alt={""}
                        draggable={"false"}
                        onContextMenu={(e) => e.preventDefault() }
                    />
                }
            </div>
        </>
    }
}
