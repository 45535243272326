import React from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import {TooltipPosition, Tooltips, TooltipText} from "../Tooltips";

/**
 * @param defaultStatus - if toggle button is checked or unchecked at start
 * @param imageUrlSelected - path of icon shown when button is checked  (thumbnail or imageUrl is mandatory!)
 * @param imageClassSelected - defines how button should be rendered (css class) when checked
 * @param imageUrlUnselected - path of icon shown when button is unchecked  (thumbnail or imageUrl is mandatory!)
 * @param imageClassSelected - defines how button should be rendered (css class) when unchecked
 * @param thumbnail - if button should show thumbnail add the whole image (thumbnail or imageUrl is mandatory!)
 * @param text - if additionally or instead of image text should be rendered
 * @param altText - altText of image, default if no image and no text is provided
 * @param toggleHandler - gives parent information of toggle status
 */
interface IProps {
    id: string
    defaultStatus: boolean

    buttonClass?: string
    imageUrlSelected?: string
    imageClassSelected?: string
    imageUrlUnselected?: string
    imageClassUnselected?: string
    thumbnail?: string

    text?: string
    color?: string
    arrow: boolean
    altText: string
    tooltip?: TooltipText
    tooltipPosition?: TooltipPosition

    buttonWidth?: number
    buttonHeight?: number
    imageWidth?: number
    imageHeight?: number

    toggleHandler: (b: boolean) => void
}
interface IState {
}

export class ImageToggleButton extends React.Component<IProps, IState> {

    findImageForStatus() {
        if(this.props.thumbnail) {
            return this.props.thumbnail
        }
        return this.props.defaultStatus ? this.props.imageUrlSelected : this.props.imageUrlUnselected
    }

    findClassForStatus() {
        return this.props.defaultStatus ? this.props.imageClassSelected : this.props.imageClassUnselected
    }

    findTranslationForTooltipPosition(buttonWidth: number, buttonHeight: number, toolTipPosition?: TooltipPosition) {
        let tooltipWidth = 170

        if(toolTipPosition === TooltipPosition.belowRight) {
            return {x: -(tooltipWidth - buttonWidth), y: 0}
        }
        if(toolTipPosition === TooltipPosition.below) {
            return {x: -(tooltipWidth/2 - (buttonWidth / 2)), y: 0}
        }
        if(toolTipPosition === TooltipPosition.belowLeft) {
            return {x: 0, y: 0}
        }

        return {x: -(tooltipWidth/2 - (buttonWidth / 2)), y: -buttonHeight - 8}
    }

    handleItemClickedToggle = (status: boolean) => {
        this.setState({status: status})
    }

    renderDropDownArrow = () => {
        if (this.props.arrow) {
            return <img
                src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                className={"ws-designer-toolbar-button-arrow"}
                alt={this.props.altText}
                draggable={"false"}
                onContextMenu={(e) => e.preventDefault() }
            />
        }
    }

    render() {
        let imageClass = this.findClassForStatus()
        let imageForStatus = this.findImageForStatus()
        let buttonWidth = this.props.buttonWidth || (this.props.arrow ? 42 : 32)
        let buttonHeight = this.props.buttonHeight || 32
        let imageHeight = this.props.imageHeight || (this.props.color ? 18 : 24)
        let imageWidth = this.props.imageWidth || 24
        let translation = this.findTranslationForTooltipPosition(buttonWidth, buttonHeight, this.props.tooltipPosition)

        // Border only shown when no color is selected - otherwise no color line would be visible
        let setBorder = false
        if((this.props.color === "#FFFFFF" || this.props.color === "transparent")) {
            setBorder = true
        }

        if (imageForStatus) {
            let image = this.props.thumbnail ? ImagePath.getThumbnailUrl() + imageForStatus : process.env.PUBLIC_URL + imageForStatus
            return <>
                <div
                    id={"btn-" + this.props.id}
                    className={this.props.buttonClass}
                    style={{width: buttonWidth, height: buttonHeight}}
                    onClick={(event) => {
                        event.stopPropagation()
                        this.props.toggleHandler(!this.props.defaultStatus)
                    }}>

                    <img
                        src={image}
                        width={imageWidth}
                        height={imageHeight}
                        style={{height: imageHeight, width: imageWidth}}
                        className={imageClass}
                        alt={this.props.altText}
                        draggable={"false"}
                        onContextMenu={(e) => e.preventDefault() }
                    />

                    {this.props.color &&
                    <div className={"ws-designer-toolbar-button-image-color"}
                         style={{
                             backgroundColor: this.props.color,
                             border: setBorder ? "1px solid #CBD2EC" : "unset"
                         }}/>
                    }

                    {this.renderDropDownArrow()}
                </div>

                {this.props.tooltip && <Tooltips text={this.props.tooltip} translateX={translation.x} translateY={translation.y} />}
            </>
        } else {
            let renderText = this.props.text ? this.props.text : this.props.altText

            return <div className={this.props.buttonClass} style={{color: "black"}} onClick={() => {
                this.props.toggleHandler(!this.props.defaultStatus)
            }}>

                {renderText}&nbsp;
                {this.renderDropDownArrow()}
            </div>
        }
    }
}

