import API from "../Framework/API";
import Image, {ImageFilter, ImageState, ImageToolboxFilter} from "../_model/Image";
import ImageCollection from "../_model/ImageCollection";
import ImageFamily from "../_model/ImageFamily";
import {WSContextType} from "../Designer/Elements/WSContext";
import ImageAlternative from "../_model/ImageAlternative";
import PageResult from "../_model/base/PageResult";

const URL = "images"

export function GetImageList() {
    let url = URL + "/"
    return API.Get<Image[]>(url)
}
export function GetImageListAdmin(filter: ImageFilter, page?: number, size?: number) {
    let url = URL + "/filter"

    let params = ""
    if (page !== null) {
        params = "?page=" + page
    }
    if (size) {
        params += ((params === "") ? "?" : "&") + "size=" + size
    }

    return API.Post<ImageFilter, PageResult>(url + params, filter)
}
export function GetImageGalleryFiltered(filter: ImageFilter, wsType: WSContextType, page?: number, size?: number) {
    let url = URL + "/" + wsType + "/filter"

    let params = ""
    if (page !== null) {
        params = "?page=" + page
    }
    if (size) {
        params += ((params === "") ? "?" : "&") + "size=" + size
    }

    return API.Post<ImageFilter, PageResult>(url + params, filter)
}
export function GetImagesByToolboxItem(filter: ImageToolboxFilter, page?: number, size?: number) {
    let url = URL + "/toolbox"

    let params = ""
    if (page !== null) {
        params = "?page=" + page
    }
    if (size) {
        params += ((params === "") ? "?" : "&") + "size=" + size
    }

    return API.Post<ImageToolboxFilter, Image[]>(url + params, filter)
}
export function GetImage(id: number) {
    let url = URL + "/" + id
    return API.Get<Image>(url)
}
export function GetImageWithCounterpartImageInfo(id: number) {
    let url = URL + "/counterpart/" + id
    return API.Get<Image>(url)
}
export function GetCounterpartImage(id: number) {
    let url = URL + "/counterpart-of/" + id
    return API.Get<Image>(url)
}
export function GetRandomImageByToolboxId(toolboxId: number) {
    let url = URL + "/toolbox/" + toolboxId + "/random"
    return API.Get<Image>(url)
}
export function GetImageDetails(id: number) {
    let url = URL + "/" + id + "/details"
    return API.Get<Image>(url)
}

export function CreateImage(item: Image) {
    return API.Post<object, Image>(URL, item)
}
export async function CloneImage(item: Image, name: string) {
    item.name = name
    item.image = undefined
    item.filename = undefined
    item.thumbnail = undefined
    item.thumbnailFilename = undefined
    item.counterpartImage = undefined
    return CreateImage(item)
}
export function UpdateImage(item: Image) {
    return API.Put<object, Image>(URL + "/" + item.id, item)
}
export function UpdateImageState(item: Image) {
    let obj = new ImageState(item.status, item.ownerId)
    return API.Put<object, ImageState>(URL + "/" + item.id + "/state", obj)
}

export function GetImageCollections() {
    let url = URL + "/collections"
    return API.Get<ImageCollection[]>(url)
}
export function CreateImageCollection(item: ImageCollection) {
    let url = URL + "/collections"
    return API.Post<object, ImageCollection>(url, item)
}

export function GetImageFamilies() {
    let url = URL + "/families"
    return API.Get<ImageFamily[]>(url)
}
export function GetImagesByFamily(imageFamilyId: number) {
    let url = URL + "/families/" + imageFamilyId + "/images"
    return API.Get<Image[]>(url)
}
export function CreateImageFamily(item: ImageFamily) {
    let url = URL + "/families"
    return API.Post<object, ImageFamily>(url, item)
}

export function GetImageAlternative() {
    let url = URL + "/alternatives"
    return API.Get<ImageAlternative[]>(url)
}
export function GetImagesByAlternative(imageAlternativeId: number) {
    let url = URL + "/alternatives/" + imageAlternativeId + "/images"
    return API.Get<Image[]>(url)
}
export function CreateImageAlternative(item: ImageAlternative) {
    let url = URL + "/alternatives"
    return API.Post<object, ImageAlternative>(url, item)
}
