import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button (Yes/No) in Toolbar
 * Effects selected element(s)
 * Yes: exercise should be calculated by the logic of the element
 * No:  input values should be writeable (not readOnly) so user can define exercises by themselves
 */

interface IProps {
    id: number
    pressed: boolean
    enabled: boolean
    tooltipDisabled?: TooltipText

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
    onShowConfirmation?: (title: string, description: string, onSubmit: () => void) => void
    onUngroup?: () => void
}

export class WDToolbarButtonTaskGenerator extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onToggle = () => {
        if (this.props.onShowConfirmation) {
            this.props.onShowConfirmation(
                this.context.translate(translations.text.task_generator_ungroup_exercise_title),
                this.context.translate(translations.text.task_generator_ungroup_exercise_description),
                this.changeTaskGenerator
            )
        }
        else {
            this.changeTaskGenerator()
        }
    }
    changeTaskGenerator = () => {
        let valuePressed: any = {"generateTask": !this.props.pressed}

        if(this.props.onUngroup) {
            this.props.onUngroup()
        } else {
            this.props.onActionButton(WDToolbarAction.GENERATE_TASK, valuePressed)
        }
        this.props.onToggleButton(this.props.id)
    }

    render() {
        return <li key={"ws-designer-toolbar-button-task-generator"}
                   id={"taskGenerator"}>
            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getMathUrl() + (this.props.pressed ? "task_generator_inactive.svg" : "task_generator.svg")}
                             iconClassSelected={"svg-color-original"}
                             iconClassUnselected={"svg-color-original"}
                             tooltip={
                                new TooltipText(this.context.translate(translations.toolbar.task_generator),
                                    this.context.translate(translations.tooltip.task_generator))}
                             tooltipDisabled={this.props.tooltipDisabled}
                             onToggleButton={this.onToggle}
                             arrow={false}
                             enabled={this.props.enabled}
                             pressed={false} />
        </li>
    }
}
