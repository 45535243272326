import translations from "../../../../Framework/translations.json";
import {CalculationType} from "../../../Toolbar/Button/Math/WDToolbarButtonCalculationType";

export default class WDCalculationTriangleDropDownValues {
    static getTowerNumberRange() {
        return [
            {key: -1, value: ""},
            {key: 10, value: "10"},
            {key: 20, value: "20"},
            {key: 30, value: "30"},
            {key: 100, value: "100"},
        ];
    }

    static getTowerCalculationType() {
        return [
            {key: CalculationType.ADD, value: translations.math.calculation_type.add},
            {key: CalculationType.MULTIPLY, value: translations.math.calculation_type.multiply},
            {key: CalculationType.DIVIDE, value: translations.math.calculation_type.divide},
            {key: CalculationType.COMPLEMENT, value: translations.math.calculation_type.complement}
        ]
    }
}
