import React from 'react';
import {Link} from "react-router-dom";
import Entity from "../../_model/Entity";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import ListPagination from "./ListPagination";
import ISort, {genericSort} from "../../Framework/Sort";
import {MainContext} from "../../_base/MainContext";
import {ListItem} from "./List";
import Card, {CardAction, CardDefinition} from "./Card";
import {Hint, NotificationData} from "../Notification/Hint";
import {AdminObjectType, NotificationStatus} from "../../Framework/Enums";
import {Tooltips, TooltipText} from "../Tooltips";
import {ListSortOptions} from "./ListSortOptions";
import {AdminDataListSortSettings} from "../../Admin/Maintenance/AdminDataListSortSettings";

interface IProps {
    items?: ListItem[]
    selectedItems: number[]
    selectedEntityTextSingle?: string
    selectedEntityTextMultiple?: string
    objectType: AdminObjectType

    definitions: CardDefinition
    cardActions: CardAction[]

    sortOptions: ListSortOptions

    newItemUrl?: string
    newItemTooltip?: TooltipText
    redirectUrl?: string
    redirectTarget?: string
    allowMultiSelect: boolean

    onSelectionChange?: (id: number[]) => void
    onLoadThumbnail: (id: number) => void
}
interface IState {
    page: number
    pageSize: number
    sortValue: ISort<Entity>
}

export default class CardList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        let sortValueContext = this.getCurrentSortValue()
        let sortValueNew = {
            property: sortValueContext.sortString as keyof Entity,
            isDescending: sortValueContext.descending
        }

        this.state = {
            page: 1,
            pageSize: 100,
            sortValue: sortValueNew
        }

        this.getThumbnails()
    }

    componentDidMount() {
        this.context.getUserSettings().then (userSettings => {
            this.setState({pageSize: userSettings.listPageSize})
        })
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if(prevProps.items?.length !== this.props.items?.length ||
            prevProps.items?.filter(item => {return item.item.thumbnail !== undefined}).length
            !== this.props.items?.filter(item => {return item.item.thumbnail !== undefined}).length) {

            this.getThumbnails()
        }
    }

    getCurrentSortValue = () => {
        let sortValue: AdminDataListSortSettings | undefined = this.context.getListSortValue(this.props.objectType)
        if (sortValue === undefined) {
            sortValue = new AdminDataListSortSettings(
                this.props.objectType,
                this.state ? this.state.sortValue.property : this.props.sortOptions.sortField,
                this.state ? this.state.sortValue.isDescending : this.props.sortOptions.sortDescending
            )
        }

        return sortValue
    }

    getThumbnails = () => {
        this.props.items?.forEach(item => {
            if(item.item.thumbnail === undefined) {
                this.props.onLoadThumbnail(item.item.id!)
            }
        })
    }

    setSortValue = (property: string, descending: boolean) => {
        let propRule = property as keyof Entity
        this.setState({sortValue: {property: propRule, isDescending: descending}})
    }
    onItemSelect = (itemId: number) => {
        let selectedItems: number[] = (this.props.allowMultiSelect ? this.props.selectedItems : [])

        if (selectedItems.find(item => item === itemId) === undefined) {
            selectedItems.push(itemId)
        }
        this.props.onSelectionChange?.(selectedItems)
    }
    onItemUnselect = (itemId: number) => {
        let selectedItems = this.props.selectedItems
        const index = this.props.selectedItems.indexOf(itemId)

        if (index >= 0) {
            selectedItems.splice(index, 1)
            this.props.onSelectionChange?.(selectedItems)
        }
    }
    onPageChange = (page: number) => {
        if (page > 0 && page <= this.getPageCount()) {
            this.setState({page: page})
        }
    }

    getPageCount = () => {
        if (this.props.items === undefined || this.props.items.length === 0) {
            return 1
        }
        return Math.ceil(this.props.items.length / this.state.pageSize)
    }

    render() {
        let footer: string | undefined = undefined
        if (this.props.allowMultiSelect) {
            footer = this.props.selectedItems.length + " "
            if (this.props.selectedItems.length === 1) {
                footer += (this.props.selectedEntityTextSingle || this.context.translate(translations.text.row_selected))
            } else {
                footer += (this.props.selectedEntityTextMultiple || this.context.translate(translations.text.rows_selected))
            }
        }

        return <div className="card-area">
            <div className={"card-rows"}>

                {this.props.items === undefined &&
                    <div className={"list-notification-container"}>
                        <Hint id={"list-loading"}
                              style={{width: "50%", marginTop: 20, height: 50}}
                              notificationData={new NotificationData(NotificationStatus.loading, this.context.translate(translations.notification.loading))}/>
                    </div>
                }

                {this.props.items && this.props.items.length === 0 &&
                    <div className={"list-notification-container"}>
                        <Hint id={"list-empty"}
                              style={{width: "50%", marginTop: 20, height: 50}}
                              notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.image_search_no_result))}/>
                    </div>
                }

                {this.props.items && this.props.items
                    .sort((itemA, itemB) => {
                        return genericSort<Entity>(itemA.item, itemB.item, this.state.sortValue);})
                    .map((value, i) => {
                        if (i >= (this.state.pageSize * (this.state.page - 1)) && i < (this.state.pageSize * this.state.page)) {
                            return <Card
                                value={value}
                                selected={
                                    value.item.id
                                    ? this.props.selectedItems.includes(value.item.id)
                                    : false
                                }
                                onSelectItem={this.onItemSelect}
                                onUnselectItem={this.onItemUnselect}

                                cardDefinition={this.props.definitions}
                                cardActions={this.props.cardActions}
                                redirectUrl={this.props.redirectUrl}
                                redirectTarget={this.props.redirectTarget}
                                key={"card" + value.item.id}/>
                        }
                        return undefined
                    })
                }

            </div>

            {this.props.newItemUrl &&
                <div className={"plus-button-container tooltip-above"} style={{bottom: 5}}>
                    <div className={"plus-button tooltip-control"}>
                        <Link id="btnAddEntry" style={{textDecoration: "none"}} to={this.props.newItemUrl}>
                            <input type="image" alt={this.context.translate(translations.command.add)} className={"plus-button-main"}
                                   src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "plus.svg"}/>
                        </Link>
                    </div>

                    {this.props.newItemTooltip &&
                        <Tooltips
                            text={this.props.newItemTooltip}
                            translateX={15} translateY={110} />
                    }
                </div>
            }

            <ListPagination page={this.state.page}
                            pageCount={this.getPageCount()}
                            onPageChange={this.onPageChange}
                            text={footer}
            />
        </div>
    }
}

