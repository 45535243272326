export enum NumeralType {
    Grundzahl = 'Grundzahl',
    Ordnungszahl = 'Ordnungszahl',
    Bruchzahl = 'Bruchzahl',
    Vervielfaeltigungszahl = 'Vervielfältigungszahl',
    Einteilungszahl = 'Einteilungszahl',
    Wiederholungszahl = 'Wiederholungszahl',
    Gattungszahlwort = 'Gattungszahlwort',
    Unbestimmt = 'Unbestimmt'
}

export class Numeral {
    wordId?: number
    syllabification?: string
    numeralType?: NumeralType

    toString = () : string => {
        return "- Numeral"
    }
}

export default Numeral
