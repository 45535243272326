export class WDDataCellBorder {
    leftShow: boolean = false
    topShow: boolean = false
    rightShow: boolean = false
    bottomShow: boolean = false
    middleShow: boolean = false
    bottomDouble: boolean = false

    leftColor: string = "transparent"
    topColor: string = "transparent"
    rightColor: string = "transparent"
    bottomColor: string = "transparent"
    middleColor: string = "transparent"
}
