import React from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import {NotificationStatus} from "../../Framework/Enums";
import {Tooltips, TooltipText} from "../Tooltips";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";

interface IProps {
    id: string
    title?: string
    notificationType: NotificationStatus
    message: string
    notificationDelay?: number

    onClickSendErrorReport?: (message: string) => void
    onClickCancel?: () => void
    onClickStayActive?: () => void
}

/**
 * Render notification dialog depending on notification status
 */
export class Dialog extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    className: string = "notification"
    image: string = ""
    altText: string = ""
    animation: string = ""
    animationDuration: number = 1.5

    constructor(props: IProps) {
        super(props);

        this.getData()
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(this.props.notificationType !== prevProps.notificationType) {
            this.resetVariables()
            this.getData()
        }
    }

    resetVariables = () => {
        this.className = "notification"
        this.image = ""
        this.altText = ""
        this.animation = ""
    }

    getData = () => {
        switch (this.props.notificationType) {
            case NotificationStatus.ok:
                this.className += " notification-success"
                this.image = "all_right.svg"
                this.altText = "Success"
                this.animation = " fade-out-notification " + this.animationDuration + "s forwards"
                break

            case NotificationStatus.loading:
                this.className += " notification-loading"
                this.image = "loading.gif"
                this.altText = "Processing"
                break

            case NotificationStatus.info:
                this.className += " notification-information"
                this.image = "callsign.svg"
                this.altText = "Information"
                this.animation = " fade-out " + this.animationDuration + "s forwards"
                break

            case NotificationStatus.error:
                this.className += " notification-error"
                this.image = "warning.svg"
                this.altText = "Error"
                this.animation = " fade-out-notification " + this.animationDuration + "s forwards"
                break
        }
    }

    onSendErrorReport = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        this.props.onClickSendErrorReport?.(this.props.message)
        this.props.onClickCancel?.()
    }

    render() {
        return <div id={this.props.id} className={this.className}
                    style={{
                        animation: this.props.notificationDelay && this.animation,
                        animationDelay: this.props.notificationDelay + "ms"
                    }}
        >
            {(this.props.onClickCancel || this.props.title) &&
                <div className={"notification-header"}>
                    <span onClick={this.props.onClickStayActive}>{this.props.title}</span>

                    <div className={"notification-header-icons tooltip-above-right"}>
                        {this.props.onClickSendErrorReport &&
                            <>
                                <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "bugs.svg"}
                                     className={"notification-icon tooltip-control"}
                                     width={16}
                                     height={16}
                                     alt={"Close"}
                                     draggable={"false"}
                                     onClick={this.onSendErrorReport}
                                     onContextMenu={(e) => e.preventDefault()}
                                />
                                <Tooltips text={new TooltipText(this.context.translate(translations.command.send_error), this.context.translate(translations.tooltip.send_error))}
                                    translateX={-127} translateY={-28} />
                            </>
                        }

                        {this.props.onClickCancel &&
                            <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "close.svg"}
                                 className={"notification-icon"}
                                 width={16}
                                 height={16}
                                 alt={"Close"}
                                 draggable={"false"}
                                 onClick={this.props.onClickCancel}
                                 onContextMenu={(e) => e.preventDefault()}
                            />
                        }
                    </div>
                </div>
            }
            <div className={"notification-content"}
                 onClick={this.props.onClickStayActive}
            >
                <img className={"notification-logo"}
                     src={process.env.PUBLIC_URL + ImagePath.getNotificationUrl() + this.image} alt={this.altText}/>
                <div className={"notification-text"}>{this.props.message}</div>
            </div>
        </div>
    }
}
