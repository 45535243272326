import React from "react";
import {Modal} from "../Modal";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";
import {ButtonInfo} from "../Controls/ButtonList";

interface IProps {
    checkSaveDecision: (save: boolean, saveDecision: boolean) => void
    showSaveAlwaysCheckbox: boolean
    cancel: () => void
}

interface IState {
    checkBoxValue: boolean
}

export class UnsavedChangesModal extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            checkBoxValue: false
        }
    }

    onButtonClickSave = () => {
        return this.props.checkSaveDecision(true, this.state.checkBoxValue)
    }
    onButtonClickNotSave = () => {
        return this.props.checkSaveDecision(false, this.state.checkBoxValue)
    }
    onChangeCheckbox = () => {
        this.setState({checkBoxValue: !this.state.checkBoxValue})
    }

    render() {
        return <Modal id={"unsavedChanges"}
                      onFormSubmit={this.onButtonClickSave}
                      buttons={[
                          new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.props.cancel, {width: "150px", marginLeft: "15px"}),
                          new ButtonInfo("btnNotSave", "button button-save", "button", true, false, this.context.translate(translations.command.not_save), this.onButtonClickNotSave, {width: "150px", marginLeft: "15px"}),
                          new ButtonInfo("btnSave", "button button-save", "submit", true, false, this.context.translate(translations.command.save), undefined, {width: "150px", marginLeft: "15px"})
                      ]}
                      dialogStyle={{width: "40%", height: "160px", minWidth: "520px"}}
                      contentAlignment={"center"}
        >

            <div className={"modal-dialog-title"}>
                {this.context.translate(translations.text.save_changes_question)}
            </div>

            {this.props.showSaveAlwaysCheckbox &&
            <div className={"form-row"} style={{paddingTop: "10px"}}>
                <div className={"checkbox-group"}>
                    <input type={"checkbox"}
                           id={"ckbSaveDecision"}
                           checked={this.state.checkBoxValue}
                           onChange={this.onChangeCheckbox} />
                    <label className={"label"} htmlFor={"ckbSaveDecision"}>{this.context.translate(translations.command.save_decision)}</label>
                </div>
            </div>
            }

        </Modal>
    }
}
