import {WorksheetItem} from "../../_model/WorksheetItem";
import {WDHistoryAction} from "../History/Enum/WDHistoryAction";
import {WDActionLogCategory} from "../ActionLog/WDActionLogEntry";

export class WorksheetItemUpdate {
    itemKey: string
    value: Partial<WorksheetItem>

    constructor(itemKey: string, value: Partial<WorksheetItem>) {
        this.itemKey = itemKey
        this.value = value
    }
}

export class WorksheetItemUpdateOptions {
    historyAction?: WDHistoryAction | undefined
    updateHistory?: boolean | undefined = false

    actionCategory?: WDActionLogCategory | undefined
    actionDescription?: string | undefined
    actionData?: any | undefined

    applyToChildren?: boolean | undefined = true
    applyToLocked?: boolean | undefined = false
    applyRecursive?: boolean | undefined = true
    proportional?: boolean | undefined = false
}
