import React from "react";
import {MainContext} from "../../../../_base/MainContext";
import translations from "../../../../Framework/translations.json";
import {GetAdverb} from "../../../../_endpoint/WordEndpoint";
import {SidebarContentDragElementType} from "../../SidebarContentBase";
import Adverb from "../../../../_model/Dictionary/Adverb";

interface IProps {
    wordId?: number
    onDragStart: (event: React.DragEvent, dragElementType: SidebarContentDragElementType, elementId: string) => void
}
interface IState {
    adverb?: Adverb
}

export class SidebarDictionaryWordFormAdverb extends React.Component<IProps, IState>{
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
        }
    }

    componentDidMount() {
        this.fetchAdverb()
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(this.props.wordId !== prevProps.wordId) {
            this.fetchAdverb()
        }
    }

    fetchAdverb = () => {
        if(this.props.wordId) {
            GetAdverb(this.props.wordId).then(
                (itemData) => {
                    this.setState({adverb: itemData}, () => {
                    })
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }

    render() {
        if (this.state.adverb === null || this.state.adverb === undefined) {
            return <></>
        }

        return <>
            <div className={"ws-designer-dictionary-form-entry-row-column"}>
                <div className={"ws-designer-dictionary-form-entry-row-label"}>
                    {this.context.translate(translations.fields.word.adverb.adverb_type) + ":"}
                </div>
                <div className={"ws-designer-dictionary-form-entry-row-text"}>
                    {this.state.adverb.adverbType}
                </div>
            </div>
        </>

    }
}
