import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {DropDown, DropDownItem, DropDownType} from "../../../../Components/Controls/DropDown";
import {TooltipText} from "../../../../Components/Tooltips";
import {NumberInput} from "../../../../Components/Controls/NumberInput";
import {WDToolbarButtonDigitExceeded} from "./WDToolbarButtonDigitExceeded";
import {WDToolbarButtonZeroCalculation} from "./WDToolbarButtonZeroCalculation";
import {FloatingHint} from "../../../../Components/Notification/FloatingHint";
import {NotificationStatus} from "../../../../Framework/Enums";
import {NotificationData} from "../../../../Components/Notification/Hint";

/**
 * Button (with submenu) in Toolbar
 * Effects selected element(s)
 * Shows ZR, max and minValue
 * Dropdown ZR:   ZR should change to selected. This effects also min and maxValue. Recalculation of exercise should be done
 * Input min:     Changes minValue of solution to input. Empties ZR. Recalculation of exercise should be done.
 * Input max:     Changes maxValue of solution to input. Empties ZR. Recalculation of exercise should be done.
 */

export enum ValueRangeMode {
    simple,
    advanced
}

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltipDisabled?: TooltipText

    dropDownValues: DropDownItem[]
    zr: number
    mode: ValueRangeMode
    maxValue?: number
    maximalMaxValue?: number
    minValue?: number
    minimalMinValue?: number
    digitExceeded?: boolean
    zeroCalculation?: boolean
    minValueDisabled?: boolean
    maxValueDisabled?: boolean
    zrDisabled?: boolean
    digitExceededDisabled?: boolean
    zeroCalcDisabled?: boolean
    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data?: any) => void
}

interface IState {
    maxValueZR?: number
    maxValueInput?: number
    minValueInput?: number
    error: string | undefined
}

export class WDToolbarButtonValueRange extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            maxValueZR: this.props.zr,
            maxValueInput: this.props.maxValue,
            minValueInput: this.props.minValue,
            error: undefined
        }
    }

    componentDidUpdate(prevProps: IProps) {
        // Check if new selected element is different from previous selected element by data
        if (this.props.zr === prevProps.zr
            && this.props.maxValue === prevProps.maxValue
            && this.props.minValue === prevProps.minValue
            && this.props.digitExceeded === prevProps.digitExceeded) {
            return
        }

        this.setState({
            maxValueZR: this.props.zr,
            maxValueInput: this.props.maxValue,
            minValueInput: this.props.minValue
        })
    }

    onChangeZR = (zr: number) => {
        let valueRange: any = {"zr": zr, "maxValue": zr, "minValue": 0}
        this.setState({
                maxValueZR: zr,
                maxValueInput: zr,
                minValueInput: 0
            },
            () => this.props.onActionButton(WDToolbarAction.CHANGE_VALUE_RANGE, valueRange))
    }
    onHideError = () => {
        this.setState({error: undefined})
    }

    recalculateNumberRange = (minValue: number, maxValue: number) => {
        // Check if min is smaller than max
        if (minValue > maxValue) {
            this.setState({error: this.context.translate(translations.error.min_max_value)})
            return
        }

        let valueRange: any = {"zr": -1, "maxValue": maxValue, "minValue": minValue};

        // Set state and call calculation method
        this.setState({
            maxValueZR: -1,
            maxValueInput: maxValue,
            minValueInput: minValue
        }, () => this.props.onActionButton(WDToolbarAction.CHANGE_VALUE_RANGE, valueRange))
    }

    render() {
        return <li key={"ws-designer-toolbar-button-number-range"} id={"valueRange"}>

            <WDToolbarButton key={"btnChangeZR"}
                             id={this.props.id}
                             tooltip={new TooltipText(
                                 this.context.translate(translations.toolbar.change_value_range),
                                 this.context.translate(translations.tooltip.change_value_range))}
                             tooltipDisabled={this.props.tooltipDisabled}
                             icon={ImagePath.getMathUrl() + "value_range.svg"}
                             onToggleButton={this.props.onToggleButton}
                             arrow={true}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed}/>

            {this.props.pressed &&
                <div className="ws-designer-toolbar-submenu">
                    <label className={"ws-designer-options-section-label-normal"}>
                        {this.context.translate(translations.toolbar.change_value_range)}
                    </label>

                    <DropDown
                        id={"value-range"}
                        type={DropDownType.TEXT}
                        readonly={this.props.zrDisabled !== undefined ? this.props.zrDisabled : false}
                        required={false}
                        value={this.props.zr.toString()}
                        width={225}
                        autocomplete={false}
                        items={this.props.dropDownValues}
                        onChangeValue={(e) => this.onChangeZR(+e)}/>

                    {this.props.mode === ValueRangeMode.advanced &&
                        <>
                            <div className={"ws-designer-options-section-label-normal"}> {this.context.translate(translations.toolbar.define_value_range)} </div>
                            <div className={"value-range-row"}>
                                <NumberInput minValue={0} maxValue={this.props.minimalMinValue || 1000000} maxlength={7} width={95}
                                             unitWidth={-10}
                                             required={true} clickable={!this.props.minValueDisabled}
                                             value={this.state.minValueInput}
                                             onChangeValue={(value: number) => this.recalculateNumberRange(value, this.state.maxValueInput !== undefined ? this.state.maxValueInput : 1000000)}
                                />

                                <div style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px"
                                }}> {this.context.translate(translations.text_fragment.to)} </div>

                                <NumberInput minValue={0} maxValue={this.props.maximalMaxValue || 1000000} maxlength={7} width={95}
                                             unitWidth={-10}
                                             required={true} clickable={!this.props.maxValueDisabled}
                                             value={this.state.maxValueInput}
                                             onChangeValue={(value: number) => this.recalculateNumberRange(this.state.minValueInput !== undefined ? this.state.minValueInput : 0, value)}
                                />
                            </div>

                            <div className={"value-range-row"} style={{paddingTop: "15px"}}>
                                <WDToolbarButtonDigitExceeded
                                    id={this.props.id}
                                    pressed={this.props.digitExceeded !== undefined ? this.props.digitExceeded : false}
                                    clickable={!this.props.digitExceededDisabled}
                                    onActionButton={this.props.onActionButton}/>
                            </div>
                            <div className={"value-range-row"} style={{paddingTop: "10px"}}>
                                <WDToolbarButtonZeroCalculation
                                    id={this.props.id}
                                    pressed={this.props.zeroCalculation !== undefined ? this.props.zeroCalculation : false}
                                    clickable={!this.props.zeroCalcDisabled}
                                    onActionButton={this.props.onActionButton}/>
                            </div>
                        </>
                    }

                    {this.state.error &&
                        <FloatingHint id={"error-number-value-range"}
                                      style={{width: "200px", left: "22px", top: "97%"}}
                                      onHide={this.onHideError}
                                      notificationData={new NotificationData(NotificationStatus.error, this.state.error)}/>
                    }
                </div>
            }

        </li>
    }
}
