import React, {RefObject} from "react";
import {WDTextbox, WDTextboxData} from "../Textbox/WDTextbox";
import {WDDataCellBorder} from "../General/WDDataCellBorder";
import Const from "../../../Framework/Const";
import {WDImage} from "../Image/WDImage";
import {WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import {WDElementBaseData} from "../WDElementBase";

export class WDTableRowData {
    height: number

    constructor(height: number) {
        this.height = height
    }
}

export class WDTableColumnData {
    width: number

    constructor(width: number) {
        this.width = width
    }
}

export class WDTableCellPromiseData {
    row: number
    col: number
    data: WDTextboxData

    constructor(row: number, col: number, data: WDTextboxData) {
        this.row = row
        this.col = col
        this.data = data
    }
}

export class WDTableCellData {
    data: WDTextboxData
    spellCheck: boolean
    background: string
    border: WDDataCellBorder
    worksheetElementType: WorksheetItemTypeEnum

    // The current cell spans over multiple rows/columns
    rowspan: number
    colspan: number

    // The current cell is connected to the previous cell in the same row/column
    connectedRow: boolean
    connectedCol: boolean

    paddingLeft: number
    paddingRight: number
    paddingTop: number
    paddingBottom: number

    textboxRef: RefObject<WDTextbox>
    imageRef: RefObject<WDImage>

    constructor() {
        this.data = WDTextboxData.defaultContent()
        this.spellCheck = true
        this.background = "transparent"
        this.worksheetElementType = WorksheetItemTypeEnum.TEXTBOX
        this.rowspan = 1
        this.colspan = 1
        this.connectedRow = false
        this.connectedCol = false

        this.paddingLeft = 0
        this.paddingRight = 0
        this.paddingTop = 0
        this.paddingBottom = 0

        this.border = new WDDataCellBorder()
        this.border.leftShow = true
        this.border.leftColor = Const.COLOR_PRIMARY
        this.border.rightShow = true
        this.border.rightColor = Const.COLOR_PRIMARY
        this.border.topShow = true
        this.border.topColor = Const.COLOR_PRIMARY
        this.border.bottomShow = true
        this.border.bottomColor = Const.COLOR_PRIMARY

        this.textboxRef = React.createRef()
        this.imageRef = React.createRef()
    }
}

export class WDTableData extends WDElementBaseData {
    id?: number
    rows: WDTableRowData[]
    cols: WDTableColumnData[]
    cells: WDTableCellData[][]
    syllableActivated: boolean

    constructor(
        rows: WDTableRowData[],
        cols: WDTableColumnData[],
        cells: WDTableCellData[][],
        syllableActivated: boolean) {

        super()
        this.rows = rows
        this.cols = cols
        this.cells = cells
        this.syllableActivated = syllableActivated
    }

    static calculateHeight(data: WDTableData) {
        let height = 0

        for (let i = 0; i < data.rows.length; i ++) {
            height += data.rows[i].height
        }

        return height + (Const.ELEMENT_PADDING * 2)
    }
    static calculateWidth(data: WDTableData) {
        let width = 0

        for (let i = 0; i < data.cols.length; i ++) {
            width += data.cols[i].width
        }

        return width + (Const.ELEMENT_PADDING * 2)
    }

    static createEmptyTableData(r: number, c: number, rowHeight: number, colWidth: number) {
        let rows: WDTableRowData[] = []
        let cols: WDTableColumnData[] = []
        let cells: WDTableCellData[][] = []

        for (let i = 0; i < c; i ++) {
            cols.push(new WDTableColumnData(colWidth))
        }

        for (let i = 0; i < r; i ++) {
            rows.push(new WDTableRowData(rowHeight))
            cells[i] = []

            for (let j = 0; j < c; j ++) {
                cells[i][j] = new WDTableCellData()
                cells[i][j].data = WDTextboxData.defaultContent()
            }
        }

        return new WDTableData(rows, cols, cells, false)
    }

    static serialize(data: WDTableData) {
        return JSON.stringify(data, (key, value) => {
            if (key === "textboxRef" || key === "imageRef") return undefined;
            else return value;
        })
    }
}
