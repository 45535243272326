import React from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import {NumberInput} from "./NumberInput";
import {TooltipText} from "../Tooltips";
import {MainContext} from "../../_base/MainContext";
import {WDToolbarIconLabel} from "../../Designer/Toolbar/General/WDToolbarIconLabel";
import Converter from "../../Framework/Converter";
import {PageBorderDirection} from "../../Designer/Toolbar/Button/Page/WDToolbarButtonPageBorder";
import {LinkBracketInfo, WDToolbarLinkBracket} from "../../Designer/Toolbar/General/WDToolbarLinkBracket";
import {ElementBorder} from "../../Designer/Elements/WDElementContainer";

interface IProps {
    borderOptions: ElementBorder
    onChangeBorder: (border: ElementBorder) => void
}

export class BorderPadding extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onChangePadding = (value: number, direction: PageBorderDirection) => {
        let borderOptions = this.props.borderOptions

        value = Math.round(Converter.mmToPx(value))

        if (this.props.borderOptions.linkPadding) {
            borderOptions.paddingTop = value;
            borderOptions.paddingRight = value;
            borderOptions.paddingBottom = value;
            borderOptions.paddingLeft = value;
        } else {
            switch (direction) {
                case PageBorderDirection.left:
                    borderOptions.paddingLeft = value; break;
                case PageBorderDirection.top:
                    borderOptions.paddingTop = value; break;
                case PageBorderDirection.right:
                    borderOptions.paddingRight = value; break;
                case PageBorderDirection.bottom:
                    borderOptions.paddingBottom = value; break;
            }
        }

        this.props.onChangeBorder(borderOptions)
    }
    onChangePaddingLink = () => {
        let borderOptions = this.props.borderOptions
        borderOptions.linkPadding = !borderOptions.linkPadding

        this.props.onChangeBorder(borderOptions)
    }

    render() {
        let paddingLeft = this.props.borderOptions.paddingLeft < 0 ? undefined : 0
        if (this.props.borderOptions.paddingLeft > 0) {
            paddingLeft = Math.round(Converter.pxToMm(this.props.borderOptions.paddingLeft))
        }

        let paddingTop = this.props.borderOptions.paddingTop < 0 ? undefined : 0
        if (this.props.borderOptions.paddingTop > 0) {
            paddingTop = Math.round(Converter.pxToMm(this.props.borderOptions.paddingTop))
        }

        let paddingRight = this.props.borderOptions.paddingRight < 0 ? undefined : 0
        if (this.props.borderOptions.paddingRight > 0) {
            paddingRight = Math.round(Converter.pxToMm(this.props.borderOptions.paddingRight))
        }

        let paddingBottom = this.props.borderOptions.paddingBottom < 0 ? undefined : 0
        if (this.props.borderOptions.paddingBottom > 0) {
            paddingBottom = Math.round(Converter.pxToMm(this.props.borderOptions.paddingBottom))
        }

        return <table>
            <tbody>
            <tr>
                <td>
                    <WDToolbarIconLabel id={1}
                                        icon={ImagePath.getButtonUrl() + "distance_left.svg"}
                                        tooltip={new TooltipText(
                                            this.context.translate(translations.toolbar.border.margin),
                                            this.context.translate(translations.tooltip.border_margin_left))
                                        }
                                        enabled={true}
                    />
                </td>
                <td>
                    <NumberInput width={80} maxlength={3} minValue={0} maxValue={999} clickable={true} required={false}
                                 unit={"mm"} unitWidth={20}
                                 value={paddingLeft}
                                 onChangeValue={(value) =>
                                     this.onChangePadding(value, PageBorderDirection.left)}/>

                </td>
                <td rowSpan={2}>
                    <WDToolbarLinkBracket
                        readonly={false}
                        linked={this.props.borderOptions.linkPadding}
                        linkBracketInfo={new LinkBracketInfo(true, true, true, true)}
                        onClickLink={this.onChangePaddingLink}/>
                </td>
                <td>
                    <WDToolbarIconLabel id={2}
                                        icon={ImagePath.getButtonUrl() + "distance_top.svg"}
                                        tooltip={new TooltipText(
                                            this.context.translate(translations.toolbar.border.margin),
                                            this.context.translate(translations.tooltip.border_margin_top))
                                        }
                                        enabled={true}
                    />
                </td>
                <td>
                    <NumberInput width={80} maxlength={3} minValue={0} maxValue={999} clickable={true} required={false}
                                 unit={"mm"} unitWidth={20}
                                 value={paddingTop}
                                 onChangeValue={(value) =>
                                     this.onChangePadding(value, PageBorderDirection.top)}/>
                </td>
            </tr>
            <tr>
                <td>
                    <WDToolbarIconLabel id={3}
                                        icon={ImagePath.getButtonUrl() + "distance_right.svg"}
                                        tooltip={new TooltipText(
                                            this.context.translate(translations.toolbar.border.margin),
                                            this.context.translate(translations.tooltip.border_margin_right))
                                        }
                                        enabled={true}
                    />
                </td>
                <td>
                    <NumberInput width={80} maxlength={3} minValue={0} maxValue={999} clickable={true} required={false}
                                 unit={"mm"} unitWidth={20}
                                 value={paddingRight}
                                 onChangeValue={(value) =>
                                     this.onChangePadding(value, PageBorderDirection.right)}/>
                </td>
                <td>
                    <WDToolbarIconLabel id={4}
                                        icon={ImagePath.getButtonUrl() + "distance_bottom.svg"}
                                        tooltip={new TooltipText(
                                            this.context.translate(translations.toolbar.border.margin),
                                            this.context.translate(translations.tooltip.border_margin_bottom))
                                        }
                                        enabled={true}
                    />
                </td>
                <td>
                    <NumberInput width={80} maxlength={3} minValue={0} maxValue={999} clickable={true} required={false}
                                 unit={"mm"} unitWidth={20}
                                 value={paddingBottom}
                                 onChangeValue={(value) =>
                                     this.onChangePadding(value, PageBorderDirection.bottom)}/>
                </td>
            </tr>
            </tbody>
        </table>
    }
}
