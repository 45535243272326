import Entity from "../../_model/Entity";

export default class MPListItem extends Entity {
    id: number
    name: string
    subject?: string
    difficulty?: string
    schoolLevels?: string
    modifiedOn: Date
    createdBy: string
    target: string
    rating?: number
    orientation: boolean

    constructor(id: number, name: string, modifiedOn: Date, createdBy: string, target: string, orientation: boolean, subject?: string, difficulty?: string, schoolLevels?: string, thumbnail?: string, rating?: number) {
        super(name, id, thumbnail);

        this.id = id
        this.name = name
        this.subject = subject
        this.difficulty = difficulty
        this.schoolLevels = schoolLevels
        this.modifiedOn = modifiedOn
        this.createdBy = createdBy
        this.target = target
        this.rating = rating
        this.orientation = orientation
    }
}
