import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import translations from "../../../../Framework/translations.json";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button in Toolbar
 * Effects selected element(s)
 * Click should regenerate an exercise (settings stay the same)
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltip?: TooltipText
    tooltipDisabled?: TooltipText

    onActionButton: (action: WDToolbarAction) => void
    onToggleButton: (id: number) => void
}

interface IState {
    taskGenerator?: boolean
}

export class WDToolbarButtonRegenerate extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {}
    }

    regenerate = () => {
        this.props.onActionButton(WDToolbarAction.REGENERATE)
        this.props.onToggleButton(this.props.id)
    }

    render() {
        let tooltip = this.props.tooltip || new TooltipText(this.context.translate(translations.toolbar.regenerate), this.context.translate(translations.tooltip.regenerate))
        return <li
            key={"ws-designer-toolbar-button-regenerate"}
            id={"regenerate"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getMathUrl() + "regenerate.svg"}
                             tooltip={tooltip}
                             tooltipDisabled={this.props.tooltipDisabled}
                             onToggleButton={this.regenerate}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed} />
        </li>
    }
}
