export default interface ISort<T> {
    property: Extract<keyof T, string | number | Date>;
    isDescending: boolean;
}

// comparator function for any property within type T
// works for: strings, numbers, and Dates (and is typed to accept only properties which are those types)
export function genericSort<T>(
    objectA: T,
    objectB: T,
    sorter: ISort<T>
) {
    const result = () => {
        let propA = objectA[sorter.property]
        let propB = objectB[sorter.property]

        if(typeof propA === "string" && typeof propB === "string") {
            if (propA.toLowerCase() > propB.toLowerCase()) {
                return 1;
            } else if (propA.toLowerCase() < propB.toLowerCase()) {
                return -1;
            } else {
                return 0;
            }
        }
        else {
            if (propA > propB) {
                return 1;
            } else if (propA < propB) {
                return -1;
            } else {
                return 0;
            }
        }
    }
    return sorter.isDescending ? result() * -1 : result();
}
