import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButtonGraphicsImage} from "./WDToolbarButtonGraphicsImage";
import {TooltipText} from "../../../../Components/Tooltips";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../../Framework/CategoryImage";

/**
 * Button (with submenu) in Toolbar
 * Effects selected element(s)
 * Shows all given images with given width
 * Click Image:   Checks which images was clicked - images of element should change to selected
 *
 * @param icon: if icon is provided, the arrow will be within the icon-button; otherwise the button is only the arrow
 * @param text: if text is provided, text with arrow will be shown
 * Hint: please only provide icon or text for button representation
 *
 * @param submenuWidth: if not provided, default value will be set
 *
 * @param onClickItem: method which will be called when item in submenu is selected
 * @param onActionButton: calls button action and returns data
 * Hint: please only provide one of the above methods
 *
 */

interface IProps {
    id: number
    pressed: boolean
    clickable: boolean
    tooltip: TooltipText
    tooltipDisabled?: TooltipText

    imageCategory: ImageCategory[]
    imagePath: string
    imagePlaceholders?: string[]
    imageWidth: number
    imageHeight?: number

    icon?: string
    iconClassSelected?: string
    iconClassUnselected?: string
    iconButtonWidth?: number
    iconButtonHeight?: number
    iconImageWidth?: number
    iconImageHeight?: number

    submenuWidth?: number
    submenuHeight?: number
    text?: string

    onToggleButton: (id: number) => void
    onActionButton?: (action: WDToolbarAction, data?: any) => void
    onClickItem?: (id: string) => void
}

export class WDToolbarButtonGraphics extends React.Component<IProps> {
    images: string[] = []

    componentDidMount() {
        this.getImagesByCategories()
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if(prevProps.imageCategory !== this.props.imageCategory) {
            this.getImagesByCategories()
        }
    }

    getImagesByCategories = () => {
        let categoryImages : string[] = []
        this.props.imageCategory.forEach(category => {
            if(categoryImages.length === 0) {
                categoryImages = CategoryImageValue.getImagesByCategory(category, this.props.imagePlaceholders)
            } else {
                categoryImages = categoryImages.concat(CategoryImageValue.getImagesByCategory(category, this.props.imagePlaceholders))
            }
        })

        this.images = categoryImages
    }
    getKeyOfImage = (image: string) => {
        let key: string | undefined = undefined
        let categoryAmount = this.props.imageCategory.length

        while (categoryAmount >= 0) {
            key = CategoryImageValue.getKeyOfImage(this.props.imageCategory[categoryAmount], image)

            if(key) {
                return key
            }

            categoryAmount -= 1
        }

        return image
    }

    onClickItemAction = (key: string) => {
        if(this.props.onClickItem) {
            this.props.onClickItem(key)
        }
        else if(this.props.onActionButton) {
            this.props.onActionButton(WDToolbarAction.CHANGE_GRAPHIC, {image: key})
        }
    }

    render() {
        let arrowAsButtonAlone = true
        if(this.props.icon || this.props.text) {arrowAsButtonAlone = false}

        let optionsClass = "ws-designer-toolbar-options ws-designer-toolbar-graphics"
        if(arrowAsButtonAlone) {
            optionsClass += " ws-designer-toolbar-arrow-options"
        }

        return <li
            style={{position: "relative"}}
            key={"ws-designer-toolbar-button-graphics"}
            id={"change-graphic-" + this.props.id}>

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={arrowAsButtonAlone ? ImagePath.getButtonUrl() + "dropdown_arrow_down.svg" : this.props.icon}
                             tooltip={this.props.tooltip}
                             tooltipDisabled={this.props.tooltipDisabled}
                             text={this.props.text}

                             iconClassSelected={this.props.iconClassSelected || "svg-color-original"}
                             iconClassUnselected={this.props.iconClassUnselected || "svg-color-original"}

                             arrow={!arrowAsButtonAlone}
                             cssClass={arrowAsButtonAlone ? "ws-designer-toolbar-button ws-designer-toolbar-button-m0" : undefined}
                             buttonHeight={arrowAsButtonAlone ? 32 : (this.props.iconButtonHeight || undefined)}
                             buttonWidth={arrowAsButtonAlone ? 10 : (this.props.iconButtonWidth || undefined)}
                             imageHeight={arrowAsButtonAlone ? 5 : (this.props.iconImageHeight || undefined)}
                             imageWidth={arrowAsButtonAlone ? 5 : (this.props.iconImageWidth || undefined)}
                             iconClassDisabled={"ws-designer-toolbar-button-disabled-thumbnail"}

                             onToggleButton={this.props.onToggleButton}
                             pressed={this.props.pressed} />

            {this.props.pressed &&
                <div className={optionsClass}
                     style={{width: this.props.submenuWidth, height: this.props.submenuHeight, marginTop: this.props.iconButtonHeight}}>

                    {this.images.map(image => {
                        return <WDToolbarButtonGraphicsImage
                            key={image}
                            keyValue={this.getKeyOfImage(image)}
                            id={this.props.id}
                            image={image}
                            imagePath={this.props.imagePath}
                            imageWidth={this.props.imageWidth}
                            imageHeight={this.props.imageHeight}
                            onClickItem={this.onClickItemAction}
                        />
                    })}
                </div>
            }

        </li>
    }
}
