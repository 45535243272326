import User from "./User";
import {Gender} from "../Framework/Enums";
import Language from "./Language";
import Entity from "./Entity";
import {Worksheet} from "./Worksheet";
import Name from "./Name";

export class NameConfig extends Entity {
    gender?: Gender
    genitive?: string
    difficulty?: string
    phonemic?: boolean
    language?: Language
    worksheetId?: Worksheet
    currentName?: Name
    createdBy?: User
    createdOn?: Date
}

export default NameConfig
