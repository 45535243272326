export enum WDHistoryAction {
    CREATE = "CREATE",
    DELETE = "DELETE",
    RESIZE = "RESIZE",
    MOVE = "MOVE",
    LOCK = "LOCK",

    COPY = "COPY",
    CUT = "CUT",
    PASTE = "PASTE",
    DUPLICATE = "DUPLICATE",
    GROUP = "GROUP",
    UNWRAP_EXERCISE = "UNWRAP_EXERCISE",

    ROTATE = "ROTATE",
    SKEW = "SKEW",
    FILL_COLOR = "FILL_COLOR",
    BORDER = "BORDER",
    BORDER_COLOR = "BORDER_COLOR",
    FLIP = "FLIP",
    TRANSPARENCY = "TRANSPARENCY",
    ARRANGE = "ARRANGE",
    ALIGN = "ALIGN",
    DISTRIBUTE = "DISTRIBUTE",
    TOOLBAR_ACTION = "TOOLBAR_ACTION",

    ORIENTATION = "ORIENTATION",
    PAGE_ADD = "PAGE_ADD",
    PAGE_DELETE = "PAGE_DELETE",
    PAGE_MOVE = "PAGE_MOVE",
    PAGE_COPY = "PAGE_COPY",
    PAGE_BORDER = "PAGE_COPY",
    PAGE_SOLUTION = "PAGE_COPY",

    CONTENT_CHANGED = "CONTENT_CHANGED",
    HISTORY = "HISTORY"
}
