import API from "../Framework/API";
import SalesMaterial from "../_model/SalesMaterial";
import {Worksheet} from "../_model/Worksheet";
import {CloneWorksheet} from "./WorksheetEndpoint";
import {WSContextType} from "../Designer/Elements/WSContext";

const URL = "sales-material"

export function GetAllSalesMaterial() {
    let url = URL + "/"
    return API.Get<SalesMaterial[]>(url)
}
export function GetSalesMaterial(salesMaterialId: number) {
    let url = URL + "/" + salesMaterialId
    return API.Get<SalesMaterial>(url)
}

export async function CloneSalesMaterial(salesMaterial: SalesMaterial, name: string) {
    salesMaterial.name = name

    if (salesMaterial.worksheet) {
        const worksheetName = salesMaterial.name.substring(0, 100)

        const worksheet: Worksheet = await CloneWorksheet(salesMaterial.worksheet.id, worksheetName, WSContextType.sales_material)
        salesMaterial.worksheet = new Worksheet(worksheet.id, worksheet.name, worksheet.context, worksheet.format, worksheet.editingAllowed)
    }

    return CreateSalesMaterial(salesMaterial)
}
export async function CreateSalesMaterial(salesMaterial: SalesMaterial) {
    return API.Post<object, SalesMaterial>(URL, salesMaterial)
}
export async function UpdateSalesMaterial(salesMaterial: SalesMaterial) {
    return API.Put<object, SalesMaterial>(URL + "/" + salesMaterial.id, salesMaterial)
}
