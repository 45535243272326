export enum RESIZE_NODE {
    topLeft = "-tl",
    top = "-t",
    topRight = "-tr",
    right = "-r",
    bottomRight = "-br",
    bottom = "-b",
    bottomLeft = "-bl",
    left = "-l"
}
