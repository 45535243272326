export class Conjunction {
    wordId?: number
    syllabification?: string

    toString = () : string => {
        return "- Conjunction"
    }
}

export default Conjunction
