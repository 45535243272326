import React from "react";
import {ImagePath} from "../../../Framework/CategoryImage";
import {Tooltips, TooltipText} from "../../../Components/Tooltips";
import translations from "../../../Framework/translations.json";
import {MainContext} from "../../../_base/MainContext";

export enum WDToolbarTypeEnum {
    OBJECT,
    TEXT,
    TRANSFORMATION
}
export class WDToolbarTabsConfig {
    showElementTab: boolean
    showTransformationTab: boolean
    showTextTab: boolean
    onChangeToolbarTab: (newToolbar: WDToolbarTypeEnum) => void

    constructor(showElementTab: boolean, showTransformationTab: boolean, showTextTab: boolean, onChangeToolbarTab: (newToolbar: WDToolbarTypeEnum) => void) {
        this.showElementTab = showElementTab
        this.showTransformationTab = showTransformationTab
        this.showTextTab = showTextTab
        this.onChangeToolbarTab = onChangeToolbarTab
    }
}

export interface IProps {
    activeToolbarType: WDToolbarTypeEnum
    showTabConfig: WDToolbarTabsConfig
}

export class WDToolbarTabs extends React.Component<IProps>{
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    onClickChangeTab = (event: React.MouseEvent, newType: WDToolbarTypeEnum) => {
        this.props.showTabConfig.onChangeToolbarTab(newType)
    }

    render() {
        let objectTranslateX, transformTranslateX, textTranslateX
        let firstTranslateX = -(81 - (23 / 2))
        let secondTranslateX = -(79 - (23 * 1.5))
        let thirdTranslateX = -(76 - (23 * 2.5))

        if(this.props.showTabConfig.showElementTab) {
            objectTranslateX = firstTranslateX
        }
        if(this.props.showTabConfig.showTransformationTab) {
            if(objectTranslateX) {
                transformTranslateX = secondTranslateX
            } else {
                transformTranslateX = firstTranslateX
            }
        }
        if(this.props.showTabConfig.showTextTab) {
            if(objectTranslateX && transformTranslateX) {
                textTranslateX = thirdTranslateX
            } else {
                if(!objectTranslateX && !transformTranslateX) {
                    textTranslateX = firstTranslateX
                } else {
                    textTranslateX = secondTranslateX
                }
            }
        }

        return <ul className={"ws-designer-toolbar-tabs tooltip-above"}>
            {this.props.showTabConfig.showElementTab &&
                <>
                <li id={"toolbar-tab-object"}
                    className={"ws-designer-toolbar-tab tooltip-control"}
                    style={{paddingBottom: this.props.activeToolbarType === WDToolbarTypeEnum.OBJECT ? "10px" : undefined}}
                    onClick={(event) => this.onClickChangeTab(event, WDToolbarTypeEnum.OBJECT)}>

                    <img src={process.env.PUBLIC_URL + ImagePath.getToolbarUrl() + "object.svg"} alt={"Element"}
                         draggable={"false"}
                         onContextMenu={(e) => e.preventDefault() }
                    />
                </li>

                <Tooltips
                    text={new TooltipText(
                        this.context.translate(translations.toolbar.toolbar_tab.object),
                        this.context.translate(translations.tooltip.tab_object)
                    )}
                    translateX={objectTranslateX} translateY={-29 + (this.props.activeToolbarType === WDToolbarTypeEnum.OBJECT ? -4 : 0)} />
                </>
            }

            {this.props.showTabConfig.showTransformationTab &&
                <>
                <li id={"toolbar-tab-transformation"}
                    className={"ws-designer-toolbar-tab tooltip-control"}
                    style={{paddingBottom: this.props.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION ? "10px" : undefined}}
                    onClick={(event) => this.onClickChangeTab(event, WDToolbarTypeEnum.TRANSFORMATION)}>

                    <img src={process.env.PUBLIC_URL + ImagePath.getToolbarUrl() + "properties.svg"} alt={"Transformation"}
                         draggable={"false"}
                         onContextMenu={(e) => e.preventDefault() }
                    />
                </li>

                <Tooltips
                    text={new TooltipText(
                        this.context.translate(translations.toolbar.toolbar_tab.transformation),
                        this.context.translate(translations.tooltip.tab_transformation)
                    )}
                    translateX={transformTranslateX} translateY={-29 + (this.props.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION ? -4 : 0)} />
                </>
            }

            {this.props.showTabConfig.showTextTab &&
                <>
                    <li id={"toolbar-tab-text"}
                        className={"ws-designer-toolbar-tab tooltip-control"}
                        style={{paddingBottom: this.props.activeToolbarType === WDToolbarTypeEnum.TEXT ? "10px" : undefined}}
                        onClick={(event) => this.onClickChangeTab(event, WDToolbarTypeEnum.TEXT)}>

                        <img src={process.env.PUBLIC_URL + ImagePath.getToolbarUrl() + "texts_teacher.svg"}
                             alt={"Text"}
                             draggable={"false"}
                             onContextMenu={(e) => e.preventDefault() }
                        />
                    </li>

                    <Tooltips
                        text={new TooltipText(
                            this.context.translate(translations.toolbar.toolbar_tab.text),
                            this.context.translate(translations.tooltip.tab_text)
                        )}
                        translateX={textTranslateX} translateY={-29 + (this.props.activeToolbarType === WDToolbarTypeEnum.TEXT ? -4 : 0)} />
                </>
            }
        </ul>
    }
}
