import React from "react";
import '../../assets/css/layout.min.css';
import {MainContext} from "../../_base/MainContext";
import {Modal} from "../../Components/Modal";
import translations from "../../Framework/translations.json";
import {ButtonInfo} from "../../Components/Controls/ButtonList";
import TextBox from "../../Components/Controls/TextBox";
import {ImageGroupListItem} from "./ImageGroupListItem";
import ImageFamily from "../../_model/ImageFamily";
import Entity from "../../_model/Entity";
import Image from "../../_model/Image";

interface IProps {
    title: string
    retrieveRecords: () => Promise<Entity[]>
    retrieveImages: (id: number) => Promise<Image[]>

    onCancel: () => void
    onSubmit: (imageFamilyName: string) => void
    onChange: (imageFamily: ImageFamily) => void
}

interface IState {
    recordName: string
    records?: Entity[]
}

export class ImageGroupModal extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            recordName: ""
        }
    }

    componentDidMount() {
        this.props.retrieveRecords().then(
            (records) => {
                this.setState({records: records})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    onFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        this.props.onSubmit(this.state.recordName)
    }
    onFormCancel = () => {
        this.props.onCancel()
    }

    onChangeNewRecord = (value: string) => {
        this.setState({recordName: value})
    }

    render() {
        return <Modal id={"imageFamily"}
                      onFormSubmit={this.onFormSubmit}
                      title={this.props.title}
                      buttons={[
                          new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onFormCancel, {marginTop: "30px"}),
                      ]}
                      dialogStyle={{width: "850px", height: "75%"}}
                      contentAlignment={"flex-start"}
        >
            <div style={{height: "100%"}}>
                <div className={"form-row"} style={{paddingTop: "20px"}}>

                    <TextBox id={"txtNewRecord"}
                             width={400}
                             required={false}
                             readonly={false}
                             onChange={this.onChangeNewRecord}
                             value={this.state.recordName}
                    />

                    <input id={"btnCreate"}
                           key={"btnCreate"}
                           className={"form-button"}
                           type={"submit"}
                           style={{marginLeft: "20px"}}
                           value={this.context.translate(translations.command.create)}
                    />

                </div>

                <div className={"form-row image-family-list-modal-row"}>

                    <div className={"form-group"} style={{
                        width: "100%", height: "100%", overflowY: "auto"
                    }}>

                        <div className={"image-family-list"}>
                            {this.state.records && this.state.records
                                .filter(i => i.name.toLowerCase().indexOf(this.state.recordName.toLowerCase()) >= 0)
                                .map(i => {
                                    return <ImageGroupListItem id={i.id!}
                                                               name={i.name}
                                                               key={i.id!}
                                                               retrieveImages={this.props.retrieveImages}
                                                               onSelectItem={this.props.onChange}
                                                               showTitle={true}
                                                               showHover={true}
                                                               showImages={false}
                                    />
                                })}

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    }
}
