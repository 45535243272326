import {AdminObjectType} from "../../Framework/Enums";
import {AdminUtils} from "../../Admin/Maintenance/AdminUtils";

export class ListRecord {
    id: number
    objectType: AdminObjectType

    constructor(id: number, objectType: AdminObjectType) {
        this.id = id
        this.objectType = objectType
    }

    static getUrl(record: ListRecord) {
        return AdminUtils.getObjectTypeUrl(record.objectType)
    }
}
