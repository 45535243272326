import React from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {WDesigner} from "../../Designer/WDesigner";
import {Dashboard} from "../../Dashboard/Dashboard";
import {Login} from "../../Login/Login";
import {ProtectedRouter} from "./ProtectedRouter";
import {AdminDashboard} from "../../Admin/Maintenance/AdminDashboard";
import RuleList from "../../Admin/Rule/RuleList";
import RuleForm from "../../Admin/Rule/RuleForm";
import Const from "../Const";
import NameList from "../../Admin/Name/NameList";
import NameForm from "../../Admin/Name/NameForm";
import CountryForm from "../../Admin/Country/CountryForm";
import CountryList from "../../Admin/Country/CountryList";
import ImageList from "../../Admin/Images/ImageList";
import ImageForm from "../../Admin/Images/ImageForm";
import {Profile} from "../../Profile/Profile";
import WSDesignerList from "../../Designer/List/WSDesignerList";
import ExerciseList from "../../Admin/Exercise/ExerciseList";
import ExerciseForm from "../../Admin/Exercise/ExerciseForm";
import WordList from "../../Admin/Dictionary/WordList";
import WordForm from "../../Admin/Dictionary/WordForm";
import TextExerciseList from "../../Admin/Exercise/TextExerciseList";
import TextExerciseChildList from "../../Admin/Exercise/TextExerciseChildList";
import WritingCourseForm from "../../Admin/WritingCourse/WritingCourseForm";
import WritingCourseList from "../../Admin/WritingCourse/WritingCourseList";
import WritingCourseChildList from "../../Admin/WritingCourse/WritingCourseChildList";
import SearchResultList from "../../Admin/SearchResult/SearchResultList";
import SearchResultForm from "../../Admin/SearchResult/SearchResultForm";
import PatternList from "../../Admin/Exercise/PatternList";
import MPList from "../../Marketplace/List/MPList";
import MPWorksheetList from "../../Admin/Marketplace/MPWorksheetList";
import MPWorksheetForm from "../../Admin/Marketplace/MPWorksheetForm";
import {MainContext} from "../../_base/MainContext";
import {VicidoProduct} from "../../_model/VicidoProduct";
import SalesMaterialForm from "../../Admin/Marketplace/SalesMaterial/SalesMaterialForm";
import SalesMaterialList from "../../Admin/Marketplace/SalesMaterial/SalesMaterialList";

interface IProps {
    userProductLicenses: VicidoProduct[]
}
export class AppRouter extends React.Component<IProps> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        return <Router basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route path={"/login"} component={Login}/>

                {/* User area */}
                <ProtectedRouter path={"/profile"} component={Profile} admin={false} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/designer/:id"} component={WDesigner} admin={false} userLicenses={this.props.userProductLicenses}/> {/*productLicenses={[ProductLicense.smart, ProductLicense.employee]}*/}
                <ProtectedRouter path={"/designer/" + Const.CreateNewDataUrl} component={WDesigner} admin={false} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/designer"} component={WSDesignerList} admin={false} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/marketplace/:id"} component={MPList} admin={false} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/marketplace"} component={MPList} admin={false} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/dashboard"} component={Dashboard} admin={false} userLicenses={this.props.userProductLicenses}/>

                {/* Maintenance area */}
                <ProtectedRouter path={"/maintenance/rules/:id"} component={RuleForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/rules/" + Const.CreateNewDataUrl} component={RuleForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/rules"} component={RuleList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/exercises/:id"} component={ExerciseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/exercises/" + Const.CreateNewDataUrl} component={ExerciseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/exercises"} component={ExerciseList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/exercise-pattern/:id"} component={ExerciseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/exercise-pattern/" + Const.CreateNewDataUrl} component={ExerciseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/exercise-pattern"} component={PatternList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/text-exercises/:id/list"} component={TextExerciseChildList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/text-exercises/:id/" + Const.CreateNewDataUrl} component={ExerciseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/text-exercises/" + Const.CreateNewDataUrl} component={ExerciseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/text-exercises/:id"} component={ExerciseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/text-exercises"} component={TextExerciseList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/names/:id"} component={NameForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/names/" + Const.CreateNewDataUrl} component={NameForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/names"} component={NameList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/images/:id"} component={ImageForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/images/" + Const.CreateNewDataUrl} component={ImageForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/images"} component={ImageList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/countries/:id"} component={CountryForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/countries/" + Const.CreateNewDataUrl} component={CountryForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/countries"} component={CountryList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/dictionary/:id"} component={WordForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/dictionary/" + Const.CreateNewDataUrl} component={WordForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/dictionary"} component={WordList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/writing-course/:id/list"} component={WritingCourseChildList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/writing-course/:id/" + Const.CreateNewDataUrl} component={WritingCourseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/writing-course/" + Const.CreateNewDataUrl} component={WritingCourseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/writing-course/:id"} component={WritingCourseForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/writing-course"} component={WritingCourseList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/search-results/:id"} component={SearchResultForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/search-results"} component={SearchResultList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/marketplace-worksheets/:id"} component={MPWorksheetForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/marketplace-worksheets"} component={MPWorksheetList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/marketplace-sales-material/:id"} component={SalesMaterialForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/marketplace-sales-material/" + Const.CreateNewDataUrl} component={SalesMaterialForm} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance/marketplace-sales-material"} component={SalesMaterialList} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/maintenance"} component={AdminDashboard} admin={true} userLicenses={this.props.userProductLicenses}/>
                <ProtectedRouter path={"/"} component={Dashboard} admin={false} userLicenses={this.props.userProductLicenses}/>
            </Switch>
        </Router>
    }
}
